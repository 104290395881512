import React, { useState, useEffect } from "react";
import {toast, ToastContainer } from "react-toastify";
import "common/css/master.css";
import Header from "common/js/header/header.js";
import {BookBorrowerSearchBar} from "./bookBorrowerSearchBar";
import { IssuedBookTable } from "./issuedBookTable";
import { searchBookBorrowerSvc } from "./returnBooksSvc";

const ReturnCtrl = () => {
  const [objSearch, setObjSearch] = useState({});
  const [loader, setLoader] = useState("none");
  const [lstBook, setLstBook] = useState([]);
  const [tableKey, setTableKey] = useState(0);
  const [objBorrowerDtls, setObjBorrowerDtls] = useState({
    lstBookCopyNo: [],
    totalFine: 0,
    totalSecurityDeposit: 0,
  });

  function searchBookBorrower(pSearchDtls) {
    setObjSearch(pSearchDtls);
    setLoader("block")
    setLstBook([]);
    searchBookBorrowerSvc(pSearchDtls, searchBookBorrowerCallback);
  }
  function searchBookBorrowerCallback(data) {
    setLoader("none");
    if (data.SvcStatus === "Success") {
      setLstBook(data.lstBook);
      toast.success(data.SvcMsg);
    } else {
      if (data.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (data.SvcMsg === "Your Session is expired, login again") {
        toast.error(data.SvcMsg);
        // console.log("session expired");
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(data.SvcMsg);
      }
    }
  }
  
  return (
    <div
      className="MainContainer"
      style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <Header />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className="col-sm-9 col-centered" style={{ marginTop: "2%" }}>
        <div className="col-sm-11" style={{ height: "100%" }}>
          <BookBorrowerSearchBar searchBookBorrower={searchBookBorrower} />
        </div>
        <div
          className="col-sm-11"
          style={{
            border: "1px solid #aaa",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            height: "420px",
            marginTop: 20,
            marginLeft: "4%",
          }}
        >
          <IssuedBookTable
            key={tableKey}
            setLoader={setLoader}
            lstBook={lstBook}
            setObjBorrowerDtls={setObjBorrowerDtls}
            
          />
          
        </div>
      </div>

    </div>
  );
};

export default ReturnCtrl;
