import React, { useState, useEffect } from 'react'
import lstModules from 'common/json/lstModules.json'
import axios from 'axios'
import 'common/css/master.css'
import Header from 'common/js/header/header'
import SetupHeader from './SetupHeader'
import { toast, ToastContainer } from 'react-toastify'
import { getSetupSvc } from './SetupCtrlSvc'

var httpURL = lstModules['main'].url + ':' + lstModules['main'].port
let queryString = require('querystring')
var lstSetup = []
var lstSetupGeneral = [
  /*   {
    label: 'Wings',
    remarks:
      'Wings are the base of your school. You can use wings to divide your school into multiple operational systems. Classes, Transport, Schedule, Timetable, Reminders, etc are confined to Wings',
    link: '/wings',
    setup: 1
  },
  {
    label: 'Academic Year',
    remarks:
      'Academic Calendar, Academic Sessions, Fees, Exams, Timetables, Periods are based on Academic Years',
    link: '/academicYear',
    setup: 0
  },
  {
    label: 'Academic Sessions',
    remarks:
      'Academic Calendar, Academic Sessions, Fees, Exams, Timetables, Periods are based on Academic Years',
    link: '/academicSchedule',
    setup: 0
  },
  {
    label: 'Subjects',
    remarks:
      'Subjects are used in timetable, syllabus, exams and almost everywhere.',
    link: '/subject',
    setup: 0
  },
  {
    label: 'Syllabus',
    remarks:
      'Syllabus is associated with classes. Each syllabus has a list of Subjects available.',
    link: '/syllabus',
    setup: 0
  },
  {
    label: 'Classes',
    remarks:
      'Students can be added to the system only when classes are defined',
    link: '/classes',
    setup: 0
  },
  {
    label: 'Staff',
    remarks: 'Setup the staff members for your school',
    link: '/staffctrl',
    setup: 0
  },
  {
    label: 'Students',
    remarks: 'Setup the students for your school',
    link: '/student',
    setup: 0
  } */
]
var lstSetupFee = [
  /* {
    label: 'Fee Labels',
    remarks:
      'Labels define the key areas for which Fees can be collected. Also define the Collection Order which prioritizes the fees to be collection.  Eg, you may prioritize Physics Lab Fees over Chemistry Lab fees.',
    link: '/label',
    setup: 1
  },
  {
    label: 'Fee Notifications',
    remarks:
      'Due dates, reminder dates and notification dates along with communication mode to be defined.',
    link: '/feeDue',
    setup: 0
  },
  {
    label: 'Fee Heads',
    remarks:
      'Fee Heads are with fee labels. All fee heads need to be defined before any fee can be collected. They define the fee to be annual, half-yearly, quarterly or monthly. Also define if late fee to be charged for respective heads. Discounts can also be specified.',
    link: '/feeHeads',
    setup: 0
  },
  {
    label: 'Class Fee',
    remarks:
      'This is where you select the applicable Fee Heads for specific classes. It helps you show only applicable fee heads for the students of respective classes.',
    link: '/classFee',
    setup: 0
  },
  {
    label: 'Student Fee',
    remarks:
      'Customized fees for students need to be defined here. Student specific discounts or special fees to be defined here.',
    link: '/studentFees',
    setup: 0
  } */
]
var lstSetupTimeTable = [
  /* {
    label: 'Syllabus',
    remarks:
      'Each Class is associated with a syllabus. This helps in determining the coverage in a given timeframe. This also helps in maintaining the Teacher’s performance.',
    link: '/syllabus',
    setup: 1
  },
  {
    label: 'Class Syllabus',
    remarks: 'Associate syllabus with respective classes.',
    link: '/clssyb',
    setup: 0
  },
  {
    label: 'Teacher’s Eligibility',
    remarks:
      'Define teacher’s eligibility for specific classes, subjects along with efficiency. This allows that only the eligible teachers are allocated for handling the classes.',
    link: '/staffctrl',
    setup: 0
  },
  {
    label: 'Subject Allocation',
    remarks:
      'Timetable can be created based on syllabus coverage. Timetable are published for each class to share with the students.',
    link: '/ttsub',
    setup: 0
  },
  {
    label: 'Teacher Allocation',
    remarks:
      'Timetable can be created based on teachers’ eligibility and availability. Timetable are published to share with the teachers.',
    link: '/tttch',
    setup: 0
  } */
]

export default function SetupCtrl () {
  const [state, setState] = useState({
    wingId: '',
    loader: 'none',
    opacity: 1,
    tokenid: localStorage.getItem('tokenid'),
    setupId: 0,
    setupObj: ''
  })

  function getSetup () {
    showLoader()

    getSetupSvc(handleLstSetupResponse)
  }

  function showLoader () {
    setState({ ...state, loader: 'block', opacity: 0.5 })
  }
  function hideLoader () {
    setState({ ...state, loader: 'none', opacity: 1 })
  }
  function handleLstSetupResponse (data) {
    var obj = data
    lstSetup = obj.lstTab
    // console.log(lstSetup.length)
    if (0 < lstSetup.length) lstSetupGeneral = obj.lstTab[0].lstDtls
    if (1 < lstSetup.length) lstSetupFee = obj.lstTab[1].lstDtls
    if (2 < lstSetup.length) lstSetupTimeTable = obj.lstTab[2].lstDtls
    // console.log(obj.lstTab[0].lstDtls)

    hideLoader()
    if (obj.status === 'Success') {
      // setState({ ...state, lstState: obj.lstState });
      // getSchoolProfile(handleSchoolProfileResponse);
    } else if (obj.status === 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(data.message)
      }
    } else {
      toast.error('Service failed' + obj)
    }
  }

  function setSetupId (pSetupId) {
    setState({ ...state, setupId: pSetupId })
  }
  useEffect(() => {
    // getStateList();
    // getProfile();
    getSetup()
  }, [])

  return (
    <div style={{ height: '100%' }}>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: 'none'
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <div className='MainContainer' style={{ opacity: state.opacity }}>
        <div className='col-md-12 col-centered wingsWrap clearfix'>
          <SetupHeader
            setupId={state.setupId}
            setSetupId={setSetupId}
            lstSetup={lstSetup}
          />
        </div>
        <div className='col-sm-10 col-centered' style={{ marginTop: 15 }}>
          <div className=''>
            {lstSetup.map((obj, idx) => {
              return (
                <div
                  key={idx}
                  className='tableLayout1'
                  style={{ display: 'table' }}
                >
                  {state.setupId === idx && obj.lstDtls.length !== 0
                    ? obj.lstDtls.map((obj, subIdx) => {
                        return (
                          <a
                            key={subIdx}
                            href={obj.link}
                            className='col-sm-12 padding-remove'
                            style={{
                              display: 'flex',
                              minHeight: '50',
                              justifyContent: 'center'
                            }}
                          >
                            <div
                              style={{
                                fontWeight: 'bold',
                                margin: 'auto 0',
                                float: 'left',
                                textAlign: 'left',
                                width: '13%',
                                padding: '5px'
                              }}
                            >
                              <span>{obj.label}</span>
                            </div>
                            <div
                              style={{
                                float: 'left',
                                textAlign: 'left',
                                width: '5%',
                                padding: '5px'
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 50,
                                  color: obj.setupCompleted
                                    ? '#6aa84f'
                                    : '#ff0000'
                                }}
                                className='fa fa-angle-double-right'
                              ></span>
                            </div>
                            <div
                              style={{
                                fontSize: 12,
                                margin: 'auto 0',
                                float: 'left',
                                textAlign: 'left',
                                width: '55%',
                                padding: '5px'
                              }}
                            >
                              <span>{obj.remarks}</span>
                            </div>
                          </a>
                        )
                      })
                    : ''}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )

  function getWingId (wingId) {
    // console.log('wingId' + wingId)
  }
}
