import React, { useEffect, useState, Fragment } from 'react';
import { Document, Page, PDFViewer, View, Text, Image, Font } from '@react-pdf/renderer';


import { pageStyle, textStyle, headerStyle, styleHeaderLogo, styleOMR } from './styleAnsweSheetPDF';


Font.register({
    family: 'Calibri'
    , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
        , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});

const AnswerSheedPDF = (props) => {
    const { logoUrl, noOfResponses, rowsPerColumn, noOfColumns } = props;


    useEffect(() => {
        // console.log("useEffect in AnswerSheedPDF called, noOfResponses", noOfResponses, ", rowsPerColumn", rowsPerColumn, ", noOfColumns", noOfColumns);
    }, [noOfResponses, rowsPerColumn, noOfColumns]);


    const getRow = (pQuesNo) => {
        // console.log("getRow called, pQuesNo", pQuesNo);
        return (
        <View key={pQuesNo} style={styleOMR.row}>
            <Text style={styleOMR.questionNumber}>{pQuesNo}</Text>
            <View style={styleOMR.circleContainer}>
                {['A', 'B', 'C', 'D'].map((option) => (
                    <View key={option} style={[styleOMR.circle]}>
                        <Text style={styleOMR.circleText}>{option}</Text>
                    </View>
                ))}
            </View>
        </View>
        );
    }

    const getColumn = (i) => {
        // console.log("getColumn called, i", i, ", rowsPerColumn", rowsPerColumn);
        let quesNo = i*rowsPerColumn;
        const rows = [];
        for (let j = 0; j< rowsPerColumn; j++){
            if (j+quesNo === noOfResponses ){
                break;
            }
            const row = getRow(j+quesNo+1);
            rows.push(row);
        }
        return rows;
    }

    // Split rows into columns
    const columns = [];
    for (let i = 0; i < noOfColumns; i++) {
        const height = Math.min(rowsPerColumn, noOfResponses - i * rowsPerColumn) * 21 + "px";
        const column = getColumn(i);
        columns.push(
            <View key={i} style={[styleOMR.column, {height:height}]}>
                {column}
            </View>
        );
    }



    return (
        <div className='col-sm-12 padding-remove' style={{ margin: "5px" }}>
            <PDFViewer style={{ width: "calc(100% - 10px)", height: "78vh", overflowY: "scroll" }}>
                <Document >
                    <Page size="A4" style={pageStyle.page} wrap>
                        <View style={pageStyle.fullPage}>
                            <View style={pageStyle.pageHeader} >
                                <View style={headerStyle.row}>
                                    <View style={headerStyle.leftSection}>
                                        <View style={headerStyle.column}>
                                            <View style={headerStyle.row}>
                                                <View style={headerStyle.viewLeftLabel}>
                                                    <Text style={textStyle.textLeftMedium}>
                                                        {"Name: "}
                                                    </Text>
                                                </View>
                                                <View style={headerStyle.vieLeftValue}>
                                                    <Text style={textStyle.textLine}>
                                                        {"_________________________"}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={headerStyle.row}>
                                                <View style={headerStyle.viewLeftLabel}>
                                                    <Text style={textStyle.textLeftMedium}>
                                                        {"Class: "}
                                                    </Text>
                                                </View>
                                                <View style={headerStyle.vieLeftValue}>
                                                    <Text style={textStyle.textLine}>
                                                        {"_________________________"}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={headerStyle.row}>
                                                <View style={headerStyle.viewLeftLabel}>
                                                    <Text style={textStyle.textLeftMedium}>
                                                        {"Test: "}
                                                    </Text>
                                                </View>
                                                <View style={headerStyle.vieLeftValue}>
                                                    <Text style={textStyle.textLine}>
                                                        {"_________________________"}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={headerStyle.midSection}>
                                        <View style={headerStyle.viewCenter}>
                                            <Image style={styleHeaderLogo.image1} src={logoUrl} />
                                        </View>
                                    </View>
                                    <View style={headerStyle.rightSection}>
                                        <View style={headerStyle.column}>
                                            <View style={headerStyle.row}>
                                                <View style={headerStyle.viewRightLabel1}>
                                                    <Text style={textStyle.textLeftMedium}>
                                                        {"Date:"}
                                                    </Text>
                                                </View>
                                                <View style={headerStyle.viewRightValue1}>
                                                    <Text style={textStyle.textLine}>
                                                        {"__________________"}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={headerStyle.row}>
                                                <View style={headerStyle.viewRightLabel1}>
                                                    <Text style={textStyle.textLeftMedium}>
                                                        {"Max Score:"}
                                                    </Text>
                                                </View>
                                                <View style={headerStyle.viewRightValue1}>
                                                    <Text style={textStyle.textLine}>
                                                        {"_________________"}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={headerStyle.row}>
                                                <View style={headerStyle.viewRightLabel}>
                                                    <Text style={textStyle.textLeftMedium}>
                                                        {"Score Obtained:"}
                                                    </Text>
                                                </View>
                                                <View style={headerStyle.viewRightValue}>
                                                    <Text style={textStyle.textLine}>
                                                        {"____________"}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={pageStyle.horizontalBar2} fixed></View>
                                <View style={pageStyle.pageContent}>
                                    {columns}
                                </View>
                            </View>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </div>
    );
}

export default AnswerSheedPDF;