import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { saveTermSvc, deleteTermSvc } from "./termFormSvc";
// import './termForm.css';
// import {getlstTermSvc,saveTermDtlsSvc,deleteTermDtlsSvc } from './TermSvc'

const TermForm = (props) => {
  const [term, setTerm] = useState("");
  const [termId, setTermId] = useState(0);
  const [termObj, setTermObj] = useState({});
  const [weight, setWeight] = useState(0);
  const [order, setOrder] = useState(0);

  useEffect(() => {
    // console.log(JSON.stringify(props.termObj));
    setTermId(props.termObj.termId);
    setTerm(props.termObj.term);
    setWeight(props.termObj.weight);
    setOrder(props.termObj.termOrder);
  }, [
    props.termObj.termId,
    props.termObj.term,
    props.termObj.weight,
    props.termObj.termOrder,
  ]);

  function handleChangeWeight(pWeight) {
    setWeight(pWeight);
  }

  function handleChangeOrder(pOrder) {
    setOrder(pOrder);
  }

  function handleChangeTerm(pTerm) {
    setTerm(pTerm);
  }

  function saveTerm() {
    if (!term) {
      toast.warn("Please enter term");
      return;
    }
    if (!weight) {
      toast.warn("Please enter weight");
      return;
    }

    props.setFormLoader("block");
    // console.log("termId: " + termId);
    // console.log("weight: " + weight);
    // console.log("termOrder: " + order);
    // console.log("term: " + term);

    var vTermObj = {
      termId: termId,
      termOrder: parseInt(order),
      weight: parseInt(weight),
      term: term,
    };

    saveTermSvc(vTermObj, cbTermResponse);
  }

  function cbTermResponse(data) {
    props.setFormLoader("none");
    // console.log(data);
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      props.refreshTable();
      var vTermId = termId;
      if (!vTermId) {
        vTermId = data.termId;
      }
      var tmpTerm = {
        termId: vTermId,
        term: term,
        weight: parseInt(weight),
        termOrder: parseInt(order),
      };
      // console.log(tmpTerm);
      props.rowClick(tmpTerm);
    } else {
      toast.error(data.SvcMsg);
    }
  }

  function deleteTerm() {
    var confirmed = window.confirm("Are you sure to delete the term?");
    if (confirmed) {
      props.setFormLoader("block");
      deleteTermSvc(termId, cbDeleteTermResponse);
    }
  }
  function cbDeleteTermResponse(data, termId) {
    props.setFormLoader("none");
    // console.log(data);
    if (data.status === "Success") {
      toast.success(data.message);
      props.refreshTable();
      props.rowClick({ termId: undefined, term: "", weight: 0, termOrder: 0 });
    } else {
      toast.error(data.message);
    }
  }

  function cancelTerm() {
    // console.log(props.termObj);
    if (!termId || termId == 0) {
      setTermObj({});
      setTermId(0);
      setTerm("");
      setOrder(0);
      setWeight(0);
    } else {
      setTermObj(props.termObj);
      setTermId(props.termObj.termId);
      setTerm(props.termObj.term);
      setOrder(props.termObj.termOrder);
      setWeight(props.termObj.weight);
    }
  }
  return (
    <>
      <form id="termForm" className="form-horizontal formWrapper">
        <div className="form-group">
          <label className="control-label col-sm-5 text-left">Term</label>
          <div className="col-sm-6 padding-remove">
            <input
              value={term}
              autoComplete="off"
              name="term"
              id="term"
              type="text"
              className="form-control "
              placeholder="Enter Term"
              onChange={(e) => handleChangeTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="control-label col-sm-5 text-left">Weight</label>
          <div className="col-sm-6 padding-remove">
            <input
              value={!!weight ?  weight : ""}
              autoComplete="off"
              name="weight"
              id="weight"
              type="number"
              className="form-control text-right"
              placeholder="Enter Weight"
              onChange={(e) => handleChangeWeight(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-5 text-left">Order</label>
          <div className="col-sm-6 padding-remove">
            <input
              value={!!order ? order : ""}
              autoComplete="off"
              name="order"
              id="order"
              type="number"
              className="form-control text-right"
              placeholder="Enter Term Order"
              onChange={(e) => handleChangeOrder(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group">
          <div
            className="col-sm-12 text-center userButtonWrap"
            style={{ marginTop: 20 }}
          >
            <button
              type="button"
              title="Save"
              onClick={() => saveTerm()}
              className="btn btn-info"
            >
              Save
            </button>
            <button
              type="button"
              title="Cancel"
              onClick={() => cancelTerm()}
              className="btn btn-info"
            >
              Cancel
            </button>
            <button
              type="button"
              title="Delete"
              onClick={() => deleteTerm()}
              className="btn btn-danger deleteButton"
            >
              Delete
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default TermForm;
