import { getSyllabusList } from "sms/tt/syllabus/syllabusServices";
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import Syallbus from "./teacherSyllabusFormSubjects";
import { getClassList, saveClassList } from "./teacherSyllabusService";
// import { Col } from "reactstrap";
const TeacherSyllabusForm = (props) => {
  var initialState = {
    loader: 'none',
    opacity: 1,
  };
  const [state, setState] = useState(initialState);
  const [subjects, setAllSubject] = useState([]);
  const [teacher, setTeacher] = useState();
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [allClasses, setAllClasses] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [newClassList, setNewClassList] = useState([]);
  // const [loading, setLoading] = useState(true);

  const newClassListRef = useRef([]);

  useEffect(() => {
    const tchr = { ...props.teacher };
    tchr["allsections"] = false;
    setTeacher(tchr);
    setAllClasses([]);
    setNewClassList([]);
    setSelectedSubject(null);
    showLoader();
    newClassListRef.current = [];
    getClassList(props.teacher.tchId, handleClassList);
  }, [props.teacher]);

  function handleClassList(data) {
    if (data.SvcStatus === "Success") {
      setAllClasses(data.lstTchClsSec);
      // setLoading(false);
      hideLoader();

      /* data.lstTchClsSec.map((item, index) => {
        const checked = teacher.class_Section
          .split(",")
          .map((item) => item.replace("-", "").toUpperCase())
          .includes((item.cls + item.section).toUpperCase());

        if (checked) {
          newClassListRef.current.push(index);
          setNewClassList([...newClassList, index]);
        }
      }); */
    } else {
      toast.error(data.SvcMsg);
      hideLoader();
      // setLoading(false);
      setErrorMsg(data.SvcMsg);
    }
  }

  function addSection(item, index, e) {
    // console.log(e.target.checked)
    // console.log('idx = ' + index);
    setSelectedSubject(index);
    let tchObj = {
      tchId: teacher.tchId,
      classId: item.classId,
      sectionId: item.sectionId,
    };
    tchObj.selected = e.target.checked;
    saveClassList(tchObj, handleSaveClassList);
    /* if (newClassList.includes(index)) {
      setNewClassList(newClassList.filter((item) => item != index));
      newClassListRef.current = newClassListRef.current.filter(
        (item) => item != index
      );

      tchObj.selected = 0;
      saveClassList(tchObj, handleSaveClassList);
    } else {
      tchObj.selected = 1;
      saveClassList(tchObj, handleSaveClassList);
      setNewClassList([...newClassList, index]);
      newClassListRef.current.push(index);
    } */

  }
  function handleSaveClassList(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);

    } else {
      toast.error(data.SvcMsg);
      setErrorMsg(data.SvcMsg);
    }
  }

  async function addAllSections(e) {
    let inputs = document.querySelectorAll("input[type=checkbox]")
    inputs.forEach(item => {
      item.checked = e.target.checked
    })
    if (allClasses.length == newClassList.length) {
      newClassListRef.current = [];
      setNewClassList([]);

      await Promise.all(
        allClasses.map(
          async (item) =>
            await saveClassList(
              {
                tchId: teacher.tchId,
                classId: item.classId,
                selected: e.target.checked,
                sectionId: item.sectionId,
              },
              handleSaveClassList
            )
        )
      );
    } else {
      setNewClassList(allClasses.map((item, index) => index));
      newClassListRef.current = allClasses.map((item, index) => index);
      await Promise.all(
        allClasses.map(
          async (item) =>
            await saveClassList(
              {
                tchId: teacher.tchId,
                classId: item.classId,
                selected: e.target.checked,
                sectionId: item.sectionId,
              },
              handleSaveClassList
            )
        )
      );
    }
    getClassList(props.teacher.tchId, handleClassList);
  }
  function showLoader() {
    setState({ ...state, loader: 'block', opacity: 0.5 });
  }
  function hideLoader() {
    setState({ ...state, loader: 'none', opacity: 1 });
  }
  function getSyb(item, index, e) {
    getSyllabusList(props.teacher.tchId, item.classId, item.sectionId, handleGetSyallbus);
    setSelectedSubject(index);
  }
  function handleGetSyallbus(data) {
    if (data.SvcStatus === "Success") {
      setAllSubject(data.lstSubject);
      hideLoader()
    } else {
      hideLoader();
      toast.error(data.SvcMsg);
      setErrorMsg(data.SvcMsg);
    }
  }



  return (
    <React.Fragment>
      <div className="col-sm-12 col-lg-3 col-md-3 col-lg-4 center-block d-flex justify-content-center">
        <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: state.loader }}>
          <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
        </div>
        <div className="container-fluid mainContainer" style={{ opacity: state.opacity }}>


          <div className="teacher-detail">
            <div className="card" style={{ height: '80vh' }}>
              <div className="teacher-form-main">
                {teacher && (
                  <div className="teacher-form-table">
                    <p className="para">
                      Teacher{" "}
                      <span
                        style={{
                          float: "right",
                          borderBottom: "1px solid #9b9b9b",
                        }}
                      >
                        {teacher.tch}
                      </span>
                    </p>
                    <p className="para">
                      Role{" "}
                      <span
                        style={{
                          float: "right",
                          borderBottom: "1px solid #9b9b9b",
                        }}
                      >
                        {teacher.role}
                      </span>
                    </p>
                  </div>
                )}
              </div>

              <div id="table-wrapper">
                <div id="table-responsive">
                  <table cellSpacing="0" cellPadding="0" className="tableLayout1">
                    <thead>
                      <tr>
                        <th className="text-center">All Classes</th>
                        <th className="text-center">
                          <input
                            type={"checkbox"}
                            className="type-checkbox"
                            onClick={(e) => addAllSections(e)}
                          />
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <div className="tableDiv" style={{ height: '63vh' }}>
                    <table id="clstable" className="tableLayout">
                      <tbody className="teacher-class-table">
                        {allClasses.map((item, index) => {
                          const clas =
                            index == selectedSubject
                              ? "selected"
                              : "";

                          return (
                            <tr className={clas} key={index} onClick={(e) => getSyb(item, index, e)}>
                              <td className="text-center">
                                {item.cls}-{item.section}
                              </td>
                              <td className="text-center">
                                <input
                                  type={"checkbox"}
                                  className="type-checkbox"
                                  defaultChecked={item.selected}
                                  onChange={(e) => addSection(item, index, e)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedSubject != null && (
        <div className="col-sm-12 col-lg-4">
          <Syallbus
            subjects={subjects}
            selectedSubject={allClasses[selectedSubject]}
            tchId={props.teacher.tchId}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default TeacherSyllabusForm;
