import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getLabelListSvc } from './LabelSvc';

const LabelTable = (props) => {
    const [lstLabel, setLstLabel] = useState([])
    const [labelId, setLabelId] = useState(0)
    const [loader, setLoader] = useState('none')
    const [selLabelId, setSelLabelId] = useState(0)
    const [labelObj, setLabelObj] = useState({})
    useEffect(() => {
        setLoader('block')
        let isMounted = true;
        getLabelListSvc(handleLabelResponse)
    }, [props.randNo]);
    function handleLabelResponse(data) {
        setLstLabel(data.lstLabel)
        setLoader('none')
    }

    async function editLabel(lbl) {
        // console.log(labelId)
        for (var i = 0; i < lstLabel.length; i++) {
            if (lstLabel[i].labelId === lbl) {
                props.setLabelforEdit(lstLabel[i].labelId, lstLabel[i])
                break;
            }
        }

    }

    function handleSelLabelId(param) {
        setSelLabelId(param)
    }
    const colgrp = <colgroup>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
    </colgroup>
    return (
        <div style={{ pointerEvents: loader === "none" ? "auto" : "none", marginTop: 15 }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>

            <table className='tableLayout1'>
                {colgrp}
                <thead>
                    <tr>
                        <th>Label Id</th>
                        <th className='text-left'>Fees Label</th>
                        <th>Display Order</th>
                        <th>In Use</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </table>
            <div className='tablebodyWrapper'>
                <table className='tableLayout'>
                    {colgrp}
                    <tbody>{
                        lstLabel.map((label, idx) => {
                            return <tr onClick={(e) => handleSelLabelId(label.labelId)} className={label.labelId === selLabelId ? "selected" : ""}
                                key={idx} >
                                <td>{label.labelId}</td>
                                <td className='text-left'>{label.label}</td>
                                <td>{label.paymentPriority}</td>
                                <td>{label.flgInUse ? 'Yes' : '-'}</td>
                                <td className="text-center">
                                    <span title="edit" className="glyphicon glyphicon-edit" onClick={(e) => editLabel(label.labelId)}></span>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>

        </div>
    );
};


export default LabelTable;
