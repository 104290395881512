import React, { useEffect, useState } from 'react';
import { Text, View, Font } from '@react-pdf/renderer';
import { StyleSheet } from '@react-pdf/renderer';

import { toast } from "react-toastify";
import { styleSchoScore, } from './reportCardStyles.js';

Font.register({
    family: 'Calibri'
    , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
        , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});


const ScholasticSection = (props) => {
    const { schoScore } = props;

    const [mainCols, setMainCols] = useState(0);
    const [OverallCols, setOverallCols] = useState(0);

    useEffect(() => {
        // console.log("scoreSection.js called, schoScore", schoScore);

    }, [schoScore]);

    const getFlexByTermId = (tId) => {
        // Use find() to efficiently locate the matching term
        const matchingTerm = schoScore.lstTermHeader?.find(term => term.termId === tId);

        // Return the calculation only if a matching term is found
        return matchingTerm ? 3 / matchingTerm.lstExam.length : 1;
    };

    return (
        <View style={styleSchoScore.container}>
            {
                !!schoScore ?
                    <View style={styleSchoScore.score_table}>
                        <View style={[styleSchoScore.score_head, { height: "60px" }]}>
                            <View style={[styleSchoScore.exam_row, { flex: 2 }]}>
                                <View style={styleSchoScore.subject_col}>
                                    <View style={[styleSchoScore.top_left_cell, { flex: 1 }]}>
                                    </View>
                                </View>
                                <View style={styleSchoScore.mark_col}>
                                    {
                                        schoScore.lstTermHeader && schoScore.lstTermHeader.length ?
                                            schoScore.lstTermHeader.map((item, idx) => (
                                                <View key={idx} style={[styleSchoScore.top_cell, { flex: item.termId != -1 ? 3 : 4 }]}>
                                                    <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                                        {item.term}({item.totalScore}<Text style={{ textTransform: "capitalize" }}>{' Marks'}</Text>)
                                                    </Text>
                                                </View>
                                            )) : null
                                    }
                                </View>
                            </View>
                            <View style={[styleSchoScore.exam_row, { flex: 2 }]}>
                                <View style={styleSchoScore.subject_col}>
                                    <View style={[styleSchoScore.left_cell, { flex: 1 }]}>
                                        <Text style={[styleSchoScore.text_schoExam_label]}>SUBJECTS</Text>
                                    </View>
                                </View>
                                <View style={styleSchoScore.mark_col}>
                                    {schoScore.lstExamHeaderScho && schoScore.lstExamHeaderScho.length ?
                                        schoScore.lstExamHeaderScho.map((item, idx) => (
                                            <View key={idx} style={[styleSchoScore.inner_cell, { flex: (item.termId != -1) ? (getFlexByTermId(item.termId)) : 1 }]}>
                                                <Text style={styleSchoScore.text_schoExam_label}>
                                                    {item.exam}<Text style={{ textTransform: "capitalize" }}></Text>
                                                </Text>
                                            </View>
                                        )) :
                                        <View style={[styleSchoScore.inner_cell, { flex: 1 }]}>
                                        </View>
                                    }
                                </View>
                            </View>
                        </View>
                        <View style={[styleSchoScore.score_rows, { flex: schoScore.lstSubjectScore.length + 1 }]}>
                            {schoScore.lstSubjectScore && schoScore.lstSubjectScore.length ?
                                schoScore.lstSubjectScore.map((subject, idx) => (
                                    <View key={idx} style={[styleSchoScore.score_row, { flex: 1 }]}>
                                        <View style={styleSchoScore.subject_col}>
                                            <View style={[styleSchoScore.left_cell, { flex: 1 }]}>
                                                <Text style={styleSchoScore.text_subject_label}>
                                                    {subject.sub.subject}<Text style={{ textTransform: "capitalize" }}></Text>
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={styleSchoScore.mark_col}>
                                            {subject.lstScore && subject.lstScore.length > 1 ?
                                                subject.lstScore.map((score, index) => (
                                                    <View key={index} style={[styleSchoScore.inner_cell, { flex:  (schoScore.lstExamHeaderScho[index]?.termId > 0) ? (getFlexByTermId(schoScore.lstExamHeaderScho[index]?.termId)) : 1  }]}>
                                                        <Text style={[styleSchoScore.text_center, styleSchoScore.text_value]}>{score}</Text>
                                                    </View>
                                                )) :
                                                subject.lstScore && subject.lstScore.length === 1 ?
                                                    subject.lstScore.map((score, index) => (
                                                        <View key={index} style={[styleSchoScore.inner_cell, { flex: 1 }]}>
                                                            <Text style={[styleSchoScore.text_left, styleSchoScore.text_value]}>{score}</Text>
                                                        </View>
                                                    )) :
                                                    <View style={[styleSchoScore.inner_cell, { flex: '1' }]}>
                                                    </View>
                                            }
                                        </View>
                                    </View>
                                )) : null
                            }

                        </View>
                    </View >
                    : null
            }

        </View >
    );
}

export default ScholasticSection;


{/* <View style={[styleSchoScore.score_row, { flex: 1 }]}>
<View style={styleSchoScore.subject_col}>
<View style={[styleSchoScore.left_cell, { flex: 1 }]}>
<Text style={styleSchoScore.text_subject_label}>TOTAL</Text>
        </View>
    </View>
    <View style={styleSchoScore.mark_col}>
        {
            lstTotal.map((score, index) => (
                <View key={index} style={[styleSchoScore.inner_cell, { flex: 2 }]}>
                    <Text style={[styleSchoScore.text_center, styleSchoScore.text_value]}>{score}</Text>
                </View>
            ))
        }
        <View style={[styleSchoScore.inner_cell, { flex: 8 }]}>
        </View>
    </View>
</View> */}

{/* <View style={[styleSchoScore.scho_bottom_rows, { flex: 2 }]}>
    <View style={[styleSchoScore.exam_row, { flex: 1 }]}>
        <View style={styleSchoScore.subject_col}>
            <View style={[styleSchoScore.left_cell, { flex: 1 }]}>
                <Text style={styleSchoScore.text_subject_label}>RANK</Text>
            </View>
        </View>
        <View style={styleSchoScore.mark_col}>
            <View style={[styleSchoScore.inner_cell, { flex: 1 }]}>
                <Text style={[styleSchoScore.text_left, styleSchoScore.text_value]}>
                </Text>
            </View>
        </View>
    </View>
    <View style={[styleSchoScore.exam_row, { flex: 1 }]}>
        <View style={styleSchoScore.subject_col}>
            <View style={[styleSchoScore.left_cell, { flex: 1 }]}>
                <Text style={styleSchoScore.text_subject_label}>ATTENDANCE</Text>
            </View>
        </View>
        <View style={styleSchoScore.mark_col}>
            <View style={[styleSchoScore.inner_cell, { flex: 1 }]}>
                <Text style={[styleSchoScore.text_left, styleSchoScore.text_value]}>
                </Text>
            </View>
        </View>
    </View>
</View> */}