import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../css/studentTest.css';
import '../css/question.css';
import Question from "./question";
import Options from "./options";
import Answer from "./answer";
import Students from "./students";
import { getQuestionSvc, studentListSvc, submitAnswerSvc } from "./questionSvc";

const TestQuestion = () => {


    const params = new URLSearchParams(window.location.search);
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [studentId, setStudentId] = useState(0);
    const [testId, setTestId] = useState(0);
    const [quesObj, setQuesObj] = useState({});
    const [questionId, setQuestionId] = useState(0);
    const [optionSelected, setOptionSelected] = useState("");
    const [question, setQuestion] = useState({});
    const [lstStudent, setLstStudent] = useState([]);
    const [lstOptions, setLstOptions] = useState([]);
    const [flgAnswered, setFlgAnswered] = useState(false);
    // const [flgAttempted, setFlgAttempted] = useState(false);
    // const [flgOptionType, setFlgOptionType] = useState(0);
    const [schoolId, setSchoolId] = useState(0);
    const [leaderboardCount, setLeaderboardCount] = useState(0);




    useEffect(() => {
        // console.log("TestQuestion called");
        const sId = params.get("sId");
        const tId = params.get("tId");
        const schId = params.get("schId");
        const rankers = params.get("rankers");
        setStudentId(sId);
        setTestId(tId);
        setSchoolId(schId);
        getQuestion(tId, sId, schId, rankers);
        setLeaderboardCount(rankers);

    }, []);

    const getQuestion = (tId, sId, schId, rankers) => {
        // console.log("getQuestion called, tId", tId, ", sId", sId, ", rankers", rankers);
        setLoader("block");
        setOpacity(0.5);
        getQuestionSvc(tId, sId, schId, rankers, questionResponse);
    }

    const questionResponse = async (data) => {
        // console.log("questionResponse called", data);
        let obj = data;
        if (obj.status === 'Success') {
            await setFlgAnswered(obj.qusObj.attempted);
            await setOptionSelected(obj.qusObj.ans);
            setQuesObj(obj.qusObj);
            getQuesDtls(obj.qusObj);
            // setFlgOptionType(obj.quesObj.optionType);

            callStudentList(obj.testId, obj.studentId, obj.schoolId, obj.qusObj.qusNo, obj.rankers );
        }
        else {
            toast.error(obj.message);
        }
        setLoader('none');
        setOpacity(1);
    }

    const submitAnswer = (pOption, pSchoolId) => {
        // console.log("submitAnswer called. pOption", pOption);
        if (pOption === "") {
            toast.warn("please select answer");
            return;
        }
        const answerObj = {
            "testId": testId,
            "studentId": studentId,
            "qusId": questionId,
            "optionSelected": pOption
        };
        setLoader("block");
        setOpacity(0.5);
        submitAnswerSvc(answerObj, pSchoolId, submitAnswerResponse);

    }

    const submitAnswerResponse = (data) => {
        // console.log("submitAnswerResponse called", data);
        let obj = data;
        if (obj.status === 'Success') {
            // toast.success(obj.message);
            setFlgAnswered(true);
        }
        else {
            toast.error(obj.message);
        }
        setLoader('none');
        setOpacity(1);
    }

    const callStudentList = (tId, sId, schId, qNo, rankers) => {
        // console.log("callGetStudent called, rankers", rankers);
        const interval = setInterval(() => {
            getStudentList(tId, sId, schId, qNo, rankers);
        }, 500);
        return () => clearInterval(interval);
    }

    const getStudentList = (tId, sId, schId, qNo, rankers) => {
        // console.log("getStudentList called, rankers", rankers);
        // setLoader("block");
        // setOpacity(0.5);
        studentListSvc(tId, sId, schId, qNo, rankers, studentListResponse);
    }

    const studentListResponse = (data) => {
        // console.log("studentListResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            if (obj.nextAction === 10) {
                setLstStudent(obj.lstStudent);
            } else {
                handleNextPage(obj.testId, obj.studentId, obj.qusNo, obj.nextAction, obj.schoolId, obj.rankers);
            }
        }
        else {
            toast.error(obj.message);
        }
    }

    const handleNextPage = (tId, sId, qusNo, pNextAction, pSchoolId, rankers) => {
        // console.log("handle nextPage called, pNextAction", pNextAction, ", rankers", rankers);
        // 10: Stay, 20: next qus, 30:leaderboard
        if (pNextAction === 30) {
            const newPageUrl = "/testLeaderBoard?tId=" + tId + "&sId=" + sId + "&qusNo=" + qusNo + "&schId=" + pSchoolId + "&rankers=" + rankers;
            window.location.href = newPageUrl;
        } else if (pNextAction === 20) {
            const newPageUrl = "/testQuestion?tId=" + tId + "&sId=" + sId+ "&schId=" + pSchoolId + "&rankers=" + rankers;
            window.location.href = newPageUrl;
        } else {
            // console.log("error in pNextAction");
        }
    }

    const getQuesDtls = async (pQusObj) => {
        // console.log("getQuestDtls called, pQusObj", pQusObj);
        if (!!pQusObj) {
            await setQuestionId(pQusObj.qusId);
            const tempQues = {
                "questionId": pQusObj.qusId,
                "question": pQusObj.qus,
                "questionImage": pQusObj.qusImg,
                "questionNo": pQusObj.qusNo,
            }
            await setQuestion(tempQues);
            let tempLstOptions = [];
            if (pQusObj.optionType === 20) {
                tempLstOptions = [
                    {
                        "optionKey": "A",
                        "option": pQusObj.opA,
                        "optionImage": pQusObj.opAImg
                    },
                    {
                        "optionKey": "B",
                        "option": pQusObj.opB,
                        "optionImage": pQusObj.opBImg
                    }
                ]
            } else {
                tempLstOptions = [
                    {
                        "optionKey": "A",
                        "option": pQusObj.opA,
                        "optionImage": pQusObj.opAImg
                    },
                    {
                        "optionKey": "B",
                        "option": pQusObj.opB,
                        "optionImage": pQusObj.opBImg
                    },
                    {
                        "optionKey": "C",
                        "option": pQusObj.opC,
                        "optionImage": pQusObj.opCImg
                    },
                    {
                        "optionKey": "D",
                        "option": pQusObj.opD,
                        "optionImage": pQusObj.opDImg
                    }
                ]
            }
            await setLstOptions(tempLstOptions);
        }
    }

    const handleChangeOption = (pOption) => {
        // console.log("handleChangeOptionCalled, pOption", pOption);
        setOptionSelected(pOption);
        const schId = params.get("schId");
        submitAnswer(pOption, schId);
    }

    return (
        <div className="col-sm-12 col-xs-12 padding-remove">
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="col-sm-11 col-xs-12 col-centered padding-remove" style={{ pointerEvents: loader === 'none' ? 'auto' : 'none', }}>
                <div className="col-sm-12 col-xs-12 padding-remove">
                    <div className="col-sm-10 col-xs-12 padding-remove" >
                        <div className="question-options">
                            <div className="col-sm-12 col-xs-12 padding-remove">
                                <Question
                                    key={questionId}
                                    questionId={questionId}
                                    question={question}
                                />
                            </div>
                            <div className="col-sm-12 col-xs-12 padding-remove">
                                <Options
                                    lstOptions={lstOptions}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2 col-xs-12 padding-remove" >
                        <div className="answer-avatars-block" >
                            {
                                flgAnswered ?
                                    <>
                                        <div className="answer-section-with-avatars">
                                            <Answer
                                                lstOptions={lstOptions}
                                                optionSelected={optionSelected}
                                                handleChangeOption={handleChangeOption}
                                                flgAnswered={flgAnswered}
                                            />
                                        </div>
                                        <div className="avatars-section">
                                            <Students
                                                lstStudent={lstStudent}
                                            />
                                        </div>
                                    </>
                                    :
                                    <div className="answer-section">
                                        <Answer
                                            lstOptions={lstOptions}
                                            optionSelected={optionSelected}
                                            handleChangeOption={handleChangeOption}
                                            flgAnswered={flgAnswered}
                                        />
                                    </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default TestQuestion;