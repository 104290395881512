import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css'
import { getVehicleTypeList, deleteVehicleType } from "./vehicleTypeSvc";
const VehicleTypeTable = (props) => {
    const [loader, setLoader] = useState("none");
    const [lstVehicleType, setLstVehicleType] = useState([])
    useEffect(() => {
        setLoader("block")
        getVehicleTypeList(cbVehicleTypeList)
    }, [])
    function cbVehicleTypeList(data) {
        // console.log(data);
        if (data.SvcStatus === 'Success') {
            setLstVehicleType(data.lstVehicleType)
        }
        else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
    function setVehicleType(param) {
        props.setVehicleTypeId(param)
    }
    function deleteVehicleTypeDtls(param) {
        deleteVehicleType(param, cbDeleteVehicleType)
    }
    function cbDeleteVehicleType(data) {
        if (data.SvcStatus === 'Success') {
            props.generateRandomNo()
            toast.success(data.SvcMsg)
        }
        else {
            toast.error(data.SvcMsg)
        }
    }
    const colgrp = <colgroup>
        <col width="25%"></col>
        <col width="25%"></col>
        <col width="25%"></col>
        <col width="25%"></col>
    </colgroup>
    return (
        <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <table className='tableLayout1'>
                {colgrp}
                <thead>
                    <tr>
                        <th>Vehicle Type</th>
                        <th>Seating Capacity</th>
                        <th>Max. Allowed</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </table>
            <div className='tablebodyWrapper'>
                <table className='tableLayout'>
                    {colgrp}
                    <tbody>
                        {
                            lstVehicleType.map((obj, idx) => {
                                return <tr key={idx}
                                    onClick={(e) => setVehicleType(obj.vehicleTypeId)} className={obj.vehicleTypeId === props.vehicleTypeId ? "selected" : ""}
                                >
                                    <td className="text-left">{obj.vehicleType}</td>
                                    <td>{obj.seatingCapacity}</td>
                                    <td>{obj.maxAllowed}</td>
                                    <td className="text-center">
                                        <span className="glyphicon glyphicon-edit" onClick={(e) => props.setVehicleTypeForEdit(obj.vehicleTypeId)}></span>
                                        <span className="glyphicon glyphicon-trash" onClick={(e) => deleteVehicleTypeDtls(obj.vehicleTypeId)}></span>
                                    </td>
                                </tr>
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default VehicleTypeTable;