import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StudentOtpPdf from "./studentOtpPDF";
import StaffOTPPDF from "./staffOTPPDF";
import LeaderboardPDF from "./leaderboardPDF";



const OtpPDFSlider = (props) => {
    const { testId, closeSlider, test, testDt, lstOtp, flgPDFStudent } = props;

    const [loaderSlider, setLoaderSlider] = useState("none");
    const [flgPDFType, setFlagPDFType] = useState(flgPDFStudent);        // 10 for students and 20 for staff/guest





    useEffect(() => {
        // console.log("OtpPDFSlider called");
        // console.log("flgPDFStudent passed", flgPDFStudent);
        // console.log("testId passed", testId);
        // console.log("test passed", test);
        // console.log("testDt passed", testDt);
        setFlagPDFType(flgPDFStudent);
    }, [testId, flgPDFStudent]);



    return (
        <div className="col-xs-12 col-centered padding-remove ">
            <div style={{ position: "fixed", zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '70%', top: '40%', display: loaderSlider }} >
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div >
            <ToastContainer autoClose={3000} hideProgressBar />
            <div>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={() => closeSlider()} style={{ right: "60%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <div className='col-sm-12 padding-remove' style={{ margin: 0 }}>
                    {
                        flgPDFType === 20 ?
                            <StaffOTPPDF
                                key={testId}
                                testId={testId}
                                test={test}
                                lstOtp={lstOtp}
                            />
                            :
                            (
                                flgPDFType === 30 ?
                                    <LeaderboardPDF
                                        key={testId}
                                        testId={testId}
                                        test={test}
                                        lstOtp={lstOtp}
                                    />
                                    :
                                    <StudentOtpPdf
                                        key={testId}
                                        testId={testId}
                                        test={test}
                                        lstOtp={lstOtp}
                                    />
                            )
                    }
                </div>
            </div>
        </div>
    );
}
export default OtpPDFSlider;