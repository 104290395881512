import axios from 'axios'
import $ from 'jquery'
import { toast, ToastContainer } from "react-toastify"
import lstModules from 'common/json/lstModules.json';

var httpURLTT = lstModules['timeTable'].url + ":" + lstModules['timeTable'].port;

var queryString = require('querystring');
var screenId = 33;
var tokenid = localStorage.getItem("tokenid");

function getClsSyb(setData) {
    // console.log("getClsSyb");
    axios({
        url: httpURLTT + "/sms/tt/v1/tt/cls/syb/list",
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log('data.SvcStatus=' + response.data.SvcStatus);
        setData(response.data);
    }).catch((error) => {
        setData(error);
    });
}

function getSyllabusList(callback2) {
    // console.log("getSyllabusList");
    axios({
        url: httpURLTT + '/sms/tt/v1/tt/syb/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log('data.SvcStatus=' + response.data.SvcStatus);
        callback2(response.data);
    }).catch((error) => {
        callback2(error);
    });
}

function getSubjectList(callback3, sybId) {
    // console.log("getSubjectList");
    // console.log("SybId : " + sybId)
    axios({
        url: httpURLTT + '/sms/tt/v1/tt/syb/sub/list',
        method: "POST",
        params: { screenId, sybId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log('data.SvcStatus=' + response.data.SvcStatus);
        callback3(response.data, sybId);
    }).catch((error) => {
        console.error(error);
    });
}

function saveSyllabus(clsSyb, handleSyllabusResponse) {
    // console.log(clsSyb);
    //clsSyb.sectionId = parseInt(clsSyb.sectionId);
    axios.post(httpURLTT + '/sms/tt/v1/tt/cls/syb/save',
        queryString.stringify({ 'clsSyb': JSON.stringify(clsSyb), screenId }), {

        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === "Success") {
            closeSlider()
            handleSyllabusResponse(response.data)
        }
        else {
            toast.error(response.data.SvcMsg);
        }
        //setState({...state, loader: 'none', opacity: 1 });
    }).catch((e) => {
        //setState({...state, loader: 'none', opacity: 1 })
        toast.error("Service failed " + e);
    });
}


function closeSlider() {
    $("body").removeClass("openSlider");
};

export { getClsSyb, getSyllabusList, getSubjectList, saveSyllabus }