import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL =  lstModules['syllabus'].url + ':' + lstModules['syllabus'].port + "/sms/syllabus/chapter";
const tokenid = localStorage.getItem('tokenid');


export const getChapterSvc = (sybSubId, cbLstChapter) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/list',
        method: "POST",
        params: { "sybSubId": sybSubId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log("response in getChapterSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstChapters= response.data.lstChapters;
        }
        cbLstChapter(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbLstChapter(obj);
    })
}

export const saveChapterSvc = (chapter, cbSaveChapter) => {
    // console.log("saveChapterSvc called, chapter", chapter);
    const obj = { status: "", message: "" };
    const formData = new FormData();
    formData.append("chapter", JSON.stringify(chapter));
    // console.log("url ", httpURL+ "/save");
    axios({
        url: httpURL + '/save',
        method: "POST",
        headers: { 'tokenid': tokenid },
        // params:{"chapter": JSON.stringify(chapter)},
        data: formData,
        withCredentials: true,
    }).then((response) => {
        // console.log("response in saveChapterSvc", response);
        // console.log("response.data in saveChapterSvc", response.data);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbSaveChapter(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSaveChapter(obj);
    })
}