import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['main'].url + ':' + lstModules['main'].port;
var tokenid = localStorage.getItem("tokenid");
var screenId = 8;

function getAddressSvc(callback) {
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/school/profile/addr/dtls',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.addr = response.data.addr;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            // console.log(obj);
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });

}
function getLatLongSvc(callback) {
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/school/profile/lat_long/fetch',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.latLong = response.data.latlong;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            // console.log(obj);
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });

}

function getIDCardDtlsSvc(callback) {
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/school/profile/id_card/dtls',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.idCard = response.data.idCard;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            // console.log(obj);
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });

}

function getReportCardDtlsSvc(callback) {
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/school/profile/reportcard/dtls',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.reportCard = response.data.reportCard;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            // console.log(obj);
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });

}

function getLstState(callback) {
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/mst/state/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.lstState = response.data.lstState;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            // console.log(obj);
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

function getLstCity(stateId, callback, cityId) {
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/mst/city/list',
        method: "POST",
        params: { 'stateId': stateId, screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.lstCity = response.data.lstCity;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            // console.log(obj);
            callback(obj, cityId)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}
function getLstLocality(pcityId, callBack, pLocalityId) {
    var status = 'Success'
    var obj = { status: status };


    axios(
        {
            url: httpURL + "/sms/mst/locality/list",
            method: "POST",
            params: { cityId: pcityId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((response) => {
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;
        if (response.data.SvcStatus === 'Success') {
            obj.lstLocality = response.data.lstLocality;
            // console.log(obj);
        }
        callBack(obj, pLocalityId);
    }
    ).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callBack(obj)
    });
}

function getSchoolProfile(callback) {
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/school/profile',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.schoolProfile = response.data.schoolProfile;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            // console.log(obj);
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

function getLogoSvc(callback) {

    var xhr = new XMLHttpRequest();
    xhr.open("GET", httpURL + '/sms/school/profile/logo/view', true);
    xhr.setRequestHeader('tokenid', tokenid);

    xhr.responseType = "arraybuffer";

    xhr.onload = function (e) {
        var arrayBufferView = new Uint8Array(this.response);
        var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        callback(imageUrl);
    };
    xhr.send();
}
function getFeeBookDtlsSvc(callback) {
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/school/profile/feebook/dtls',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;

        if (response.data.SvcStatus === 'Success') {
            obj.feebookObj = response.data.feebookObj;
            // console.log(obj);
        }
        callback(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });

}
function getEmailServerDtlsSvc(callback) {
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/school/profile/mail/dtls',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;

        if (response.data.SvcStatus === 'Success') {
            obj.mailDtls = response.data.mailDtls;
            // console.log(obj);
        }
        callback(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });

}

function getParentAppDtlsSvc(callback) {
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/school/profile/parentapp/dtls',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;

        if (response.data.SvcStatus === 'Success') {
            obj.parentDtls = response.data.parentApp;
            // console.log(obj);
        }
        callback(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });

}
function saveImgLogoSvc(file, callBack) {
    var formData = new FormData();
    formData.append("logo", file)


    axios({
        url: httpURL + "/sms/school/profile/logo/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        callBack(response.data)
    }).catch((e) => {
        console.error("Service failed " + e);
    });
}
function saveFeebookSvc(formData, cb) {
    var obj = { status: "Success", message: "" };
    axios(
        {
            url: httpURL + "/sms/school/profile/feebook/save",
            method: "POST",
            data: formData,
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((Response) => {
        // console.log(Response.data)
        var data = Response.data;
        obj.status = data.SvcStatus;
        obj.message = data.SvcMsg;
        cb(obj);

    }
    ).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cb(obj);
    });
}

function saveIdCardSvc(idCard, cb) {
    var obj = { status: "Success", message: "" };
    axios(
        {
            url: httpURL + "/sms/school/profile/id_card/save",
            method: "POST",
            params: { idCard: JSON.stringify(idCard) },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((Response) => {
        // console.log(Response.data)
        var data = Response.data;
        obj.status = data.SvcStatus;
        obj.message = data.SvcMsg;
        cb(obj);

    }
    ).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cb(obj);
    });
}

function saveReportCardSvc(reportCard, cb) {
    var obj = { status: "Success", message: "" };
    const formData = new FormData();
    formData.append("reportCard", JSON.stringify(reportCard));
    axios(
        {
            url: httpURL + "/sms/school/profile/reportcard/save",
            method: "POST",
            // params: { reportCard: JSON.stringify(reportCard) },
            data:formData,
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((Response) => {
        // console.log(Response.data)
        var data = Response.data;
        obj.status = data.SvcStatus;
        obj.message = data.SvcMsg;
        cb(obj);
    }
    ).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cb(obj);
    });
}


function saveAddressSvc(addr, cb) {
    var obj = { status: "Success", message: "" };
    axios(
        {
            url: httpURL + "/sms/school/profile/addr/save",
            method: "POST",
            params: { addr: JSON.stringify(addr) },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((Response) => {
        // console.log(Response.data)
        var data = Response.data;
        obj.status = data.SvcStatus;
        obj.message = data.SvcMsg;
        cb(obj);

    }
    ).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cb(obj);
    });
}
function saveLatLongSvc(latLng, cb) {
    var obj = { status: "Success", message: "" };
    axios(
        {
            url: httpURL + "/sms/school/profile/lat_long/save",
            method: "POST",
            params: { latLong: latLng },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((Response) => {
        // console.log(Response.data)
        var data = Response.data;
        obj.status = data.SvcStatus;
        obj.message = data.SvcMsg;
        cb(obj);

    }
    ).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cb(obj);
    });
}
function saveEmailServerDtlsSvc(mailDtlsObj, cb) {
    var obj = { status: "Success", message: "" };
    axios(
        {
            url: httpURL + "/sms/school/profile/mail/save",
            method: "POST",
            params: { mailDtls: mailDtlsObj },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((Response) => {
        // console.log(Response.data)
        var data = Response.data;
        obj.status = data.SvcStatus;
        obj.message = data.SvcMsg;
        cb(obj);

    }
    ).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cb(obj);
    });
}

function saveParentAppDtlsSvc(parentAppObj, cb) {
    console.log("saveParentAppDtlsSvc called, url", httpURL + "/sms/school/profile/parentapp/save", ", parentAppObj", parentAppObj);
    var obj = { status: "Success", message: "" };
    const formData = new FormData();
    formData.append("parentApp", JSON.stringify(parentAppObj));
    // console.log("formData", formData);
    axios(
        {
            url: httpURL + "/sms/school/profile/parentapp/save",
            method: "POST",
            params: { parentApp: JSON.stringify(parentAppObj) },
            // data:formData,
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((Response) => {
        // console.log("response in saveParentAppDtlsSvc", Response);
        // console.log(Response.data)
        var data = Response.data;
        obj.status = data.SvcStatus;
        obj.message = data.SvcMsg;
        cb(obj);

    }
    ).catch((error) => {
        // console.log("error in saveParentAppDtlsSvc", error);
        obj.status = "Exception";
        obj.message = error;
        cb(obj);
    });
}
export {
    getLstState, getLstCity, getLstLocality, getSchoolProfile, getLatLongSvc, getIDCardDtlsSvc, getReportCardDtlsSvc,
    getAddressSvc, getLogoSvc, getFeeBookDtlsSvc, saveImgLogoSvc, saveFeebookSvc, saveIdCardSvc, saveReportCardSvc,
    saveAddressSvc, saveLatLongSvc, getEmailServerDtlsSvc, saveEmailServerDtlsSvc,saveParentAppDtlsSvc, getParentAppDtlsSvc
};