import React, { useState, useEffect } from 'react';
import './css/tchSlider.css';

var filterRoleId = null;

const TchSlider = (props) => {
    /* var initialLstTch = [
        {
            tchid: 10,
            tch: 'Sam',
            roleId: 10,
            role: 'Senior',
            appliedOn: '23-July',
            appliedBy: '23-July',
        },
        {
            tchid: 11,
            tch: 'Pam',
            roleId: 11,
            role: 'Junior',
            appliedOn: '22-July',
            appliedBy: 0,
        },
    ] */
    //const [lstTch, setLstTch] = useState(props.lstTch)
    // const [lstTch, setLstTch] = useState([
    //     {
    //         tchid: 10,
    //         tch: 'Sam',
    //         roleId: 10,
    //         role: 'Senior',
    //         appliedOn: '23-July',
    //         appliedBy: '23-July',
    //     },
    //     {
    //         tchid: 11,
    //         tch: 'Pam',
    //         roleId: 11,
    //         role: 'Junior',
    //         appliedOn: '22-July',
    //         appliedBy: 0,
    //     },
    // ])
    const [state, setState] = useState(
        {
            lstTch: props.lstTch,
            tch: "",
            roleId: 0,
            flg: true,
        }
    )

    useEffect(() => {
        setState({ lstTch: props.lstTch })

    }, [])


    function filterTableWithType(roleId) {
        setState({
            lstTch: props.lstTch
        })
        let newArr = []
        for (let i = 0; i < state.lstTch.length; i++) {
            // console.log('state roleId' + parseInt(state.lstTch[i].roleId) + 'roleId' + parseInt(roleId))
            if (parseInt(state.lstTch[i].roleId) === parseInt(roleId)) {
                newArr.push(state.lstTch[i])
            }
        }
        setState({
            lstTch: newArr,
        })
    }

    const filterWithType = async (e) => {
        var roleId = await e.target.value;
        // console.log("e is printed " + roleId + "value of e: " + e);
        setState({
            ...state,
            [e.target.name]: e.target.value
        },
        )
        // console.log(e.target.value);
        filterTableWithType(e.target.value)
    }

    function filterTable(tch) {
        if (tch !== "") {
            const filterContent = state.lstTch.filter(contents =>
                contents.tch.toLowerCase().includes(tch.toLowerCase()));
            setState({
                lstTch: filterContent
            })
            // console.log(filterContent);
        }
        else {

            setState({
                lstTch: props.lstTch
            })
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setState({
            ...state,
            [name]: value
        })
        filterTable(value)
    }


    return (
        <div>
            <h3 style={{ textAlign: 'center', backgroundColor: 'whitesmoke' }}>Teachers</h3>
            <table className="tableLayout1" style={{ width: 200 }}>
                <thead>
                    <tr>
                        <th style={{ width: 80 }} rowSpan="2">
                            <input
                                type="text"
                                className="form-control inp-search"
                                defaultValue={state.tch}
                                onChange={handleChange}
                                name="tch" id="tchId" placeholder="Teacher Name" />
                        </th>
                        <th style={{ width: 50 }} rowSpan="2">
                            <select
                                autoComplete="off"
                                className="form-control inp-search"
                                name="roleId"
                                value={state.roleId}
                                onChange={filterWithType}
                            >
                                <option id={0} key={0}>Role</option>
                                {props.lstRole.map((obj) => {
                                    return <option value={obj.roleId} key={obj.roleId}>{obj.role}
                                    </option>
                                })}
                            </select>
                        </th>
                        <th style={{ width: 50 }} className="text-center" colSpan="2"> Applied</th>
                        <th style={{ width: 20 }} className="text-center" rowSpan="2"> Absent </th>
                    </tr>
                    <tr>
                        <th style={{ width: 25 }} className="text-center" > Date</th>
                        <th style={{ width: 25 }} className="text-center" > By</th>
                    </tr>
                </thead>
            </table>
            <div className="subjectTableDiv" style={{ height: '80vh', overflowY: 'scroll', border: '1px solid #ccc' }} >
                <table id="subjectTable" className="tableLayout">
                    <colgroup>
                        <col width="80" />
                        <col width="50" />
                        <col width="25" />
                        <col width="25" />
                        <col width="20" />
                    </colgroup>
                    <tbody id="tableBody">
                        {state.lstTch.map((obj, index) => (
                            (filterRoleId === null || parseInt(filterRoleId) === obj.roleId) &&
                            <tr key={index} id={obj.roleId}>
                                <td width="" className="no-display">{obj.tchId}</td>
                                <td width="20" className="text-center">{obj.tch}</td>
                                <td width="" className="no-display">{obj.roleId}</td>
                                <td width="20" className="text-center">{obj.role}</td>
                                <td width="" className="text-center">{obj.appliedOn}</td>
                                <td width="" className="text-center">{obj.appliedBy === "" ? "-" : obj.appliedBy}</td>
                                <td width="35" className="text-center">
                                    <input key={obj.tchId} className="" autoComplete="off" id={obj.tchId} type="checkbox" defaultChecked={obj.selected} onClick={(e) => props.cboxClick(obj.tchId, e.target.checked)} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TchSlider;