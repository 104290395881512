import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'common/css/master.css'
import Header from 'common/js/header/header.js'
import {
  getChannelListSvc, 
  getCommunicationListSvc,
  deleteCommunicationSvc,
  completeCommunicationSvc
} from './commSvc'
import {SliderType} from './sliderType'
import CommunicationTable from './commTable'
import CommunicationSlider from './commSlider'
import CommunicationStudentSlider from './commStudentSlider'
import CommPopup from './commPopup'

const CommCtrl = () => {
  const [loader, setLoader] = useState('none')
  const [communicationObj, setCommunicationObj] = useState({})
  const [communicationId, setCommunicationId] = useState(0)
  const [channelId, setChannelId] = useState(0)
  const [lstCommunication, setLstCommunication] = useState([])
  const [lstChannel, setLstChannel] = useState([])
  const [randNo, setRandNo] = useState(0)
  const [flgSlider, setFlgSlider] = useState(0)
  const [flgPopup, setFlgPopup] = useState(false)
  const [template, setTemplate] = useState('')
  
  useEffect(() => {
    setLoader('block')
    getCommunicationList()
    getChannelList()
  }, [])
  
  function getChannelList () {
    getChannelListSvc(cbChannelListResponse)
  }

  function cbChannelListResponse(data) {
    setLoader('none')
    if (data.SvcStatus === 'Success') {
      setLstChannel(data.lstChannel)
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  function getCommunicationList () {
    getCommunicationListSvc(cbCommunicationListResponse)
  }
  function cbCommunicationListResponse (data) {
    setLoader('none')
    if (data.SvcStatus === 'Success') {
      setLstCommunication(data.lstCommunication)
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  function generateRandomNo () {
    var lRandNo = Math.floor(Math.random() * 999999)
    // console.log(lRandNo)
    setRandNo(lRandNo)
  }
  function openCommunicationSlider (pCommunicationId) {
    setCommunicationId(pCommunicationId)
    // console.log(typeof pCommunicationId)
    setFlgSlider(SliderType.COMM_DTLS)
  }
  function openStudentSlider (pCommunicationId) {
    setCommunicationId(pCommunicationId)
    // console.log(typeof pCommunicationId)
    setFlgSlider(SliderType.COMM_STUDENT)
  }

  function cbCommunicationDtlsResponse (data) {
    setLoader('none')
    if (data.SvcStatus === 'Success') {
      setCommunicationObj(data.communication)
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  function closeSlider () {
    setFlgSlider(0)
    // setCommunicationObj({})
    // setCommunicationId(0)
    getCommunicationListSvc(cbCommunicationListResponse)
  }

  function deleteCommunication (pCommunicationId, pTemplateId) {
    var confirmDelCommunication = window.confirm(
      'Are you sure to delete the communication?'
    )
    if (confirmDelCommunication) {
      setLoader('block')
      deleteCommunicationSvc(
        pCommunicationId,
        pTemplateId,
        cbDeleteCommunicationResponse
      )
    }
  }

  function cbDeleteCommunicationResponse (data) {
    setLoader('none')
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getCommunicationListSvc(cbCommunicationListResponse)
    } else {
      toast.error(data.SvcMsg)
    }
    closeSlider()
  }

  function completeCommunication (pCommunicationId) {
    setLoader('block')
    completeCommunicationSvc(pCommunicationId, cbCompleteCommunicationResponse)
  }

  function cbCompleteCommunicationResponse (data) {
    setLoader('none')
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getCommunicationListSvc(cbCommunicationListResponse)
    } else {
      toast.error(data.SvcMsg)
    }
    closeSlider()
  }
  const getWingId = wingId => {
    // console.log('wingId = ' + wingId)
  }

  const closePopup = () => {
    document.querySelector('body').classList.remove('showPopup')
  }

  return (
    <div
      className='MainContainer'
      style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}
    >
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: loader
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className='col-sm-12 col-centered' style={{ marginTop: '2%' }}>
        <div className='col-sm-11' style={{ height: '100%' }}>
          <CommunicationTable
            setCommunicationId={setCommunicationId}
            setChannelId={setChannelId}
            communicationId={communicationId}
            closeSlider={closeSlider}
            generateRandomNo={generateRandomNo}
            openCommunicationSlider={openCommunicationSlider}
            openStudentSlider={openStudentSlider}
            deleteCommunication={deleteCommunication}
            completeCommunication={completeCommunication}
            lstCommunication={lstCommunication}
            getCommunicationList={getCommunicationList}
          />
        </div>
        <div
          className='col-xs-1'
          onClick={e => {
            openCommunicationSlider(0)
            generateRandomNo()
          }}
        >
          <span
            id='addReadyId'
            title='Add'
            className='addIcon glyphicon glyphicon-plus-sign'
          ></span>
        </div>
      </div>
      {flgSlider === SliderType.COMM_DTLS && (
        <div
          className='TestSlider'
          style={{ display: flgSlider=== SliderType.COMM_DTLS ? 'block' : 'none', width: '90%' }}
        >
          <CommunicationSlider
            key={communicationId}
            communicationId={communicationId}
            setCommunicationId={setCommunicationId}
            openSlider={openCommunicationSlider}
            closeSlider={closeSlider}
            setRandNo={setRandNo}
            communicationObj={communicationObj}
            getCommunicationList={getCommunicationList}
            setFlgPopup={setFlgPopup}
            setTemplate={setTemplate}
          />
        </div>
      )}

  {flgSlider === SliderType.COMM_STUDENT && (
        <div
          className='TestSlider'
          style={{ display: flgSlider === SliderType.COMM_STUDENT ? 'block' : 'none', width: '80%' }}
        >
          <CommunicationStudentSlider
            key={communicationId}
            communicationId={communicationId}
            channelId={channelId}
            closeSlider={closeSlider}
          />
        </div>
      )}
      <div className='popup'>
        {flgPopup && (
          <CommPopup
            key={communicationId + '_' + randNo}
            closePopup={closePopup}
            commId={communicationId}
            template={template}
          />
        )}
      </div>
    </div>
  )
}

export default CommCtrl
