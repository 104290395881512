import React, { useState, useEffect, useMemo } from "react";
import '../css/StaffCtrl.css'
import 'common/css/master.css'
import SliderType from "./SliderType";
import { toast, ToastContainer } from 'react-toastify';
import { getStaffClassSectionListSvc, deleteStaffSectionSvc } from "./StaffSvc";
const ClassSubjectTable = (props) => {
    const [lstStaffClassSection, setLstStaffClassSection] = useState([])
    const [sectionId, setSectionId] = useState(0)
    const [loader, setLoader] = useState(props.loader)
    function selectClassSection(pSectionId, pClassId) {
        // console.log("pSectionId" + pSectionId + "PclassId" + pClassId)
        setSectionId(pSectionId)
        props.setSelSectionId(pSectionId)
        props.setSelClassId(pClassId)
    }
    function cbStaffClassSectionList(data) {
        if (data.SvcStatus === "Success") {
            // console.log("Test list fetching success")
            setLstStaffClassSection(data.lstClsSection)
            setLoader('none')
        }
        else {
            toast.error(data.SvcMsg)
            setLoader('none')
        }
    }
    function deleteSection(pUserId, pSectionId) {
        setLoader('block');
        deleteStaffSectionSvc(pUserId, pSectionId, cbDeleteStaffSection);
    }
    function cbDeleteStaffSection(data) {
        setLoader('none')
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg);
            props.generateRandomNo()
            props.setClassSubjectTblKey(props.generateRandomNo());
        } else {
            toast.error(data.SvcMsg)
        }
    }
    useEffect(() => {
        setLoader("block")
        if (props.userId && props.flgSliderType == SliderType.SLIDER_CLASS_SUBJECTS)
            getStaffClassSectionListSvc(props.userId, cbStaffClassSectionList)


    }, [props.userId, props.flgSliderType]);
    const colgrp = (
        <colgroup>
            <col width="8%"></col>
            <col width="8%"></col>
        </colgroup>
    )
    return (
        <>
            <div className="col-sm-10 padding-remove" style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
                <ToastContainer autoClose={3000} hideProgressBar />

                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <table className="tableLayout1">

                    {colgrp}
                    <thead>
                        <tr>

                            <th className="text-left">Class-Section</th>
                            <th>Action</th>
                            {/* <th style={{ display: props.allowMultiSelect ? "table-cell" : "none" }}>Select</th> */}
                        </tr>
                    </thead>
                </table>
                <div className="tablebodyWrapper" style={{ height: "auto" }}>
                    <table className="tableLayout">
                        {colgrp}
                        <tbody>
                            {
                                lstStaffClassSection && lstStaffClassSection.length !== 0 && lstStaffClassSection.map((lst, idx) => {
                                    return (
                                        <tr key={lst.sectionId} onClick={(e) => selectClassSection(lst.sectionId, lst.clsId)}
                                            className={lst.sectionId === sectionId ? 'selected' : ''}>
                                            {/* <td>{lst.clsId}</td>
                                            <td>{lst.sectionId}</td> */}
                                            <td className="text-left">{lst.section}</td>

                                            <td className="text-center">
                                                <button type='button' className="btn-space" style={{ padding: '1px' }} onClick={(e) => deleteSection(props.userId, lst.sectionId)}>Delete</button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
export default ClassSubjectTable;