import React, { useState, useEffect } from 'react';
import '../css/wardTab.css';

const MenuTabList = (props) => {
    const [state, setState] = useState(
        {
            lstMenuTab: props.lstMenuTab,
            selMenuTabId: props.selMenuTabId,
            dynamicWidth: 'calc(100% / ' + (Object.keys(props.lstMenuTab).length + 1) + ')',
        }
    )

    useEffect(() => {
        // console.log(props.lstMenuTab)
        //setLstDt(props.lstDt)
        setState({
            lstMenuTab: props.lstMenuTab,
            selMenuTabId: props.selMenuTabId,
            dynamicWidth: 'calc(100% / ' + (Object.keys(props.lstMenuTab).length + 1) + ')'
        })

    }, [])


    return (
        <>
            <div id={state.selMenuTabId} className="col-sm-11 padding-remove tab-menu-wrapper" style={{}}>
                {state.dynamicWidth && props.lstMenuTab && props.lstMenuTab.length !== 0 && props.lstMenuTab.map((obj, idx) => (
                    <div key={idx} obj={JSON.stringify(obj)} className={obj.menuTabId === state.selMenuTabId ? "menu-card" : "menu-card"} style={{ width: state.dynamicWidth, height: '100%' }} onClick={() => props.onMenuTabClick(obj.menuTabId)}>
                        <div className="" style={{ height: '100%' }}>
                            <div className={obj.menuTabId === props.selMenuTabId ? "card-body menu-active" : "card-body"} style={{ height: '100%' }}>
                                <div style={{ width: '75%', padding: '0.2rem 0.5rem' }} className="pull-left">
                                    <div className="card-title">{obj.menuTab}</div>
                                </div>


                            </div>
                        </div>
                    </div>))
                }
            </div >

        </>);
}

export default MenuTabList;