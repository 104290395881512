import axios from "axios";
import lstModules from "common/json/lstModules.json";
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules["enquiry"].url + ":" + lstModules["enquiry"].port;
var httpURLCommon = lstModules["main"].url + ":" + lstModules["main"].port;
function getInterviewSlotListSvc(callBack) {
  axios({
    url: httpURL + "/sms/enquiry/interview/slot/list",
    method: "POST",
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      console.error(error);
    });
}
function getInterviewPanelListSvc(pInterviewSlotId, callBack) {
  axios({
    url: httpURL + "/sms/enquiry/interview/panel/list",
    method: "POST",
    params: { interviewSlotId: pInterviewSlotId },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      console.error(error);
    });
}
function saveInterviewSlotSvc(pInterviewObj, callBack) {
  var formData = new FormData();
  formData.append("interviewSlot", JSON.stringify(pInterviewObj));
  axios({
    url: httpURL + "/sms/enquiry/interview/slot/save",
    method: "POST",
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((response) => {
      var data = response.data;
      if (data.interviewSlotId === undefined) {
        data.interviewSlotId = pInterviewObj.interviewSlotId;
      }
      callBack(data, pInterviewObj);
    })
    .catch((error) => {
      // console.log("Error Occured");
    });
}
function deleteInterviewSlotSvc(pInterviewSlotId, callBack) {
  axios({
    url: httpURL + "/sms/enquiry/interview/slot/delete",
    method: "POST",
    params: { interviewSlotId: pInterviewSlotId },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      console.error(error);
    });
}
function informCandidatesSvc(pinterviewSlotId, pAdd, callBack) {
  axios({
    url: httpURL + "/sms/enquiry/interview/slot/inform_candidate",
    method: "POST",
    params: { interviewSlotId: pinterviewSlotId, add: pAdd },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      console.error(error);
    });
}
function informPanelSvc(pinterviewSlotId, pAdd, callBack) {
  axios({
    url: httpURL + "/sms/enquiry/interview/slot/inform_panel",
    method: "POST",
    params: { interviewSlotId: pinterviewSlotId, add: pAdd },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      console.error(error);
    });
}
export { getInterviewSlotListSvc };
export { getInterviewPanelListSvc };
export { saveInterviewSlotSvc };
export { deleteInterviewSlotSvc };
export { informCandidatesSvc };
export { informPanelSvc };
