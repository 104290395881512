import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['enquiry'].url + ':' + lstModules['enquiry'].port
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port
const PIXSZ = 8

const RegistrationSiblings = ({ admissionId }) => {
  const [lstSibling, setLstSibling] = useState([])
  const [loader, setLoader] = useState('none')

  useEffect(() => {
    setLoader('block')
    getSiblingListSvc(admissionId, cbSiblingListResponse)
  }, [admissionId])

  function cbSiblingListResponse (data) {
    if (data.SvcStatus === 'Success') {
      // console.log('sibling::' + data)
      setLstSibling(data.lstSibling)
    } else {
      toast.error(data.SvcMsg)
    }
    setLoader('none')
  }
  function getSiblingListSvc (admissionId, callBack) {
    axios({
      url: httpURL + '/sms/admission/sibling/dtls',
      method: 'POST',
      params: { admissionId: admissionId },
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        var data = Response.data

        callBack(data)
      })
      .catch(error => {
        // console.log('error')
      })
  }
  const hdr = [
    { label: 'Name', sz: 30 },
    { label: 'DOB', sz: 12 },
    { label: 'Gender', sz: 10 },
    { label: 'Class', sz: 10 },
    { label: 'School', sz: 40 },
    { label: 'Action', sz: 15 }
  ]

  function ColGrp ({ items }) {
    return items.map(data => (
      <col key={data.label} width={data.sz * PIXSZ}></col>
    ))
  }

  return (
    <>
      <div
        className='col-sm-12'
        style={{
          height: '100%',
          float: 'left',
          background: '#fff',
          paddingTop: '1%'
        }}
      >
        <div className='col-sm-8'>
          <div
            className='col-sm-12'
            style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}
          >
            <ToastContainer autoClose={3000} hideProgressBar />

            <div
              style={{
                position: 'fixed',
                zIndex: 10000,
                width: 100,
                height: 100,
                marginLeft: -50,
                marginTop: -50,
                left: '50%',
                top: '50%',
                display: loader
              }}
            >
              <img
                className='loderImg'
                src={require('common/images/loader.gif')}
                alt='Loader...'
              />
            </div>
            <div>
              <table>
                <colgroup>
                  <ColGrp items={hdr} />
                </colgroup>
                <thead>
                  <tr>
                    {hdr.map((data, idx) => {
                      return (
                        <th key={idx} width={data.sz * PIXSZ}>
                          {data.label}
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {lstSibling.map(data => {
                    return (
                      <tr key={data.siblingId}>
                        <td>{data.name}</td>
                        <td>{data.dob}</td>
                        <td>{data.gender === 'M' ? 'Male' : 'Female'}</td>
                        <td>{data.cls}</td>
                        <td>{data.school}</td>
                        <td className='text-center'>
                          <span className='glyphicon glyphicon-edit'></span>
                          <span className='glyphicon glyphicon-trash'></span>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='col-xs-1'>
          <span
            id='addReadyId'
            title='Add'
            className='addIcon glyphicon glyphicon-plus-sign'
          ></span>
        </div>
      </div>
    </>
  )
}

export default RegistrationSiblings
