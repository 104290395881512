import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
// import "./termForm.css"
import { getLstTermSvc } from "./termTableSvc";

const TermTable = (props) => {
  const [lstTerm, setLstTerm] = useState([]);
  const [termId, setTermId] = useState(undefined);
  const [loader, setLoader] = useState("none");

  useEffect(() => {
    setTermId(props.termId);
  }, [props.termId]);

  useEffect(() => {
    setLoader("block");
    getLstTermSvc(cbLsTermResponse, props.termId);
  }, []);

  function cbLsTermResponse(data, pTermId) {
    setLoader("none");
    if (data.SvcStatus === "Success") {
      setLstTerm(data.lstTerm);
      if (!pTermId && pTermId !== 0) {
        // console.log("pTermId is undefined");
        setTermId(data.lstTerm[0].termId);
        props.rowClick(data.lstTerm[0]);
      }
    } else {
      toast.error(data.SvcMsg);
    }
  }
  function termClick(pTermObj) {
    // console.log("termTable: termClick() ");
    setTermId(pTermObj.termId);
    props.rowClick(pTermObj);
  }
  const colgrp = (
    <colgroup>
      <col width="30%"></col>
      <col width="30%"></col>
      <col width="30%"></col>
    </colgroup>
  );

  return (
    <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <div className="col-sm-11">
        <table className="tableLayout1">
          {colgrp}
          <thead>
            <tr>
              <th>Term</th>
              <th>Weight</th>
              <th>Order</th>
              <th>Action</th>
            </tr>
          </thead>
        </table>
        <div className="tablebodyWrapper">
          <table className="tableLayout">
            {colgrp}
            <tbody>
              {lstTerm.map((value, key) => {
                return (
                  <tr
                    key={key}
                    onClick={() => termClick(value)}
                    className={termId === value.termId ? "selected" : ""}
                  >
                    <td>{value.term}</td>
                    <td>{value.weight}</td>
                    <td>{value.termOrder}</td>
                    <td
                      onClick={(e) => {
                        e.stopPropagation();
                        termClick(value);
                      }}
                    >
                      <span className="glyphicon glyphicon-edit"></span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div
        className="col-md-1  col-xs-1 pull-right"
        onClick={() =>
          termClick({ termId: 0, term: "", weight: 0, termOrder: 0 })
        }
      >
        <span className="addIcon glyphicon glyphicon-plus-sign"></span>
      </div>
    </div>
  );
};

export default TermTable;
