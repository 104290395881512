import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['students'].url + ':' + lstModules['students'].port;
const tokenid = localStorage.getItem('tokenid');
const schoolId = localStorage.getItem("schoolId");
var queryString = require("querystring");



export const deleteParentSvc = (pStudentId, parentId, pType, callback) => {
    const responseObj = { status: "", message: "", error: "" };

    axios({
        url: httpURL + "/sms/student/delete_parent",
        method: 'POST',
        params: { "studentId": pStudentId, "parentId": parentId, "parentType": pType },
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // console.log("response in deleteParentSvc , response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};


export const reverifyEmailSvc = (resendDetails, callback) => {
    const responseObj = { status: "", message: "", error: "" };
    axios({
        url: httpURL + "/sms/student/resendMail",
        method: 'POST',
        data: queryString.stringify({ resendDetails: JSON.stringify(resendDetails), }),
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // console.log("response in reverifyEmailSvc , response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};

export const getParentDtlsSvc = (studentId, callback) => {
    const responseObj = { status: "", message: "", error: "" };
    const schoolStudent = {
        schoolId: schoolId,
        studentId: parseInt(studentId),
    };
    axios({
        url: `${httpURL}/sms/student/parents`,
        method: 'POST',
        data: queryString.stringify({
            schoolStudent: JSON.stringify(schoolStudent),
        }),
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // console.log("response in getParentDtlsSvc , response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            if (response.data.SvcStatus === "Success") {
                responseObj.parents = response.data.parents;
            }
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};


export const getCityListSvc = (stateId, callback) => {
    const responseObj = { status: "", message: "", error: "" };
    axios({
        url: `${httpURL}/sms/mst/city/list`,
        method: 'POST',
        data: queryString.stringify({ schoolId, stateId }),
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // console.log("response in getCityListSvc , response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            if (response.data.SvcStatus === "Success") {
                responseObj.lstCity = response.data.lstCity;
            }
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};


export const saveParentDetailsSvc = (parentsObj, callback) => {
    const responseObj = { status: "", message: "", error: "" };
    axios({
        url: `${httpURL}/sms/student/save_parents`,
        method: 'POST',
        data: queryString.stringify({
            studentParents: JSON.stringify(parentsObj),
        }),
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // console.log("response in saveParentDetailsSvc , response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};

