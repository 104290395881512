import { useEffect, useState } from "react";
import { getCopyCount, saveCopySvc } from "./booksSvc";
import { toast } from "react-toastify";


const BookCopies = (props) => {

    const { closeCopySlider, bookNo, copyNo, title, setTableData } = props;

    const [copyCount, setCopyCount] = useState(0);
    const [createCopies, setCreateCopies] = useState(0);

    useEffect(() => {
        getCopyCount(copyCountResponse, bookNo);
    }, [])

    const copyCountResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            setCopyCount(obj.copyCount);
        }
        else {

            if (data.SvcMsg === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (data.SvcMsg === "Your Session is expired, login again") {
                toast.error(data.SvcMsg);
                // console.log("session expired");
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(data.SvcMsg);
            }
        }
    }

    const saveCopies = () => {
        saveCopySvc(saveCopyResponse, bookNo, copyNo, createCopies);
    }

    const saveCopyResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            toast.success(obj.message);
            closeCopySlider();
            setTableData();
        }
        else {

            if (data.SvcMsg === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (data.SvcMsg === "Your Session is expired, login again") {
                toast.error(data.SvcMsg);
                // console.log("session expired");
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(data.SvcMsg);
            }
        }
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", paddingTop: 20, position: "absolute", top: "28%", left: "60%", backgroundColor: "#F8F8F8", width: 400, height: 300, zIndex: 1001 }}>
            {/* <div> */}
            <div>
                <div className="col-sm-12" style={{ marginBottom: 15 }} >
                    <div className="col-sm-8" style={{ padding: "10px 2px", fontSize: "large" }}>
                        Create Book Copies
                    </div>
                    <div className="col-sm-4" >
                        <button type="button"
                            title="close copy-book"
                            onClick={closeCopySlider}
                            className='btn btn-info text-center pull-right'>X</button>
                    </div>
                </div>

                <div className="formPage ">
                    <form className='form form-horizontal'>
                        <div className="col-sm-12 padding-remove">
                            <div className="form-group col-sm-12">
                                <label className="control-label col-md-4 text-left">Title</label>
                                <div className="col-sm-8 padding-remove">
                                    <input type="text" value={title} disabled />
                                </div>
                            </div>
                            <div className="form-group col-sm-12">
                                <label className="control-label col-md-4 text-left">Current copies</label>
                                <div className="col-sm-8 padding-remove">
                                    <input type="number" value={copyCount} disabled />
                                </div>
                            </div>
                            <div className="form-group col-sm-12">
                                <label className="control-label col-md-4 text-left">Copies to create</label>
                                <div className="col-sm-8 padding-remove">
                                    <input type="number" defaultValue={createCopies} onChange={(e) => { setCreateCopies(e.target.value) }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12" >
                            <div className=" col-md-12 form-group text-center multiButtonWrap save-print-buttons" >
                                <div >
                                    <button type="button" style={{ marginTop: 45, width: 100 }} onClick={saveCopies} className="btn btn-success">Save</button>
                                </div>
                                {/* <div>
                                    <button type="button" style={{ marginTop: 45, width: 100 }} onClick={closeCopySlider} className="btn btn-danger">Cancel</button>
                                </div> */}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )

}

export default BookCopies;