import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import lstModules from 'common/json/lstModules.json';


var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules['main'].url + ":" + lstModules['main'].port;


function getLogoSvc(callback) {

    var xhr = new XMLHttpRequest();
    xhr.open("GET", httpURL + '/sms/school/profile/logo/view', true);
    xhr.setRequestHeader('tokenid', tokenid);

    xhr.responseType = "arraybuffer";

    xhr.onload = function (e) {
        var arrayBufferView = new Uint8Array(this.response);
        var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        // console.log("imageUrl in getLogoSvc", imageUrl);
        callback(imageUrl);
    };
    xhr.send();
}


export { getLogoSvc };