
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import FeeDueCell from './feeDueCellNew';
import { feeDueStudentListSvc } from "./feePaymentSvc";

// var tmpLstStudentData;
const StudentFeePaymentTable = (props) => {
    const [lstSelectedStudent, setLstSelectedStudent] = useState([]);
    const [lstFeeDue, setLstFeeDue] = useState([]);
    const [lstFeeDues, setLstFeeDues] = useState([]);
    // const [lstStudentData, setLstStudentData] = useState([]);
    const [lstStudentKey, setLstStudentKey] = useState(0);

    const [filterEnrollment, setFilterEnrollment] = useState("");
    const [filterName, setFilterName] = useState("");
    const [filterClassSection, setFilterClassSection] = useState("");
    const [filterFather, setFilterFather] = useState("");
    const [filterGuardian, setFilterGuardian] = useState("");
    const [filterMother, setFilterMother] = useState("");


    const [pendingFeeStudentCount, setPendingFeeStudentCount] = useState(0);
    const [pendingSum, setPendingSum] = useState(0);

    // const [feeDueKey, setFeeDueKey] = useState(0);

    useEffect(() => {
        getFeeDueStudentList();
    }, []);

    const getFeeDueStudentList = () => {
        props.setLoader('block');
        props.setOpacity(0.5);

        feeDueStudentListSvc(feeDueStudentListResponse);
    };

    const feeDueStudentListResponse = async (data) => {
        let obj = data;
        if (obj.status === 'Success') {
            await setLstFeeDue(obj.lstFeeDue);
            await setLstFeeDues(obj.lstFeeDue);
            const newLstStudentKey = obj.lstFeeDue.map(item => ({
                studentId: item.studentId,
                cellKey: 0,
            }));
            await setLstStudentKey(newLstStudentKey);
            // console.log("newLstStudentKey in feeDueStudentListResponse", newLstStudentKey);
            updatePendingSummary(obj.lstFeeDue);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        props.setLoader('none');
        props.setOpacity(1);
    };

    const refreshStudentFeeDue = async (sId) => {
        var updatedLstStudentKey = [...lstStudentKey];
        for (let i = 0; i < updatedLstStudentKey.length; i++) {
            if (updatedLstStudentKey[i].studentId === sId) {
                updatedLstStudentKey[i] = {
                    ...updatedLstStudentKey[i],
                    cellKey: updatedLstStudentKey[i].cellKey+1,
                    // cellKey: Math.round(Math.random() * 99999),
                };
                break;
            }
        }
        await setLstStudentKey(updatedLstStudentKey);
    }

    const handleRowClick = (e, sId, fDue) => {
        if (sId === 0) {
            e.preventDefault();
            return false;
        }
        // if (fDue === 0) {
        //     e.preventDefault();
        //     return false;
        // }
        var array = []
        array.push(sId);

        props.selectedStudentData(array);
    }

    const handleCheckboxChange = (sId) => {
        if (lstSelectedStudent.includes(sId)) {
            const updatedLstSelectedStudent = lstSelectedStudent.filter((id) => id !== sId);
            setLstSelectedStudent(updatedLstSelectedStudent);
        } else {
            setLstSelectedStudent([...lstSelectedStudent, sId]);
        }
    }

    const getCheckboxValue = (sId) => {
        return lstSelectedStudent.includes(sId);
    }

    const handleProceed = () => {
        if (lstSelectedStudent.length > 3) {
            toast.warning("Please select only 3 students or less");
            return false;
        }
        if (lstSelectedStudent.length == 0) {
            toast.error("Please select atleast 1 student");
            return false;
        }
        props.selectedStudentData(lstSelectedStudent);
    }

    const updateFeeDue =async (sId, fDue) => {
        var updateLstFeeDues = [...lstFeeDues];
        for (let i = 0; i < updateLstFeeDues.length; i++) {
            if (updateLstFeeDues[i].studentId === sId) {
                updateLstFeeDues[i] = {
                    ...updateLstFeeDues[i],
                    feeDue:fDue,
                };
                break;
            }
        }
        await setLstFeeDues(updateLstFeeDues);
        var updateLstFeeDue = [...lstFeeDue];
        for (let i = 0; i < updateLstFeeDue.length; i++) {
            if (updateLstFeeDue[i].studentId === sId) {
                updateLstFeeDue[i] = {
                    ...updateLstFeeDue[i],
                    feeDue:fDue,
                };
                break;
            }
        }
        await setLstFeeDue(updateLstFeeDue);
        updatePendingSummary(lstFeeDues);
    }

    const updatePendingSummary = (feeDueList) => {
        // console.log("updatePendingSummary called");
        // console.log("lstFeeDues", lstFeeDues);
        // console.log("lstFeeDue", lstFeeDue);
        let noOfStd = 0;
        let ttlDue = 0;
        if (feeDueList && feeDueList.length) {
            feeDueList.map((feeDtls) => {
                if (feeDtls.feeDue > 0) {
                    ttlDue += feeDtls.feeDue;
                    noOfStd++;
                }
            })
        }
        setPendingFeeStudentCount(noOfStd);
        setPendingSum(ttlDue);
    }

    const getStudentCellKey = (sId) => {
        // console.log("getStudntCellKey called, sId", sId, ", lstStudentKey", lstStudentKey);
        const student = lstStudentKey.find(student => student.studentId === sId);
        // console.log("returned", student ? student.cellKey : 0);
        return student ? student.cellKey : 0;
    };

    const filterFeeDueList = (fEnrollNo, fName, fClassSection, fFather, fMother, fGuardian) => {
        let filterFeeDueList = [];
        filterFeeDueList = lstFeeDues.filter(feeDue =>
            feeDue.enrollmentNo === null || feeDue.enrollmentNo === undefined || feeDue.enrollmentNo.toString().toLowerCase().includes(fEnrollNo.toLowerCase())
        );

        filterFeeDueList = filterFeeDueList.filter(feeDue =>
            (feeDue.cls + "-" + feeDue.section).toString().toLowerCase().includes(fClassSection.toLowerCase()));

        filterFeeDueList = filterFeeDueList.filter(feeDue =>
            feeDue.student.toString().toLowerCase().includes(fName.toLowerCase()));

        filterFeeDueList = filterFeeDueList.filter(feeDue =>
            (feeDue.father + "-" + feeDue.fatherPhone).toString().toLowerCase().includes(fFather.toLowerCase()));

        filterFeeDueList = filterFeeDueList.filter(feeDue =>
            (feeDue.mother + "-" + feeDue.motherPhone).toString().toLowerCase().includes(fMother.toLowerCase()));

        filterFeeDueList = filterFeeDueList.filter(feeDue =>
            (feeDue.guardian + "-" + feeDue.guardianPhone).toString().toLowerCase().includes(fGuardian.toLowerCase()));

        // console.log("filterFeeDueList", filterFeeDueList);

        setLstFeeDue(filterFeeDueList);
    }

    const handleFilterEnrollmentNo = (e) => {
        setFilterEnrollment(e.target.value);
        filterFeeDueList(e.target.value, filterName, filterClassSection, filterFather, filterMother, filterGuardian);
    }

    const handleFilterName = (e) => {
        setFilterName(e.target.value);
        filterFeeDueList(filterEnrollment, e.target.value, filterClassSection, filterFather, filterMother, filterGuardian);
    }

    const handleFilterClassSection = (e) => {
        setFilterClassSection(e.target.value);
        filterFeeDueList(filterEnrollment, filterName, e.target.value, filterFather, filterMother, filterGuardian);
    }

    const handleFilterFather = (e) => {
        setFilterFather(e.target.value);
        filterFeeDueList(filterEnrollment, filterName, filterClassSection, e.target.value, filterMother, filterGuardian);
    }

    const handleFilterMother = (e) => {
        setFilterMother(e.target.value);
        filterFeeDueList(filterEnrollment, filterName, filterClassSection, filterFather, e.target.value, filterGuardian);
    }

    const handleFilterGuardian = (e) => {
        setFilterGuardian(e.target.value);
        filterFeeDueList(filterEnrollment, filterName, filterClassSection, filterFather, filterMother, e.target.value);
    }

    return (
        <div className="col-md-12">
            <div className="col-md-12 col-centered">
                <ToastContainer autoClose={3000} hideProgressBar />
                <div className='col-sm-12 padding-remove' style={{ height: "35px" }}>
                    <div className="col-sm-3 padding-remove pull-right">
                        <div className="col-sm-6 padding-remove "  >
                            <div className="col-sm-10 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                                <label className=" col-sm-12 text-center padding-remove" style={{ fontWeight: 400 }}  >No of Students: {pendingFeeStudentCount.toLocaleString('en-IN')}</label>
                            </div>
                        </div>
                        <div className="col-sm-6 padding-remove "  >
                            <div className="col-sm-10 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                                <label className=" col-sm-12 text-center padding-remove" style={{ fontWeight: 400 }}  >Total Due: {pendingSum.toLocaleString('en-IN')}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="tableLayout1" >
                    <thead>
                        <tr>
                            <th style={{ width: '25%' }}>
                                <input type="text" className="form-control inp-search"
                                    name="enrollmentNo"
                                    id="enrollmentNoFilter"
                                    value={filterEnrollment}
                                    onChange={(e) => handleFilterEnrollmentNo(e)}
                                    placeholder="Enrollment No." />
                            </th>
                            <th style={{ width: '40%' }}>
                                <input type="text" className="form-control inp-search"
                                    value={filterName}
                                    onChange={(e) => handleFilterName(e)}
                                    name="name"
                                    id="nameFilter"
                                    placeholder="Name" />
                            </th>
                            <th style={{ width: "18%" }}>
                                <input type="text" className="form-control inp-search"
                                    value={filterClassSection}
                                    onChange={(e) => handleFilterClassSection(e)}
                                    name="cls"
                                    id="clsFilter"
                                    placeholder="Class" />
                            </th>
                            <th style={{ width: "40%" }}>
                                <input type="text" className="form-control inp-search"
                                    value={filterFather}
                                    onChange={(e) => handleFilterFather(e)}
                                    name="father"
                                    id="fatherFilter"
                                    placeholder="Father" />
                            </th>
                            <th style={{ width: "40%" }}>
                                <input type="text" className="form-control inp-search"
                                    value={filterMother}
                                    onChange={(e) => handleFilterMother(e)}
                                    name="mother"
                                    id="motherFilter"
                                    placeholder="Mother" />
                            </th>
                            <th style={{ width: "40%" }}>
                                <input type="text" className="form-control inp-search"
                                    value={filterGuardian}
                                    onChange={(e) => handleFilterGuardian(e)}
                                    name="guardian"
                                    id="guardianFilter"
                                    placeholder="Guardian" />
                            </th>
                            <th style={{ width: "18%" }}>
                                Fees Due
                            </th>
                            <th style={{ width: "13%" }}>
                                Select
                            </th>
                            <th style={{ width: "29%" }}>
                                Action
                            </th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv feeDuesTableDiv1">
                    <table id="feeDuesTable" className="tableLayout">
                        <tbody id="feeDuesBodyTable" >
                            {lstFeeDue  &&
                                lstFeeDue.map((feeDueDtls, i) => (
                                    <tr key={feeDueDtls.studentId}
                                        onClick={(e) => handleRowClick(e, feeDueDtls.studentId, feeDueDtls.feeDue)} >
                                        <td style={{ width: "25%" }} className="text-left" >{feeDueDtls.enrollmentNo}</td>
                                        <td style={{ width: "40%" }} className="text-left">{feeDueDtls.student}</td>
                                        <td style={{ width: "18%" }} className="text-left">{feeDueDtls.cls ? feeDueDtls.cls : ''}{feeDueDtls.section ? '-' + feeDueDtls.section : ''}</td>
                                        <td style={{ width: "40%" }} className="text-left">{feeDueDtls.father}-{feeDueDtls.fatherPhone}</td>
                                        <td style={{ width: "40%" }} className="text-left">{feeDueDtls.mother}-{feeDueDtls.motherPhone}</td>
                                        <td style={{ width: "40%" }} className="text-left">{feeDueDtls.guardian}-{feeDueDtls.guardianPhone}</td>
                                        <td style={{ width: "18%" }} className="text-right">
                                            {<FeeDueCell
                                                cellKey={lstStudentKey && getStudentCellKey(feeDueDtls.studentId)}
                                                studentId={feeDueDtls.studentId}
                                                feeDue={feeDueDtls.feeDue}
                                                updateFeeDue={updateFeeDue}
                                            />}
                                        </td>
                                        <td style={{ width: "13%" }} className="text-center">
                                            <input type="checkbox"
                                                className="form-check-input"
                                                value={getCheckboxValue(feeDueDtls.studentId)}
                                                onChange={() => handleCheckboxChange(feeDueDtls.studentId)}
                                                onClick={(e) => { e.stopPropagation(); }}
                                                disabled={feeDueDtls.feeDue === 0}
                                            />
                                        </td>
                                        <td style={{ width: "29%" }} className="text-center">
                                            <span className="glyphicon glyphicon-film" onClick={(e) => { e.stopPropagation(); props.setFlgSliderType(1, feeDueDtls.studentId) }}></span>
                                            <span className="glyphicon glyphicon-refresh" onClick={(e) => { e.stopPropagation(); refreshStudentFeeDue(feeDueDtls.studentId); }} />
                                            <span className="glyphicon glyphicon-edit" onClick={(e) => { e.stopPropagation(); props.setFlgSliderType(2, feeDueDtls); }} />
                                            <span className="glyphicon glyphicon-print" />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <button type="button" style={{ marginTop: 15 }} className="btn btn-primary pull-right" onClick={(e) => { e.stopPropagation(); handleProceed(); }}>
                    Proceed
                </button>
            </div>
        </div>
    );
}

export default StudentFeePaymentTable;
