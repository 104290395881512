import lstModules from 'common/json/lstModules.json';
import axios from 'axios';
const tokenid = localStorage.getItem('tokenid');
const httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;

export const studentDiscountListSvc = (cbStudentDiscountList) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/fee/discount/student/list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: {},
        withCredentials: true,
    }).then((response) => {
        // console.log("response in studentDiscountListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstDiscount = response.data.lstStudentDiscount;
        }
        cbStudentDiscountList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbStudentDiscountList(obj);
    })
}