import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var httpURL =
  lstModules['communication'].url + ':' + lstModules['communication'].port
var httpURLMain = lstModules['main'].url + ':' + lstModules['main'].port
var tokenid = localStorage.getItem('tokenid')

function getStudentListSvc ( commId,channelId, callBack) {
  axios({
    url: httpURL + '/sms/communication/channel/student/list',
    method: 'POST',
    params: {channelId:channelId, commId:commId},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console.log('Student List Catch error')
    })
}

export {
  getStudentListSvc
}
