import React, { useState, useEffect } from "react";
import axios from "axios";
import Datepicker from "react-datepicker";
import { toast, ToastContainer } from "react-toastify";
import "../css/TestConfigCtrl.css";
import "../css/EnquiryCtrl.css";
import {
  getStaffListSvc,
  getClassListSvc,
  saveSlotSvc,
  deleteSlotSvc,
  getSlotListSvc,
  informInvigilatorSvc,
} from "./SlotConfigSvc";

const SlotConfigSlider = (props) => {
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(props.tm);
  const [classId, setClassId] = useState(props.clsId);
  const [slotId, setSlotId] = useState(props.slotId);
  const [invig1infrom, setInvig1infrom] = useState(props.informInvigilator1Dt);
  const [invig2infrom, setInvig2infrom] = useState(props.informInvigilator2Dt);
  const [invigilator1Id, setInvigilator1Id] = useState(props.invigilator1Id);
  const [invigilator2Id, setInvigilator2Id] = useState(props.invigilator2Id);
  const [remarksForCand, setRemarksForCand] = useState(props.candidateRemarks);
  const [remarksForInv, setRemarksForInv] = useState(props.invigilatorRemarks);
  const [lstStaff, setLstStaff] = useState([]);
  const [lstClass, setLstClass] = useState([]);
  const [loader, setLoader] = useState("none");
  const [lstSlot, setLstSlot] = useState([]);
  const [slotIdForEdit, setSlotIdForEdit] = useState(0);
  const [lstTm, setLstTm] = useState([]);
  const controller = new AbortController();
  var signal = controller.signal;
  useEffect(() => {
    if (props.flgSlider) {
      setLoader("block");
      var promise = new Promise((resolve, reject) => {
        getClassListSvc(cbClassListResponse);
        getStaffListSvc(cbStaffListResponse);
        populate();
      });
      setTime(props.tm);
      setDate(props.dt);
      setClassId(props.clsId);
      setSlotId(props.slotId);
      setInvig1infrom(props.informInvigilator1Dt);
      setInvig2infrom(props.informInvigilator2Dt);
      setInvigilator1Id(props.invigilator1Id);
      setInvigilator2Id(props.invigilator2Id);
      setRemarksForCand(props.candidateRemarks);
      setRemarksForInv(props.invigilatorRemarks);
      // console.log("time:::" + props.sObj.tm);
      // console.log("date:::" + props.sObj.dt);
      // setRemarksForCand(props.sObj.cand)
    }
    return () => {
      controller.abort();
    };
  }, [
    props.flgSlider,
    props.tm,
    props.clsId,
    props.slotId,
    props.informInvigilator1Dt,
    props.informInvigilator2Dt,
    props.invigilator1Id,
    props.invigilator2Id,
    props.candidateRemarks,
    props.invigilatorRemarks,
  ]);

  function cbStaffListResponse(data) {
    if (data.SvcStatus === "Success") {
      setLstStaff(data.lstStaff);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbClassListResponse(data) {
    if (data.SvcStatus === "Success") {
      setLstClass(data.lstClass);
      setSlotIdForEdit(props.slotIdForEdit);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function handleClsIdChange(param) {
    setClassId(param);
  }
  // function handleDateChange(param) {
  //   setDate(param);
  // }
  function handleTimeChange(param) {
    setTime(param);
    // console.log("time ::" + param + typeof param);
  }
  function handleInvigilator1Change(param) {
    setInvigilator1Id(param);
  }
  function handleInvigilator2Change(param) {
    setInvigilator2Id(param);
  }
  function handleRemarksForInvChange(param) {
    setRemarksForInv(param);
  }
  function handleRemarksForCandChange(param) {
    setRemarksForCand(param);
  }

  function cbSaveSlotResponse(data, pSlotObj) {
    setLoader("none");
    if (data.SvcStatus === "Success") {
      // console.log(data)
      pSlotObj.slotId = data.slotId;
      // console.log(pSlotObj)
      toast.success(data.SvcMsg);
      props.closeSlider(data.slotId, pSlotObj);
    } else {
      // console.log(data)
      // console.log(pSlotObj)
      toast.error(data.SvcMsg);
      props.closeSlider(pSlotObj.slotId, pSlotObj);
    }
  }
  function saveSlot() {
    setLoader("block");
    const slotObj = {
      dt: date,
      tm: time,
      invigilator1Id: invigilator1Id,
      invigilator2Id: invigilator2Id,
      clsId: classId,
      slotId: !slotId ? 0 : slotId,
      candidateRemarks: remarksForCand,
      invigilatorRemarks: remarksForInv,
    };

    saveSlotSvc(cbSaveSlotResponse, slotObj);
  }

  function deleteSlot(param) {
    setLoader("block");
    deleteSlotSvc(param, cbDeleteSlot);
  }
  function cbDeleteSlot(data) {
    setLoader("none");
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      props.closeSlider(0, {});
    } else {
      toast.error(data.SvcMsg);
      props.closeSlider(slotId, {});
    }
  }
  function getDateForCalendar(strDt) {
    if (!strDt) return null;
    var parts = strDt.split("-");
    var d = parts[0];
    var m = parts[1] - 1;
    var y = parts[2];

    return new Date(y, m, d);
  }
  function handleDtChange(param) {
    var m = param.getMonth() + 1;
    if (m < 10) {
      m = "0" + m;
    }
    var d = param.getDate();
    if (d < 10) {
      d = "0" + d;
    }
    const dt = d + "-" + m + "-" + param.getFullYear();
    setDate(dt);
  }
  function populate() {
    var tempLstTime = [];
    var hours, minutes, ampm;
    for (var i = 0; i <= 24 * 59; i += 30) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes; // adding leading zero
      }
      ampm = hours % 24 < 12 ? "AM" : "PM";
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      if (hours < 10) {
        hours = "0" + hours;
      }
      tempLstTime.push(hours + ":" + minutes + " " + ampm);
    }
    // console.log(tempLstTime)
    setLstTm(tempLstTime);
  }

  function informInvigilator1() {
    // console.log( "inform invigilator 1 triggered slotId/invigilator1Id" + slotId + "/" + invigilator1Id );
    informInvigilatorSvc(cbInformInvigilatorResp, slotId, 1);
  }
  function informInvigilator2() {
    informInvigilatorSvc(cbInformInvigilatorResp, slotId, 2);
  }
  function cbInformInvigilatorResp(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      props.closeSlider(data.slotId, props);
    } else {
      toast.error(data.SvcMsg);
    }
  }

  return (
    <form
      className="form form-horizontal"
      style={{
        pointerEvents: loader === "none" ? "auto" : "none",
        height: "100%",
        width: "100%",
        overflowX: "hidden",
        background: "#fff",
        border: "1px solid #ccc",
        borderRadius: 10,
      }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <button
        type="button"
        className="btn btn-info text-center btnCloseDiv"
        onClick={(e) => props.closeSlider(slotId, props)}
      >
        <span
          id="close"
          title="Close Slider"
          className="glyphicon glyphicon-remove"
        ></span>
      </button>
      <div className="row">
        <div className="form-group col-md-12">
          <label className="control-label col-sm-3">Date:</label>
          <div className="col-sm-2 padding-remove">
            <Datepicker
              type="date"
              className="form-control"
              selected={getDateForCalendar(date)}
              onChange={handleDtChange}
              dateFormat="dd-MM-yyyy"
            ></Datepicker>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <label className="control-label col-sm-3">Time:</label>
          <div className="col-sm-2 padding-remove">
            <select
              className="form-control"
              onChange={(e) => handleTimeChange(e.target.value)}
              value={time}
            >
              <option value={0}>{time}</option>
              {lstTm.map((time, idx) => {
                return (
                  <option key={idx} vlaue={lstTm[idx]}>
                    {lstTm[idx]}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>

      {/* <label>Time:</label>
            {<TimePicker
              label="Time"
              value={time}/> } */}
      <div className="row">
        <div className="form-group col-md-12">
          <label className="control-label col-sm-3">Class:</label>
          <div className="col-sm-2 padding-remove">
            <select
              className="form-control"
              onChange={(e) => handleClsIdChange(e.target.value)}
              value={classId}
            >
              <option value={0}>Class</option>
              <option value={99}>All</option>

              {lstClass.map((cls, idx) => {
                return (
                  <option key={idx} value={cls.clsId}>
                    {cls.cls}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-md-12">
          <label className="control-label col-sm-3">Invigilator 1:</label>
          <div className="col-sm-9 padding-remove">
            <div className="col-sm-3 padding-remove">
              <select
                className="form-control"
                value={invigilator1Id}
                onChange={(e) => handleInvigilator1Change(e.target.value)}
              >
                <option value={0}>Invigilator 1</option>
                {lstStaff.map((local, idx) => {
                  return (
                    <option key={idx} value={local.staffId}>
                      {local.staff}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-sm-9 padding-remove">
              <div className="col-sm-3 padding-remove">
                <label
                  className="text-primary"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={(e) => informInvigilator1()}
                >
                  Inform Invigilator 1
                </label>
              </div>

              <div className="col-sm-4 text-left">
                <label className="link">Informed on: {invig1infrom}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <label className="control-label col-sm-3">Invigilator 2:</label>
          <div className="col-sm-9 padding-remove">
            <div className="col-sm-3 padding-remove">
              <select
                className="form-control"
                onChange={(e) => handleInvigilator2Change(e.target.value)}
                value={invigilator2Id}
              >
                <option value={0}>Invigilator 2</option>
                {lstStaff.map((local, idx) => {
                  return (
                    <option
                      key={idx}
                      defaultValue={local.invigilator2Id}
                      value={local.staffId}
                    >
                      {local.staff}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-sm-9 padding-remove">
              <div className="col-sm-3 padding-remove">
                <label
                  className="text-primary"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={(e) => informInvigilator2()}
                >
                  Inform Invigilator 2
                </label>
              </div>
              <div className="col-sm-4 text-left">
                <label className="link">Informed on {invig2infrom}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-md-12">
          <label className="control-label col-sm-3">
            Remarks For Invigilator:
          </label>
          <div className="col-sm-6 padding-remove">
            <textarea
              rows="8"
              cols="80"
              maxLength={500}
              defaultValue={remarksForInv}
              className="form-control"
              onChange={(e) => handleRemarksForInvChange(e.target.value)}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <label className="control-label col-sm-3">
            Remarks For Candidate:
          </label>
          <div className="col-sm-6 padding-remove">
            <textarea
              rows="8"
              cols="80"
              maxLength={500}
              defaultValue={remarksForCand}
              className="form-control"
              onChange={(e) => handleRemarksForCandChange(e.target.value)}
            ></textarea>
          </div>
        </div>
      </div>
      {/* 
      <div className="row">
        <div className="form-group col-md-12">
          <label className="control-label col-sm-3">Invigilator 1 </label>
          <div className='col-sm-6 padding-remove'>
            <label className="link" >Informed on: {invig1infrom}</label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-6">
          <label className="control-label col-sm-5">Invigilator 2 informed on:</label>
          <div className='col-sm-6 padding-remove'>
            <label className="link">Informed on:{invig2infrom}</label>
          </div>
        </div>
      </div> */}
      <div className="row">
        <button
          type="button"
          className="btn btn-info btn-space"
          onClick={(e) => saveSlot()}
        >
          Save Slot
        </button>
        <button
          type="button"
          className="btn btn-info btn-space"
          onClick={(e) => deleteSlot(slotId, cbDeleteSlot)}
        >
          Delete Slot
        </button>
      </div>
      {/* <div className="row">
        <br></br>
        <button type="button" className="btn btn-info btn-space" onClick={(e) => informInvigilator1()}>
          Inform Invigilator 1
        </button>
        <button type="button" className="btn btn-info btn-space" onClick={(e) => informInvigilator2()}>
          Inform Invigilator 2
        </button>
      </div> */}
    </form>
  );
};
export default SlotConfigSlider;
