import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "common/css/master.css";
import "../css/transport.css";
import DropDown from "common/components/DropDown";
import { saveStopLatLngSvc } from "./routeSvc";

const LatLngSlider = (props) => {
  const [loader, setLoader] = useState("none");
  const [latLng, setLatLng] = useState(0);
  const controller = new AbortController();
  const signal = controller.signal;
  useEffect(() => {
    if (props.flgSliderLatLng) {
      // console.log(props.latLng);
      setLatLng(props.latLng);
    }
    return () => {
      controller.abort();
    };
  }, [props.latLng, props.flgSliderLatLng, props.stopId]);

  function save() {
    saveStopLatLngSvc(props.stopId, latLng, cbSaveLatLngResponse);
  }
  function cbSaveLatLngResponse(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      props.generateRandomNo();
      props.closeSlider();
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  return (
    <>
      <div
        className="col-sm-11 col-centered padding-remove"
        style={{
          pointerEvents: loader === "none" ? "auto" : "none",
          height: "100%",
          width: "100%",
          background: "#fff",
          border: "1px solid #ccc",
          borderRadius: 10,
        }}
      >
        <div
          className="EnquiryHead col-sm-12 col-centered"
          style={{
            border: "1px solid #aaa",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            fontWeight: "bold",
          }}
        >
          <h4>Coordinates </h4>
        </div>
        <form className="form form-horizontal">
          <button
            type="button"
            className="btn btn-info text-center btnCloseDiv"
            onClick={props.closeSlider}
            style={{ right: "50%" }}
          >
            <span
              id="close"
              title="Close Slider"
              className="glyphicon glyphicon-remove"
            ></span>
          </button>
          <div className="col-sm-9">
            <div className="row">
              <label className="control-label col-sm-4">Coordinates</label>
              <div className="form-group col-sm-8">
                <input
                  type="text"
                  value={latLng}
                  className="form-control"
                  onChange={(e) => setLatLng(e.target.value)}
                ></input>
              </div>
            </div>
            <div
              className="row"
              style={{ width: "100%", paddingBottom: "1px" }}
            >
              <div className="pull-right">
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={(e) => save()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default LatLngSlider;
