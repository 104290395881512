import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { saveStudentSvc } from "./parentLoginSvc";

const ParentAppLoginTable = (props) => {
    const [tokenid, setTokenid] = useState(localStorage.getItem("tokenid"));
    const [lstParentLogin, setLstParentLogin] = useState(props.lstParentLogin);
    const [lstParentLoginTable, setLstParentLoginTable] = useState(props.lstParentLogin);
    const [lstSelect, setLstSelect] = useState(props.lstSelect);
    const [isSelectedAll, setIsSelectedAll] = useState(false);


    const [studentFiltered, setStudentFiltered] = useState("");
    const [parentFiltered, setParentFiltered] = useState("");
    const [accessFiltered, setAccessFiltered] = useState(false);


    useEffect(() => {
        // console.log("parentAppLoginTable called, parameters passed");
        // console.log("props.classSectionId:", props.classSectionId);
        // console.log("lstParentLogin:", props.lstParentLogin);
        // console.log("lstSelect:", props.lstSelect);
        // console.log("classId:", props.classId);

        if (props.lstParentLogin && props.lstParentLogin.length > 0) {
            setLstParentLogin(props.lstParentLogin);
        }
    }, [props.classId, props.lstParentLogin]);

    const rowClick = (id) => {
        const updatedParentLogin = lstParentLogin.map(student => {
            return {
                ...student,
                isSelected: student.studentId === id,
            };
        });

        setLstParentLogin(updatedParentLogin);
    };

    const saveSingleStudent = (sId, accessStatus) => {
        props.setLoader("block");
        props.setOpacity(0.5);

        const lstAccess = [{
            "studentId": sId,
            "accessStatus": accessStatus
        }];
        saveStudentSvc(lstAccess, saveStudentResponse);
    }

    const saveStudents = () => {
        props.setLoader("block");
        props.setOpacity(0.5);

        const lstAccess = lstSelect.map(student => ({
            "studentId": student.studentId,
            "accessStatus": student.accessStatus
        }));

        saveStudentSvc(lstAccess, saveStudentResponse);
    };



    const saveStudentResponse = (data) => {
        let obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            props.getStudentsList(props.classId, props.classSectionId);
            // console.log("getStudentList called from saveStudent Response in parentapploginTable");
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        props.setLoader("none");
        props.setOpacity(1);
    };


    const getAccessStatusForStudent = (studentId) => {
        // console.log("getAccessStatusForStudent called, studentId", studentId);
        // console.log("lstSelect", lstSelect);
        const studentObject = lstSelect.find(student => student.studentId === studentId);
        // console.log("returned", studentObject ? studentObject.accessStatus : null);
        return studentObject ? studentObject.accessStatus : null;
    };

    const updateAccessStatusForStudent = (sId, newAccessStatus) => {
        if (lstSelect && lstSelect.length) {
            const updatedLstSelect = lstSelect.map(student => {
                if (student.studentId === sId) {
                    return {
                        ...student,
                        accessStatus: newAccessStatus
                    };
                }
                return student;
            });
            setLstSelect(updatedLstSelect);
            if (newAccessStatus === false && isSelectedAll === true)
                setIsSelectedAll(false);
        }
    };

    const updateSelectAll = (selected) => {
        // console.log("updateSelectAll called, selected", selected);
        if (lstSelect && lstSelect.length > 0) {
            const updatedLstSelect = lstSelect.map(student => ({
                ...student,
                accessStatus: (student.email !== null && student.email !== "-") ? selected : false,
            }));
            // console.log("updatedLstSelect", updatedLstSelect);

            setLstSelect(updatedLstSelect);
            setIsSelectedAll(selected);
        }
    };

    const handleChangeStudentFiltered = (fStudent) => {
        setStudentFiltered(fStudent);
        filterStudentsTable(fStudent, parentFiltered, accessFiltered);
    }

    const handleParentFiltered = (fParent) => {
        setParentFiltered(fParent);
        filterStudentsTable(studentFiltered, fParent, accessFiltered);
    }

    const handleAccessFiltered = (fAccess) => {
        setAccessFiltered(fAccess);
        filterStudentsTable(studentFiltered, parentFiltered, fAccess);
    }

    const filterStudentsTable = (fStudent, fParent, fAccess) => {
        // console.log("filterStudentTable called, fAccess",fAccess);
        let filteredStudentList = [];
        filteredStudentList = lstParentLogin.filter(student =>
            student.student.toString().toLowerCase().includes(fStudent.toLowerCase()));

        filteredStudentList = filteredStudentList.filter(student =>
            student.relative === undefined || student.relative === null ||
            student.relative.name === undefined || student.relative.name === null ||
            student.relative.name.toString().toLowerCase().includes(fParent.toLowerCase()));

        if (fAccess === true) {
            // console.log("condition satisfied if (fAccess === true) ")
            // console.log("filteredStudentList", filteredStudentList);
            filteredStudentList = filteredStudentList.filter(student =>
                student.relative === undefined || student.relative === null ||
                student.relative.appAccess === undefined || student.relative.appAccess === null ||
                student.relative.appAccess === true);
            // console.log("filteredStudentList", filteredStudentList);
        }
        setLstParentLoginTable(filteredStudentList);
    }

    return (
        <div className="col-md-12">
            <div className="col-md-12">
                <div className="col-md-12 padding-remove">
                    <div className="col-md-3 paddding-remove pull-right">
                        <div className="col-md-4 paddding-remove  text-right" style={{ marginTop: "10px" }}>
                            <input
                                type="checkbox"
                                title={accessFiltered ? "show all students" : "show only parent app users"}
                                checked={accessFiltered}
                                onChange={(e) => handleAccessFiltered(e.target.checked)}
                            />
                        </div>
                        <div className="col-md-8 paddding-remove  text-right">
                            <button type="button" style={{ margin: "3px 0", width: 150 }} onClick={() => saveStudents()} className="btn btn-success">Save Access</button>
                        </div>
                    </div>
                </div>
                <table className="tableLayout1">
                    <thead>
                        <tr>
                            <th style={{ width: 90 }}>
                                <input type="text" value={studentFiltered} onChange={(e) => handleChangeStudentFiltered(e.target.value)} className="form-control inp-search" placeholder="Student" />
                            </th>
                            <th style={{ width: 30 }}> Gender </th>
                            <th style={{ width: 40 }}> DOB </th>
                            <th style={{ width: 40 }}> Relation </th>
                            <th style={{ width: 90 }}>
                                <input type="text" value={parentFiltered} onChange={(e) => handleParentFiltered(e.target.value)} className="form-control inp-search" placeholder="Parent" />
                            </th>
                            <th style={{ width: 100 }}> eMail </th>
                            <th style={{ width: 30 }}> Status </th>
                            {/* <th style={{ width: 30 }}> Access </th> */}
                            <th style={{ width: 30 }}>
                                <div style={{ float: 'left', width: '70%', marginTop: 6 }}>Select </div>
                                <div style={{ width: '15%', float: 'left' }}>
                                    <input
                                        type="checkbox"
                                        checked={isSelectedAll}
                                        onChange={(e) => updateSelectAll(e.target.checked)}
                                    />
                                </div>
                            </th>
                            <th style={{ width: 30 }}> Access Given On </th>
                            <th style={{ width: 40 }}> Action </th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv parentApp">
                    <table id="parentLoginTable" className="tableLayout">
                        <tbody id="parentLoginTableBody">
                            {lstParentLoginTable && lstParentLoginTable.length > 0 ?
                                lstParentLoginTable.map((students, i) => {
                                    return (
                                        <tr key={students.studentId} id={students.studentId} onClick={(e) =>
                                            rowClick(students.studentId)
                                        }>
                                            <td style={{ width: 90 }} className="text-left">
                                                {students.student}
                                            </td>
                                            <td style={{ width: 30 }} className="text-center">
                                                {students.gender}
                                            </td>
                                            <td style={{ width: 40 }} className="text-center">
                                                {students.dob}
                                            </td>
                                            <td style={{ width: 40 }} className="text-center">
                                                {students.relative && students.relative.relation}
                                            </td>
                                            <td style={{ width: 90 }} className="text-left">
                                                {students.relative && students.relative.name}
                                            </td>
                                            <td style={{ width: 100 }} className="text-left">
                                                {students.relative && students.relative.email}
                                            </td>
                                            <td style={{ width: 30 }} className="text-center">
                                                {students.relative && students.relative.emailStatus === true ?
                                                    <i className="fa fa-check" aria-hidden="true"></i>
                                                    : "-"}
                                            </td>
                                            {/* <td style={{ width: 30 }} className="text-center">
                                            {students.relative.appAccess === true ? "enabled" : "-"}
                                        </td> */}
                                            <td style={{ width: 30 }} className="text-center">
                                                <input
                                                    type="checkbox"
                                                    // id={students.studentId + students.relative.relation}
                                                    checked={getAccessStatusForStudent(students.studentId)}
                                                    // checked={lstSelect.some((selected) => selected.studentId === students.studentId && selected.accessStatus)}
                                                    onChange={(e) => updateAccessStatusForStudent(students.studentId, e.target.checked)}
                                                    disabled={!students.relative || students.relative.email === null || students.relative.email === "-"}
                                                />
                                            </td>
                                            <td style={{ width: 30 }} className="text-center">
                                                {students.relative && students.relative.accessGivenDt}
                                            </td>
                                            <td style={{ width: 40 }} className="text-center">
                                                <label
                                                    style={{
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer'
                                                    }}
                                                    className='text-primary'
                                                    onClick={() => saveSingleStudent(
                                                        students.studentId, getAccessStatusForStudent(students.studentId)
                                                    )}
                                                    disabled={!students.relative || students.relative.email === null || students.relative.email === "-"}
                                                >
                                                    {/* {students.relative.appAccess ? "Disable" : "Enable"} */}
                                                    Save Access
                                                </label>
                                            </td>
                                        </tr>
                                    )
                                }) : null
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ParentAppLoginTable;
