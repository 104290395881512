import React, { useState, useEffect } from "react";
import { getTeachersSvc, getSubjectsSvc, saveFeedbackSvc, getPtmDetails } from "./ptmFeedBackSvc.js";
import { ToastContainer, toast } from "react-toastify";
import FeedbackField from "./feedbackField.js";
import { useLocation } from "react-router-dom";
import "../css/ptmFeedback.css";

const PtmFeedBack = () => {

    const search = useLocation().search;

    const [ptmStudentId, setPtmStudentId] = useState(
        new URLSearchParams(search).get('ptmStudentId')
    );
    const [schoolId, setSchoolId] = useState(
        parseInt(new URLSearchParams(search).get('schoolId'))
    )
    const [ptmDate, setPtmDate] = useState("");
    const [ptmstudentName, setPtmStudentName] = useState(
    );
    const [ptmstudentClass, setPtmStudentClass] = useState("");
    const [ptmstudentSection, setPtmStudentSection] = useState("");
    const [loader, setLoader] = useState("none");
    const [lstSubject, setLstSubject] = useState([]);
    const [lstTeacher, setLstTeacher] = useState([]);
    const [feedbackModelId, setFeedbackModelId] = useState(
        new URLSearchParams(search).get('feedbackModeId')
    );
    const [sectionId, setSectionId] = useState(
        new URLSearchParams(search).get('sectionId')
    );

    const [rateStudies, setRateStudies] = useState(0);
    const [rateHygiene, setRateHygiene] = useState(0);
    const [rateActivities, setRateActivities] = useState(0);
    const [rateInfra, setRateInfra] = useState(0);
    const [rateSchool, setRateSchool] = useState(0);

    const [favSub1, setFavSub1] = useState(0);
    const [favSub2, setFavSub2] = useState(0);
    const [favSub3, setFavSub3] = useState(0);
    const [favTeacher1, setFavTeacher1] = useState(0);
    const [favTeacher2, setFavTeacher2] = useState(0);

    const [feedbackStudies, setFeedbackStudies] = useState("");
    const [feedbackHygiene, setFeedbackHygiene] = useState("");
    const [feedbackActivities, setFeedbackActivities] = useState("");
    const [feedbackInfra, setFeedbackInfra] = useState("");
    const [feedbackSchool, setFeedbackSchool] = useState("");

    const [formDisabled, setFormDisabled] = useState(false);
    const [key, setKey] = useState(0);




    // const labels = [
    //     "Studies",
    //     "Hygiene",
    //     "Sports / Extra Curricular Activities",
    //     "Infrastructure",
    //     "School"
    // ]

    useEffect(() => {
        // console.log("ptmFeedback");
        setPtmDetails();
        setTeachers();
        setSubjects();
        if(formDisabled){
            toast("Can't edit ptm feedback");
        }
    }, [key]);

    const setPtmDetails = () => {
        getPtmDetails(schoolId, ptmStudentId, sectionId, ptmDetailsResponse);
    }

    const ptmDetailsResponse = (data) => {
        var obj = data;
        // console.log("obj in ptmDetailResponse", obj);
        if (obj.status === "Success") {
            setPtmStudentName(obj.feedbackDtls.studentNm);
            setPtmDate(obj.feedbackDtls.ptmDt);
            setPtmStudentClass(obj.feedbackDtls.clsNm);
            setPtmStudentSection(obj.feedbackDtls.secNm);
            setPtmStudentId(obj.feedbackDtls.ptmStudentId);
            setRateStudies(obj.feedbackDtls.ratingStudies);
            setRateHygiene(obj.feedbackDtls.ratingHygiene);
            setRateActivities(obj.feedbackDtls.ratingActivities);
            setRateInfra(obj.feedbackDtls.ratingInfra);
            setRateSchool(obj.feedbackDtls.ratingSchool);
            setFeedbackStudies(obj.feedbackDtls.studies);
            setFeedbackHygiene(obj.feedbackDtls.hygiene);
            setFeedbackActivities(obj.feedbackDtls.activities);
            setFeedbackInfra(obj.feedbackDtls.infra);
            setFeedbackSchool(obj.feedbackDtls.school);
            setFavSub1(obj.feedbackDtls.sub1);
            setFavSub2(obj.feedbackDtls.sub2);
            setFavSub3(obj.feedbackDtls.sub3);
            setFavTeacher1(obj.feedbackDtls.tch1);
            setFavTeacher2(obj.feedbackDtls.tch2);
            setFormDisabled(!(obj.feedbackDtls.parentEnable))
            // console.log("!(obj.feedbackDtls.parentEnable)", !(obj.feedbackDtls.parentEnable))
            // console.log("obj.feedbackDtls.sub1", obj.feedbackDtls.sub1)
        } else {
            toast.error(obj.message);
        }
    }

    const setTeachers = () => {
        // setLoader("block");
        getTeachersSvc(schoolId, sectionId, teachersListResponse);
    }

    const teachersListResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            setLstTeacher(obj.lstTeacher);
        } else {
            toast.error(obj.message);
        }
        // setLoader('none');
    }



    const setSubjects = () => {
        getSubjectsSvc(schoolId, sectionId, subjectsListResponse);
    }

    const subjectsListResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            setLstSubject(obj.lstSubject);
        } else {
            toast.error(obj.message);
        }
    }

    const submitFeedback = () => {
        const feedback = {
            "ptmStudentId": ptmStudentId,
            "ratingStudies": rateStudies,
            "ratingHygiene": rateHygiene,
            "ratingActivities": rateActivities,
            "ratingInfra": rateInfra,
            "ratingSchool": rateSchool,

            "studies": feedbackStudies,
            "hygiene": feedbackHygiene,
            "activities": feedbackActivities,
            "infra": feedbackInfra,
            "school": feedbackSchool,

            "sub1Id": favSub1,
            "sub2Id": favSub2,
            "sub3Id": favSub3,
            "tch1Id": favTeacher1,
            "tch2Id": favTeacher2,
            "feedbackModeId": feedbackModelId,
            "schoolId": schoolId
        }
        saveFeedbackSvc(feedback, submitFeedbackResponse)
    }

    const submitFeedbackResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            toast.success(obj.message);
            setKey((prevKey)=> prevKey + 1);
        } else {
            toast.error(obj.message);
        }

    }

    const getFavTeacherName = (tchId,n) => {
        for (var i =0; i<lstTeacher.length; i++) {
            if(lstTeacher[i].staffId === tchId){
                return lstTeacher[i].staff;
            }
        }
        return "Teacher "+n;
    }

    const getFavSubjectName = (subId,n) => {
        for (var i =0; i<lstSubject.length; i++) {
            if(lstSubject[i].subjectId === subId){
                return lstSubject[i].subject;
            }
        }
        return "Subject "+n;
    }

    return (
        <>
            <ToastContainer />
            <form>
                {/* <div style={{ backgroundColor: "#f8f8f8", margin: "10px 2%", width: "96%", padding: 0 }}> */}
                <div className="col-xs-12" style={{ margin:0, paddingTop:10, backgroundColor:"white" }} >
                    <div className="ptm-feedback-head" style={{ margin: 0, padding: 0, width: "100%", fontSize:"initial", fontWeight:500 }}>
                        {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
                        <div className="col-xs-12" style={{ padding: 0 }}>
                            <div className="col-xs-5" style={{ padding: 0 }}>
                                {/* Name:                   to be commented */}
                                {ptmstudentName}
                            </div>
                            <div className="col-xs-3 pull-right" style={{ padding: 0, textAlign: "right" }} >
                                {/* Cls-Sec                 to be commented */}
                                {ptmstudentClass}-{ptmstudentSection}
                            </div>
                        </div>
                        <div className="col-xs-5" style={{ padding: 0 }}>
                            PTM Date: 
                            {/* Date                        to be commented */}
                            {' '+ptmDate}
                        </div>
                        <hr
                            style={{
                                width: "100%",
                                height: 5,
                                margin: "5px 0"
                            }}
                        />
                    </div>
                    <div className="form form-wrap feedback-form">
                        <div style={{ padding: 0, width: "100%" }}>
                            {/* {labels.map((label) =>
                                <FeedbackField
                                    label={label}
                                />
                            )

                            } */}
                            <div >
                                <FeedbackField
                                    label="Studies"
                                    rate={rateStudies}
                                    setRate={setRateStudies}
                                    feedback={feedbackStudies}
                                    setFeedback={setFeedbackStudies}
                                    formDisabled={formDisabled}
                                />
                            </div>
                            <div>
                                <FeedbackField
                                    label="Hygiene"
                                    rate={rateHygiene}
                                    setRate={setRateHygiene}
                                    feedback={feedbackHygiene}
                                    setFeedback={setFeedbackHygiene}
                                    formDisabled={formDisabled}
                                />
                            </div>
                            <div>
                                <FeedbackField
                                    label="Sports/Extra Curricular Activities"
                                    rate={rateActivities}
                                    setRate={setRateActivities}
                                    feedback={feedbackActivities}
                                    setFeedback={setFeedbackActivities}
                                    formDisabled={formDisabled}
                                />
                            </div>
                            <div>
                                <FeedbackField
                                    label="Infrastructure"
                                    rate={rateInfra}
                                    setRate={setRateInfra}
                                    feedback={feedbackInfra}
                                    setFeedback={setFeedbackInfra}
                                    formDisabled={formDisabled}
                                />
                            </div>
                            <div>
                                <FeedbackField
                                    label="School"
                                    rate={rateSchool}
                                    setRate={setRateSchool}
                                    feedback={feedbackSchool}
                                    setFeedback={setFeedbackSchool}
                                    formDisabled={formDisabled}
                                />
                            </div>
                        </div>
                        {/* <div style={{margin:0, padding:0, width:"100%"}}> */}
                        <div className="col-xs-12" style={{ padding: 0 }}>
                            {/* <div style={{margin:0, padding:0, width:"100%"}}> */}
                            <div className="col-xs-12" style={{ padding: 0 }}>
                                <label>Your Favourite Subjects?</label>
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:15}}>
                            <div className="col-xs-4"  style={{ padding: "0px 0px 0px 15px" }}>
                                <select
                                    style={{ width: "95%", height:35 }}
                                    defaultValue={favSub1}
                                    disabled={formDisabled}
                                    onChange={(e) => {
                                        setFavSub1(e.target.value)
                                    }}
                                    >
                                    <option
                                        value={favSub1}>{getFavSubjectName(favSub1,1)} </option>
                                    {lstSubject && lstSubject.map((subject) => (
                                        <option key={subject.subjectId} value={subject.subjectId}>{subject.subject}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-xs-4" style={{ padding: 0 }}>
                                <select
                                    style={{ width: "95%", height:35 }}
                                    defaultValue={favSub2}
                                    disabled={formDisabled}
                                    onChange={(e) => {
                                        setFavSub2(e.target.value)
                                    }}
                                    >
                                    <option
                                        value={favSub2}>{getFavSubjectName(favSub2,2)} </option>
                                    {lstSubject && lstSubject.map((subject) => (
                                        <option key={subject.subjectId} value={subject.subjectId}>{subject.subject}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-xs-4" style={{ padding: 0 }}>
                                <select
                                    style={{ width: "95%", height:35 }}
                                    defaultValue={favSub3}
                                    disabled={formDisabled}
                                    onChange={(e) => {
                                        setFavSub3(e.target.value)
                                    }}
                                    >
                                    <option
                                        value={favSub3}>{getFavSubjectName(favSub3,3)} </option>
                                    {lstSubject && lstSubject.map((subject) => (
                                        <option key={subject.subjectId} value={subject.subjectId}>{subject.subject}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {/* <div style={{margin:0, padding:0, width:"100%"}}> */}
                        <div className="col-xs-12" style={{ padding: 0 }}>
                            <label>Your Favourite Teachers?</label>
                        </div>
                        <div className="row" style={{marginBottom:15}}>
                            <div className="col-xs-4"  style={{ padding: "0px 0px 0px 15px" }}>
                                <select
                                    style={{ width: "95%", height:35 }}
                                    defaultValue={favTeacher1}
                                    disabled={formDisabled}
                                    onChange={(e) => {
                                        setFavTeacher1(e.target.value)
                                    }}
                                    >
                                    <option
                                        value={favTeacher1}>{getFavTeacherName(favTeacher1, 1)}</option>
                                    {lstTeacher && lstTeacher.map((teacher) => (
                                        <option key={teacher.staffId} value={teacher.staffId}>{teacher.staff}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-xs-4"  style={{ padding: 0 }}>
                                <select
                                    style={{ width: "95%", height:35 }}
                                    defaltValue={favTeacher2}
                                    disabled={formDisabled}
                                    onChange={(e) => {
                                        setFavTeacher2(e.target.value)
                                    }}
                                >
                                    <option
                                        value={favTeacher2}>{getFavTeacherName(favTeacher2, 2)}</option>
                                    {lstTeacher.map((teacher) => (
                                        <option key={teacher.staffId} value={teacher.staffId}>{teacher.staff}</option>
                                    ))}
                                </select>

                            </div>
                        </div>
                        <div className="col-xs-9 col-xs-push-1 feedback-form-submit" style={{ padding: "10px 0px" }}>
                            <button
                                type="button"
                                className="btn btn-success form-control"
                                // style={{ borderStyle: "groove", width: "100%" }}
                                onClick={() => submitFeedback()}
                                disabled={formDisabled}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </form>

        </>
    )

}

export default PtmFeedBack;
