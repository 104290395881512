import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import { tcDtlsSvc, lstStudentSvc, tcApproverSvc } from './tcSvc';
import TcSliderTable from './tcSliderTable';
import TcForm from './tcForm';

const TcSlider = (props) => {
    const { certificateId, studentId, setStudentId, getCertificateId, setCertificateId, printCertificate, closeCertificateEditSlider } = props;

    const [editLoader, setEditLoader] = useState("none");
    const [editOpacity, setEditOpacity] = useState(1);
    const [lstStudent, setLstStudent] = useState([]);
    const [studentName, setStudentName] = useState("");
    const [clsSection, setClsSection] = useState("");
    const [lstApprovalAuthority, setLstApprovalAuthority] = useState([]);
    const [studentDtlsObj, setStudentDtlsObj] = useState({});
    const [flgPrinted, setFlgPrinted] = useState(false);


    useEffect(() => {
        // console.log("TcSlider called, certificateId", certificateId);
        getLstStudent();
        getApprovalAuthority();
        if (!!certificateId) {
            getTcDtls(certificateId);
        }else {
            setFlgPrinted(false);
        }
    }, [certificateId, studentId]);

    const getLstStudent = () => {
        // console.log("getLstStudent called");
        setEditLoader("block");
        setEditOpacity(0.5);
        lstStudentSvc(lstStudentResposnse);
    }



    const lstStudentResposnse = async (data) => {
        // console.log("lstStudentResposnse called, data", data);
        if (data.status === "Success") {
            await setLstStudent(data.lstStudent);
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setEditLoader("none");
        setEditOpacity(1);
    }

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    const openCertificatePdf = (pCertificateId) => {
        // console.log("openCertificatePdf called, pCertificateId", pCertificateId);
        closeCertificateEditSlider();
        printCertificate(pCertificateId);
    }



    const getTcDtls = (pCertificateId) => {
        // console.log("getTcDtls called");
        setEditLoader("block");
        setEditOpacity(0.5);
        tcDtlsSvc(pCertificateId, tcDtlsResponse);
    }

    const tcDtlsResponse = (data) => {
        // console.log("tcDtlsResponse called, data", data);
        if (data.status === "Success") {
            // console.log("data.certificateDtls", data.certificateDtls);
            setStudentDtlsObj(data.certificateDtls);
            let printDate = data.certificateDtls.printDt;
            if (!!printDate && printDate !== "-") {
                setFlgPrinted(true);
            }
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setEditLoader("none");
        setEditOpacity(1);
    }




    const getApprovalAuthority = () => {
        // console.log("getApprovalAuthority called");
        setEditLoader("block");
        setEditOpacity(0.5);
        tcApproverSvc(approvalAuthorityResponse);
    }

    const approvalAuthorityResponse = (data) => {
        // console.log("approvalAuthorityResponse called, data", data);
        if (data.status === "Success") {
            setLstApprovalAuthority(data.lstApprovalAuthority);
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setEditLoader("none");
        setEditOpacity(1);
    }





    return (
        <div >
            <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={() => closeCertificateEditSlider()} style={{ right: "90%" }}>
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '58%', top: '45%', display: editLoader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="col-md-12 col-xs-12 col-centered padding-remove "
                style={{
                    cursor: (editLoader === "none") ? 'pointer' : 'none',
                    pointerEvents: (editLoader === "none") ? 'auto' : 'none',
                    opacity: editOpacity
                }}>
                <div className="col-md-12 col-xs-12 col-centered" style={{ marginTop: "40px" }}>
                    <div className="col-md-7 col-xs-12 padding-remove">
                        <TcSliderTable
                            lstStudent={lstStudent}
                            studentId={studentId}
                            setStudentId={setStudentId}
                            setClsSection={setClsSection}
                            setStudentName={setStudentName}
                            getCertificateId={getCertificateId}
                            setCertificateId={setCertificateId}
                            // setStudentObj={setStudentObj}
                            />
                    </div>
                    <div className="col-md-5 col-xs-12 padding-remove">
                        <TcForm
                            certificateId={certificateId}
                            setCertificateId={setCertificateId}
                            studentId={studentId}
                            studentName={studentName}
                            clsSection={clsSection}
                            setEditLoader={setEditLoader}
                            setEditOpacity={setEditOpacity}
                            openCertificatePDf={openCertificatePdf}
                            studentDtlsObj={studentDtlsObj}
                            lstApprovalAuthority={lstApprovalAuthority}
                            flgPrinted={flgPrinted}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TcSlider;