import React, { Component } from "react";
import lstModules from "common/json/lstModules.json";
import $ from "jquery";
import Header from "common/js/header/header";
import { toast, ToastContainer } from "react-toastify";
import "common/css/master.css";
import { getICardSvc } from "../../studentsNew/idCardPDF/IdCardSliderSvc";
// import './../bootstrap/css/bootstrap.min.css';

import axios from "axios";
import LoaderImage from "common/components/LoaderImage.jsx";
import IdCardSlider from "./IdCardSlider";
import AcademicYearPopup from "common/login/js/academicYearPopup";

//import {baseUrl} from './const';

var httpURLStudent = "";
var httpURL = "";
var queryString = require("querystring");
var schoolId = localStorage.getItem('schoolId');
var lstClass = [];
var screenId = 15;
var lstStudent = [];

var COL_STUDENTID = "td:eq(0)";
var tokenid = localStorage.getItem("tokenid");
var ctr = 0;
var ctx;
class Student extends Component {
  constructor(props) {
    super(props);
    ctx = this;
    this.state = {
      schoolId: 0,
      lstClass: [],
      lstStudent: [],
      lstErrors: [],

      flg: true,
      width: "97%",
      loader: "none",
      opacity: 1,
      tokenid: tokenid,
      showError: false,
      flgSlider: false,
      flgIdSlider: false,
      lstSelStudentId: [],
      lstStudentId: [],
      lstIdCard: [],
      academicYearId: localStorage.getItem('academicYearId') || 0,
      filteredsection: "",
      filteredStudentId: "",
      filteredAcademicYear: "",
      studentCount: 0,
      filteredStudentCount: 0,
    };
  }


  componentDidMount = async () => {
    // console.log("inside componentDidMount");
    this.handleResize();
    window.addEventListener("load", this.handleResize);
    window.addEventListener("resize", this.handleResize);
    this.getMasterData();
    window.addEventListener("keydown", this.handleKeyDown);
    await this.getStudentList(schoolId);
    this.setStudentTable();
  };
  handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      this.setState({ loader: "none", opacity: 1 });
    }
  };

  getMasterData = () => {
    this.getMstClassList(schoolId);
  };


  getMstClassList = (schoolId) => {
    this.setState({ loader: "block", opacity: 0.5 });
    axios({
      url: httpURL + "/sms/mst/cls/list",
      method: "POST",
      data: queryString.stringify({ schoolId: schoolId, academicYearId: this.state.academicYearId, screenId }),
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          this.setState({
            lstClass: response.data.lstClass,
          });
          lstClass = response.data.lstClass;
          this.fillClassCmb();
          this.getStudentList(schoolId);
        } else {
          toast.error(response.data.SvcMsg);
          if (response.data.SvcMsg === "You are not Logged In") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          } else if (response.data.SvcMsg === "Server Timed Out, Login Again") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          }
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((error) => {
        toast.error("Service failed " + error);
      });
  };

  getStudentList = (schoolId) => {
    this.setState({ loader: "block", opacity: 0.5 });
    // console.log(httpURLStudent + "/sms/student/list");
    axios({
      url: httpURLStudent + "/sms/student/list",
      method: "POST",
      data: queryString.stringify({ schoolId: schoolId, acdYrId: this.state.academicYearId, screenId }),
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          this.setState({
            lstStudent: response.data.lstStudents,
            studentCount: response.data.lstStudents.length,
          });
          lstStudent = response.data.lstStudents;
          // console.log(lstStudent);
          this.setStudentTable();
        } else {
          toast.error(response.data.SvcMsg);
          if (response.data.SvcMsg === "You are not Logged In") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          } else if (response.data.SvcMsg === "Server Timed Out, Login Again") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          }
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((error) => {
        toast.error("Service failed " + error);
      });
  };

  handleResize = () => {
    var width = $(".tableLayout1").parent().width() - 18;
    this.setState({ width: width });
  };

  fillClassCmb() {
    $("#inp_class").empty();
    $("#inp_class").prepend($("<option> </option>").val("Class").html("Class"));
    for (var i = 0; i < lstClass.length; i++) {
      var classId = lstClass[i].clsId;
      var classNm = lstClass[i].cls;
      $("#inp_class").append(
        $("<option> </option>").val(classId).html(classNm)
      );
    }
  }
  addStudentReady = () => {
    var studentId = 0;

    if (!this.state.flg) {
      // console.log(this.state.flg);
      localStorage.setItem("flg", this.state.flg);
      studentId = $("#studentTableBody tr.selected").attr("id");
    } else {
      // console.log("flg=true");
      localStorage.setItem("flg", true);
    }

    localStorage.setItem("studentId", studentId);

    var newPageUrl = "/studentDtls?studentId=" + studentId + '&flg=' + this.state.flg + "&academicYrId=" + this.state.academicYearId;
    // route to new page by changing window.location
    // window.open(newPageUrl, "")
    window.open(newPageUrl, "");
  };

  setStudentTable = () => {
    // console.log("control here");
    let count = 0;
    lstStudent = this.state.lstStudent;
    $("#studentTableBody tr").remove();
    var table = document.getElementById("studentTable");
    var tbody = document.getElementById("studentTableBody");
    var filterStudent =
      $("#student").val() !== undefined
        ? $("#student").val().toLowerCase()
        : "";
    var filterClass = isNaN($("#inp_class").find("option:selected").val())
      ? 0
      : parseInt($("#inp_class").find("option:selected").val());
    var filterBlocked = $("#inp_blocked").is(":checked");
    filterStudent = filterStudent.trim();
    // console.log(filterClass);
    // console.log(typeof filterClass);
    if (lstStudent) {
      for (var i = 0; i < lstStudent.length; i++) {
        var id = lstStudent[i].studentId;
        var student = lstStudent[i].student;
        var gender = lstStudent[i].gender;
        var blockedVal = lstStudent[i].blocked;
        var blocked = lstStudent[i].blocked;
        var academicYear = lstStudent[i].academicYear;
        var age = lstStudent[i].age;
        var clsId = lstStudent[i].clsId;
        var clsLabel = !lstStudent[i].cls ? "" : lstStudent[i].cls;
        var section = !lstStudent[i].section ? "" : lstStudent[i].section;
        var row = document.createElement("tr");
        row.id = id;

        row.onclick = async (e) => {
          $(e.target)
            .closest("tr")
            .addClass("selected")
            .siblings()
            .removeClass("selected");
          await this.setState({ flg: false });
          this.addStudentReady();
          //   this.setState({ flg: true });
        };

        if (
          student !== null &&
          student.toLowerCase().indexOf(filterStudent) === -1
        )
          continue;
        if (filterClass !== 0 && filterClass !== clsId) continue;
        if (this.state.filteredSection && this.state.filteredSection.toLowerCase() !== section.toLowerCase()) continue;
        if (this.state.filteredStudentId && !id.toString().includes(this.state.filteredStudentId)) continue;
        if (this.state.filteredAcademicYear && !academicYear.includes(this.state.filteredAcademicYear)) continue;
        if (filterBlocked !== blockedVal) continue;

        this.addTD(row, id, "");
        this.addTD(row, student, "");
        this.addTD(row, clsId, "no-display");
        this.addTD(row, gender, "text-center");
        this.addTD(row, age, "text-center");
        this.addTD(row, clsLabel, "text-center");
        this.addTD(row, section, "");
        this.addTD(row, academicYear, "text-center");
        this.addTD(row, blocked ? "Yes" : "-", "");
        this.addTDCBX(row, "StuCbx" + id, id, "text-center");
        // this.addTDAction(row, "View", "text-center");
        this.addTDAction(row, "text-center");
        tbody.append(row);
        count++;
      }
      table.append(tbody);
      this.setState({
        filteredStudentCount: count,
      });
    }
  };


  filterSection = async (fSection) => {
    await this.setState({ filteredSection: fSection });
    this.setStudentTable();
  }
  filterStudentId = async (fStudentId) => {
    await this.setState({ filteredStudentId: fStudentId });
    this.setStudentTable();
  }
  filterAcademicYear = async (fAcademicYear) => {
    await this.setState({ filteredAcademicYear: fAcademicYear });
    this.setStudentTable();
  }
  selectAll(e) {
    /* if ($('#cbxSelectAll').prop('checked') === true) {
            $('#studentTableBody tr').each(function () {
                if ($(this).find($('input[type=checkbox]')).prop('disabled') === false) {
                    $(this).find($('input[type=checkbox]')).prop('checked', true);
                }
            });

        }
        else {
            $('#studentTableBody tr input[type=checkbox]').prop('checked', false);
        } */
    var list_cbox = document.querySelectorAll(
      "#studentTableBody td input[type=checkbox]"
    );
    list_cbox.forEach((cbox) => {
      if (cbox.checked !== e.target.checked) cbox.click();
    });
  }

  addTD(row, val, classname) {
    var cell = document.createElement("td");
    cell.className = classname;
    var text = document.createTextNode(val);
    cell.append(text);
    row.append(cell);
  }
  addTDCBX(row, id, rowId, classname) {
    var cell = document.createElement("td");
    cell.className = classname;
    var div = document.createElement("div");

    var cbx = document.createElement("input");
    cbx.type = "checkbox";

    cbx.id = id;
    cell.onclick = function (e) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      cbx.click();
      return true;
    };

    cbx.addEventListener("change", function (e) {
      e.stopPropagation();
      e.stopImmediatePropagation();
      ctx.handleLstStudentIdChange(rowId, e);
      return true;
    });
    cbx.addEventListener("click", function (e) {
      e.stopPropagation();
      e.stopImmediatePropagation();
      return false;
    });

    div.append(cbx);
    cell.append(div);
    row.append(cell);
  }
  addTDAction(row, classname) {
    var cell = document.createElement("td");
    cell.className = classname;

    // Create View link
    var viewLink = document.createElement("a");
    viewLink.href = "#";
    viewLink.textContent = "View";
    viewLink.addEventListener("click", async function (e) {
      e.stopPropagation();
      e.preventDefault();
      var tmpLstStudentId = [parseInt(e.target.closest("tr").id)];
      await ctx.setState({ lstStudentId: tmpLstStudentId });
      ctx.getIdCard();
    });

    // Create Delete link
    var deleteLink = document.createElement("a");
    deleteLink.href = "#";
    deleteLink.textContent = "Delete";
    deleteLink.style.marginLeft = "10px"; // Add some space between the links
    deleteLink.addEventListener("click", function (e) {
      e.stopPropagation();
      e.preventDefault();
      var studentId = parseInt(e.target.closest("tr").id);
      // Call a function to handle the delete action
      ctx.handleDelete(studentId);
    });

    // Append both links to the cell
    cell.appendChild(viewLink);
    cell.appendChild(deleteLink);
    row.append(cell);
  }
  cellClick(e) {
    console.error(e);
    e.stopPropagation();
    ctx.setState({ flgSlider: true });
    ctx.showSlider();
  }

  /*   addStudentReady = () => {
    var studentId = 0;

    if (!this.state.flg) {
      localStorage.setItem("flg", this.state.flg);
      studentId = $("#studentTableBody tr.selected").attr("id");
    } else {
      localStorage.setItem("flg", true);
    }

    localStorage.setItem("studentId", studentId);

    var newPageUrl = "/studentdtls?studentId="+studentId;
    // route to new page by changing window.location
    window.open(newPageUrl, "");
  };
 */
  highlightRow(table, id) {
    $("#" + table + " tr").each(function () {
      var studentId = $(this).find(COL_STUDENTID).text();
      if (studentId === id) {
        $(this).addClass("selected").siblings().removeClass("selected");
      }
    });
  }

  getWingId = (wingId) => {
    // console.log("wingId" + wingId);
  };

  setErrorTable = (errors) => {
    // console.log("setErrorTable method called inside student.js");
    // console.log("errors", errors);
    $("#errorTableBody tr").remove();
    var table = document.getElementById("errorTable");
    var tbody = document.getElementById("errorTableBody");
    var lstErrors = errors;
    for (var i = 0; i < lstErrors.length; i++) {
      // console.log(i);
      var errorId = i;
      var sheetName = lstErrors[i].sheetName;
      var lineNo = lstErrors[i].rowNo;
      var error = lstErrors[i].message;

      // console.log(lstErrors[i]);
      // console.log(errorId, sheetName, lineNo, error);
      var row = document.createElement("tr");
      row.id = i;

      this.addTD(row, errorId, "no-display");
      this.addTD(row, sheetName, "text-left");
      this.addTD(row, lineNo, "text-center");
      this.addTD(row, error, "text-center");

      tbody.append(row);
    }
    table.append(tbody);
  };
  setErrorList = async (errors) => {
    await this.setState({ lstErrors: errors });
    this.setErrorTable(errors);
    // console.log("errors",errors);
    // console.log("this.state.lstErrors",this.state.lstErrors);
  };
  triggerUploadExcel = () => {
    $("#excelUpload").trigger("click");
  };
  uploadExcel = (event) => {
    this.setState({ loader: "block", opacity: 0.5 });
    /* $("#excelUpload").on('change', function (event) { */

    if (!event.target.files) {
      this.setState({ loader: "none", opacity: 1 });
      return;
    }

    var files = event.target.files; //FileList object
    /* if (!event.target.files.length) {
            // console.log("no file selected")
            this.setState({ loader: 'none', opacity: 1 });
            return;
        } */
    files = files[0];
    var formData = new FormData();
    formData.append("studentExcelFile", files);
    formData.append("screenId", screenId);
    formData.append("acdYrId", this.state.academicYearId);

    axios({
      url: httpURLStudent + "/sms/student/excel/upload",
      method: "POST",
      data: formData,
      headers: { tokenid },
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response.data);
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
          // $('#btn_showerror').trigger('click');
          this.getStudentList(schoolId);
          this.setStudentTable();
        } else if (response.data.SvcStatus === "Failure") {
          toast.error(response.data.SvcMsg);
          if (
            response.data.hasOwnProperty("errors") &&
            response.data.errors.length !== 0
          ) {
            $("#btn_showerror").trigger("click");
            this.setErrorList(response.data.errors);
          }
        } else {
          if (response.data.SvcMsg === "You are not logged in") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          } else if (
            response.data.SvcMsg === "Your Session is expired, login again"
          ) {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          } else {
            toast.error(response.data.SvcMsg);
            this.showErrors();
          }
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        toast.error("Service failed " + e);
        this.showErrors();
      });
    /* }); */
  };
  showErrors = () => {
    ctr++;
    if (ctr % 2 === 1) this.setState({ showError: true });
    else this.setState({ showError: false });
  };

  closeSlider = () => {
    this.setState({ flgSlider: false, flgIdSlider: false });
    $("body").removeClass("openSlider");
  };

  showSlider() {
    $("body").addClass("openSlider");
  }

  getIdCard() {
    // console.log(this.state.lstStudentId);
    getICardSvc(this.state.lstStudentId, this.cbGetICardSvc);
  }

  handleDelete(studentId) {
    // console.log(`handleDelete method called passed studentId`, studentId);
    this.setState({ loader: "block", opacity: 0.5 });
    // console.log("url:", httpURLStudent + "/sms/student/delete");
    axios({
      url: httpURLStudent + "/sms/student/delete",
      method: "POST",
      // data: queryString.stringify({ "studentId": studentId }),
      params:{"studentId":studentId},
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        // console.log("response in handleDelete ", response);
        if (response.data.SvcStatus === "Success") {
          this.setState(prevState => ({lstStudent: prevState.lstStudent.filter(student => student.studentId !== studentId)
          }), () => {
            // Update the table after deletion
            this.setStudentTable();
          });
        } else {
          toast.error(response.data.SvcMsg);
          if (response.data.SvcMsg === "You are not Logged In") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          } else if (response.data.SvcMsg === "Server Timed Out, Login Again") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          }
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((error) => {
        toast.error("Service failed " + error);
      });
  }


  async cbGetICardSvc(data) {
    // console.log(data);
    if (data.status === "Success") {
      await ctx.setState({ flgIdSlider: true, lstIdCard: data.lstIdCard });
      ctx.showSlider();
    } else if (data.status === "Failure") {
      if (data.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (data.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(data.message);
      }
    } else {
      toast.error("Service failed " + data.error);
    }
  }
  handleLstStudentIdChange = (pId, e) => {
    // console.log(pId);
    e.stopPropagation();
    e.stopImmediatePropagation();
    var tmpLstStudent = [];
    var pChecked = e.target.checked;
    tmpLstStudent = this.state.lstSelStudentId;
    pId = parseInt(pId);
    pChecked = !!pChecked;

    if (pChecked && !this.state.lstSelStudentId.includes(pId)) {
      tmpLstStudent.push(pId);
      this.setState({ lstSelStudentId: tmpLstStudent });
    } else {
      tmpLstStudent.splice(tmpLstStudent.indexOf(pId), 1);
      this.setState({ lstStudentId: tmpLstStudent });
    }
    // console.log(typeof tmpLstStudent);
    this.setState({ lstStudentId: tmpLstStudent });
  };

  setAcademicYearId = (newId) => {
    this.setState({ academicYearId: newId });
  };

  render() {
    httpURLStudent = lstModules["students"].url + ":" + lstModules["students"].port;
    httpURL = lstModules["main"].url + ":" + lstModules["main"].port;

    return (
      <div style={{ height: "100%" }}>
        <Header wingFromChild={this.getWingId} />
        <ToastContainer autoClose={3000} hideProgressBar />
        <div
          style={{
            position: "fixed",
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: "50%",
            top: "50%",
            display: this.state.loader,
          }}
        >
          <LoaderImage />
        </div>

        <div
          className="mainContainer"
          style={{
            opacity: this.state.opacity,
            pointerEvents: this.state.opacity == 1 ? "auto" : "none",
          }}
        >
          <div className="container-fluid">
            <div className="col-sm-12">
              <div className="form-group">

                {/* <div className="col-sm-2 padding-left-remove">
                  
                </div> */}
              </div>
              <div className="form-group">
                <div className="col-sm-8 padding-remove ">
                  <div className="col-sm-3 padding-remove  pull-right"  >
                    <div className="col-sm-8 padding-remove "  >
                      <div className="col-sm-10 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                        <label className=" col-sm-12 text-center padding-remove  padding-remove " style={{ fontWeight: 400 }}  >Students: {this.state.filteredStudentCount}</label>
                      </div>
                    </div>
                    <div className="col-sm-4 padding-remove">
                      <button
                        type="button"
                        title="Print ID Card"
                        onClick={() => this.getIdCard()}
                        className="btn btn-warning pull-right"
                      >
                        Print
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div
                className="col-sm-5 padding-remove"
                style={{
                  marginTop: 10,
                  display: this.state.showError ? "block" : "none",
                }}
              >
                <table
                  className="tableLayout1 errorTable"
                  style={{ width: this.state.width }}>
                  <thead>
                    <tr>
                      <th style={{ display: "none" }}> Error Id </th>
                      <th style={{ width: 60 }}>Sheet </th>
                      <th style={{ width: 20 }}>Line Number </th>
                      <th style={{ width: 60 }}> Error </th>
                    </tr>
                  </thead>
                </table>
                <div
                  className="errorTableDiv"
                  style={{
                    height: "500px",
                    overflowY: "scroll",
                    border: "1px solid #ccc",
                  }}
                >
                  <table id="errorTable" className="tableLayout">
                    <colgroup>
                      <col width="60" />
                      <col width="20" />
                      <col width="60" />
                    </colgroup>
                    <tbody id="errorTableBody"></tbody>
                  </table>
                </div>
              </div>
              <div
                className="col-sm-8 padding-remove"
                style={{
                  marginTop: 10,
                  display: !this.state.showError ? "block" : "none",
                }}
              >
                <table
                  className="tableLayout1 table-head-borders"
                  style={{ width: this.state.width }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: 40, }}>
                        <input
                          type="text"
                          className="form-control inp-search"
                          onChange={(e) => this.filterStudentId(e.target.value)}
                          defaultValue={this.state.filteredStudentId}
                          placeholder="Id"
                        />
                      </th>
                      <th style={{ width: 100 }}>
                        <input
                          onChange={this.setStudentTable}
                          type="text"
                          className="form-control inp-search"
                          id="student"
                          placeholder="Student"
                        />
                      </th>
                      <th style={{ width: 50, display: "none" }}>Class Id</th>
                      <th style={{ width: 60 }}>Gender</th>
                      <th style={{ width: 45 }}>Age</th>
                      <th style={{ width: 75 }}>
                        <select
                          onChange={this.setStudentTable}
                          className="form-control inp-search"
                          id="inp_class"
                        ></select>
                      </th>
                      <th className="text-left" style={{ width: 70 }}>
                        {/* Section */}
                        {/* <input
                          type="text"
                          onKeyUp={(e)=>this.filterSection(e.target.value)}
                          className="form-control inp-search"
                          id="section"
                          placeholder="Section"
                        /> */}
                        <input
                          type="text"
                          className="form-control inp-search"
                          onChange={(e) => this.filterSection(e.target.value)}
                          defaultValue={this.state.filteredsection}
                          placeholder="Section"
                        />
                      </th>
                      <th style={{ width: 70 }}>
                        <input
                          type="text"
                          className="form-control inp-search"
                          onChange={(e) => this.filterAcademicYear(e.target.value)}
                          defaultValue={this.state.filteredAcademicYear}
                          placeholder="Academic Year"
                        />
                      </th>
                      <th style={{ width: 50 }}>
                        <div style={{ float: 'left', width: '70%', marginTop: 5 }}>Blocked</div>
                        <div style={{ width: '15%', float: 'left' }}>
                          <input
                            type="checkbox"
                            className="form-control"
                            id="inp_blocked"
                            onChange={this.setStudentTable}
                          />
                        </div>
                      </th>
                      <th style={{ width: 50 }}>
                        <div style={{ float: 'left', width: '70%', marginTop: 5 }}>Select </div>
                        <div style={{ width: '15%', float: 'left' }}>
                          <input
                            onChange={this.selectAll}
                            className="form-control"
                            type="checkbox"
                            id="cbxSelectAll"
                          />
                        </div>
                      </th>
                      <th style={{ width: 80 }}>Action</th>
                    </tr>
                  </thead>
                </table>
                <div
                  className="studentTableDiv col-sm-12 col-xs-11 padding-remove"
                  style={{ height: "500px", overflowY: "scroll", border: "1px solid #ccc" }}
                >
                  <table id="studentTable" className="tableLayout">
                    <colgroup>
                      <col width="40" />
                      <col width="100" />
                      <col width="60" />
                      <col width="45" />
                      <col width="75" />
                      <col width="70" />
                      <col width="70" />
                      <col width="50" />
                      <col width="50" />
                      <col width="80" />
                    </colgroup>
                    <tbody id="studentTableBody"></tbody>
                  </table>
                </div>
              </div>
              <div className="col-sm-1 col-xs-1" style={{ width: "4%" }}>
                <span
                  id="addStudent"
                  title="Add Student"
                  className="addIcon glyphicon glyphicon-plus-sign"
                  onClick={async () => {
                    await this.setState({ flg: true });
                    this.addStudentReady();
                  }}
                ></span>
              </div>
              <div className="col-sm-2 col-xs-1" style={{ width: "13%", display: "flex", flexDirection: "column", gap: "5px", border: "1px solid #ccc", marginTop: "10px" }}>
                <div className="form-group">
                  <AcademicYearPopup
                    academicYearId={this.state.academicYearId}
                    setAcademicYearId={this.setAcademicYearId}
                  />
                </div>
                <div className="form-group">
                  <input
                    key={new Date().getTime()}
                    type="file"
                    id="excelUpload"
                    className="hidden"
                    accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => this.uploadExcel(e)}
                  />
                  <button
                    id="uploadeExcel"
                    title="Upload Excel"
                    type="button"
                    onClick={() => this.triggerUploadExcel()}
                    className="btn btn-warning"
                  >
                    Upload Student
                  </button>
                </div>
                <div className="form-group">
                  <button
                    type="button"
                    id="btn_showerror"
                    title={
                      !this.state.showError ? "View Errors" : "View Students"
                    }
                    onClick={this.showErrors}
                    className="btn btn-info"
                  >
                    {!this.state.showError ? "View Errors" : "View Students"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slider halfWidthSlider padding-remove">
          {this.state.flgIdSlider && (
            <IdCardSlider
              lstIdCard={this.state.lstIdCard}
              heading="View Id Card"
              lstStudentId={this.state.lstStudentId}
              removeClassFromBody={this.closeSlider}
            />
          )}
        </div>
      </div>
    );
  }
}
export default Student;
