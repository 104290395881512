import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "common/css/master.css";
import Header from "common/js/header/header.js";
import DiarySlider from "./DiarySlider";
import DiaryTable from "./DiaryTable";
import DiaryVerificationSlider from "./DiaryVerificationSlider";

const DiaryCtrl = () => {
  const [subjectId, setSubjectId] = useState(0);
  const [flgSliderType, setFlgSliderType] = useState(false);
  const [randNo, setRandNo] = useState(0);
  const [loader, setLoader] = useState("none");

  function openSlider() {
    setFlgSliderType(true);
  }
  function refreshScreen() {
    setSubjectId(0);
    setFlgSliderType(false);
    generateRandomNo();
  }
  function generateRandomNo() {
    var lRandNo = Math.floor(Math.random() * 999999);
    // console.log(lRandNo);
    setRandNo(lRandNo);
  }
  const getWingId = (wingId) => {
    // console.log('wingId = ' + wingId)
  }
  return (
    <div style={{ height: "100%" }}>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className="MainContainer" style={{ marginTop: "2%" }}>
        <div className="col-sm-11" style={{ height: "100%" }}>
          <div
            className="col-sm-11"
            style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
            onClick={(e) => setFlgSliderType(true)}
          >
            <DiaryTable setSubjectId={setSubjectId} />
          </div>

          <div className="col-xs-1" onClick={(e) => setFlgSliderType(true)}>
            <span
              id="addReadyId"
              title="Add"
              className="addIcon glyphicon glyphicon-plus-sign"
            ></span>
          </div>
          <div
            style={{
              width: "60%",
              display: flgSliderType === true ? "block" : "none",
            }}
            className="staffSlider"
          >
            <button
              type="button"
              className="btn btn-info text-center btnCloseDiv"
              style={{ right: "60%" }}
              onClick={refreshScreen}
            >
              <span
                id="close"
                title="Close Slider"
                className="glyphicon glyphicon-remove"
              ></span>
            </button>
            <DiarySlider
              key={subjectId}
              subjectId={subjectId}
              closeSlider={refreshScreen}
              generateRandomNo={generateRandomNo}
              flgSliderType={flgSliderType}

            />

          </div>
        </div>
      </div>
    </div>
  );
};

export default DiaryCtrl;
