import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Datepicker from "react-datepicker";
import {
  saveInterviewSlotSvc,
  getInterviewPanelListSvc,
} from "./InterviewSlotConfigSvc";

const InterviewSlotConfigSlider = (props) => {
  const [interviewSlotId, setInterviewSlotId] = useState(props.interviewSlotId);
  const [slotLabel, setSlotLabel] = useState(props.slotLabel);
  const [slotDt, setSlotDt] = useState(props.slotDt);
  const [startTm, setStartTm] = useState(props.startTm);
  const [endTm, setEndTm] = useState(props.endTm);
  const [timePerCandidate, setTimePerCandidate] = useState(
    props.timePerCandidate
  );
  const [lstInterviewPanel, setLstInterviewPanel] = useState([]);
  const [selStaffId, setSelStaffId] = useState(0);
  const [lstSelPanel, setLstSelPanel] = useState([]);
  const [lstTm, setLstTm] = useState([]);
  const [loader, setLoader] = useState("none");
  useEffect(() => {
    populate();
    if (props.flgSlider) {
      // console.log(props);
      setLoader("block");
      setInterviewSlotId(props.interviewSlotId);
      setSlotLabel(props.slotLabel);
      setSlotDt(props.slotDt);
      setStartTm(props.startTm);
      setEndTm(props.endTm);
      setTimePerCandidate(props.timePerCandidate);
    }
    getInterviewPanelListSvc(props.interviewSlotId, handlePanelListResponse);
  }, [
    props.flgSlider,
    props.interviewSlotId,
    props.slotLabel,
    props.slotDt,
    props.startTm,
    props.endTm,
    props.endTm,
    props.timePerCandidate,
  ]);
  function handlePanelListResponse(data) {
    if (data.SvcStatus === "Success") {
      setLstInterviewPanel(data.lstInterviewPanel);
      setStateSelectedPanel(data.lstInterviewPanel);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function setStateSelectedPanel(pLstInterviewPanel) {
    var tmpArr = [];
    for (var i = 0; i < pLstInterviewPanel.length; i++) {
      var obj = pLstInterviewPanel[i];
      if (obj.selected) {
        tmpArr.push(obj.staffId);
      }
    }
    setLstSelPanel(tmpArr);
  }
  function handleSlotLabelChange(param) {
    setSlotLabel(param);
  }
  function getDateForCalendar(strDt) {
    if (!strDt) return null;
    var parts = strDt.split("-");
    var d = parts[0];
    var m = parts[1] - 1;
    var y = parts[2];

    return new Date(y, m, d);
  }
  function handleSlotDtChange(param) {
    var m = param.getMonth() + 1;
    if (m < 10) {
      m = "0" + m;
    }
    var d = param.getDate();
    if (d < 10) {
      d = "0" + d;
    }
    const dt = d + "-" + m + "-" + param.getFullYear();
    setSlotDt(dt);
  }
  function handleTimePerCandidateChange(param) {
    setTimePerCandidate(param);
  }
  function saveInterviewSlot() {
    const pInterviewObj = {
      interviewSlotId: !interviewSlotId ? 0 : interviewSlotId,
      slotLabel: slotLabel,
      slotDt: slotDt,
      startTm: startTm,
      endTm: endTm,
      timePerCandidate: timePerCandidate,
      lstInterviewPanel: lstSelPanel,
    };
    setLoader("block");
    props.closeSlider();
    saveInterviewSlotSvc(pInterviewObj, cbSaveInterviewSlot);
  }
  function cbSaveInterviewSlot(data, pInterviewObj) {
    setLoader("none");
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      pInterviewObj.interviewSlotId = data.interviewSlotId;
      props.setInterviewObj(pInterviewObj);
      props.closeSlider(data.interviewSlotId);
    } else {
      toast.error(data.SvcMsg);
    }
  }
  function handleCBoxChange(staff_id, status) {
    // console.log("handleCBoxChange triggered");
    if (status && !lstSelPanel.includes(staff_id)) {
      var lstTempArr = lstSelPanel;
      lstTempArr.push(staff_id);
      setLstSelPanel(lstTempArr);
      // console.log("checked " + lstTempArr);
      //returnSelEnqId()
    } else {
      var lstTempArr = lstSelPanel;
      lstTempArr.splice(lstTempArr.indexOf(staff_id));
      setLstSelPanel(lstTempArr);
      // console.log("unchecked" + lstTempArr);
      //returnSelEnqId()
    }
  }

  function populate() {
    var tempLstTime = [];
    var hours, minutes, ampm;
    for (var i = 0; i <= 24 * 59; i += 30) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes; // adding leading zero
      }
      ampm = hours % 24 < 12 ? "AM" : "PM";
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      if (hours < 10) {
        hours = "0" + hours;
      }
      tempLstTime.push(hours + ":" + minutes + " " + ampm);
    }
    // console.log(tempLstTime)
    setLstTm(tempLstTime);
  }
  function handleStartTimeChange(param) {
    setStartTm(param);
  }
  function handleEndTimeChange(param) {
    setEndTm(param);
  }
  function handleSelStaffId(pStaffId) {
    setSelStaffId(pStaffId);
  }
  return (
    <form
      className="form form-horizontal"
      style={{
        pointerEvents: loader === "none" ? "auto" : "none",
        height: "auto",
        width: "90%",
        margin: "10px auto",
        background: "#fff",
        border: "1px solid #ccc",
        borderRadius: 10,
      }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <button
        type="button"
        className="btn btn-info text-center btnCloseDiv"
        onClick={props.closeSlider}
      >
        <span
          id="close"
          title="Close Slider"
          className="glyphicon glyphicon-remove"
        ></span>
      </button>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-5">Slot Label</label>
          <div className="col-sm-6 padding-remove">
            <input
              type="text"
              className="form-control"
              maxLength={20}
              defaultValue={slotLabel}
              onChange={(e) => handleSlotLabelChange(e.target.value)}
              required
            />
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-5">
            Date<span className="red-color">*</span>
          </label>
          <div className="col-sm-6 padding-remove">
            <Datepicker
              type="date"
              className="form-control"
              selected={getDateForCalendar(slotDt)}
              onChange={handleSlotDtChange}
              dateFormat="dd-MM-yyyy"
            ></Datepicker>
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-5">
            Start Time<span className="red-color">*</span>
          </label>
          <div className="col-sm-6 padding-remove">
            <select
              className="form-control"
              onChange={(e) => handleStartTimeChange(e.target.value)}
              value={startTm}
            >
              <option value={""}>Start Time</option>
              {lstTm.map((time, idx) => {
                return (
                  <option key={idx} value={time}>
                    {time}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-5">
            End Time<span className="red-color">*</span>
          </label>
          <div className="col-sm-6 padding-remove">
            <select
              className="form-control"
              onChange={(e) => handleEndTimeChange(e.target.value)}
              value={endTm}
            >
              <option value={""}>End Time</option>

              {lstTm.map((time, idx) => {
                return (
                  <option key={idx} value={time}>
                    {time}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-5">
            Time Per Candidate(mins)<span className="red-color">*</span>
          </label>
          <div className="col-sm-6 padding-remove">
            <input
              type="number"
              className="form-control"
              min={5}
              max={30}
              defaultValue={timePerCandidate}
              onChange={(e) => handleTimePerCandidateChange(e.target.value)}
              required
            />
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-5">
            Interview Panel<span className="red-color">*</span>
          </label>
          <div className="tablebodyWrapper" style={{ height: "44vh" }}>
            <table className="tableLayout">
              <tbody>
                {lstInterviewPanel.map((staff, idx) => {
                  return (
                    <tr
                      onClick={(e) => handleSelStaffId(staff.staffId)}
                      className={staff.staffId === selStaffId ? "selected" : ""}
                      key={idx}
                    >
                      <td>{staff.staff}</td>
                      <td>
                        <input
                          type="checkbox"
                          defaultChecked={staff.selected}
                          onClick={(e) =>
                            handleCBoxChange(staff.staffId, e.target.checked)
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <button
          type="button"
          className="btn btn-info btn-space"
          onClick={() => saveInterviewSlot()}
        >
          Save
        </button>
      </div>
    </form>
  );
};
export default InterviewSlotConfigSlider;
