import React, { Component } from "react";
import lstModules from 'common/json/lstModules.json';
import $ from "jquery";
import "jquery-ui";

import "common/css/master.css";
import "../css/feeHeads.css";
import "../css/studentFee.css"
import axios from "axios";
import Header from "common/js/header/header.js";
import { toast, ToastContainer } from "react-toastify";
import Section from '../../section';
// import FeeStudentsTable from './feeStudentsTableOld';
import FeeStudentsTable from './feeStudentTable';
import StudentFeeDiscountTable from './studentFeeDiscountTableOld'
import DiscountForm from './discountForm'
import FeeBookView from '../../feeBookView';
// import FeeBookView from './feeBookView';

import StudentFeeDetails from "sms/fees/StudentFeeDetailsDiscount/js/studentFeeDetailsDiscount";

var httpURLCommon = '', httpURL = "";
var queryString = require("querystring");
var screenId = 11;
var lstFeeHeads = [];
var tblKey = 0;


class FeeStudents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lstClass: [],
            tokenid: localStorage.getItem("tokenid"),
            wingId: localStorage.getItem("wingId"),
            lstFeeHead: [],
            discount: false,
            feeDiscount: [],
            feeHeadData: {},
            studentId: 0,
            feeHeadId: 0,
            feeClassId: 0,
            feeStudentId: 0,
            lstClassSection: [],
            classId: "",
            lstFeeStudent: []
        };
        this.changeCls = this.changeCls.bind(this);
        this.sendFeebook = this.sendFeebook.bind(this);
    }

    componentDidMount() {
        // this.getFeeStudentsList();
        this.getLstClass();
    }
    setTblKey() {
        // this.getFeeStudentsList();
        tblKey = Math.round(Math.random() * 99999);
    }
    getLstClass = () => {
        let wingId = this.state.wingId;
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURLCommon + '/sms/cls/list',
            queryString.stringify({ wingId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then(async (response) => {
            if (response.data.SvcStatus === 'Success') {
                // console.log("response.data.lstWingClass = "+response.data.lstWingClass);
                await this.setState({ lstClass: response.data.lstCls });
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }
    getFeeHeadList = (studentId) => {
        // let wingId = $('#wingCmb').find('option:selected').val();

        let classSectionId = this.state.classSectionId
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/fee/student/feehead/list',
            queryString.stringify({ studentId, classSectionId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({ lstFeeHead: response.data.lstFeeHead });
                lstFeeHeads = this.state.lstFeeHead
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
        // console.log("called  " + studentId);
        this.getFeeBookData(studentId);

    }


    changeCls = (e) => {
        let wingId = this.state.wingId;
        let screenId = 33;
        var classId = $('#clsCmb').find('option:selected').attr('id');
        var className = $('#clsCmb').find('option:selected').text();
        this.setState({
            classId: classId,
            className: className
        });
        this.setState({ lstFeeStudent: [] })


        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURLCommon + '/sms/cls/sec/list',
            queryString.stringify({ classId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                // console.log("response.data.lstClassSection = ", response.data.lstClassSection);
                this.setState({ lstClassSection: response.data.lstClassSection });
                {this.state
                    && this.state.lstClassSection
                    && this.state.lstClassSection.length != 0
                    &&
                        // this.getFeeStudentsList(classId, response.data.lstClassSection[0].sectionId)
                        this.setState({
                            classId: classId,
                            classSectionId: response.data.lstClassSection[0].sectionId,
                        });
                }
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }

    // getFeeStudentsList = (classId, classSectionId) => {
    // console.log("getFeeStudentList called in feeStudents.js");
    // console.log("classSectionId passed to getFeeStudentList", classSectionId);
    //     let screenId = 33;
    //     this.setState({ loader: "block", opacity: 0.5 });
    //     axios({
    //         url: httpURL + "/sms/fee/student/list",
    //         method: "POST",
    //         params: { 'screenId': screenId, 'classId': classId, 'classSectionId': classSectionId },
    //         headers: { tokenid: this.state.tokenid },
    //         withCredentials: true,
    //     })
    //         .then((response) => {
    //             if (response.data.SvcStatus === "Success") {
    //                 this.setTblKey();
    // console.log("lstFeeStudent get into getFeeStudent from service" ,response.data.lstFeeStudent);
    //                 this.setState({ lstFeeStudent: response.data.lstFeeStudent, tblKey:tblKey });
    //             } else {
    //                 toast.error(response.data.SvcMsg);
    //             }
    //             this.setState({ loader: "none", opacity: 1 });
    //         })
    //         .catch((e) => {
    //             this.setState({ loader: "none", opacity: 1 });
    //             toast.error("Service failed: " + e);
    //         });
    // };

    handleCallback = (classSectionId, classSectionName) => {
        // console.log("handleCallback called in feestudent.js");
        // console.log("classSectionId passed to handleCallback", classSectionId);
        this.setState({
            classId: this.state.classId,
            classSectionId: classSectionId,
            classSectionName: classSectionName
        });
        // console.log("classSectionId", classSectionId);
        // this.getFeeStudentsList(this.state.classId, classSectionId);
    }
    getDiscountData = (studentId, enrollmentNo, student) => {
        // console.log("getDiscountData() called");
        $('body').addClass('openSlider');
        this.setState({
            discount: false,
            studentId: studentId,
            enrollmentNo: enrollmentNo,
            studentName: student
        });
        this.getFeeHeadList(studentId);
        // console.log("called")
    }
    
    
    openDiscountSlider = (stdId, stdName) => {
       // console.log("openDiscountSlider called, stdId", stdId, ", stdName", stdName);
        $('body').addClass('openSlider1');
        this.setState({
            studentId: stdId,
            studentName: stdName
        })
    }




    sendFeebook = (studentId) => {
        // console.log("sendFeebook called");
        // console.log("studentId pass to sendFeebook method", studentId);
        let classSectionId = this.state.classSectionId;
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/fee/cls/students/feebook',
            queryString.stringify({ classSectionId, screenId, studentId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }
    getFeeBookData = (studentId) => {
        // console.log("getFeeBookData called in feeStudent.js");
        var classSectionId = this.state.classSectionId;
        axios.post(httpURL + '/sms/fee/student/feebook',
            queryString.stringify({ "studentId": studentId, classSectionId, 'screenId': screenId }),
            {
                headers: { tokenid: this.state.tokenid },
                withCredentials: true
            }
        ).then((response) => {
            this.setState({
                feeBookData: response.data.feeBook,
            });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
        var classSection = this.state.className + "-" + this.state.classSectionName
        this.setState({
            classSection: classSection
        });

    }
    sendFeeBookStudent = (studentId) => {
        // var classSectionId = this.state.classSectionId;
        axios.post(httpURL + '/sms/fee/student/feebook/mail',
            queryString.stringify({ "studentId": studentId
            // , classSectionId, 'screenId': screenId
         }),
            {
                headers: { tokenid: this.state.tokenid },
                withCredentials: true
            }
        ).then((response) => {
            // console.log(response);
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
            } else {
                toast.error(response.data.SvcMsg);
            }
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
        

    }

    // getFeeStudentTable = () => {
    //     const lstFeeStudent = this.state.lstFeeStudent
    //     const classSectionId = this.state.classSectionId
    //     const lstClassSection = this.state.lstClassSection
    //     const classId = this.state.classId
    //     return (


    //     );
    // }
    closeSlider = () => {
        $('body').removeClass('openSlider');
        $('body').removeClass('openSlider1');
        this.setState({
            studentId: 0,
            studentName: "",
        })
    }
    getDiscount = (feeHeadId, feeClassId, feeStudentId) => {
        this.setState({
            discount: true,
            feeHeadId: feeHeadId,
            feeStudentId
        })

        let { studentId } = this.state
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/fee/student/discount/list',
            queryString.stringify({ feeHeadId, feeClassId, studentId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    feeDiscount: response.data.lstDiscount
                }, () => {
                    // console.log(this.state.feeDiscount)
                });
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });


        const result = this.state.lstFeeHead.filter(feeHead => feeHead.feeHeadId === feeHeadId);
        this.setState({
            feeHeadData: result[0]
        })
    }

    saveDiscount = (discountId, feeClassId) => {
        let { feeStudentId, feeHeadId } = this.state;
        let discount = { feeStudentId, feeHeadId, discountId };
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/fee/student/discount/save',
            queryString.stringify({ "discount": JSON.stringify(discount), screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getFeeHeadList(this.state.studentId);
                this.getDiscount(feeHeadId, feeClassId, feeStudentId)
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }

    handleCheckboxSave = (event, feeHeadId, feeClassId) => {
        // console.log("handleCheckboxSave called in feeStudent.js from studentFeeDiscountTable (on selecting a feehead) ");
        // console.log("event", event);
        // console.log("feeHeadId", feeHeadId);
        // console.log("feeClassId", feeClassId);
        let target = event.target;
        // console.log("target", target);
        let chk = event.target.checked;
        // console.log("checked", chk);
        let { lstFeeHead } = this.state;
        // console.log("this.state", this.state);
        // console.log("lstFeeHead", lstFeeHead);
        if (Array.isArray(this.state.lstFeeHead) &&
            target.id != null &&
            this.state.lstFeeHead[target.id] != null) {
            //toggele the current checkbox
            // console.log(lstFeeHead[target.id].selected);
            if (lstFeeHead[target.id].selected === true) {
                // let selected = 0
                this.saveFeeCollection(feeHeadId, feeClassId, chk)
            }
            else {
                // let selected = 1
                this.saveFeeCollection(feeHeadId, feeClassId, chk)
            }
            lstFeeHead[target.id].selected = target.checked;
            this.setState({
                lstFeeHead
            })
        }


    };
    saveFeeCollection = (feeHeadId, feeClassId, selected) => {
        // console.log("saveFeeCollection called on checking/uncheking feeHead in feeStudents");
        // console.log("feeHeadId", feeHeadId);
        // console.log("feeClassId", feeClassId);
        var associate = {};
        let studentId = this.state.studentId

        associate = {
            "feeHeadId": feeHeadId,
            "feeClassId": feeClassId,
            "attach": selected,
            "studentId": studentId,
        }
        // console.log("associate to be passed in params ", associate);
        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/fee/student/feehead/associate',
            method: "POST",
            params: { 'screenId': screenId, 'associate': JSON.stringify(associate) },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getFeeHeadList(this.state.studentId);
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });

    }
    filterFeesLabelTable = (feeLabel) => {

        if (feeLabel !== "") {
            const filterName = this.state.lstFeeHead.filter(feeHead =>
                feeHead.feeLabel.toLowerCase().includes(feeLabel.toLowerCase()));
            this.setState({
                lstFeeHead: filterName
            })
        }
        else {
            this.setState({
                lstFeeHead: lstFeeHeads
            })
        }
    }

    getWingId = (wingId) => {
        this.setState({
            wingId: wingId
        }, () => this.getLstClass())
    }

    setLoaderValue = (ldr) => {
        this.setState({loader:ldr});
      }
    
      setOpacityValue = (opct) => {
        this.setState({opacity:opct});
      }

    render() {
        httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
        httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;
        return (
            <div style={{ pointerEvents: this.state.opacity === 1 ? 'auto' : 'none' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                {/* <div className="mainContainer feeHeads"> */}
                <div className="container-fluid" style={{ boxShadow: '0 0 10px 0 #214a75' }}>
                    <div className="col-sm-12" style={{ padding: '10px' }}>
                        <div className="col-sm-2">
                            <select autoComplete="off" id="clsCmb" onChange={() => this.changeCls()} className="form-control">
                                <option id={0} key={0}>Class</option>
                                {this.state.lstClass.map((cls) => {
                                    return <option id={cls.classId} key={cls.classId}>{cls.classLabel}</option>
                                })}
                            </select>
                        </div>

                        <div className="col-sm-10">
                            {this.state
                                && this.state.lstClassSection
                                && this.state.lstClassSection.length != 0
                                && <Section
                                    data={this.state.lstClassSection}
                                    selected={this.state.lstClassSection[0].sectionId}
                                    parentCallback={this.handleCallback} />
                            }
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 padding-remove" style={{ marginTop: 20 }}>
                    {this.state.classId
                        &&
                        this.state.lstClassSection
                        &&
                        this.state.classSectionId
                        // &&
                        // this.state.lstFeeStudent.length !== 0
                        ?
                        <FeeStudentsTable
                        //   tblKey={this.state.tblKey}
                            // lstFeeStudent={this.state.lstFeeStudent}
                            getDiscountData={this.getDiscountData}
                            openDiscountSlider={this.openDiscountSlider}
                            classId={this.state.classId}
                            classSectionId={this.state.classSectionId}
                            sendFeebook={this.sendFeebook}
                            sendFeeBookStudent={this.sendFeeBookStudent}
                            setLoader = {this.setLoaderValue}
                            setOpacity = {this.setOpacityValue}
                        /> : null}
                    {/* </div> */}
                </div>
                <div className="discountSection slider fullWidthSlider">
                    <div className="col-sm-5" style={{ width: '48%' }}>
                        <StudentFeeDiscountTable
                            lstFeeHead={this.state.lstFeeHead}
                            closeSlider={this.closeSlider}
                            getDiscount={this.getDiscount}
                            handleCheckboxSave={this.handleCheckboxSave}
                            filterFeesLabelTable={this.filterFeesLabelTable}

                        />

                        {this.state.discount ?
                            <DiscountForm
                                feeDiscount={this.state.feeDiscount}
                                feeHeadData={this.state.feeHeadData}
                                saveDiscount={this.saveDiscount}
                            />
                            : null
                        }
                    </div>
                    <div className="col-sm-7" style={{ width: '52%' }}>
                        {
                            this.state.feeBookData &&
                            <FeeBookView
                                classSectionId={this.state.classSectionId}
                                classId={this.state.classId}
                                feeBookData={this.state.feeBookData}
                                rows={this.state.feeBookData.maxFeeHeadCount}
                                classSection={this.state.classSection}
                                studentName={this.state.studentName}
                                enrollmentNo={this.state.enrollmentNo}
                                closeSlider={this.closeSlider}
                            />
                        }
                        {/* this.state.feeBookData
                                && this.state.feeBookData.maxFeeHeadCount
                                && this.state.feeBookData.lstFeebook
                                && this.state.feeBookData.lstFeebook.length > 0
                                && */}

                    </div>

                </div>
                <div className="discountSection slider1 fullWidthSlider" style={{ width: "80%" }}>
                    <div className="col-sm-12"  >

                        <StudentFeeDetails
                            key={this.state.studentId}
                            studentId={this.state.studentId}
                            closeSlider={this.closeSlider}
                            studentName={this.state.studentName}
                        />
                    </div>

                </div>


            </div>
        );
    }
}

export default FeeStudents;
