import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from 'react-toastify';
import CommunicationGroupsStudentTable from "./commGroupsStudentTable";
import { saveCommunicationGroupStudentsSvc, communicationGroupStudentRemoveSvc } from "./commGroupsSvc";

const CommunicationGroupsStudent = (props) => {

    const childRef = useRef();
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);

    const [selectedOnly, setSelectedOnly] = useState(false);
    const [unselectedOnly, setUnselectedOnly] = useState(false);
    const [totalSelected, setTotalSelected] = useState(0);
    const [totalUnselected, setTotalUnselected] = useState(0);
    const [lstSelected, setLstSelected] = useState([]);

    useEffect(() => {
        // console.log("CommunicationGroupStudent called, selGroupId", props.selGroupId);
    }, [props.selGroupId]);

    const removeStudents = () => {
        // console.log("removeStudents called");
        setLoader("block");
        setOpacity(0.5);
        communicationGroupStudentRemoveSvc(props.selGroupId, removeStudentsResponse);
    }

    const removeStudentsResponse = (data) => {
        // console.log("removeStudentsResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            props.closeSlider();
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const saveStudents = () => {
        // console.log("saveStudents called");
        setLoader("block");
        setOpacity(0.5);
        saveCommunicationGroupStudentsSvc(props.selGroupId, lstSelected, saveStudentsResponse);
    }

    const saveStudentsResponse = (data) => {
        // console.log("saveStudentsResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            props.closeSlider();
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const toggleSelectedOnly = () => {
        childRef.current.filterSelection(!selectedOnly, false);
        setSelectedOnly((selected) => !selected);
        setUnselectedOnly(false);
    }

    const toggleUnselectedOnly = () => {
        childRef.current.filterSelection(false, !unselectedOnly);
        setUnselectedOnly((selected) => !selected);
        setSelectedOnly(false);
    }

    return (
        <>
            <div style={{ width: "100%", textAlign: "center", margin: 0 }}>
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>

                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={() => props.closeSlider()} style={{ right: "80%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <div className="col-md-11  col-xs-11 col-centered studentTableWrap  padding-remove" style={{ opacity }} >
                    <div className="col-sm-12 padding-remove" style={{ marginTop: "10px" }} >
                        <div className="col-sm-2 padding-remove ">
                            <label className=" col-sm-12 text-left " style={{ fontWeight: "bold", paddingLeft: 15 }}>
                                Group Students
                            </label>
                        </div>
                        <div className="col-sm-5 padding-remove pull-right">
                            {/* <div className="col-sm-4 padding-remove">
                                <button className={selectedOnly ? "filterButtonOn" : "filterButtonOff"} onClick={() => toggleSelectedOnly()}>selected</button>
                            </div>
                            <div className="col-sm-4 padding-remove">
                                <button className={unselectedOnly ? "filterButtonOn" : "filterButtonOff"} onClick={(e) => toggleUnselectedOnly()}>not selected</button>
                            </div> */}
                            <div className="col-sm-4 padding-remove pull-right">
                                <div className="col-sm-10 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                                    <label className=" col-sm-12 text-center padding-remove  padding-remove " style={{ fontWeight: 400 }}  >Count: {totalSelected}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CommunicationGroupsStudentTable
                        ref={childRef}
                        groupId={props.selGroupId}
                        selectedOnly={selectedOnly}
                        unselectedOnly={unselectedOnly}
                        setTotalSelected={setTotalSelected}
                        setTotalUnselected={setTotalUnselected}
                        lstSelected={lstSelected}
                        setLstSelected={setLstSelected}
                        setLoader={setLoader}
                        setOpacity={setOpacity}
                    />
                    <div className="col-sm-12 text-center padding-remove" style={{ marginTop: 20, marginRight: 40, display: "flex", justifyContent: "flex-end", }} >
                        <div className="col-sm-3 padding-remove pull-right" >
                            <div className="col-sm-6 padding-remove">
                                <button
                                    type="button"
                                    style={{
                                        width: 100,
                                        backgroundColor: "#48a2bc",
                                        padding: "3px 12px",
                                        fontSize: 16,
                                    }}
                                    title="Save"
                                    onClick={() => saveStudents()}
                                    className="btn btn-info" >
                                    Save
                                </button>
                            </div>
                            <div className="col-sm-6 padding-remove">
                                <a className="discountTd" onClick={() => removeStudents()}>
                                    remove all students
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CommunicationGroupsStudent;