import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURLPerformance = lstModules['performance'].url + ':' + lstModules['performance'].port;
const httpURL = lstModules['performance'].url + ':' + lstModules['performance'].port + "/sms/performance/exam/subject";
const tokenid = localStorage.getItem('tokenid');

export const getExamsListSvc = (cbGetExamsList) => {
    let obj = { status: "", message: "" };
    // console.log("getExamsListSvc called, url", httpURLPerformance + '/sms/performance/mst/exam/list');
    axios({
        url: httpURLPerformance + '/sms/performance/mst/exam/list',
        method: 'POST',
        headers: { tokenid: tokenid },
        withCredentials: true
    }).then((response) => {
        // console.log("response in getExamsListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstExam = response.data.lstExam;
        }
        cbGetExamsList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbGetExamsList(obj);
    })
}

export const getExamSubjectSecionsListSvc = (examId, cbGetExamSubjectSecionsList) => {
    let obj = { status: "", message: "" };
    // console.log("getExamSubjectSecionsListSvc called examId", examId, ", url", httpURL + '/list/cls_section');
    const formData = new FormData();
    formData.append("examId", examId);
    axios({
        url: httpURL + '/list/cls_section',
        method: 'POST',
        headers: { tokenid: tokenid },
        // params: {"examId":examId},
        data: formData,
        withCredentials: true
    }).then((response) => {
        // console.log("response in getExamSubjectSecionsListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstClsSection = response.data.lstClsSection;
        }
        cbGetExamSubjectSecionsList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbGetExamSubjectSecionsList(obj);
    })
}

export const getExamSubjectListSvc = (examId, sectionId, cbGetExamSubjectList) => {
    let obj = { status: "", message: "" };
    // console.log("getExamSubjectListSvc called examId", examId, ", url", httpURL + '/list/subject');
    const formData = new FormData();
    formData.append("examId", examId);
    formData.append("sectionId", sectionId);
    axios({
        url: httpURL + '/list/subject',
        method: 'POST',
        headers: { tokenid: tokenid },
        // params: {"examId":examId, "sectionId":sectionId},
        data: formData,
        withCredentials: true
    }).then((response) => {
        // console.log("response in getExamSubjectListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstExamSubject = response.data.lstExamSubject;
        }
        cbGetExamSubjectList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbGetExamSubjectList(obj);
    })
}
export const getClassSectionListSvc = (classId, cbGetClassSectionList) => {
    let obj = { status: "", message: "" };
    // console.log("getClassSectionListSvc called classId", classId, ", url", httpURLPerformance + '/sms/cls/sec/list');
    axios({
        url: httpURLPerformance + '/sms/cls/sec/list',
        method: 'POST',
        headers: { tokenid: tokenid },
        params: { classId: classId },
        withCredentials: true
    }).then((response) => {
        // console.log("response in getClassSectionListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstClsSection=response.data.lstClassSection;
        }
        cbGetClassSectionList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbGetClassSectionList(obj);
    })
}

export const saveExamSubjectListSvc = (examSubSaveRQ, cbSaveExamSubjectList) => {
    let obj = { status: "", message: "" };
    // console.log("saveExamSubjectListSvc called examSubSaveRQ", examSubSaveRQ, ", url", httpURL + '/save');
    axios({
        url: httpURL + '/save',
        method: 'POST',
        headers: { tokenid: tokenid },
        params: { "examSubSaveRQ": examSubSaveRQ },
        withCredentials: true
    }).then((response) => {
        // console.log("response in saveExamSubjectListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
        }
        cbSaveExamSubjectList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSaveExamSubjectList(obj);
    })
}

export const duplicateExamSubjectListSvc = (examDuplicateRQ, cbDuplicateExamSubjectList) => {
    let obj = { status: "", message: "" };
    // console.log("duplicateExamSubjectListSvc called examDuplicateRQ", examDuplicateRQ, ", url", httpURL + '/duplicate');
    axios({
        url: httpURL + '/duplicate',
        method: 'POST',
        headers: { tokenid: tokenid },
        params: { "examDuplicateRQ": examDuplicateRQ },
        withCredentials: true
    }).then((response) => {
        // console.log("response in duplicateExamSubjectListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
        }
        cbDuplicateExamSubjectList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbDuplicateExamSubjectList(obj);
    })
}