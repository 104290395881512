
import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { communicationGroupListSvc } from "./commGroupsSvc";

const CommunicationGroupsTable = (props) => {

    const [lstCommunicationGroup, setlstCommunicationGroup] = useState([]);
    const [lstCommunicationGroupFiltered, setlstCommunicationGroupFiltered] = useState([]);
    const [groupNameFiltered, setGroupNameFiltered] = useState("");
    const [commGroupId, setcommGroupId] = useState(0);

    useEffect(() => {
        // console.log("CommunicationGroupTable called");
        getGroupList();
    }, [props.tableKey]);

    const getGroupList = () => {
        // console.log("getGroupList called");
        props.setLoader('block');
        props.setOpacity(0.5);
        communicationGroupListSvc(communicationGroupListResponse);
    }

    const communicationGroupListResponse = (data) => {
        // console.log("communicationGroupListResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            setlstCommunicationGroup(obj.lstCommGroup);
            setlstCommunicationGroupFiltered(obj.lstCommGroup);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        props.setLoader('none');
        props.setOpacity(1);
    }

    const editGroup = (groupData) => {
        // console.log("editGroup called, groupData", groupData);
        setcommGroupId(groupData.commGroupId);
        props.setSelGroupId(groupData.commGroupId);
        props.setSelGroupData({
            commGroupName: groupData.commGroupName,
            commGroupId: groupData.commGroupId,
            studentsCount: groupData.studentsCount,
            createdDttm: groupData.createdDttm,
        });
        props.openGroupSlider();
    }

    const editGroupStudents = (gId) => {
        // console.log("editGroupStudents called, gId", gId);
        setcommGroupId(gId);
        props.setSelGroupId(gId);
        props.openGroupStudents();
    }

    const handleFilterName = (fGroup) => {
        // console.log("handleFilterName called, fGroup", fGroup);
        setGroupNameFiltered(fGroup);
        filterGroupTable(fGroup);
    }
    const filterGroupTable = (fGroup) => {
        // console.log("filterGroupTable called, fGroup", fGroup);
        let filteredGroups = [];
        filteredGroups = lstCommunicationGroup.filter(comm =>
            comm.commGroupName.toString().toLowerCase().includes(fGroup.toLowerCase()));
        setlstCommunicationGroupFiltered(filteredGroups);
    }

    return (
        <div className="col-md-12" style={{ marginTop: "5px" }}>
            <table className="tableLayout1" >
                <thead>
                    <tr>
                        <th className="no-display" style={{ width: '100px' }}>
                            commGroupId
                        </th>
                        <th style={{ width: '200px' }}>
                            <input type="text" className="form-control inp-search text-center"
                                value={groupNameFiltered}
                                onChange={(e) => handleFilterName(e.target.value)}
                                name="group"
                                placeholder="Group" />
                        </th>
                        <th style={{ width: '100px' }}>
                            Count
                        </th>
                        <th style={{ width: '100px' }}>
                            created On
                        </th>
                        <th style={{ width: '100px' }}>
                            Action
                        </th>
                    </tr>
                </thead>
            </table>
            <div className="tableDiv" style={{ height: "550px" }}>
                <table className='tableLayout'>
                    <tbody id="commGroupTable">
                        {
                            lstCommunicationGroupFiltered && lstCommunicationGroupFiltered ?
                            lstCommunicationGroupFiltered.map((group) => (
                                <tr key={group.commGroupId}
                                    onClick={(e) => setcommGroupId(group.commGroupId)}
                                    className={group.commGroupId === commGroupId ? "selected" : ""}
                                >
                                    <td style={{ width: 100 }} className='no-display text-center'>{group.commGroupId}</td>
                                    <td style={{ width: 200 }} className='text-left'>{group.commGroupName}</td>
                                    <td style={{ width: 100 }} className='text-center'>{group.studentsCount}</td>
                                    <td style={{ width: 100 }} className='text-center'>{group.createdDttm}</td>
                                    <td style={{ width: 100 }} >
                                        <span  style={{ display: 'flex', justifyContent: 'space-evenly' }} >
                                        <label
                                            style={{
                                                textDecoration: 'underline',
                                                cursor: 'pointer'
                                            }}
                                            className='text-primary'
                                            onClick={e => { e.preventDefault(); editGroup(group); }}
                                        >
                                            Edit
                                        </label>
                                        {/* {' | '} */}
                                        <label
                                            style={{
                                                textDecoration: 'underline',
                                                cursor: 'pointer'
                                            }}
                                            onClick={e => { e.preventDefault(); editGroupStudents(group.commGroupId); }}
                                            className='text-primary'
                                        >
                                            Students
                                        </label>
                                            </span>
                                    </td>
                                </tr>
                            ))
                            : null
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default CommunicationGroupsTable;