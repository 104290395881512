import React, { useEffect, useState } from 'react'
import { getTestScheduleListSvc } from './WTestScheduleSvc.js'
import { toast, ToastContainer } from "react-toastify";



const WTestScheduleTable = (props) => {

    const { loader, setLoader, lstEvaluationMode, lstTestMode, testScheduleId, testBatchId, setTestScheduleId, setTestBatchId, scheduleTableKey, editSchedule, deleteSchedule, setBatchTime, setTestDates, generateQuestions, startTest, printTest } = props;

    const [lstTestSchedule, setLstTestSchedule] = useState([]);
    const [lstTestScheduleFiltered, setLstTestScheduleFiltered] = useState([]);

    const [testFiltered, setTestFiltered] = useState("");
    const [classFiltered, setClassFiltered] = useState("");
    const [sectionFiltered, setSectionFiltered] = useState("");

    useEffect(() => {
        // console.log("useEffect called in WTestScheduleTable");
        getTestScheduleList()
    }, [scheduleTableKey]);


    function getTestScheduleList() {
        setLoader("block");
        getTestScheduleListSvc(cbGetTestScheduleList);
    }
    function cbGetTestScheduleList(data) {
        // console.log("cbGetTestScheduleList() called.. ")
        if (data.SvcStatus === "Success") {
            setLstTestSchedule(data.lstTestSchedule);
            setLstTestScheduleFiltered(data.lstTestSchedule);
            // setLstTestScheduleFiltered(data.lstTestSchedule.sort((a, b) => parseInt(b.nNextTestDt) - parseInt(a.nNextTestDt)));
            toast.success(data.SvcMsg)
        } else {
            setLstTestSchedule([])
            toast.error(data.SvcMsg)
        }
        setLoader("none");
    }



    const filterTest = (fTest) => {
        // console.log("filterTest called, fTest", fTest);
        setTestFiltered(fTest);
        filterTable(fTest, classFiltered, sectionFiltered);
    }

    const filterClass = (fClass) => {
        // console.log("filterClass called, fClass", fClass);
        setClassFiltered(fClass);
        filterTable(testFiltered, fClass, sectionFiltered);
    }

    const filterSection = (fSection) => {
        // console.log("filterTest called, fSection", fSection);
        setSectionFiltered(fSection);
        filterTable(testFiltered, classFiltered, fSection);
    }

    const filterTable = (fTest, fClass, fSection) => {
        // console.log("filterTable called, fTest", fTest, ", fSection", fSection, ", fClass", fClass);
        let filteredTestSchuduleList = lstTestSchedule;
        filteredTestSchuduleList = lstTestSchedule.filter(testSchedule =>
            testSchedule.test.toString().toLowerCase().includes(fTest.toLowerCase()));

        filteredTestSchuduleList = filteredTestSchuduleList.filter(testSchedule =>
            testSchedule.cls.toString().toLowerCase().includes(fClass.toLowerCase()));

        filteredTestSchuduleList = filteredTestSchuduleList.filter(testSchedule =>
            testSchedule.section.toString().toLowerCase().includes(fSection.toLowerCase()));

        setLstTestScheduleFiltered(filteredTestSchuduleList);
    }

    const colgrp = (
        <colgroup>
            <col width='25'></col>
            <col width='18'></col>
            <col width='10'></col>
            <col width='10'></col>
            <col width='8'></col>
            <col width='12'></col>
            <col width='13'></col>
            <col width='20'></col>
            <col width='13'></col>
            <col width='80'></col>
        </colgroup>
    )

    return (
        <div style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}>
            <table className='tableLayout1'>
                {colgrp}
                <thead>
                    <tr>
                        <th>
                            <input type="text" className="form-control inp-search"
                                name="Test Name"
                                value={testFiltered}
                                onChange={(e) => filterTest(e.target.value)}
                                placeholder="Test" />
                        </th>
                        <th>
                            <input type="text" className="form-control inp-search"
                                name="Class name"
                                value={classFiltered}
                                onChange={(e) => filterClass(e.target.value)}
                                placeholder="Class" /></th>
                        <th>
                            <input type="text" className="form-control inp-search"
                                name="Class Section Name"
                                value={sectionFiltered}
                                onChange={(e) => filterSection(e.target.value)}
                                placeholder="Section" /></th>
                        <th>{"Duration (minutes)"}</th>
                        <th>Batch No.</th>
                        <th>Start Time</th>
                        <th>Next Test</th>
                        <th>Test Type</th>
                        <th>Evaluation</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </table>
            <div className='tablebodyWrapper'>
                <table className='tableLayout'>
                    {colgrp}
                    <tbody id="tbodyWTestSchedule">
                        {lstTestScheduleFiltered && lstTestScheduleFiltered.length ?
                            lstTestScheduleFiltered.map((obj, idx) => {
                                return (
                                    <tr
                                        key={'tsId_' + obj.testScheduleId + '_bId_' + obj.testBatchId}
                                        id={obj.testScheduleId}
                                        onClick={e => { setTestScheduleId(obj.testScheduleId); setTestBatchId(obj.testBatchId) }}
                                        className={obj.testScheduleId === testScheduleId && obj.testBatchId === testBatchId ? 'selected' : ''}
                                    >
                                        {/* <td>{obj.testScheduleId}</td> */}
                                        <td className='text-left'>{obj.test}</td>
                                        <td className='text-left'>{obj.cls}</td>
                                        <td className='text-center'>{obj.section}</td>
                                        <td className='text-center'>{obj.durationMins}</td>
                                        <td className='text-center'>{obj.batchNo}</td>
                                        <td className='text-center'>{obj.startTm}</td>
                                        <td className='text-center'>{obj.nextTestDt || "-"}</td>
                                        <td className='text-left'>
                                            {lstTestMode.find(mode => mode.testModeId == obj.testMode)?.testMode ?? null}
                                        </td>
                                        <td className='text-left'>
                                            {lstEvaluationMode.find(mode => mode.evaluationModeId == obj.evaluationMode)?.evaluationMode ?? null}
                                        </td>
                                        <td>
                                            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                                <div>
                                                    <label
                                                        style={{
                                                            textDecoration: 'underline',
                                                            cursor: 'pointer'
                                                        }}
                                                        className='text-primary'
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            editSchedule(obj.testScheduleId, obj.testBatchId);
                                                        }}
                                                    >
                                                        Edit
                                                    </label>
                                                </div>
                                                <div>
                                                    <label
                                                        style={{
                                                            textDecoration: 'underline',
                                                            cursor: 'pointer'
                                                        }}
                                                        className='text-primary'
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            deleteSchedule(obj.testScheduleId);
                                                        }}
                                                    >
                                                        Delete
                                                    </label>
                                                </div>
                                                <div>
                                                    <label
                                                        style={{
                                                            textDecoration: 'underline',
                                                            cursor: 'pointer'
                                                        }}
                                                        className='text-primary'
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setBatchTime(obj.testScheduleId);
                                                        }}
                                                    >
                                                        Batch Time
                                                    </label>
                                                </div>
                                                <div>
                                                    <label
                                                        style={{
                                                            textDecoration: 'underline',
                                                            cursor: 'pointer'
                                                        }}
                                                        className='text-primary'
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setTestDates(obj.testScheduleId);
                                                        }}
                                                    >
                                                        Test Dates
                                                    </label>
                                                </div>
                                                <div>
                                                    <label
                                                        style={{
                                                            textDecoration: 'underline',
                                                            cursor: !!obj.nextTestId ? 'pointer' : 'none',
                                                            pointerEvents: !!obj.nextTestId ? 'auto' : 'none'
                                                        }}
                                                        className={!!obj.nextTestId ? 'text-primary' : 'text-muted'}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            generateQuestions(obj.testScheduleId, obj.nextTestId, obj.noOfQus, obj.clsId);
                                                        }}
                                                    >
                                                        Generate Questions
                                                    </label>
                                                </div>
                                               
                                                <div>
                                                    <label
                                                        style={{
                                                            textDecoration: 'underline',
                                                            cursor: !!obj.nextTestId ? 'pointer' : 'none',
                                                            pointerEvents: !!obj.nextTestId ? 'auto' : 'none'
                                                        }}
                                                        className={!!obj.nextTestId ? 'text-primary' : 'text-muted'}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            startTest(obj.testScheduleId, obj.nextTestId);
                                                        }}
                                                    >
                                                        Start Test
                                                    </label>
                                                </div>
                                                <div>
                                                    <label
                                                        style={{
                                                            textDecoration: 'underline',
                                                            cursor: !!obj.nextTestId ? 'pointer' : 'none',
                                                            pointerEvents: !!obj.nextTestId ? 'auto' : 'none'
                                                        }}
                                                        className={!!obj.nextTestId ? 'text-primary' : 'text-muted'}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            printTest(obj.testScheduleId, obj.nextTestId);
                                                        }}
                                                    >
                                                        Print
                                                    </label>
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                )
                            })
                            : null
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default WTestScheduleTable