import '../css/EnquiryCtrl.css'
import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import SliderType from './sliderType'
import { getCloseRemarksSvc } from './CloseSvc'
import { saveCloseRemarksSvc } from './CloseSvc'
import { getCloseReasonSvc } from './CloseSvc'
const CloseSlider = props => {
  const [loader, setLoader] = useState('none')
  const [admissionId, setAdmissionId] = useState(props.admissionId)
  const [closeRemarks, setCloseRemarks] = useState('')
  const [reasonId, setReasonId] = useState(0)
  const [lstReason, setLstReason] = useState([])
  function cbCloseResponse (data) {
    if (data.SvcStatus === 'Success') {
      setCloseRemarks(data.closeRemarks)
      setReasonId(data.reasonId)
    } else {
      toast.error(data.SvcMsg)
    }
    setLoader('none')
  }
  function cbCloseReasonResponse (data) {
    if (data.SvcStatus === 'Success') {
      // console.log('Fetching success')
      setLstReason(data.lstReason)
    } else {
      toast.error(data.SvcMsg)
    }
    setLoader('none')
  }
  function handleCloseRemarksChange (param) {
    setCloseRemarks(param)
  }
  function handleReasonIdChange (param) {
    setReasonId(param)
  }
  function cbSaveClose (data) {
    if (data.SvcStatus === 'Success') {
      props.setSelectedAdmissionId(data.admissionId)
      toast.success(data.SvcMsg)
      var p2 = new Promise(function (resolve, reject) {
        resolve(props.setSelectedAdmissionId(data.admissionId))
      })

      p2.then(function () {
        props.closeSlider()
      })
    } else {
      toast.error(data.SvcMsg)
    }
    setLoader('none')
  }
  function saveClose () {
    saveCloseRemarksSvc(admissionId, reasonId, closeRemarks, cbSaveClose)
  }
  useEffect(() => {
    setLoader('block')

    var promise = new Promise((resolve, reject) => {
      if (props.flgSliderType === SliderType.SLIDER_CLOSE) {
        if (props.admissionId) {
          setAdmissionId(props.admissionId)
          getCloseRemarksSvc(cbCloseResponse, props.admissionId)
        }
        getCloseReasonSvc(cbCloseReasonResponse)
      }
    })
  }, [props.admissionId, props.flgSliderType])
  return (
    <form
      className='form form-horizontal'
      style={{
        width: '100%',
        margin: '0 auto',
        background: '#fff',
        border: '1px solid #ccc',
        borderRadius: 10,
        pointerEvents: loader === 'none' ? 'auto' : 'none'
      }}
    >
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: loader
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <div
        className='EnquiryHead col-sm-12 col-centered'
        style={{
          border: '1px solid #aaa',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          fontWeight: 'bold'
        }}
      >
        <h4>Close</h4>
      </div>

      <div className='form-group col-md-12 padding-remove'>
        <label className='control-label col-md-2'>
          Reason <span className='red-color'>*</span>
        </label>
        <div className='padding-remove col-md-5'>
          <select
            className='form-control'
            onChange={e => handleReasonIdChange(e.target.value)}
            value={reasonId}
          >
            <option value={0}>Reason</option>
            {lstReason.map((reason, idx) => {
              return (
                <option key={idx} value={reason.reasonId}>
                  {reason.reason}
                </option>
              )
            })}
          </select>
        </div>
      </div>

      <div className='form-group col-md-12 padding-remove'>
        <label className='control-label col-md-2'>Remarks: </label>
        <div className=' padding-remove col-md-10'>
          <textarea
            maxLength={500}
            rows={6}
            defaultValue={closeRemarks}
            className='form-control'
            onChange={e => handleCloseRemarksChange(e.target.value)}
          ></textarea>
        </div>
      </div>

      <div className='form-group col-md-12 padding-remove'>
        <button
          type='button'
          className='btn btn-warning pull-right'
          onClick={e => saveClose()}
        >
          Save
        </button>
      </div>
    </form>
  )
}
export default CloseSlider
