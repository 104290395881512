import React, { Component } from 'react'
import lstModules from 'common/json/lstModules.json'
import { toast, ToastContainer } from 'react-toastify'
import $ from 'jquery'
import axios from 'axios'
import Header from 'common/js/header/header.js'
import FeeClassSetupTableHead from './feeClassSetupTableHead'
import TableBody from './tableBody'
import FeeBookView from '../../feeBookView'
// import FeeBookView from '../../StudentFee/js/feeBookView'
import '../css/mainFee.css'
import FeeCollection from './feeCollection'
import ClassFeeTable from './classFeeTable'
// import lstClassFeeSetup from '../jsonFiles/lstClassFeeSetup.json'

var httpURL = ''
var queryString = require('querystring')
var screenId = 36

var feeBook = {
  colsToDisplay: 2,
  maxFeeHeadCount: 2,
  lstFeebook: []
}
export default class mainFee extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loader: 'none',
      opacity: 1,
      lstFeeClass: [],
      lstFeeCollection: [],
      showSideView: false,
      feeBookData: feeBook,
      tokenid: localStorage.getItem('tokenid'),
      academicYr: localStorage.getItem('academicYear'),
      classSection: 'I - A',
      tableKey: 0,
      // lstClassFeeSetup:lstClassFeeSetup,
      lstFeeHead: [
        {
          feeHeadCode: 'fc001',
          feeHeadId: 1,
          label: 'annual fees',
          feeFrequency: 'Annual',
          lateFeeAmtPerDay: 10,
          lateFeeFixAmt: 1000,
          lateFeeLimit: 100,
          collectionFrequency: 'Annual',
          collectionType: null,
          collectionTypeId: 0,
          amount: 10000,
          discountSiblingAmt: 1000,
          discountScholarAmt: 1000,
          discountMeritMeansAmt: 20000,
          discountBackwardAmt: 1000,
          discountScStAmt: 1000,
          lstCollection: [
            {
              feeId: 0,
              month: 'Sep',
              amount: 5000,
              remarks: '',
              selected: false
            },
            {
              feeId: 0,
              month: 'Sep',
              amount: 5000,
              remarks: '',
              selected: false
            }
          ],
          cls: '',
          section: ''
        }
      ]
    }
  }

  setLoaderValue = (ldr) => {
    this.setState({loader:ldr});
  }

  setOpacityValue = (opct) => {
    this.setState({opacity:opct});
  }

  getClassList = () => {
    this.setState({ loader: 'block', opacity: 0.5 })
    let screenId = 36
    axios
      .post(
        httpURL + '/sms/fee/cls/list',
        queryString.stringify({ screenId: screenId }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true
        }
      )
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          this.setState({
            lstFeeClass: response.data.lstFeeClass
          })
        } else {
          toast.error(response.data.SvcMsg)
          if (response.data.SvcMsg === 'You are not logged in') {
            setTimeout(function () {
              window.location.href = '/'
            }, 2000)
            return false
          } else if (
            response.data.SvcMsg === 'Your Session is expired, login again'
          ) {
            setTimeout(function () {
              window.location.href = '/'
            }, 2000)
            return false
          } else {
            toast.error(response.data.SvcMsg)
          }
        }
        this.setState({ loader: 'none', opacity: 1 })
      })
      .catch(error => {
        this.setState({ loader: 'none', opacity: 1 })
        toast.error('Service failed ' + error)
      })
  }

  generateRandomNo = () => {
    return Math.floor(Math.random() * 999999)
  }
  getFeeCollectionlist = classSectionId => {
    this.setState({ loader: 'block', opacity: 0.5 })
    let screenId = 36
    axios
      .post(
        httpURL + '/sms/fee/cls/head/list',
        queryString.stringify({
          screenId: screenId,
          classSectionId: classSectionId
        }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true
        }
      )
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          this.setState({
            lstFeeCollection: response.data.lstFeeClass,
            tableKey: this.generateRandomNo()
          })
        } else {
          if (response.data.SvcMsg === 'You are not logged in') {
            setTimeout(function () {
              window.location.href = '/'
            }, 2000)
            return false
          } else if (
            response.data.SvcMsg === 'Your Session is expired, login again'
          ) {
            setTimeout(function () {
              window.location.href = '/'
            }, 2000)
            return false
          } else {
            toast.error(response.data.SvcMsg)
          }
        }
        this.setState({ loader: 'none', opacity: 1 })
      })
      .catch(error => {
        this.setState({ loader: 'none', opacity: 1 })
        toast.error('Service failed ' + error)
      })
  }

  // getFeeBookData = () => {
  //     var classId = this.state.classId;
  //     var classSectionId = this.state.classSectionId;
  //     axios.post(httpURL + '/sms/fee/cls/feebook',
  //         queryString.stringify({ classId, classSectionId, screenId }),
  //         {
  //             headers: { tokenid: this.state.tokenid },
  //             withCredentials: true
  //         }
  //     ).then((response) => {
  //         this.setState({
  //             feeBookData: response.data.feeBook,
  //         });
  //     }).catch((e) => {
  //         this.setState({ loader: 'none', opacity: 1 });
  //         toast.error('Service failed: ' + e);
  //     });
  // }
  createColsData = async cols => {
    var arrCols = []

    for (let i = 0; i < cols; i++) {
      arrCols.push({
        divClass: 'col-sm-' + 12 / cols,
        testCondition: (12 / cols) * (i + 1),
        breakpoint: (12 / cols) * i
      })
    }
    await this.setState({ arrCols: arrCols })
  }
  componentDidMount = () => {
    this.createColsData(this.state.feeBookData.colsToDisplay)
    this.getClassList()
  }

  showSlider = (classSectionId, classId) => {
    this.getFeeCollectionlist(classSectionId)
    this.setState({
      showSideView: true,
      classSectionId: classSectionId,
      classId: classId
    })
    $('.slider:eq(0)').show()
    $('body').addClass('openSlider')
  }
  getSliderContent = () => {
    return (
      <div className='col-sm-12'>
        <div className='col-md-8 col-xs-10 col-centered padding-remove'>
          <h2>Fees Heads</h2>
          <form
            id='feeHeadAddForm'
            className='form-horizontal formWrapper clearfix'
          >
            <div className='form-group col-md-8 padding-remove'>
              <label className='control-label col-sm-6'>
                Fees Code<span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </label>
              <div className='col-sm-6 padding-remove'>
                <input
                  autoComplete='off'
                  id='inp_fee_code'
                  type='text'
                  className='form-control '
                  placeholder='Fees Code'
                />
              </div>
            </div>
            <div className='form-group col-md-8 padding-remove'>
              <label className='control-label col-sm-6'>
                {' '}
                Fees Label<span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </label>
              <div className='col-sm-6 padding-remove'>
                <input
                  autoComplete='off'
                  id='inp_fee_lbl'
                  type='text'
                  className='form-control '
                  placeholder='Fees Label'
                />
              </div>
            </div>
            <div className='form-group col-md-8 padding-remove'>
              <label className='control-label col-sm-6'> Amount</label>
              <div className='col-sm-6 padding-remove'>
                <input
                  autoComplete='off'
                  id='inp_amt'
                  type='text'
                  className='form-control '
                  placeholder='Amount'
                />
              </div>
            </div>
            <div className='form-group col-md-8 padding-remove'>
              <label className='control-label col-sm-6'>Frequency</label>
              <div className='col-sm-6 padding-remove'>
                <input
                  autoComplete='off'
                  id='inp_freq'
                  type='text'
                  className='form-control '
                  placeholder='Frequency'
                />
              </div>
            </div>
            <button
              type='button'
              className='btn btn-info text-center '
              id='closeSlider'
              onClick={this.props.closeSlider}
            >
              <span
                id='close'
                title='Close Slider'
                className='glyphicon glyphicon-remove'
              ></span>
            </button>
          </form>
          <div className='col-sm-12 padding-remove periodTableWrap'>
            <div className='col-sm-11 padding-remove'>
              {/* <TableComponent data = {this.state.lstCollection} /> */}
              {/* <CollectionTable
                                    lstCollection={this.state.lstSchedulePeriod}
                                /> */}
              {/* <PeriodTable
                                    lstSchedulePeriod={this.state.lstSchedulePeriod}
                                    academicScheduleId={this.props.academicScheduleId}
                                    setPeriodForm={this.setPeriodForm}
                                /> */}
            </div>
            <div className='col-xs-1 addPeriods'>
              <span
                id='addPeriods'
                title='Add Period'
                onClick={this.addPeriodReady}
                className='addIcon glyphicon glyphicon-plus-sign'
              ></span>
            </div>
            <div className='col-md-5 col-xs-8 padding-remove popup'>
              {/* <PeriodForm
                                    addPeriod={this.addPeriod}
                                    cancelPopup={this.cancelPopup}
                                    deletePeriod={this.deletePeriod}
                                /> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
  closeSlider = () => {
    $('body').removeClass('openSlider')
  }
  addClassOnBody = id => {
    $('#feeClassSetupTableBody tr#' + id)
      .addClass('selected')
      .siblings()
      .removeClass('selected')
  }
  // setForm = (classId, classSectionId, className, section) => {
  //     let classSection = className + "-" + section
  //     this.setState({
  //         classId: classId,
  //         classSectionId: classSectionId,
  //         classSection: classSection
  //     }, () => this.getFeeBookData())
  // }

  handleCheckboxSave = (event, feeHeadId, feeClassId) => {
    // event.preventDefault()
    // event.stopPropagation();

    let target = event.target
    let chk = event.target.checked
    let { lstFeeCollection } = this.state
    event.target.checked = lstFeeCollection[target.id].selected
    if (
      Array.isArray(this.state.lstFeeCollection) &&
      target.id != null &&
      this.state.lstFeeCollection[target.id] != null
    ) {
      // console.log(lstFeeCollection[target.id].selected)
      //toggle the current checkbox
      if (lstFeeCollection[target.id].selected === true) {
        // let selected = false;
        // event.target.checked = false;
        this.saveFeeCollection(event, feeHeadId, feeClassId, chk)
        // this.getFeeBookData();
      } else {
        // let selected = true;
        // event.target.checked = true;
        this.saveFeeCollection(event, feeHeadId, feeClassId, chk)
      }
      lstFeeCollection[target.id].selected = target.checked
      this.setState({
        lstFeeCollection
      })
    }
  }
  saveFeeCollection = (e, feeHeadId, feeClassId, selected) => {
    var saveFeeClassHead = {}
    saveFeeClassHead = {
      feeClassId: selected ? 0 : feeClassId,
      selected: selected,
      feeHeadId: feeHeadId,
      classId: this.state.classId,
      classSectionId: this.state.classSectionId
    }
    this.setState({ loader: 'block', opacity: 0.5 })
    axios({
      url: httpURL + '/sms/fee/cls/head/save',
      method: 'POST',
      params: {
        screenId: screenId,
        saveFeeClassHead: JSON.stringify(saveFeeClassHead)
      },
      headers: { tokenid: this.state.tokenid },
      withCredentials: true
    })
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          toast.success(response.data.SvcMsg)
          e.target.checked = selected
          // console.log('success::selected')
          // this.getFeeBookData();
        } else {
          // e.target.checked = !selected;
          // console.log('failure::deselected')
          toast.error(response.data.SvcMsg)
        }
        this.setState({ loader: 'none', opacity: 1 })
      })
      .catch(e => {
        this.setState({ loader: 'none', opacity: 1 })
        toast.error('Service failed: ' + e)
      })
    e.persist()
  }

  sendFeebook = sectionId => {
    let classSectionId = sectionId
    let studentId = 0;
    if (window.confirm('Are you sure to associate fee heads with class?')) {
      this.setState({ loader: 'block', opacity: 0.5 })
      axios
        .post(
          httpURL + '/sms/fee/cls/students/feebook',
          queryString.stringify({ classSectionId, screenId, studentId }),
          {
            headers: { tokenid: this.state.tokenid },
            withCredentials: true
          }
        )
        .then(response => {
          if (response.data.SvcStatus === 'Success') {
            toast.success(response.data.SvcMsg)
          } else {
            toast.error(response.data.SvcMsg)
          }
          this.setState({ loader: 'none', opacity: 1 })
        })
        .catch(e => {
          this.setState({ loader: 'none', opacity: 1 })
          toast.error('Service failed: ' + e)
        })
    }
  }

  sendFeebookClassSection = sectionId => {
    let classSectionId = sectionId
    this.setState({ loader: 'block', opacity: 0.5 })
    axios
      .post(
        httpURL + '/sms/fee/cls/feebook/mail',
        queryString.stringify({ classSectionId, screenId }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true
        }
      )
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          toast.success(response.data.SvcMsg)
        } else {
          toast.error(response.data.SvcMsg)
        }
        this.setState({ loader: 'none', opacity: 1 })
      })
      .catch(e => {
        this.setState({ loader: 'none', opacity: 1 })
        toast.error('Service failed: ' + e)
      })
  }
  getWingId = wingId => {
    // console.log('wingId' + wingId)
  }

  setClass = pCls => {
    this.setState({ cls: pCls })
  }
  setSection = pSection => {
    this.setState({ section: pSection })
  }

  render() {
    httpURL = lstModules['fees'].url + ':' + lstModules['fees'].port
    return (
      <div style={{ height: '100%' }}>
        <Header wingFromChild={this.getWingId} />
        <ToastContainer autoClose={3000} hideProgressBar />
        <div
          style={{
            position: 'fixed',
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: '50%',
            top: '50%',
            display: this.state.loader
          }}
        >
          <img
            className='loderImg'
            src={require('common/images/loader.gif')}
            alt='Loader...'
          />
        </div>
        <div
          className='mainContainer feeHeads'
          style={{ pointerEvents: this.state.opacity ? 'auto' : 'none' }}
        >
          <div
            className='col-md-7 col-centered'
            style={{ marginTop: 20 + 'px' }}
          >
            {/* <h4>Fee Setup</h4>
                        <hr></hr> */}
             {
              <ClassFeeTable
                // lstFeeClass={this.state.lstFeeClass}
                openSlider={this.showSlider}
                sendFeebookClassSection={this.sendFeebookClassSection}
                sendFeebook={this.sendFeebook}
                setLoader={this.setLoaderValue}
                setOpacity={this.setOpacityValue}
              />
            } 
            {/* {
              <div className='col-xs-12'>
                <FeeClassSetupTableHead
                  tblId={'feeClassSetupHeadTable'}
                  headCls={'tableLayout1'}
                  setClass={this.setClass}
                  setSection={this.setSection}
                  // headWidth={this.state.width}
                  heads={[
                    ['ClassSectionId', 0, 'no-display', 1, 100],
                    ['Class', 40, '', '2', '1', 100],
                    ['Section', 40, '', '2', '1', 100],
                    ['Students', 40, 'br', '', '3', 300],
                    ['Action', 40, '', '2', '1', 100]
                  ]}
                />

                <div
                  className='tableDiv1'
                  style={{
                    height: 400,
                    border: '1px solid #ccc',
                    overflowY: 'scroll'
                  }}
                >
                  {this.state.lstFeeHead && this.state.lstFeeHead.length > 0 && (
                    <TableBody
                      bodyCls={''}
                      tableId='feeClassSetupTable'
                      tbodyId='feeClassSetupTableBody'
                      data={this.state.lstFeeClass}
                      colswidth={[100, 100, 100, 100, 100, 100]}
                      setForm={this.setForm}
                      lstKeys={[
                        'classFeeId',
                        'class',
                        'section',
                        'total',
                        'setUp',
                        'pending',
                        'class',
                        'class'
                      ]}
                      actions={['edit', 'delete']}
                      parentCallback={this.showSlider}
                      sendFeebook={this.sendFeebook}
                      cls={this.state.cls}
                      section={this.state.section}
                      sendFeebookClassSection={this.sendFeebookClassSection}
                    />
                  )}
                </div>
              </div>
            } */}

            <div
              className='col-xs-8 padding-remove slider fullWidthSlider'
              style={{
                pointerEvents: this.state.opacity ? 'auto' : 'none',
                width: '45%'
              }}
            >
              <div className='col-sm-6 ' style={{ width: '45%' }}>
                <FeeCollection
                  key={this.state.tableKey}
                  lstFeeCollection={this.state.lstFeeCollection}
                  handleCheckboxSave={this.handleCheckboxSave}
                  closeSlider={this.closeSlider}
                />
              </div>
              {/* <div className="col-xs-6" style={{ width: '55%' }}>
                                {this.state.showSideView
                                    && this.state.feeBookData
                                    // && this.state.feeBookData.maxFeeHeadCount
                                    && this.state.feeBookData.lstFeebook
                                    && this.state.feeBookData.lstFeebook.length > 0
                                    &&
                                    <FeeBookView
                                        data={this.state.lstFeeCollection}
                                        closeSlider={this.closeSlider}
                                        classId={this.state.classId}
                                        classSectionId={this.state.classSectionId}
                                        feeBookData={this.state.showSideView && this.state.feeBookData}
                                        arrCols={this.state.arrCols}
                                        rows={this.state.feeBookData.maxFeeHeadCount}
                                        getFeeCollectionlist={this.getFeeCollectionlist}
                                        handleCheckboxSave={this.handleCheckboxSave}
                                        classSection={this.state.classSection}
                                        academicYr={this.state.academicYr}
                                    />
                                }
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
