import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { saveTaskSvc, getClassListSvc, getLstTchSvc } from "./taskSvc";
import { toast, ToastContainer } from 'react-toastify';

const TaskSlider = (props) => {

    const [loader, setLoader] = useState("none")
    const [tchId, setTchId] = useState(props.tchId)
    const [state, setState] = useState({ startDt: null, dueDt: null });
    const [taskId, setTaskId] = useState(props.taskId)
    const [fromClsId, setFromClsId] = useState(0)
    const [toClsId, setToClsId] = useState(0)
    const [assignedToId, setAssignedToId] = useState(props.taskObj.assignedToId)
    const [lstTchId, setLstTchId] = useState([])
    const [task, setTask] = useState('');
    const [taskObj, setTaskObj] = useState(props.taskObj);
    const [lstFromCls, setLstFromCls] = useState([])
    const [lstToCls, setLstToCls] = useState([])
    const [lstSelTchId, setLstSelTchId] = useState([]);
    const [lstTch, setLstTch] = useState([]
    );

    useEffect(() => {
        // console.log("taskSlider called props.taskObj", props.taskObj)
        setTaskId(props.taskId)
        setTaskObj(props.taskObj)
        setTask(props.taskObj.task)
        // console.log(props.taskObj.startDt)
        // console.log(props.taskObj.dueDt)
        // console.log("props.taskObj.fromClsId", props.taskObj.fromClsId);
        // console.log("props.taskObj.toClsId", props.taskObj.toClsId);;
        // setFromClsId(props.taskObj.fromClsId);
        // setToClsId(props.taskObj.toClsId);
        var startDtObj = null;
        var dueDtObj = null;
        if (props.taskObj.startDt) {
            var stDateParts = props.taskObj.startDt.split("-");
            startDtObj = new Date(stDateParts[2], stDateParts[1] - 1, stDateParts[0]);
        }
        if (props.taskObj.dueDt) {
            var dueDtParts = props.taskObj.dueDt.split("-");
            dueDtObj = new Date(dueDtParts[2], dueDtParts[1] - 1, dueDtParts[0]);

        }
        // console.log("startdtObj", startDtObj);
        // console.log("duedtObj", dueDtObj);
        setState({ startDt: startDtObj, dueDt: dueDtObj })
        setAssignedToId(props.taskObj.assignedToId)
        // if (props.taskObj.assignedToId === 20) {
        //     // console.log("props.assignedToId = 20, going to set fromClsId and toClsId");
        //     // console.log("props.taskObj.fromClsId",props.taskObj.fromClsId);
        //     // console.log("props.taskObj.toClsId",props.taskObj.toClsId);
        // } else if (props.assignedToId === 10) {

        // }
        // console.log("assignedToId = " + props.taskObj.assignedToId)
        getLstClass();
    }, [props.taskId, props.taskObj]);

    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        // console.log([day, mnth, date.getFullYear()].join("-"));
        return [day, mnth, date.getFullYear()].join("-");
    }
    function getLstClass() {
        // console.log("getLstclass called");
        // setLoader('block')
        getClassListSvc(cbClsResponseChange)
        getLstTchSvc(props.taskId, cbLstTchResponse)
    }
    function cbClsResponseChange(data) {
        // setLoader("none")
        if (data.status === "Success") {
            setLstFromCls(data.lstClass);
            setLstToCls(data.lstClass);
            // console.log("props.taskObj.fromClsId", props.taskObj.fromClsId);
            // console.log("props.taskObj.toClsId", props.taskObj.toClsId);
            setFromClsId(props.taskObj.fromClsId);
            setToClsId(props.taskObj.toClsId)
        }

    }

    function cbLstTchResponse(data) {
        // setLoader("none")
        if (data.status === "Success") {
            setLstTch(data.lstTch)
            getSelLstTchId(data.lstTch)
        } else {
            toast.error(data.message)
        }

    }
    function getSelLstTchId(pLstTch) {
        var tmpLstTchId = []
        for (var i = 0; i < pLstTch.length; i++) {
            var obj = pLstTch[i];
            // console.log(i + "::" + (obj.selected === true))
            if (obj.selected === true) {
                tmpLstTchId.push(obj.staffId);
            }
        }
        // console.log(tmpLstTchId)
        setLstSelTchId(tmpLstTchId)
    }
    function handleTaskChange(param) {
        setTask(param)
    }
    function handleDueDtChange(param) {
        setState({ ...state, dueDt: param })
    }
    function handleStartDtChange(param) {
        setState({ ...state, startDt: param })
    }

    function handleFromClsIdChange(param) {
        // console.log("handleFromClsIdCahnge called, param", param);
        // console.log("fromClsId beforeChange", fromClsId);
        setFromClsId(param)
    }
    function handleToClsIdChange(param) {
        // console.log("handleToClsIdCahnge called, param", param);
        setToClsId(param)
    }
    function handleAssignToIdChange(param) {
        // console.log('handleAssignToIdChange called', param);
        setAssignedToId(param)
        // console.log(param)
    }
    async function handleLstTchIdChange(pId, pChecked) {
        var tmpLstTch = lstSelTchId
        pId = parseInt(pId)
        pChecked = !!(pChecked)
        // await setLstTchId(tmpLstTch)
        // console.log("checked = " + pChecked + "::tchId = " + pId + "::lstSelTchId = " + lstSelTchId)
        if (pChecked && !lstSelTchId.includes(pId)) {
            // console.log("push case")
            tmpLstTch.push(parseInt(pId));
            setLstSelTchId(tmpLstTch);
        } else {
            // console.log("remove case")
            tmpLstTch.splice(tmpLstTch.indexOf(pId), 1)
            setLstSelTchId(tmpLstTch);
        }
        // console.log(tmpLstTch);
        setLstTchId(tmpLstTch)
    }
    function saveTask() {
        var fClsId = 0;
        var tClsId = 0;
        let pTaskObj = {};
        if (assignedToId === 10) {
            setFromClsId(fClsId);
            setToClsId(tClsId)

            pTaskObj = {
                taskId: props.taskId,
                task: task,
                dueDt: convert(state.dueDt),
                startDt: convert(state.startDt),
                fromClsId: fClsId,
                toClsId: tClsId,
                assignedToId: assignedToId,
                lstTchId: lstTchId
            }
        } else if (assignedToId === 20) {
            pTaskObj = {
                taskId: props.taskId,
                task: task,
                dueDt: convert(state.dueDt),
                startDt: convert(state.startDt),
                fromClsId: fromClsId,
                toClsId: toClsId,
                assignedToId: assignedToId,
                lstTchId: lstTchId
            }
        } else {
            pTaskObj = {
                taskId: props.taskId,
                task: task,
                dueDt: convert(state.dueDt),
                startDt: convert(state.startDt),
                fromClsId: fClsId,
                toClsId: tClsId,
                assignedToId: assignedToId,
                lstTchId: lstTchId
            }
        }
        setLoader("block")
        saveTaskSvc(pTaskObj, cbSaveTaskResponse)
    }
    function cbSaveTaskResponse(data) {
        setLoader("none")
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg)
            props.getTaskList();
        } else {
            toast.error(data.SvcMsg)
        }
    }
    const colgrp = <colgroup>
        <col width="10%"></col>
        <col width="40%"></col>
        <col width="30%"></col>
        <col width="20%"></col>

    </colgroup>

    return (
        <>
            <form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", height: 'auto', width: '90%', margin: '10px auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={props.closeSlider} style={{ right: "50%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }} >
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div >
                <div className='row no-display' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Task Id</label>
                        <div className='col-sm-6 padding-remove'>
                            <input type="text" defaultValue={taskId} className="form-control" readOnly></input>
                        </div>
                    </div>
                </div>
                <div className='form-group' style={{ width: '100%' }}>

                    <label className="control-label col-sm-2" style={{ width: '19%' }}>Task</label>

                    <div className="form-group col-sm-8">
                        <div className='col-sm-12 padding-remove'>
                            <textarea
                                defaultValue={task}
                                className="form-control"
                                onChange={(e) => handleTaskChange(e.target.value)}
                                maxLength="100"></textarea>
                        </div>
                    </div>
                </div>

                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Start Date</label>
                        <div className='col-sm-6 padding-remove'>
                            <DatePicker id="inp_startDate"
                                className='form-control text-center'
                                placeholderText="Start Date"
                                selected={state.startDt}
                                dateFormat="dd-MM-yyyy"
                                onChange={handleStartDtChange}
                                autoComplete='off'
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                            />
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Due Date</label>
                        <div className='col-sm-6 padding-remove'>
                            <DatePicker id="inp_dueDate"
                                className='form-control text-center'
                                placeholderText="Due Date"
                                selected={state.dueDt}
                                dateFormat="dd-MM-yyyy"
                                onChange={handleDueDtChange}
                                autoComplete='off'
                            />

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Assign To</label>
                        <div className='col-sm-1 form-group'>
                            <label className="radio-inline" style={{ margin: 0 }}><input type='radio' name="assignTo" value={10} checked={assignedToId === 10} onChange={(e) => handleAssignToIdChange(parseInt(e.target.value))}></input>Teachers</label>
                            <label className="radio-inline" style={{ margin: 0 }}><input type='radio' name="assignTo" value={20} checked={assignedToId === 20} onChange={(e) => handleAssignToIdChange(parseInt(e.target.value))}></input>Students</label>
                            <label className="radio-inline" style={{ margin: 0 }}><input type='radio' name="assignTo" value={30} checked={assignedToId === 30} onChange={(e) => handleAssignToIdChange(parseInt(e.target.value))}></input>Others</label>
                        </div>
                    </div>
                </div>
                {assignedToId === 20 && <>
                    <div className='row' style={{ width: '100%' }}>
                        <div className="form-group col-md-6">
                            <label className="control-label col-sm-5">From Class</label>
                            <div className='col-sm-6 padding-remove'>
                                <select 
                                className="form-control inp-search"
                                 id="cmbFromCls"
                                value={fromClsId} 
                                onChange={(e) => handleFromClsIdChange(e.target.value)} 
                                >
                                    <option value={0}>From Class</option>
                                    {lstFromCls && lstFromCls.map((cls, idx) => {
                                        return <option key={idx} value={cls.clsId}>{cls.cls}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ width: '100%' }}>
                        <div className="form-group col-md-6">
                            <label className="control-label col-sm-5">ToClass</label>
                            <div className='col-sm-6 padding-remove'>
                                <select value={toClsId} onChange={(e) => handleToClsIdChange(e.target.value)} className="form-control inp-search" id="cmbToCls">
                                    <option value={0}>To Class</option>
                                    {lstToCls && lstToCls.map((cls, idx) => {
                                        return <option key={idx} value={cls.clsId}>{cls.cls}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </>}
                {assignedToId === 10 && <div className="col-sm-12" style={{ width: '100%' }}>
                    <table className="tableLayout1">
                        {colgrp}
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th>Teacher</th>
                                <th>Role</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                    </table>
                    <div className='tablebodyWrapper'>
                        <table className='tableLayout'>
                            {colgrp}
                            <tbody>
                                {lstTch && lstTch.length ?
                                    lstTch.map((obj, idx) => {
                                        return <tr key={idx} id={obj.staffId}
                                            onClick={(e) => setTchId(obj.staffId)} className={obj.staffId === tchId ? "selected" : ""}>
                                            <td className="no-display">{obj.staffId}</td>
                                            <td className="text-left">
                                                <input type="checkbox"
                                                    key={obj.staffId}
                                                    id={obj.staffId}
                                                    defaultChecked={obj.selected}
                                                    onChange={(e) => handleLstTchIdChange(e.target.id, e.target.checked)} />
                                            </td>
                                            <td className="text-left">{obj.staff}</td>
                                            <td className="text-left">{obj.mainRole}</td>
                                            <td className="text-left">{obj.status}</td>
                                        </tr>
                                    })
                                    : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>}
                <div className='row' style={{ width: '100%', paddingBottom: "1px" }}>
                    <div className="col-md-offset-10 col-md-1 padding-remove">
                        <button type="button" className="btn btn-info pull-right" onClick={(e) => saveTask()}>Save</button>
                    </div>
                </div>
            </form>
        </>
    )
}
export default TaskSlider;