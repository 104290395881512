import axios from "axios";
import lstModules from "common/json/lstModules.json";

var tokenid = localStorage.getItem("tokenid");
var httpURLComm =
  lstModules["communication"].url + ":" + lstModules["communication"].port;
var httpURL = lstModules["transport"].url + ":" + lstModules["transport"].port;

function getChannelListSvc(callBack) {
  axios({
    url: httpURLComm + "/sms/communication/channel/list",
    method: "POST",
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      // console.log("Channel List Catch error");
    });
}
function sendCommunicationSvc(formData, callBack) {
  
  axios({
    url: httpURL + "/sms/tpt/route/notify_students",
    method: "POST",
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((response) => {
      var data = response.data;
      callBack(data);
    })
    .catch((error) => {
      // console.log("Error Occured");
    });
}
export {getChannelListSvc,sendCommunicationSvc}