import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var httpURL =
  lstModules['communication'].url + ':' + lstModules['communication'].port
var httpURLMain = lstModules['main'].url + ':' + lstModules['main'].port
var tokenid = localStorage.getItem('tokenid')

function getCommunicationListSvc (callBack) {
  axios({
    url: httpURL + '/sms/communication/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console.log('Communication List Catch error')
    })
}

function viewSampleSvc (pTemplate, callBack) {
  axios({
    url: httpURL + '/sms/communication/sample',
    method: 'POST',
    params: { template: pTemplate },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console.log('View Template Catch error')
    })
}

function saveCommunicationSvc (pCommunicationObj, callBack) {
  var formData = new FormData()
  formData.append('commObj', JSON.stringify(pCommunicationObj))
  axios({
    url: httpURL + '/sms/communication/save',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      
      var data = response.data
      if(pCommunicationObj.commId){
        // console.log(pCommunicationObj)
        // console.log('edit case')
        callBack(data, pCommunicationObj.commId)
      }else{
        // console.log(data)
        // console.log('add case')
        callBack(data, data.communicationId)
      }
    })
    .catch(error => {
      // console.log('Error Occured')
    })
}

function sendCommunicationSvc (pCommunicationId, callBack) {
  axios({
    url: httpURL + '/sms/communication/send',
    method: 'POST',
    params: { commId: pCommunicationId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      var data = response.data
      callBack(data)
    })
    .catch(error => {
      // console.log('Error Occured')
    })
}
function deleteCommunicationSvc (pCommunicationId, pTemplateId, callBack) {
  axios({
    url: httpURL + '/sms/communication/delete',
    method: 'POST',
    params: { commId: pCommunicationId, templateId: pTemplateId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console.log('error')
    })
}
function completeCommunicationSvc (pCommunicationId, callBack) {
  axios({
    url: httpURL + '/sms/communication/complete',
    method: 'POST',
    params: { communicationId: pCommunicationId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console.log('error')
    })
}

function getCommunicationDtlsSvc (pCommunicationId, cb) {
  // console.log('pCommunicationId= ' + pCommunicationId)
  axios({
    url: httpURL + '/sms/communication/dtls',
    method: 'POST',
    params: { commId: pCommunicationId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      cb(data)
    })
    .catch(error => {
      // console.log('Communication Dtls Catch error')
    })
}
function getClassListSvc (callback1) {
  // console.log('getClassListSvc')
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURLMain + '/sms/mst/cls/list',
    method: 'POST',
    params: { screenId: 0 },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      // console.log('data.SvcStatus=' + response.data.SvcStatus)
      obj.message = response.data.SvcMsg
      obj.status = 'Success'
      obj.lstClass = response.data.lstClass
      // console.log(obj)
      callback1(obj)
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback1(obj)
    })
}

function getLstTchSvc (pCommunicationId, cb) {
  // console.log('getLstTchSvc')
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/communication/tch/list',
    method: 'POST',
    params: { communicationId: pCommunicationId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      obj.message = response.data.SvcMsg
      obj.status = 'Success'
      obj.lstTch = response.data.lstTch
      // console.log(obj)
      cb(obj)
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      cb(obj)
    })
}

function getWingListSvc (callBack) {
  axios({
    url: httpURL + '/sms/communication/wing/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console.log('Wing List Catch error')
    })
}

function getSectionListSvc (callBack) {
  axios({
    url: httpURL + '/sms/communication/section/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console.log('Section List Catch error')
    })
}
function getStudentListSvc (callBack) {
  axios({
    url: httpURL + '/sms/communication/student/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console.log('Student List Catch error')
    })
}

function getPlaceholderListSvc (callBack) {
  axios({
    url: httpURL + '/sms/communication/placeholder/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console.log('Placeholder List Catch error')
    })
}

function getLevelListSvc (callBack) {
  axios({
    url: httpURL + '/sms/communication/level/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console.log('Level List Catch error')
    })
}


function getModuleListSvc (callBack) {
  axios({
    url: httpURL + '/sms/mst/module/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console.log('Module List Catch error')
    })
}

function getChannelListSvc (callBack) {
  axios({
    url: httpURL + '/sms/communication/channel/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console.log('Channel List Catch error')
    })
}


const communicationGroupListSvc = (cbLstCommunicationGroup) => {
  // console.log("CommunicationGroupListSvc called, url", httpURL + '/list');
  const obj = { status: "", message: "" };
  axios({
      url: httpURL + '/sms/communication/group/list',
      method: "POST",
      headers: { 'tokenid': tokenid },
      withCredentials: true,
  }).then((response) => {
      // console.log("response in communicationGroupListSvc", response);
      obj.status = response.data.SvcStatus;
      obj.message = response.data.SvcMsg;
      if (response.data.SvcStatus === "Success") {
          obj.lstCommGroup = response.data.lstCommGroup;
      }
      cbLstCommunicationGroup(obj);
  }).catch((error) => {
      obj.status = "Exception";
      obj.message = error;
      cbLstCommunicationGroup(obj);
  })
}


export {
  getCommunicationListSvc,
  saveCommunicationSvc,
  deleteCommunicationSvc,
  getCommunicationDtlsSvc,
  getClassListSvc,
  getLstTchSvc,
  completeCommunicationSvc,
  getWingListSvc,
  getSectionListSvc,
  getStudentListSvc,
  getPlaceholderListSvc,
  getLevelListSvc,
  viewSampleSvc,
  sendCommunicationSvc,
  getChannelListSvc, 
  getModuleListSvc,
  communicationGroupListSvc
}
