import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios'
import "../css/payrollSetupCtrl.css"
import lstModules from 'common/json/lstModules.json';
const httpURL = lstModules['payrollSetup'].url + ':' + lstModules['payrollSetup'].port;
//var httpURL = lstModules['payrollSetup'].url + ":" + lstModules['enquiry'].port;
var tokenid = localStorage.getItem('tokenid');

function AllowancesSlider(props){

  const [allowId,setAllowId]=useState(props.allowObj.allowanceId)
  const [allowCode,setAllowCode]=useState(props.allowObj.allowanceCode)
  const [label,setLabel]=useState(props.allowObj.allowanceLabel)
  const [lstGradeId,setLstGradeId]=useState([])
  const [lstRoleId,setLstRoleId]=useState([])
  const [lstRole,setLstRole]=useState([])
  const [flg,setFlg]=useState(false)
  const [lstGrade,setLstGrade]=useState([])
  const [loader, setLoader] = useState("none");
  const [esi,setEsi]=useState(false)
  const [tds,setTds]=useState(false)
  const [pf,setPf]=useState(false)
  const [tax,setTax]=useState(false)
  const [pctBasic,setPctBasic]=useState(0)
  const [flgPctOfBasic,setFlgPctOfBasic]=useState(false)
  useEffect(()=>{
        setLstGrade([])
        setLstRole([])
        setLoader('block')
        getLstGrade(cbGetLstGrade)
        getLstRole(cbGetLstRole)
        setAllowId(props.allowId)
        setAllowCode(props.allowObj.allowanceCode)
        setLabel(props.allowObj.allowanceLabel)
        setFlg(props.allowObj.flgStdAllowance)
        setFlgPctOfBasic(props.allowObj.flgPctOfBasic)
        setPctBasic(props.allowObj.pctBasic)
  },[])
  function getLstGrade(callback){
    axios(
        {
            url: httpURL +"/sms/allowance/grade/list",
            method: 'POST',
            params: {allowanceId:props.allowId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callback(data)
            
  
        }
    ).catch((error) => {
        console.error(error)
    });
  }
  function getLstRole(callback){
    axios(
        {
            url: httpURL +"/sms/allowance/role/list",
            method: 'POST',
            params: {allowanceId:props.allowId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callback(data)
            
  
        }
    ).catch((error) => {
        console.error(error)
    });
  }
  function cbGetLstGrade(data){
    var tempArr=[]
    for(var i=0;i<data.lstGrade.length;i++){
        if(data.lstGrade[i].selected===true){
          tempArr.push(data.lstGrade[i].gradeId)
        }
    }
    setLstGrade(tempArr)
      setLstGradeId(data.lstGrade)
      setLoader('none')
  }
  function cbGetLstRole(data){
      var tempArr=[]
      for(var i=0;i<data.lstRole.length;i++){
          if(data.lstRole[i].selected===true){
            tempArr.push(data.lstRole[i].roleId)
          }
      }
      setLstRole(tempArr)
      setLstRoleId(data.lstRole)
      setLoader('none')
  }
  function handlePctOfBasicChange(param){
    setFlgPctOfBasic(param)
  }
  function handlePctBasicChange(param){
      setPctBasic(param)
  }
  function handleAllowCodeChange(param){
    setAllowCode(param)
  }
  function handleLabelChange(param){
    setLabel(param)
  }
  function handleFlgChange(param){
    setLabel(param)
  }
  function handleGradeCBoxChange(gradeId,status){
    var lstTempArr=lstGrade
    if(status && !lstTempArr.includes(gradeId)){
      lstTempArr.push(gradeId)
      setLstGrade(lstTempArr)
      // console.log("checked " + lstTempArr)
    }
    else {

      lstTempArr.splice(lstTempArr.indexOf(gradeId), 1)
      setLstGrade(lstTempArr)
      // console.log("unchecked" + lstTempArr)
      //returnSelEnqId()
  }

  }
  function handleRoleCBoxChange(roleId,status){
      var lstTempArr=lstRole
      if(status && !lstTempArr.includes(roleId)){
        lstTempArr.push(roleId)
        setLstRole(lstTempArr)
        // console.log("checked " + lstTempArr)
      }
      else {

        lstTempArr.splice(lstTempArr.indexOf(roleId), 1)
        setLstRole(lstTempArr)
        // console.log("unchecked" + lstTempArr)
        //returnSelEnqId()
    }
  }

  function saveResult(cbSaveAllowance){
    setLoader("block");
    const allowanceObj={
        allowanceId:allowId,
        allowanceCode:allowCode,
        allowanceLabel:label,
        flgStdAllowance:flg,
        lstGradeId:lstGrade,
        lstRoleId:lstRole,
        flgEsi:esi,
        flgTax:tax,
        flgTds:tds,
        flgPf:pf,
        flgPctOfBasic:!!flgPctOfBasic,
        pctBasic:parseInt(pctBasic)

    }
    var formData = new FormData();
    formData.append("allowance",JSON.stringify(allowanceObj))
    
    // formData.append("allowanceId", allowId)
    // formData.append("allowanceCode", allowCode) 
    // formData.append("allowanceLabel", label)
    // formData.append("flgStdAllowance", flg)
    // formData.append("lstGradeId", lstGrade)
    // formData.append("lstRoleId", lstRole)
//     axios.post(httpURL +"/sms/allowance/save",
//     queryString.stringify({ 'allowanceId': allowId, 'allowanceCode': allowCode,"allowanceLabel":label,"flgStdAllowance": flg,
//     "lstGradeId":JSON.stringify(lstGrade),"lstRoleId":JSON.stringify(lstRole)
// }), {
//         axios.post(httpURL +"/sms/allowance/save",
//     queryString.stringify({ allowance:allowanceObj
// }), {
    axios({
        url: httpURL +"/sms/allowance/save",
        method: "POST",
        headers: { 'tokenid': tokenid },
        data: formData,
        withCredentials: true,
}).then(
        (Response) => {
            var data = Response.data
            cbSaveAllowance(data)
        }
    ).catch((error) => {
        console.error(error)
    });

  }
  function cbSaveAllowance(data){
    setLoader("none");
    if(data.SvcStatus==='Success'){
        toast.success(data.SvcMsg)
        props.generateRandomNo()
    }
    else{
        toast.error(data.SvcMsg)
    }
  }
  function handleEsiCBoxChange(e){
      setEsi(e.target.checked)
  }
  function handlePfCBoxChange(e){
      setPf(e.target.checked)
  }
  function handleTaxCBoxChange(e){
      setTax(e.target.checked)
  }
  function handleTdsBoxChange(e){
      setTds(e.target.checked)
  }
    return(<form    
    className="form form-horizontal"
    style={{
        pointerEvents: loader === "none" ? "auto" : "none",
      height: "100%",
      width: "100%",
      overflowX: 'hidden',
      background: "#fff",
      border: "1px solid #ccc",
      borderRadius: 10,
    }}>
                <div className="form-group col-sm-12">
                    <label className="control-label col-md-5">Allowance Code:<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='text' className="form-control" defaultValue={props.allowObj.allowanceCode} onChange={(e) => handleAllowCodeChange(e.target.value)} required></input>
                    </div>
                </div>
                <div className="form-group col-sm-12">
                    <label className="control-label col-md-5">Label:<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='text' className="form-control" defaultValue={props.allowObj.allowanceLabel} onChange={(e) => handleLabelChange(e.target.value)} required></input>
                    </div>
                </div>
                <div className="form-group col-sm-12">
                    <label className="control-label col-md-5">Special Allowance:<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='checkbox' className="form-control" defaultChecked={props.allowObj.flgStdAllowance} onChange={(e) => handleFlgChange(e.target.value)} required></input>
                    </div>
                </div>
                <div className="form-group col-sm-12" >
                    <label className="control-label col-md-5">Percentage of Basic<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='checkbox' className="form-control" defaultChecked={props.allowObj.flgPctOfBasic} onChange={(e) => handlePctOfBasicChange(e.target.value)} ></input>
                    </div>
                </div>
                <div className="form-group col-sm-12" >
                    <label className="control-label col-md-5">Percentage<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='number' readOnly={!flgPctOfBasic} className="form-control" defaultValue={props.allowObj.pctBasic} onChange={(e) => handlePctBasicChange(e.target.value)} ></input>
                    </div>
                </div>



                <div className="form-group col-sm-10 radioCheckboxWrap" style={{marginLeft:'30px',paddingTop: '5px',paddingBottom: '5px'}} >
                <div className="col-sm-12 text-center" style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 10, }}>Applicable To:</div>
                <div className='col-sm-3 multiButtonWrap'>
                <label className="control-label ">ESI<span className="red-color">*</span></label>
                <input
                    type="checkbox"
                    defaultChecked={props.allowObj.flgEsi}
                   onClick={(e) => handleEsiCBoxChange(e)}
                  />
                </div>
                <div className='col-sm-3 multiButtonWrap'>
                <label className="control-label ">PF<span className="red-color">*</span></label>
                <input
                    type="checkbox"
                    defaultChecked={props.allowObj.flgPf}
                   onClick={(e) => handlePfCBoxChange(e)}
                  />
                </div>
                <div className='col-sm-3 multiButtonWrap'>
                <label className="control-label ">TAX<span className="red-color">*</span></label>
                <input
                    type="checkbox"
                    defaultChecked={props.allowObj.flgTax}
                    onClick={(e) => handleTaxCBoxChange(e)}
                  />
                </div>
                <div className='col-sm-3 multiButtonWrap'>
                <label className="control-label ">TDS<span className="red-color">*</span></label>
                <input
                    defaultChecked={props.allowObj.flgTds}
                    type="checkbox"
                    onClick={(e) => handleTdsBoxChange(e)}
                  />
                </div>
                </div>
                <div className=" col-md-12 col-centered">
                <div className="col-md-6">
                    <table className="col-sm-12">
                        <thead>
                            <tr><th colSpan="2">Grade</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lstGradeId.map((obj,idx)=>{
                                return(
                            <tr key={idx}>
                                <td style={{textAlign:'center'}}>
                                   {obj.grade}</td>
                                    <td><input
                                     onClick={(e) => handleGradeCBoxChange(obj.gradeId, e.target.checked)}
                                        defaultChecked={obj.selected}
                                        type="checkbox"/>
                                </td>
                            </tr>)
})}
                        </tbody>
                    </table>
                </div>
                <div className="col-md-6">
                    <table className="tableLayout1" >
                        <thead >
                            <tr><th>Roles</th>
                            </tr>
                        </thead>
                        </table>
                        <div className='tablebodyWrapper2'>
                        <table className='tableLayout'>
                        <tbody>
                            {lstRoleId.map((obj,idx)=>{
                                return(
                            <tr key={idx}>
                                <td style={{textAlign:'center'}}>
                                   {obj.role}</td>
                                    <td><input
                                        defaultChecked={obj.selected}
                                        onClick={(e) => handleRoleCBoxChange(obj.roleId, e.target.checked)}
                                        type="checkbox"/>
                                </td>
                            </tr>)
})}
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>

                <div className="form-group col-md-12">
                    <div className="col-md-offset-6 col-md-1 padding-remove">
                        <button type="button" className="btn btn-info" onClick={(e) => saveResult(cbSaveAllowance)}>Save</button>
                    </div>
                </div>


    </form>)
}
export default AllowancesSlider;
