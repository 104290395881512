
import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['main'].url + ':' + lstModules['main'].port;
var tokenid = localStorage.getItem("tokenid");


export function getClassData(setData) {
    var status = 'Success'
    var obj = { status: status };

    // console.log(httpURL)
    axios({
        url: httpURL + '/sms/performance/mst/all_section_list',
        method: "POST",
        params: {},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;
        if (response.data.SvcStatus === "Success") {
            obj.lstAllClassSections = response.data.lstAllClassSections;
            // console.log(obj);
        }
        setData(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        setData(obj)
    });
}


export const lstAdmitCardSvc = (pSectionId, callback) => {
    // console.log("lstAdmitCardSvc called");
    const formData = new FormData();
    formData.append("sectionId",pSectionId);
    var obj = { status: "Success", message: "" }
    axios({
        url: httpURL + '/sms/perf/admitcard/list_student',
        method: "POST",
        data:formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log('data.SvcStatus in lstAdmitCardSvc', response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstStudent = response.data.lstStudent;
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstIdCard = [];
        callback(obj);
    });
}

export function getLogoSvc(callback) {

    var xhr = new XMLHttpRequest();
    xhr.open("GET", httpURL + '/sms/school/profile/logo/view', true);
    xhr.setRequestHeader('tokenid', tokenid);

    xhr.responseType = "arraybuffer";

    xhr.onload = function (e) {
        var arrayBufferView = new Uint8Array(this.response);
        var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        // console.log("imageUrl in getLogoSvc", imageUrl);
        callback(imageUrl);
    };
    xhr.send();
}

export function getLstExamSvc (callback) {
    // console.log("getLstExamSvc called");
    axios({
      url: httpURL + '/sms/performance/mst/exam/list',
      method: 'POST',
      params: {},
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(response => {
        // console.log("response in getLstExamSvc", response);
        // console.log('data.SvcStatus=' + response.data.SvcStatus)
        callback(response.data);
      })
      .catch(error => {
        callback(error);
      })
  }