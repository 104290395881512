import React from 'react';
import axios from "axios";
import lstModules from 'common/json/lstModules.json';
import 'react-toastify/dist/ReactToastify.css';


let httpURL = "";
let queryString = require('querystring');
var tokenid = localStorage.getItem("tokenid");
httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;

export const getStudentFeeDtlsSvc = (studentId, cbgetStudentFeeDtls) => {
    var obj = { status: 'Success', message:"" };
    axios({
        url: httpURL + "/sms/fee/discount/list",
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
        data: queryString.stringify({ 'studentId': studentId }),
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if(response.data.SvcStatus === "Success"){
            obj.lstDiscount = response.data.lstDisc;
        }
        cbgetStudentFeeDtls(obj);
    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = 'error';
        cbgetStudentFeeDtls(obj);
    })
}

// export const getDiscountType = (cbDiscountTable) => {
//     var obj = { status: 'Success', message:"" };
//     axios({
//         url: httpURL + "/sms/mst/discount_type",
//         method: "POST",
//         headers: { 'tokenid': tokenid },
//         withCredentials: true,
//     }).then((response) => {
//         obj.status = response.data.SvcStatus;
//         obj.lstDiscountType = response.data.lstType;
//         obj.message = response.data.SvcMsg;
//         cbDiscountTable(obj);
//     }).catch((e) => {
//         obj.status = 'Exception';
//         obj.message = 'error';
//         cbDiscountTable(obj);
//     })
// }


export const saveStudentFeeDtlsSvc = (studentId, lstSaveDiscount, cbSaveStudentFeeDtls) => {
    // console.log("lstSaveDiscount received in saveDiscountService:",lstSaveDiscount);
    var obj = { status: 'Success', message:"" };
    axios({
        url: httpURL + "/sms/fee/discount/save",
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
        data: queryString.stringify({ 'studentId': studentId, "lstDiscount":JSON.stringify(lstSaveDiscount) }),
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.waverId = response.data.waverId;
        cbSaveStudentFeeDtls(obj);
    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = 'error';
        cbSaveStudentFeeDtls(obj);
    })
}


export const removeDiscountSvc = (lstWaiverId, cbRemoveDiscount) => {
    var obj = { status: 'Success', message:"" };
    axios({
        url: httpURL + "/sms/fee/discount/remove",
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
        data: queryString.stringify({ "lstWaiverId": JSON.stringify(lstWaiverId) }),
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.waverId = response.data.waverId;
        cbRemoveDiscount(obj);
    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = 'error';
        cbRemoveDiscount(obj);
    })
}


// export const feeBreakdownSvc = (studentId, mnthId, cbfeeBreakdown) => {
//     var obj = { status: 'Success', message:"" };
//     axios({
//         url: httpURL + "/sms/fee/discount/breakdown",
//         method: "POST",
//         headers: { 'tokenid': tokenid },
//         withCredentials: true,
//         data: queryString.stringify({ 'studentId':studentId, "monthId":mnthId }),
//     }).then((response) => {
//         obj.status = response.data.SvcStatus;
//         obj.message = response.data.SvcMsg;
//         obj.lstFee = response.data.lstFee;
//         cbfeeBreakdown(obj);
//     }).catch((e) => {
//         obj.status = 'Exception';
//         obj.message = 'error';
//         cbfeeBreakdown(obj);
//     })
// }