import axios from "axios";
import lstModules from "common/json/lstModules.json";
var httpURL = lstModules["transport"].url + ":" + lstModules["transport"].port;
var httpURLWings = lstModules["wings"].url + ":" + lstModules["wings"].port;
var httpURLCommon = lstModules["main"].url + ":" + lstModules["main"].port;
var tokenid = localStorage.getItem("tokenid");
function getRouteListSvc(callBack) {
  axios({
    url: httpURL + "/sms/tpt/route/list",
    method: "POST",
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      // console.log("Test List Catch error");
    });
}
function saveRouteSliderSvc(pRouteObj, callBack) {
  var formData = new FormData();
  formData.append("route", JSON.stringify(pRouteObj));
  axios({
    url: httpURL + "/sms/tpt/route/save",
    method: "POST",
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((response) => {
      var data = response.data;
      callBack(data);
    })
    .catch((error) => {
      // console.log("Error Occured");
    });
}
function getRouteStaffDtlsSvc(pRouteId, callBack) {
  axios({
    url: httpURL + "/sms/tpt/route/staff/dtls",
    method: "POST",
    params: { routeId: pRouteId },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      // console.log("Test List Catch error");
    });
}
function getRouteStaffListSvc(pRoleId, callBack) {
  axios({
    url: httpURL + "/sms/tpt/role/staff/list",
    method: "POST",
    params: { roleId: pRoleId },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      // console.log("Test List Catch error");
    });
}
function saveRouteStaffSvc(pRouteId, pRouteStaffObj, callBack) {
  var formData = new FormData();
  formData.append("routeId", JSON.stringify(pRouteId));
  formData.append("staff", JSON.stringify(pRouteStaffObj));
  axios({
    url: httpURL + "/sms/tpt/route/staff/save",
    method: "POST",
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((response) => {
      var data = response.data;
      callBack(data);
    })
    .catch((error) => {
      // console.log("Error Occured");
    });
}
function getWingsListSvc(callBack) {
  axios({
    url: httpURLWings + "/sms/wings/list",
    method: "POST",
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      console.error(error);
    });
}
function getRouteArrDepDtls(pRouteId, flgMorEve, callBack) {
  axios({
    url: httpURL + "/sms/tpt/route/arr_dept/dtls",
    method: "POST",
    params: { routeId: pRouteId, flgMorEve: flgMorEve },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      // console.log("getRouteArrDepDtls Catch error");
    });
}
function getBaseLocationListSvc(callBack) {
  axios({
    url: httpURL + "/sms/tpt/base_loc/list",
    method: "POST",
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      // console.log("Test List Catch error");
    });
}
function getArrDepStateListSvc(callBack) {
  axios({
    url: httpURLCommon + "/sms/mst/state/list",
    method: "POST",
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      // console.log(Response.data);
      var ourData = Response.data;
      callBack(ourData);
    })
    .catch((error) => {
      console.error(error);
    });
}
function getArrDepCityListSvc(callBack, pstateId, pCityId) {
  axios({
    url: httpURLCommon + "/sms/mst/city/list",
    method: "POST",
    params: { stateId: pstateId },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      // console.log(Response.data);
      var ourData = Response.data;
      callBack(ourData, pCityId);
    })
    .catch((error) => {
      console.error(error);
    });
}
function getArrDepLocalityListSvc(callBack, pcityId, pLocalityId) {
  axios({
    url: httpURLCommon + "/sms/mst/locality/list",
    method: "POST",
    params: { cityId: pcityId },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      // console.log(Response.data);
      var ourData = Response.data;
      callBack(ourData, pLocalityId);
    })
    .catch((error) => {
      console.error(error);
    });
}
function saveArrDepSvc(pRouteId, pArrDepObj, callBack) {
  var formData = new FormData();
  formData.append("routeId", JSON.stringify(pRouteId));
  formData.append("arrDeptDtls", JSON.stringify(pArrDepObj));
  axios({
    url: httpURL + "/sms/tpt/route/arr_dept/save",
    method: "POST",
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((response) => {
      var data = response.data;
      callBack(data);
    })
    .catch((error) => {
      // console.log("Error Occured");
    });
}
function getStateListSvc(callBack, pStateId, pCityId, pLocalityId) {
  axios({
    url: httpURLCommon + "/sms/mst/state/list",
    method: "POST",
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var ourData = Response.data;

      callBack(ourData, pStateId, pCityId, pLocalityId);
    })
    .catch((error) => {
      console.error(error);
    });
}

function getCityListSvc(callBack, pstateId) {
  axios({
    url: httpURLCommon + "/sms/mst/city/list",
    method: "POST",
    params: { stateId: pstateId },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      // console.log(Response.data);
      var ourData = Response.data;
      callBack(ourData);
    })
    .catch((error) => {
      console.error(error);
    });
}

function getCityListSvcAndSetCity(callBack, pStateId, pCityId) {
  axios({
    url: httpURLCommon + "/sms/mst/city/list",
    method: "POST",
    params: { stateId: pStateId },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      // console.log(Response.data);
      var ourData = Response.data;
      callBack(ourData, pCityId);
    })
    .catch((error) => {
      console.error(error);
    });
}

function getLstStopRangeSvc(callBack) {
  // setLoader('block')
  axios({
    url: httpURL + "/sms/tpt/mst/stop_range/list",
    method: "POST",
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      // console.log("error" + error);
    });
}
function saveStopSvc(pRouteId, pObj, pFlgDirection, cbSaveStopResponse) {
  axios({
    url: httpURL + "/sms/tpt/stop/save",
    method: "POST",
    params: {
      routeId: pRouteId,
      flgDirection: pFlgDirection,
      stop: JSON.stringify(pObj),
    },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((response) => {
      var data = response.data;
      cbSaveStopResponse(data);
    })
    .catch((error) => {
      // console.log("Error Occured");
    });
}

function getLocalityListSvc(callBack, pcityId, pLocalityId) {
  axios({
    url: httpURLCommon + "/sms/mst/locality/list",
    method: "POST",
    params: { cityId: pcityId },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      // console.log(Response.data);
      var ourData = Response.data;
      callBack(ourData, pLocalityId);
    })
    .catch((error) => {
      // console.log("error: " + error.message);
    });
}
function getStopListSvc(routeId, flgMorEve, callBack) {
  axios({
    url: httpURL + "/sms/tpt/stop/list",
    method: "POST",
    params: { routeId: routeId, flgMorEve: flgMorEve },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data, flgMorEve);
    })
    .catch((error) => {
      // console.log("Stop List Catch error");
    });
}

function deleteRouteStopSvc(pRouteId, pStopId, flgDirection, callBack) {
  axios({
    url: httpURL + "/sms/tpt/stop/delete",
    method: "POST",
    params: { routeId: pRouteId, stopId: pStopId, flgDirection: 20 },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      console.error(error);
    });
}
function reorderSvc(pRouteId, pStopId, pAction, pFlgMorEve, callBack) {
  axios({
    url: httpURL + "/sms/tpt/stop/reorder",
    method: "POST",
    params: {
      stopId: pStopId,
      action: pAction,
      routeId: pRouteId,
      flgMorEve: pFlgMorEve,
    },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      console.error(error);
    });
}
function saveStopLatLngSvc( stopId, latLng, cb) {
  var data = {};
  axios({
    url: httpURL + "/sms/tpt/stop/lat_long/save",
    method: "POST",
    params: {
      stopId: stopId,
      latLong: latLng,
    },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      cb(data);
    })
    .catch((error) => {
      data = { SvcStatus: "Success", SvcMsg: error };
      cb(data);
    });
}
export {
  getRouteListSvc,
  saveRouteSliderSvc,
  saveRouteStaffSvc,
  getWingsListSvc,
  getRouteArrDepDtls,
  getBaseLocationListSvc,
  saveArrDepSvc,
  getStopListSvc,
  getStateListSvc,
  getCityListSvc,
  getCityListSvcAndSetCity,
  getLocalityListSvc,
  getRouteStaffDtlsSvc,
  getRouteStaffListSvc,
  saveStopSvc,
  getLstStopRangeSvc,
  reorderSvc,
};
export {
  deleteRouteStopSvc,
  getArrDepCityListSvc,
  getArrDepLocalityListSvc,
  getArrDepStateListSvc,
  saveStopLatLngSvc,
};
