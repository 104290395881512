import axios from 'axios';
import lstModules from 'common/json/lstModules.json'

const httpURL = lstModules['performance'].url + ':' + lstModules['performance'].port;
var tokenid = localStorage.getItem("tokenid");

function getLstEvaluationSvc(callback, examId) {
    axios({
        url: httpURL + '/sms/performance/evaluate/list',
        method: "POST",
        params: { 'examId' : examId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log('data.SvcStatus=' + response.data.SvcStatus);
        callback(response.data);
    }).catch((error) => {
        callback(error);
    });
}

export { getLstEvaluationSvc }