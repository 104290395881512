import React, { useState, useEffect } from 'react'
import lstModules from 'common/json/lstModules.json'
import axios from 'axios'
import 'common/css/master.css'
import Header from 'common/js/header/header.js'
import { toast, ToastContainer } from 'react-toastify'
import {
  getLstCity,
  getLstState,
  getLstLocality,
  getGeneralInfoSvc,
  getDocListSvc,
  getPdfSvc,
  saveDocSvc,
  saveGeneralInfoSvc,
  deleteDocSvc
} from './infoSvc.js'

var httpURL = lstModules['main'].url + ':' + lstModules['main'].port

export default function InfoCtrl () {
  const [cityId, setCityId] = useState(0)
  const [localityId, setLocalityId] = useState(0)
  const [stateId, setStateId] = useState(0)
  const [address1, setAddress1] = useState('')
  const [pin, setPin] = useState('')
  const [school, setSchool] = useState('')
  const [lstState, setLstState] = useState([])
  const [lstCity, setLstCity] = useState([])
  const [lstLocality, setLstLocality] = useState([])
  const [docUrl, setDocUrl] = useState('')
  const [docFile, setDocFile] = useState('')
  const [email, setEmail] = useState('')
  const [qualification, setQualification] = useState('')
  const [affiliationNo, setAffiliationNo] = useState('')
  const [schoolCode, setSchoolCode] = useState('')
  const [contactNo, setContactNo] = useState('')
  const [principalNm, setPrincipalNm] = useState('')
  const [title, setTitle] = useState('')
  const [lstDoc, setLstDoc] = useState([])
  const [docId, setDocId] = useState(0)
  const [openViewer, setOpenViewer] = useState(false)
  const [state, setState] = useState({
    wingId: '',
    loader: 'none',
    loader1: 'none',
    loader2: 'none',
    opacity: 1,
    tokenid: localStorage.getItem('tokenid')
  })

  useEffect(() => {
    getStateList()
  }, [])

  function getStateList () {
    getLstState(handleLstStateResponse)
    getPdfSvc(handleGetDocResponse)
    getGeneralInfo()
  }
  function getGeneralInfo () {
    getGeneralInfoSvc(cbGeneralInfoResponse)
  }
  function getDocList () {
    getDocListSvc(cbDocListResponse)
  }
  function cbDocListResponse (data) {
    setLstDoc(data.lstDoc)
  }
  function setBlankDocDtls () {
    setDocDtls(
      {
        docId: 0,
        title: '',
        docpath: ''
      },
      false
    )
  }

  function viewDocSvc (pPath, callback, pOpenViewTab) {
    var xhr = new XMLHttpRequest()
    xhr.open('GET', httpURL + '/sms/cms/img/view?imgPath=' + pPath, true)
    xhr.setRequestHeader('tokenid', state.tokenid)
    xhr.responseType = 'arraybuffer'
    xhr.onload = function (e) {
      // console.log(xhr)
      var arrayBufferView = new Uint8Array(this.response)
      var blob = new Blob([arrayBufferView], { type: 'image/jpeg' })
      var urlCreator = window.URL || window.webkitURL
      var imageUrl = urlCreator.createObjectURL(blob)
      // console.log(blob)
      callback(imageUrl, xhr.response, pOpenViewTab)
    }
    xhr.send()
  }

  function setDocDtls (obj, pOpenViewTab) {
    // console.log(JSON.stringify(obj))
    setDocId(obj.docId)
    setTitle(obj.title)
    setDocFile(obj.docpath)
    if (obj.docId != 0) viewDocSvc(obj.docpath, cbGetDocResponse, pOpenViewTab)
  }
  function setDoc (id) {
    if (id == 0) {
      setOpenViewer(true)
      const iframe = document.getElementById('viewer')
      iframe.setAttribute('src', docUrl)
      return
    }
    for (var i = 0; i < lstDoc.length; i++) {
      // console.log(id)
      if (id == lstDoc[i].docId) {
        // console.log('id matched')
        setDocDtls(lstDoc[i], true)
        break
      }
    }
    return
  }
  function cbGetDocResponse (url, response, pOpenViewTab) {
    // console.log(url)
    if (!url) {
      if (response.SvcStatus === 'Failure') {
        toast.error(response.SvcMsg)
      }
    } else {
      if (pOpenViewTab) {
        setOpenViewer(pOpenViewTab)
        // window.open(url, '_blank')
        let blob = new Blob([response], { type: 'application/pdf' }),
          url = window.URL.createObjectURL(blob)
        const iframe = document.getElementById('viewer')
        iframe.setAttribute('src', url)
      }

      // setDocUrl(url)
    }
    hideLoader()
  }

  const handleDocChange = e => {
    // console.log('src:' + URL.createObjectURL(e.target.files[0]))
    if (e.target.files && e.target.files[0]) {
      setDocUrl(URL.createObjectURL(e.target.files[0]))
      setDocFile(e.target.files[0])
    }
  }

  function onErrorDoc () {
    // console.log('onError Doc')
    setDocUrl(window.appURL + '/no-image.png')
  }

  function saveDoc () {
    var formData = new FormData()
    formData.append('docId', docId)
    formData.append('title', title)
    formData.append('pdf', docFile)
    showLoader2()
    saveDocSvc(formData, cbSaveDocResponse)
  }
  function cbSaveDocResponse (data) {
    if (data.status === 'Success') {
      hideLoader2()
      toast.success(data.message)
      getDocList()
      setBlankDocDtls()
      setDoc(data.docId)
    } else {
      toast.error(data.SvcMsg)
    }
    hideLoader1()
  }
  function deleteDoc (pDocId) {
    showLoader1()
    deleteDocSvc(pDocId, cbDeleteDocResponse)
  }

  function cbDeleteDocResponse (data) {
    hideLoader()
    // console.log(data)
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getDocList()
      setBlankDocDtls()
    } else {
      toast.error(data.SvcMsg)
    }
  }

  function handleGetDocResponse (imageUrl) {
    var img = document.querySelector('#doc_file')
    img.src = imageUrl
  }

  async function handleLstStateResponse (data) {
    // console.log(data)
    var obj = data
    getGeneralInfoSvc(cbGeneralInfoResponse)
    if (obj.status === 'Success') {
      setLstState(obj.lstState)
    } else if (obj.status === 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(obj.message)
      }
    } else {
      toast.error('Service failed' + obj)
    }
  }

  const cbGeneralInfoResponse = obj => {
    hideLoader1()
    if (obj.status === 'Success') {
      setSchool(obj.genInfo.schNm)
      setAffiliationNo(obj.genInfo.affiliationNo)
      setSchoolCode(obj.genInfo.schCode)
      setAddress1(obj.genInfo.address1)
      setPin(obj.genInfo.pin)
      setContactNo(obj.genInfo.contactInfo)
      setEmail(obj.genInfo.schEmail)
      setPrincipalNm(obj.genInfo.principalNm)
      setQualification(obj.genInfo.principalQual)
      var pStateId = obj.genInfo.stateId
      var pCityId = obj.genInfo.cityId
      var pLocalityId = obj.genInfo.localityId
      setStateId(pStateId)
      getLstCity(pStateId, handleLstCityResponse, pCityId)
      getLstLocality(pCityId, handleLocalityResponse, pLocalityId)
    } else if (obj.status === 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(obj.message)
      }
    } else {
      toast.error('Service failed' + obj)
    }
  }

  const changeLocality = e => {
    setLocalityId(e.target.value)
  }

  const changeCity = e => {
    var pCityId = e.target.value
    setCityId(pCityId)
    getLstLocality(pCityId, handleLocalityResponse, 0)
  }
  const changeState = e => {
    var pStateId = e.target.value
    setStateId(pStateId)
    getLstCity(pStateId, handleLstCityResponse, 0)
  }

  function handleLstCityResponse (data, pCityId) {
    var obj = data
    hideLoader()
    if (obj.status === 'Success') {
      setLstCity(obj.lstCity)
      setCityId(pCityId)
    } else if (obj.status === 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(obj.message)
      }
    } else {
      toast.error('Service failed' + obj)
    }
  }
  function handleLocalityResponse (data, pLocalityId) {
    var obj = data
    if (data.status === 'Success') {
      setLstLocality(obj.lstLocality)
      setLocalityId(pLocalityId)
    } else if (obj.status === 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(obj.message)
      }
    } else {
      toast.error('Service failed' + obj)
    }
  }

  function getWingId (wingId) {
    // console.log('wingId' + wingId)
  }

  function handleSaveGeneralInfoResponse (obj) {
    hideLoader()
    if (obj.status === 'Success') {
      toast.success(obj.message)
    } else if (obj.status === 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(obj.message)
      }
    } else {
      toast.error('Service failed' + obj)
    }
  }

  function saveGeneralInfo (e) {
    e.preventDefault()

    var addr = {
      schNm: school,
      affiliationNo: affiliationNo,
      schCode: schoolCode,
      stateId: stateId,
      cityId: cityId,
      localityId: localityId,
      address1: address1,
      pin: pin,
      principalNm: principalNm,
      principalQual: qualification,
      schEmail: email,
      contactInfo: contactNo
    }
    showLoader()
    saveGeneralInfoSvc(addr, handleSaveGeneralInfoResponse)
  }

  function showLoader () {
    setState({ ...state, loader: 'block', opacity: 0.5 })
  }
  function hideLoader () {
    setState({ ...state, loader: 'none', opacity: 1 })
  }

  function showLoader1 () {
    setState({ ...state, loader1: 'block', opacity: 0.5 })
  }
  function hideLoader1 () {
    setState({ ...state, loader1: 'none', opacity: 1 })
  }
  function showLoader2 () {
    setState({ ...state, loader2: 'block', opacity: 0.5 })
  }
  function hideLoader2 () {
    setState({ ...state, loader2: 'none', opacity: 1 })
  }
  const colgrp = (
    <colgroup>
      <col width='25%'></col>
      <col width='10%'></col>
    </colgroup>
  )
  return (
    <div style={{ height: '100%' }}>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />

      <div className='mainContainer' style={{ opacity: state.opacity }}>
        <div className='col-sm-11 col-centered page' style={{ marginTop: 25 }}>
          <div className='pcss3t pcss3t-effect-scale pcss3t-theme-1'>
            <input
              type='radio'
              name='pcss3t'
              defaultChecked={true}
              id='tab1'
              className='tab-content-first'
              onClick={() => getGeneralInfo()}
            />
            <label
              htmlFor='tab1'
              title='GeneralInfo'
              onClick={() => getGeneralInfo()}
            >
              <i className='icon-globe'></i>General Info
            </label>

            <input
              type='radio'
              name='pcss3t'
              id='tab2'
              className='tab-content-2'
              onClick={() => getDocList()}
            />
            <label htmlFor='tab2' title='Docs' onClick={() => getDocList()}>
              <i className='icon-file'></i>Docs
            </label>
            <ul>
              <li
                className='tab-content tab-content-first typography'
                style={{
                  pointerEvents: state.loader === 'none' ? 'auto' : 'none'
                }}
              >
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 10000,
                    width: 100,
                    height: 100,
                    marginLeft: -50,
                    marginTop: -50,
                    left: '50%',
                    top: '50%',
                    display: state.loader
                  }}
                >
                  <img
                    className='loderImg'
                    src={require('common/images/loader.gif')}
                    alt='Loader...'
                  />
                </div>
                <div className='col-sm-12 col-centered'>
                  <form
                    className='form form-vertical col-sm-12'
                    style={{
                      margin: '2%',
                      border: '1px solid #ddd',
                      borderRadius: 6
                    }}
                  >
                    <div className='col-sm-12' style={{ padding: 20 }}>
                      <div className='row'>
                        <label
                          className='control-label col-sm-3'
                          htmlFor='name'
                        >
                          School Name
                        </label>
                        <div className='form-group col-sm-5'>
                          <input
                            className='form-control'
                            name='school'
                            defaultValue={school}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <label
                          className='control-label col-sm-3'
                          htmlFor='affiliationNo'
                        >
                          Affiliation No.
                        </label>
                        <div className='form-group col-sm-2'>
                          <input
                            className='form-control'
                            name='affiliationNo'
                            defaultValue={affiliationNo}
                            onChange={e => setAffiliationNo(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <label
                          className='control-label col-sm-3'
                          htmlFor='schoolCode'
                        >
                          School Code
                        </label>
                        <div className='form-group col-sm-2'>
                          <input
                            className='form-control'
                            name='schoolCode'
                            defaultValue={schoolCode}
                            onChange={e => setSchoolCode(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className='row'>
                        <label
                          className='control-label col-sm-3'
                          htmlFor='state'
                        >
                          State
                        </label>
                        <div className='form-group col-sm-3'>
                          <select
                            value={stateId}
                            onChange={e => changeState(e)}
                            className='form-control'
                            style={{ width: '100%' }}
                            id='stateCmb'
                            name='stateId'
                          >
                            <option>State</option>

                            {lstState &&
                              lstState.map(function (obj, i) {
                                return (
                                  <option key={i} value={obj.stateId}>
                                    {obj.state}
                                  </option>
                                )
                              })}
                          </select>
                        </div>
                      </div>
                      <div className='row'>
                        <label
                          className='control-label col-sm-3'
                          htmlFor='city'
                        >
                          City
                        </label>
                        <div className='form-group col-sm-3'>
                          <select
                            value={cityId}
                            onChange={e => changeCity(e)}
                            className='form-control'
                            style={{ width: '100%' }}
                            id='cityCmb1'
                            name='cityId'
                          >
                            <option value='0'>City</option>
                            {lstCity &&
                              lstCity.map(function (obj, i) {
                                return (
                                  <option key={i} value={obj.cityId}>
                                    {obj.city}
                                  </option>
                                )
                              })}
                          </select>
                        </div>
                      </div>
                      <div className='row'>
                        <label
                          className='control-label col-sm-3'
                          htmlFor='locality'
                        >
                          Locality
                        </label>
                        <div className='form-group col-sm-3'>
                          <select
                            value={localityId}
                            onChange={e => changeLocality(e)}
                            className='form-control'
                            style={{ width: '100%' }}
                            id='localityCmb'
                            name='localityId'
                          >
                            <option value='0'>Locality</option>
                            {lstLocality &&
                              lstLocality.map(function (obj, i) {
                                return (
                                  <option key={i} value={obj.localityId}>
                                    {obj.locality}
                                  </option>
                                )
                              })}
                          </select>
                        </div>
                      </div>
                      <div className='row'>
                        <label className='control-label col-sm-3' htmlFor='pin'>
                          Pin
                        </label>
                        <div className='form-group col-sm-2'>
                          <input
                            className='form-control'
                            name='pin'
                            defaultValue={pin}
                            onChange={e => setPin(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <label
                          className='control-label col-sm-3'
                          htmlFor='address1'
                        >
                          Address
                        </label>
                        <div className='form-group col-sm-8'>
                          <input
                            className='form-control'
                            name='address1'
                            defaultValue={address1}
                            onChange={e => setAddress1(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <label
                          className='control-label col-sm-3'
                          htmlFor='principalNm'
                        >
                          Principal Name
                        </label>
                        <div className='form-group col-sm-4'>
                          <input
                            className='form-control'
                            name='principalNm'
                            defaultValue={principalNm}
                            onChange={e => setPrincipalNm(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <label
                          className='control-label col-sm-3'
                          htmlFor='qualification'
                        >
                          Qualification
                        </label>
                        <div className='form-group col-sm-3'>
                          <input
                            className='form-control'
                            name='qualification'
                            defaultValue={qualification}
                            onChange={e => setQualification(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <label
                          className='control-label col-sm-3'
                          htmlFor='email'
                        >
                          Email
                        </label>
                        <div className='form-group col-sm-5'>
                          <input
                            className='form-control'
                            name='email'
                            defaultValue={email}
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className='row'>
                        <label
                          className='control-label col-sm-3'
                          htmlFor='contactNo'
                        >
                          Contact No.
                        </label>
                        <div className='form-group col-sm-3'>
                          <input
                            className='form-control'
                            name='contactNo'
                            defaultValue={contactNo}
                            onChange={e => setContactNo(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='form-group col-sm-11'>
                          <button
                            className='pull-right btn btn-info'
                            title='Save'
                            onClick={e => saveGeneralInfo(e)}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </li>

              <li
                className='tab-content tab-content-2 typography'
                style={{
                  pointerEvents: state.loader1 === 'none' ? 'auto' : 'none'
                }}
              >
                <div className='col-sm-12'>
                  <div className='col-sm-12 padding-remove'>
                    <div className='col-sm-7 padding-remove'>
                      <div
                        style={{
                          position: 'absolute',
                          zIndex: 10000,
                          width: 100,
                          height: 100,
                          marginLeft: -50,
                          marginTop: -50,
                          left: '50%',
                          top: '50%',
                          display: state.loader1
                        }}
                      >
                        <img
                          className='loderImg'
                          src={require('common/images/loader.gif')}
                          alt='Loader...'
                        />
                      </div>
                      <table className='tableLayout1'>
                        {colgrp}
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                      </table>
                      <div
                        className=''
                        style={{ height: '300px', overflowY: 'scroll' }}
                      >
                        <table className='tableLayout'>
                          {colgrp}
                          <tbody>
                            {lstDoc &&
                              lstDoc.length > 0 &&
                              lstDoc.map((docObj, idx) => {
                                return (
                                  <tr
                                    key={idx}
                                    onClick={() => setDocDtls(docObj, false)}
                                    className={
                                      docObj.docId == docId ? 'selected' : ''
                                    }
                                  >
                                    <td className='text-left'>
                                      {docObj.title}
                                    </td>
                                    <td className='text-center'>
                                      <span
                                        className='glyphicon glyphicon-remove'
                                        onClick={() => deleteDoc(docObj.docId)}
                                      ></span>
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <span
                      className='col-sm-1 glyphicon glyphicon-plus-sign addicon padding-remove'
                      style={{ fontSize: '30px', width: '4%' }}
                      onClick={e => {
                        setBlankDocDtls()
                      }}
                    ></span>
                    <div className='col-sm-4' style={{ width: '37%' }}>
                      <form
                        className='form form-horizontal'
                        style={{
                          width: '100%',
                          margin: '10px auto',
                          padding: 10,
                          background: '#fff',
                          border: '1px solid #ccc',
                          borderRadius: 10,
                          pointerEvents:
                            state.loader2 === 'none' ? 'auto' : 'none'
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: 10000,
                            width: 100,
                            height: 100,
                            marginLeft: -50,
                            marginTop: -50,
                            left: '50%',
                            top: '50%',
                            display: state.loader2
                          }}
                        >
                          <img
                            className='loderImg'
                            src={require('common/images/loader.gif')}
                            alt='Loader...'
                          />
                        </div>

                        <div className='row'>
                          <label className='control-label col-md-3 text-left'>
                            Title
                          </label>
                          <div className='form-group col-md-9'>
                            <input
                              type='text'
                              value={title}
                              className='form-control'
                              onChange={e => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-sm-offset-3 col-sm-6 padding-remove'>
                            <input
                              key={'k_' + docId}
                              type='file'
                              id={'doc_file'}
                              name='img'
                              onChange={e => handleDocChange(e)}
                              accept='application/pdf'
                              style={{ display: 'block' }}
                              disabled={docId}
                            />
                          </div>
                          <div className='col-sm-12 form-group'>
                            <div
                              className='col-sm-offset-3 col-sm-9 padding-remove'
                              style={{
                                display: 'flex',
                                justifyContent: 'right'
                              }}
                            >
                              <button
                                type='button'
                                className='btn btn-warning pull-right'
                                onClick={e => saveDoc()}
                                style={{ marginRight: 10 }}
                                disabled={docId}
                              >
                                Save
                              </button>
                              <button
                                type='button'
                                className='btn btn-info pull-right'
                                title='Save'
                                onClick={e => setDoc(docId)}
                              >
                                View Document
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          className='TestSlider'
          style={{
            height: '96vh',
            display: openViewer ? 'block' : 'none',
            width: '90%'
          }}
        >
          <button
            type='button'
            className='btn btn-info text-center '
            id='closeSlider'
            style={{ right: '90%', opacity: 1 }}
            onClick={e => setOpenViewer(false)}
          >
            <span
              id='close'
              title='Close Slider'
              className='glyphicon glyphicon-remove'
            ></span>
          </button>
          <iframe
            src={require('common/images/loader.gif')}
            title='Document'
            id='viewer'
            style={{ width: '100%', height: '100%' }}
            onError={() => onErrorDoc()}
          />
        </div>
      </div>
    </div>
  )
}
