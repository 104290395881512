import axios from 'axios'
import lstModules from 'common/json/lstModules.json'

const httpURL = lstModules['main'].url + ':' + lstModules['main'].port
var tokenid = localStorage.getItem('tokenid')
var screenId = 8

function getClassListSvc (callback) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/cls/labels/list',
    method: 'POST',
    params: { screenId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.lstCls = response.data.lstClsLabel
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        // console.log(obj.lstCls)
        callback(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback(obj)
    })
}
function saveLstClsLabelSvc (pLstTmpCls, callback) {
  var formData = new FormData()
  formData.append('cls', JSON.stringify({ lstCls: pLstTmpCls }))
  axios({
    url: httpURL + '/sms/cls/labels/save',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // console.log(Response.data)
      var ourData = Response.data

      callback(ourData)
    })
    .catch(error => {
      // console.log('error')
    })
}
export { getClassListSvc, saveLstClsLabelSvc }
