import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css'
import SAHeader from 'sa/header/js/saHeader';
import { getLstSchoolSvc } from './SettlementCtrlSvc';

const SettlementCtrl = () => {
  const [loader, setLoader] = useState("none");

  const [schoolId, setSchoolId] = useState(0);
  const [lstSchool, setLstSchool] = useState([])

  useEffect(() => {
    getLstSchoolSvc(cbLstSchoolResponse)

  }, [])

  function cbLstSchoolResponse(data) {
    if (data.status === "Success") {
      setLstSchool(data.lstSchool)
    } else {
      // console.log("entered in else condition in cbLstSchoolResponse");
      if (data.message === "You are not logged in") {
        localStorage.setItem("isRememberMeSelected", false);
        setTimeout(function () {
          window.location.href = "/sa";
        }, 2000);
        return false;
      } else if (data.message === "Your Session is expired, login again") {
        toast.error(data.SvcMsg);
        // console.log("session expired");
        localStorage.setItem("isRememberMeSelected", false);
        setTimeout(function () {
          window.location.href = "/sa";
        }, 2000);
        return false;
      } else {
        toast.error(data.SvcMsg);
      }
    }
  }
  const colgrp = <colgroup>
    <col width="10%"></col>
    <col width="30%"></col>
    <col width="10%"></col>
    <col width="10%"></col>
    <col width="10%"></col>
    <col width="20%"></col>
    <col width="10%"></col>
  </colgroup>


  function openSettlementTab(pSchoolId, pSchool, pCount, pCity, pLocality) {
    // await localStorage.setItem("schoolId", pSchoolId)
    // await localStorage.setItem("school", pSchool);
    // await localStorage.setItem("count", pCount);
    // await localStorage.setItem("city", pCity);
    // await localStorage.setItem("locality", pLocality);
    const settlementTabUrl = "/sa/settlementTab?schoolId=" + pSchoolId + "&school=" + pSchool + "&count=" +pCount+ "&city=" +pCity+ "&locality=" + pLocality;
    window.open(settlementTabUrl, '_blank');
  }
  const getWingId = (wingId) => {
    // console.log("wingId" + wingId);
  }

  return (
    <div className='MainContainer' style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
      <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }} >
        <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
      </div >
      <SAHeader wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className="col-sm-9 col-centered" style={{ marginTop: '2%' }}>
        <div className="col-sm-11" style={{ height: '100%' }}>

          <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <table className='tableLayout1'>

              {colgrp}
              <thead>
                <tr>
                  <th>School Id</th>
                  <th>School</th>
                  <th>Locality</th>
                  <th>City</th>
                  <th>Count</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
            </table>
            <div className='tablebodyWrapper'>
              <table className='tableLayout'>
                {colgrp}
                <tbody>
                  {lstSchool && lstSchool.map((obj, idx) => {
                    return <tr key={idx} id={obj.schoolId}
                      onClick={(e) => setSchoolId(obj.schoolId)} className={obj.schoolId === schoolId ? "selected" : ""}>
                      <td>{obj.schoolId}</td>
                      <td>{obj.school}</td>
                      <td className="text-left">{obj.locality}</td>
                      <td className="text-left">{obj.city}</td>
                      <td className="text-right">{obj.count}</td>
                      <td className="text-right">{obj.totalAmount.toLocaleString("en-IN")}</td>
                      <td className="text-center" onClick={() => openSettlementTab(obj.schoolId, obj.school, obj.count, obj.city, obj.locality)}>
                        <span className="glyphicon glyphicon-edit">
                        </span>

                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
          </div >
        </div>
      </div >
    </div>

  )
}

export default SettlementCtrl