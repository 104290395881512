import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from 'react-toastify';

import { saveChapterSvc } from "./examSyllabusSvc";


const SubjectTable = (props) => {

    const { examId, syllabusId, sectionId, subjectId, subject, lstChapter, setLoader, setOpacity } = props;

    const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
    const [lstChapters, setLstChapters] = useState([]);
    const [lstChapterId, setLstChapterId] = useState([]);


    useEffect(() => {
        // console.log("SubjectTable called, syllabusId, examId, subjectId, subject",syllabusId, examId, subjectId, subject);
        getLstChapters();
    }, [subjectId, syllabusId, examId]);


    const getLstChapters = () => {
        let tempLstChapter = [];
        let chapterIdSet = new Set();

        if (lstChapter && lstChapter.length) {
            // First loop: Add chapters that match examId or have examId of 0
            for (let i = 0; i < lstChapter.length; i++) {
                if (lstChapter[i].examId === examId || lstChapter[i].examId === 0) {
                    tempLstChapter.push(lstChapter[i]);
                    chapterIdSet.add(lstChapter[i].chapterId);
                }
            }

            // Second loop: Add chapters that are not already in tempLstChapter
            for (let i = 0; i < lstChapter.length; i++) {
                if (!chapterIdSet.has(lstChapter[i].chapterId)) {
                    tempLstChapter.push(lstChapter[i]);
                    chapterIdSet.add(lstChapter[i].chapterId);
                }
            }

            // console.log("tempLstChapter in getLstChapters in ", subject, tempLstChapter);
            setLstChapters(tempLstChapter);
        }
        getLstChapterId(tempLstChapter);
    }



    const getLstChapterId = (pLstChapter) => {
        let temppLstChapter = [];
        // console.log("pLstChapter", pLstChapter);
        // console.log("exexamId", examId);
        if (pLstChapter && pLstChapter.length) {
            for (let i = 0; i < pLstChapter.length; i++) {
                if (pLstChapter[i].examId === examId)
                    temppLstChapter.push(pLstChapter[i].chapterId);
            }
            setLstChapterId(temppLstChapter);
        }
        // console.log("temppLstChapter", temppLstChapter);
    }

    const handleSelectChapter = (pChapterId, checked) => {
        // console.log("handleSelectChapter called, pChapterId", pChapterId, ", checked", checked);
        setLstChapterId((prevLstChapterId) => {
            if (prevLstChapterId.some((chapterId) => chapterId === pChapterId)) {
                return prevLstChapterId.filter((chapterId) => chapterId !== pChapterId);
            } else {
                return [...prevLstChapterId, pChapterId];
            }
        });
    }

    const saveSubjectSyllabus = () => {
        // console.log("saveSubjectSyllabus called");
        const chapterExam = {
            "syllabusId": syllabusId,
            "examId": examId,
            "subjectId": subjectId,
            "lstChapterId": lstChapterId
        }
        // console.log("chapterExam", chapterExam);
        setLoader((prevLoader) => prevLoader + 1);
        setOpacity(0.5);
        saveChapterSvc(chapterExam, saveChapterResponse);
    }



    const saveChapterResponse = (data) => {
        // console.log("saveChapterResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader((prevLoader) => prevLoader - 1);
        setOpacity(1);
    }

    // const isSelected = (pchapterId) => {
    //     // console.log("isSelected called for pchapterid", pchapterId);
    //     // console.log("lstChapterId", lstChapterId);
    //     // console.log("lstChapterId.includes(pchapterId)",lstChapterId.includes(pchapterId));
    //     return lstChapterId.includes(pchapterId);
    // }

    return (
        <div className="col-sm-12 syllabus-chapter-table padding-remove">
            <table className="tableLayout1" >
                <thead>
                    <tr>
                        <th style={{ width: '75%' }}>
                            {subject}
                        </th>
                        <th style={{ width: "25%" }}>
                            Select
                        </th>
                    </tr>
                </thead>
            </table>
            <div className="tableDiv3 ">
                <table id="exam-syllabus-chapter" className="tableLayout">
                    <tbody id="exam-syllabus-chapter" >
                        {lstChapters && lstChapters.length ?
                            lstChapters.map((chapter, i) => (
                                // (chapter.examId === "examId" || chapter.examId === 0) ? (
                                <tr key={i}
                                    style={{ fontWeight: chapter.examId === "examId" ? "bold" : 400, backgroundColor: chapter.examId !== "examId" && chapter.examId == !0 ? "grey" : "" }}
                                >
                                    <td style={{ width: "75%" }} className="text-left" >{chapter.chapter}</td>
                                    <td style={{ width: "25%" }} className="text-center">
                                        <input type="checkbox"
                                            className="form-check-input"
                                            checked={lstChapterId.includes(chapter.chapterId)}
                                            onChange={(e) => handleSelectChapter(chapter.chapterId, e.target.checked)}
                                        />
                                    </td>
                                </tr>
                                // ) : null
                            )) : null
                        }
                    </tbody>
                </table>
            </div>
            <div className="col-sm-12 text-center padding-remove">
                <button
                    type="button"
                    style={{
                        width: "195px",
                        backgroundColor: "#48a2bc",
                        padding: "3px 12px",
                        fontSize: 16,
                    }}
                    title="Save"
                    onClick={() => saveSubjectSyllabus()}
                    className="btn btn-info"
                >
                    Save
                </button>
            </div>
        </div>
    );
}

export default SubjectTable; 