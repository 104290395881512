import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../css/studentTest.css';
import '../css/question.css';

const Options = (props) => {

    const { lstOptions } = props;


    useEffect(() => {
        // console.log("Options called");
        // console.log("lstOptions", lstOptions);
    }, [lstOptions]);

    return (
        <div className="col-sm-12 col-xs-12 padding-remove">
            <div className="option-section">
                {lstOptions && lstOptions.length > 0 ?
                    lstOptions.map((option) => (
                        <div key={option.optionKey} className="option-box " >
                            <div className="col-sm-1 col-xs-1 padding-remove" >
                                <div className="option-name" >
                                    {option.optionKey + ")"}
                                </div>
                            </div>
                            <div className="col-sm-11 col-xs-11 padding-remove">
                                <div className="option-content">
                                    {
                                        !!option && !!option.option ?
                                            <div className="option-text" >
                                                {option.option}
                                            </div>
                                            : null
                                    }
                                    {
                                        !!option && !!option.optionImage ?
                                            <div className="option-image">
                                                <div className="option-img">
                                                    <img src={option.optionImage} alt={""} />
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    )) : null
                }
            </div>
        </div>

    );
}

export default Options;