import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['performance'].url + ':' + lstModules['performance'].port;

var tokenid = localStorage.getItem("tokenid");
var screenId = 8;

function saveExamSvc(examObj, handleSaveExamResponse) {

    var status = 'Success'
    var obj = { message: '', status: status };

    axios({
        url: httpURL + '/sms/perf/performance/exam/save',
        method: "POST",
        params: { 'exam': JSON.stringify(examObj), screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log("finished service call");
        if (response.data.SvcStatus === 'Success') {
            handleSaveExamResponse(response.data, examObj)
        } else {
            handleSaveExamResponse(response.data, examObj)
        }
    }).catch((error) => {
        // console.log("finished service call: but failure");
        obj.status = 'Exception';
        obj.SvcMsg = error;
        return obj;
    });
    // console.log("may be the service call is not finished");
}

function deleteExamSvc(pExamId, handleExamResponse) {

    var obj = { status: '', message: '' }
    axios({
        url: httpURL + '/sms/perf/performance/exam/delete',
        method: "POST",
        params: { 'examId': pExamId, screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg
        handleExamResponse(obj, pExamId)

    }).catch((error) => {
        // console.log("finished service call: but failure");
        obj.status = 'Exception';
        obj.message = error;
        return handleExamResponse(obj, pExamId);
    });
    // console.log("may be the service call is not finished");
}
function getLstTypeSvc(cb, pTypeId) {
    var obj = { status: "", message: "", lstType: [] }
    axios({
        url: httpURL + '/sms/performance/mst/exam_type/list',
        method: "POST",
        params: {},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success")
            obj.lstType = response.data.lstType;
        cb(obj, pTypeId)
    }).catch((error) => {
        obj.message = error;
        cb(obj);
    })
}
function getLstReportCardSvc(cb, pReportCardId) {
    var obj = { status: "", message: "", lstType: [] }
    axios({
        url: httpURL + '/sms/performance/mst/report_card/list',
        method: "POST",
        params: {},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success")
            obj.lstReportCard = response.data.lstRC;
        cb(obj, pReportCardId)
    }).catch((error) => {
        obj.message = error;
        cb(obj);
    })
}
export { saveExamSvc, deleteExamSvc, getLstTypeSvc, getLstReportCardSvc }