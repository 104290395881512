import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "common/css/master.css";
import "../css/transport.css";
import Header from "common/js/header/header.js";
import RouteTable from "./routeTable";
import RouteSlider from "./routeSlider";
import RouteStaffSlider from "./routeStaffSlider";
import ChannelPopup from "./channelPopup";
import RouteMapView from "./routeMapView";
import { getRouteListSvc } from "./routeSvc";
import RouteArrDepSlider from "./routeArrDepSlider";

function RouteCtrl() {
  const [loader, setLoader] = useState("none");
  const [flgSlider, setFlgSlider] = useState(false);
  const [flgStaff, setFlgStaff] = useState(false);
  const [flgMap, setFlgMap] = useState(false);
  const [flgArrDep, setFlgArrDep] = useState(false);
  const [randNo, setRandNo] = useState(0);
  const [routeObj, setRouteObj] = useState({});
  const [routeId, setRouteId] = useState(0);
  const [lstRoute, setLstRoute] = useState([]);
  const [flgPopup, setFlgPopup] = useState(false);

  useEffect(() => {
    setLoader("block");
    getRouteListSvc(cbRouteList);
  }, [randNo]);
  
  function cbRouteList(data) {
    if (data.SvcStatus === "Success") {
      setLstRoute(data.lstRoute);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function generateRandomNo() {
    var lRandNo = Math.floor(Math.random() * 999999);
    // console.log(lRandNo);
    setRandNo(lRandNo);
  }
  function openSlider() {
    setFlgSlider(true);
  }

  function addNewRoute() {
    setRouteObj(prevState => ({
      ...prevState,
      routeNo: 0, // Reset routeNo to 0
      routeDesc: '' // Reset routeDesc to empty string
    }));
    openSlider(); // Call the function to open the slider
  }
  const closePopup = () => {
    document.querySelector("body").classList.remove("showPopup");
  };

  function openStaffSlider(param) {
    setRouteId(param);
    setFlgStaff(true);
  }
  function openArrDepSlider(param) {
    setRouteId(param);
    setFlgArrDep(true);
  }
  function openMapSlider(param) {
    setRouteId(param);
    setFlgMap(true);
  }
  function closeSlider() {
    setFlgSlider(false);
    setFlgStaff(false);
    setFlgArrDep(false);
    setFlgMap(false);
    setRouteObj({});
    setRouteId(0);
    generateRandomNo();
  }
  function editRoute(pRouteId) {
    for (var i = 0; i < lstRoute.length; i++) {
      if (lstRoute[i].routeId === pRouteId) {
        setRouteObj(lstRoute[i]);
        setRouteId(lstRoute[i].routeId);
      }
    }
    openSlider();
  }
  const getWingId = (wingId) => {
    // console.log("wingId" + wingId);
  };
  return (
    <div
      className="MainContainer"
      style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className="TestTblWrapper1">
        <div className="TestTbl">
          <RouteTable
            key={randNo}
            setRouteId={setRouteId}
            openSlider={openSlider}
            closeSlider={closeSlider}
            generateRandomNo={generateRandomNo}
            editRoute={editRoute}
            lstRoute={lstRoute}
            openStaffSlider={openStaffSlider}
            openArrDepSlider={openArrDepSlider}
            openMapSlider={openMapSlider}
            setFlgPopup={setFlgPopup}
          />
        </div>
        <div className="" onClick={(e) => addNewRoute()}>
          <span
            id="addReadyId"
            title="Add"
            className="addIcon glyphicon glyphicon-plus-sign"
          ></span>
        </div>
        <div
          className="TestSlider"
          style={{ display: flgSlider ? "block" : "none", width: "50%" }}
        >
          <RouteSlider
            key={routeId}
            routeId={routeId}
            openSlider={openSlider}
            closeSlider={closeSlider}
            setRandNo={setRandNo}
            routeObj={routeObj}
          />
        </div>
        <div
          className="TestSlider"
          style={{ display: flgStaff ? "block" : "none" }}
        >
          <RouteStaffSlider
            key={routeId}
            routeId={routeId}
            closeSlider={closeSlider}
            flgStaff={flgStaff}
          />
        </div>
        <div
          className="TestSlider"
          style={{ display: flgMap ? "block" : "none" }}
        >
           {flgMap && <RouteMapView
            key={routeId}
            routeId={routeId}
            closeSlider={closeSlider}
            flgMap={flgMap}
          />}
        </div>
        <div
          className="TestSlider"
          style={{ display: flgArrDep ? "block" : "none" }}
        >
          <RouteArrDepSlider
            key={routeId}
            routeId={routeId}
            closeSlider={closeSlider}
            flgArrDep={flgArrDep}
          />
        </div>
      </div>
      <div className="popup">
        {flgPopup && (
          <ChannelPopup
            key={routeId + "_" + randNo}
            closePopup={closePopup}
            routeId={routeId}
          />
        )}
      </div>
    </div>
  );
}
export default RouteCtrl;
