import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['ptm'].url + ':' + lstModules['ptm'].port
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port
function getPtmListSvc (callBack) {
  axios({
    url: httpURLCommon + '/sms/ptm/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // console.log(Response.data)
      var ourData = Response.data

      callBack(ourData)
    })
    .catch(error => {
      // console.log('error')
    })
}

function cancelPtmSvc (ptmId, callBack) {
  axios({
    url: httpURL + '/sms/ptm/cancel',
    method: 'POST',
    params: { ptmId: ptmId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // console.log(Response.data)
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // console.log('error')
    })
}
function deletePtmSvc (ptmId, callBack) {
  axios({
    url: httpURL + '/sms/ptm/delete',
    method: 'POST',
    params: { ptmId: ptmId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // console.log(Response.data)
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // console.log('error')
    })
}
export { getPtmListSvc, cancelPtmSvc, deletePtmSvc }
