import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import DatePicker from 'react-datepicker'
import { useLocation } from 'react-router-dom'
import Header from 'common/js/header/header.js'
import {
  getSubjectListSvc,
  getTchListSvc,
  getStudentFeedbackSvc,
  saveStudentFeedbackSvc
} from './StudentFeedbackSvc'

const StudentFeedback = () => {
  const search = useLocation().search
  const [ptmStudent, setPtmStudent] = useState(
    new URLSearchParams(search).get('name')
  )
  const [ptmStudentId, setPtmStudentId] = useState(
    new URLSearchParams(search).get('ptmStudentId')
  )
  const [sectionId, setSectionId] = useState(
    new URLSearchParams(search).get('sectionId')
  )
  const [loader, setLoader] = useState('none')
  const [lstSubject, setLstSubject] = useState([])
  const [lstTeacher, setLstTeacher] = useState([])
  const [ratingStudies, setRatingStudies] = useState(0)
  const [ratingHygiene, setRatingHygiene] = useState(0)
  const [ratingActivities, setRatingActivities] = useState(0)
  const [ratingInfra, setRatingInfra] = useState(0)
  const [ratingSchool, setRatingSchool] = useState(0)

  const [studies, setStudies] = useState('')
  const [hygiene, setHygiene] = useState('')
  const [activities, setActivities] = useState('')
  const [infra, setInfra] = useState('')
  const [school, setSchool] = useState('')

  const [sub1, setSub1] = useState('')
  const [sub2, setSub2] = useState('')
  const [sub3, setSub3] = useState('')

  const [sub1Id, setSub1Id] = useState(0)
  const [sub2Id, setSub2Id] = useState(0)
  const [sub3Id, setSub3Id] = useState(0)

  const [tch1Id, setTch1Id] = useState(0)
  const [tch2Id, setTch2Id] = useState(0)

  const [tch1, setTch1] = useState('')
  const [tch2, setTch2] = useState('')

  useEffect(() => {
    getTchListSvc(sectionId, cbTchListResponse)
    getSubjectListSvc(sectionId, cbSubjectListResponse)
    setLoader('block')
    getStudentFeedbackSvc(ptmStudentId, cbStudentFeedbackResponse)
  }, [])

  function cbTchListResponse (data) {
    setLstTeacher(data.lstTeacher)
  }
  function cbSubjectListResponse (data) {
    setLstSubject(data.lstSubject)
  }

  function cbStudentFeedbackResponse (data) {
    setLoader('none')
    // console.log(data)
    setSub1Id(data.feedbackDtls.sub1)
    setSub2Id(data.feedbackDtls.sub2)
    setSub3Id(data.feedbackDtls.sub3)

    setTch1Id(data.feedbackDtls.tch1)
    setTch2Id(data.feedbackDtls.tch2)

    setRatingStudies(data.feedbackDtls.ratingStudies)
    setRatingHygiene(data.feedbackDtls.ratingHygiene)
    setRatingActivities(data.feedbackDtls.ratingActivities)
    setRatingInfra(data.feedbackDtls.ratingInfra)
    setRatingSchool(data.feedbackDtls.ratingSchool)

    setStudies(data.feedbackDtls.studies)
    setHygiene(data.feedbackDtls.hygiene)
    setActivities(data.feedbackDtls.activities)
    setInfra(data.feedbackDtls.infra)
    setSchool(data.feedbackDtls.school)
  }

  function saveStudentFeedback (e) {
    e.preventDefault()
    var feedback = {
      ptmStudentId: ptmStudentId,
      ratingStudies: ratingStudies,
      ratingHygiene: ratingHygiene,
      ratingActivities: ratingActivities,
      ratingInfra: ratingInfra,
      ratingSchool: ratingSchool,
      studies: studies,
      hygiene: hygiene,
      activities: activities,
      infra: infra,
      school: school,
      sub1Id: sub1Id,
      sub2Id: sub2Id,
      sub3Id: sub3Id,
      tch1Id: tch1Id,
      tch2Id: tch2Id
    }
    setLoader('block')
    saveStudentFeedbackSvc(feedback, cbResponse)
  }
  function cbResponse (data) {
    // console.log(data)
    setLoader('none')
    if (data.status === 'Success') {
      setLoader('block')
      getStudentFeedbackSvc(ptmStudentId, cbStudentFeedbackResponse)
      toast.success(data.message)
    } else {
      toast.error(data.message)
    }
  }
  return (
    <div style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}>
      <div
        style={{
          position: 'absolute',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: loader
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <Header />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className='col-sm-12' style={{ marginTop: '2%' }}>
        <form className='form form-horizontal'>
          <div className='col-sm-12'>
            <label className='control-label col-sm-4 text-left'>
              <b>Name:</b> {ptmStudent}
            </label>
          </div>

          <div className='col-sm-12'>
            <label className='control-label col-sm-4 text-left'>
              Are you satisified with the level of studies at the school?
            </label>
            <div className='col-sm-1 form-group' style={{ margin: 10 }}>
              <input
                type='number'
                className='form-control text-right'
                min={0}
                max={5}
                onChange={e => {
                  if (e.target.value > 5) return
                  setRatingStudies(e.target.value)
                }}
                placeholder='Max(5)'
                value={ratingStudies != 0 ? ratingStudies : "" }
              />
            </div>
            <div className='col-sm-6 form-group'>
              <textarea
                className='col-sm-12'
                style={{ border: '1px solid #ccc', borderRadius: 4 }}
                col-sm-12s='6'
                placeholder='-'
                defaultValue={studies != "-" ? studies : ""}
                onChange={e => setStudies(e.target.value)}
              />
            </div>
          </div>
          <div className='col-sm-12'>
            <label className='control-label col-sm-4 text-left'>
              Are you satisified with the level of hygiene at the school?
            </label>
            <div className='col-sm-1 form-group' style={{ margin: 10 }}>
              <input
                type='number'
                className='form-control text-right'
                min={0}
                max={5}
                onChange={e => {
                  if (e.target.value > 5) return
                  setRatingHygiene(e.target.value)
                }}
                placeholder='Max(5)'
                value={ratingHygiene != 0 ? ratingHygiene : "" }
              />
            </div>
            <div className='col-sm-6 form-group'>
              <textarea
                className='col-sm-12'
                style={{ border: '1px solid #ccc', borderRadius: 4 }}
                col-sm-12s='6'
                placeholder='-'
                onChange={e => setHygiene(e.target.value)}
                defaultValue={hygiene != "-" ? hygiene : ""}
              />
            </div>
          </div>
          <div className='col-sm-12'>
            <label className='control-label col-sm-4 text-left'>
              Are you satisified with the sports/extra-curricular activities?
            </label>
            <div className='col-sm-1 form-group' style={{ margin: 10 }}>
              <input
                type='number'
                className='form-control text-right'
                min={0}
                max={5}
                onChange={e => {
                  if (e.target.value > 5) return
                  setRatingActivities(e.target.value)
                }}
                value={ratingActivities != 0 ? ratingActivities : "" }
                placeholder='Max(5)'
              />
            </div>
            <div className='col-sm-6 form-group'>
              <textarea
                className='col-sm-12'
                style={{ border: '1px solid #ccc', borderRadius: 4 }}
                col-sm-12s='6'
                placeholder='-'
                onChange={e => setActivities(e.target.value)}
                defaultValue={activities != "-" ? activities : ""}
              />
            </div>
          </div>
          <div className='col-sm-12'>
            <label className='control-label col-sm-4 text-left'>
              Are you satisified with the infrastructure at the school?
            </label>
            <div className='col-sm-1 form-group' style={{ margin: 10 }}>
              <input
                type='number'
                className='form-control text-right'
                max={5}
                min={0}
                onChange={e => {
                  if (e.target.value > 5) return
                  setRatingInfra(e.target.value)
                }}
                placeholder='Max(5)'
                value={ratingInfra != 0 ? ratingInfra : "" }
              />
            </div>
            <div className='col-sm-6 form-group'>
              <textarea
                className='col-sm-12'
                style={{ border: '1px solid #ccc', borderRadius: 4 }}
                col-sm-12s='6'
                placeholder='-'
                onChange={e => setInfra(e.target.value)}
                defaultValue={infra != "-" ? infra : ""}
              />
            </div>
          </div>
          <div className='col-sm-12'>
            <label className='control-label col-sm-4 text-left'>
              How satisfied are you with the school?
            </label>
            <div className='col-sm-1 form-group' style={{ margin: 10 }}>
              <input
                type='number'
                className='form-control text-right'
                max={5}
                min={0}
                onChange={e => {
                  if (e.target.value > 5) return
                  setRatingSchool(e.target.value)
                }}
                placeholder='Max(5)'
                value={ratingSchool != 0 ? ratingSchool : "" }
              />
            </div>
            <div className='col-sm-6 form-group'>
              <textarea
                className='col-sm-12'
                col-sm-12s='6'
                style={{ border: '1px solid #ccc', borderRadius: 4 }}
                placeholder='-'
                onChange={e => setSchool(e.target.value)}
                defaultValue={school != "-" ? school : ""}
              />
            </div>
          </div>

          <div className='col-sm-12'>
            <hr style={{ margin: 10 }} />
            <label className='control-label col-sm-3 text-left'>
              3 Favourite Subjects(from syllabus)?
            </label>
            <div
              className='form-group col-sm-2'
              style={{ margin: 10, marginRight: 0 }}
            >
              <select
                name='sub1Id'
                id='sub1Cmb'
                className='form-control'
                onChange={e => setSub1Id(e.target.value)}
                value={sub1Id}
              >
                <option value='0'>Subject 1</option>
                {lstSubject &&
                  lstSubject.length > 0 &&
                  lstSubject.map(obj => (
                    <option
                      value={obj.subjectId}
                      key={obj.subjectId}
                      id={obj.subjectId}
                    >
                      {obj.subject}
                    </option>
                  ))}
              </select>
            </div>
            <div
              className='form-group col-sm-2'
              style={{ margin: 10, marginRight: 0 }}
            >
              <select
                name='sub2Id'
                id='sub2Cmb'
                className='form-control'
                onChange={e => setSub2Id(e.target.value)}
                value={sub2Id}
              >
                <option value='0'>Subject 2</option>
                {lstSubject &&
                  lstSubject.length > 0 &&
                  lstSubject.map(obj => (
                    <option
                      value={obj.subjectId}
                      key={obj.subjectId}
                      id={obj.subjectId}
                    >
                      {obj.subject}
                    </option>
                  ))}
              </select>
            </div>
            <div
              className='form-group col-sm-2'
              style={{ margin: 10, marginRight: 0 }}
            >
              <select
                name='sub3Id'
                id='sub3Cmb'
                className='form-control'
                onChange={e => setSub3Id(e.target.value)}
                value={sub3Id}
              >
                <option value='0'>Subject 3</option>
                {lstSubject &&
                  lstSubject.length > 0 &&
                  lstSubject.map(obj => (
                    <option
                      value={obj.subjectId}
                      key={obj.subjectId}
                      id={obj.subjectId}
                    >
                      {obj.subject}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className='col-sm-12'>
            <label className='control-label col-sm-3 text-left'>
              2 Favourite Teachers(from your class)?
            </label>

            <div
              className='form-group col-sm-2'
              style={{ margin: 10, marginRight: 0 }}
            >
              <select
                name='tch1Id'
                id='tch1Cmb'
                className='form-control'
                onChange={e => setTch1Id(e.target.value)}
                value={tch1Id}
              >
                <option value='0'>Teacher 1</option>
                {lstTeacher &&
                  lstTeacher.length > 0 &&
                  lstTeacher.map(obj => (
                    <option
                      value={obj.staffId}
                      key={obj.staffId}
                      id={obj.staffId}
                    >
                      {obj.staff}
                    </option>
                  ))}
              </select>
            </div>
            <div
              className='form-group col-sm-2'
              style={{ margin: 10, marginRight: 0 }}
            >
              <select
                name='tch2Id'
                id='tch2Cmb'
                className='form-control'
                onChange={e => setTch2Id(e.target.value)}
                value={tch2Id}
              >
                <option value='0'>Teacher 2</option>
                {lstTeacher &&
                  lstTeacher.length > 0 &&
                  lstTeacher.map(obj => (
                    <option
                      value={obj.staffId}
                      key={obj.staffId}
                      id={obj.staffId}
                    >
                      {obj.staff}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className='col-sm-11' style={{ bottom: '1%' }}>
            <button
              type='button'
              className='btn btn-info pull-right'
              onClick={e => saveStudentFeedback(e)}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default StudentFeedback
