import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Datepicker from "react-datepicker";
import { saveStaffSvc } from "./StaffSvc";
import { getStaffDtlsSvc } from "./StaffSvc";
import SliderType from "./SliderType";
export const StaffDtlsSlider = (props) => {
  const [loader, setLoader] = useState("none");
  // const [staffId, setStaffId] = useState(props.staffId);
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [doj, setDoj] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userId, setUserId] = useState(props.userId);
  const [gender, setGender] = useState("");
  function cbStaffDtls(data) {
    if (data.SvcStatus === "Success") {
      setStaffDtls(data.staff);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function setStaffDtls(staff) {
    if (typeof staff === "String") {
      return;
    }
    setPhone(staff.phone);
    setName(staff.name);
    setEmail(staff.email);
    setGender(staff.gender);
    if (staff.dob == "-") {
      setDob("");
    } else {
      setDob(staff.dob);
    }
    if (staff.doj == "-") {
      setDoj("");
    } else {
      setDoj(staff.doj);
    }
    setUserId(staff.userId);
  }
  function handleGenderChange(param) {
    setGender(param);
  }
  function handleNameChange(param) {
    setName(param);
  }
  function handleEmailChange(param) {
    setEmail(param);
  }

  function getDateForCalendar(strDt) {
    if (!strDt) return null;
    var parts = strDt.split("-");
    var d = parts[0];
    var m = parts[1] - 1;
    var y = parts[2];

    return new Date(y, m, d);
  }
  function handleDobChange(param) {
    var m = param.getMonth() + 1;
    if (m < 10) {
      m = "0" + m;
    }
    var d = param.getDate();
    if (d < 10) {
      d = "0" + d;
    }
    const dt = d + "-" + m + "-" + param.getFullYear();
    setDob(dt);
  }
  function handleDojChange(param) {
    var m = param.getMonth() + 1;
    if (m < 10) {
      m = "0" + m;
    }
    var d = param.getDate();
    if (d < 10) {
      d = "0" + d;
    }
    const dt = d + "-" + m + "-" + param.getFullYear();
    setDoj(dt);
  }
  function handlePhoneChange(param) {
    setPhone(param);
  }
  function saveStaff() {
    setLoader("block");
    const staffObj = {
      // staffId: !staffId ? 0 : staffId,
      name: name,
      dob: dob,
      doj: doj,
      email: email,
      phone: phone,
      gender:gender,
      userId:!userId?0:userId
    };
    saveStaffSvc(staffObj, cbSaveStaff);
  }
  function cbSaveStaff(data) {
    if (data.SvcStatus === "Success") {
      // console.log("UserId " + data.userId);
      props.setSelectedUserId(data.userId);
      toast.success(data.SvcMsg);
      //props.generateRandomNo()
      props.closeSlider();
      setLoader("none");
    } else {
      setLoader("none");
      toast.error(data.SvcMsg);
    }
  }
  useEffect(() => {
    if (props.flgSliderType == SliderType.SLIDER_EDIT_DTLS) {
      if (props.userId == 0) {
        setStaffDtls({ name: "", dob: "-", doj: "-", email: "", phone: "", gender:"", userId:0 });
      } else {
        // console.log(props.flgSliderType);
        getStaffDtlsSvc( props.userId, cbStaffDtls);
      }
    }
  }, [props.flgSliderType, props.userId]);
  return (
    <form
      className="form form-horizontal"
      style={{
        width: "100%",
        margin: "0 auto",
        background: "#fff",
        border: "1px solid #ccc",
        borderRadius: 10,
        pointerEvents: loader === "none" ? "auto" : "none",
      }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>

      <div
        className="StaffHead col-sm-12 col-centered"
        style={{
          border: "1px solid #aaa",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          fontWeight: "bold",
        }}
      >
        <h4>Staff Details</h4>
      </div>
      <div className="row no-display" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-5">Staff Id</label>
          <div className="col-sm-6 padding-remove">
            <input
              type="text"
              defaultValue={userId}
              className="form-control"
              readOnly
            ></input>
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-5">
            Name<span className="red-color">*</span>
          </label>
          <div className="col-sm-6 padding-remove">
            <input
              type="text"
              className="form-control"
              maxLength={60}
              value={name}
              onChange={(e) => handleNameChange(e.target.value)}
              required
            ></input>
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-5">
            Gender<span className="red-color">*</span>
          </label>
          <div className="col-sm-6 padding-remove">
            <select
              className="form-control"
              value={gender}
              onChange={(e) => handleGenderChange(e.target.value)}
            >
              <option value="">Gender</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
          </div>
        </div>
      </div>

      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-5">
            Date of Birth<span className="red-color">*</span>
          </label>
          <div className="col-sm-6 padding-remove">
            <Datepicker
              type="date"
              className="form-control"
              selected={getDateForCalendar(dob)}
              onChange={handleDobChange}
              dateFormat="dd-MM-yyyy"
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
            ></Datepicker>
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-5">
            Date of Joining<span className="red-color">*</span>
          </label>
          <div className="col-sm-6 padding-remove">
            <Datepicker
              type="date"
              className="form-control"
              selected={getDateForCalendar(doj)}
              onChange={handleDojChange}
              dateFormat="dd-MM-yyyy"
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
            ></Datepicker>
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-5">
            Email<span className="red-color">*</span>
          </label>
          <div className="col-sm-6 padding-remove">
            <input
              type="text"
              className="form-control"
              maxLength={100}
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-5">
            Contact No.<span className="red-color">*</span>
          </label>
          <div className="col-sm-6 padding-remove">
            <input
              type="tel"
              className="form-control"
              id="phone"
              name="phone"
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              value={phone}
              onChange={(e) => handlePhoneChange(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <button
          type="button"
          className="btn btn-info "
          onClick={(e) => saveStaff()}
        >
          Save
        </button>
      </div>
    </form>
  );
};
export default StaffDtlsSlider;
