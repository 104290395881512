import React from 'react'
import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
import { toast, ToastContainer } from 'react-toastify';
var queryString = require('querystring');
const tokenid = localStorage.getItem('tokenid')
const screenId = 37

var httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
const PaymentHistory = (props) => {

    
    function viewReceipt(paymentId) {

        axios.post(httpURL+'/sms/payment/slip',
            queryString.stringify({ paymentId: paymentId, screenId }), {
            headers: { 'tokenid': tokenid },
            withCredentials: true,
            responseType: 'arraybuffer',
        }).then((response) => {


            // if (response.data.SvcStatus === "Success") {
            // console.log(response.data)
            // this.props.flgSlider(1)
            props.pdfObject(response)
            if (response.data.SvcStatus !== "Success") {
                toast.error(response.data.SvcMsg);
            }

        }).catch((e) => {
            toast.error("Service failed " + e);
        });
        props.flgSlider(true)
    }

    return (
        <div>
            <h6 className=" text-center paymentTextHeader">Payment History</h6>
            <table className="tableLayout1">
                <thead>
                    <tr>
                        <th style={{ width: 40 }}> Date</th>
                        <th style={{ width: 40 }}> Amount </th>
                        <th style={{ width: 40 }}> Paid By</th>
                        <th style={{ width: 40 }}> Mail Status</th>
                        <th style={{ width: 40 }}> Action</th>
                    </tr>
                </thead>
            </table>
            <div className="tableDivSmall">
                <table id="paymentHistoryTable" className="tableLayout">
                    <tbody id="paymentHistoryTableBody" >
                        {props.lstPayment.map((payment, i) => (
                            <tr key={i}>
                                <td width="40" className='no-display' >
                                    {payment.paymentId}
                                </td>
                                <td width="40" className="text-center">
                                    {payment.dt}
                                </td>
                                <td width="40" className="text-center">
                                    {payment.amt}
                                </td>
                                <td width="40" className="text-left">
                                    {payment.paidByNm}
                                </td>
                                <td width="40" className="text-center">
                                    {payment.mailStatus}
                                </td>
                                <td width="40" className="text-center">
                                    <span className="glyphicon glyphicon-eye-open" onClick={(e) => viewReceipt(payment.paymentId)} />
                                    <span className="glyphicon glyphicon-print" />
                                    <span className="glyphicon glyphicon-envelope" />
                                    <span className="glyphicon glyphicon-trash" onClick={(e) => props.deletePayment(payment.paymentId, payment.dt, payment.amt)} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PaymentHistory
