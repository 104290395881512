import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { startTestSvc, generateOtpSvc } from './WTestSvc.js';

const WTestHeader = ({ lstStudent, testId, lstStudentId, testObj, countTotalStudent, getTestDtls, printStudentOtp }) => {
  const [sectionId, setSectionId] = useState(0);
  const [clsSection, setClsSection] = useState('');
  const [test, setTest] = useState('');
  const [secondsPassed, setSecondsPassed] = useState(0);
  const [countdown, setCountDown] = useState(0);
  const [tmToStart, setTmToStart] = useState(0);
  const [myFlg, setMyFlg] = useState(0);
  const [flgPositive, setFlgPositive] = useState(true);
  const [schoolId, setSchoolId] = useState(parseInt(localStorage.getItem('schoolId')) || 0);
  var arrTmToStart = [], minsToStart = 0, secsToStart = 0;

  useEffect(() => {
    // console.log(testObj)
    if (testObj.hasOwnProperty("timeToStart")) {
      setClsSection(testObj.clsSection);
      setTest(testObj.test);
      setSectionId(testObj.sectionId);

      arrTmToStart = testObj.timeToStart.split(":");

      minsToStart = parseInt(arrTmToStart[0]);
      secsToStart = parseInt(arrTmToStart[1]);
      if (minsToStart >= 0) {
        // console.log("POSITIVE")
        if (secsToStart >= 0) {
          setFlgPositive(true);
        } else {
          setFlgPositive(false);
        }
        setTmToStart(minsToStart * 60 + secsToStart);
      } else {
        // console.log("NEGATIVE")
        setFlgPositive(false);
        setTmToStart(-minsToStart * 60 - secsToStart);
      }
      setMyFlg(1)
    }
  }, [testObj])

  useEffect(() => {
    const secsInterval = setInterval(() => {
      setSecondsPassed(sp => {
        if (myFlg == 1) {
          if (flgPositive) {
            let tmpTmToStart = tmToStart - sp;
            if (tmpTmToStart >= 0) {
              // console.log("P-POSITIVE")
              minsToStart = ~~(tmpTmToStart / 60);
              secsToStart = tmpTmToStart % 60;
              setCountDown(`${minsToStart}:${secsToStart.toLocaleString("en-US", {
                minimumIntegerDigits: 2
              })}`);
              // console.log('tmToStart = ' + tmToStart + ', sp = ' + sp + ', tmpTmToStart = ' + tmpTmToStart + `${minsToStart}:${secsToStart.toLocaleString("en-US", { minimumIntegerDigits: 2 })}`)//correct
            } else {
              setFlgPositive(false)
              minsToStart = ~~(-tmpTmToStart / 60);
              secsToStart = -tmpTmToStart % 60;
              setCountDown(`${minsToStart}:${secsToStart.toLocaleString("en-US", {
                minimumIntegerDigits: 2
              })}`);
              // console.log('else part of tmpTmpToStart<=0' + `${minsToStart}:${secsToStart.toLocaleString("en-US", {    minimumIntegerDigits: 2 `) 
            }
          } else {
            if (tmToStart - sp > 0) {
              let tmpTmToStart = tmToStart + sp;
              minsToStart = ~~(tmpTmToStart / 60);
              secsToStart = tmpTmToStart % 60;
              setCountDown(`${minsToStart}:${secsToStart.toLocaleString("en-US", {
                minimumIntegerDigits: 2
              })}`);
              // console.log('N-NEGATIVE tmpTmToStart>0 tmToStart = ' + tmToStart + ', sp = ' + sp + ', tmpTmToStart = ' + tmpTmToStart + '-' + minsToStart + '-' + secsToStart + `${minsToStart}:${secsToStart.toLocaleString("en-US", {    minimumIntegerDigits: 2})}`)
            } else {
              let tmpTmToStart = tmToStart - sp;
              minsToStart = ~~(-tmpTmToStart / 60);
              secsToStart = -tmpTmToStart % 60;
              setCountDown(`${minsToStart}:${secsToStart.toLocaleString("en-US", {
                minimumIntegerDigits: 2
              })}`);
              // console.log('N-NEGATIVE tmpTmToStart<0 tmToStart = ' + tmToStart + ', sp = ' + sp + ', tmpTmToStart = ' + tmpTmToStart + `${minsToStart}:${secsToStart.toLocaleString("en-US", {  minimumIntegerDigits: 2})}`)
            }
          }
        }
        return sp + 1
      }
      )
    }, 992);
    return () => { clearInterval(secsInterval) }
  }, [secondsPassed]);

  function generateOtp() {
    // console.log(lstStudentId)
    generateOtpSvc(testId, lstStudentId, cbGenerateOtpSvc);
  }

  function cbGenerateOtpSvc(data) {
    // console.log("cbGenerateOtpSvc() called.. ")
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      getTestDtls();
    } else {
      toast.error(data.SvcMsg);
    }
  }

  function startTest(e) {
    startTestSvc(testId, cbStartTestSvc);
    // console.log("startTest() called:- redirect to new screen /wtestprogress?testId=" + testId + "&test=" + test)
    const newPageUrl = "/wTestProgress?testId=" + testId + "&test=" + test;
    // window.location.href = newPageUrl;
    window.open(newPageUrl, '_blank');
  }

  function cbStartTestSvc(data) {
    // console.log("cbStartTestSvc() called.. ")
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg);
    }
  }
  return (
    <form className="form form-horizontal">
      <div className="col-sm-12 padding-remove" style={{ padding: 0 }}>
        <div style={{ height: 35, border: '1px solid #b4b4b4' }} className={schoolId === 31270 ? 'col-sm-1 padding-remove' : "col-sm-2 padding-remove"}>
          {/* <label className="control-label col-sm-6" style={{ paddingLeft: 0 }}>Class Section:</label> */}
          <div className='col-sm-12 form-group padding-remove' title='Class SEction'>
            <label className="text-left">{clsSection}</label>
          </div>
        </div>
        <div className="col-sm-2 padding-remove">
          <div style={{ height: 35, marginLeft: 10, border: '1px solid #b4b4b4' }} className="col-sm-9">
            {/* <label className="control-label col-sm-2" style={{ paddingRight: 0 }}>Test:</label> */}
            <div className='col-sm-12 form-group' title='Test Name' >
              <label className="text-left" >{test}</label>
            </div>
          </div>
          <div style={{ height: 35, marginLeft: 0, borderWidth: '1px 1px 1px 0', borderStyle: 'solid', borderColor: '#b4b4b4' }} className="col-sm-2">
            {/* <label className="control-label col-sm-2" style={{ paddingRight: 0 }}>Test:</label> */}
            <div className='col-sm-12 form-group' title='Test Id'>
              <label className="text-left" >{testId}</label>
            </div>
          </div>
        </div>
        <div className="col-sm-2" style={{ width:  '11%' }}>
          <button type="button" className="btn btn-info pull-right" onClick={(e) => generateOtp()}>Generate OTP</button>
        </div>
        <div className="col-sm-2" style={{ width: '8%' }}>
          <button type="button" className="btn btn-info pull-right" onClick={(e) => printStudentOtp(10)}>Print OTP</button>
        </div>
            <div className="col-sm-2" style={{ width: '8%' }}>
              <button type="button" className="btn btn-info pull-right" onClick={(e) => printStudentOtp(20)}>Staff OTP</button>
            </div>
        {
          schoolId === 31270 ?
              <div className="col-sm-1" style={{ width: '8%' }}>
                <button type="button" className="btn btn-info pull-right" onClick={(e) => printStudentOtp(30)}>Print PDF</button>
              </div>
            :
            null
        }
        <div className="col-sm-2">
          <label className="control-label text-left" >Total Students:</label>
          <label className="text-left" >{countTotalStudent}</label>
        </div>
        <div className="col-sm-1" style={{ width: '23%' }}>
          <button type="button" className="btn btn-info pull-right" style={{ width: '40%' }} onClick={(e) => startTest()}>Start</button>
          <div className="col-sm-12 pull-right" style={{ width: '50%' }}>
            <div className="timer" style={{
              border: '1px solid orangered', padding: 2, borderRadius: 4, color: 'orangered', fontSize: 20, width: '100%', height: 34, justifyContent: 'center', display: 'flex',
              alignItems: 'center', fontWeight: 'bold'
            }}>{flgPositive ? "" : "-"} {countdown}</div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default WTestHeader