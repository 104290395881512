import React, { useState, useEffect } from "react";
import Header from "common/js/header/header.js";
import "./Registration.css";
import axios from "axios";
import RegistrationPersonal from "./RegistrationPersonal.js";
import RegistrationCategory from "./RegistrationCategory.js";
import RegistrationCommunication from "./RegistrationCommunication.js";
import RegistrationParents from "./RegistrationParents.js";
import RegistrationSiblings from "./RegistrationSiblings.js";
import RegistrationHistory from "./RegistrationHistory.js";
import RegistrationDocs from "./RegistrationDocs.js";
import { toast, ToastContainer } from "react-toastify";
import lstModules from "common/json/lstModules.json";
var httpURL = lstModules["enquiry"].url + ":" + lstModules["enquiry"].port;
var tokenid = localStorage.getItem("tokenid");

const RegistrationSlider = (props) => {
  const [lstDocDtls, setLstDocDtls] = useState([]);
  const [photoPath, setPhotoPath] = useState("");
  const [loader, setLoader] = useState("none");
  const [regsId, setRegsId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [enqId, setEnqId] = useState(0);
  let isMounted = false;
  const PHOTO = 50;
  const controller = new AbortController();
  const signal = controller.signal;

  function setCtrlRegId(pRegsId) {
    setRegsId(pRegsId);
    props.setRegsId(pRegsId);
  }

  useEffect(() => {
    setLoader("block");
    setUserId(props.userId);
    setEnqId(props.enquiryId);
    isMounted = true;
    return () => {
      controller.abort();
      isMounted = false;
    };
  }, [props.registrationId, props.userId, props.enquiryId]);

  useEffect(() => {
    setLoader("block");
    var vRegsId = props.registrationId === "-" ? 0 : props.registrationId;
    if (vRegsId) {
      setRegsId(vRegsId);
      getDocDtlsSvc(vRegsId, cbIdDocDtlsResponse);
    }
  }, [props.registrationId]);

  function getDocDtlsSvc(regsId, callBack) {
    axios({
      url: httpURL + "/sms/regs/docs/dtls",
      method: "POST",
      params: { regsId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var data = Response.data;
        // console.log("list fetching success");
        callBack(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function cbIdDocDtlsResponse(pLstDocDtls) {
    if (pLstDocDtls.SvcStatus === "Success") {
      setLstDocDtls(pLstDocDtls.lstDocDtls);
      for (var i = 0; i < pLstDocDtls.lstDocDtls.length; i++) {
        var docObj = pLstDocDtls.lstDocDtls[i];
        if (docObj.typeId === PHOTO) {
          // console.log( httpURL + "/sms/regs/docs/view?imgPath=" + docObj.imgPath );
          setPhotoPath(
            httpURL + "/sms/regs/docs/view?imgPath=" + docObj.imgPath
          );
        }
      }
    } else {
      toast.error(pLstDocDtls.SvcMsg);
    }
    setLoader("none");
  }

  return (
    <div className="col-sm-12 padding-remove regsContainer">
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className="container-fluid">
        <div id="divPersonal" className="divPersonal row-fluid">
          <div className="col-sm-12 section-head">
            <h5>Personal </h5>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 section"
            style={{ height: 330 }}
          >
            {userId != 0 && (
              <>
                <RegistrationPersonal
                  key={userId}
                  regsId={regsId === "-" ? 0 : regsId}
                  userId={userId}
                  enqId={enqId}
                  photoPath={photoPath}
                  setCtrlRegId={setCtrlRegId}
                />
              </>
            )}
          </div>
        </div>

        <div id="divCategory" className="divCategory">
          <div className="col-sm-12 section-head">
            <h5>Category </h5>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 section form-inline"
            style={{ height: 50 }}
          >
            <RegistrationCategory
              regsId={regsId === "-" ? 0 : regsId}
              flgSliderType={props.flgSliderType}
            />
          </div>
        </div>

        <div id="divCommunication" className="divCommunication">
          <div className="col-sm-12 section-head">
            <h5>Communication </h5>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 section"
            style={{ height: 50 }}
          >
            <RegistrationCommunication
              regsId={regsId === "-" ? 0 : regsId}
              flgSliderType={props.flgSliderType}
            />
          </div>
        </div>
        <div id="divParents" className="divParents">
          <div className="col-sm-12  section-head">
            <h5>Parents </h5>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 section"
            style={{ height: 420 }}
          >
            {
              <RegistrationParents
                regsId={regsId === "-" ? 0 : regsId}
                flgSliderType={props.flgSliderType}
              />
            }
          </div>
        </div>

        <div id="divSiblings" className="divSiblings">
          <div className="col-sm-12  section-head">
            <h5>Siblings </h5>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 section"
            style={{ height: 120 }}
          >
            <RegistrationSiblings
              key={
                userId +
                "_" +
                regsId +
                "_" +
                (props.siblingSliderKey ? true : false)
              }
              flgSliderType={props.flgSliderType}
              regsId={regsId === "-" ? 0 : regsId}
              setPopup={props.setPopup}
              setSiblingId={props.setSiblingId}
              setSibling={props.setSibling}
              setClsId={props.setClsId}
              setSchool={props.setSchool}
              setGender={props.setGender}
              setDob={props.setDob}
            />
          </div>
        </div>

        <div id="divHistory" className="divHistory">
          <div className="col-sm-12  section-head">
            <h5>History </h5>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 section"
            style={{ height: 350 }}
          >
            <RegistrationHistory
              regsId={regsId === "-" ? 0 : regsId}
              flgSliderType={props.flgSliderType}
            />
          </div>
        </div>

        <div id="divDocs" className="divDocs">
          <div className="col-sm-12  section-head">
            <h5>Documents </h5>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 section"
            style={{ height: 200, marginBottom: 10 }}
          >
            <RegistrationDocs
              key={userId + "_" + regsId}
              regsId={regsId === "-" ? 0 : regsId}
              flgSliderType={props.flgSliderType}
              lstDocDtls={lstDocDtls}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationSlider;
