import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { getLstStaff, deleteStaffSvc } from "./StaffSvc";
import SliderType from "./SliderType";

const StaffTable = (props) => {
  const [loader, setLoader] = useState("none");
  const [lstStaff, setLstStaff] = useState([]);
  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false); const [filterEnrollment, setFilterEnrollment] = useState("");
  const [filteredName, setFilteredName] = useState("");
  const [filteredRole, setFilteredRole] = useState("");
  const [filteredEmail, setFilteredEmail] = useState("");
  const [foundStaff, setFoundStaff] = useState(lstStaff);

  useEffect(() => {
    props.setLoader("block");
    getLstStaff(cbHandleStaffResponse);
    setFilteredName("");
    setFilteredEmail("");
    setFilteredRole("");
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  function cbHandleStaffResponse(data) {
    // setLstStaff(data)
    props.setLoader("none");
    if (data.SvcStatus === "Success") {
      setLstStaff(data.lstStaff);
      setFoundStaff(data.lstStaff);
    } else {
      toast.error(data.SvcMsg);
      // console.log(data.SvcStatus);
    }
  }

  function setStaffDtls(prId, flgSA, pUsrId) {
    props.setPrimaryRoleId(prId);
    props.setFlgSystemAccess(flgSA);
    // props.setSelectedStaffId(staffId);
    props.setSelectedUserId(pUsrId)
    setIsMenuOpen((oldState) => !oldState);
  }


  function deleteStaff(userId, name) {
    const confirmBox = window.confirm(
      "Are you sure you want to delete staff: " + name
    );
    if (confirmBox === true) {
      deleteStaffSvc(cbDeleteStaffResp, userId);
    }
  }
  function cbDeleteStaffResp(data) {
    if (data.SvcStatus === "Success") {
      // console.log("inside cbDeleteStaffResp");
      // props.setLoader('none')
      props.refreshScreen();
      toast.success("Staff Deleted");
    } else {
      props.setLoader("none");
      // console.log(data.SvcMsg);
    }
  }
  const colgrp = (
    <colgroup>
      <col width="7%"></col>
      <col width="16.6%"></col>
      <col width="16.6%"></col>
      <col width="23.6%"></col>
      <col width="16.6%"></col>
      <col width="10%"></col>
    </colgroup>
  );
  const filterName = (e) => {
    setFilteredName(e.target.value);
    filterStaffList(e.target.value, filteredEmail, filteredRole);
  };
  const filterEmail = (e) => {
    setFilteredEmail(e.target.value);
    filterStaffList(filteredName, e.target.value, filteredRole);
  };
  const filterRole = (e) => {
    setFilteredRole(e.target.value);
    filterStaffList(filteredName, filteredEmail, e.target.value);
  };

  const filterStaffList = (fName, fEmail, fRole) => {
    if(lstStaff && lstStaff.length){
      let filteredStaff = [];
      filteredStaff = lstStaff.filter(user =>
      user.name.toLowerCase().includes(fName.toLowerCase()));
      
      filteredStaff = filteredStaff.filter(user =>
        user.email.toLowerCase().includes(fEmail.toLowerCase()));

        filteredStaff = filteredStaff.filter(user =>
      user.primaryRole.toLowerCase().includes(fRole.toLowerCase()));
      
      setFoundStaff(filteredStaff);
    }
  }

  return (
    <>
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: props.loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>

      <table className="tableLayout1">
        {colgrp}
        <thead>
          <tr>
            <th>Staff Id</th>
            {/* <th>
                            <input className='text-left' type="text" value={name} onChange={filter} style={{color:"black"}} placeholder="Name"/>
                        </th> */}
            <th style={{ width: 75 }}>
              <input
                type="text"
                value={filteredName}
                onChange={(e)=>filterName(e)}
                className="form-control inp-search"
                placeholder="Name"
              />
            </th>
            <th>Phone</th>
            <th style={{ width: 75 }}>
              <input
                type="text"
                value={filteredEmail}
                className="form-control inp-search"
                placeholder="Email"
                onChange={(e)=>filterEmail(e)}
              />
            </th>
            <th style={{ width: 75 }}>
              <input
                type="text"
                value={filteredRole}
                onChange={(e)=>filterRole(e)}
                className="form-control inp-search"
                placeholder="Role"
              />
            </th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
      <div className="tablebodyWrapper tableBodyBorder" >
        <table className="tableLayout">
          {colgrp}
          <tbody>
            {foundStaff && foundStaff.map((staff, idx) => {
              return (
                <tr
                  className={
                    staff.userId === props.selectedUserId ? "selected" : ""
                  }
                  key={idx}
                  onClick={(e) => {
                    props.setSelectedUserId(staff.userId)
                    // console.log("onClick called for user ", staff.userId)
                    // console.log("selected user is", props.selectedUserId)
                  }}
                >
                  <td>{staff.userId}</td>
                  <td className="text-left">{staff.name}</td>
                  <td>{staff.phone}</td>
                  <td className="text-left">{staff.email}</td>
                  <td className="text-left">{staff.primaryRole}</td>
                  <td className="text-center">
                    <span
                      className="glyphicon glyphicon-option-vertical"
                      onClick={(e) =>
                        setStaffDtls(
                          // staff.staffId,
                          staff.primaryRoleId,
                          staff.flgAllowAccess,
                          staff.userId
                        )
                      }
                    >
                      <ul
                        className="ulStyle1"
                        style={{
                          display:
                            isMenuOpen &&
                              staff.userId === props.selectedUserId
                              ? "block"
                              : "none",
                        }}
                      >
                        <li
                          onClick={(e) =>
                            props.setFlgSliderType(SliderType.SLIDER_EDIT_DTLS)
                          }
                        >
                          Edit
                        </li>
                        <li
                          onClick={(e) =>
                            deleteStaff(staff.userId, staff.name)
                          }
                        >
                          Delete
                        </li>
                        <li
                          onClick={(e) =>
                            props.setFlgSliderType(SliderType.SLIDER_ROLES)
                          }
                        >
                          Roles
                        </li>
                        <li
                          onClick={(e) =>
                            props.setFlgSliderType(
                              SliderType.SLIDER_CLASS_SUBJECTS
                            )
                          }
                        >
                          Class-Subjects
                        </li>
                        <li
                          onClick={(e) =>
                            props.setSelectedStaffInfo(
                              SliderType.SLIDER_WEEKLY_ALLOC,
                              staff
                            )
                          }
                        >
                          Weekly Allocation
                        </li>

                        {/* <li onClick={(e) => props.setFlgSliderType(SliderType.SLIDER_SALARY)}>Salary</li>
                                    <li onClick={(e) => props.setFlgSliderType(SliderType.SLIDER_EDUCATION)}>Education</li>
                                    <li onClick={(e) => props.setFlgSliderType(SliderType.SLIDER_DOCUMENTS)}>Documents</li> */}
                        <li
                          onClick={(e) =>
                            props.setFlgSliderType(
                              SliderType.SLIDER_SYSTEM_ACCESS
                            )
                          }
                        >
                          System Access
                        </li>
                        <li
                          onClick={(e) =>
                            props.setFlgSliderType(SliderType.SLIDER_SIGN)
                          }
                        >
                          Sign
                        </li>
                      </ul>
                    </span>{" "}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default StaffTable;
