import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
const httpURL = lstModules['transport'].url + ':' + lstModules['transport'].port;
var tokenid = localStorage.getItem('tokenid');

function StopRangeSlider(props){

    const [stopRange,setStopRange]=useState('')
    const [distance,setDistance]=useState(0)
    const [stopRangeId,setStopRangeId]=useState(0)
    const [loader,setLoader]=useState('none')
    const [selIdx,setSelIdx]=useState()
    const [selFeeHeadId,setSelFeeHeadId]=useState(props.selFeeHeadId)
    useEffect(()=>{
        // console.log(JSON.stringify(props.stopObj))
        setStopRange(props.stopObj.stopRange)
        setDistance(props.stopObj.distance)
        setStopRangeId(props.stopObj.stopRangeId)
        getSelIdx(props.selFeeHeadId)
        setSelFeeHeadId(props.selFeeHeadId)
        
    },[])
    function getSelIdx(id){
        for(var i=0;i<props.lstFeeHead.length;i++){
            if(props.lstFeeHead[i].feeHeadId===id){
                setSelIdx(i)
            }
        }
    }
    function handleSelRow(param){
        setSelIdx(param)
        setSelFeeHeadId(props.lstFeeHead[param].feeHeadId)
    }
    function handleStopRangeChange(param){
        setStopRange(param)
    }
    function handleDistanceChange(param){
        setDistance(param)
    }
    function saveResult(callBack){
        setLoader('block')
        var stopRangeObj={
            stopRangeId:stopRangeId,
            stopRange:stopRange,
            distance:distance,
            feeHeadId:selFeeHeadId,
        }
        axios(
            {
                url: httpURL +"/sms/tpt/stop_range/save",
                method: 'POST',
                params: {stopRange: JSON.stringify(stopRangeObj) },
                headers: { 'tokenid': tokenid },
                withCredentials: true
            }
        ).then(
            (Response) => {
                var data = Response.data
                callBack(data)
                
    
            }
        ).catch((error) => {
            console.error(error)
        });
    }
    function cbSave(data){
        if(data.SvcStatus==='Success'){
            toast.success(data.SvcMsg)
            props.clearForm()
            props.generateRandomNo()
        }
        else{
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
    const colgrp = (
        <colgroup>
          <col width="8%"></col>
          <col width="8%"></col>
          <col width="8%"></col>
        </colgroup>
      );
    return(<form
    className="form form-horizontal"
    style={{
    pointerEvents: loader === "none" ? "auto" : "none",
      height: "100%",
      width: "100%",
      overflowX: 'hidden',
      background: "#fff",
      border: "1px solid #ccc",
      borderRadius: 10,
    }}>
    <ToastContainer autoClose={3000} hideProgressBar />        
    <div className="form-group col-md-12">
                    <label className="control-label col-md-5">Stop Range<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='text' className="form-control" defaultValue={props.stopObj.stopRange} onChange={(e) => handleStopRangeChange(e.target.value)} required></input>
                    </div>
                </div>
                <div className="form-group col-md-12">
                    <label className="control-label col-md-5">Distance<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='text' className="form-control" defaultValue={props.stopObj.distance} onChange={(e) => handleDistanceChange(e.target.value)} required></input>
                    </div>
                </div>
                <div className="form-group col-md-12">
                    <div className='col-sm-12 padding-remove'>
                    <table className="col-sm-8  col-sm-offset-3" style={{overflow :'scroll'}}>
                          {colgrp}
                            <thead>
                            <tr>
                                <th>Fee Head Code</th>
                                <th>Amount</th>
                                <th>Frequency</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    props.lstFeeHead.map((obj,idx)=>{
                                        return (
                                            <tr onClick={(e)=>handleSelRow(idx)} className={selIdx === idx ? 'selected' : ''} key={idx}>
                                                <td style={{textAlign:'center'}}>{obj.feeHeadCode}</td>
                                                <td style={{textAlign:'center'}}>{obj.amt}</td>                              
                                                <td style={{textAlign:'center'}}>{obj.frequency}</td>
                                            </tr>   
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="form-group col-md-12">
                    <div className="col-md-offset-7 col-md-1 padding-remove">
                        <button type="button" className="btn btn-info pull-right" onClick={(e) => saveResult(cbSave)}>Save</button>
                    </div>
                </div>
    </form>)
}
export default StopRangeSlider;