import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios'
import Datepicker from "react-datepicker";
import lstModules from 'common/json/lstModules.json'
const httpURL = lstModules['transport'].url + ':' + lstModules['transport'].port;
var tokenid = localStorage.getItem('tokenid');
function DriverSlider(props) {
    const [staffId, setStaffId] = useState()
    const [loader, setLoader] = useState('none')
    const [lstDriver, setLstDriver] = useState([])
    const [age, setAge] = useState()
    const [drivingExp, setDrivingExp] = useState()
    const [schoolExp, setSchoolExp] = useState()
    const [licenseNo, setLicenseNo] = useState()
    const [licenseType, setLicenseType] = useState()
    const [licenseExpiryDt, setLicenseExpiryDt] = useState()
    const [lstVehicleType, setLstVehicleType] = useState([])
    const [vehicleTypeArr, setVehicleTypeArr] = useState([])
    useEffect(() => {
        getDtls(cbGetDtls)
        setStaffId(props.selStaffId)
    }, [])
    function save(callback) {
        var driver = {
            staffId: props.selStaffId,
            // age:age,
            drivingExp: drivingExp,
            schoolExp: schoolExp,
            licenseNo: licenseNo,
            licenseType: licenseType,
            licenseExpiryDt: licenseExpiryDt,
            lstVehicleTypeId: vehicleTypeArr
        }
        var formData = new FormData();
        formData.append("driver", JSON.stringify(driver))

        axios(
            {
                url: httpURL + "/sms/tpt/driver/save",
                method: 'POST',
                data: formData,
                headers: { 'tokenid': tokenid },
                withCredentials: true
            }
        ).then(
            (Response) => {
                var data = Response.data
                callback(data)


            }
        ).catch((error) => {
            console.error(error)
        });
    }
    function cbSave(data) {
        if (data.SvcStatus === 'Success') {
            toast.success(data.SvcMsg)
            props.closeSlider()
        }
        else {
            toast.error(data.SvcMsg)
        }
    }
    function handleAgeChange(param) {
        setAge(param)
    }
    function handleDrivingExpChange(param) {
        setDrivingExp(param)
    }
    function handleSchoolExpChange(param) {
        setSchoolExp(param)
    }
    function handleLicenseTypeChange(param) {
        setLicenseType(param)
    }
    function handleLicenseNumberChange(param) {
        setLicenseNo(param)
    }
    function getDateForCalendar(strDt) {
        if (!strDt) return null;
        var parts = strDt.split("-");
        var d = parts[0];
        var m = parts[1] - 1;
        var y = parts[2];

        return new Date(y, m, d);
    }
    function handleDtChange(param) {
        var m = param.getMonth() + 1;
        if (m < 10) {
            m = "0" + m;
        }
        var d = param.getDate();
        if (d < 10) {
            d = "0" + d;
        }
        const dt = d + "-" + m + "-" + param.getFullYear();
        setLicenseExpiryDt(dt);
    }
    function getDtls(callback) {
        // console.log(props.selStaffId)
        if (!props.selStaffId || props.selStaffId < 0) {
            return
        }
        axios(
            {
                url: httpURL + "/sms/tpt/driver/dtls",
                method: 'POST',
                params: { staffId: props.selStaffId },
                headers: { 'tokenid': tokenid },
                withCredentials: true
            }
        ).then(
            (Response) => {
                var data = Response.data;
                callback(data);
            }
        ).catch((error) => {
            // console.log("error" + error)
        });
    }
    function cbGetDtls(data) {
        if (data.SvcStatus === "Success") {
            setLstDriver(data.driver)
            setAge(data.driver.age)
            setDrivingExp(data.driver.drivingExp)
            setSchoolExp(data.driver.schoolExp)
            setLstVehicleType(data.driver.lstVehicleType)
            setLicenseType(data.driver.licenseType)
            setLicenseNo(data.driver.licenseNo)
            setLicenseExpiryDt(data.driver.licenseExpiryDt)
            makeArrVchType(data.driver.lstVehicleType)
        }
        else {
            toast.error(data.SvcMsg)
        }
        setLoader('none');
    }
    function makeArrVchType(data) {
        var tempArr = []
        for (var i = 0; i < data.length; i++) {
            if (data[i].selected === true) {
                tempArr.push(data[i].vehicleTypeId)
            }

        }
        setVehicleTypeArr(tempArr)

    }
    return (<form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", height: '100%', width: '100%', margin: '0px auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
        <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
            <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
        </div>
        <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={props.closeSlider}>
            <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
        </button>
        <div className='row'>
            <div className="form-group col-md-6">
                <label className="control-label col-sm-5">Age</label>
                <div className='col-sm-6 padding-remove'>
                    <input type='text' className="form-control" minLength={50} defaultValue={age} onChange={(e) => handleAgeChange(e.target.value)} required />
                </div>
            </div>
        </div>
        <div className='row'>
            <div className="form-group col-md-6">
                <label className="control-label col-sm-5">Driving Experience</label>
                <div className='col-sm-6 padding-remove'>
                    <input type='text' className="form-control" minLength={50} defaultValue={drivingExp} onChange={(e) => handleDrivingExpChange(e.target.value)} required />
                </div>
            </div>
        </div>
        <div className='row'>
            <div className="form-group col-md-6">
                <label className="control-label col-sm-5">School Experience</label>
                <div className='col-sm-6 padding-remove'>
                    <input type='text' className="form-control" minLength={50} defaultValue={schoolExp} onChange={(e) => handleSchoolExpChange(e.target.value)} required />
                </div>
            </div>
        </div>
        <div className='row'>
            <div className="form-group col-md-6">
                <label className="control-label col-sm-5">License Number</label>
                <div className='col-sm-6 padding-remove'>
                    <input type='text' className="form-control" minLength={50} defaultValue={licenseNo} onChange={(e) => handleLicenseNumberChange(e.target.value)} required />
                </div>
            </div>
        </div>
        <div className='row'>
            <div className="form-group col-md-6">
                <label className="control-label col-sm-5">License Type</label>
                <div className='col-sm-6 padding-remove'>
                    <input type='text' className="form-control" minLength={50} defaultValue={licenseType} onChange={(e) => handleLicenseTypeChange(e.target.value)} required />
                </div>
            </div>
        </div>
        <div className='row'>
            <div className="form-group col-md-6">
                <label className="control-label col-sm-5">License Expiry Date</label>
                <div className='col-sm-6 padding-remove'>
                    <Datepicker
                        type="date"
                        className="form-control"
                        selected={getDateForCalendar(licenseExpiryDt)}
                        onChange={handleDtChange}
                        dateFormat="dd-MM-yyyy"
                    ></Datepicker>
                </div>
            </div>
        </div>
        <div className="row">
            <button type="button" className="btn btn-info btn-space col-md-offset-3" onClick={(e) => save(cbSave)}>Save</button>
            {/* </div>
            <div className="row"> */}
        </div>
    </form>)
}
export default DriverSlider;