import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import $ from 'jquery';

var httpURL = '';
var queryString = require('querystring');
var screenId = 33;

export default class TdSub extends Component {
    constructor(props) {
        super(props);
        this.state = { cellId: 0, loader: 'block', opacity: 0, tokenid: localStorage.getItem('tokenid') };
        this.handleClick = this.handleClick.bind(this);
        this.getCellDataObj = this.getCellDataObj.bind(this);
        this.setSlider = this.setSlider.bind(this);
        /* this.onMoveMouse1 = this.onMoveMouse1.bind(this);
        this.onMouseLeave1 = this.onMouseLeave1.bind(this);
        this.dragOverHandler = this.dragOverHandler.bind(this);
        this.dropHandler = this.dropHandler.bind(this); */
    }

    handleClick() {
        // console.log('Click happened');
    }

    setSlider = (event) => {
        // console.log("setslider", $(event.target).parent().attr("cell"))
        $('#closeSlider').attr("cell", $(event.target).parent().attr("cell"));

    }


    componentDidMount() {
        this.getCellDataObj(this.props.periodId, this.props.dayId, this.props.classSectionId, this.props.examScheduleId, this.props.examDt, this.props.alongWithStudy, 0, this.props.examId);
        // console.log("periodId = " + this.props.periodId + ",dayId = " + this.props.dayId + ",classSectionId = " + this.props.sectionId + ", scheduleId = " + this.props.scheduleId);
    }

    getCellDataObj(periodId, dayId, classSectionId, examScheduleId, examDt, alongWithStudy, cellId, examId) {
        var rqCell = { "slotId": periodId, "classSectionId": classSectionId, "examDt": examDt, "cellId": cellId, "alongWithStudy": alongWithStudy, "dayId": dayId, "examId": examId };
        // console.log('rqCell: ' + rqCell);
        axios({
            url: httpURL + '/sms/exam/cell/dtls',
            method: "POST",
            params: { screenId, 'rqCell': rqCell },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            this.setState({
                cell: response.data.cellDtls,
            });
            // console.log(response.data.cellDtls);
            this.setState({ loader: 'none', opacity: 1 });

        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }
    render() {
        httpURL = lstModules['examTT'].url + ":" + lstModules['examTT'].port;

        return (
            <td style={{ width: 60 }} cell={JSON.stringify(this.props)}
                onClick={this.handleClick} >
                <div style={{ position: 'absolute', zIndex: 10000, display: this.state.loader, margin: '0 auto' }}>
                    <img className="loderImg" style={{ width: '20px' }} width={20} height={20} src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                {this.state.cell &&
                    (this.state.cell.ctrSub === 0 && <CellSubZero getCellDataObj={this.getCellDataObj} cellData={this.state.cell} {...this.props} />
                        || this.state.cell.ctrSub === 1 && <CellSubOne getCellDataObj={this.getCellDataObj} cellData={this.state.cell} setSlider={this.setSlider} {...this.props} />
                        || this.state.cell.ctrSub === 2 && <CellSubTwo getCellDataObj={this.getCellDataObj} cellData={this.state.cell} {...this.props} />)
                }

            </td>
        )
    }


}
class CellSubZero extends Component {
    state = {
        tokenid: localStorage.getItem('tokenid'),
        disp1: 'none',

    }
    constructor(props) {
        super(props);
        // console.log("props", props)
    }

    dragOverHandler(e) {
        e.preventDefault();
    }

    dropHandler(e) {
        e.preventDefault();
        // console.log(e.dataTransfer.getData("id"));
        $(e.target).closest(".sub1 > div").append("<span></span>");
        var targetElem = $(e.target).closest(".droppable > div > span")
        var subjectId = e.dataTransfer.getData("id");
        this.updateCellData(subjectId, 0, 1);
        $(targetElem).text(e.dataTransfer.getData("text"));
    }

    updateCellData = (subId, cellId, pos) => {
        this.setState({ loader: 'block', opacity: 0.5 });
        var rqSaveCell = {
            cellId: cellId,
            slotId: this.props.periodId,
            examDt: this.props.examDt,
            subId: subId,
            tchId: this.props.tch,
            subPosition: pos,
            classSectionId: this.props.classSectionId,
            assetId: this.props.asset,
            link: this.props.link,
            notes: this.props.notes,
            invigilatorId: this.props.invigilator,
            evaluatorId: this.props.evaluator,
            verifierId: this.props.verifier,
            alongWithStudy: this.props.alongWithStudy,
            dayId: this.props.dayId,
            examId: this.props.examId
        };
        axios({
            url: httpURL + '/sms/exam/cell/savedtls',
            method: "POST",
            params: { screenId, 'rqSaveCell': JSON.stringify(rqSaveCell) },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                cellId = response.data.cellId;
                this.props.getCellDataObj(this.props.periodId, this.props.dayId, this.props.classSectionId, this.props.examScheduleId, this.props.examDt, this.props.alongWithStudy, cellId, this.props.examId);
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
    }
    render() {
        return <div className="droppable" droppable={true} style={{ height: '80px' }} onDragOver={event => this.dragOverHandler(event)} onDrop={event => this.dropHandler(event)} >
            <div style={{ width: '100%', height: '40px', position: 'relative' }}>

            </div>
            <div className="droppable" droppable={true} style={{ width: '100%', height: '40px', display: 'flex', alignItems: 'flex-end', flexDirection: 'column', boxSizing: 'border-box' }}>
                <button
                    onClick={this.props.showSlider}
                    className="glyphicon glyphicon-plus"
                    style={{ textAlign: 'right', height: 'auto', marginLeft: 'auto', marginTop: 'auto' }} ></button>
            </div>

        </div>
    }
}
class CellSubOne extends Component {
    state = {
        tokenid: localStorage.getItem('tokenid'),
        disp1: 'none',

    }

    constructor(props) {
        super(props);

    }
    onMoveMouse1(event) {
        this.setState({ disp1: 'block' });
    }
    onMouseLeave1(event) {
        this.setState({ disp1: 'none' });
    }
    dragOverHandler(e) {
        e.preventDefault();
    }

    dropHandler(e) {
        e.preventDefault();
        // console.log(e.target);
        var cellId = $(e.target).closest(".droppable").attr('id');
        if (e.target.className == "droppable") {
            // console.log($(e.target).closest(".droppable").attr('id'));
            // console.log($(e.target).find(".sub1").attr('id'));
            var targetElem = $(e.target).find(".sub1").attr('id');
            // console.log($(e.target).find(".sub1"));
        } else {
            // console.log($(e.target).closest(".droppable").attr('id'));
            // console.log($(e.target).closest(".sub1").attr('id'));
            var targetElem = $(e.target).closest(".sub1").attr('id');
            // console.log($(e.target).closest(".sub1"));
        }
        var subjectId = e.dataTransfer.getData("id");
        this.updateCellData(subjectId, cellId, 1);
        document.getElementById(targetElem).innerHTML = e.dataTransfer.getData("text");
        $(e.target).innerHTML = e.dataTransfer.getData("text");
        // console.log("onDropHandler: " + e.dataTransfer.getData("text"));
    }

    updateCellData = (subId, cellId, pos) => {
        this.setState({ loader: 'block', opacity: 0.5 });
        var rqSaveCell = {
            cellId: cellId,
            slotId: this.props.periodId,
            examDt: this.props.examDt,
            subId: subId,
            tchId: this.props.tch,
            subPosition: pos,
            classSectionId: this.props.classSectionId,
            assetId: this.props.asset,
            link: this.props.link,
            notes: this.props.notes,
            invigilatorId: this.props.invigilator,
            evaluatorId: this.props.evaluator,
            verifierId: this.props.verifier,
            alongWithStudy: this.props.alongWithStudy,
            dayId: this.props.dayId,
            examId: this.props.examId

        };
        axios({
            url: httpURL + '/sms/exam/cell/savedtls',
            method: "POST",
            params: { screenId, 'rqSaveCell': JSON.stringify(rqSaveCell) },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                // console.log(this.props.periodId, this.props.dayId, this.props.sectionId, this.props.scheduleId);
                cellId = response.data.cellId;
                this.props.getCellDataObj(this.props.periodId, this.props.dayId, this.props.classSectionId, this.props.examScheduleId, this.props.examDt, this.props.alongWithStudy, cellId, this.props.examId);

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
    }

    dragOverHandler2(e) {
        e.preventDefault();
    }

    dropHandler2(e) {
        e.preventDefault();
        // console.log(e.target);
        $("td").removeClass("selected")
        $(e.target).parent().parent().addClass("selected")
        var cellId = $(e.target).closest(".droppable").attr('id');
        $("#" + cellId).find("td").attr("selected", true)
        $(e.target).closest(".sub1 > div").append("<span></span>");
        var targetElem = $(e.target).closest(".droppable > div > span")
        var subjectId = e.dataTransfer.getData("id");
        this.updateCellData2(subjectId, cellId, 2);
        $(targetElem).text(e.dataTransfer.getData("text"));
        // console.log("onDropHandler2_SubOne: " + e.dataTransfer.getData("text"));
    }

    updateCellData2 = (subId, cellId, pos) => {
        this.setState({ loader: 'block', opacity: 0.5 });
        var rqSaveCell = {
            cellId: cellId,
            slotId: this.props.periodId,
            examDt: this.props.examDt,
            subId: subId,
            tchId: this.props.tch,
            subPosition: pos,
            classSectionId: this.props.classSectionId,
            assetId: this.props.asset,
            link: this.props.link,
            notes: this.props.notes,
            invigilatorId: this.props.invigilator,
            evaluatorId: this.props.evaluator,
            verifierId: this.props.verifier,
            alongWithStudy: this.props.alongWithStudy,
            dayId: this.props.dayId,
            examId: this.props.examId
        };
        axios({
            url: httpURL + '/sms/exam/cell/savedtls',
            method: "POST",
            params: { screenId, 'rqSaveCell': JSON.stringify(rqSaveCell) },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                // console.log(this.props.periodId, this.props.dayId, this.props.sectionId, this.props.scheduleId);
                cellId = response.data.cellId;
                this.props.getCellDataObj(this.props.periodId, this.props.dayId, this.props.classSectionId, this.props.examScheduleId, this.props.examDt, this.props.alongWithStudy, cellId, this.props.examId);
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
    }
    removeSub(e, pos) {
        var cellId = $(e.target).closest(".droppable").attr('id');
        this.setState({ loader: 'block', opacity: 0.5 });
        var rqRemoveSubjectCell = {
            cellId: cellId,
            subPosition: pos
        };
        axios({
            url: httpURL + '/sms/exam/cell/removedtls',
            method: "POST",
            params: { 'rqRemoveSubjectCell': JSON.stringify(rqRemoveSubjectCell), screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                // console.log(this.props.periodId,this.props.dayId,this.props.sectionId,this.props.scheduleId);
                this.props.getCellDataObj(this.props.periodId, this.props.dayId, this.props.classSectionId, this.props.examScheduleId, this.props.examDt, this.props.alongWithStudy, 0, this.props.examId);
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
    }
    render() {

        return <div style={{ height: '80px' }} >
            <div className="droppable" id={this.props.cellData.cellId} droppable={true} onDragOver={event => this.dragOverHandler(event)} onDrop={event => this.dropHandler(event)} onMouseOver={event => this.onMoveMouse1(event)} onMouseLeave={event => this.onMouseLeave1(event)} style={{ width: '100%', height: '40px', position: 'relative', borderBottom: '1px solid #e4e4e4', lineHeight: '40px', textAlign: 'center' }}>
                <span className="sub1" id={this.props.cellData.dtls1.subId}>{this.props.cellData.dtls1.sub}</span>
                <button className="glyphicon glyphicon-remove" style={{ display: this.state.disp1, height: 'auto', right: 0, top: 0, position: 'absolute' }} onClick={event => this.removeSub(event, 1)}></button>
            </div>
            <div className="droppable" cell={JSON.stringify(this.props.cellData)} id={this.props.cellData.cellId} droppable={true} onDragOver={event => this.dragOverHandler2(event)} onDrop={event => this.dropHandler2(event)} style={{ width: '100%', height: '40px', display: 'flex', alignItems: 'flex-end', flexDirection: 'column', boxSizing: 'border-box' }}>
                <button
                    onClick={(event) => { this.props.showSlider(); this.props.setSlider(event); }}
                    className="glyphicon glyphicon-plus"
                    style={{ textAlign: 'right', height: 'auto', marginLeft: 'auto', marginTop: 'auto' }} ></button>

            </div>
        </div>
    }
}
class CellSubTwo extends Component {
    state = {
        tokenid: localStorage.getItem('tokenid'),
        disp1: 'none',
        disp2: 'none'
    }
    onMoveMouse1(event) {
        this.setState({ disp1: 'block' });
    }
    onMouseLeave1(event) {
        this.setState({ disp1: 'none' });
    }
    onMoveMouse2(event) {
        this.setState({ disp2: 'block' });
    }
    onMouseLeave2(event) {
        this.setState({ disp2: 'none' });
    }
    dragOverHandler1(e) {
        e.preventDefault();
    }

    dropHandler1(e) {
        e.preventDefault();
        // console.log(e.target);
        var cellId = $(e.target).closest(".droppable").attr('id');
        if (e.target.className == "droppable") {
            var targetElem = $(e.target).find(".sub1").attr('id');
        } else {
            var targetElem = $(e.target).closest(".sub1").attr('id');
        }
        var subjectId = e.dataTransfer.getData("id");
        this.updateCellData(subjectId, cellId, 1);
        document.getElementById(targetElem).innerHTML = e.dataTransfer.getData("text");
        $(e.target).innerHTML = e.dataTransfer.getData("text");
        // console.log("onDropHandler: " + e.dataTransfer.getData("text"));
    }

    dragOverHandler2(e) {
        e.preventDefault();
    }

    randomfunction(e) {
        var cellId = $(e.target).closest(".droppable").attr('id');
    }

    dropHandler2(e) {
        e.preventDefault();
        // console.log(e.target);
        var cellId = $(e.target).closest(".droppable").attr('id');
        if (e.target.className == "droppable") {
            // console.log($(e.target).closest(".droppable").attr('id'));
            // console.log($(e.target).find(".sub2").attr('id'));
            var targetElem = $(e.target).find(".sub2").attr('id');
            // console.log($(e.target).find(".sub2"));
        } else {
            // console.log($(e.target).closest(".droppable").attr('id'));
            // console.log($(e.target).closest(".sub2").attr('id'));
            var targetElem = $(e.target).closest(".sub2").attr('id');
            // console.log($(e.target).closest(".sub2"));
        }
        var subjectId = e.dataTransfer.getData("id");
        this.updateCellData(subjectId, cellId, 2);
        document.getElementById(targetElem).innerHTML = e.dataTransfer.getData("text");
        $(e.target).innerHTML = e.dataTransfer.getData("text");
        // console.log("onDropHandler: " + e.dataTransfer.getData("text"));
    }
    updateCellData = (subId, cellId, pos) => {
        this.setState({ loader: 'block', opacity: 0.5 });
        var rqSaveCell = {
            cellId: cellId,
            slotId: this.props.periodId,
            examDt: this.props.examDt,
            subId: subId,
            tchId: this.props.tch,
            subPosition: pos,
            classSectionId: this.props.classSectionId,
            assetId: this.props.asset,
            link: this.props.link,
            notes: this.props.notes,
            invigilatorId: this.props.invigilator,
            evaluatorId: this.props.evaluator,
            verifierId: this.props.verifier,
            alongWithStudy: this.props.alongWithStudy,
            dayId: this.props.dayId,
            examId: this.props.examId
        };
        axios({
            url: httpURL + '/sms/exam/cell/savedtls',
            method: "POST",
            params: { screenId, 'rqSaveCell': JSON.stringify(rqSaveCell) },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                cellId = response.data.cellId;
                this.props.getCellDataObj(this.props.periodId, this.props.dayId, this.props.classSectionId, this.props.examScheduleId, this.props.examDt, this.props.alongWithStudy, cellId, this.props.examId);
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
    }
    constructor(props) {
        super(props);
    }

    removeSub(e, pos) {
        var cellId = $(e.target).closest(".droppable").attr('id');
        this.setState({ loader: 'block', opacity: 0.5 });
        var rqRemoveSubjectCell = {
            cellId: cellId,
            subPosition: pos
        };
        axios({
            url: httpURL + '/sms/tt/subject/cell/removedtls',
            method: "POST",
            params: { 'rqRemoveSubjectCell': JSON.stringify(rqRemoveSubjectCell), screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                // console.log(this.props.periodId, this.props.dayId, this.props.sectionId, this.props.scheduleId);
                if (this.props.cellData.ctrSub == 1) {
                    cellId = 0;
                }
                this.props.getCellDataObj(this.props.periodId, this.props.dayId, this.props.classSectionId, this.props.examScheduleId, this.props.examDt, this.props.alongWithStudy, cellId, this.props.examId);
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
    }
    render() {
        return <div style={{ height: '80px' }}>
            <div className="droppable sub1Wrapper bottomOutline" id={this.props.cellData.cellId} droppable={true} onDragOver={event => this.dragOverHandler1(event)} onDrop={event => this.dropHandler1(event)} onMouseOver={event => this.onMoveMouse1(event)} onMouseLeave={event => this.onMouseLeave1(event)} >
                <span className="sub1" id={this.props.cellData.dtls1.subId}>{this.props.cellData.dtls1.sub}</span>
                <button className="glyphicon glyphicon-remove" style={{ display: this.state.disp1, height: 'auto', right: 0, top: 0, position: 'absolute' }} onClick={event => this.removeSub(event, 1)}></button>
            </div>
            <div className="droppable sub2Wrapper" id={this.props.cellData.cellId} droppable={true} onDragOver={event => this.dragOverHandler2(event)} onDrop={event => this.dropHandler2(event)} onMouseOver={event => this.onMoveMouse2(event)} onMouseLeave={event => this.onMouseLeave2(event)} >
                <span className="sub2" id={this.props.cellData.dtls2.subId}>{this.props.cellData.dtls2.sub}</span>
                <button className="glyphicon glyphicon-remove" style={{ display: this.state.disp2, textAlign: 'right', margin: 0, padding: 0, height: 'auto', right: 0, top: 0, position: 'absolute' }} onClick={event => this.removeSub(event, 2)}></button>
            </div>
        </div>
    }
}
