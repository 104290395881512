import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import Loader from "common/components/Loader";

import "../css/schoolProfile.css";
import { getLogoSvc, saveImgLogoSvc } from "./schoolProfileSvc";


const SchoolLogo = (props) => {
    const { schoolId } = props;

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [imgLogo, setImgLogo] = useState("");



    useEffect(() => {
        console.log("SchoolLogo component called"); getLogoSvc(handleLogoResponse);
    }, []);



    function handleLogoResponse(imageUrl) {
        var img = document.querySelector("#logo_img");
        img.src = imageUrl;
    }
    const onLogoChange = (e) => {
        // console.log(e.target.files[0].name);
        // console.log("src:" + URL.createObjectURL(e.target.files[0]));
        if (e.target.files && e.target.files[0]) {
            setImgLogo(URL.createObjectURL(e.target.files[0]));
            setLoader("block");
            setOpacity(0.5);
            saveImgLogoSvc(e.target.files[0], cbSaveimgLogoResponse);
        }
    };
    function cbSaveimgLogoResponse(data) {
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg);
        } else {
            toast.error(data.SvcMsg);
        }
        setLoader("none");
        setOpacity(1);
    }
    function handleLogoClick() {
        // console.log(document.getElementById("img_logo"));
        // document.getElementById("img_logo").dispatchEvent(new Event('click'));
        document.getElementById("img_logo").click();
    }
    const onError = () => {
        setImgLogo(window.appURL + "/no-image.png");
    };

    return (
        <div className="col-xl-12 col-ls-12 col-md-12 col-sm-12 col-xs-12 padding-remove"
            style={{
                cursor: (loader === "none") ? 'pointer' : 'none',
                pointerEvents: (loader === "none") ? 'auto' : 'none',
                opacity: opacity
            }}>
            <Loader loader={loader} position="fixed" />
            <form
                className="form form-vertical col-sm-12"
                style={{ margin: "2%", border: "1px solid #ddd", borderRadius: 6 }}
            >
                <div
                    className="EnquiryHead col-centered"
                    style={{
                        fontWeight: "bold",
                    }}
                >
                    <h4>Logo</h4>
                </div>
                <div className="row" style={{ background: "transparent" }}>
                    <label className="col-sm-2 control-label">Logo</label>
                    <div
                        style={{ padding: 0, display: "flex", width: 100, height: 100 }}
                        className="form-group col-sm-3"
                    >
                        <input
                            docid="3"
                            type="file"
                            id="img_logo"
                            name="img"
                            onChange={onLogoChange}
                            className="hidden"
                            accept="image/*"
                        />
                        <img
                            style={{
                                display: "block",
                                width: "100%",
                                maxWidth: "100%",
                                height: "auto",
                                border: 0,
                                objectFit: "fill",
                            }}
                            id="logo_img"
                            src={imgLogo}
                            alt="img"
                            onClick={(e) => handleLogoClick(e)}
                            onError={(e) => onError()}
                        />
                    </div>
                    <label className="col-sm-12" style={{ fontSize: "1rem" }}>
                        Logo should preferably be a square image
                    </label>
                </div>
            </form>

        </div>
    );
};

export default SchoolLogo;
