import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "common/js/header/header.js";
import StopRangeSlider from "./stopRangeSlider";
import StopRangeTable from "./stopRangeTable";
import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
const httpURL = lstModules['transport'].url + ':' + lstModules['transport'].port;
var tokenid = localStorage.getItem('tokenid');
function StopRange() {
  const [randNo, setRandNo] = useState(0);
  const [selObj, setSelObj] = useState({});
  const [selIdx, setSelIdx] = useState();
  const [lstFeeHead, setLstFeeHead] = useState([])
  const [loader, setLoader] = useState('none')
  const [selFeeHeadId, setSelFeeHeadId] = useState()
  function generateRandomNo() {
    var lRand = Math.floor(Math.random() * 999999);
    setRandNo(lRand);
  }
  function clearForm() {
    setSelObj({})
    setSelIdx(null)
    setSelFeeHeadId(null)
  }
  function getSelData(param) {
    setSelObj(param);
    setSelIdx(param.stopRangeId);
    setSelFeeHeadId(param.feeHeadId)
  }
  function addNew() {
    setSelObj({ stopRangeId: 0 });
    setSelIdx(null);
  }
  useEffect(() => {
    getLstFee(cbGetLstFee)
  }, [])
  function getLstFee(callBack) {
    axios({
      url: httpURL + '/sms/tpt/fee_head/list',
      method: 'POST',
      params: {},
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }).then((Response) => {
      var data = Response.data
      callBack(data)
    }).catch((error) => {
      // console.log('error' + error)
    });
  }
  function cbGetLstFee(data) {
    setLoader('none')
    if (data.SvcStatus === 'Success') {
      setLstFeeHead(data.lstFeeHead)
    }
    else {
      toast.error(data.SvcMsg)
    }
  }
  return (
    <div>
      <Header></Header>
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className="col-sm-10 col-centered TestTblWrapper">
        <div className="col-sm-8">
          <StopRangeTable key={randNo} getSelData={getSelData}></StopRangeTable>
          <div className="col-sm-1" onClick={(e) => addNew()}>
            <span id="addReadyId" title="Add" className="addIcon glyphicon glyphicon-plus-sign"></span>
          </div>
        </div>

        <div className="col-sm-4">
          <StopRangeSlider
            key={selIdx}
            clearForm={clearForm}
            stopObj={selObj}
            generateRandomNo={generateRandomNo}
            selFeeHeadId={selFeeHeadId}
            lstFeeHead={lstFeeHead}
          ></StopRangeSlider>
        </div>
      </div>
    </div>
  );
}
export default StopRange;
