import Header from "common/js/header/header";
import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "../../css/library.css";
import { getBookList, getSchoolList } from "./booksSvc";
import AddEditBook from "./addEditBook";
import PrintSlider from "./printSlider";
import BookCopies from "./bookCopies";
import PrintCopies from "./printCopies";
// import { useReactToPrint } from 'react-to-print';



const Books = () => {
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [lstBook, setLstBook] = useState([]);
    const [lstBooks, setLstBooks] = useState([]);
    const [lstSelect, setLstSelect] = useState([]);
    const [lstBookCopies, setLstBookCopies] = useState([]);
    const [selectedBook, setSelectedBook] = useState([]);
    const [tags, setTags] = useState("");
    const [showEditSlider, setShowEditSlider] = useState(false);
    const [showPrintSlider, setShowPrintSlider] = useState(false);
    const [showPrintOneSlider, setShowPrintOneSlider] = useState(false);
    const [showPrintAllCopies, setShowPrintAllCopies] = useState(false);
    const [bookNo, setBookNo] = useState(0);
    const [copyNo, setCopyNo] = useState(0);
    const [schoolId, setSchoolId] = useState(localStorage.getItem('schoolId'));
    const [schoolName, setSchoolName] = useState("School name");
    const [lstSchool, setLstSchool] = useState([]);
    const [showCopySlider, setShowCopySlider] = useState(false);
    const [showPrintCopies, setShowPrintCopies] = useState(false);
    const [title, setTitle] = useState("");
    const [author, setAuthor] = useState("");
    const [issueRestriction, setIssueRestriction] = useState("");
    const [addEditId, setAddEditId] = useState(0);

    const [isChecked, setIsChecked] = useState(false);
    const [key, setKey] = useState(0);




    useEffect(() => {
        if (key === 0) {
            setTableData();
            getSchoolList(schoolLstResponse, schoolId);
        }
        else {
            setLstBook(lstBook);
        }
    }, [key])

    const setTableData = () => {
        setLoader("block");
        setOpacity(0.5);
        getBookList(booklistResponse, tags);
    }

    const booklistResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            setLstBook(obj.lstBook);
            setLstBooks(obj.lstBook);
        }
        else {
            if (data.SvcMsg === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (data.SvcMsg === "Your Session is expired, login again") {
                toast.error(data.SvcMsg);
                // console.log("session expired");
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(data.SvcMsg);
            }
        }
        setLoader("none");
        setOpacity(1);
    }


    // const selectAllBooks = (e) => {
    //     setLstSelect([]);
    //     lstBook.map((book) => {
    //         handleSelect(book.bookNo, book.copyNo, book.title, book.author, book.issueRestriction);
    //     })

    // }
    const selectAllBooks = () => {
        setLstSelect(lstBook.map((book) => ({
            bookNo: book.bookNo,
            copyNo: book.copyNo,
            title: book.title,
            author: book.author,
            restriction: book.issueRestriction || "", // Handle empty restriction
        })));
    }



    const handleSelect = (bkNo, cpNo, title, author, issueRes) => {
        var restriction = issueRes;
        if (/^[-]+$/g.test(restriction)) {
            restriction = "";
        }
        const bookToSelect = { bookNo: bkNo, copyNo: cpNo, title, author, restriction };
        if (lstSelect.length === lstBook.length) {
            setIsChecked(false);
        } else if (lstSelect.length === lstBook.length - 1) {
            if (!(lstSelect.some((selected) => selected.bookNo === bookToSelect.bookNo && selected.copyNo === bookToSelect.copyNo)))
                setIsChecked(true);
        }

        setLstSelect((prevLstSelect) => {
            if (prevLstSelect.some((book) => isSameBook(book, bookToSelect))) {
                return prevLstSelect.filter((book) => !isSameBook(book, bookToSelect));
            } else {
                return [...prevLstSelect, bookToSelect];
            }
        });

    };

    const isSameBook = (book1, book2) => {
        return (
            book1.bookNo === book2.bookNo &&
            book1.copyNo === book2.copyNo &&
            book1.title === book2.title &&
            book1.author === book2.author
        );
    };

    const handleCheckboxChange = () => {
        if (!isChecked) {
            // If it's unchecked, check it and call selectAllBooks
            selectAllBooks();
            setKey(prevKey => prevKey + 1);
        } else {
            // If it's checked, uncheck it and set the list to an empty array
            setLstSelect([]);
            // setLstBook(lstBook);
            setKey(prevKey => prevKey + 1);
        }
        // Toggle the checkbox state
        setIsChecked(!isChecked);
    }

    const printBook = () => {
        if (lstSelect.length) {
            getSchoolName(parseInt(schoolId));
            openPrintSlider();
        }
        else {
            toast("Select atleast one book");
        }
    }

    const printOnlyThisBook = () => {

        setShowPrintCopies(false);
        setLstSelect([]);
        printOneBook(bookNo, copyNo, title, author, issueRestriction);
    }

    const printOneBook = (bkNo, cpNo, titl, athor, issueRest) => {

        var restriction = issueRest;
        if (/^[-]+$/g.test(restriction)) {
            restriction = "";
        }

        const bookToSelect = { bookNo: bkNo, copyNo: cpNo, title: titl, author: athor, restriction };
        setSelectedBook([...selectedBook, bookToSelect]);
        getSchoolName(parseInt(schoolId));
        setShowPrintOneSlider(true);
    }

    const filterTag = () => {
        // var searchTag = e.target.value;
        // setTags(e.target.value);
        setTableData();
    }

    const filterBookNo = (e) => {
        var searchBookNo = e.target.value;
        if (searchBookNo !== "") {
            const filterBookCopyNo = lstBooks.filter(book =>
                (book.bookNo + "-" + book.copyNo).toLowerCase().includes(searchBookNo.toLowerCase()));
            setLstBook(filterBookCopyNo);
        }
        else {
            setLstBook(lstBooks);
        }

    }

    const filterTitle = (e) => {
        var searchTitle = e.target.value;
        // setTitle(e.target.value);
        if (searchTitle !== "") {
            const filterTitle = lstBooks.filter(book =>
                book.title.toLowerCase().includes(searchTitle.toLowerCase()));
            setLstBook(filterTitle);
        }
        else {
            setLstBook(lstBooks);
        }

    }

    const filterAuthor = (e) => {
        var searchAuthor = e.target.value;
        if (searchAuthor !== "") {
            const filterAuthor = lstBooks.filter(book =>
                book.author.toLowerCase().includes(searchAuthor.toLowerCase()));
            setLstBook(filterAuthor);
        }
        else {
            setLstBook(lstBooks);
        }
    }

    const filterGenre = (e) => {
        var searchGenre = e.target.value;
        if (searchGenre !== "") {
            const filterGenre = lstBooks.filter(book =>
                book.genre.toLowerCase().includes(searchGenre.toLowerCase()));
            setLstBook(filterGenre);
        }
        else {
            setLstBook(lstBooks);
        }
    }

    const filterIssueRestriction = (e) => {
        var searchIssueRestriction = e.target.value;
        if (searchIssueRestriction !== "") {
            const filterIssueRestriction = lstBooks.filter(book =>
                book.issueRestriction.toLowerCase().includes(searchIssueRestriction.toLowerCase()));
            setLstBook(filterIssueRestriction);
        }
        else {
            setLstBook(lstBooks);
        }
    }

    const filterIssueStatus = (e) => {
        var searchIssueStatus = e.target.value;
        if (searchIssueStatus !== "") {
            const filterIssueStatus = lstBooks.filter(book =>
                book.status.toLowerCase().includes(searchIssueStatus.toLowerCase()));
            setLstBook(filterIssueStatus);
        }
        else {
            setLstBook(lstBooks);
        }
    }

    const editBook = (bNo, cNo, editId, titl, athor, restrict) => {
        setBookNo(bNo);
        setCopyNo(cNo);
        setTitle(titl);
        setAuthor(athor);
        setIssueRestriction(restrict);
        setAddEditId(editId);
        setShowEditSlider(true);
    }

    const closeEditSlider = () => {
        setShowEditSlider(false);
    }

    const deleteBook = () => {

    }

    const copyBook = (bkNo, cpNo, bkTitle) => {
        setBookNo(bkNo);
        setCopyNo(cpNo);
        setTitle(bkTitle);
        setShowCopySlider(true);

    }

    const closeCopySlider = () => {
        setShowCopySlider(false);
    }

    const schoolLstResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            setLstSchool(obj.lstSchool);
        }
        else {

            if (data.SvcMsg === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (data.SvcMsg === "Your Session is expired, login again") {
                toast.error(data.SvcMsg);
                // console.log("session expired");
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(data.SvcMsg);
            }
        }
    }

    const getSchoolName = (id) => {
        var school = "School";
        if (lstSchool)
            for (var i = 0; i < lstSchool.length; i++) {
                if (lstSchool[i].schoolId === id) {
                    school = lstSchool[i].school;
                }
            }
        setSchoolName(school);
    }

    const openPrintSlider = () => {
        setShowPrintSlider(true);
    }

    const closePrintSlider = () => {
        setShowPrintSlider(false);
        setShowPrintOneSlider(false);
        setShowPrintAllCopies(false);
        setSelectedBook([]);
    }


    const openPrintCopies = () => {
        // console.log("openPrintCopies method called");
        setShowPrintCopies(true);
    }

    const printAllCopies = async () => {
        // console.log("print all copies method called");
        await selectAllCopies();
        // console.log("lstBookCopies", lstBookCopies);
        getSchoolName(parseInt(schoolId));
        closePrintCopies();
        setShowPrintAllCopies(true);
        
    }

    const selectAllCopies = async () => {
        return new Promise((resolve) => {
            setLstBookCopies([]);
            const filterBookNo = lstBooks.filter(book => book.bookNo === bookNo);
            // console.log("selected books for print in selectAllCopies", filterBookNo);
            filterBookNo.map((book) => {
                var restriction = book.issueRestriction;
                if (/^[-]+$/g.test(restriction)) {
                    restriction = "";
                }
                // console.log("book in map function", book);
                const bookToSelect = { bookNo: book.bookNo, copyNo: book.copyNo, title: book.title, author: book.author, restriction };
                if (!(lstBookCopies.some((selected) => selected.bookNo === bookToSelect.bookNo && selected.copyNo === bookToSelect.copyNo))) {
                    setLstBookCopies((prevList) => [...prevList, bookToSelect]);
                }
            })
            resolve();
        })
    }

    const closePrintCopies = () => {
        setShowPrintCopies(false);
    }


    return (
        <div >
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="col-md-12" style={{ height: "5vh", width: "90%", marginTop: "5vh", marginLeft: "4%", marginBottom: 0, paddingBottom: 0 }} >
                <div className="col-md-6">
                    <div style={{ display: "flex", gap: 10 }}>
                        <input type="text" className="form-control inp-search"
                            onKeyUp={(e) => { setTags(e.target.value) }}
                            placeholder="Tags"
                        />
                        <button type="button" title="Search books" style={{ width: 100 }} onClick={filterTag} className="btn btn-success">Search</button>
                    </div>
                </div>
                <div className="col-md-2 padding-remove pull-right" style={{ textAlign: "right" }}>
                    <div className="col-md-6 padding-remove">
                        <button type="button" title="print selected books" style={{ width: 100 }} onClick={(e) => { editBook(0, 0, 0, "", "", "") }} className="btn btn-success">Add</button>
                    </div>
                    <div className="col-md-6 padding-remove">
                        <button type="button" title="print selected books" style={{ width: 100 }} onClick={printBook} className="btn btn-success">Print</button>
                    </div>
                </div>
            </div>
            <div className="col-md-12" style={{ height: "80vh", width: "90%", marginLeft: "5%" }}>
                <table className="tableLayout1 "  >
                    <thead>
                        <tr>
                            <th style={{ width: 75 }} >
                                <input type="text" className="form-control inp-search text-left"
                                    onKeyUp={(e) => { filterBookNo(e) }}
                                    placeholder="Book No"
                                />
                            </th>
                            <th style={{ width: 150 }} >
                                <input type="text" className="form-control inp-search text-left"
                                    onKeyUp={(e) => { filterTitle(e) }}
                                    placeholder="Title"
                                />
                            </th>
                            <th style={{ width: 150 }}>
                                <input type="text" className="form-control inp-search text-left"
                                    onKeyUp={(e) => { filterAuthor(e) }}
                                    placeholder="Author"
                                />
                            </th>
                            <th style={{ width: 38 }} className='text-center'>Cost</th>
                            <th style={{ width: 69 }} className='text-center'>Security Deposit</th>
                            <th style={{ width: 90 }} className='text-center'>
                                <input type="text" className="form-control inp-search"
                                    onKeyUp={(e) => { filterGenre(e) }}
                                    placeholder="Genre"
                                />
                            </th>
                            <th style={{ width: 60 }} className='text-center'>
                                <input type="text" className="form-control inp-search"
                                    onKeyUp={(e) => { filterIssueStatus(e) }}
                                    placeholder="Status"
                                />
                            </th>
                            <th style={{ width: 118 }} className='text-center'>Location</th>
                            <th style={{ width: 90 }} className='text-center'>
                                <input type="text" className="form-control inp-search"
                                    onKeyUp={(e) => { filterIssueRestriction(e) }}
                                    placeholder="Issue Restriction"
                                />
                            </th>
                            {/* <th style={{ width: 50 }} className='text-center'>Select</th> */}
                            <th style={{ width: 38 }} className='text-center' >
                                <input
                                    type="checkbox"
                                    // className="form-check-input"
                                    className=" inp-select"
                                    checked={isChecked}
                                    onChange={(e) => { handleCheckboxChange(e) }}
                                />
                            </th>
                            <th style={{ width: 118 }} className='text-center'>Action</th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv" style={{ height: "90%" }}>
                    <table className='tableLayout'>
                        <tbody id="booksTable">
                            {lstBook &&
                                lstBook.map((book, index) => (
                                    <tr key={index} >
                                        <td style={{ width: 75 }} className='text-center'>{book.bookNo + "-" + book.copyNo}</td>
                                        <td style={{ width: 150 }} className='text-left'>{book.title}</td>
                                        <td style={{ width: 150 }} className='text-left'>{book.author}</td>
                                        <td style={{ width: 38 }} className='text-right'>{book.cost}</td>
                                        <td style={{ width: 69 }} className='text-right'>{book.securityDeposit}</td>
                                        <td style={{ width: 90 }} className='text-center'>{book.genre}</td>
                                        <td style={{ width: 60 }} className='text-center'>{book.status}</td>
                                        <td style={{ width: 118 }} className='text-left'>{book.location}</td>
                                        <td style={{ width: 90 }} className='text-center'>{book.issueRestriction}</td>
                                        <td style={{ width: 38 }} className='text-center'>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={lstSelect.some((selected) => selected.bookNo === book.bookNo && selected.copyNo === book.copyNo)}
                                                onChange={(e) => handleSelect(book.bookNo, book.copyNo, book.title, book.author, book.issueRestriction)}
                                            />
                                        </td>
                                        <td style={{ width: 118 }} className='text-center'>
                                            <span className="glyphicon glyphicon-edit" title="edit" onClick={(e) => { editBook(book.bookNo, book.copyNo, 1, book.title, book.author, book.issueRestriction) }}></span>
                                            <span className="glyphicon glyphicon-trash" title="delete" onClick={(e) => { deleteBook() }}></span>
                                            <span className="glyphicon glyphicon-copy" title="copy" onClick={(e) => { copyBook(book.bookNo, book.copyNo, book.title) }}></span>
                                            <span className="glyphicon glyphicon-print" title="print" onClick={(e) => { printOneBook(book.bookNo, book.copyNo, book.title, book.author, book.issueRestriction) }}></span>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {showEditSlider &&
                <div className="col-xs-8 padding-remove TestSlider" style={{ width: "60%" }} >
                    <AddEditBook
                        bookNo={bookNo}
                        copyNo={copyNo}
                        closeSlider={closeEditSlider}
                        copyBook={copyBook}
                        setTableData={setTableData}
                        id={addEditId}
                        printBooks={openPrintCopies}
                    />
                </div>
            }
            {showPrintSlider &&
                <div className="col-xs-8 padding-remove TestSlider" style={{ width: "60%" }} >
                    <PrintSlider
                        closeSlider={closePrintSlider}
                        schoolName={schoolName}
                        lstSelect={lstSelect}
                    />
                </div>
            }
            {showPrintOneSlider &&
                <div className="col-xs-8 padding-remove TestSlider" style={{ width: "60%" }} >
                    <PrintSlider
                        closeSlider={closePrintSlider}
                        schoolName={schoolName}
                        lstSelect={selectedBook}
                    />
                </div>
            }
            {showPrintAllCopies &&
                <div className="col-xs-8 padding-remove TestSlider" style={{ width: "60%" }} >
                    <PrintSlider
                        closeSlider={closePrintSlider}
                        schoolName={schoolName}
                        lstSelect={lstBookCopies}
                    />
                </div>
            }
            {showCopySlider &&
                <BookCopies
                    closeCopySlider={closeCopySlider}
                    bookNo={bookNo}
                    copyNo={copyNo}
                    title={title}
                    setTableData={setTableData}
                />
            }
            {showPrintCopies &&
                <PrintCopies
                    printOneBook={printOnlyThisBook}
                    printAllCopies={printAllCopies}
                    closePrintCopies={closePrintCopies}
                />
            }

        </div>
    )
}

export default Books;