import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import DatePicker from 'react-datepicker'
import Header from 'common/js/header/header.js'
import 'react-datepicker/dist/react-datepicker.css'
import 'common/css/master.css'
import { getLstMonthlySummary } from './summaryServices.js'

const MonthlySummary = () => {
  const [state, setState] = useState({
    loader: 'none',
    opacity: 1
  })
  // const [dt, setDt] = useState('')
  // const [dispDt, setDispDt] = useState('')
  const [lstMonthlySummary, setLstMonthlySummary] = useState([])
  const [totalExp, setTotalExp] = useState(0)
  const [totalRec, setTotalRec] = useState(0)
  const [totalDiff, setTotalDiff] = useState(0)
  const [totalFeeHead, setTotalFeeHead] = useState({})
  const [lstFeeHeads, setLstFeeHeads] = useState([])
  useEffect(() => {
    var dispDate = convert(new Date())
    getMonthSummary()
    // setDt(new Date())
    // setDispDt(dispDate)
  }, [])

  function getMonthSummary() {
    setState({
      loader: 'block',
      opacity: 0.7
    })

    getLstMonthlySummary( handleMonthlySummaryResponse)
    // getFakeMonSummary();
  }
  const getFakeMonSummary = () => {
    var obj = {
      status: 'Success',
      lstMonthlySummary: [
        {
          monthId: 11,
          month: 'Nov',
          exp: 25000000,
          rec: 20000000,
          diff: 50000000,
          fee: {
            Annual: 16000000,
            Transport: 2000000,
            Sports: 1000000
          }
        },
        {
          monthId: 10,
          month: 'Oct',
          exp: 6000000,
          rec: 5000000,
          diff: 1000000,
          fee: {
            Annual: 16000000,
            Transport: 2000000,
            Sports: 1000000
          }
        }
      ],
      totalExp: 31000000,
      totalRec: 25000000,
      totalDiff: 51000000,
      feeTotal: { Annual: 32000000, Transport: 4000000, Sports: 2000000 }
    }
    setLstMonthlySummary(obj.lstMonthlySummary)
    // console.log(obj.lstMonthlySummary[0].fee)
    setLstFeeHeads(Object.keys(obj.lstMonthlySummary[0].fee))
    setState({
      loader: 'none',
      opacity: 1
    })
    setTotalFeeHead(obj.feeTotal)

    setTotalExp(obj.totalExp)
    setTotalRec(obj.totalRec)
    setTotalDiff(obj.totalDiff)
  }
  const handleDtChange = date => {
    // setDt(date)
    // setDispDt(convert(date))
    // console.log('handleDtChange() called')
    // console.log(date)
    // getMonthSummary()
    // getFakeMonSummary();
  }
  function convert(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2)
    // console.log([day, mnth, date.getFullYear()].join('-'))
    return [day, mnth, date.getFullYear()].join('-')
  }
  function handleMonthlySummaryResponse(data) {
    var obj = data
    setState({
      loader: 'none',
      opacity: 1
    })
    if (obj.status == 'Success') {
      setLstMonthlySummary(obj.lstMonthlySummary)
      // setLstFeeHeads(Object.keys(obj.lstMonthlySummary[0].fee));
      setLstFeeHeads(obj.lstLabels)
      setTotalFeeHead(obj.feeTotal)
      setTotalExp(obj.totalExp)
      setTotalRec(obj.totalRec)
      setTotalDiff(obj.totalDiff)

      // console.log('obj.feeTotal = ' + obj.feeTotal)
    } else if (obj.status == 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(obj.message)
      }
    } else {
      toast.error('Service failed ' + obj.message)
    }
  }
  const navClassSummary = (e, monthId, month) => {
    e.preventDefault()
    // localStorage.setItem('month', month)
    // localStorage.setItem('monthId', monthId)
    let newUrl = "/classSummary?month=" + month + "&monthId=" + monthId;
    window.location.href = newUrl;
  }
  const getWingId = wingId => {
    // console.log('wingId = ' + wingId)
  }
  return (
    <div style={{ height: '100%' }}>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        style={{
          display: state.loader,
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%'
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <div
        className='mainContainer report'
        style={{ pointerEvents: state.opacity == 1 ? 'auto' : 'none' }}
      >
        <div className='col-md-12 col-centered padding-remove'>
          <div className='col-centered col-sm-2'>
            <h6>
              Monthly Summary
              <div
                className='col-sm-4 pull-right padding-remove'
                style={{ top: -7 }}
              >
                {/* {dt && (
                  <DatePicker
                    id='dt-picker'
                    className='form-control'
                    placeholderText='Enter Date'
                    selected={dt}
                    onChange={date => handleDtChange(date)}
                    dateFormat='dd-MM-yyyy'
                    dropdownMode='select'
                    autoComplete='off'
                    showMonthDropdown
                    showYearDropdown
                    adjustDateOnChange
                  />
                )} */}
              </div>
            </h6>
          </div>
          <div className='col-md-12'>
            <table className='tableLayout1' id='summaryTableHead'>
              <colgroup>
                <col width='30' />
                <col width='40' />
                <col width='40' />
                <col width='40' />
                {lstFeeHeads && lstFeeHeads.length > 0 && lstFeeHeads.map((obj, i) => (
                  <col key={i} width='40' />
                ))}
              </colgroup>
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Expected</th>
                  <th>Received</th>
                  <th>Difference</th>
                  {lstFeeHeads && lstFeeHeads.length > 0 && lstFeeHeads.map((obj, i) => (
                    <th key={i}>{obj}</th>
                  ))}
                </tr>
              </thead>
            </table>
            <div className='tableDiv'>
              <table id='summaryTable' className='tableLayout'>
                <colgroup>
                  <col width='30' />
                  <col width='40' />
                  <col width='40' />
                  <col width='40' />
                  {lstFeeHeads && lstFeeHeads.map((obj, i) => (
                    <col key={i} width='40' />
                  ))}
                </colgroup>
                <tbody id='summaryTableBody'>
                  {lstMonthlySummary &&
                    lstMonthlySummary.length > 0 &&
                    lstMonthlySummary.map((obj, i) => (
                      <tr key={i} id={obj.monthId}>
                        <td
                          className='text-center'
                        /*  */
                        >
                          <a
                            onClick={e => {
                              e.preventDefault()
                              navClassSummary(e, obj.monthId, obj.month)
                            }}
                            href='#'
                          >
                            {obj.month}
                          </a>
                        </td>
                        <td className='text-right'>
                          {obj.exp.toLocaleString('en-IN')}
                        </td>
                        <td className='text-right'>
                          {obj.rec.toLocaleString('en-IN')}
                        </td>
                        <td className='text-right'>
                          {obj.diff.toLocaleString('en-IN')}
                        </td>
                        { lstFeeHeads && lstFeeHeads.length > 0 && lstFeeHeads.map((feeObj, j) => (
                          <td key={j} className='text-right'>
                            {obj.fee[feeObj] ? obj.fee[feeObj].toLocaleString('en-IN') : 0}
                          </td>
                        ))}
                      </tr>
                    ))}
                  <tr>
                    <td style={{ fontWeight: 'bold' }} className='text-center'>
                      Total
                    </td>
                    <td style={{ fontWeight: 'bold' }} className='text-right'>
                      {totalExp ? totalExp.toLocaleString('en-IN') : 0}
                    </td>
                    <td style={{ fontWeight: 'bold' }} className='text-right'>
                      {totalRec ? totalRec.toLocaleString('en-IN') : 0}
                    </td>
                    <td style={{ fontWeight: 'bold' }} className='text-right'>
                      {totalDiff ? totalDiff.toLocaleString('en-IN') : 0}
                    </td>
                    { lstFeeHeads && lstFeeHeads.map((feeHdObj, k) => (
                      <td
                        key={k}
                        style={{ fontWeight: 'bold' }}
                        className='text-right'
                      >
                        {parseInt(totalFeeHead[feeHdObj]).toLocaleString(
                          'en-IN'
                        )}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MonthlySummary
