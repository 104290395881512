import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import DriverVehicleType from "./driverVehicleType";
import Header from "common/js/header/header.js";
import DriverTable from "./driverTable";
import DriverSlider from "./driverSlider";
import '../css/transport.css';

function Driver(){
    const [selStaff,setSelStaff]=useState(-1)
    const [sId,setSId]=useState(0)
    function getSelStaff(param,s_Id){
        setSelStaff(param)
        setSId(s_Id)
        // console.log(param+'  '+s_Id)
    }
    function closeSlider(){
        setSId(0)
        setSelStaff(null)
    }
    return(<div>
        <ToastContainer autoClose={3000} hideProgressBar />        
        <Header></Header>
        <div className="TestTblWrapper">
        <DriverTable
        getSelStaff={getSelStaff}
        />
        <div         
        className='TestSlider'
        style={{display:sId===1?'block':'none'}}>
        <DriverSlider
        key={selStaff}
        closeSlider={closeSlider}
        selStaffId={selStaff}
        />
        </div>
        <div         
        className='TestSlider'
          style={{display:sId===2?'block':'none'}}>
        <DriverVehicleType
        key={selStaff}
        closeSlider={closeSlider}
        selStaffId={selStaff}
        />
        </div>

        </div>
    </div>)
}
export default Driver;
