
import React, { useEffect, useState } from 'react';
import { Document, Page, PDFViewer, Text, View, Image, Font } from '@react-pdf/renderer';
import { StyleSheet } from '@react-pdf/renderer';

import { toast } from "react-toastify";
import { styles, scoreSectionStyle } from './examReportCardStyles.js';
import ScholasticSection from './schoSection.js';
import CoScholasticSection from './coSchoSection.js';
import SummarySection from './summarySection.js';




const ScoreSection = (props) => {
    const { studentHeader, studentSummary, lstScholastic, lstCoscholastic, exam } = props;


    // useEffect(() => {
    //     // console.log("scoreSection.js called");
    //     // console.log("studentSummary:", JSON.stringify(props.studentSummary, null, 2));
    //     // console.log("lstScholastic:", JSON.stringify(props.lstScholastic, null, 2));
    //     // console.log("lstCoscholastic:", JSON.stringify(props.lstCoscholastic, null, 2));
    //     // console.log("exam:", JSON.stringify(props.exam, null, 2));
    // }, [studentSummary, lstScholastic, lstCoscholastic, exam]);


    return (
        <View style={scoreSectionStyle.container}>
            <View style={scoreSectionStyle.scho_section_header}>
                <Text style={scoreSectionStyle.section_header_text}>SCHOLASTIC AREAS</Text>
            </View>
            <View style={scoreSectionStyle.marks_section}>
                <View style={scoreSectionStyle.scho_section}>
                    <ScholasticSection
                        lstScholastic={lstScholastic}
                        exam={exam}
                    />
                    <SummarySection
                        studentSummary={studentSummary}
                    />
                </View>
                <View style={scoreSectionStyle.coscho_section}>
                    <CoScholasticSection
                        studentHeader={studentHeader}
                        lstCoscholastic={lstCoscholastic}
                        studentSummary={studentSummary}
                        exam={exam}
                    />
                </View>
            </View >
        </View >
    );
}

export { ScoreSection };