import React, { useEffect, useState } from 'react';
import "../../../common/css/master.css";
import "../css/header.css";
import { ToastContainer, toast } from "react-toastify";
import { NavLink } from 'react-router-dom';
import '../../../common/bootstrap/css/bootstrap.min.css';
import $ from 'jquery';
// import DarkModeToggle from '../../../common/js/darkMode/DarkModeToggle';
import DarkModeToggle from 'common/js/darkMode/DarkModeToggle';
import Changepassword from './ChangePassword';
import { saLogoutSvc } from './saHeaderSvc';

const SAHeader = () => {
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
    const [activePage, setActivePage] = useState("");

    useEffect(() => {
        // console.log("localStorage.getItem('module')",localStorage.getItem('module'));
        const navigationMenu = document.querySelector('ul.navigationMenu');
        const activeLink = navigationMenu.querySelector('li a.active');
        const activePage = activeLink ? activeLink.textContent : '';
        setActivePage(activePage);
    }, []);

    const closeChangePassword = () => {
        // console.log('closeChamgePassword triggered');
        setIsChangePasswordOpen(false);
    };

    const openChangePassword = () => {
        // console.log('openChamgePassword triggered');
        setIsChangePasswordOpen(true);
    }

    const logout = () => {
        // console.log('logout triggered');
        setLoader("block");
        setOpacity(0.5);
        saLogoutSvc(saLogoutResponse);
    }

    const saLogoutResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            toast.success(obj.message);
            localStorage.setItem("isRememberMeSelected", false);
            setTimeout(function () { window.location.href = "/sa"; }, 2000);
        }
        else {
            if (data.message === "You are not logged in") {
                localStorage.setItem("isRememberMeSelected", false);
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else if (data.message === "Your Session is expired, login again") {
                toast.error(data.message);
                localStorage.setItem("isRememberMeSelected", false);
                // console.log("session expired");
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else {
                toast.error(data.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const toggleClass = (e) => {
        e.preventDefault();
        document.querySelector('.header').classList.toggle('openMenu');
        // console.log(document.querySelector('.header'))
    };


    return (
        <>
            <div className="header">
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col col-sm-1 hamberger" style={{ width: '4%' }}>
                            <i className="fa fa-bars" onClick={(e) => toggleClass(e)}></i>
                        </div>
                        <div className='col-sm-1 padding-remove pull-right'>
                            <div className="col col-sm-12 headerWingWrap pull-right" style={{ width: '100%' }}>
                                <i
                                    className="col-sm-1 fa fa-key"
                                    title="Change Password"
                                    onClick={openChangePassword}>
                                </i>
                                <i className="col-sm-1 fa fa-sign-out" onClick={logout}>
                                </i>
                            </div>
                        </div>
                        <div className='col-sm-3 padding-remove '>
                        </div>
                        
                        <div className="col col-sm-offset-3 col-sm-3 pageHeader  " style={{ width: '18%' }}>
                            <div className="pageName">{activePage}</div>
                        </div>
                    </div>
                </div>
                <ul className="navigationMenu">
                    <DarkModeToggle />
                    <li>
                        <NavLink to="/sa/approveSchools" activeClassName="active">
                            Approve School
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/sa/settlement" activeClassName="active">
                            Settlement
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/sa/apikeys" activeClassName="active">
                            Api Keys
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div className=" col-sm-4 col-centered pass padding-remove"
                style={{ display: isChangePasswordOpen === true ? 'block' : 'none' }}>
                <Changepassword
                    // key={setIsChangePasswordOpen} 
                    closePass={closeChangePassword} />
            </div>
        </>
    );
}

export default SAHeader;