import React, { Component, useState, useEffect } from "react";
import "../css/login.css";
import "../../../common/css/master.css";
import { ToastContainer, toast } from "react-toastify";
import $ from "jquery";
import { loginSvc, forgotPasswordSvc } from "./loginSvc";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isRememberMeSelected, setIsRememberMeSelected] = useState(false);
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [userDtls, setUserDtls] = useState([]);

    useEffect(() => {
        if (localStorage.getItem('isRememberMeSelected') !== null) {
            // console.log("localStorage.getItem('isRememberMeSelected') ",localStorage.getItem('isRememberMeSelected') );
            if (localStorage.getItem('isRememberMeSelected') === "true"){
                // toast("You are already logged in.");
                redirectToApproveSchools();
            }
        }
        // console.log("value of loader", loader);
    }, [])

    const redirectToApproveSchools = () => {
        // console.log("redirectTo called");
        setTimeout(function () {
            window.location.href = "/sa/approveSchools" ;
        }, 2000);
    };

    const login = () => {
        // console.log("login method called");
        var loginObj = {
            email: email,
            password: password,
        };
        if (!email.trim() || !password.trim()) {
            if (!email.trim() && !password.trim())
                toast.error('Please fill in all the fields');
            else if (!email.trim())
                toast.error('Please fill your username');
            else
                toast.error('Please fill your password');
            return;
        }
        setLoader("block");
        setOpacity(0.5);

        loginSvc(loginObj, loginResponse);
    }

    const loginResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            toast.success(obj.message);
            setUserDtls(obj.userDtls);
            localStorage.setItem("sysopstokenid", obj.tokenid);
            localStorage.setItem("email", email);
            // console.log("obj.tokenid", obj.tokenid);
            // console.log("localstorage.getItem('tokenid')", localStorage.getItem('tokenid'));
            // console.log("localstorage.getItem('email')", localStorage.getItem('email'));
            localStorage.setItem("isRememberMeSelected", isRememberMeSelected);

            redirectToApproveSchools();
        }
        else {
            toast.error(obj.message);
        }
        setLoader("none");
        setOpacity(1);
    }

    const forgotPassword = () => {
        // console.log("forgotPassword called");
        if (!email.trim()) {
            toast.error('Please fill your username');
            return;
        }
        setLoader("block");
        setOpacity(0.5);
        forgotPasswordSvc(email, forgotPasswordResponse);
    }

    const forgotPasswordResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            toast.success(obj.message);
        }
        else {
            toast.error(obj.message);
        }
        setLoader("none");
        setOpacity(1);
    }


    const showPassword = () => {
        // console.log("showPassword called");
        $(".toggle-password").toggleClass("fa-eye fa-eye-slash");

        var input = $("#pwd");
        input.attr("type") === "password"
            ? input.attr("type", "text")
            : input.attr("type", "password");
    };


    return (
        <>
            <div className="wrapper-login">



                <ToastContainer autoClose={3000} hideProgressBar />
                <div
                    style={{
                        position: "fixed",
                        zIndex: 10000,
                        width: 100,
                        height: 100,
                        marginLeft: -50,
                        marginTop: -50,
                        left: "50%",
                        top: "50%",
                        display: loader,
                    }}
                >
                    <img
                        className="loderImg"
                        src={require("../../../common/images/loader.gif")}
                        alt="Loader..."
                    />
                </div>

                <div className="col-xs-8">
                    <div className="col-xs-5 textWrap">
                        <img
                            className="loginImg"
                            style={{
                                width: 300,
                                height: "auto",
                                justifyContent: "center",
                                display: "flex",
                            }}
                            src={require("../images/bg.jpeg")}
                            alt="Background"
                        />
                    </div>
                    <div className="col-xs-7 imageWrap">
                    </div>
                </div>
                <div className="formContent">
                    <div
                        className="form-wrapper"
                        style={{ opacity: opacity }}
                    >
                        <div style={{ marginBottom:50 }}>
                            <h3 style={{ display: "inline" }}>Welcome To </h3>
                            <h4 style={{ display: "inline" }}>SysOps</h4>
                        </div>
                        <form id="loginForm1">
                            <div
                                className=" input-group"
                                style={{ marginBottom: 20, width: "100%" }}
                            >
                                <span className="has-float-label">
                                    <input
                                        type="email"
                                        autoComplete="off"
                                        className="form-control"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Username"
                                        required
                                    />
                                    <label >Username</label>
                                </span>
                            </div>
                            <div
                                className="form-group input-group"
                                style={{ marginTop: 40, marginBottom: 40, width: "100%" }}
                            >
                                <span className="has-float-label">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="pwd"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Password"
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter")
                                                login()
                                        }}
                                        required
                                    />
                                    <label >Password</label>
                                </span>
                                <span
                                    toggle="#password-field"
                                    onClick={showPassword}
                                    className="fa fa-fw fa-eye field_icon toggle-password"
                                ></span>
                            </div>
                            <div
                                className="form-group input-group"
                                style={{ marginTop: 40, marginBottom: 40, width: "100%" }}
                            >
                                <input
                                    type="checkbox"
                                    selected={isRememberMeSelected}
                                    onChange={(e) => setIsRememberMeSelected(e.target.checked)}
                                />
                                <label htmlFor="rememberMe" style={{ marginLeft: 10 }}>
                                    Remember me
                                </label>
                            </div>
                            <div className="text-center loginButtons">
                                <button
                                    type="button"
                                    title="Login"
                                    className="btn btn-info text-center"
                                    onClick={login}
                                >
                                    Login{" "}
                                    <span
                                        className="glyphicon glyphicon-arrow-right arrowShow"
                                        aria-hidden="true"
                                    ></span>
                                </button>
                            </div>
                            <div className="loginWrap">
                                <div className="form-group forgotPass">
                                    <span onClick={() => forgotPassword()} >
                                        Forgot password ?
                                    </span>
                                </div>
                            </div>
                        </form>
                        <div className="copyright">
                            <a href="https://kmsgtech.com/" target="blank">
                                Powered By KMSG Technologies
                            </a>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default Login;