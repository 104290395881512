import React, { useState, useEffect } from "react";
import Header from "common/js/header/header";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ExamSubjectsTable from "./examSubjectsTable";
import ExamSubjectsSlider from "./examSubjectsSlider";
import { getExamsListSvc } from "./examSubjectsSvc";


const ExamSubjects = () => {

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [lstExams, setLstExams] = useState([]);
    const [examId, setExamId] = useState(0);
    // const [examName, setExamName] = useState("");
    const [cls, setCls] = useState("");
    const [clsId, setClsId] = useState(0);
    const [section, setSection] = useState("");
    const [sectionId, setSectionId] = useState(0);
    const [isSliderOpen, setIsSliderOpen] = useState(false);
    const [tableKey, setTableKey] = useState(0);

    useEffect(() => {
        // console.log("examSubjects.js called");
        getExamList();
    }, []);

    const getExamList = () => {
        // console.log("getEXamList called");
        setLoader("block");
        setOpacity(0.5);
        getExamsListSvc(examListResponse);
    }

    const examListResponse = (data) => {
        // console.log("examListResponse called, data", data)
        let obj = data;
        if (obj.status === 'Success') {
            // console.log("successful response in examListResponse");
            setLstExams(obj.lstExam);
            if (obj.lstExam && obj.lstExam.length) {
                setExamId(obj.lstExam[0].examId);
                // setExamName(obj.lstExam[0].exam);
            }
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader('none');
        setOpacity(1);
    }

    const openSlider = (clsSec) => {
        // console.log("openSlider called");
        setSectionId(clsSec.sectionId);
        setClsId(clsSec.clsId);
        setCls(clsSec.cls);
        setSection(clsSec.section);
        setIsSliderOpen(true);
        setOpacity(0.7);
    }

    const closeSlider = () => {
        // console.log("closeSlider called");
        setOpacity(1);
        setTableKey((prevKey) => prevKey + 1);
        setIsSliderOpen(false);
    }

    const handleExamChange = (eId) => {
        // console.log("handleExamChange called, examId passed", eId);
        setExamId(eId);
        // setExamName(getExamById(eId));
    }

    // const getExamById = (examId) => {
    //     for (let i = 0; i < lstExams.length; i++) {
    //         if (lstExams[i].examId === examId) {
    //             return lstExams[i].exam;
    //         }
    //     }
    //     return "";
    // }

    return (
        <div>
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="mainContainer" style={{ opacity: opacity }}>
                <div className="col-xs-6 col-centered padding-remove">
                    <div className="col-xs-12 padding-remove">
                        <div className="col-sm-6">
                            <select
                                className="form-control"
                                value={examId}
                                onChange={(e) => handleExamChange(e.target.value)}
                            >
                                <option value={0}>Select Exam</option>
                                {lstExams && lstExams.length ?
                                    lstExams.map((exam) => (
                                        <option
                                            key={exam.examId}
                                            value={exam.examId}>
                                            {exam.exam}
                                        </option>
                                    )) : null
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-xs-12 padding-remove">
                        <ExamSubjectsTable
                            key={examId}
                            examId={examId}
                            tableKey={tableKey}
                            setLoader={setLoader}
                            setOpacity={setOpacity}
                            selectSubjects={openSlider}
                        />
                    </div>
                </div>
            </div>
            {isSliderOpen ?
                <div className='slider1-60'>
                    <ExamSubjectsSlider
                        key={sectionId}
                        examId={examId}
                        // examName={examName}
                        cls={cls}
                        clsId={clsId}
                        section={section}
                        sectionId={sectionId}
                        closeSlider={closeSlider}
                    />
                </div>
                : null
            }
        </div>
    );
}

export default ExamSubjects;