import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { getStaffListSvc } from './EnqSvc.js'
import { assignStaffSvc, unassignStaffSvc } from './EnquirySvc.js'

const AssignCounsellor = props => {
  const [selStaffId, setSelStaffId] = useState()
  const [lstStaff, setLstStaff] = useState([])
  let isMounted = false;
  const controller = new AbortController()
  const signal = controller.signal;

  useEffect(() => {
    isMounted = true
    getStaffListSvc(handleStaffResponse)
    return()=>{
      isMounted = false
      controller.abort()
    }
  }, [])

  function handleStaffResponse (pLstStaff) {
    if(isMounted)
      setLstStaff(pLstStaff)
  }

  function handleLstSelStaffChange (staffId) {
    setSelStaffId(staffId)
    // console.log('Value printed in setSelStaffId' + staffId)
  }

  function assignStaff () {
    if (props.lstSelEnqId.length === 0) {
      toast.warn('Please select atleast one enquiry')
      return
    }
    if (!selStaffId) {
      toast.warn('Please select the counsellor')
      return
    }
    assignStaffSvc(props.lstSelEnqId, selStaffId, cbAssignResponse)
  }

  function cbAssignResponse (data) {
    props.generateRandomNo()
    props.refreshScreen()
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
    } else {
      if (data.SvcMsg === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (data.SvcMsg === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(data.SvcMsg)
      }
    }
  }

  function unassignStaff () {
    if (props.lstSelEnqId.length === 0) {
      toast.warn('Please select atleast one enquiry')
      return
    }
    unassignStaffSvc(props.lstSelEnqId, cbUnassignResponse)
  }

  function cbUnassignResponse (data) {
    props.generateRandomNo()
    props.refreshScreen()
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  return (
    <div className='form form-horizontal'>
      <div
        className='col-md-offset-5 col-md-5'
        style={{ border: '1px solid #ccc', paddingTop: 6, width: '49%' }}
      >
        <div className='form-group'>
          <label className='control-label col-sm-3'>
            Counsellor<span className='red-color'>*</span>
          </label>
          <div className='col-sm-9  padding-remove'>
            <div className='form-group col-sm-7'>
              <select
                className='form-control'
                onChange={e => handleLstSelStaffChange(e.target.value)}
              >
                <option value={0}>Counsellor</option>
                {lstStaff.map((local, idx) => {
                  return (
                    <option key={idx} value={local.staffId}>
                      {local.staff}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='col-sm-6'>
              <button
                type='button'
                className='btn btn-info '
                id='assignCounsellor'
                onClick={e => assignStaff()}
              >
                Assign
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-1' style={{ marginTop: '1%' }}>
        <button
          type='button'
          className='btn btn-info'
          id='unassignCounsellor'
          onClick={e => unassignStaff()}
        >
          Unassign
        </button>
      </div>
    </div>
  )
}
export default AssignCounsellor
