import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'

const FeeDueSlider = props => {
  /* constructor(props) {
        super(props); */

  var initialState = {
    feeDue: 0,
    feeDueObj: props.feeDueObj,
    reminder1: 0,
    reminder2: 0,
    overdue1: 0,
    overdue2: 0,
    lstdt: props.lstdt
  }
  const [state, setState] = useState(initialState)
  // }

  const handleSubmit = e => {
    e.preventDefault()

    var feeDue = state.feeDue
    var reminder1 = state.reminder1
    var reminder2 = state.reminder2
    var overdue1 = state.overdue1
    var overdue2 = state.overdue2
    var flgEmail = state.flgEmail
    var flgSms = state.flgSms
    var flgWhatsapp = state.flgWhatsapp
    var flgAutoCall = state.flgAutoCall
    var wingId = props.wingId
    var wing = props.wing
    if (feeDue === 0) {
      toast.warning('Please Enter Fee Due Date')
      return false
    }
    // if (reminder1 == 0) {
    //     toast.warning("Please Enter Reminder 1 Date");
    //     return false;
    // }
    // if (reminder2 == 0) {
    //     toast.warning("Please Enter Reminder 2 Date");
    //     return false;
    // }
    // if (overdue1 == 0) {
    //     toast.warning("Please Enter Overdue 1 Date");
    //     return false;
    // }
    // if (overdue2 == 0) {
    //     toast.warning("Please Enter Overdue 2 Date");
    //     return false;
    // }
    var objToSave = {
      wingId,
      wing,
      feeDue,
      reminder1,
      reminder2,
      overdue1,
      overdue2,
      flgEmail,
      flgSms,
      flgWhatsapp,
      flgAutoCall
    }
    props.saveFormData(objToSave)
  }

  useEffect(() => {
    var objData = props.feeDueObj
    // console.log(objData)
    setState({
      ...state,
      wingId: objData.wingId,
      wing: objData.wing,
      feeDue: objData.feeDue,
      reminder1: objData.reminder1,
      reminder2: objData.reminder2,
      overdue1: objData.overDueNotice1,
      overdue2: objData.overDueNotice2,
      flgEmail: objData.flgEmail,
      flgSms: objData.flgSms,
      flgWhatsapp: objData.flgWhatsapp,
      flgAutoCall: objData.flgAutoCall,
      loader: 'block',
      opacity: 0.5
    })
  }, [props])
  /*     componentDidMount = () => {
    
            var objData = this.props.lstMonthlydata;
            // console.log(objData)
            this.setState({
                wingId: objData.wingId,
                wing: objData.wing,
                feeDue: objData.feeDue,
                reminder1: objData.reminder1,
                reminder2: objData.reminder2,
                overdue1: objData.overDueNotice1,
                overdue2: objData.overDueNotice2,
                loader: 'block',
                opacity: 0.5
            })
    
        } */
  function getObjData (wingId) {
    for (var i = 0; i < props.lstMonthlydata.length; i++) {
      if (parseInt(wingId) === props.lstMonthlydata[i].wingId)
        return props.lstMonthlydata[i]
    }
  }
  /* componentDidUpdate = (prevProps) => {
        if (prevProps.wingId !== this.props.wingId) {
            if (this.props.wingId !== 0) {
                var objData = this.getObjData(this.props.wingId);
                this.setState({
                    wingId: objData.wingId,
                    wing: objData.wing,
                    feeDue: objData.feeDue,
                    reminder1: objData.reminder1,
                    reminder2: objData.reminder2,
                    overdue1: objData.overDueNotice1,
                    overdue2: objData.overDueNotice2
                })

            }
            else {
                this.setState({
                    feeDue: 0,
                    reminder1: 0,
                    reminder2: 0,
                    overdue1: 0,
                    overdue2: 0
                })
            }
        }
    } */

  const handleInputChange = e => {
    e.preventDefault()
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }
  function handleEmailCBoxChange (e) {
    setState({ ...state, flgEmail: e.target.checked })
  }
  function handleSmsCBoxChange (e) {
    setState({ ...state, flgSms: e.target.checked })
  }
  function handleWappCBoxChange (e) {
    setState({ ...state, flgWhatsapp: e.target.checked })
  }
  function handleACCBoxChange (e) {
    setState({ ...state, flgAutoCall: e.target.checked })
  }
  /* render() { */
  return (
    <div className='col-md-7 col-md-offset-3'>
      <form className='form-horizontal formWrapper' onSubmit={handleSubmit}>
        <div className='form-group' style={{ paddingTop: 5 + 'px' }}>
          <label className='control-label col-sm-5'>
            Fees Due<span style={{ color: 'red', marginLeft: 2 }}>*</span>{' '}
          </label>
          <div className='col-sm-6'>
            <select
              autoComplete='off'
              id='feeDuecmb'
              name='feeDue'
              value={state.feeDue}
              className='form-control'
              onChange={handleInputChange}
            >
              <option value='0'>Fees Due</option>
              {state.lstdt && state.lstdt.map(date => (
                <option key={date.id} value={date.id}>
                  {date.date}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='form-group' style={{ paddingTop: 5 + 'px' }}>
          <label className='control-label col-sm-5'>Reminder-1 </label>
          <div className='col-sm-6'>
            <select
              autoComplete='off'
              id='reminder1'
              name='reminder1'
              value={state.reminder1}
              className='form-control'
              onChange={handleInputChange}
            >
              <option value='0'>Reminder-1</option>
              {state.lstdt && state.lstdt.map(date => (
                <option key={date.id} value={date.id}>
                  {date.date}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='form-group' style={{ paddingTop: 5 + 'px' }}>
          <label className='control-label col-sm-5'>Reminder-2 </label>
          <div className='col-sm-6'>
            <select
              autoComplete='off'
              id='reminder2'
              name='reminder2'
              value={state.reminder2}
              className='form-control'
              onChange={handleInputChange}
            >
              <option value='0'>Reminder-2</option>
              {state.lstdt && state.lstdt.map(date => (
                <option key={date.id} value={date.id}>
                  {date.date}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='form-group' style={{ paddingTop: 5 + 'px' }}>
          <label className='control-label col-sm-5'>Overdue Notice-1 </label>
          <div className='col-sm-6'>
            <select
              autoComplete='off'
              id='overdue1'
              name='overdue1'
              value={state.overdue1}
              className='form-control'
              onChange={handleInputChange}
            >
              <option value='0'>Overdue Notice-1</option>
              {state.lstdt && state.lstdt.map(date => (
                <option key={date.id} value={date.id}>
                  {date.date}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='form-group' style={{ paddingTop: 5 + 'px' }}>
          <label className='control-label col-sm-5'>Overdue Notice-2 </label>
          <div className='col-sm-6'>
            <select
              autoComplete='off'
              id='overdue2'
              name='overdue2'
              value={state.overdue2}
              className='form-control'
              onChange={handleInputChange}
            >
              <option value='0'>Overdue Notice-2</option>
              {state.lstdt && state.lstdt.map(date => (
                <option key={date.id} value={date.id}>
                  {date.date}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='form-group' style={{ paddingTop: 5 + 'px' }}>
          <div className='col-sm-3 multiButtonWrap'>
            <label>E-Mail</label>
            <input
              type='checkbox'
              defaultChecked={state.flgEmail}
              onClick={e => handleEmailCBoxChange(e)}
            />
          </div>
          <div className='col-sm-3 multiButtonWrap'>
            <label>SMS</label>
            <input
              type='checkbox'
              defaultChecked={state.flgSms}
              onClick={e => handleSmsCBoxChange(e)}
            />
          </div>
          <div className='col-sm-3 multiButtonWrap'>
            <label>WhatsApp</label>
            <input
              type='checkbox'
              defaultChecked={state.flgWhatsapp}
              onClick={e => handleWappCBoxChange(e)}
            />
          </div>
          <div className='col-sm-3 multiButtonWrap'>
            <label>IVR</label>
            <input
              defaultChecked={state.flgAutoCall}
              type='checkbox'
              onClick={e => handleACCBoxChange(e)}
            />
          </div>
        </div>
        <div className='form-group' style={{ paddingTop: 5 + 'px' }}>
          <div className='col-sm-12 text-center multiButtonWrap'>
            <button
              type='submit'
              style={{ marginTop: 10 }}
              className='btn btn-success'
            >
              Save
            </button>
            <button
              type='button'
              className='btn btn-info text-center '
              id='closeSlider'
              onClick={props.removeClassFromBody}
            >
              <span
                id='close'
                title='Close Slider'
                className='glyphicon glyphicon-remove'
              ></span>
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
export default FeeDueSlider
