import React, { useState, useEffect, useRef, useCallback } from 'react';
import 'jquery-ui';
import 'common/css/master.css';
import 'react-datepicker/dist/react-datepicker.css';
import CellForSub from './ttSubCell.js';
import '../css/selectedCell.css'
import { toast, ToastContainer } from 'react-toastify';
import TTSubjectTblBody from './ttSubjectTblBody.js';

//import TimeTableHead from 'sms/v1/header/js/timeTableHead';

/*var sectionId = 1;
var sessionId = 1;
var classId = 4;*/
var cellDtls = [];

var lstDay = [
  {
    dayId: 1,
    day: 'Mon'
  },
  {
    dayId: 2,
    day: 'Tue'
  },
  {
    dayId: 3,
    day: 'Wed'
  },
  {
    dayId: 4,
    day: 'Thu'
  },
  {
    dayId: 5,
    day: 'Fri'
  },
  {
    dayId: 6,
    day: 'Sat'
  },
  {
    dayId: 7,
    day: 'Sun'
  },
];

//var lstPeriod = [];

const TTSubBody = (props) => {
  var cellRef = useRef();
  const [state, setState] = useState({
    lstDay: [],
    //lstPeriod: [],
    cellDtls: [],
    selPeriodId: 0,
    selDayId: 0,

    selCellDtls: {
      selCellId: props.selCellId,
      selPeriodId: props.selPeriodId,
      selSubId: props.selSubId,
      selDayId: props.selDayId
    },
  });
  const [selCellId, setSelCellId] = useState(0)
  const [lstPeriod, setLstPeriod] = useState(props.lstPeriod);
  const [selPeriodId, setSelPeriodId] = useState(props.selPeriodId);
  const [selDayId, setSelDayId] = useState(props.selDayId);
  const [selSubId, setSelSubId] = useState(0);
  const [daysList, setDaysList] = useState({})
  const [lstSub, setLstSub] = useState(props.lstSub)
  const [classId, setClassId] = useState(props.classId);
  const [sectionId, setSectionId] = useState(props.sectionId);
  const [sessionId, setSessionId] = useState(props.sessionId);
  const [session, setSession] = useState(props.selSession);
  const [selectedSubjectId, setSelectedSubjectId] = useState(0)

  useEffect(() => {
    setLstPeriod(props.lstPeriod)
    setSelPeriodId(props.selPeriodId)
    setDaysList(props.lstDay)
    setWorkingDays(props.lstDay)
    // console.log(props)
    setSelCellId(props.selCellId)
    setClassId(props.classId);
    setSectionId(props.sectionId);
    setSessionId(props.sessionId);
    setSession(props.selSession);
    // console.log(props.lstSub);
    setLstSub(props.lstSub);
    // console.log("props.classId in useEffect in ttSubBody",props.classId);


  },[props.classId, props.sectionId, props.lstSection, props.lstSub]);




  function handleSubjectTableResponse(data) {
    var obj = data;
    if (obj.SvcStatus === 'Success') {
      setLstSub(obj.lstSub);
    } else if (obj.status === 'Failure') {
      if (obj.SvcMsg === 'You are not logged in') {
        setTimeout(function () { window.location.href = ('/') }, 2000);
        return false;
      }
      else if (obj.SvcMsg === 'Your Session is expired, login again') {
        setTimeout(function () { window.location.href = ('/') }, 2000);
        return false;
      } else {
        toast.error(obj.SvcMsg);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }

  function setWorkingDays(pLstDay) {
    // console.log(pLstDay);
    for (var i = 0; i < lstDay.length; i++) {
      lstDay[i].working = pLstDay['' + lstDay[i].day.toLowerCase()]
    }
    // console.log(lstDay)
  }

  function onClickCell(e, pCellDtls, pCellId, pDayId, pPeriodId) {
    /* var vDayId = e.target.getAttribute('seldayid');
        var vPeriodId = e.target.getAttribute('selperiodid');
        var vCellDtls = e.target.getAttribute('selcelldtls')
         */
    pCellId = (e.target.closest("td").firstChild.getAttribute('cell-id'));
    var pos = parseInt(e.target.closest("td").firstChild.getAttribute('pos'))
    // console.log("pos=" + pos + ", cellId = " + pCellId)
    setState({ ...state, selCellDtls: pCellDtls, selDayId: pDayId, selPeriodId: pPeriodId, subPosition: pos });
    props.setDayPeriodId(pCellId, pDayId, pPeriodId, pos);
  }

  function setPSelCellId(pCellId, pos) {
    setSelCellId(pCellId);
    var pDayId = document.querySelectorAll('[cell-id=' + pCellId + ']').closest("td").firstChild.getAttribute('day-id');
    var pPeriodId = document.querySelectorAll('[cell-id=' + pCellId + ']').closest("td").firstChild.getAttribute('period-id');
    var pCellDtls = document.querySelectorAll('[cell-id=' + pCellId + ']').closest("td").firstChild.getAttribute('cell-dtls');
    // console.log("pos=" + pos + ", cellId = " + pCellId)
    props.setSubPositionId(pos - 1 < 0 ? 0 : pos - 1)
    setState({ ...state, selCellDtls: pCellDtls, selDayId: pDayId, selPeriodId: pPeriodId, subPosition: pos });
    props.setDayPeriodId(pCellId, pDayId, pPeriodId, pos);
  }

  function myFun(e) {
    // console.log(e.which)
    // console.log(e.currentTarget)
    // console.log(e.currentTarget.nextElementSibling)
    e.currentTarget.nextElementSibling.click()
    // document.querySelector(e.currentTarget.nextElementSibling).addClass('selected-cell').siblings().removeClass('selected-cell')
  }
  return (
    <>
      <div className="col-sm-12" style={{ width: '68%', height: '100%' }}>
        <table className="tableLayout" >
          <thead>
            <tr>
              <th style={{ width: 30 }}> Periods </th>
              {lstDay.map((cell, j) => {
                return (cell.working && <th style={{ width: 38 }} key={j}>{cell.day}</th>
                  || !cell.working && <th style={{ width: 10 }} key={j}>{cell.day}</th>)
              })}
            </tr>
          </thead>
        </table>
        <div className="tableLayout" style={{ height: 'auto-fit' }} >
          <table id="" className="tableLayout">
            <colgroup>
              <col width="30" />
              {lstDay.map((cell, j) => {
                return (cell.working && <col key={j} width="38" />
                  || !cell.working && <col key={j} width="10" />)
              })}
            </colgroup>
            <tbody id="timeLayout">
              {lstPeriod && lstPeriod.map((obj, i) => {
                { JSON.stringify(obj) }
                return <tr height="10" key={"tr_" + i} >
                  <td style={{ width: 30, textAlign: 'center', borderRight: '1px solid gainsboro', borderBottom: '1px solid gainsboro', background: '#eee' }}>
                    {obj.periodType == 'Regular' ? <label>{obj.period}</label> : ""} <p>{obj.timings}</p></td>
                  {
                    obj.periodType !== 'Regular' &&
                    <th colSpan="7" className="dark-border" style={{ textAlign: 'center', background: '#ccc' }}><label>{obj.periodType}</label></th>
                  }

                  {
                    obj.periodType === 'Regular' && lstDay &&

                    lstDay.map((cell, j) => {

                      var isCellSelected = (obj.periodId === state.selPeriodId && cell.dayId === state.selDayId);
                      return props.classId !== 0 && props.classSectionId !== 0 && props.sessionId !== 0 &&
                        <td key={'c_' + j} style={{ width: 40, fontSize: '12px', textAlign: 'center' }}
                          working-day={cell.day + "_" + cell.working}
                          className={!cell.working ? "unused-cell" : !isCellSelected ? "cell" : "selected-cell"}
                          selcelldtls={JSON.stringify(cell)}
                          selcellid={cellDtls.cellId}
                          seldayid={cell.dayId}
                          selperiodid={obj.periodId}
                          onClick={(e) => onClickCell(e, cellDtls, cellDtls.cellId, cell.dayId, obj.periodId)}
                          ref={cellRef}
                          onKeyDown={(e) => myFun(e)}
                          tabIndex={10 * i + j}
                        >


                          {cell.working && <CellForSub
                            key={cell.dayId === props.selDayId && obj.periodId === props.selPeriodId ? Math.floor(Math.random() * 999999) : "d_" + cell.dayId + "_p_" + obj.periodId}
                            selCellId={selCellId}
                            dayId={cell.dayId}
                            classId={props.classId}
                            periodId={obj.periodId}
                            sessionId={props.sessionId}
                            sectionId={props.classSectionId}
                            selDayId={selDayId}
                            cellDtls={props.cellDtls}
                            selPeriodId={selPeriodId}
                            setSubPositionId={props.setSubPositionId}
                            selCellDtls={props.selCellDtls}
                            setPSelCellId={setPSelCellId}
                          // getCellDtls={getCellDtls}
                          // deleteCellDtls={deleteCellDtls}
                          // saveCellDtls={props.saveCellDtls}
                          />}
                        </td>
                    })
                  }
                </tr>
              })}
              {/* <tr style={{height: 50}}>
                        <td>
                            <label textAlign="centre">0</label><br/>
                            <p>10:00 2:00</p>
                        </td>
                        <th colSpan="6" style={{height: 50}}><label>ASSEMBLY</label></th>
                    </tr> */}
            </tbody>
          </table>
        </div>
      </div>
      <div className='col-sm-6 padding-remove' style={{ width: '32%', height: '100%' }}>
        {props.selSession && lstSub && lstSub.length !== 0 && session && <TTSubjectTblBody
          key={props.subTBodyKey}
          classId={classId}
          sectionId={sectionId}
          sessionId={sessionId}
          setCellSubId={props.setCellSubId}
          selSession={props.selSession}
          setPeriod={props.setPeriod}
          selSubId={selSubId}
          selCellDtls={props.selCellDtls}
          setPosDayPeriod={props.setPosDayPeriod}
          lstSub={lstSub}
          session={session}

          //rowClick={rowClickInMain}
          //selSubId={lstSub.SubId}
          selectedSubjectId={selectedSubjectId}
          setSelectedSubjectId={setSelectedSubjectId} />
        }
      </div>

    </>
  );
}
export default TTSubBody;