
import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import 'jquery-ui';
import 'common/css/calendarscreen.css';
import 'common/css/master.css';
import axios from 'axios';
import { toast } from 'react-toastify';

// import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import Calendar from 'react-calendar';


var httpURL = '';
var queryString = require('querystring');
let classId = 1;
let slotType = 10;
var defaultDate = "";
let lstSlotDates = [];
const mark = [
]
var tokenid = localStorage.getItem('tokenid');
var screenId = 17;


class calendarScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            date: new Date(),
            defaultDate: null,
            mindate: new Date(),
            tokenid: tokenid,
        }
    }

    componentDidMount = () => {
        classId = this.props.classId;
        slotType = this.props.slotType;
        defaultDate = this.props.defaultDate;
        // console.log(defaultDate);
        this.getSlotDatesList(classId);
        this.setState({
            defaultDate: defaultDate
        });

    }

    getSlotDatesList = (classId) => {
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/reg/sch/test/dates',
            queryString.stringify({ 'classId': classId, 'slotType': slotType, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstSlotDates = response.data.lstSlotDates;
                for (let i = 0; i < lstSlotDates.length; i++) {
                    mark.push(response.data.lstSlotDates[i].date);
                }
                // console.log(mark);
            }
            else {

                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    changeDate = (date) => {
        this.setState({
            defaultDate: date,
        });
        date = moment(date).format("DD-MM-YYYY");
        // $('#lbl_sel_date').text('You selected : ' + date);
        this.setState({ date });
        // console.log(date);
        this.props.dateFromChild(date);
    }

    render() {
        httpURL = lstModules['registration'].url + ':' + lstModules['registration'].port;
        return (
            <>
                <Calendar
                    onChange={this.changeDate}
                    value={this.state.defaultDate}
                    tileClassName={({ date, view }) => {
                        if (mark.find(x => x === moment(date).format("DD-MM-YYYY"))) {
                            return 'highlight'
                        }
                    }
                    }

                />
                {/* <label className="control-label col-sm-12" id="lbl_sel_date"></label> */}

            </>
        );
    }



}
export default calendarScreen;