import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['students'].url + ':' + lstModules['students'].port;
const tokenid = localStorage.getItem('tokenid');
const schoolId = localStorage.getItem("schoolId");
var queryString = require("querystring");





export const saveCategorySvc = (category, callback) => {
    // console.log('saveCategorySvc called, studentObj', studentObj);
    const responseObj = { status: "", message: "", error: "" };
    axios({
        url: httpURL + "/sms/student/category",
        method: 'POST',
        params: { category: JSON.stringify(category) },
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // console.log("response in saveCategorySvc, response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};

