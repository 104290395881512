import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')

var httpURL = lstModules['enquiry'].url + ':' + lstModules['enquiry'].port
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port
function getCloseRemarksSvc (callBack, pAdmissionId) {
  if (!pAdmissionId) {
    return
  }
  axios({
    url: httpURL + '/sms/admission/close',
    method: 'POST',
    params: { admissionId: pAdmissionId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console.log('error')
    })
}
function saveCloseRemarksSvc (
  pAdmissionId,
  pReasonId,
  pRemarks,
  cbSaveFollowUp
) {
  axios({
    url: httpURL + '/sms/admission/close/save',
    method: 'POST',
    params: {
      admissionId: pAdmissionId,
      reasonId: pReasonId,
      closeRemarks: pRemarks
    },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      var data = response.data
      cbSaveFollowUp(data)
    })
    .catch(error => {
      // console.log('Error Occured')
    })
}
function getCloseReasonSvc (callBack) {
  axios({
    url: httpURL + '/sms/admission/close_reason/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console.log('error')
    })
}
export { getCloseRemarksSvc }
export { saveCloseRemarksSvc }
export { getCloseReasonSvc }
