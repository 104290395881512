import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "common/js/header/header.js";
import MeasureCard from "./MeasureCard";
import AppraisalTbl from "./AppraisalTbl";

const AppraisalCtrl = () => {
    const [loader, setLoader] = useState("none");
    const [kpiId, setKpiId] = useState(0)
    const [lstKPI, setLstKPI] = useState([{
        "kpiId": 1,
        "kpi": "Self Attendance",
        "relativeWt": 24,
        "ratio": 99.5,
        "disabled": false
    }, {
        "kpiId": 2,
        "kpi": "Students Admission",
        "relativeWt": 100,
        "ratio": 26.6,
        "disabled": false
    }, {
        "kpiId": 3,
        "kpi": "Timely Student Attendance",
        "relativeWt": 53,
        "ratio": 80.2,
        "disabled": false
    }, {
        "kpiId": 4,
        "kpi": "Students Presence",
        "relativeWt": 27,
        "ratio": 57.9,
        "disabled": false
    }, {
        "kpiId": 5,
        "kpi": "Class Result",
        "relativeWt": 31,
        "ratio": 74.0,
        "disabled": true
    }, {
        "kpiId": 6,
        "kpi": "Subjects Result",
        "relativeWt": 56,
        "ratio": 10.3,
        "disabled": false
    }, {
        "kpiId": 7,
        "kpi": "Copies Checked",
        "relativeWt": 14,
        "ratio": 44.7,
        "disabled": false
    }, {
        "kpiId": 8,
        "kpi": "Parents Feedback",
        "relativeWt": 89,
        "ratio": 0,
        "disabled": true
    }, {
        "kpiId": 9,
        "kpi": "Peer Feedback",
        "relativeWt": 41,
        "ratio": 0,
        "disabled": true
    }, {
        "kpiId": 10,
        "kpi": "Supervisor Feedback",
        "relativeWt": 33,
        "ratio": 0,
        "disabled": true
    }, {
        "kpiId": 11,
        "kpi": "Self Feedback",
        "relativeWt": 72,
        "ratio": 0,
        "disabled": true
    }])

    const [lstMeasure, setLstMeasure] = useState([{
        "measureId": 1,
        "measure": "Self Attendance",
        "remarks": "Based on # of absences",
        "lstScore": [{ key: '1', score: 20 }, { key: '2', score: 15 }, { key: '3', score: 10 }, { key: '4', score: 5 }, { key: 'otherwise', score: 0 }],
        "disabled": false
    }, {
        "measureId": 2,
        "measure": "Students Admission",
        "remarks": "Based on # of delays",
        "lstScore": [{ key: '0', score: 20 }, { key: '2', score: 15 }, { key: '5', score: 10 }, { key: '10', score: 5 }, { key: 'otherwise', score: 0 }],
        "disabled": false
    }, {
        "measureId": 3,
        "measure": "Timely Student Attendance",
        "remarks": "Based on % of total absenteeism",
        "lstScore": [{ key: '2%', score: 20 }, { key: '3%', score: 15 }, { key: '5%', score: 10 }, { key: '8%', score: 5 }, { key: 'otherwise', score: 0 }],
        "disabled": false
    }, {
        "measureId": 4,
        "measure": "Students Presence",
        "remarks": "Based on # of copies checked",
        "lstScore": [{ key: '4.50', score: 20 }, { key: '4.25', score: 15 }, { key: '4.00', score: 10 }, { key: '3.50', score: 5 }, { key: 'otherwise', score: 0 }],
        "disabled": false
    }, {
        "measureId": 5,
        "measure": "Class Result",
        "remarks": "Based on feedback rating by Parents",
        "lstScore": [{ key: '4.50', score: 20 }, { key: '4.25', score: 15 }, { key: '4.00', score: 10 }, { key: '3.50', score: 5 }, { key: 'otherwise', score: 0 }],
        "disabled": false
    }, {
        "measureId": 6,
        "measure": "Subjects Result",
        "remarks": "Result as class teacher",
        "lstScore": [{ key: '4.50', score: 20 }, { key: '4.25', score: 15 }, { key: '4.00', score: 10 }, { key: '3.50', score: 5 }, { key: 'otherwise', score: 0 }],
        "disabled": false
    }, {
        "measureId": 7,
        "measure": "Copies Checked",
        "remarks": "Result as class teacher",
        "lstScore": [{ key: '4.50', score: 20 }, { key: '4.25', score: 15 }, { key: '4.00', score: 10 }, { key: '3.50', score: 5 }, { key: 'otherwise', score: 0 }],
        "disabled": false
    }, {
        "measureId": 8,
        "measure": "Parents Feedback",
        "remarks": "New admissions with teacher reference",
        "lstScore": [{ key: '8', score: 20 }, { key: '5', score: 15 }, { key: '2', score: 10 }, { key: '1', score: 5 }, { key: 'otherwise', score: 0 }],
        "disabled": false
    }, {
        "measureId": 9,
        "measure": "Peer Feedback",
        "remarks": "Based on feedback rating from peers",
        "lstScore": [{ key: '4.50', score: 20 }, { key: '4.25', score: 15 }, { key: '4.00', score: 10 }, { key: '3.50', score: 5 }, { key: 'otherwise', score: 0 }],
        "disabled": true
    }, {
        "measureId": 10,
        "measure": "Supervisor Feedback",
        "remarks": "Based on feedback rating received from the supervisor",
        "lstScore": [{ key: '4.50', score: 20 }, { key: '4.25', score: 15 }, { key: '4.00', score: 10 }, { key: '3.50', score: 5 }, { key: 'otherwise', score: 0 }],
        "disabled": true
    }, {
        "measureId": 11,
        "measure": "Self Feedback",
        "remarks": "It shows your self feedback",
        "lstScore": [{ key: '4.50', score: 20 }, { key: '4.25', score: 15 }, { key: '4.00', score: 10 }, { key: '3.50', score: 5 }, { key: 'otherwise', score: 0 }],
        "disabled": true
    }])

    const getWingId = (wingId) => {
        // console.log('wingId = ' + wingId)
    }
    return (
        <div className="MainContainer" style={{ pointerEvents: loader === "none" ? "auto" : "none", }}>
            <div style={{
                position: "fixed",
                zIndex: 10000,
                width: 100,
                height: 100,
                marginLeft: -50,
                marginTop: -50,
                left: "50%",
                top: "50%",
                display: loader,
            }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>

            <Header wingFromChild={getWingId} />

            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="col-sm-12">
                <div className="col-sm-12 padding-remove">
                    <div className="row head">
                        <div className="container-fluid" style={{ height: "20%", boxShadow: "rgb(204 204 204) 8px 2px 4px 0px", borderWidth: "1px 0px", borderStyle: "solid", borderColor: "rgb(204, 204, 204)", padding: "5px 20px", border: "none", pointerEvents: loader === "none" ? "auto" : "none" }}>
                            <div className="col-sm-4 padding-remove">
                                <AppraisalTbl lstKPI={lstKPI} kpiId={kpiId} onClick={setKpiId} />
                            </div>
                            <div className="col-sm-8">
                                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px', marginBottom: '1%', fontWeight: 'bold', fontFamily: 'Arial', fontSize: '15px', textTransform: 'uppercase', padding: '3%', background: 'cornflowerblue', borderRadius: '4px', border: '1px solid #000' }}>Performance Measure</div>
                                {lstMeasure.map((obj, idx) => {
                                    return <div key={idx} className="col-sm-3" style={{ marginTop: 0 }}><MeasureCard idx={idx} obj={obj} /></div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppraisalCtrl