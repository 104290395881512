import React, { useEffect, useState } from 'react';
import 'sms/tt/teacher/css/tabList.css'
import 'common/css/master.css'


const SessionList = (props) => {
    const [lstSession, setLstSession] = useState(props.lstSession)
    useEffect(() => {
        // console.log("useEffect for sessionList.js called");
        // console.log("props.lstSession in sessionList",props.lstSession);
        //if (props.classId !== 0 && props.sectionId !== 0 && props.sessionId === 0) {
            setLstSession(props.lstSession)
            // console.log("useEffect for session" + lstSession)
        //}
    }, [props.classId,props.sectionId,props.sessionId,props.lstSession]);

    return (
        <div className="tab1">
            {lstSession.map((obj) => {
            // console.log("obj.sessionId in lstsession.map in sesionList)",obj.sessionId, "props.selSessionId ",props.selSessionId);
                var isSelected = obj.sessionId == props.selSessionId;
                return <div className={isSelected ? "sellected" : ""} onClick={() => props.sessionClick(obj.sessionId, obj.session)} key={obj.sessionId}>
                    <span hidden>{obj.sessionId}</span>
                    <span>{obj.session}</span><span style={{ marginLeft: '3.5rem' }}>{obj.pct + '%'}</span>
                    <br></br>
                    <span className="col-sm-12 col-centered">{obj.dispStartDt} | {obj.dispEndDt}</span>
                </div>
            })}
        </div>
    )
}
export default SessionList;