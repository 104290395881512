import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './testFeedback.css';
import StarRating from "./starRating";

import { studentFeedbackSvc } from "./testFeedbackSvc";


const TestFeedback = () => {
    const params = new URLSearchParams(window.location.search);

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);

    const [testId, setTestId] = useState("");
    const [studentId, setStudentId] = useState("");
    const [flgFeedbackPositive, setFlgFeedbackPositive] = useState(false);
    const [schoolId, setSchoolId] = useState(0);

    const [title, setTitle] = useState("Mr.");
    const [name, setName] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [ratingFun, setRatingFun] = useState(0);
    const [ratingNoOverload, setRatingNoOverload] = useState(0);
    const [ratingSimpleToUse, setRatingSimpleToUse] = useState(0);
    const [improvementFeedback, setImprovementFeedback] = useState("");
    const [formDisabled, setFormDisabled] = useState(false);

    const titleOptions = [
        { value: 'mr', label: 'Mr.' },
        { value: 'ms', label: 'Ms.' },
        { value: 'mrs', label: 'Mrs.' },
    ];

    useEffect(() => {
        setLoader("block");
        setOpacity(0.5);

        const tId = params.get("testId");
        const sId = params.get("studentId");
        const flgPos = params.get("flgFeedbackPositive") === 'true';
        const pSchoolId = params.get("schoolId");

        setTestId(tId);
        setStudentId(sId);
        setFlgFeedbackPositive(flgPos);
        setSchoolId(parseInt(pSchoolId));

        // Simulate loading and disable form if needed
        setTimeout(() => {
            setLoader("none");
            setOpacity(1);
        }, 500);
    }, []);

    const submitFeedback = () => {
        if (flgFeedbackPositive) {
            if (!title || title.length > 4) {
                toast.error('Title is required and must be at most 4 characters long.');
                return;
            }

            if (!name || name.length > 45) {
                toast.error('Name is required and must be at most 45 characters long.');
                return;
            }

            if (!mobileNo || mobileNo.length > 12 || mobileNo.length < 10) {
                toast.error('Mobile number is required and can have 10 to 12 characters.');
                return;
            }
        }

        if (improvementFeedback.length > 100) {
            toast.error('Remarks must be at most 100 characters long.');
            return;
        }
        const feedback = {
            "schoolId":schoolId,
            "testId":parseInt(testId),
            "studentId":parseInt(studentId),
            "positive": flgFeedbackPositive,
            "remarks": improvementFeedback,
            title,
            "nm": name,
            "phoneNo": mobileNo,
            "feedback1": ratingFun,
            "feedback2": ratingNoOverload,
            "feedback3": ratingSimpleToUse,
            schoolId
        };
        // Save feedback using a service or API call
        // console.log(feedback);
        studentFeedbackSvc(feedback, schoolId, studentFeedbackResponse);
    };

    const studentFeedbackResponse = (data) => {
        // console.log("studentFeedbackResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            setFormDisabled(true);
        }
        else {
            toast.error(obj.message);
        }
        setLoader('none');
        setOpacity(1);
    }

    return (
        <div style={{ margin: 0 }}>
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loaderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="test-feedback-page" style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
                <div className="col-sm-10 col-xs-12 col-centered padding-remove">
                    {!flgFeedbackPositive && (
                        <div className="col-sm-6 col-xs-12  padding-remove improvement-box">
                            <div className="form-group col-sm-12 col-xs-12 padding-remove">
                                <div className="col-sm-12 col-xs-12 padding-remove">
                                    <label className="improvement-label" >How can we improve?</label>
                                </div>
                                <div className="col-sm-12 col-xs-12 padding-remove">
                                    <textarea
                                        value={improvementFeedback}
                                        style={{ height: "120px" }}
                                        onChange={(e) => setImprovementFeedback(e.target.value)}
                                        className="form-control improvement-input"
                                        disabled={formDisabled}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {flgFeedbackPositive && (
                        <>
                            <div className="col-sm-12 col-xs-12 col-centered padding-remove">
                                <div className="test-feedback-header col-sm-4 col-xs-12">
                                    <div className="form-group col-sm-12 col-xs-12 padding-remove">
                                        <div className="col-sm-3 col-xs-3 padding-remove">
                                            <select
                                                autoComplete='off'
                                                className='form-control'
                                                style={{ height: 35, width: "95%" }}
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)}
                                            >
                                                {titleOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-9 col-xs-9 padding-remove">
                                            <input
                                                type="text"
                                                placeholder="Name"
                                                className="form-control"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                disabled={formDisabled}
                                            />
                                        </div>
                                    </div>
                                    <div className='form-group mobile-no-group col-sm-12 col-xs-12 padding-remove'>
                                        <div className="form-group col-sm-3 col-xs-3 padding-remove">
                                            <label className="control-label">Mobile No</label>
                                        </div>
                                        <div className="col-sm-9 col-xs-9 padding-remove">
                                            <input
                                                type="text"
                                                value={mobileNo}
                                                onChange={(e) => setMobileNo(e.target.value)}
                                                className="form-control"
                                                placeholder="9999999999"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-xs-12 padding-remove">
                                <div className="test-feedback-rating col-sm-6 col-xs-12">
                                    <div className="rating-group col-sm-12 col-xs-12 padding-remove">
                                        <div className="form-group col-sm-6 col-xs-12 padding-remove">
                                            <label className="rating-label">Is it fun for students?</label>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 padding-remove">
                                            <StarRating rate={ratingFun} setRate={setRatingFun} formDisabled={formDisabled} />
                                        </div>
                                    </div>
                                    <div className="rating-group col-sm-12 col-xs-12 padding-remove">
                                        <div className="form-group col-sm-6 col-xs-12 padding-remove">
                                            <label className="rating-label">No overload for teachers</label>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 padding-remove">
                                            <StarRating rate={ratingNoOverload} setRate={setRatingNoOverload} formDisabled={formDisabled} />
                                        </div>
                                    </div>
                                    <div className="rating-group col-sm-12 col-xs-12 padding-remove">
                                        <div className="form-group col-sm-6 col-xs-12 padding-remove">
                                            <label className="rating-label">Simple to use</label>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 padding-remove">
                                            <StarRating rate={ratingSimpleToUse} setRate={setRatingSimpleToUse} formDisabled={formDisabled} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="col-sm-12 col-xs-12 padding-remove">
                        <div className="col-sm-6 col-xs-12 padding-remove">
                            <button className="submit-button pull-right" style={{cursor:formDisabled? "not-allowed":"pointer"}} onClick={submitFeedback} disabled={formDisabled}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestFeedback;
