import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
var httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;

var httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;

var screenId = 33;
//var sessionId = 3;
//var queryString = require('querystring');
var tokenid = localStorage.getItem("tokenid");
function getLstMonth(callback) {
    var status = 'Success'
    var obj = { status: status, message:"" };
    // console.log("getLstMonth");
    axios({
        url: httpURLCommon+'/sms/mst/month/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === 'Success') {
            // console.log('data.SvcStatus=' + response.data.SvcStatus);
            // console.log(response.data);
            obj.lstMonth = response.data.lstMonth;
            // console.log(obj);
        }
        callback(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

function getLstClsSection(callback) {
    var status = 'Success'
    var obj = { status: status, message:"" };
    // console.log("getLstClsSection");
    axios({
        url: httpURL + '/sms/fees/cls/section/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === 'Success') {
            // console.log('data.SvcStatus=' + response.data.SvcStatus);
            // console.log(response.data);
            obj.lstClsSection = response.data.lstClsSection;
            // console.log(obj);
        }
        callback(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

function getLstMonthlySummary( callback) {
    var status = 'Success'
    var obj = { status: status, message:"" };

    
    // console.log("getLstMonthlySummary");
    axios({
        url: httpURL + '/sms/report/act/summary/month/school',
        method: "POST",
        params: {  screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === 'Success') {
            // console.log('data.SvcStatus=' + response.data.SvcStatus);
            // console.log(response.data);
            obj.lstLabels = response.data.lstLabels;
            obj.lstMonthlySummary = response.data.lstMonthlySummary;
            obj.totalExp = response.data.totalExp;
            obj.totalRec = response.data.totalRec;
            obj.totalDiff = response.data.totalDiff;
            obj.feeTotal = response.data.feeTotal;
            // console.log(response.data.feeTotal);
            // console.log(obj);
        }
        callback(obj);
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
    });
    callback(obj)
}
function getLstClassSummary(monthId, callback) {

    var status = 'Success'
    var obj = { status: status, message:"" };

    // console.log("monthId " + monthId);
    // console.log("getLstClassSummary`");
    axios({
        url: httpURL + '/sms/report/act/summary/month/class_section',
        method: "POST",
        params: { monthId, screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === 'Success') {
            // console.log('data.SvcStatus=' + response.data.SvcStatus);
            // console.log(response.data);
            obj.lstLabels = response.data.lstLabels;
            obj.feeTotal = response.data.feeTotal;
            obj.lstClassSummary = response.data.lstClassSummary;
            obj.totalExp = response.data.totalExp;
            obj.totalRec = response.data.totalRec;
            obj.totalDiff = response.data.totalDiff;

            // console.log(response.data.feeTotal);
            // console.log(obj);
        }
        callback(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}
function getLstStudentSummary(clsSectionId, month, callback) {

    var status = 'Success'
    var obj = { status: status, message:"" };

    // console.log("month " + month);
    // console.log("getLstStudentSummary`");
    axios({
        url: httpURL + '/sms/report/act/summary/month/student',
        method: "POST",
        params: { clsSectionId, monthId:month, screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === 'Success') {
            // console.log('data.SvcStatus=' + response.data.SvcStatus);
            // console.log(response.data);
            obj.lstLabels = response.data.lstLabels;
            obj.feeTotal = response.data.feeTotal;
            obj.lstStudentSummary = response.data.lstStudentSummary;
            obj.totalExp = response.data.totalExp;
            obj.totalRec = response.data.totalRec;
            obj.totalDiff = response.data.totalDiff;

            // console.log(response.data.feeTotal);
            // console.log(obj);
        }
        callback(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

export { getLstMonthlySummary, getLstClassSummary, getLstMonth, getLstClsSection, getLstStudentSummary };