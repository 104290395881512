import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import ClassSubjectForm from './ClassSubjectForm';
import ClassSubjectTable from './ClassSubjectTable';

const ClassSubjectSlider = (props) => {

    const [selSectionId, setSelSectionId] = useState(0)
    const [selClassId, setSelClassId] = useState(0)
    const [randNoClassSubjectForm, setRandClassSubjectForm] = useState(0);
    const [loader, setLoader] = useState('none')
    const [classSubjectTblKey, setClassSubjectTblKey] = useState(0);

    function generateRandomNo() {
        return Math.floor(Math.random() * 999999);
    }
    function newSubject() {
        // console.log("add")
        setSelClassId(0)
        setSelSectionId(0)
        setRandClassSubjectForm(generateRandomNo)
    }

    return (
        <div className='col-sm-12'>
            <div className="StaffHead col-sm-12 col-centered" style={{ marginTop: '1%', border: '1px solid rgb(170, 170, 170)', borderRadius: '10px 10px 5px 5px', fontWeight: 'bold' }}>
                <h4>Class-Subjects</h4>
            </div>
            <div className='col-sm-6'>
                <ClassSubjectTable
                    key={classSubjectTblKey}
                    userId={props.userId}
                    flgSliderType={props.flgSliderType}
                    loader={loader}
                    sectionId={selSectionId}
                    setSelSectionId={setSelSectionId}
                    setSelClassId={setSelClassId}
                    generateRandomNo={generateRandomNo}
                    setClassSubjectTblKey={setClassSubjectTblKey}
                />
                <div className="col-xs-1" onClick={(e) => newSubject()}>
                    <span id="addReadyId" title="Add" className="addIcon glyphicon glyphicon-plus-sign"></span>
                </div>
            </div>
            <div className='col-sm-6'>
                <ClassSubjectForm
                    userId={props.userId}
                    flgSliderType={props.flgSliderType}
                    key={randNoClassSubjectForm}
                    closeSlider={props.closeSlider}
                    loader={loader}
                    sectionId={selSectionId}
                    classId={selClassId}
                    setSelClassId={setSelClassId}
                    setSelSectionId={setSelSectionId}
                    generateRandomNo={generateRandomNo}
                    setClassSubjectTblKey={setClassSubjectTblKey}
                />
            </div>
        </div>
    )
}
export default ClassSubjectSlider;