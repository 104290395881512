import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from 'react-toastify';


import { getSubjectListSvc } from "./examSyllabusSvc";
import SubjectTable from "./subjectTable";
import "../css/examSyllabus.css";


const ExamTable = (props) => {
    const { examId, syllabusId, sectionId, setLoader, setOpacity } = props;

    const [subjectId, setSubjectId] = useState(0);
    const [lstSubject, setLstSubject] = useState([]);

    useEffect(() => {
        // console.log("ExamTable called");
        // console.log("Exam ID:", examId);
        // console.log("Syllabus ID:", syllabusId);
        // console.log("Section ID:", sectionId);
        getSubjectList();
    }, [examId, syllabusId, sectionId]);


    const getSubjectList = () => {
        setLoader((prevLoader) => prevLoader + 1);
        setOpacity(0.5);
        getSubjectListSvc(syllabusId, examId, subjectListResponse);
    }



    const subjectListResponse = (data) => {
        // console.log("subjectListResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            setLstSubject(obj.lstSubject);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader((prevLoader) => prevLoader - 1);
        setOpacity(1);
    }


    return (
        <div className="col-sm-12 subject-list padding-remove">
            {lstSubject && lstSubject.length ?
                lstSubject.map((subject, i) => (
                    <div className="syllabus-subject ">
                        <SubjectTable
                            examId={examId}
                            syllabusId={syllabusId}
                            sectionId={sectionId}
                            subjectId={subject.subjectId}
                            subject={subject.subject}
                            lstChapter={subject.lstChapter}
                            setLoader={setLoader}
                            setOpacity={setOpacity}
                        />
                    </div>
                )) : null
            }
        </div>
    );
}

export default ExamTable;