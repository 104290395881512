import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TestQuestionPDF from "./testQuestionsPDF";

import { getQuestionsSvc, getLogoSvc } from "./testQuestionsSvc";



const TestPDFSlider = (props) => {
    const { testId, testScheduleId, closeSlider } = props;

    const [lstQuestions, setlstQuestions] = useState([]);
    const [testHeader, setTestHeader] = useState([]);
    const [loaderSlider, setLoaderSlider] = useState("none");
    const [opacitySlider, setOpacitySlider] = useState(1);
    const [logoUrl, setLogoUrl] = useState('');



    useEffect(() => {
        // console.log("TestPDFSlider called");
        getTestQuestions(testId, testScheduleId);
        getLogoSvc(cbLogoResponse);
    }, [testId]);


    function cbLogoResponse(imageUrl) {
        // console.log("cbLogoResponse called, imageUrl", imageUrl);
        setLogoUrl(imageUrl)
    }

    const getTestQuestions = (pTestId, pTestScheduleId) => {
        // console.log("getTestQuestions called, testId passed", pTestId);
        getQuestionsSvc(pTestId, pTestScheduleId, getQuestionsResponse);
        setLoaderSlider("block");
        setOpacitySlider(0.5);
    }

    const getQuestionsResponse = (data) => {
        // console.log("getQuestionsResponse, data", data);
        setLoaderSlider("none");
        setOpacitySlider(1);
        let obj = data;
        if (obj.status === 'Success') {
            // console.log("obj.lstQuestion", obj.lstQuestion);
            setlstQuestions(obj.lstQuestion);
            setTestHeader(obj.header);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
    }

    return (
        <div className="col-xs-12 col-centered padding-remove ">
            <div style={{ position: "fixed", zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '70%', top: '40%', display: loaderSlider }} >
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div >
            <ToastContainer autoClose={3000} hideProgressBar />
            <div>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={() => closeSlider()} style={{ right: "60%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <div className='col-sm-12 padding-remove' style={{ margin: 0 }}>
                    <TestQuestionPDF
                        logoUrl={logoUrl}
                        subject={testHeader.test || ""}
                        clsSec={testHeader.clsSection || ""}
                        school={testHeader.school || ""}
                        academicYear={testHeader.acdYr || ""}
                        duration={testHeader.durationMins || ""}
                        questionCount={testHeader.noOfQus || ""}
                        lstQuestions={lstQuestions}
                        flgAnswerBox={!testHeader.separateAnsSheet}
                    />
                </div>
            </div>
        </div>
    );
}
export default TestPDFSlider;