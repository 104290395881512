import React, { useState } from "react";
import { searchBorrowerSvc } from "./issueBooksSvc";
import { toast } from "react-toastify";

export function BorrowerSearchBar(props) {
  const [keyBorrower, setKeyBorrower] = useState("");
  const [flgType, setFlgType] = useState("Student");

  function searchBorrower() {
    props.setLoader("block");
    var flgStaff = flgType === "Student" ? false : true;
    props.setBorrowerDtls({
      age: 0,
      gender: "-",
      clsSection: "",
      borrower: "",
      staffId: 0,
      studentId: 0,
    });
    props.setBorrowerId(0);
    props.setStudentId(0);
    props.setStaffId(0);

    searchBorrowerSvc(keyBorrower, flgStaff, searchBorrowerCalback);
  }

  function searchBorrowerCalback(data) {
    props.setLoader("none");
    if (data.SvcStatus === "Success") {
      props.setBorrowerDtls(data.borrowerObj);
      if (flgType === "Student") {
        props.setBorrowerId(data.borrowerObj.studentId);
        props.setStudentId(data.borrowerObj.studentId);
      } else {
        props.setStaffId(data.borrowerObj.staffId);
        props.setBorrowerId(data.borrowerObj.staffId);
      }
      toast.success(data.SvcMsg);
    } else {
      props.setBorrowerDtls({
        age: 0,
        gender: "-",
        clsSection: "",
        borrower: "",
        staffId: 0,
        studentId: 0,
      });
      if (data.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (data.SvcMsg === "Your Session is expired, login again") {
        toast.error(data.SvcMsg);
        // console.log("session expired");
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        props.setBorrowerDtls({
          age: 0,
          gender: "-",
          clsSection: "",
          borrower: "",
          staffId: 0,
          studentId: 0,
        });
        toast.error(data.SvcMsg);
      }
    }
  }

  return (
    <div>
      <div className="col-sm-12">
        <div className="form-group">
          <div className="col-sm-3">
            <input
              autoComplete="off"
              id="borrowerKey"
              type="text"
              onChange={(e) => setKeyBorrower(e.target.value)}
              className="form-control "
              placeholder="Enrollment No./Employee Code"
            />
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <div className="col-sm-12 padding-remove">
                <label className="col-sm-5">
                  Borrower<span className="red-color">*</span>
                </label>
                <label className="radio-inline">
                  <input
                    type="radio"
                    name="type"
                    value="Student"
                    checked={flgType === "Student"}
                    onChange={(e) => setFlgType(e.target.value)}
                  ></input>
                  Student
                </label>
                <label className="radio-inline">
                  <input
                    type="radio"
                    name="type"
                    value="Staff"
                    checked={flgType === "Staff"}
                    onChange={(e) => setFlgType(e.target.value)}
                  ></input>
                  Staff
                </label>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-info col-sm-2"
            onClick={() => searchBorrower()}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
}
