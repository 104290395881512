import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['ptm'].url + ':' + lstModules['ptm'].port
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port
var httpURLTT = lstModules['timeTable'].url + ':' + lstModules['timeTable'].port

function getClassList (callback1) {
  var status = 'Success'
  var obj = { status: status }
  axios({
    url: httpURLCommon + '/sms/mst/cls/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        obj.lstCls = response.data.lstClass
        callback1(obj)
      } else {
        obj.status = 'Failure'
        obj.message = response.data.SvcMsg
        callback1(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback1(obj)
    })
}

function getSectionData (clsId, callback2) {
  var status = 'Success'
  var obj = { status: status, clsId:clsId }
  axios({
    url: httpURLTT + '/sms/tt/v1/tt/section/list',
    // url: httpURLCommon + '/sms/cls/sec/list',
    method: 'POST',
    params: { clsId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.message = ''
        obj.status = 'Success'
        obj.lstSection = response.data.lstSection
        callback2(obj)
      } else {
        obj.status = 'Failure'
        obj.message = response.data.SvcMsg
        callback2(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback2(obj)
    })
}

function getLstPtmAttendanceSvc (callBack, ptmId, clsId, sectionId) {
  axios({
    url: httpURLCommon + '/sms/ptm/attendance/list',
    method: 'POST',
    params: { ptmId: ptmId, classId: clsId, sectionId: sectionId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // console.log("response in service ", httpURLCommon+"/sms/ptm/attendance/list", Response);
      var ourData = Response.data;

      callBack(ourData)
    })
    .catch(error => {
      // console.log('error')
    })
}
function saveAttendanceSvc (pAttendance, callBack) {
  axios({
    url: httpURLCommon + '/sms/ptm/attendance/save',
    method: 'POST',
    params: { attendance: JSON.stringify(pAttendance) },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var ourData = Response.data

      callBack(ourData)
    })
    .catch(error => {
      // console.log('error')
    })
}
export {
  getClassList,
  getSectionData,
  getLstPtmAttendanceSvc,
  saveAttendanceSvc
}
