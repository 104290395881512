import axios from "axios";
import lstModules from "common/json/lstModules.json";
var queryString = require('querystring');

const httpURL = lstModules["main"].url + ":" + lstModules["main"].port;
var tokenid = localStorage.getItem("tokenid");

function getLstSchoolSvc(callback) {
  var status = "Success";
  var obj = { status: status };

  axios({
    url: httpURL + "/sms/mst/user/school/list",
    method: "POST",
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((response) => {
      if (response.data.SvcStatus === "Success") {
        obj.lstSchool = response.data.lstSchool;
        obj.message = response.data.SvcMsg;
        obj.status = "Success";
        // console.log(obj);
        callback(obj);
      }
    })
    .catch((error) => {
      obj.status = "Exception";
      obj.message = error;
      callback(obj);
    });
}

function saveDefaultSchoolId(schoolId, cb) {
  var status = "Success";
  var obj = { status: status, message: "", schoolId: schoolId };

  axios
    .post(
      httpURL + "/sms/mst/defaultSchoolId/save",
      queryString.stringify({ schoolId }),
      {
        headers: { tokenid: tokenid },
        withCredentials: true,
      }
    )
    .then((response) => {
      if (response.data.SvcStatus === "Success") {
        obj.message = response.data.SvcMsg;
        obj.status = "Success";
        cb(obj);
      } else {
        obj.status = "Failure";
        obj.message = response.data.SvcMsg;
        cb(obj);
      }
    })
    .catch((e) => {
      obj.status = "Exception";
      obj.message = e.message();
      cb(obj);
    });
}

function getLstRoleSvc(schoolId, callback) {
  var status = "Success";
  var obj = { status: status };

  axios({
    url: httpURL + "/sms/mst/user/school/role/list",
    method: "POST",
    params: { schoolId },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((response) => {
      obj.message = response.data.SvcMsg;
      obj.status = response.data.SvcStatus;
      if (response.data.SvcStatus === "Success") {
        obj.lstSecondaryRole = response.data.lstSecondaryRole;
        obj.lstSecondaryRole.unshift({
          roleId: response.data.primaryRoleId,
          role: response.data.primaryRole,
        });
        obj.primaryRoleId = response.data.primaryRoleId
        obj.wingId = response.data.wingId;
        obj.acdYrId = response.data.acdYrId;
        // console.log(obj);
      }
      callback(obj);
    })
    .catch((error) => {
      obj.status = "Exception";
      obj.message = error;
      callback(obj);
    });
}

function saveDefaultRoleId(roleId, cb) {
    var status = "Success";
    var obj = { status: status, message: "", roleId: roleId };
  
    axios
      .post(
        httpURL + "/sms/mst/defaultRoleId/save",
        queryString.stringify({ roleId }),
        {
          headers: { tokenid: tokenid },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          obj.message = response.data.SvcMsg;
          obj.status = "Success";
          cb(obj);
        } else {
          obj.status = "Failure";
          obj.message = response.data.SvcMsg;
          cb(obj);
        }
      })
      .catch((e) => {
        obj.status = "Exception";
        obj.message = e.message();
        cb(obj);
      });
  }
  
export { getLstSchoolSvc, getLstRoleSvc, saveDefaultSchoolId, saveDefaultRoleId };
