import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { enqDtlsSvc, getClassListSvc, getStreamListSvc, getCityListSvc, getLocalityListSvc, getSourceListSvc, getCampaignListSvc, getStaffListSvc, getStateListSvc, saveEnqSvc } from './EnquirySvc';
import 'react-datepicker/dist/react-datepicker.css';
import Datepicker from 'react-datepicker'
import SliderType from './sliderType';
import { getClassList } from 'sms/classes/js/classService';
import { getLstState } from 'sms/setup/js/schoolProfile/js/schoolProfileSvc';

const EnquirySlider = (props) => {
    const [loader, setLoader] = useState('none')
    const [enqId, setEnqId] = useState(props.enquiryId)
    const [stateId, setStateId] = useState(0)
    const [userId, setUserId] = useState(0)
    /*const [userId,        setUserId]=useState([])
    const [passYr,          setPassYr]=useState([])*/
    const [enqDt, setEnqDt] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [contactNo, setContactNo] = useState('')
    const [gender, setGender] = useState('')
    const [dob, setDob] = useState('')
    const [clsId, setClsId] = useState(0)
    const [streamId, setStreamId] = useState(0)
    const [lastPct, setLastPct] = useState('')
    const [lastSchool, setLastSchool] = useState('')
    const [cityId, setCityId] = useState(0)
    const [localityId, setLocalityId] = useState(0)
    const [query, setQuery] = useState('')
    const [internalNotes, setInternalNotes] = useState('')
    const [sourceId, setSourceId] = useState(0)
    const [campaignId, setCampaignId] = useState(0)
    const [staffId, setStaffId] = useState(0)
    const [assignDt, setAssignDt] = useState('')
    const [regsId, setRegsId] = useState(0)
    const [regsAmt, setRegsAmt] = useState(0)
    const [regsDt, setRegsDt] = useState('')
    const [lstCls, setLstCls] = useState([])
    const [lstStream, setLstStream] = useState([])
    const [lstLocality, setLstLocality] = useState([])
    const [lstSource, setLstSource] = useState([])
    const [lstCampaign, setLstCampaign] = useState([])
    const [lstCity, setLstCity] = useState([])
    const [lstState, setLstState] = useState([])
    const [lstStaff, setLstStaff] = useState([])
    const [enqDtls, setEnqDtls] = useState({})
    let isMounted = false;
    const controller = new AbortController()
    const signal = controller.signal;

    function cbEnqResponse(data) {
        if (isMounted) {
            if (data.SvcStatus === "Success") {
                if (typeof data.enq === "String") {
                    return;
                }
                // console.log(data)
                setEnqDtls(data.enq)
                setEnqDtls(data.enq)
                setStateId(data.enq.stateId)
                if (data.enq.enqDt !== '-')
                    setEnqDt(data.enq.enqDt);
                setName(data.enq.name)
                setEmail(data.enq.email)
                setContactNo(data.enq.contact)
                setGender(data.enq.gender)
                setDob(data.enq.dob);
                setClsId(data.enq.clsId)
                setStreamId(data.enq.streamId)
                setLastPct(data.enq.lastPct)
                setLastSchool(data.enq.lastSchool)
                setQuery(data.enq.query)
                setInternalNotes(data.enq.internalNotes)
                setSourceId(data.enq.sourceId)
                setCampaignId(data.enq.campaignId)
                setStaffId(data.enq.assignedToId)
                if (data.enq.assignDt !== '-')
                    setAssignDt(data.enq.assignDt)
                setRegsId(data.enq.regsId)
                setRegsAmt(data.enq.regsAmt)
                if (data.enq.regsDt !== '-')
                    setRegsDt(data.enq.regsDt)
                getCityListSvc(cbCityResponseChange, data.enq.stateId, data.enq.cityId);
                getLocalityListSvc(cbLocalityResponsechange, data.enq.cityId, data.enq.localityId)
                setUserId(data.enq.userId);
            } else {
                toast.error(data.SvcMsg)
            }
        }
        setLoader('none')
    }

    function cbClsResponseChange(data) {
        if (isMounted) {
            if (data.SvcStatus === "Success") {
                setLstCls(data.lstClass)
            } else {
                if (data.SvcMsg === "You are not logged in") {
                    setTimeout(function () {
                        window.location.href = "/";
                    }, 2000);
                    return false;
                } else if (data.SvcMsg === "Your Session is expired, login again") {
                    setTimeout(function () {
                        window.location.href = "/";
                    }, 2000);
                    return false;
                } else {
                    toast.error(data.SvcMsg);
                }
            }
        }
        setLoader('none')
    }
    function cbStreamResponseChange(data) {
        if (isMounted) {
            if (data.SvcStatus === "Success") {
                setLstStream(data.lstStream)
            } else {
                if (data.SvcMsg === "You are not logged in") {
                    setTimeout(function () {
                        window.location.href = "/";
                    }, 2000);
                    return false;
                } else if (data.SvcMsg === "Your Session is expired, login again") {
                    setTimeout(function () {
                        window.location.href = "/";
                    }, 2000);
                    return false;
                } else {
                    toast.error(data.SvcMsg);
                }
            }
        }
        setLoader('none')
    }
    function cbStateResponseChange(data) {
        if (isMounted) {
            if (data.SvcStatus === "Success") {
                setLstState(data.lstState)
            } else {
                if (data.SvcMsg === "You are not logged in") {
                    setTimeout(function () {
                        window.location.href = "/";
                    }, 2000);
                    return false;
                } else if (data.SvcMsg === "Your Session is expired, login again") {
                    setTimeout(function () {
                        window.location.href = "/";
                    }, 2000);
                    return false;
                } else {
                    toast.error(data.SvcMsg);
                }
            }
        }
        setLoader('none')
    }
    function cbCityResponseChange(data, pCityId) {
        if (data.SvcStatus === "Success") {
            setLstCity(data.lstCity)
            setCityId(pCityId)
        } else {
            if (data.SvcMsg === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (data.SvcMsg === "Your Session is expired, login again") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(data.SvcMsg);
            }
        }
        setLoader('none')
    }
    function cbLocalityResponsechange(data, pLocalityId) {
        if (data.SvcStatus === "Success") {
            setLstLocality(data.lstLocality)
            setLocalityId(pLocalityId)
        } else {
            if (data.SvcMsg === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (data.SvcMsg === "Your Session is expired, login again") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(data.SvcMsg);
            }
        }
        setLoader('none')
    }
    function cbSourceResponsechange(data) {
        if (isMounted) {
            if (data.SvcStatus === "Success") {
                setLstSource(data.lstSource)
            } else {
                if (data.SvcMsg === "You are not logged in") {
                    setTimeout(function () {
                        window.location.href = "/";
                    }, 2000);
                    return false;
                } else if (data.SvcMsg === "Your Session is expired, login again") {
                    setTimeout(function () {
                        window.location.href = "/";
                    }, 2000);
                    return false;
                } else {
                    toast.error(data.SvcMsg);
                }
            }
        }
        setLoader('none')
    }
    function cbCampaignResponsechange(data) {
        if (isMounted) {
            if (data.SvcStatus === "Success") {
                setLstCampaign(data.lstCampaign)
            } else {
                if (data.SvcMsg === "You are not logged in") {
                    setTimeout(function () {
                        window.location.href = "/";
                    }, 2000);
                    return false;
                } else if (data.SvcMsg === "Your Session is expired, login again") {
                    setTimeout(function () {
                        window.location.href = "/";
                    }, 2000);
                    return false;
                } else {
                    toast.error(data.SvcMsg);
                }
            }
        }
        setLoader('none')
    }
    function cbStaffResponsechange(data) {
        if (isMounted) {
            if (data.SvcStatus === "Success") {
                setLstStaff(data.lstStaff)
            } else {
                if (data.SvcMsg === "You are not logged in") {
                    setTimeout(function () {
                        window.location.href = "/";
                    }, 2000);
                    return false;
                } else if (data.SvcMsg === "Your Session is expired, login again") {
                    setTimeout(function () {
                        window.location.href = "/";
                    }, 2000);
                    return false;
                } else {
                    toast.error(data.SvcMsg);
                }
            }
        }
        setLoader('none')
    }

    useEffect(() => {
        isMounted = true;
        // var promise = new Promise((resolve, reject) => {
        if (props.flgSliderType === SliderType.SLIDER_ENQUIRY_DTLS) {
            if (props.enquiryId) {
                setEnqId(props.enquiryId)
                // console.log("enqId" + props.enquiryId);
                setLoader('block')
                enqDtlsSvc(props.enquiryId, cbEnqResponse)
            }
            getLstClass();
            getLstStream();
            getLstSource();
            getLstCampaign();
            getLstStaff();
            getLstState();

            //        getCityListSvc(cbCityResponseChange)
            //        getLocalityListSvc(cbLocalityResponsechange)



        }
        // });
        return () => {
            isMounted = false;
            controller.abort()
        }
    }, [props.flgSliderType, props.enquiryId])
    function getLstClass() {
        setLoader('block')
        getClassListSvc(cbClsResponseChange)
    }
    function getLstStream() {
        setLoader('block')
        getStreamListSvc(cbStreamResponseChange)
    }
    function getLstSource() {
        setLoader('block')
        getSourceListSvc(cbSourceResponsechange)
    }
    function getLstCampaign() {
        setLoader('block')
        getCampaignListSvc(cbCampaignResponsechange)
    }
    function getLstStaff() {
        setLoader('block')
        getStaffListSvc(cbStaffResponsechange)
    }
    function getLstState() {
        setLoader('block')
        getStateListSvc(cbStateResponseChange)
    }
    function getDateForCalendar(strDt) {
        if (!strDt)
            return null;
        var parts = strDt.split('-')
        var d = parts[0];
        var m = parts[1] - 1;
        var y = parts[2];

        return new Date(y, m, d);
    }
    function handleNameChange(param) {
        setName(param)
    }
    function handleEnqDtChange(param) {
        var m = param.getMonth() + 1;
        if (m < 10) {
            m = "0" + m;
        }
        var d = param.getDate();
        if (d < 10) {
            d = "0" + d;
        }
        const dt = d + "-" + (m) + "-" + param.getFullYear()
        setEnqDt(dt)
    }
    function handleEmailChange(param) {
        setEmail(param)
    }
    function handleContactNoChange(param) {
        setContactNo(param)
    }
    function handleGenderChange(param) {
        setGender(param)
    }
    function handleDobChange(param) {
        var m = param.getMonth() + 1;
        if (m < 10) {
            m = "0" + m;
        }
        var d = param.getDate();
        if (d < 10) {
            d = "0" + d;
        }
        const dt = d + "-" + (m) + "-" + param.getFullYear()
        setDob(dt)
    }

    function handleClsIdChange(param) {
        setClsId(param)
    }
    function handleStreamIdChange(param) {
        setStreamId(param)
    }
    function handleLastPctChange(param) {
        setLastPct(param)
    }
    function handleLastSchoolChange(param) {
        setLastSchool(param)
    }
    function handleStateIdChange(param) {
        setStateId(param)
        getCityListSvc(cbCityResponseChange, param)
    }
    function handleCityIdChange(param) {
        setCityId(param)
        getLocalityListSvc(cbLocalityResponsechange, param)
    }
    function handleLocalityIdChange(param) {
        setLocalityId(param)
    }
    function handleQueryChange(param) {
        setQuery(param)
    }
    function handleInternalNotesChange(param) {
        setInternalNotes(param)
    }
    function handleSourceIdChange(param) {
        setSourceId(param)
    }
    function handleCampaignIdChange(param) {
        setCampaignId(param)
    }
    function handleStaffIdChange(param) {
        setStaffId(param)
    }
    function handleAssignDtChange(param) {
        var m = param.getMonth() + 1;
        if (m < 10) {
            m = "0" + m;
        }
        var d = param.getDate();
        if (d < 10) {
            d = "0" + d;
        }
        const dt = d + "-" + (m) + "-" + param.getFullYear()
        setAssignDt(dt)
    }
    function saveEnq() {
        const enqObj = {
            name: name,
            enqDt: !enqDt ? null : enqDt,
            email: email,
            contact: contactNo,
            gender: gender,
            dob: !dob ? null : dob,
            clsId: clsId,
            streamId: streamId,
            lastPct: lastPct,
            lastSchool: lastSchool,
            stateId: stateId,
            cityId: cityId,
            localityId: localityId,
            query: query,
            internalNotes: internalNotes,
            sourceId: sourceId,
            campaignId: campaignId,
            assignedToId: staffId,
            assignDt: !assignDt ? null : assignDt,
            enqId: enqId,
            userId: userId,
            passYr: "2016"
        }

        // Check name
        if (enqObj.name === "") {
            toast.warning("Please fill student name");
            return false;
            setLoader("none");
        }

        // Check enqDt
        if (!enqObj.enqDt) {
            toast.warning("Please provide enquiry date");
            return false;
            setLoader("none");
        }

        // Check email
        if (enqObj.email === "") {
            toast.warning("Please enter email address");
            return false;
            setLoader("none");
        }

        // Check contact
        if (!enqObj.contact) {
            toast.warning("Please provide contact number");
            return false;
            setLoader("none");
        }

        // Check gender
        if (enqObj.gender === "") {
            toast.warning("Please select gender");
            return false;
            setLoader("none");
        }

        // Check dob
        if (!enqObj.dob) {
            toast.warning("Please select date of birth");
            return false;
            setLoader("none");
        }

        // Check stateId
        if (!enqObj.stateId) {
            toast.warning("Please select state");
            return false;
            setLoader("none");
        }

        // Check cityId
        if (!enqObj.cityId) {
            toast.warning("Please select city");
            return false;
            setLoader("none");
        }

        // Check localityId
        if (!enqObj.localityId) {
            toast.warning("Please select locality");
            return false;
            setLoader("none");
        }

        // Check query
        if (enqObj.query === "") {
            toast.warning("Please provide student query");
            return false;
            setLoader("none");
        }

        // Check assignDt with assignedToId
        if (enqObj.assignedToId && !enqObj.assignDt) {
            toast.warning("Please assign a date when assigning to staff.");
            return false;
            setLoader("none");
        }


        
        setLoader('block')

        saveEnqSvc(enqObj, cbSaveEnq)
    }
    function cbSaveEnq(data) {
        if (data.SvcStatus === "Success") {
            props.setSelectedEnquiryId(data.enqId);
            toast.success(data.SvcMsg)
            props.generateRandomNo();
            props.closeSlider();
        } else {
            if (data.SvcMsg === "You are not logged in") {
                toast.error(data.SvcMsg);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (data.SvcMsg === "Your Session is expired, login again") {
                toast.error(data.SvcMsg);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(data.SvcMsg);
            }
        }
        setLoader('none');
    }

    return (
        <form className='form form-horizontal' style={{ width: '100%', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10, pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." /></div>

            <div className='EnquiryHead col-sm-12 col-centered' style={{
                border: "1px solid #aaa", borderTopLeftRadius: 10,
                borderTopRightRadius: 10, borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5, fontWeight: 'bold'
            }}><h4>Enquiry Form</h4></div>
            <div className='row' style={{ width: '100%' }}>
                <div className="col-sm-7 padding-remove pull-right" style={{ border: "1px solid #aaa", marginBottom: '1%' }}>
                    <label className="control-label col-sm-5">Registration Amount</label>
                    <label className='col-sm-6 control-label padding-remove'>Rs.{regsAmt}</label>
                </div>
            </div>
            <div className='row' style={{ width: '100%' }}>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Enquiry Date</label>
                    <div className='col-sm-6 padding-remove'>
                        <Datepicker
                            type='date'
                            className="form-control"
                            selected={getDateForCalendar(enqDt)}
                            onChange={handleEnqDtChange}
                            dateFormat="dd-MM-yyyy"
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"></Datepicker>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5"> Enquiry No.</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type="text" defaultValue={enqId} className="form-control" readOnly></input>
                    </div>
                </div>
            </div>
            <div className="h-divider">
            </div>
            <div className='row' style={{ width: '100%' }}>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Name<span className="red-color">*</span></label>
                        <div className='col-sm-6 padding-remove'>
                            <input type='text' className="form-control" maxLength={60} defaultValue={name} onChange={(e) => handleNameChange(e.target.value)} required></input>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Email<span className="red-color">*</span></label>
                        <div className='col-sm-6 padding-remove'>
                            <input type='text' className="form-control" maxLength={100} defaultValue={email} onChange={(e) => handleEmailChange(e.target.value)}></input>
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Contact No. <span className="red-color">*</span></label>
                        <div className='col-sm-6 padding-remove'>
                            <input type='tel' className="form-control" id="phone" name="phone" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" defaultValue={contactNo} onChange={(e) => handleContactNoChange(e.target.value)}></input>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Gender<span className="red-color">*</span></label>
                        <div className='col-sm-6 padding-remove'>
                            <label className="radio-inline"><input type='radio' name="gender" value="M" checked={gender === "M"} onChange={(e) => handleGenderChange(e.target.value)}></input>Male</label>
                            <label className="radio-inline"><input type='radio' name="gender" value="F" checked={gender === "F"} onChange={(e) => handleGenderChange(e.target.value)}></input>Female</label>
                            <label className="radio-inline"><input type='radio' name="gender" value="O" checked={gender === "O"} onChange={(e) => handleGenderChange(e.target.value)}></input>Other</label>
                            {/* <input type='text'  onChange={(e) => handleGenderChange(e.target.value)}></input>*/}
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Date of Birth<span className="red-color">*</span></label>
                        <div className='col-sm-6 padding-remove'>
                            <Datepicker
                                type='date'
                                className="form-control"
                                selected={getDateForCalendar(dob)}
                                onChange={handleDobChange}
                                dateFormat="dd-MM-yyyy"
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"></Datepicker>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Class (applied for)<span className="red-color">*</span></label>
                        <div className='col-sm-6 padding-remove'>
                            <select className="form-control" onChange={(e) => handleClsIdChange(e.target.value)} value={clsId}>
                                <option value={0}>Class</option>
                                {lstCls.map((cls, idx) => {
                                    return <option key={idx} value={cls.clsId}>{cls.cls}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                {
                    clsId > 13 && <div className='row' style={{ width: '100%' }}>
                        <div className="form-group col-md-6">
                            <label className="control-label col-sm-5">Stream<span className="red-color">*</span></label>
                            <div className='col-sm-6 padding-remove'>
                                <select className="form-control" onChange={(e) => handleStreamIdChange(e.target.value)} value={streamId}>
                                    <option value={0}>Stream</option>
                                    {lstStream.map((stream, idx) => {
                                        return <option key={idx} value={stream.streamId}>{stream.stream}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="h-divider">
            </div>

            <div className='row' style={{ width: '100%' }}>
                <div className="form-group col-md-4">
                    <label className="control-label col-sm-5">State<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <select className="form-control" onChange={(e) => handleStateIdChange(e.target.value)} value={stateId}>
                            <option value={0}>State</option>
                            {lstState.map((stat, idx) => {
                                return <option key={idx} value={stat.stateId}>{stat.state}</option>
                            })}
                        </select>
                    </div>
                </div>

                <div className="form-group col-md-4">
                    <label className="control-label col-sm-5">City<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <select className="form-control" onChange={(e) => handleCityIdChange(e.target.value)} value={cityId}>
                            <option value={0}>City</option>
                            {lstCity.map((city, idx) => {
                                return <option key={idx} value={city.cityId}>{city.city}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label className="control-label col-sm-5">Locality<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <select className="form-control" onChange={(e) => handleLocalityIdChange(e.target.value)} value={localityId}>
                            <option value={0}>Locality</option>
                            {lstLocality.map((local, idx) => {
                                return <option key={idx} value={local.localityId}>{local.locality}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <div className="h-divider">
            </div>
            <div className='row' style={{ width: '100%' }}>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Last %</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='number' min="1" max="100" className="form-control" maxLength={60} defaultValue={lastPct} onChange={(e) => handleLastPctChange(e.target.value)}></input>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Last School</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='text' className="form-control" maxLength={100} defaultValue={lastSchool} onChange={(e) => handleLastSchoolChange(e.target.value)}></input>
                    </div>
                </div>
            </div>
            <div className="h-divider">
            </div>
            <div className='row' style={{ width: '100%' }}>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Query<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <textarea rows="8"
                            cols="80" maxLength={500} defaultValue={query} className="form-control" onChange={(e) => handleQueryChange(e.target.value)} required></textarea>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Internal Notes</label>
                    <div className='col-sm-6 padding-remove'>
                        <textarea rows="8"
                            cols="80" maxLength={1000} defaultValue={internalNotes} className="form-control" onChange={(e) => handleInternalNotesChange(e.target.value)}></textarea>
                    </div>
                </div>
            </div>
            <div className="h-divider">
            </div>
            <div className='row' style={{ width: '100%' }}>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Source</label>
                    <div className='col-sm-6 padding-remove'>
                        <select className="form-control" onChange={(e) => handleSourceIdChange(e.target.value)} value={sourceId}>
                            <option value={0}>Source</option>
                            {lstSource.map((source, idx) => {
                                return <option key={idx} value={source.sourceId}>{source.source}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Campaign</label>
                    <div className='col-sm-6 padding-remove'>
                        <select className="form-control" onChange={(e) => handleCampaignIdChange(e.target.value)} value={campaignId}>
                            <option value={0}>Campaign</option>
                            {lstCampaign.map((camp, idx) => {
                                return <option key={idx} value={camp.campaignId}>{camp.campaign}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <div className="h-divider">
            </div>
            <div className='row' style={{ width: '100%' }}>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Assigned To</label>
                    <div className='col-sm-6 padding-remove'>
                        <select className="form-control" onChange={(e) => handleStaffIdChange(e.target.value)} value={staffId}>
                            <option value={0}>Assigned To</option>
                            {lstStaff.map((assignTo, idx) => {
                                return <option key={idx} value={assignTo.staffId}>{assignTo.staff}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Assign Date</label>
                    <div className='col-sm-6 padding-remove'>
                        <Datepicker type='date' className="form-control" selected={getDateForCalendar(assignDt)} onChange={handleAssignDtChange} dateFormat="dd-MM-yyyy"></Datepicker>
                    </div>
                </div>
            </div>
            <div className="h-divider">
            </div>
            <div className='row' style={{ width: '100%' }}>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5"> Registration No.</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type="text" defaultValue={regsId} className="form-control" readOnly></input>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5"> Registration Date.</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type="text" defaultValue={regsDt} className="form-control" readOnly></input>
                    </div>
                </div>
            </div>
            <div className="h-divider">
            </div>
            <div className='row' style={{ width: '100%' }}>
                <button type="button" className="btn btn-info " onClick={(e) => saveEnq()}>Save</button>
            </div>
        </form >
    )
}

export default EnquirySlider;