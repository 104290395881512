import React, { useState, useEffect } from "react";
// import { getBorrowerBookListSvc, saveIssueBookSvc } from "./issueBooksSvc";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { ReturnSlider } from "./returnSlider";

export function IssuedBookTable(props) {
  const [lstBook, setLstBook] = useState([]);
  const [objBorrowerDtls, setObjBorrowerDtls] = useState({});
  const [sliderDisplayed, setSliderDisplayed] = useState(0);
  const [sliderKey, setSliderKey] = useState(0);

  useEffect(() => {
    // console.log(props);
    setLstBook(props.lstBook);
    var lstTmpBook = props.lstBook;
    lstTmpBook.forEach((obj, idx) => {
      obj.selected = false;
    });
    lstTmpBook = undefined;
  }, [props.lstBook]);

  async function handleCboxChange(pBookNo, pCopyNo, status) {
    var lstTmpBook = lstBook;
    for (let i = 0; i < lstTmpBook.length; i++) {
      var obj = lstTmpBook[i];
      if (obj.bookNo === pBookNo && obj.copyNo === pCopyNo) {
        obj.selected = status;
        break;
      }
    }
    await setLstBook(lstTmpBook);
  }
  function returnItemClick(obj) {
    var lstTmpBook = [];
    var tmpObj = { bookNo: obj.bookNo, copyNo: obj.copyNo };
    lstTmpBook.push(tmpObj);
    // console.log(lstTmpBook);
    setObjBorrowerDtls({
      lstBookCopyNo: lstTmpBook,
      totalFine: parseInt(obj.fine),
      totalSecurityDeposit: parseInt(obj.securityDeposit),
      staffId: obj.staffId,
      studentId: obj.studentId,
      flgStaff: obj.flgStaff,
    });
    setSliderKey(Math.floor(Math.random() * 999999));
    setSliderDisplayed(1);
  }
  function returnClick() {
    var lstTmpBook = [],
      tmpTotalFine = 0,
      tmpTotalSecurityDeposit = 0,
      vFlgError = false,
      vFlgStaff = false;
    var vBorrowerId = 0,
      vStudentId,
      vStaffId = 0;
    for (let i = 0; i < lstBook.length; i++) {
      var obj = lstBook[i];
      // console.log(i + " - " + obj.selected);
      if (obj.selected) {
        if (i !== 0 && !vFlgError) {
          if (obj.borrower !== vBorrowerId) {
            vFlgError = true;
          }
        } else {
          vBorrowerId = obj.borrower;
          vStaffId = obj.staffId;
          vStudentId = obj.studentId;
          vFlgStaff = obj.flgStaff;
        }
        var tmpObj = { bookNo: obj.bookNo, copyNo: obj.copyNo };
        lstTmpBook.push(tmpObj);
        tmpTotalFine += parseInt(obj.fine);
        tmpTotalSecurityDeposit += parseInt(obj.securityDeposit);
      }
    }
    if (lstTmpBook.length === 0) {
      toast.warn("Please select atleast one book to return");
      return;
    }

    if (vFlgError) {
      toast.warn("Multiple books can be returned only for the same borrower");
      return;
    }
    // console.log(lstTmpBook);
    setObjBorrowerDtls({
      lstBookCopyNo: lstTmpBook,
      totalFine: tmpTotalFine,
      totalSecurityDeposit: tmpTotalSecurityDeposit,
      staffId: vStaffId,
      studentId: vStudentId,
      flgStaff: vFlgStaff,
    });
    setSliderKey(Math.floor(Math.random() * 999999));
    setSliderDisplayed(1);
  }
  const colgrp = (
    <colgroup>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="25%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="8%"></col>
      <col width="8%"></col>
      <col width="8%"></col>
    </colgroup>
  );
  function isLessThanToday(mydate) {
    var q = new Date();
    var m = q.getMonth();
    var d = q.getDate();
    var y = q.getFullYear();
    var date = new Date(y, m, d);
    var tmpArr = mydate.split("-");
    d = tmpArr[0];
    m = tmpArr[1] - 1;
    y = tmpArr[2];

    mydate = new Date(y, m, d);
    return mydate < date;
  }
  return (
    <form
      className="form form-horizontal"
      style={{
        width: "100%",
        background: "#fff",
        padding: 10,
      }}
    >
      <div className="col-sm-12 padding-remove">
        <div
          className="col-sm-1 padding-remove pull-right"
          style={{ margin: "5px 15px 5px 0" }}
        >
          <button
            type="button"
            className="btn btn-info "
            onClick={() => returnClick()}
          >
            Return
          </button>
        </div>
      </div>
      <div className="col-sm-12 padding-remove">
        <table className="tableLayout1">
          {colgrp}
          <thead>
            <tr>
              <th>Issue Date</th>
              <th>Book No.</th>
              <th>Title</th>
              <th>Borrower</th>
              <th>Staff/Student</th>
              <th>Security Deposit</th>
              <th>Due Date</th>
              <th>Fine</th>
              <th>Select</th>
              <th>Action</th>
            </tr>
          </thead>
        </table>
        <div style={{ height: "40vh", overflowY: "scroll" }}>
          <table className="tableLayout">
            {colgrp}
            <tbody>
              {lstBook.map((obj, key) => {
                return (
                  <tr key={key}>
                    <td className="text-center">{obj.issueDt}</td>
                    <td className="text-left">{`${obj.bookNo}-${obj.copyNo}`}</td>
                    <td className="text-left">{obj.title}</td>
                    <td className="text-left">{`${obj.borrower} - ${obj.name}`}</td>
                    <td className="text-center">
                      {obj.flgStaff ? "Staff" : "Student"}
                    </td>
                    <td className="text-right">{obj.securityDeposit}</td>
                    <td
                      className="text-center"
                      style={{
                        color: isLessThanToday(obj.dueDt) ? "red" : "",
                        fontWeight: isLessThanToday(obj.dueDt) ? "bold" : "",
                      }}
                    >
                      {obj.dueDt}
                    </td>
                    <td
                      className="text-right"
                      style={{
                        color: obj.fine > 0 ? "red" : "",
                        fontWeight: obj.fine > 0 ? "bold" : "",
                      }}
                    >
                      {obj.fine}
                    </td>
                    <td className={"text-center"}>
                      <input
                        onClick={(e) =>
                          handleCboxChange(
                            obj.bookNo,
                            obj.copyNo,
                            e.target.checked
                          )
                        }
                        defaultChecked={obj.selected}
                        type="checkbox"
                      />
                    </td>
                    <td className="text-center">
                      <a onClick={(e) => {e.preventDefault();returnItemClick(obj);}}>Return</a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-sm-7 col-centered padding-remove">
        {sliderDisplayed === 1 && (
          <ReturnSlider
            key={sliderKey}
            sliderKey={sliderKey}
            objBorrowerDtls={objBorrowerDtls}
            sliderDisplayed={sliderDisplayed === 1}
            setSliderDisplayed={setSliderDisplayed}
          />
        )}
      </div>
    </form>
  );
}
