import { useEffect, useState } from "react";
import { toast } from 'react-toastify';

import { saveAcademicYearWingListSvc } from "./academicYearSvc";

const AcademicYearWingTable = (props) => {

    const [lstAcademicYearWing, setLstAcademicYearWing] = useState(props.lstAcademicYearWing);
    const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
    const [wingTableKey, setWingTableKey] = useState(0);

    useEffect(() => {
        // console.log("lstAcademicYearWing in AcademicYearWingTable", lstAcademicYearWing);
        setLstAcademicYearWing(lstAcademicYearWing);
    }, [wingTableKey]);


    const savewing = () => {
        const academicYearId = props.academicYearId;
        const lstWings = [];

        let acdyrWing = {};
        lstAcademicYearWing.map((obj) => {
            if (obj.applied) {
                const wingRow = {
                    "wingId": obj.wingId,
                    "dflt": obj.dflt,
                    "locked": obj.locked
                }
                lstWings.push(wingRow);
            }
        })


        acdyrWing = { academicYearId, lstWings }
        if (lstWings.length === 0) {
            toast.warning('Please Select Altleast one Wing!');
            return false;
        }
        var defaultSelected = 0;
        lstAcademicYearWing.map((obj) => {
            if (obj.dflt) {
                defaultSelected++;
            }
        })
        if (defaultSelected === 0) {
            toast.warning('Please select one wing as default!');
            return false;
        } else if (defaultSelected > 1) {
            toast.warning('Only one wing can be default!');
            return false;
        }
        props.setLoader("block");
        props.setOpacity(0.5);
        saveAcademicYearWingListSvc(acdyrWing, saveAcademicYearWingListResponse);
    }

    const saveAcademicYearWingListResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            // console.log("obj", obj);
            toast.success(obj.message);
            // props.changeSliderKey();
            props.closeSlider();
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        props.setLoader("none");
        props.setOpacity(1);
    }

    const selectAllWings = () => {
        var tmpLstAcademicYearWing = [...lstAcademicYearWing];
        if(!isSelectAllSelected){
            for (var i = 0; i < tmpLstAcademicYearWing.length; i++) {
                tmpLstAcademicYearWing[i] = { ...tmpLstAcademicYearWing[i], applied: true }
            }
            setIsSelectAllSelected(true);
        } else {
            for (var i = 0; i < tmpLstAcademicYearWing.length; i++) {
                tmpLstAcademicYearWing[i] = { ...tmpLstAcademicYearWing[i], applied: false }
            }
            setIsSelectAllSelected(false);
        }

        setLstAcademicYearWing(tmpLstAcademicYearWing);
        setWingTableKey(prevKey => prevKey + 1);
    }

    const toggleSelectApplyWing = (wId) => {
        var tmpLstAcademicYearWing = [...lstAcademicYearWing];
        var totalApplied = 0;
        lstAcademicYearWing.map((obj) => {
            if (obj.applied) {
                totalApplied++;
            }
        })
        for (var i = 0; i < tmpLstAcademicYearWing.length; i++) {
            if (tmpLstAcademicYearWing[i].wingId === wId) {
                if (totalApplied === lstAcademicYearWing.length-1 && !tmpLstAcademicYearWing[i].applied) {
                    setIsSelectAllSelected(true);
                } else {
                    setIsSelectAllSelected(false);
                }
                tmpLstAcademicYearWing[i] = {
                    ...tmpLstAcademicYearWing[i],
                    applied: !tmpLstAcademicYearWing[i].applied,
                };
                break;
            }
        }
        setLstAcademicYearWing(tmpLstAcademicYearWing);
    }

    const toggleSelectLockedWing = (wId) => {
        var tmpLstAcademicYearWing = [...lstAcademicYearWing];
        for (var i = 0; i < tmpLstAcademicYearWing.length; i++) {
            if (tmpLstAcademicYearWing[i].wingId === wId) {
                tmpLstAcademicYearWing[i] = {
                    ...tmpLstAcademicYearWing[i],
                    locked: !tmpLstAcademicYearWing[i].locked,
                };
                break;
            }
        }
        setLstAcademicYearWing(tmpLstAcademicYearWing);
    }

    const toggleSelectDefault = (wId) => {
        var tmpLstAcademicYearWing = [...lstAcademicYearWing];
        for (var i = 0; i < tmpLstAcademicYearWing.length; i++) {
            if (tmpLstAcademicYearWing[i].wingId === wId) {
                tmpLstAcademicYearWing[i] = {
                    ...tmpLstAcademicYearWing[i],
                    dflt: !tmpLstAcademicYearWing[i].dflt,
                };
                if(tmpLstAcademicYearWing[i].dflt === true){
                    tmpLstAcademicYearWing[i] = { ...tmpLstAcademicYearWing[i], applied: true }
                }
                break;
            }
        }
        setLstAcademicYearWing(tmpLstAcademicYearWing);
    }


    return (
        <>
            <table className="tableLayout1" id='customTable'>
                <thead>
                    <tr>
                        <th width="130" className="selectAll">
                            <div style={{ display: "table-row" }}>
                                <span style={{ display: "table-cell", verticalAlign: "middle", paddingLeft: "10px", paddingRight: "10px" }}>Apply</span>
                                <input type="checkbox"
                                    checked={isSelectAllSelected}
                                    onClick={selectAllWings}
                                />
                            </div>
                        </th>
                        <th width="100">Wing</th>
                        <th width="100">Default</th>
                        <th width="100">Locked</th>
                    </tr>
                </thead>
            </table>
            <div className="tableDivSmall">
                <table id="mainTable" className="tableLayout">
                    <tbody id="wingTableBody">
                        {lstAcademicYearWing.map((academicYear) => (
                            <tr key={academicYear.wingId} id={academicYear.academicYearId}>
                                <td className="no-display">{academicYear.wingId}</td>
                                <td width="130" className="text-center"><input type="checkbox" className="formControl applyWingCbx" checked={academicYear.applied} onChange={() => toggleSelectApplyWing(academicYear.wingId)} /></td>
                                <td width="100" className="text-center">{academicYear.wing}</td>
                                <td width="100" className="text-center"><input type="checkbox" className="formControl " checked={academicYear.dflt} onChange={() => toggleSelectDefault(academicYear.wingId)} /></td>
                                <td width="100" className="text-center"><input type="checkbox" className="formControl " checked={academicYear.locked} onChange={() => toggleSelectLockedWing(academicYear.wingId)} /></td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>
            <div className="text-right">
                <button type="button" style={{ marginTop: 20 }} className="btn btn-success" onClick={savewing}>Save</button>
            </div>
        </>
    );
}

export default AcademicYearWingTable;