import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

var screenId = 8;
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules['performance'].url + ":" + lstModules['performance'].port;
const academicYearId= localStorage.getItem('academicYearId') || 0;


function getClassListSvc(callback1) {
    // console.log("getClassListSvc service called");
    axios({
        url: httpURL + '/sms/cls/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log('response in getClassListSvc', response);
        callback1(response.data);
    }).catch((error) => {
        callback1(error);
    });
}

function getSectionListSvc(callback2, pClassId) {
    // console.log("getSectionListSvc called, pClassId passed", pClassId);
    axios({
        url: httpURL + "/sms/cls/sec/list",
        method: "POST",
        params: { screenId: 8, classId: pClassId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log('response in getSectionListSvc', response);
        // console.log('data.SvcStatus=' + response.data.SvcStatus);
        callback2(response.data);
    }).catch((error) => {
        callback2(error);
    });
}

export const remarksListSvc = (sectionId, examId, cbRemarkList) => {
    // console.log("remarksListSvc called, url", httpURL + '/sms/reportcard/examreportcard/remarks/list');
    const obj = { status: "", message: "" };
    const formData = new FormData();
    formData.append("sectionId", sectionId);
    formData.append("examId", examId);
    formData.append("acdYrId", academicYearId);
    axios({
        url: httpURL + '/sms/reportcard/examreportcard/remarks/list',
        method: "POST",
        // params: { "schoolId": schoolId },
        data:formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log("response in remarksListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstRemark = response.data.lstRemark;
        }
        cbRemarkList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbRemarkList(obj);
    })
}

export const saveRemarkSvc = (examRemarksSaveRQ, cbSaveRemark) => {
    // console.log("saveRemarkSvc called, examRemarksSaveRQ", examRemarksSaveRQ, ", url", httpURL + '/sms/reportcard/examreportcard/remarks/save');
    const obj = { status: "", message: "" };
    const formData = new FormData();
    formData.append("examRemarksSaveRQ", JSON.stringify(examRemarksSaveRQ));
    axios({
        url: httpURL + '/sms/reportcard/examreportcard/remarks/save',
        method: "POST",
        // params: { "schoolId": schoolId },
        data:formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log("response in saveRemarkSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstRemark = response.data.lstRemark;
        }
        cbSaveRemark(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSaveRemark(obj);
    })
}

export { getClassListSvc, getSectionListSvc };
