import axios from "axios";
import lstModules from "common/json/lstModules.json";

const httpURL =
  lstModules["performance"].url + ":" + lstModules["performance"].port;

var tokenid = localStorage.getItem("tokenid");
var screenId = 8;

function getLstExamSvc(pTermId, callback) {
  console.log("getLstExamSvc called, parameter- pTermId", pTermId);
  var status = "Success";
  var obj = { lstExam: [], status: status };

  axios({
    url: httpURL + "/sms/perf/performance/exam/list",
    method: "POST",
    params: { termId: pTermId },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((response) => {
      obj.status = response.data.SvcStatus;
      obj.message = response.data.SvcMsg;
      if (response.data.SvcStatus === "Success") {
        obj.lstExam = response.data.lstExam;
      }
      callback(obj);
    })
    .catch((error) => {
      console.error(error);
    });
}
export { getLstExamSvc };
