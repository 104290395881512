import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import Header from 'common/js/header/header.js'
import LabelTable from './LabelTable';
import { getLabelListSvc } from './LabelSvc';
import LabelSlider from './LabelSlider';

var tokenid = localStorage.getItem("tokenid");
function LabelCtrl() {
  const [randNo, setRandNo] = useState(0);
  const [loader, setLoader] = useState('none');
  const [lstLabel, setLstLabel] = useState([])
  const [labelId, setLabelId] = useState([])
  const [labelObj, setLabelObj] = useState({})
  const [flgSlider, setFlgSlider] = useState(false)

  // useEffect(() => {
  //   setLoader('block')
  //   getLabelListSvc(handleLabelResponse)
  // }, []);
  // function handleLabelResponse(data) {
  //   setLstLabel(data.lstLabel)
  //   setLoader('none')
  // }
  function openSlider() {
    setFlgSlider(true)
  }
  function closeSlider() {
    setFlgSlider(false)
    setLabelObj({})
    setLabelId(0)
    generateRandomNo()
  }
  function generateRandomNo() {
    var lRandNo = Math.floor(Math.random() * 999999);
    // console.log(lRandNo)
    setRandNo(lRandNo)
  }
  function setLabelforEdit(labelId, labelObj) {
    // console.log('labelObj' + JSON.stringify(labelObj))
    setLabelId(labelId);
    setLabelObj(labelObj)
    openSlider()
  }
  const getWingId = (wingId) => {
    // console.log('wingId = ' + wingId)
  }

  return (
    <div className='MainContainer'>
      <Header wingFromChild={getWingId} />
      <div className="col-sm-6 col-centered" style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
        <ToastContainer autoClose={3000} hideProgressBar />
        <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
          <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
        </div>
        <LabelTable
          key={randNo}
          setLabelforEdit={setLabelforEdit}
          closeSlider={closeSlider}
          openSlider={openSlider}
        />

        <div style={{ display: flgSlider ? 'block' : 'none' }} className='TestSlider'>
          <LabelSlider
            key={labelId}
            closeSlider={closeSlider}
            labelObj={labelObj}
            loader={loader}
            generateRandomNo={generateRandomNo}
          />
        </div>
      </div>
    </div>
  );
}

export default LabelCtrl;
