import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css'
import '../css/transport.css'
import Header from 'common/js/header/header.js';
import VehicleTypeSlider from "./vehicleTypeSlider";
import VehicleTypeTable from "./vehicleTypeTable";
function VehicleTypeCtrl() {
    const [loader, setLoader] = useState("none")
    const [randNo, setRandNo] = useState(0)
    const [randFormNo, setRandFormNo] = useState(0)
    const [vehicleTypeId, setVehicleTypeId] = useState(0)
    const [vehicleTypeObj, setVehicleTypeObj] = useState({})
    function setVehicleTypeForEdit(pVId) {
        setVehicleTypeId(pVId)
    }
    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console.log(lRandNo)
        setRandNo(lRandNo)
        setRandFormNo(lRandNo)
    }
    function addNew() {
        setVehicleTypeId(0)
        setVehicleTypeObj({})
        generateRandomNo()
    }
    const getWingId = (wingId) => {
        // console.log("wingId" + wingId);
    }
    return (
        <div className='MainContainer'
            style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="col-sm-12" style={{ marginTop: "2%" }}>
                <div className="col-sm-7">
                    <VehicleTypeTable
                        key={randNo}
                        vehicleTypeId={vehicleTypeId}
                        setVehicleTypeId={setVehicleTypeId}
                        setVehicleTypeForEdit={setVehicleTypeForEdit}
                        generateRandomNo={generateRandomNo}
                    />
                </div>
                <div className="col-sm-1">
                    <div className="col-xs-1" onClick={(e) => addNew()}>
                        <span id="addReadyId" title="Add" className="addIcon glyphicon glyphicon-plus-sign"></span>
                    </div>
                </div>
                <div className="col-sm-4">
                    <VehicleTypeSlider
                        key={randFormNo}
                        vehicleTypeId={vehicleTypeId}
                        setRandNo={setRandNo}
                        generateRandomNo={generateRandomNo}
                    />
                </div>
            </div>
        </div>
    )
}
export default VehicleTypeCtrl;