import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['performance'].url + ':' + lstModules['performance'].port;

var tokenid = localStorage.getItem("tokenid");

function saveTermSvc(termObj, handleSaveTermResponse) {

    var status = 'Success'
    var obj = { message: '', status: status };

    axios({
        url: httpURL + '/sms/perf/performance/term/save',
        method: "POST",
        params: { 'term': JSON.stringify(termObj) },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log("finished service call");
        if (response.data.SvcStatus === 'Success') {
            handleSaveTermResponse(response.data, termObj)
        } else {
            handleSaveTermResponse(response.data, termObj)
        }
    }).catch((error) => {
        // console.log("finished service call: but failure");
        obj.status = 'Exception';
        obj.SvcMsg = error;
        return obj;
    });

}

function deleteTermSvc(pTermId, handleTermResponse) {

    var obj = { status: '', message: '' }
    axios({
        url: httpURL + '/sms/perf/performance/term/delete',
        method: "POST",
        params: { 'termId': pTermId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg
        handleTermResponse(obj, pTermId)

    }).catch((error) => {
        // console.log("finished service call: but failure");
        obj.status = 'Exception';
        obj.message = error;
        return handleTermResponse(obj, pTermId);
    });
    // console.log("may be the service call is not finished");
}
export { saveTermSvc, deleteTermSvc }