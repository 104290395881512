import axios from 'axios'
import lstModules from 'common/json/lstModules.json'

var httpURL = `${lstModules['cms'].url}:${lstModules['cms'].port}`
var tokenid = localStorage.getItem('tokenid')
var screenId = 8

function getTestimonialTextListSvc (callback) {
  // console.log(httpURL)
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/cms/testimonialText/list',
    method: 'POST',
    params: { screenId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.lstTestimonialText = response.data.lstTestimonialText
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        callback(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback(obj)
    })
}
function saveTestimonialTextSvc (formData, callBack) {
  axios({
    url: httpURL + '/sms/cms/testimonialText/save',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}

function deleteTestimonialTextSvc (pTestimonialTextId, callBack) {
  // console.log('pTestimonialTextId = ' + pTestimonialTextId)
  axios({
    url: httpURL + '/sms/cms/testimonialText/delete',
    method: 'POST',
    params: { testimonialTextId: pTestimonialTextId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}

function getTestimonialVideoListSvc (callback) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/cms/testimonialVideo/list',
    method: 'POST',
    params: { screenId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.lstTestimonialVideo = response.data.lstTestimonialVideo
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        callback(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback(obj)
    })
}

function saveTestimonialVideoSvc (formData, callBack) {
  axios({
    url: httpURL + '/sms/cms/testimonialVideo/save',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}

function deleteTestimonialVideoSvc (pTestimonialVideoId, callBack) {
  // console.log('pTestimonialVideoId = ' + pTestimonialVideoId)
  axios({
    url: httpURL + '/sms/cms/testimonialVideo/delete',
    method: 'POST',
    params: { testimonialVideoId: pTestimonialVideoId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}
export {
  getTestimonialTextListSvc,
  saveTestimonialTextSvc,
  deleteTestimonialTextSvc,
  getTestimonialVideoListSvc,
  saveTestimonialVideoSvc,
  deleteTestimonialVideoSvc
}
