import React, { useState, useEffect } from "react";
import axios from "axios";
import LblTxt from "common/components/LblTxt.js";
import DropDownEvent from "common/components/DropDownEvent.js";
import lstModules from "common/json/lstModules.json";
import { toast, ToastContainer } from "react-toastify";
import Header from "common/js/header/header.js";
import { getLstAcademicYearSvc, getFeeInfoSvc } from "./FeeSvc.js";
import SchoolFeeSlider from "./SchoolFeeSlider.js";
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules["enquiry"].url + ":" + lstModules["enquiry"].port;
var httpURLCommon = lstModules["main"].url + ":" + lstModules["main"].port;
var httpSchoolURL =
  lstModules["schools"].url + ":" + lstModules["schools"].port;

export default function Fees() {
  const [lstFees, setLstFees] = useState([]);
  const [lstAcademicYear, setLstAcademicYear] = useState([]);
  const [regsFee, setRegsFee] = useState(0);
  const [admissionFee, setAdmissionFee] = useState(0);
  const [annualFee, setAnnualFee] = useState(0);
  const [tutionFee, setTutionFee] = useState(0);
  const [transportFee, setTransportFee] = useState(0);
  const [otherFee, setOtherFee] = useState(0);
  const [tutionFreq, setTutionFreq] = useState(0);
  const [transportFreq, setTransportFreq] = useState(0);
  const [otherFreq, setOtherFreq] = useState(0);
  const [classId, setClassId] = useState(0);
  const [classNm, setClassNm] = useState("");
  const [acdYr, setAcdYr] = useState("");
  const [academicYearId, setAcademicYearId] = useState(0);
  const [schoolId, setSchoolId] = useState(localStorage.getItem("schoolId"));
  const [loader, setLoader] = useState("none");
  const [feeObj, setFeeObj] = useState({});
  const [randNo, setRandNo] = useState(0);
  const [flgSlider, setFlgSlider] = useState(false);

  const controller = new AbortController();
  const signal = controller.signal;

  useEffect(() => {
    setLoader("block");
    getLstAcademicYearSvc(cbAcademicYearSvc);

    return () => {
      controller.abort();
    };
  }, []);

  function generateRandomNo() {
    var lRandNo = Math.floor(Math.random() * 999999);
    // console.log(lRandNo);
    setRandNo(lRandNo);
  }
  function openFeeSlider(pFeeObj) {
    setClassId(pFeeObj.classId);
    setFeeObj(pFeeObj);
    setFlgSlider(true);
  }
  function getFeeList() {
    getFeeInfoSvc(academicYearId, cbFeeInfoResponse);
  }
  function closeSlider() {
    setFlgSlider(false);
    setFeeObj({});
    getFeeList();
    setAcademicYearId(0);
  }

  function cbFeeInfoResponse(data) {
    // console.log(data);
    setLstFees(data.lstFees);
    setLoader("none");
  }

  function cbAcademicYearSvc(data) {
    var data = data.lstAcademicYear;
    var maxId = 0,
      maxVal = "";
    for (var i = 0; i < data.length; i++) {
      if (maxId < data[i].academicYearId) {
        maxId = data[i].academicYearId;
        maxVal = data[i].academicYear;
      }
    }
    setAcademicYearId(maxId);
    setAcdYr(maxVal);
    setLstAcademicYear(data);
    setLoader("none");
    getFeeInfoSvc(maxId, cbFeeInfoResponse);
  }
 
  const colgrp = (
    <colgroup>
      <col width="15%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="12%"></col>
      <col width="20%"></col>
      <col width="23%"></col>
    </colgroup>
  );
  return (
    <div
      className="MainContainer"
      style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <Header />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className="col-sm-7 col-centered form-horizontal formWrapper">
        <div className="row">
          <div className="col-sm-12">
            <label className="control-label col-sm-3">Academic Year</label>
            <div className="form-group col-sm-2">
              <select
                name="acdyr"
                className="form-control"
                id="cmb_acdyr"
                style={{ width: "100%" }}
                value={academicYearId}
                onChange={(e) => {
                  // console.log(e.target.value)
                  // console.log(e.target.options[e.target.selectedIndex].text)
                  setAcademicYearId(e.target.value);

                  setAcdYr(e.target.options[e.target.selectedIndex].text);
                }}
              >
                <option value="0">Academic Year</option>
                {lstAcademicYear.map((item, idx) => {
                  return (
                    <option
                      key={"acd_" + (idx + 1)}
                      value={item.academicYearId}
                    >
                      {item.academicYear}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div>
          <table className="tableLayout1">
            {colgrp}
            <thead>
              <tr>
                <th>Class</th>
                <th>Tuition</th>
                <th>Transport</th>
                <th>Annual</th>
                <th>Admission</th>
                <th>Others</th>
              </tr>
            </thead>
          </table>
          <div className="" style={{ height: "83vh", overflowY: "scroll" }}>
            <table className="tableLayout">
              {colgrp}
              <tbody>
                {lstFees.map((obj, key) => {
                  return (
                    <tr
                      className={classId === obj.classId ? "selected" : ""}
                      key={"cls_" + key}
                      onClick={(e) => openFeeSlider(obj)}
                    >
                      <td className="text-center">{obj.classNm}</td>
                      <td className="text-right">{obj.tutionFee}</td>
                      <td className="text-right">{obj.transportFee}</td>
                      <td className="text-right">{obj.annualFee}</td>
                      <td className="text-right">{obj.admissionFee}</td>
                      <td className="text-right">{obj.otherFee}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {
          <div
            className="TestSlider"
            style={{ display: flgSlider ? "block" : "none", width: "50%" }}
          >
            <SchoolFeeSlider
              key={`${academicYearId}_${classId}`}
              classId={classId}
              classNm={classNm}
              acdYr={acdYr}
              openSlider={openFeeSlider}
              closeSlider={closeSlider}
              setRandNo={setRandNo}
              feeObj={feeObj}
              getFeeList={getFeeList}
              academicYearId={academicYearId}
            />
          </div>
        }
      </div>
    </div>
  );
}
