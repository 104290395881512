import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import Header from "common/js/header/header";
import Loader from "common/components/Loader";
import MonthList from "common/components/monthListStatic";

import MonthlyDiarySummaryTbl from "./monthlySummaryTbl";
import SectionData from "./sectionData.json";
import { getDiaryMonthlyService } from "./monthlySummarySvc";



const MonthlyDiarySummary = () => {

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [lstSummary, setLstSummary] = useState([]);
    const [monthId, setMonthId] = useState(0);
    const [sectionId, setSectionId] = useState(0);

    useEffect(() => {
        // console.log("MonthlyDiarySummary component called");
        // console.log("SectionData.lstSectionSummary", SectionData.lstSectionSummary);
        // setLstSummary(SectionData.lstSectionSummary);
        if (!!monthId && monthId != 0) {
            getSummary(monthId);
        }
    }, []);

    const getSummary = (pMonthId) => {
        // console.log("getSummary called, pMonthId", pMonthId);
        setLoader("block");
        setOpacity(0.5);
        getDiaryMonthlyService(pMonthId, lstSectionSummaryResponse);
    }


    const lstSectionSummaryResponse = (data) => {
        // console.log("lstSectionSummaryResponse called, data", data);
        if (data.status === "Success") {
            setLstSummary(data.lstSectionSummary);
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setLoader("none");
        setOpacity(1);
    }

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    const openDailySummary = (pClsId, pSectionId) => {
        // console.log('openDailySummary method called for sectionId:', pSectionId);
        let newUrl = "/dailyDiarySummary?section=" + pSectionId + "&cls=" + pClsId + "&month=" + monthId;
        window.open(newUrl, "");
    };

    const handleMonthChange = async (pMonthId) => {
        // console.log("handleMonthChange called, pMonthId", pMonthId);
        await setMonthId(pMonthId);
        getSummary(pMonthId);

    }


    return (
        <div >
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <Loader loader={loader} position="fixed" />
            <div className="col-md-12 col-xs-12 col-centered padding-remove"
                style={{
                    cursor: (loader === "none") ? 'pointer' : 'none',
                    pointerEvents: (loader === "none") ? 'auto' : 'none',
                    opacity: opacity
                }}>
                <div className="col-md-10 col-xs-10 col-centered padding-remove">
                    <div className="col-md-12 col-xs-12 col-centered padding-remove" style={{ marginTop: "2vh", height: "28px" }}>
                        <div className="col-md-2 col-sm-3 col-xs-4 padding-remove">
                            <MonthList
                                monthId={monthId}
                                handleMonthChange={handleMonthChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-xs-12 padding-remove" style={{ marginTop: "2vh" }}>
                        <div className="col-xl-6 col-lg-7 col-md-9 col-sm-10 col-xs-11 padding-remove">
                            <MonthlyDiarySummaryTbl
                                lstSummary={lstSummary}
                                sectionId={sectionId}
                                setSectionId={setSectionId}
                                openDailySummary={openDailySummary}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MonthlyDiarySummary;