import React, { useState, useEffect } from "react";
import { getBorrowerBookListSvc, saveIssueBookSvc } from "./issueBooksSvc";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

export function BookIssueSection(props) {
  const [sectionLoader, setSectionLoader] = useState("none");
  const [issueDt, setIssueDt] = useState(null);
  const [dueDt, setDueDt] = useState(null);
  const [securityDeposit, setSecurityDeposit] = useState(0);
  const [flgStaff, setFlgStaff] = useState(false);
  const [staffId, setStaffId] = useState(0);
  const [studentId, setStudentId] = useState(0);
  const [lstBorrowerBook, setLstBorrowerBook] = useState([]);

  useEffect(() => {
    setStaffId(props.borrowerDtls.staffId);
    setStudentId(props.borrowerDtls.studentId);
    setSecurityDeposit(props.issueDtls.securityDeposit);
    var dt = new Date();
    var tmpDd = dt.getDate();
    var dd = tmpDd < 9 ? "0" + tmpDd : tmpDd;
    var mon = dt.getMonth() + 1;
    var mm = mon < 10 ? "0" + mon : mon;
    var ddmmyyyy = dd + "-" + mm + "-" + dt.getFullYear();
    setIssueDt(getDateForCalendar(ddmmyyyy));
    setFlgStaff(props.flgStaff);
    var date = new Date();

    if (props.flgStaff) {
      date.setDate(date.getDate() + props.issueDtls.staffIssuePeriod);
    } else {
      date.setDate(date.getDate() + props.issueDtls.staffIssuePeriod);
    }
    var tmpDueDt = date;
    var tmpDd = tmpDueDt.getDate();
    var dd = tmpDd < 9 ? "0" + tmpDd : tmpDd;
    var mon = tmpDueDt.getMonth();
    var mm = mon < 10 ? "0" + mon : mon;
    var ddmmyyyy = dd + "-" + mm + "-" + tmpDueDt.getFullYear();
    setDueDt(getDateForCalendar(ddmmyyyy));

    if (staffId || studentId) {
      getBorrowerBookList();
    }
  }, [
    props.issueDtls.staffIssuePeriod,
    props.issueDtls.studentIssuePeriod,
    props.issueDtls.securityDeposit,
    props.flgStaff,
    props.borrowerDtls.staffId,
    props.borrowerDtls.studentId,
  ]);

  function getBorrowerBookList() {
    setSectionLoader("block");
    getBorrowerBookListSvc(staffId, studentId, borrowerBookListCallback);
  }
  function borrowerBookListCallback(data) {
    setSectionLoader("none");
    if (data.SvcStatus === "Success") {
      setLstBorrowerBook(data.lstBook);
    } else {
      if (data.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (data.SvcMsg === "Your Session is expired, login again") {
        toast.error(data.SvcMsg);
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(data.SvcMsg);
      }
    }
  }
  function getDateForCalendar(strDt) {
    if (!strDt) return null;
    var parts = strDt.split("-");
    var d = parts[0];
    var m = parts[1] - 1;
    var y = parts[2];

    return new Date(y, m, d);
  }
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("-");
  }
  function issueBook() {
    setSectionLoader("block");
    var borrowerObj = {
      studentId: studentId,
      staffId: staffId,
      issueDttm: convert(issueDt),
      dueDttm: convert(dueDt),
      securityDeposit: securityDeposit,
      bookNo: props.issueDtls.bookNo,
      copyNo: props.issueDtls.copyNo,
    };
    saveIssueBookSvc(borrowerObj, issueBookCallback);
  }
  function issueBookCallback(data) {
    setSectionLoader("none");
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      getBorrowerBookList();
      setTimeout(function () {
        props.setSliderDisplayed(0);
      }, 4000);
    } else {
      if (data.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (data.SvcMsg === "Your Session is expired, login again") {
        toast.error(data.SvcMsg);
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(data.SvcMsg);
      }
    }
  }

  async function handleChangeIssueDt(propDt) {
    await setIssueDt(propDt);
    calcDueDt(propDt);
  }
  function calcDueDt(vDt) {
    var pDt = new Date(vDt.getTime());
    if (props.flgStaff) {
      pDt.setDate(pDt.getDate() + props.issueDtls.staffIssuePeriod);
    } else {
      pDt.setDate(pDt.getDate() + props.issueDtls.studentIssuePeriod);
    }
    var tmpDd = pDt.getDate();
    var dd = tmpDd < 9 ? "0" + tmpDd : tmpDd;
    var mon = pDt.getMonth() + 1;
    var mm = mon < 10 ? "0" + mon : mon;
    var ddmmyyyy = dd + "-" + mm + "-" + pDt.getFullYear();
    setDueDt(getDateForCalendar(ddmmyyyy));
  }
  function handleChangeDueDt(pDt) {
    if (isLessThanIssueDt(pDt)) {
      toast.warn("Due Date can't be less than Issue date");
      return;
    }
    setDueDt(pDt);
  }
  function isLessThanIssueDt(mydate) {
    var t1 = convert(issueDt);
    var tmpArr = t1.split("-");
    var d = tmpArr[0];
    var m = tmpArr[1];
    var y = tmpArr[2];

    var date1 = new Date(y, m, d);
    var t2 = convert(mydate);
    var tmpArr1 = t2.split("-");
    var d1 = tmpArr1[0];
    var m1 = tmpArr1[1];
    var y1 = tmpArr1[2];

    var mydate1 = new Date(y1, m1, d1);
    const diffTime = Math.abs(date1 - mydate1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // console.log(diffDays + " days");
    return mydate1 < date1;
  }
  const colgrp = (
    <colgroup>
      <col width="15%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
    </colgroup>
  );
  return (
    <form
      className="form form-horizontal"
      style={{
        width: "96%",
        margin: "0px 15px",
        background: "#fff",
        padding: 10,
        pointerEvents: sectionLoader === "none" ? "auto" : "none",
      }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: sectionLoader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <div className="form-group">
        <label className="control-label col-sm-2" id="">
          Issue Date:
        </label>
        <div className="col-sm-2 padding-remove">
          <DatePicker
            id="inp_issuedt"
            className="form-control text-center"
            placeholderText="Issue"
            selected={issueDt}
            dateFormat="dd-MM-yyyy"
            onChange={handleChangeIssueDt}
            autoComplete="off"
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
          />
        </div>
      </div>
      <div className="form-group">
        <label className="control-label col-sm-2" id="">
          Due Date:
        </label>
        <div className="col-sm-2 padding-remove">
          <DatePicker
            id="inp_issuedt"
            className="form-control text-center"
            placeholderText="Issue"
            selected={dueDt}
            dateFormat="dd-MM-yyyy"
            onChange={handleChangeDueDt}
            autoComplete="off"
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
          />
        </div>
      </div>
      <div className="form-group col-sm-6">
        <label className="control-label col-sm-4" id="">
          Security Deposit:
        </label>
        <div className="col-sm-5" style={{ paddingLeft: 0 }}>
          <input
            autoComplete="off"
            id="txt_deposit"
            type="text"
            className="form-control text-right"
            value={securityDeposit}
            placeholder="Security Deposit"
            onChange={(e) => setSecurityDeposit(e.target.value)}
          />
        </div>
        <button
          type="button"
          className="btn btn-info col-sm-3 pull-right"
          onClick={() => issueBook()}
        >
          Issue Book
        </button>
      </div>
      <div className="col-sm-6 form-group"></div>
      <div className="col-sm-7">
        <table className="tableLayout1">
          {colgrp}
          <thead>
            <tr>
              <th>Issue Date</th>
              <th>Title</th>
              <th>Due Date</th>
            </tr>
          </thead>
        </table>
        <div style={{ height: "40vh", overflowY: "scroll" }}>
          <table className="tableLayout">
            {colgrp}
            <tbody>
              {lstBorrowerBook.map((obj, key) => {
                return (
                  <tr key={key}>
                    <td className="text-center">{obj.issueDt}</td>
                    <td className="text-left">{obj.title}</td>
                    <td className="text-center">{obj.dueDt}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </form>
  );
}
