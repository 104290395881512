import React from "react";
import "../css/EnquiryCtrl.css";
import TestTable from "./TestTable.js";
import SlotTable from "./SlotTable.js";
import EvalList from "./EvalList";
import { useState, useEffect } from "react";

function SchTestSlider(props) {
  const [loader, setLoader] = useState("none");
  const [lstTestId, setLstTestId] = useState([]);
  const [lstTestSlotId, setLstTestSlotId] = useState([]);
  const [randNoSlotTbl, setRandNoSlotTbl] = useState(0);
  const [randNoEvalList, setRandNoEvalList] = useState(0);
  const [regsId, setRegsId] = useState(0);
  const [testTableKey, setTestTableKey] = useState(0);
  // const [selectedSlot,setSelectedSlot]=useState()
  const [lstTest, setLstTest] = useState(null);
  const [slotId, setSlotId] = useState(0);
  useEffect(() => {
    setRegsId(props.regsId);
  }, [props.regsId]);

  function setSelectedTestId(param) {
    // console.log("setSelectedTestId triggered in app.js   " + param);
    setLstTestId(param);
    setRandNoSlotTbl(generateRandomNo);
  }

  function setSelectedTestSlotId(param) {
    // console.log("setSelectedTestSlotId triggered in app.js   " + param);
    setLstTestSlotId(param);
    setRandNoEvalList(generateRandomNo);
  }

  // function getSelectedSlot(param)
  // {
  //   setSelectedSlot(param)
  // }
  function generateRandomNo() {
    return Math.floor(Math.random() * 999999);
  }

  function refreshTestTable() {
    setTestTableKey(generateRandomNo);
  }

  return (
    <div>
      <div
        className="col-sm-12"
        style={{
          pointerEvents: loader === "none" ? "auto" : "none",
          height: "auto",
          margin: "10px auto",
          background: "#fff",
          border: "1px solid #ccc",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            position: "fixed",
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: "50%",
            top: "50%",
            display: loader,
          }}
        >
          <img
            className="loderImg"
            src={require("common/images/loader.gif")}
            alt="Loader..."
          />
        </div>
        <div className="col-sm-8">
          <TestTable
            key={testTableKey}
            setSelectedTestId={setSelectedTestId}
            setSelectedTestSlotId={setSelectedTestSlotId}
            enquiryId={props.enquiryId}
            regsId={regsId}
            clsId={props.clsId}
            setLstTest={setLstTest}
            lstTestId={lstTestId}
          >
            Test-Table
          </TestTable>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <SlotTable
              lstTestId={lstTestId}
              key={"rnst_" + randNoSlotTbl}
              enquiryId={props.enquiryId}
              regsId={regsId}
              closeSlider={props.closeSlider}
              flgSliderType={props.flgSliderType}
              refreshTestTable={refreshTestTable}
              clsId={props.clsId}
              setSlotId={setSlotId}
              slotId={slotId}
            >
              Slot-Table
            </SlotTable>
     
            <EvalList
              regsId={regsId}
              key={"rnel_" + testTableKey}
              lstTestSlotId={lstTestSlotId}
              closeSlider={props.closeSlider}
              lstTest={lstTest}
              lstTestId={lstTestId}
              setLstTestSlotId={setLstTestSlotId}
            >
              Assign Evaluator
            </EvalList>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchTestSlider;
