import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';


const BatchStudents = (props) => {

    const { batchDtls, isLastColumn, updateStartTm, updateEndTm, saveTestBatches } = props;

    const [testBatchId, setTestBatchId] = useState(0);
    const [batchNo, setBatchNo] = useState(0);
    // const [startTm, setStartTm] = useState("");
    // const [endTm, setEndTm] = useState("");
    const [lstStudent, setLstStudent] = useState([]);

    const [startHour, setStartHour] = useState("");
    const [startMinute, setStartMinute] = useState("");
    const [startAMPM, setStartAMPM] = useState("AM");
    const [endHour, setEndHour] = useState("");
    const [endMinute, setEndMinute] = useState("");
    const [endAMPM, setEndAMPM] = useState("AM");

    const lstHour = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    const lstMinutes = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];
    const lstAMPM = ["AM", 'PM'];

    useEffect(() => {
        // console.log("useEffect called in BatchStudents");
        setTestBatchId(batchDtls.testBatchId);
        setBatchNo(batchDtls.batchNo);
        // setStartTm(batchDtls.startTm);
        // setEndTm(batchDtls.endTm);
        setLstStudent(batchDtls.lstStudent);

        if (batchDtls.startTm) {
            const startTime = batchDtls.startTm.split(/[: ]/);
            setStartHour(startTime[0]);
            setStartMinute(startTime[1]);
            setStartAMPM(startTime[2]);
        }

        // Set the values for end time
        if (batchDtls.endTm) {
            const endTime = batchDtls.endTm.split(/[: ]/);
            setEndHour(endTime[0]);
            setEndMinute(endTime[1]);
            setEndAMPM(endTime[2]);
        }
    }, [batchDtls]);



    const handleStartTmChange = (pTestBatchId, pHour, pMinute, pAMPM) => {
        // console.log("handleStartTmChange method called, pTestBatchId", pTestBatchId, ", pHour", pHour, ", pMinute", pMinute, ", pAMPM", pAMPM);
        const startTm = pHour + ":" + pMinute + " " + pAMPM;
        updateStartTm(pTestBatchId, startTm);
    }
    const handleEndTmChange = (pTestBatchId, pHour, pMinute, pAMPM) => {
        // console.log("handleEndTmChange method called, pTestBatchId", pTestBatchId, ", pHour", pHour, ", pMinute", pMinute, ", pAMPM", pAMPM);
        const endTm = pHour + ":" + pMinute + " " + pAMPM;
        updateEndTm(pTestBatchId, endTm);
    }

    return (
        <div className="col-xs-12 col-centered padding-remove ">
            <div className='row' style={{ width: '100%' }}>
                <label className="col-sm-12 text-center" style={{ width: '100%', fontWeight: "bold" }}>{batchNo}</label>
            </div>
            <div className='row' style={{ width: '100%', height: "40px" }}>
                <label className="col-sm-12 padding-remove" style={{ width: '90%', fontWeight: "400" }}>
                    <div className='col-sm-4 padding-remove'>
                        <select
                            autoComplete='off'
                            name='Start Hour'
                            className='form-control '
                            style={{ height: 30 }}
                            value={startHour}
                            onChange={(e) => handleStartTmChange(testBatchId, e.target.value, startMinute, startAMPM)}
                        >
                            <option value=''>HH</option>
                            {
                                lstHour.map((startHour) => (
                                    <option value={startHour} key={startHour} id={startHour}>
                                        {startHour}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='col-sm-4 padding-remove '>
                        <select
                            autoComplete='off'
                            name='Start Minute'
                            className='form-control '
                            style={{ height: 30 }}
                            value={startMinute}
                            onChange={(e) => handleStartTmChange(testBatchId, startHour, e.target.value, startAMPM)}
                        >
                            <option value=''>MM</option>
                            {
                                lstMinutes.map((startMinute) => (
                                    <option value={startMinute} key={startMinute} id={startMinute}>
                                        {startMinute}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='col-sm-4 padding-remove'>
                        <select
                            autoComplete='off'
                            name='Start AMPM'
                            className='form-control '
                            style={{ height: 30 }}
                            value={startAMPM}
                            onChange={(e) => handleStartTmChange(testBatchId, startHour, startMinute, e.target.value)}
                        >
                            {
                                lstAMPM.map((startAMPM) => (
                                    <option value={startAMPM} key={startAMPM} id={startAMPM}>
                                        {startAMPM}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </label>
            </div>
            <div className='row' style={{ width: '100%', height: "40px" }}>
                <label className="col-sm-12 padding-remove" style={{ width: '90%', fontWeight: "400" }}>
                    <div className='col-sm-4 padding-remove'>
                        <select
                            autoComplete='off'
                            name='End Hour'
                            className='form-control '
                            style={{ height: 30 }}
                            value={endHour}
                            onChange={(e) => handleEndTmChange(testBatchId, e.target.value, endMinute, endAMPM)}
                        >
                            <option value=''>HH</option>
                            {
                                lstHour.map((endHour) => (
                                    <option value={endHour} key={endHour} id={endHour}>
                                        {endHour}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='col-sm-4 padding-remove '>
                        <select
                            autoComplete='off'
                            name='End Minute'
                            className='form-control '
                            style={{ height: 30 }}
                            value={endMinute}
                            onChange={(e) => handleEndTmChange(testBatchId, endHour, e.target.value, endAMPM)}
                        >
                            <option value=''>MM</option>
                            {
                                lstMinutes.map((endMinute) => (
                                    <option value={endMinute} key={endMinute} id={endMinute}>
                                        {endMinute}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='col-sm-4 padding-remove'>
                        <select
                            autoComplete='off'
                            name='End AMPM'
                            className='form-control '
                            style={{ height: 30 }}
                            value={endAMPM}
                            onChange={(e) => handleEndTmChange(testBatchId, endHour, endMinute, e.target.value)}
                        >
                            {
                                lstAMPM.map((endAMPM) => (
                                    <option value={endAMPM} key={endAMPM} id={endAMPM}>
                                        {endAMPM}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </label>
            </div>
            <div className='row ' style={{ width: '90%', height: "40px" }}>
                {
                    isLastColumn ?
                        <button type="button" className="btn btn-info pull-right" onClick={(e) => saveTestBatches()}>Save</button>
                        :
                        <label className="col-sm-12 col-centered" style={{ width: '100%', fontWeight: "400" }}>{""}</label>
                }
            </div>
            {
                lstStudent && lstStudent.length ?
                    lstStudent.map((student) => (
                        <div key={student.studentId} className='row' style={{ width: '100%' }}>
                            <label className="col-sm-12 text-left" style={{ width: '100%', fontWeight: "400" }}>{student.student}</label>
                        </div>
                    ))
                    : null
            }
        </div>
    );
}

export default BatchStudents;