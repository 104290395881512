import React, { useState, useEffect } from 'react'



const ErrorTable = (props) => {
    const { lstErrors } = props;
    const [lstError, setLstError] = useState([]);

    const colgrp = <colgroup>
        <col width="60"></col>
        <col width="20"></col>
        <col width="60"></col>
    </colgroup>


    useEffect(() => {
        // console.log("ErrorTable component called, lstError", lstErrors);
        setLstError(lstErrors);
    }, [lstErrors]);

    return (
        <div >
            <div className="col-md-12 col-xs-12 col-centered padding-remove " >
                <table className="tableLayout1" >
                    {colgrp}
                    <thead>
                        <tr>
                            <th > Sheet </th>
                            <th > Line No </th>
                            <th > Errors </th>
                        </tr>
                    </thead>
                </table>
                <div className='tableDiv' style={{ height: 500 + 'px' }}>
                    <table id="error-table" className="tableLayout">
                        {colgrp}
                        <tbody id="error-table-body" >
                            {lstError &&
                                lstError.map((error, idx) => (
                                    <tr key={idx}>
                                        <td className="text-left">{error.sheetName}</td>
                                        <td className="text-left">{error.rowNo}</td>
                                        <td className="text-center">{error.message}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ErrorTable;

