import React, { useState, useEffect } from 'react';
import CalenderScreen from 'common/components/calendarScreen';

const ScheduleInterview = (props) => {
    const [interviewDate, setInterviewDate] = useState(props.interviewDate);
    const [classId, setClassId] = useState(props.classId)
    const [slotType, setSlotType] = useState(props.slotType)
    /*const [interviewerId, setInterviewerId] = useState(props.interviewerId);
    const [slotTimeId, setSlotTimeId] = useState(0);
*/
    const [lstSlotsTime, setLstSlotsTime] = useState(props.lstSlotsTime);
    const [lstTeacher, setLstTeacher] = useState(props.lstTeacher);
    const [studentId, setStudentId] = useState(props.studentId);
    const [schDtls, setSchDtls] = useState(props.schData)
    const dateFetchFunction = (date) => {

        // console.log("Test :- " + date);
        props.getSlotList(classId, slotType, date);
        setInterviewDate(date);

        setSchDtls({ ...schDtls, interviewDate: date })
        props.handleSchChange(schDtls);
    }

    useEffect(() => {
        // console.log(props.schData);
        setSchDtls(props.schData);

        /* classAssignedTo: 7
            evaluationDate: "21-12-2020"
            interviewDate: "21-12-2020"
            interviewer: "-"
            interviewerId: 0
            remarks: ","
            result: "Pass"
            scheduleInterviewId: 4
            slotTimeId: 2 */
        setClassId(props.classId);
        setSlotType(props.slotType);
        setInterviewDate(props.interviewDate);
        setLstSlotsTime(props.lstSlotsTime);
        setLstTeacher(props.lstTeacher);
        // console.log(props.lstTeacher);
    }, [props])

    function handleIntChange(e) {
        // console.log('interviewerId: ' + e.target.value);
        // setInterviewerId(e.target.value);
        setSchDtls({ ...schDtls, interviewerId: e.target.value })
        props.handleSchChange(schDtls);

    }
    function getDate(dt) {
        // console.log(dt);
        var dateParts = dt.split("-");

        // month is 0-based, that's why we need dataParts[1] - 1
        return new Date(dateParts[2], dateParts[1] - 1, +dateParts[0]);
    }

    function addInterview() {
        let schedule = {};
        let scheduleInterviewId = schDtls.scheduleInterviewId;
        // console.log('scheduleInterviewId: ' + scheduleInterviewId);

        if (schDtls.interviewDate === "") {
            // toast.warning('Please Select Date');
            return false;
        }

        if (schDtls.slotTimeId === parseInt(0)) {
            // toast.warning('Please Select Slot');
            return false;
        }
        schedule = {
            scheduleInterviewId: scheduleInterviewId,
            interviewDate: schDtls.interviewDate,
            slotTimeId: schDtls.slotTimeId,
            interviewerId: schDtls.interviewerId
        }

        props.addInterview(schedule, studentId);

    }
    function assignInterviewer() {

    }
    const selectSlotTm = (e) => {
        var slotTmDtls = JSON.parse(e.currentTarget.getAttribute("data"));
        // setSlotTimeId(slotTmDtls.regsSlotTimeId);
        setSchDtls({ ...schDtls, regsSlotTimeId: slotTmDtls.slotTimeId })
        props.handleSchChange(schDtls);
        // console.log(slotTmDtls.slotTimeId);
    }
/*     function formatDate(date) {
        var date = new Date(date),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [day, mnth, date.getFullYear()].join("-");
    }

    // console.log(formatDate(new Date()));
 */    return (<div className=" col-xs-12 padding-remove scheduleInterviewWrap" >
        <div className="col-xs-11 col-centered padding-remove clearfix" >
            {schDtls && <form id="testNameAddForm" className="form-horizontal formWrapper clearfix">
                <div className="col-sm-6">
                    <div className="form-group col-md-12">
                        <label className="control-label col-sm-6" >Scheduled Interview Date<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-6 padding-remove">
                            <input autoComplete="off"
                                value={interviewDate}
                                id="interviewDate"
                                type="text"
                                className="form-control "
                                placeholder="Scheduled Test Date"
                                readOnly />
                        </div>
                    </div>
                    <div className="form-group col-md-12">
                        <label className="control-label col-sm-6" >Calender<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-6 padding-remove">
                            <CalenderScreen
                                //changeDate={this.handleDateChange}
                                dateFromChild={dateFetchFunction}
                                classId={classId}
                                slotType={slotType}
                                defaultDate={getDate(interviewDate)}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="evaluationPanel">
                        <h5 className="text-center">Your Selected {schDtls.interviewDate} has Slot Available</h5>
                        <div className="col-xs-10 col-centered">
                            <table className="tableLayout1" style={{ width: 700 }}>
                                <thead>
                                    <tr>
                                        <th style={{ display: 'none' }}> Fee Id </th>
                                        <th style={{ width: 30 }}> Start Time </th>
                                        <th style={{ width: 30 }}> End Time </th>
                                        <th style={{ width: 30 }}> Seat Available</th>

                                    </tr>
                                </thead>
                            </table>

                            <div className="slotTimeTableDiv" style={{ height: '100px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                <table id="slotTimeTable" className="tableLayout">
                                    <colgroup>
                                        <col width="30" />
                                        <col width="30" />
                                        <col width="30" />
                                    </colgroup>
                                    <tbody id="slotTimeTableBody">
                                        {lstSlotsTime.length > 0 && lstSlotsTime.map(function (obj, idx) {
                                            return <tr key={idx} data={JSON.stringify(obj)} onClick={selectSlotTm} className={obj.regsSlotTimeId === schDtls.slotTimeId ? "selected" : ""}>
                                                <td>{obj.startTm}</td>
                                                <td>{obj.endTm}</td>
                                                <td>{obj.seatsAvailable}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    <div className="form-group" >
                        <div className="col-sm-11 text-right" >
                            <button type="button" style={{ marginTop: 20, marginRight: 3 }} title="Save" onClick={addInterview} className="btn btn-info">Save</button>

                            <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={props.closeSlider}>
                                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                            </button>
                        </div>
                    </div>
                    <div className="form-group" >
                        <div className="col-sm-12 text-center assignButtonWrap" >
                            <div className="assignWrap">
                                <div className="form-group col-md-9">
                                    <label className="control-label col-sm-6" >Assign Interviewer<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <select autoComplete="off" value={schDtls.interviewerId} id="assignInterviewerCmb" className="form-control" onChange={handleIntChange} >
                                            <option id={0} value={0}>Teacher</option>
                                            {lstTeacher.map((obj, idx) => {
                                                return <option id={obj.teacherId} key={idx} value={obj.teacherId}>{obj.teacher}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <button type="button" style={{ marginTop: 5 }} title="Save" onClick={assignInterviewer} className="btn btn-info">Save</button>
                            </div>

                        </div>
                    </div>
                </div>
            </form>}
        </div>
    </div>)
}
export default ScheduleInterview;