import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import 'jquery-ui';
import 'common/css/master.css';
import axios from 'axios';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';


let httpURL = '';
let queryString = require('querystring');
let lstRegsFees = [];
let lstWings = [];
let lstWingClass = [];
let tokenid = localStorage.getItem('tokenid');
var screenId = 18;


class registrationFees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            flg: true,
            tokenid: tokenid,
        }

    }

    componentDidMount = () => {
        this.getRegistrationFeesList();
        $('#wingsCmb, #filterWingsCmb').prepend($("<option> </option>").val(0).html('All Wings'));
        $('#classTypeCmb').prepend($("<option> </option>").val(0).html('All Class'));

        axios({
            url: httpURL + '/sms/wings/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstWings = response.data.lstWings;
                this.fillWingsCmb();
                this.fillWingsFilterCmb();
            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    getRegistrationFeesList = () => {
        axios({
            url: httpURL + '/sms/mst/regs/fee/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstRegsFees = response.data.lstRegsFees;
                this.setRegistrationFeesTable();
            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    fillWingsCmb() {
        $('#wingsCm').empty();
        $('#wingsCm').prepend($("<option> </option>").val(0).html('All Wings'));
        for (let i = 0; i < lstWings.length; i++) {
            let wingId = lstWings[i].wingId;
            let wing = lstWings[i].wing;
            // console.log(wingId + wing);
            $('#wingsCmb').append($("<option> </option>").val(wingId).html(wing));
        }
    }
    fillWingsFilterCmb() {
        $('#filterWingsCmb').empty();
        $('#filterWingsCmb').prepend($("<option> </option>").val(0).html('Wings'));
        for (let i = 0; i < lstWings.length; i++) {
            let wingId = lstWings[i].wingId;
            let wing = lstWings[i].wing;
            // console.log(wingId + wing);
            $('#filterWingsCmb').append($("<option> </option>").val(wingId).html(wing));
        }
    }

    changeWing = async () => {
        let wingId = $('#wingsCmb').find('option:selected').val();

        if (parseInt(wingId) === 0) {
            lstWingClass = [];
            this.fillClassCmb();
        }
        else {
            this.setState({ loader: 'block', opacity: 0.5 });
            await axios({
                url: httpURL + '/sms/wings/cls/list',
                method: "POST",
                params: { wingId, screenId },
                headers: { 'tokenid': this.state.tokenid },
                withCredentials: true,
            }).then((response) => {
                if (response.data.SvcStatus === 'Success') {
                    lstWingClass = response.data.lstWingClass;
                    this.fillClassCmb();
                }
                else {
                    if (response.data.SvcMsg === 'You are not logged in') {
                        setTimeout(function () { window.location.href = ('/') }, 2000);
                        return false;
                    }
                    else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                        setTimeout(function () { window.location.href = ('/') }, 2000);
                        return false;
                    } else {
                        toast.error(response.data.SvcMsg);

                    }
                }
                this.setState({ loader: 'none', opacity: 1 });
            }).catch((error) => {
                this.setState({ loader: 'none', opacity: 1 });
                toast.error("Service failed " + error);
            });

        }
    }

    fillClassCmb() {
        $('#classTypeCmb').empty();
        $('#classTypeCmb').prepend($("<option> </option>").val(0).html('All Class'));
        for (let i = 0; i < lstWingClass.length; i++) {
            let classId = lstWingClass[i].classId;
            let className = lstWingClass[i].className;
            $('#classTypeCmb').append($("<option> </option>").val(classId).html(className));
        }
    }

    setRegistrationFeesTable = () => {
        $('#registrationFeesTableBody tr').remove();
        let table = document.getElementById('registrationFeesTable');
        let tbody = document.getElementById('registrationFeesTableBody');
        var filterWing = $('#filterWingsCmb').find('option:selected').text();

        for (let i = 0; i < lstRegsFees.length; i++) {
            let regsFeeId = lstRegsFees[i].regsFeeId;
            let wing = lstRegsFees[i].wing;
            let className = lstRegsFees[i].className;
            let fee = lstRegsFees[i].fee;
            fee = fee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            let updatedOn = lstRegsFees[i].updatedOn;



            let row = document.createElement('tr');
            row.id = regsFeeId;

            if (filterWing !== "Wings" && wing !== filterWing)
                continue;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.setwingForm($(e.target).closest('tr').attr('id'));
                this.setState({
                    flg: false
                })
            }

            this.addTD(row, regsFeeId, 'no-display');
            this.addTD(row, wing, 'text-center');
            this.addTD(row, className, 'text-center');
            this.addTD(row, fee, 'text-right');
            this.addTD(row, updatedOn, 'text-center');
            this.addTDAction(row, 'glyphicon glyphicon-trash');
            tbody.append(row);
        }
        table.append(tbody);
    }
    addTDAction(row, classname) {
        var cell = document.createElement('td');
        cell.className = 'text-center';
        var span = document.createElement('span');
        span.className = classname;
        span.style.cursor = 'pointer';
        span.onclick = () => {
            $('td').removeClass('selected');
            $(row).addClass('selected').siblings().removeClass('selected');
            // var classId = $('#classTableBody tr.selected').find('td:eq(0)').text();
            // var sectionId = $('#classTableBody tr.selected').find('td:eq(1)').text();
            this.getConfirmation();
        };
        cell.append(span);
        row.append(cell);
    }
    getConfirmation = () => {
        var retVal = window.confirm("Do you want to delete the fee ?");
        if (retVal == true) {
            this.deleteFee();
            return true;
        } else {
            return false;
        }
    }
    deleteFee = () => {
        var regsFeeId = $('#registrationFeesTableBody tr.selected').find('td:eq(0)').text();
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/mst/regs/fee/delete',
            queryString.stringify({ regsFeeId, screenId }),
            {
                headers: { tokenid: this.state.tokenid },
                withCredentials: true
            }
        ).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getRegistrationFeesList();
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }

    setwingForm = async (regsFeeId) => {
        // console.log(regsFeeId);
        let wingData = this.getwingData(regsFeeId);
        $('#wingsCmb').val(wingData.wingId);
        await this.changeWing();
        await $('#classTypeCmb').val(wingData.classId);
        $('#fee').val(wingData.fee);

    }

    getwingData(regsFeeId) {
        for (let i = 0; i < lstRegsFees.length; i++) {
            if (parseInt(regsFeeId) === lstRegsFees[i].regsFeeId)
                return lstRegsFees[i];
        }
    }

    addRegistrationFees = () => {
        let lstwing = {};
        let regsFeeId = 0;;
        let fee = $('#fee').val();
        let wingId = $('#wingsCmb').find('option:selected').val();
        let classId = $('#classTypeCmb').find('option:selected').val();
        //let date = new Date();
        //  let updatedOn = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();


        if (fee === "") {
            toast.warning('Please Enter next Fee');
            $('#fee').focus();
            return false;
        }
        if (this.state.flg) {
            regsFeeId = 0;;
        }
        else {
            regsFeeId = $('#registrationFeesTableBody tr.selected').find('td:eq(0)').text();
        }

        lstwing = {
            regsFeeId,
            wingId,
            classId,
            fee
        }


        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/mst/regs/fee/save',
            queryString.stringify({ 'regsFee': JSON.stringify(lstwing), screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getRegistrationFeesList();
                this.setRegistrationFeesTable();
                this.addRegistrationFeesType();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });


    }

    addTD(row, val, classname) {
        let cell = document.createElement('td');
        cell.className = classname;
        let text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    addRegistrationFeesType = () => {
        $('#registrationFeeAddForm').trigger('reset');
        $('#registrationFeesTableBody tr').removeClass('selected');
    }


    getWingId = (wingId) => {
        // console.log("wingId" + wingId);
    }


    render() {
        httpURL = lstModules['registration'].url + ":" + lstModules['registration'].port;
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer registrationFees" style={{ opacity: this.state.opacity }}>
                    <div className="col-md-11 col-centered">
                        <div className="col-md-6  col-xs-6  padding-remove">
                            <table className="tableLayout1" style={{ width: this.state.width }}>
                                <thead>
                                    <tr>
                                        <th style={{ display: 'none' }}> registrationFees Id </th>
                                        <th style={{ width: 40 }}>
                                            <div style={{ float: 'left', width: '100%' }}>
                                                <select type="text" onChange={this.setRegistrationFeesTable} className="form-control inp-search" id="filterWingsCmb"> </select>
                                            </div>
                                        </th>
                                        <th style={{ width: 40 }}> Class </th>
                                        <th style={{ width: 40 }}> Fees </th>
                                        <th style={{ width: 40 }}> Updates On </th>
                                        <th style={{ width: 40 }}>Action</th>
                                    </tr>
                                </thead>
                            </table>
                            <div className="registrationFeesTableDiv" style={{ height: '400px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                <table id="registrationFeesTable" className="tableLayout">
                                    <colgroup>
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                    </colgroup>
                                    <tbody id="registrationFeesTableBody"></tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-xs-1 ">
                            <span id="addRegistrationFees" title="Add Registeration " onClick={this.addRegistrationFeesType} className="addIcon glyphicon glyphicon-plus-sign"></span>
                        </div>
                        <div className="col-md-5 col-xs-8 padding-remove timingFormWrap" >
                            <form id="registrationFeeAddForm" className="form-horizontal formWrapper">
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Wing</label>
                                    <div className="col-sm-6 padding-remove">
                                        <select onChange={this.changeWing} className="form-control" style={{ width: '100%' }} id="wingsCmb"></select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Class</label>
                                    <div className="col-sm-6 padding-remove">
                                        <select onChange={this.changeClass} className="form-control" style={{ width: '100%' }} id="classTypeCmb"></select>
                                    </div>
                                </div>



                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Fees<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="fee" type="number" className="form-control " placeholder="Enter Fees" />
                                    </div>
                                </div>

                                <div className="form-group" >
                                    <div className="col-sm-12 text-center userButtonWrap" >
                                        <button type="button" style={{ marginTop: 20 }} title="Save" onClick={this.addRegistrationFees} className="btn btn-info">Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        );
    }



}
export default registrationFees;