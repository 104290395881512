import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "common/css/master.css";
import "../css/transport.css";
import Header from "common/js/header/header.js";
import RouteStopTable from "./routeStopTable";
import RouteStopSlider from "./routeStopSlider";
import LatLngSlider from "./latLngSlider";
import { deleteRouteStopSvc } from "./routeSvc";
import { useLocation } from "react-router-dom";

function RouteStopCtrl() {
  const [loader, setLoader] = useState("none");
  const [flgSlider, setFlgSlider] = useState(false);
  // const [flgStaff,setFlgStaff]=useState(false)
  const [randNo, setRandNo] = useState(0);
  const [stopObj, setStopObj] = useState({});
  const [stopId, setStopId] = useState(0);
  const search = useLocation().search;
  const [routeId, setRouteId] = useState(
    new URLSearchParams(search).get("routeId")
  );
  const [routeNo, setRouteNo] = useState(
    new URLSearchParams(search).get("routeNo")
  );
  const [flgMorEve, setFlgMorEve] = useState(false);
  const [sliderKey, setSliderKey] = useState(0)
  const [latLng, setLatLng] = useState('')
  const [flgSliderLatLng,setFlgSliderLatLng] = useState(false)

  useEffect(() => {
    // console.log(routeNo + "::" + routeId);
  }, []);

  function generateRandomNo() {
    var lRandNo = Math.floor(Math.random() * 999999);
    // console.log(lRandNo);
    setRandNo(lRandNo);
  }
  function generateSliderKey() {
    var lRandNo = Math.floor(Math.random() * 999999);
    // console.log(lRandNo);
    setSliderKey(lRandNo);
  }
  function openStopSlider(pRouteId, pStopId, pFlgMorEve, pStopObj) {
    // console.log(pStopObj);
    setStopId(pStopId);
    setFlgSlider(true);
    // console.log(typeof pStopId);
    setStopObj(pStopObj);
    setFlgMorEve(pFlgMorEve);
  }
  function openLatLngSlider(pLatLng) {
    setFlgSliderLatLng(true);
    
  }
  function closeSlider() {
    setFlgSlider(false);
    setFlgSliderLatLng(false);
    
    setStopObj({});
    setStopId(0);
    generateRandomNo();
  }

  function deleteRouteStop(pRouteId, pStopId) {
    deleteRouteStopSvc(pRouteId, pStopId, 20, cbRouteStopResponse);
  }

  function cbRouteStopResponse(data) {
    setLoader("none");
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg);
    }
    closeSlider();
  }
  const getWingId = (wingId) => {
    // console.log("wingId" + wingId);
  };
  return (
    <div
      className="MainContainer"
      style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className="TestTblWrapper">
        <div className="col-sm-12 padding-remove"></div>
        <div className="col-sm-12 padding-remove"></div>
        <div className="TestTbl">
          <RouteStopTable
            setStopId={setStopId}
            routeId={routeId}
            setFlgMorEve={setFlgMorEve}
            key={randNo}
            routeNo={routeNo}
            flgMorEve={flgMorEve}
            closeSlider={closeSlider}
            generateRandomNo={generateRandomNo}
            generateSliderKey={generateSliderKey}
            openStopSlider={openStopSlider}
            deleteRouteStop={deleteRouteStop}
            openLatLngSlider={openLatLngSlider}
            setLatLng={setLatLng}
          />
        </div>
        {/* <div
          className="col-xs-1"
          onClick={(e) => openStopSlider(routeId, 0, {})}
        >
          <span
            id="addReadyId"
            title="Add"
            className="addIcon glyphicon glyphicon-plus-sign"
          ></span>
        </div> */}
        <div
          className="TestSlider"
          style={{
            display: flgSlider ? "block" : "none",
            width: "50%",
            overflowY: "none",
          }}
        >
          <RouteStopSlider
            key={randNo}
            openSlider={openStopSlider}
            closeSlider={closeSlider}
            setRandNo={setRandNo}
            stopObj={stopObj}
            routeId={routeId}
            flgMorEve={flgMorEve}
            setStopId={setStopId}
            generateRandomNo={generateRandomNo}
          />
        </div>
        {flgSliderLatLng && <div
          className="TestSlider"
          style={{
            display: flgSliderLatLng ? "block" : "none",
            width: "50%",
            overflowY: "none",
          }}
        >
          <LatLngSlider
            key={randNo}
            openSlider={openLatLngSlider}
            latLng={latLng}
            closeSlider={closeSlider}
            setRandNo={setRandNo}
            setStopId={setStopId}
            flgSliderLatLng={flgSliderLatLng}
            generateRandomNo={generateRandomNo}
            stopId={stopId}
          />
        </div>}

      </div>
    </div>
  );
}
export default RouteStopCtrl;
