import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { nextQusSvc } from "./WTestProgressSvc.js";

const WTestQuestionRow = (props) => {
    const [qusObj, setQusObj] = useState({})
    const [loader, setLoader] = useState('none')
    const [qusId, setQusId] = useState(0)
    const [qusNo, setQusNo] = useState(0)
    const [answeredBy, setAnsweredBy] = useState(0)

    useEffect(() => {
        // console.log(props)
        setQusObj(props.obj)
        /* setQusId(props.obj.qusId)
        setQusNo(props.obj.qusNo)
        setAnsweredBy(props.obj.answeredBy); */

    }, [props.obj]);

    function nextQus() {
        nextQusSvc(props.testId, cbNextQusSvc)
        props.getTestLeaderboard()
    }
    function cbNextQusSvc(data) {
        // console.log("cbNextQus() called.. ")
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg);
        } else {
            toast.error(data.SvcMsg)
        }
    }

    
    

    return (
        <tr
            key={'qId_' + qusObj.qusNo}
            id={qusObj.qusNo}
            onClick={e => props.setSelQusNo(qusObj.qusNo)}
            className={props.selQusNo !== 0 && qusObj.qusNo === props.selQusNo ? 'selected' : ''}
        >
            <td className='text-center'>{qusObj.qusNo}</td>
            <td className='text-center'>{qusObj.answeredBy}</td>
            <td className='text-center'><input style={{ cursor: 'pointer',height:25 }}
                type="button"
                className="btn btn-link" onClick={(e) => {nextQus();props.getTestLeaderboard();}} value="Next Question"/></td>
        </tr>
    )
}

export default WTestQuestionRow