import React, { useState, useEffect } from 'react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';



const SyllabusSubjectTable = (props) => {
  const { lstSyllabusSubject, setLstSyllabusSubject, selectAll, checkedAll, checkedClick, showSlider, saveSubject } = props;

  const [filterName, setFilterName] = useState('');

  const handleFilterChange = (e) => {
    setFilterName(e.target.value);
  };

  const handleSelectAll = (e) => {
    selectAll(e.target.checked);
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(lstSyllabusSubject);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    // console.log("item after moving rows", items);
    await setLstSyllabusSubject(items);
  };

  const renderTableBody = () => {
    return lstSyllabusSubject.map((subjects, index) => (
      (filterName.trim() === '' ||
        subjects.subject.toLowerCase().includes(filterName.trim().toLowerCase()) ||
        subjects.selected) && (
        <Draggable key={subjects.subjectId} draggableId={String(subjects.subjectId)} index={index}>
          {(provided) => (
            <tr
              key={subjects.subjectId}
              id={subjects.subjectId}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <td width="5%" className="text-left" {...provided.dragHandleProps}><span style={{margin:0, width:"100%"}} className="glyphicon glyphicon-th-list" ></span></td>
              <td width="50%" className="no-display">{subjects.subjectId}</td>
              <td width="50%" className="text-left">{subjects.subject}</td>
              <td width="20%" className="text-center">
                <input
                  key={subjects.subjectId}
                  type="checkbox"
                  checked={subjects.selected}
                  onChange={(e) => checkedClick(e, subjects)}
                  className="formControl"
                  id={index}
                />
              </td>
              <td width="25%" className="text-center">
                {subjects.selected && (
                  <span
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    onClick={() => showSlider(subjects.sybSubId, subjects.subjectId)}
                  >
                    Chapter
                  </span>
                )}
              </td>
            </tr>
          )}
        </Draggable>
      )
    ));
  };
  return (
    <div className="syllabusSubjectWrapper">
      <table className="tableLayout1" id="customTable">
        <thead>
          <tr>
            <th width="5%"></th>
            <th width="50%">
              <input type="text" className="form-control inp-search text-center"
                onChange={handleFilterChange}
                // style={{width:"100%"}}
                id="syllabus"
                placeholder="Subject"
              />
            </th>
            <th width="20%" className="selectAll">
              Select
              <input
                type="checkbox"
                id="cbxSelectAll"
                onClick={handleSelectAll}
                checked={checkedAll}
              />
            </th>
            <th width="25%">Action</th>
          </tr>
        </thead>
      </table>
      <div className="tableDivSmall2">
        <table id="mainTable" className="tableLayout">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable-1" type="PERSON">
              {(provided) => (
                <tbody
                  id="syllabusSubjectTableBody"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {renderTableBody()}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>
      </div>
      <div className="form-group">
        <div className="col-sm-8 text-left">
          You can change subjects order by dragging them up-down.
          </div>
        <div className="col-sm-4 pull-right userButtonWrap">
          <button
            type="button"
            style={{ marginTop: 20 }}
            title="Save"
            className="btn btn-info"
            onClick={saveSubject}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default SyllabusSubjectTable;
