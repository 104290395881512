import React, { useEffect, useState } from 'react';
import { Document, Page, PDFViewer, View, Text } from '@react-pdf/renderer';

import { pageStyle, styleHeaderLogo, fontStyles } from './styleAdmitCard';
import AdmitCardHeader from './admitCardHeader';
import AdmitCardDetails from './admitCardDetails';



const AdmitCardContent = (props) => {
    const [studentName, setStudentName] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [schoolAddress, setSchoolAddress] = useState("");
    const [cls, setCls] = useState("");
    const [academicYear, setAcademicYear] = useState("");
    const [examName, setExamName] = useState("");
    const [rollNo, setRollNo] = useState("");
    const [schoolLogo, setSchoolLogo] = useState("");
    const [classTeacherSign, setClassTeacherSign] = useState("");
    const [studentImgUrl, setStudentImgUrl] = useState("");


    useEffect(() => {
        // console.log("AdmitCardContent called");
        setStudentName(props.studentName);
        setSchoolName(props.schoolName);
        setSchoolAddress(props.schoolAddress);
        setCls(props.cls);
        setAcademicYear(props.academicYear);
        setExamName(props.examName);
        setRollNo(props.rollNo);
        setSchoolLogo(props.schoolLogo);
        setClassTeacherSign(props.classTeacherSign);
        setStudentImgUrl(props.studentImgUrl);
    }, [props]);




    return (
        <View style={[pageStyle.page_half]}>
            <View style={[pageStyle.page_border]}>
                <View style={[pageStyle.header_section]}>
                    <AdmitCardHeader
                        schoolLogo={schoolLogo}
                        schoolName={schoolName}
                        schoolAddress={schoolAddress}
                        academicYear={academicYear}
                        examName={examName}
                    />
                </View>
                <View style={[pageStyle.title_section]}>
                    <View style={[styleHeaderLogo.title_box]}>
                        <Text style={fontStyles.textCenterMediumInter}>
                            ADMIT CARD
                        </Text>
                    </View>
                </View>
                <View style={[pageStyle.content_section]}>
                    <AdmitCardDetails
                        studentName={studentName}
                        cls={cls}
                        rollNo={rollNo}
                        classTeacherSign={classTeacherSign}
                        studentImgUrl={studentImgUrl}
                    />
                </View>
            </View>
        </View>
    );

}

export default AdmitCardContent;