import React, { useState, useEffect } from "react";
import { getFacListSvc, saveFacListSvc } from "./ListingFacilitySvc";
import { toast, ToastContainer } from "react-toastify";
var schoolId = 77;

const FacilityTable = (props) => {
  const [facilityId, setFacilityId] = useState(0);
  const [lstFacility, setLstFacility] = useState([]);
  const [lstSelFacId, setLstSelFacId] = useState([]);
  const [lstFacId, setLstFacId] = useState([]);

  useEffect(() => {
    getFacList();
  }, [props.groupId]);

  function getFacList() {
    getFacListSvc(props.groupId, cbFacListResponse);
  }

  function cbFacListResponse(obj) {
    if (obj.status === "Success") {
      setLstFacility(obj.lstFacility);
      var lstTmpSelFacId = []
      obj.lstFacility.forEach(function (obj) {
        if (obj.isAvailable) lstTmpSelFacId.push(obj.facilityId)
      })
      setLstSelFacId(lstTmpSelFacId)
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed " + obj.message);
    }
  }

  async function handleLstFacIdChange(pId, pChecked) {
    // console.log(pId);
    // console.log(pChecked);
    var tmpLstFac = lstSelFacId;
    pId = parseInt(pId);
    pChecked = !!pChecked;

    if (pChecked && !lstSelFacId.includes(pId)) {
      tmpLstFac.push(parseInt(pId));
      setLstSelFacId(tmpLstFac);
    } else {
      tmpLstFac.splice(tmpLstFac.indexOf(pId), 1);
      setLstSelFacId(tmpLstFac);
    }
    setLstFacId(tmpLstFac);
  }

  const colgrp = (
    <colgroup>
      <col width="80%" />
      <col width="20%" />
    </colgroup>
  );

  function saveFacList() {
    var facObj = { lstFac: lstSelFacId, schoolId: 77, grpId: props.groupId };
    saveFacListSvc(facObj, cbSaveFacesponse);
  }

  function cbSaveFacesponse(data) {
    if (data.status === "Success") {
      getFacList();
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  }

  return (
    <div>
      <table className="tableLayout1">
        {colgrp}
        <thead>
          <tr>
            <th>Facility</th>
            <th>Available</th>
          </tr>
        </thead>
      </table>
      <div className="" style={{ height: 320, overflowY: "scroll" }}>
        <table className="tableLayout">
          {colgrp}
          <tbody>
            {lstFacility &&
              lstFacility.map((obj, key) => {
                return (
                  <tr
                    className={facilityId === obj.facilityId ? "selected" : ""}
                    key={"cls_" + key}
                    //   onClick={(e) => openFeeSlider(obj)}
                  >
                    <td className="text-left">{obj.facility}</td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        key={"f_" + obj.facilityId}
                        id={obj.faciltyId}
                        defaultChecked={obj.isAvailable !== 0}
                        onChange={(e) =>
                          handleLstFacIdChange(obj.facilityId, e.target.checked)
                        }
                      ></input>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="form-group col-md-12">
        <button
          type="button"
          className="btn btn-warning pull-right"
          onClick={(e) => saveFacList()}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default FacilityTable;
