import React, { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'common/css/master.css'
import StudentFeeHeadTable from './StudentFeeHeadTable.js'
import Header from 'common/js/header/header.js'

function EnquiryCtrl () {
  const [selectedEnquiryId, setSelectedEnquiryId] = useState(0)
  const [lstSelEnqId, setLstSelEnqId] = useState([])
  const [randNo, setRandNo] = useState(0)
  const [flgSlider, setFlgSlider] = useState(0)

  function refreshScreen () {
    setSelectedEnquiryId(0)
    setFlgSlider(0)
    generateRandomNo()
  }

  function generateRandomNo () {
    var lRandNo = Math.floor(Math.random() * 999999)
    // console.log(lRandNo)
    setRandNo(lRandNo)
  }

  function scheduleInterviewSlider (pTypeId) {
    if (lstSelEnqId.length === 0) {
      toast.warn('Please select atleast one enquiry')
      return
    }
    setFlgSlider(0)
  }

  const getWingId = wingId => {
    // console.log('wingId = ' + wingId)
  }

  return (
    <div className='MainContainer'>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />

      <div className='enquiryTblWrapper'>
        <div className='enquiryTbl'>
          <StudentFeeHeadTable
            key={randNo}
            selectedEnquiryId={selectedEnquiryId}
            setSelectedEnquiryId={setSelectedEnquiryId}
            setFlgSlider={setFlgSlider}
          />
        </div>
      </div>

      <div
        style={{
          display: flgSlider ? 'block' : 'none'
        }}
        className='enquirySlider'
      >
        <button
          type='button'
          className='btn btn-info text-center btnCloseDiv'
          onClick={refreshScreen}
        >
          <span
            id='close'
            title='Close Slider'
            className='glyphicon glyphicon-remove'
          ></span>
        </button>
        {/* <PaymentSlider
          key={selectedEnquiryId}
          enquiryId={selectedEnquiryId}
          closeSlider={refreshScreen}
          setSelectedEnquiryId={setSelectedEnquiryId}
          generateRandomNo={generateRandomNo}
          flgSlider={flgSlider}
        /> */}
      </div>
    </div>
  )
}

export default EnquiryCtrl
