import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../css/leaderboard.css';

const FinalScore = (props) => {

    const { flgFinalScore } = props;


    useEffect(() => {
        // console.log("StudentScore called, flgFinalScore", flgFinalScore);
    }, [flgFinalScore]);

    return (
        <div className="col-sm-12 col-xs-12 final-score padding-remove" >
            {flgFinalScore ? "Final Leaderboard!" : ""}
        </div>
    );
}

export default FinalScore;