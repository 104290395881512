import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import WTestQuestionRow from './WTestQuestionRow.js';
import { getTestQusListSvc, getQusInfoSvc } from "./WTestProgressSvc.js";

const WTestProgressTable = (props) => {
    const [lstQusStatus, setLstQusStatus] = useState([])
    const [loader, setLoader] = useState("none")
    const [selQusNo, setSelQusNo] = useState(0);
    
    useEffect(() => {
        getTestQusList();
    },[])

    useEffect(() => {
        const interval = setInterval(() => {
            getQusInfo();
        }, 2000);

        return () => clearInterval(interval);
    })
    
    function getQusInfo() {
        getQusInfoSvc(props.testId, cbGetQusInfo)
    }
    
    function cbGetQusInfo(data) {
        // console.log("cbGetQusInfo() called.. ")
        if (data.SvcStatus === "Success") {
            let tmpQusProgress = data.qusProgress;
            const lstTestQuestion = [...lstQusStatus];
            // console.log(lstTestQuestion)
            for (var k = 0; k < lstTestQuestion.length; k++) {
                if (lstTestQuestion[k].qusNo === tmpQusProgress.qusNo) {
                    lstTestQuestion[k] = tmpQusProgress;
                    break;
                }
            }
            setLstQusStatus(lstTestQuestion);
        } else {
        }
    }

    function getTestQusList() {
        getTestQusListSvc(props.testId, cbGetTestQusListSvc);
    }
    
    function cbGetTestQusListSvc(data) {
        // console.log("cbGetTestQusListSvc() called.. ")
        if (data.SvcStatus === "Success") {
            setLstQusStatus(data.lstTestQuestion)
        } else {
            setLstQusStatus([])
            toast.error(data.SvcMsg)
        }
        setLoader("none");
    }

    const colgrp = (
        <colgroup>
            <col width='5%'></col>
            <col width='10%'></col>
            <col width='7%'></col>
        </colgroup>
    )

    return (
        <div >
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }} >
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div >
            <table className='tableLayout1'>
                {colgrp}
                <thead>
                    <tr>
                        <th>Question</th>
                        <th>Answered By</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </table>
            <div className='tablebodyWrapper'>
                <table className='tableLayout'>
                    {colgrp}
                    <tbody id="tbodyQuestion">
                        {lstQusStatus.map((obj, idx) => {
                            return (
                                <WTestQuestionRow
                                    key={'qus' + obj.qusNo + "_" + obj.answeredBy}
                                    testId={props.testId}
                                    obj={obj}
                                    setSelQusNo={setSelQusNo}
                                    selQusNo={selQusNo}
                                    getTestLeaderboard={props.getTestLeaderboard}
                                    cbGetTestLeaderboardSvc={props.cbGetTestLeaderboardSvc}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default WTestProgressTable