import React, { Component } from 'react'
import $ from 'jquery';
import '../css/header.css'

export default class ExamTimeLine extends Component {
    state = {
        tabClicked: 0
    }
    /* constructor(props) {
        super(props);
    } */
    componentDidMount() {
        // console.log("ExamTimeline = " + this.props.data);
        this.handleTimelineClick = this.handleTimelineClick.bind(this);
    }
    handleTimelineClick = (e, examId, alongWithStudy, startDt, lstExamDays) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        // console.log("examScheduleId = " + examId);
        $("[id$='_child']").removeClass("active_schedule");
        $('#' + examId + '_child').addClass("active_schedule");
        this.props.parentCallback(examId, alongWithStudy, startDt, lstExamDays);
        // return(
        //         <div key={exams.examId}>
        //             <span>{exams.startDt +"-"+ exams.endDt}</span>
        //         </div>

    }
    render() {
        return (
            <>
                <div className="col-sm-12" style={{ margin: '10px' }}>
                    {this.props.data !== undefined && this.props.data.length != 0 && this.props.data.map((exams) => (
                        <div key={exams.examId}>
                            <div
                                className="academicScheduleDot"
                                data-date={exams.startDt}
                                id={exams.examId}
                                key={exams.examId}
                            >
                            </div>

                            <input
                                type="button"
                                value={exams.exam}
                                title={exams.startDt + "-" + exams.endDt}
                                id={exams.examId + '_child'}
                                style={{ width: 100 }}
                                className="examSchedule"
                                onClick={((e) => this.handleTimelineClick(e, exams.examId, exams.alongWithStudy, exams.startDt, exams.lstExamDays))}
                            />

                            {/* <label className="control-label" className="lblScheduleInfo"
                            title={schedule.startDt +"-"+ schedule.endDt}>{schedule.sessionType}</label> */}
                            {/* <p className="control-label" className="lblScheduleInfo">{schedule.startDt} - {schedule.endDt}</p> */}
                            {/* {
                                
                                parseInt(schedule.percentage.substring(0,schedule.percentage.length-1))>19
                                ?
                                <p className="control-label" className="lblScheduleInfo">{schedule.startDt} - {schedule.endDt}</p>
                                :
                                <p className="control-label" className="lblScheduleInfo"> {schedule.startDt}<br></br>-{schedule.endDt}</p>
                            } */}
                            {/* </button> */}
                        </div>

                    ))}
                    {/* <div className="col-sm-12" style={{margin:'10px'}}>

                {this.props.data!==undefined && this.props.data.length != 0 && this.props.data.map((exams) => (
                    <div key={exams.examId}>
                    
                    </div>
                    ))}

                </div> */}
                </div></>
        )
    }
}
