import React, { useEffect, useState } from 'react';
import { Text, View, Image } from '@react-pdf/renderer';

import { styleFooter, fontStyles } from './styleTcPdf';

const TcFooter = (props) => {
    const { principalSign } = props;

    const [principalSignUrl, setPrincipalSignUrl] = useState("");


    useEffect(() => {
        // console.log("TcFooter called, principalSign", principalSign);
        // console.log("tempTeacherSignUrl", tempTeacherSignUrl);
        setPrincipalSignUrl(principalSign);
    }, [principalSign]);

    return (
        <View style={styleFooter.block}>
            <View style={styleFooter.empty_row}>
                <View style={styleFooter.colSign}>
                </View>
                <View style={styleFooter.colSign}>
                </View>
                <View style={styleFooter.colSign}>
                    {
                        !!principalSignUrl ?
                            <Image style={styleFooter.image_sign} src={principalSignUrl} />
                            : null
                    }
                </View>
            </View>
            <View style={styleFooter.row_labels}>
                <View style={styleFooter.colSignLabel}>
                </View>
                <View style={styleFooter.colSignLabel}>
                </View>
                <View style={styleFooter.colSignLabel}>
                    <Text style={fontStyles.textCenterNormalInter}>PRINCIPAL</Text>
                </View>
            </View>
        </View>
    );
}

export default TcFooter;