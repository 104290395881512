import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['students'].url + ':' + lstModules['students'].port;
const tokenid = localStorage.getItem('tokenid');
const schoolId = localStorage.getItem("schoolId");
const academicYearId = localStorage.getItem("academicYearId");
var queryString = require("querystring");

export const getClassListSvc = (callback) => {
    const responseObj = { status: "", message: "", error: "" };
    axios({
        url: `${httpURL}/sms/mst/cls/list`,
        method: 'POST',
        data: queryString.stringify({ schoolId }),
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // console.log("response in geClassListSvc, response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            if (response.data.SvcStatus === "Success") {
                responseObj.lstCls = response.data.lstClass;
            }
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};

export const getStateListSvc = (callback) => {
    const responseObj = { status: "", message: "", error: "" };
    axios({
        url: `${httpURL}/sms/mst/state/list`,
        method: 'POST',
        data: queryString.stringify({ schoolId }),
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // console.log("response in getStateListSvc, response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            if (response.data.SvcStatus === "Success") {
                responseObj.lstState = response.data.lstState;
            }
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};

export const studentDetailsSvc = (studentId, callback) => {
    // console.log('studentDetailsSvc called, studentId', studentId);
    const responseObj = { status: "", message: "", error: "" };
    const schoolStudent = {
        schoolId: schoolId,
        studentId: parseInt(studentId),
    };
    axios({
        url: httpURL + "/sms/student/dtls",
        method: 'POST',
        data: queryString.stringify({
            schoolStudent: JSON.stringify(schoolStudent),
            acdYrId: academicYearId,
        }),
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // console.log("response in studentDetailsSvc, response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            if (response.data.SvcStatus === "Success") {
                responseObj.studentDtls = response.data.studentDtls;
            }
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};

export const docDtlsSvc = (studentId, callback) => {
    // console.log('docDtlsSvc called, studentId', studentId);
    const responseObj = { status: "", message: "", error: "" };

    axios({
        url: httpURL + "/sms/student/docs/dtls",
        method: 'POST',

        params: { studentId: studentId },
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // console.log("response in docDtlsSvc, response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            if (response.data.SvcStatus === "Success") {
                responseObj.lstDocDtls = response.data.lstDocDtls;
            }
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};
