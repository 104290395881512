import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import { saveCommunicationGroupSvc } from "./commGroupsSvc";

const CommunicationGroupSlider = (props) => {

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [groupId, setGroupId] = useState(props.selGroupId);
    const [groupData, setGroupData] = useState({
        "commGroupName": "",
        "commGroupId": 0,
        "studentsCount": 0,
        "createdDttm": "",
    });

    useEffect(() => {
        // console.log("CommunicationGroupSlider called, groupData", props.selGroupData, ", commGroupId", props.selGroupId)
        setGroupData(props.selGroupData);
    }, [props.selGroupId]);

    const updateGroupName = (gName) => {
        // console.log("updateGroupName called, gName passed", gName);
        setGroupData(prevGroupData => {
            return {
                ...prevGroupData,
                "commGroupName": gName,
            };
        });
    }

    const saveCommunicationGroup = () => {
        if (groupData.commGroupId === "") {
            toast.error("please fill group name");
            return;
        }
        setLoader('block');
        setOpacity(0.5);
        saveCommunicationGroupSvc(groupData.commGroupId, groupData.commGroupName, saveCommunicationResponse);
    }

    const saveCommunicationResponse = (data) => {
        // console.log("saveCommunicationResponse Called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            setGroupId(obj.commGroupId);
            props.closeSlider();
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader('none');
        setOpacity(1);
    }

    return (
        <div style={{ width: "100%", textAlign: "center", margin: 0 }}>
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>

            <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={() => props.closeSlider()} style={{ right: "60%" }}>
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>

            <form id="addFormcommGroup" className="col-centered col-sm-8 form-horizontal formWrapper clearfix" style={{ opacity: opacity }}>
                <div className="col-sm-12 padding-remove ">
                    <label className=" col-sm-12 text-center padding-remove" style={{ fontWeight: "bold", paddingLeft: 10 }}>
                        {groupId ? "Edit Communication Group" : "Add Communication Group"}
                    </label>
                </div>
                <div className="ab">
                    <div className="form-group">
                        <label className="control-label col-sm-4" >Group Name<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-7 padding-remove">
                            <input autoComplete="off"
                                type="text"
                                className="form-control text-left"
                                value={groupData.commGroupName}
                                onChange={(e) => updateGroupName(e.target.value)}
                                placeholder="Group Name" />
                        </div>
                    </div>
                    {groupId ?
                        <div className="form-group">
                            <label className="control-label col-sm-4" >Count</label>
                            <div className="col-sm-7 padding-remove">
                                <input autoComplete="off"
                                    type="text"
                                    className="form-control text-left"
                                    value={groupData.studentsCount}
                                    placeholder="Count"
                                    disabled={true}
                                />
                            </div>
                        </div>
                        : null
                    }
                    {groupId ?
                        <div className="form-group">
                            <label className="control-label col-sm-4" >Created On</label>
                            <div className="col-sm-7 padding-remove">
                                <   input autoComplete="off"
                                    type="text"
                                    className="form-control text-center"
                                    value={groupData.createdDttm}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        : null
                    }
                </div>
                <div className="col-sm-3 padding-remove paymentButtons">
                    <div className="form-group" >
                        <div className="ab1" >
                            <button type="button" style={{ marginTop: 20 }}
                                title="Save"
                                onClick={() => saveCommunicationGroup()}
                                className="btn btn-info">Save</button>
                        </div>
                    </div>

                </div>
            </form >
        </div>
    );
}

export default CommunicationGroupSlider;