import axios from 'axios'
import $ from 'jquery'
import { toast, ToastContainer } from "react-toastify"
import lstModules from 'common/json/lstModules.json';

var queryString = require('querystring');
var screenId = 33;
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules['main'].url + ":" + lstModules['main'].port;

function getClassData(setData) {
    var status = 'Success'
    var obj = { status: status };

    // console.log(httpURL)
    axios({
        url: httpURL + '/sms/cls/section/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;
        if (response.data.SvcStatus === "Success") {
        obj.lstAllClassSections = response.data.lstAllClassSections;
        // console.log(obj);
    }
        setData(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        setData(obj)
    });
}

function getClassList(callback1) {
    // console.log("getClassList");
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/mst/cls/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;
        if (response.data.SvcStatus === "Success") {
        obj.lstClass = response.data.lstClass;
        // console.log(obj);
        }
        callback1(obj);
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback1(obj)
    });
}

function getClassTeacherList(callback2) {
    // console.log("getClassTeacherList")
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/teacher/acd/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;
        if (response.data.SvcStatus === "Success") {
        obj.lstTeacher = response.data.lstTeacher;
        // console.log(obj);
        }
        callback2(obj);
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback2(obj)
    });
}

function getClassTeacherDetail(callback4, secId) {
    // console.log("getClassTeacherDetail")
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + "/sms/cls/class_teacher/dtls",
        method: "POST",
        params: { classSectionId: secId, screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log('data.SvcStatus=' + response.data.SvcStatus);
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;
        if (response.data.SvcStatus === "Success") {
        obj.teacherDtls = response.data.teacherDtls;
        // console.log(obj);
        }
        callback4(obj);
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback4(obj)
    });
}

function getBuildingList(callback3) {
    var status = 'Success'
    var obj = { status: status };

    axios.post(httpURL + '/sms/a/building/list',
        queryString.stringify({ screenId }), {
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        // console.log('data.SvcStatus=' + response.data.SvcStatus);
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;
        if (response.data.SvcStatus === "Success") {
        obj.lstBuildings = response.data.lstBuildings;
        // console.log(obj);
        }
        callback3(obj);
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback3(obj)
    });
}


function saveClass(classSection, cb) {
    // console.log(classSection);
    var status = 'Success'
    var obj = { status: status };

    classSection.classId = parseInt(classSection.classId);
    axios.post(httpURL + '/sms/cls/section/save',
        queryString.stringify({ 'classSection': JSON.stringify(classSection), screenId }), {

        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === "Success") {
            /* toast.success(response.data.SvcMsg);

            closeSlider(); */
            obj.SvcMsg = response.data.SvcMsg;
            obj.status = 'Success';
            cb(obj)
        }
        else {
            /* toast.error(response.data.SvcMsg); */
            obj.status = 'Failure';
            obj.SvcMsg = response.data.SvcMsg;
            cb(obj)
        }
        //setState({...state, loader: 'none', opacity: 1 });
    }).catch((e) => {
        //setState({...state, loader: 'none', opacity: 1 })
        /* toast.error("Service failed " + e); */

        obj.status = 'Exception';
        obj.SvcMsg = e;
        cb(obj)
    });
}
function saveClassTeacher(teacher, cb) {
    // console.log(teacher);
    var status = 'Success'
    var obj = { status: status };

    teacher.classSectionId = parseInt(teacher.classSectionId);
    axios.post(httpURL + '/sms/cls/class_teacher/save',
        queryString.stringify({ 'teacher': JSON.stringify(teacher), screenId }), {

        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === "Success") {
            obj.SvcMsg = response.data.SvcMsg;
            obj.status = 'Success';
            cb(obj)
        }
        else {
            obj.status = 'Failure';
            obj.SvcMsg = response.data.SvcMsg;
            cb(obj)
        }

    }).catch((e) => {
        obj.status = 'Exception';
        obj.SvcMsg = e;
        cb(obj)

    });
}

function saveBuilding(building, cb) {
    // console.log(building);

    var status = 'Success'
    var obj = { status: status };

    building.classSectionId = parseInt(building.classSectionId);
    axios.post(httpURL + '/sms/cls/building/save',
        queryString.stringify({ 'building': JSON.stringify(building), screenId }), {

        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;
        if (response.data.SvcStatus === "Success") {

            obj.SvcMsg = response.data.SvcMsg;
            obj.status = 'Success';
            cb(obj)
        }
        else {
            obj.status = 'Failure';
            obj.SvcMsg = response.data.SvcMsg;
            cb(obj)
        }
        //setState({...state, loader: 'none', opacity: 1 });
    }).catch((e) => {
        obj.status = 'Exception';
        obj.SvcMsg = e;
        cb(obj)

    });
}

function verifyClassSection(setData) {
    var status = 'Success'
    var obj = { status: status };

    axios.post(httpURL + '/sms/cls/list',
        queryString.stringify({ screenId }), {
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        if (response.data.SvcStatus === "Success") {
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            obj.lstAllClassSections = response.data.lstAllClassSections;
            // console.log(obj);
            setData(obj)
        } else {
            obj.message = response.data.SvcMsg;
            obj.status = 'Failure';
            obj.lstAllClassSections = [];
            // console.log(obj);
            setData(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        setData(obj)

    });
}
function getStudentEligibleList(classId, sectionId, setData) {
    var status = 'Success'
    var obj = { status: status };

    axios.post(httpURL + '/sms/cls/elig_stu/list',
        queryString.stringify({ classId, screenId, sectionId }), {
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;
        if (response.data.SvcStatus === "Success") {
            obj.lstEligibleStudents = response.data.lstEligibleStudents;
            // console.log(obj);
            setData(obj)
        } else {
            obj.message = response.data.SvcMsg;
            obj.status = 'Failure';
            obj.lstEligibleStudents = [];
            // console.log(obj);
            setData(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        setData(obj)
    });
}
function getStudentsSectionList(classId, sectionId, setData) {
    var status = 'Success'
    var obj = { status: status };

    axios.post(httpURL + '/sms/cls/stu/list',
        queryString.stringify({ classId, sectionId, screenId }), {
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        if (response.data.SvcStatus === "Success") {
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            obj.lstClassStudents = response.data.lstClassStudents;
            // console.log(obj);
            setData(obj)
        } else {
            obj.message = response.data.SvcMsg;
            obj.status = 'Failure';
            obj.lstClassStudents = [];
            // console.log(obj);
            setData(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        setData(obj)
    });
}

function getGroupList(setData) {
    var status = 'Success'
    var obj = { status: status, message: "" };

    axios.post(httpURL + '/sms/cls/get_groups',
        queryString.stringify({ screenId }), {
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;
        if(response.data.SvcStatus === "Success"){
        obj.lstGroup1 = response.data.lstGroup1;
        obj.lstGroup2 = response.data.lstGroup2;
        obj.groupLabel = response.data.groupLabel;
        }
        setData(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        setData(obj)
    });
}
function assignClsSec(studentClassSection, cb) {
    var status = 'Success'
    var obj = { status: status };
    axios.post(httpURL + '/sms/cls/student/assign',
        queryString.stringify({ 'studentClassSection': JSON.stringify(studentClassSection), screenId }),
        {
            headers: { tokenid: tokenid },
            withCredentials: true
        }
    ).then((response) => {
        if (response.data.SvcStatus === "Success") {

            obj.SvcMsg = response.data.SvcMsg;
            obj.status = 'Success';
            cb(obj)
        }
        else {
            obj.status = 'Failure';
            obj.SvcMsg = response.data.SvcMsg;
            cb(obj)
        }

    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = e;
        cb(obj)
    });
}
function saveCls(classArr, cb) {
    var status = 'Success'
    var obj = { status: status };
    axios.post(httpURL + '/sms/cls/save',
        queryString.stringify({ 'classSection': JSON.stringify(classArr), screenId }),
        {
            headers: { tokenid: tokenid },
            withCredentials: true
        }
    ).then((response) => {
        if (response.data.SvcStatus === "Success") {

            obj.SvcMsg = response.data.SvcMsg;
            obj.status = 'Success';
            obj.sectionId = response.data.sectionId;
            cb(obj)
        }
        else {
            obj.status = 'Failure';
            obj.SvcMsg = response.data.SvcMsg;
            cb(obj)
        }
    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = e;
        cb(obj)
    });
}
function assignRollNo(studentRollNo, cb) {
    var status = 'Success'
    var obj = { status: status };
    axios.post(httpURL + '/sms/cls/stu/assign_roll',
        queryString.stringify({ 'studentRollNo': JSON.stringify(studentRollNo), screenId }),
        {
            headers: { tokenid: tokenid },
            withCredentials: true
        }
    ).then((response) => {
        if (response.data.SvcStatus === "Success") {

            obj.SvcMsg = response.data.SvcMsg;
            obj.status = 'Success';
            obj.sectionId = response.data.sectionId;
            cb(obj)
        }
        else {
            obj.status = 'Failure';
            obj.SvcMsg = response.data.SvcMsg;
            cb(obj)
        }
    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = e;
        cb(obj)
    });
}

function assignGroupSvc(studentGroup, cb) {
    var status = 'Success'
    var obj = { status: status };
    axios.post(httpURL + '/sms/cls/stu/assign_group',
        queryString.stringify({ 'studentGroup': JSON.stringify(studentGroup), screenId }),
        {
            headers: { tokenid: tokenid },
            withCredentials: true
        }
    ).then((response) => {

        if (response.data.SvcStatus === "Success") {

            obj.SvcMsg = response.data.SvcMsg;
            obj.status = 'Success';
            obj.sectionId = response.data.sectionId;
            cb(obj)
        }
        else {
            obj.status = 'Failure';
            obj.SvcMsg = response.data.SvcMsg;
            cb(obj)
        }
    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = e;
        cb(obj)
    });

}
function saveClsTch(classTeacherId, sectionId, cb) {
    var status = 'Success'
    var obj = { status: status };

    axios.post(httpURL + '/sms/cls/class_teacher/save',
        queryString.stringify({ classTeacherId, sectionId, screenId }),
        {
            headers: { tokenid: tokenid },
            withCredentials: true
        }
    ).then((response) => {

        if (response.data.SvcStatus === "Success") {

            obj.SvcMsg = response.data.SvcMsg;
            obj.status = 'Success';
            obj.sectionId = response.data.sectionId;
            cb(obj)
        }
        else {
            obj.status = 'Failure';
            obj.SvcMsg = response.data.SvcMsg;
            cb(obj)
        }
    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = e;
        cb(obj)
    });

}
function removeClsStudent(students, cb) {
    var status = 'Success'
    var obj = { status: status };
    axios.post(httpURL + '/sms/cls/stu/remove',
        queryString.stringify({ 'students': JSON.stringify(students), screenId }),
        {
            headers: { tokenid: tokenid },
            withCredentials: true
        }
    ).then((response) => {
        if (response.data.SvcStatus === "Success") {

            obj.SvcMsg = response.data.SvcMsg;
            obj.status = 'Success';
            obj.sectionId = response.data.sectionId;
            cb(obj)
        }
        else {
            obj.status = 'Failure';
            obj.SvcMsg = response.data.SvcMsg;
            cb(obj)
        }
    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = e;
        cb(obj)
    });
}

function uploadClassExcel(formData, cb) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + '/sms/section/excel/upload',
        method: "POST",
        data: formData,
        headers: { tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === "Success") {

            obj.message = response.data.SvcMsg;
            obj.status = response.data.SvcStatus;
        } else {
            obj.message = response.data.SvcMsg;
            obj.status = response.data.SvcStatus;
            obj.errors = response.data.errors;
        }
        cb(obj)
    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = e;
        cb(obj)

    });
}

const buildingRoomListSvc = (buildingId, cbBuildingRoomListResponse) => {
    // console.log("buildingRoomListSvc called");
    let obj = { status: "success", message: "" }
    axios({
        url: httpURL + '/sms/a/building/rooms/list',
        method: 'POST',
        params: { buildingId: buildingId, screenId },
        headers: { tokenid: tokenid },
        withCredentials: true
    })
        .then(response => {
            // console.log("status succes in buildingRoomListSvc, response", response);
            obj.status = response.data.SvcStatus;
            obj.message = response.data.SvcMsg;
            obj.lstRoom = response.data.lstBuildingRooms;
            cbBuildingRoomListResponse(obj);
        })
        .catch(error => {
            obj.status = "Exception";
            obj.message = error;
            cbBuildingRoomListResponse(obj);
        })
}

export {
    getClassData
    , getClassList
    , getClassTeacherList
    , getClassTeacherDetail
    , getBuildingList
    , saveClass
    , saveClassTeacher
    , saveBuilding
    , verifyClassSection
    , getStudentEligibleList
    , getStudentsSectionList
    , getGroupList
    , assignClsSec
    , saveCls
    , assignRollNo
    , removeClsStudent
    , saveClsTch
    , assignGroupSvc
    , uploadClassExcel
    , buildingRoomListSvc
}