import axios from 'axios';
import lstModules from 'common/json/lstModules.json'
var httpURL = lstModules['transport'].url + ':' + lstModules['transport'].port;
var tokenid = localStorage.getItem('tokenid');

function previewPdfReport1(callback) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + '/sms/tpt/report/route_no_pdf',
        method: "GET",
        params: { },
        headers: { 'tokenid': tokenid },
        responseType: 'arraybuffer',
        withCredentials: true,
    }).then((response) => {
        // console.log(response)
        obj.data = response.data;
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        callback(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}
function previewRouteListReport(callback) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + '/sms/tpt/report/route_list_pdf',
        method: "GET",
        params: { },
        headers: { 'tokenid': tokenid },
        responseType: 'arraybuffer',
        withCredentials: true,
    }).then((response) => {
        // console.log(response)
        obj.data = response.data;
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        callback(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}
function previewRouteDtlsReport(callback) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + '/sms/tpt/report/route_dtls_pdf',
        method: "GET",
        params: { },
        headers: { 'tokenid': tokenid },
        responseType: 'arraybuffer',
        withCredentials: true,
    }).then((response) => {
        // console.log(response)
        obj.data = response.data;
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        callback(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

export {previewPdfReport1, previewRouteListReport, previewRouteDtlsReport}
