import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var httpURL = lstModules['task'].url + ':' + lstModules['task'].port;
var httpURLMain = lstModules['main'].url + ":" + lstModules['main'].port;
var tokenid = localStorage.getItem('tokenid');

function getTaskListSvc(callBack) {
    // console.log("getTaskListSvc called");
    axios(
        {
            url: httpURL + "/sms/task/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // console.log("Task List Catch error")
    });
}

function saveTaskSvc(pTaskObj, callBack) {
    // console.log("saveTaskSvc called");
    var formData = new FormData();
    formData.append("task", JSON.stringify(pTaskObj));
    axios({
        url: httpURL + "/sms/task/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {

        var data = response.data
        callBack(data);
    }).catch((error) => { 
        // console.log("Error Occured")
     });
}

function deleteTaskSvc(pTaskId, callBack) {
    // console.log("deleteTaskSvc called");
    axios(
        {
            url: httpURL + "/sms/task/delete",
            method: 'POST',
            params: { taskId: pTaskId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data;
            callBack(data);
        }
    ).catch((error) => {
        console.error(error)
    });
}

function completeTaskSvc(pTaskId, callBack) {
    // console.log("completeTaskSvc called");
    axios(
        {
            url: httpURL + "/sms/task/complete",
            method: 'POST',
            params: { taskId: pTaskId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data;
            callBack(data);
        }
    ).catch((error) => {
        console.error(error)
    });
}

function getTaskDtlsSvc(pTaskId, cb) {
    // console.log("getTaskDtlsSvc called");
    axios(
        {
            url: httpURL + "/sms/task/dtls",
            method: 'POST',
            params: { taskId: pTaskId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            cb(data)

        }
    ).catch((error) => {
        // console.log("Task Dtls Catch error")
    });
}

function getClassListSvc(callback1) {
    // console.log("getClassListSvc called");
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURLMain + '/sms/mst/cls/list',
        method: "POST",
        params: { screenId: 0 },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log('data.SvcStatus=' + response.data.SvcStatus);
        obj.message = response.data.SvcMsg;
        obj.status = 'Success';
        obj.lstClass = response.data.lstClass;
        // console.log(obj);
        callback1(obj);
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback1(obj)
    });
}

function getLstTchSvc(pTaskId, cb) {
    // console.log("getLstTchSvc called");
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/task/tch/list',
        method: "POST",
        params: { taskId: pTaskId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.message = response.data.SvcMsg;
        obj.status = 'Success';
        obj.lstTch = response.data.lstTch;
        // console.log(obj);
        cb(obj);
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        cb(obj)
    });
}
export { getTaskListSvc, saveTaskSvc, deleteTaskSvc, getTaskDtlsSvc, getClassListSvc, getLstTchSvc, completeTaskSvc }