import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['ptm'].url + ':' + lstModules['ptm'].port
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port

function getStudentPtmSummaryListSvc(ptmId, sectionId, callBack) {
  axios({
    url: httpURLCommon + '/sms/ptm/student/feedback/list',
    method: 'POST',
    params: { ptmId: ptmId, sectionId: sectionId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // console.log(Response.data)
      var ourData = Response.data

      callBack(ourData)
    })
    .catch(error => {
      // console.log('error')
    })
}

const getParentFeedbackFormLink = (lstPtmStdId, sectionId, cbFeedbackFormLink) => {
  // console.log("lstPtmStdId get in getParentFeedbackFormLink service", lstPtmStdId);
  var obj = { status: "Success", message: "" };
  var formData = new FormData();
  formData.append("lstPtmStudentId",JSON.stringify(lstPtmStdId))
  formData.append("sectionId",sectionId)
  axios({
    url: httpURLCommon + '/sms/ptm/feedback/link_generate',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  }).then((response) => {
      // console.log(Response.data)
      obj.status = response.data.SvcStatus;
      obj.message = response.data.SvcMsg;
      cbFeedbackFormLink(obj);
    }).catch((e) => {
      obj.status = 'Exception';
      obj.message = 'error';
      cbFeedbackFormLink(obj);
    })
}

const getParentFeedbackFormQR = (ptmStudentId, sectionId, cbFeedbackFormQR) => {
  var obj = { status: "Success", message: "" };
  var formData = new FormData();
  formData.append("ptmStudentId",ptmStudentId)
  formData.append("sectionId",sectionId)
  axios({
    url: httpURLCommon + '/sms/ptm/feedback/qrcode_generate',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  }).then((response) => {
      // console.log(Response.data)
      obj.status = response.data.SvcStatus;
      obj.message = response.data.SvcMsg;
      obj.link = response.data.link;
      cbFeedbackFormQR(obj);
    }).catch((e) => {
      obj.status = 'Exception';
      obj.message = 'error';
      cbFeedbackFormQR(obj);
    })
}

export { getStudentPtmSummaryListSvc, getParentFeedbackFormLink, getParentFeedbackFormQR }
