// react or other global imports
import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';

// project related common imports

import lstModules from 'common/json/lstModules.json';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';

// module specific imports

import '../css/wings.css';
import { getClassList, getWingsList, saveWing } from './wingsServices'
import WingsMain from './wingsMain';
import WingsForm from './wingsForm';

const Wings = (props) => {
    const [wingsData, setWingsData] = useState({ wingId: 0, wing: "", fromClassId: 0, toClassId: 0, fromClass: "Class", toClass: "Class", day: "MTWTFS", mon: true, tue: true, wed: true, thu: true, fri: true, sat: true, sun: false });
    const [lstWing, setLstWing] = useState([]);
    const [lstClass, setLstClass] = useState([]);
    const [tableLoader, setTableLoader] = useState('none');
    const [formLoader, setFormLoader] = useState('none');
    const [allowAction, setAllowAction] = useState('auto');
    const [randNo, setRandNo] = useState(0);
    useEffect(() => {
        setWings();
        setClasses();
    }, []);

    function handleWingsResponse(data) {
        setTableLoader('none');
        setAllowAction('auto');
        var obj = data;
        if (obj.SvcStatus == 'Success') {
            setLstWing(obj.lstWings);
        } else if (obj.status == 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
                setTableLoader('none');
            }
        } else {
            toast.error("Service failed " + obj);
        }
    }

    function setWings() {
        setTableLoader('block');
        setAllowAction('none');
        getWingsList(handleWingsResponse);
    }

    function handleClassResponse(data) {
        var obj = data;
        if (obj.SvcStatus === 'Success') {
            setLstClass(obj.lstClass);
        } else if (obj.SvcStatus === 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }

    function setClasses() {
        getClassList(handleClassResponse);
    }

    const getWingId = (wingId) => {
        // console.log("wingId" + wingId);
    }

    const rowClickInMain = (e) => {
        var data = e.currentTarget.getAttribute("data");
        setWingsData(JSON.parse(data));
        generateRandomNo()
    }
    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console.log(lRandNo)
        setRandNo(lRandNo)
    }
    function onAddClick() {
        var newWingsData = {
            wingId: 0,
            wing: "",
            fromClassId: 0,
            toClassId: 0,
            mon: true,
            tue: true,
            wed: true,
            thu: true,
            fri: true,
            sat: true,
            sun: false
        }
        setWingsData(newWingsData);
        generateRandomNo();
    }

    const processSaveWings = (pWingsData, pAllDays) => {
        setFormLoader('block');
        setAllowAction('none');
        var allDays = pAllDays;

        var mon = allDays.mon;
        var tue = allDays.tue;
        var wed = allDays.wed;
        var thu = allDays.thu;
        var fri = allDays.fri;
        var sat = allDays.sat;
        var sun = allDays.sun;
        var fromClassId = pWingsData.fromClassId;
        var toClassId = pWingsData.toClassId;

        if (pWingsData.wing === "") {
            setFormLoader('none');
            toast.warning('Please Enter wings');
            return false;
        }
        if (fromClassId === 0) {
            setFormLoader('none');
            toast.warning('Please Enter Valid From Class');
            return false;
        }
        if (toClassId === 0) {
            setFormLoader('none');
            toast.warning('Please Enter Valid To Class');
            return false;
        }

        var wingObj = {
            'wingId': pWingsData.wingId, 'wing': pWingsData.wing, 'fromClassId': fromClassId, 'toClassId': toClassId,
            'mon': mon, 'tue': tue, 'wed': wed, 'thu': thu, 'fri': fri, 'sat': sat, 'sun': sun
        }
        saveWing(wingObj, handleSaveResponse);
    }

    function handleSaveResponse(data, wingsObj) {
        setFormLoader('none');
        setAllowAction('auto');
        setTableLoader('none');

        if (data.SvcStatus === 'Success') {
            toast.success(data.SvcMsg);
            setWings();
            if (wingsObj.wingId === 0) {
                wingsObj.wingId = data.wingId;
            }
            setWingsData(wingsObj);

        } else if (data.status === 'Failure') {
            if (data.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (data.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(data.SvcMsg);
            }
        } else {
            toast.error("Service failed " + data.error);
        }
    }

    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="mainContainer" style={{ pointerEvents: allowAction }}>
                <div className="col-md-11 col-centered wingsWrap clearfix">
                    <div className="col-md-6  col-xs-6">
                        <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: tableLoader }}>
                            <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                        </div>
                        <WingsMain
                            lstWing={lstWing}
                            rowClick={rowClickInMain}
                            selWingId={wingsData.wingId} />
                    </div>
                    <div className="col-md-1  col-xs-1">
                        <span className="addIcon glyphicon glyphicon-plus-sign" onClick={onAddClick}></span>
                    </div>
                    <div className="col-md-5 col-xs-8" >
                        <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: formLoader }}>
                            <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                        </div>
                        <WingsForm
                            key={randNo}
                            wingsData={wingsData}
                            lstClass={lstClass}
                            saveWings={processSaveWings}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Wings;