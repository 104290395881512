import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import Header from 'common/js/header/header';

import BonafideTable from './bonafideTable';
import { lstBonafideSvc } from './bonafideSvc';
import BonafideSlider from './bonafideSlider';
import BonafidePdfSlider from './bonafidePdfSlider';



const BonafideCtrl = () => {
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [lstCertificate, setLstCertificate] = useState([]);
    const [studentId, setStudentId] = useState(0);
    const [certificateId, setCertificateId] = useState(0);
    const [flgEditSliderVisible, setFlgEditSliderVisible] = useState(false);
    const [flgPdfSliderVisible, setPdfSliderVisible] = useState(false);
    const [tableKey, setTableKey] = useState(0);

    useEffect(() => {
        // console.log("BonafideCtrl called ");
        getLstCertificate();
    }, [tableKey]);

    const openCertificateEditSlider = () => {
        // console.log("openCertificateEditSlider called");
        setFlgEditSliderVisible(true);
    }

    const closeCertificateEditSlider = () => {
        // console.log("closeCertificateEditSlider called");
        updateKey();
        setFlgEditSliderVisible(false);
    }

    const openCertificatePdfSlider = () => {
        // console.log("openCertificatePdfSlider called");
        setPdfSliderVisible(true);
    }

    const closeCertificatePdfSlider = () => {
        // console.log("closeCertificatePdfSlider called");
        setPdfSliderVisible(false);
    }

    const getLstCertificate = () => {
        // console.log("getLstCertificate called");
        setLoader("block");
        setOpacity(0.5);
        lstBonafideSvc(lstCertificateResponse);
    }

    const lstCertificateResponse = (data) => {
        // console.log("lstCertificateResponse called, data", data);
        if (data.status === "Success") {
            setLstCertificate(data.lstBonafide);
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setLoader("none");
        setOpacity(1);
    }

    

    const addCertificate = async () => {
        // console.log("addCertificate called");
        await setCertificateId(0);
        openCertificateEditSlider();
    }

    const editCertificate = async (pCertId, pStudentId) => {
        // console.log("editCertificate called, pCertId", pCertId);
        await setCertificateId(pCertId);
        await setStudentId(pStudentId)
        openCertificateEditSlider();
    }

    const printCertificate = async (pCertId) => {
        // console.log("printCertificate called, pCertId", pCertId);
        await setCertificateId(pCertId);
        openCertificatePdfSlider();
    }

    const deleteCertificate = async (pCertId, e) => {
        // console.log("deleteCertificate called, pCertId", pCertId);
        e.preventDefault();
    }

    const deleteCertificateResponse = async (data) => {
        // console.log("deleteCertificateResponse called, data", data);
    }

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    function getCertificateId(pStudentId) {
        // console.log("getCertificateId called, pStudentId", pStudentId);
        // console.log("lstCertificate", lstCertificate);
        const studentCertificate = lstCertificate.find(item => item.studentId === pStudentId);
        // console.log("studentCertificateFound", studentCertificate);
        let certificateId =  0
        if(!!studentCertificate){
            // console.log("in condition !!studentCertificateId");
            certificateId = studentCertificate.certificateId;
        }
        // console.log("returning, ", certificateId);
        return certificateId;
      }

    const updateKey = () => {
        setTableKey(prevKey => prevKey + 1);
    }

    return (
        <div >
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="col-md-12 col-xs-12 col-centered padding-remove "
                style={{
                    cursor: (loader === "none") ? 'pointer' : 'none',
                    pointerEvents: (loader === "none") ? 'auto' : 'none',
                    opacity: opacity
                }}>
                <div className="col-md-12 col-xs-12 col-centered padding-remove" style={{ marginTop: "25px", display:'flex', flexDirection:"row" }}>
                    <div style={{width:"5%"}}>
                    </div>
                    <div style={{width:"90%"}}>
                        <BonafideTable
                            certificateId={certificateId}
                            setStudentId={setStudentId}
                            setCertificateId={setCertificateId}
                            editCertificate={editCertificate}
                            lstCertificate={lstCertificate}
                            printCertificate={printCertificate}
                            deleteCertificate={deleteCertificate}
                        />
                    </div>
                    <div style={{width:"5%"}}>
                        <span
                            id="addCertificate"
                            title="Add Certificate"
                            className="addIcon glyphicon glyphicon-plus-sign"
                            onClick={() => addCertificate()}
                        ></span>
                    </div>
                </div>
            </div>
            {flgEditSliderVisible && (
                <div className="slider1-90">
                    <BonafideSlider
                        certificateId={certificateId}
                        studentId={studentId}
                        setStudentId={setStudentId}
                        getCertificateId={getCertificateId}
                        setCertificateId={setCertificateId}
                        printCertificate={printCertificate}
                        closeCertificateEditSlider={closeCertificateEditSlider}
                    />
                </div>
            )}
            {flgPdfSliderVisible && (
                <div className="slider1-80">
                    <BonafidePdfSlider
                        certificateId={certificateId}
                        closePdfSlider={closeCertificatePdfSlider}
                    />
                </div>
            )}
        </div >
    )
}

export default BonafideCtrl;