import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import 'common/css/header.css';
import 'common/bootstrap/css/bootstrap.min.css';
import { toast } from 'react-toastify';

import { changeAcademicYearSvc, changeRoleSvc, changeSchoolSvc, changeWingSvc, getAcademicYearListSvc, getRoleListSvc, getSchoolListSvc, getWingsListSvc } from "./headerSvc";

const SettingPopup = (props) => {
    const { setOpacity, setLoader, lstRoles, closePopups } = props;

    const [lstWings, setLstWings] = useState([]);
    const [wingId, setWingId] = useState(parseInt(localStorage.getItem('wingId')) || 0);
    const [lstAcademicYear, setLstAcademicYear] = useState([]);
    const [academicYearId, setAcademicYearId] = useState(localStorage.getItem('academicYearId') || 0);
    const [lstRole, setLstRole] = useState(lstRoles);
    const [lstSecondaryRole, setLstSecondaryRole] = useState([]);
    const [roleId, setRoleId] = useState(localStorage.getItem('roleId') || 0);
    const [role, setRole] = useState("");
    const [lstSchool, setLstSchool] = useState([]);
    const [schoolId, setSchoolId] = useState(parseInt(localStorage.getItem('schoolId')) || 0);

    useEffect(() => {
        // console.log(" settingPopup called, localStorage", localStorage);
        getSchoolList();
        // getRoleList();
        getWingList();
        getAcademicYearList();
        // console.log("lstRole get in parameters", lstRoles);
    }, []);

    const getSchoolList = () => {
        setLoader("block");
        setOpacity(0.5);
        getSchoolListSvc(schoolListResponse);
    }

    const schoolListResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            setLstSchool(obj.lstSchool);
            // console.log("obj.lstSchool", obj.lstSchool);
        }
        else {
            if (obj.message === 'You are not logged in') {
                toast(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                toast(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const getRoleList = () => {
        // console.log("getRoleList called");
        setLoader("block");
        setOpacity(0.5);
        getRoleListSvc(schoolId, roleListResponse);
    }

    const roleListResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            setRoleId(obj.roleId);
            setRole(obj.role);
            setAcademicYearId(obj.acdYrId);
            setWingId(obj.wingId);
            setLstSecondaryRole(obj.lstSecondaryRole);
            var roleList = obj.lstSecondaryRole;
            var primaryRoleData = {
                roleId: obj.roleId,
                role: obj.role,
            };
            roleList.unshift(primaryRoleData);
            setLstRole(roleList);
            localStorage.setItem('roleId', obj.roleId);
            localStorage.setItem('academicYearId', obj.acdYrId);
            localStorage.setItem('wingId', obj.wingId);
        }
        else {
            if (obj.message === 'You are not logged in') {
                toast(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                toast(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const getWingList = () => {
        setLoader("block");
        setOpacity(0.5);
        getWingsListSvc(wingListResponse);
    }

    const wingListResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            // console.log('obj', obj);
            setLstWings(obj.lstWings);
        }
        else {
            if (obj.message === 'You are not logged in') {
                toast(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                toast(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const getAcademicYearList = () => {
        setLoader("block");
        setOpacity(0.5);
        getAcademicYearListSvc(academicYearListResponse);
    }

    const academicYearListResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            // console.log('obj', obj);
            setLstAcademicYear(obj.lstAcademicYear);
        }
        else {
            if (obj.message === 'You are not logged in') {
                toast(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                toast(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }


    const changeSchool = (sId) => {
        // console.log("changeSchool Called with schoolId", sId);
        if (sId == 0) {
            // console.log("prevent conditon satisfied changeSchool Called with schoolId", sId);
            toast.warn("please select default school");
            return;
        }
        else {
            setLoader("block");
            setOpacity(0.5);
            changeSchoolSvc(sId, changeSchoolResponse);
        }
    }

    const changeSchoolResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            // console.log("obj", obj);
            setSchoolId(obj.sId);
            toast.success(obj.message);
            setRoleId(0);
            setWingId(0);
            setAcademicYearId(0);
            localStorage.setItem('roleId', 0);
            localStorage.setItem('wingId', 0);
            localStorage.setItem('academicYearId', 0);
            localStorage.setItem('schoolId', schoolId);
            getRoleList();
            getWingList();
            getAcademicYearList();
            closePopups();
        }
        else {
            if (obj.message === 'You are not logged in') {
                toast(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                toast.error(obj.message);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                toast(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const changeRole = (rId) => {
        // console.log("changeRole called, rId", rId);
        if (rId == 0) {
            // console.log("prevent conditon satisfied changeRole called, rId", rId);
            toast.warn("please select default role")
            return;
        }
        else {
            setLoader("block");
            setOpacity(0.5);
            changeRoleSvc(rId, changeRoleResponse);
        }
    }

    const changeRoleResponse = async(data) => {
        var obj = data;
        if (obj.status === 'Success') {
            // console.log("obj", obj);
            await setRoleId(obj.rId);
            await localStorage.setItem('roleId', roleId);
            toast.success(obj.message);
            closePopups();
        }
        else {
            if (obj.message === 'You are not logged in') {
                toast(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                toast(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const changeWing = (wId) => {
        // console.log("changeWingId called with wingId", wId);
        if (wId == 0) {
            // console.log("prevent conditon satisfied changeWingId called with wingId", wId);
            toast.warn("Please select default wing");
            return;
        }
        else {
            setLoader("block");
            setOpacity(0.5);
            changeWingSvc(wId, changeWingResponse);
        }
    }

    const changeWingResponse = async(data) => {
        var obj = data;
        if (obj.status === 'Success') {
            // console.log("obj in changeWingResponse", obj);
            await setWingId(obj.wId);
            await localStorage.setItem('wingId', wingId);
            toast.success(obj.message);
            closePopups();
        }
        else {
            if (obj.message === 'You are not logged in') {
                toast(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                toast(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const changeAcademicYear = (acdYrId) => {
        // console.log("changeAcademicYear called with academicYearId", acdYrId);
        // console.log(" lstAcademicYear", lstAcademicYear);
        if (acdYrId == 0) {
            // console.log("prevent conditon satisfied changeAcademicYear called with academicYearId", acdYrId);
            toast.warn("please select default academic year");
            return;
        }
        else {
            setLoader("block");
            setOpacity(0.5);
            changeAcademicYearSvc(acdYrId, changeAcademicYearResponse);
        }
    }

    const changeAcademicYearResponse = (data) => {
        // console.log("changeAcademicYearResponse called, lstAcademicYear", lstAcademicYear);
        var obj = data;
        // console.log("changeAcademicYearResponse called, obj", obj);
        if (obj.status === 'Success') {
            setAcademicYearId(obj.acdYrId);
            // console.log("obj", obj);
            localStorage.setItem('academicYearId', obj.acdYrId);
            localStorage.setItem('academicYear', getAcademicYearName(obj.acdYrId));
            toast.success(obj.message);
            closePopups();
        }
        else {
            if (obj.message === 'You are not logged in') {
                toast(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                toast(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const getAcademicYearName = (acdYrId) => {
        // console.log("getAcademicYearName called, lstAcademicYear", lstAcademicYear, "academicyearid", acdYrId);
        for (var i = 0; i < lstAcademicYear.length; i++) {
            if (lstAcademicYear[i].academicYearId == acdYrId) {
                // console.log("academic year id matched, academic year name to be returned", lstAcademicYear[i].academicYear);
                return lstAcademicYear[i].academicYear;
            }
        }
        return "Academic Year";
    }

    return (
        <>
            <div>
                <select
                    className=" col-sm-12 form-control wingSelect"
                    style={{ marginBottom: 10 }}
                    value={schoolId}
                    onChange={(e) => changeSchool(e.target.value)}>
                    <option value={0}>School</option>
                    {lstSchool &&
                        lstSchool.map((school) => (
                            <option
                                key={school.schoolId}
                                value={school.schoolId}>
                                {school.school}
                            </option>
                        ))
                    }
                </select>
            </div>
            <div>
                <select
                    className=" col-sm-12 form-control wingSelect"
                    style={{ marginBottom: 10 }}
                    value={roleId}
                    onChange={(e) => changeRole(e.target.value)}>
                    <option value={0}>Role</option>
                    {lstRole &&
                        lstRole.map((role) => (
                            <option
                                key={role.roleId}
                                value={role.roleId}>
                                {role.role}
                            </option>
                        ))
                    }
                </select>
            </div>
            <div>
                <select
                    className=" col-sm-12 form-control wingSelect"
                    style={{ marginBottom: 10 }}
                    value={wingId}
                    onChange={(e) => changeWing(e.target.value)}>
                    <option value={0}>Wing</option>
                    {lstWings &&
                        lstWings.map((wing) => (
                            <option
                                key={wing.wingId}
                                value={wing.wingId}>
                                {wing.wing}
                            </option>
                        ))
                    }
                </select>
            </div>
            <div>
                <select
                    className=" col-sm-12 form-control wingSelect"
                    style={{ marginBottom: 10 }}
                    value={academicYearId}
                    onChange={(e) => changeAcademicYear(e.target.value)}>
                    <option value={0}>academic Year</option>
                    {lstAcademicYear &&
                        lstAcademicYear.map((academicYear) => (
                            <option
                                key={academicYear.academicYearId}
                                value={academicYear.academicYearId}>
                                {academicYear.academicYear}
                            </option>
                        ))
                    }
                </select>
            </div>
        </>
    );
}

export default SettingPopup;