import React, { useEffect, useState } from 'react';

const ClassList = (props) => {
    const [lstCls, setLstCls] = useState(props.lstCls)
    const [state, setState] = useState()

    useEffect(() => {
        setLstCls(props.lstCls)
    }, [props.classId, props.lstCls]);


    function handleChange(e) {
        setState({ ...state, [e.target.name]: e.target.value })
        props.classClick(e.target.value);
    }

    function getClassName(clasId) {
        // console.log("getClassName called in ClassList, clasId",clasId);
        for (let i = 0; i < props.lstCls.length; i++) {
            // console.log("props.lstCls[i].classId",props.lstCls[i].classId);
            // console.log("clasId",clasId);
            if (props.lstCls[i].classId == clasId) {
                return props.lstCls[i].classLabel;
            }
        }
        return "class";
    }

    return (
        <div className="container1">
            <div className="col-sm-12 padding-remove">

                <select autoComplete="off"
                    name="classId" id="classesCmb"
                    type="text"
                    className="form-control "
                    style={{ height: 37 }}
                    value={props.classId}
                    onChange={handleChange}
                >
                    <option>Class</option>
                    {/* <option value={props.classId}>{getClassName(props.classId)}</option> */}
                    {lstCls.map((obj) => (
                        <option
                            value={obj.classId}
                            key={obj.classId}
                            id={obj.classId}
                        >
                            {obj.classLabel}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    )
}
export default ClassList;