import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['enquiry'].url + ':' + lstModules['enquiry'].port

function getRegsFeeDtlsSvc (callBack, pAdmissionId) {
  axios({
    url: httpURL + '/sms/admission/fee/dtls',
    method: 'POST',
    params: { admissionId: pAdmissionId, flgRegs: true },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // console.log('error')
    })
}
function saveOneTimeHeadSvc (padmissionId, pOneTimeHeadId, cbSaveRegsFee) {
  axios({
    url: httpURL + '/sms/enquiry/onetimefee/save',
    method: 'POST',
    params: { admissionId: padmissionId, oneTimeHeadId: pOneTimeHeadId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      var data = response.data
      cbSaveRegsFee(data)
    })
    .catch(error => {
      // console.log('Error Occured')
    })
}
function getOneTimeHeadSvc (cbGetOneTimeHeadResponse) {
  axios({
    url: httpURL + '/sms/enquiry/onetimefee/dtls',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      var data = response.data
      cbGetOneTimeHeadResponse(data)
    })
    .catch(error => {
      // console.log('Error Occured')
    })
}
export { getOneTimeHeadSvc }
export { getRegsFeeDtlsSvc }
export { saveOneTimeHeadSvc }
