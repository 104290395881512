import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { getLstSectionSvc, promoteSvc } from './PromoteCtrlSvc';

const PromotionTable = (props) => {

    const [loader, setLoader] = useState('none')
    const [sectionObj, setSectionObj] = useState({})
    const [lstClass, setLstClass] = useState([])
    const [fromLstClass, setFromLstClass] = useState([])
    const [toLstClass, setToLstClass] = useState([])
    const [fromAcdYrId, setFromAcdYrId] = useState(0)
    const [fromAcdYr, setFromAcdYr] = useState('')
    const [toAcdYrId, setToAcdYrId] = useState(0)
    const [promoteApproach, setPromoteApproach] = useState(0)
    const [toAcdYr, setToAcdYr] = useState('')
    const [fromMaxSection, setFromMaxSection] = useState(0)
    const [toMaxSection, setToMaxSection] = useState(0)
    const [selIdx, setSelIdx] = useState(0)
    const [idx, setIdx] = useState(-1)

    useEffect(() => {
        async function fetchData() {
            await setFromAcdYrId(props.fromAcdYrId);
            await setFromAcdYr(props.fromAcdYr);
            await setToAcdYrId(props.toAcdYrId);
            await setToAcdYr(props.toAcdYr);

        }
        fetchData();
        getLstSection()
    }, [props.fromAcdYrId, props.toAcdYrId])

    function rowClick(e, obj, pIdx) {
        e.preventDefault();
        setIdx(pIdx)
        props.onSetSlider(obj, pIdx, false)
    }

    function getLstSection() {
        // console.log(typeof props.fromAcdYrId)
        // console.log(typeof props.toAcdYrId)
        // console.log(props.fromAcdYrId != 0)
        // console.log(props.toAcdYrId != 0)
        if (props.fromAcdYrId != 0 && props.toAcdYrId != 0) {
            getLstSectionSvc(props.fromAcdYrId, props.toAcdYrId, cbLstSectionResponse);
        }
    }
    function cbLstSectionResponse(data) {
        setLoader('none')
        if (data.status === "Success") {
            var lSectionObj = data.sectionObj;
            setSectionObj(lSectionObj);
            setLstClass(lSectionObj.lstClass);
            setFromLstClass(lSectionObj.lstFromSection);
            setToLstClass(lSectionObj.lstToSection);
            setFromMaxSection(lSectionObj.maxFromSections)
            setToMaxSection(lSectionObj.maxToSections)
        } else {
            if (data.message === "You are not logged in") {
                window.location = "/";
            }
        }
    }

    function promote(e, obj) {
        e.stopPropagation()
        if (promoteApproach === 0) {
            toast.warn('Please select Promote Approach');
            return;
        }
        if (promoteApproach === 30) {
            props.onSetSlider(obj, idx, true)
            return;
        }
        var promote = {}
        promote.fromAcdYrId = props.fromAcdYrId;
        promote.toAcdYrId = props.toAcdYrId;
        promote.fromClsId = obj.pFromClsId;
        promote.toClsId = obj.pToClsId;
        promote.promoteId = promoteApproach;

        promoteSvc(promote, cbResponse)
    }

    function cbResponse(obj) {
        if (obj.status === "Success") {
            toast.success(obj.message)
            props.generateRandomNo();
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed " + obj);
        }
    }
    function cboxClick(pIdx, pVal) {
        setSelIdx(pIdx);
        if (pVal !== 10) document.querySelector('#c_10_' + pIdx).checked = false;
        if (pVal !== 20) document.querySelector('#c_20_' + pIdx).checked = false;
        if (pVal !== 30) document.querySelector('#c_30_' + pIdx).checked = false;
        setPromoteApproach(pVal)
    }
    const colgrp = <colgroup>
        <col width="5%" />
        {/* <col width="7%" />
        <col width="7%" />
        <col width="7%" />
        <col width="7%" /> */}
        {Array.from(Array(fromMaxSection), (e, i) => {
            return <col key={'col_' + i} width={30 / fromMaxSection + '%'} />
        })}
        {/* {fromLstClass && fromLstClass.map((obj, i) => (<col key={'col_' + i} width={30 / fromMaxSection + '%'} />))} */}
        <col width="5%" />
        {/* {toLstClass && toLstClass.map((obj, j) => (<col key={'col_' + j} width={30 / toMaxSection + '%'} />))} */}
        {Array.from(Array(toMaxSection), (e, i) => {
            return <col key={'col_' + i} width={30 / toMaxSection + '%'} />
        })}
        {/* <col width="7%" />
        <col width="7%" />
        <col width="7%" />
        <col width="7%" /> */}

        <col width="40%" />
        <col width="10%" />
    </colgroup>
    const colgrpHead = <colgroup>
        <col width="5%" />
        <col width={30 + '%'} />
        <col width="5%" />
        <col width={30 + '%'} />
        <col width="40%" />
        <col width="10%" />
    </colgroup>

    return (
        <div className='container'>
            {<div style={{ marginTop: '15px' }} >
                <div className="col-md-11">
                    <table className="tableLayout1 ">
                        {colgrpHead}
                        {<thead>
                            <tr>
                                <th><div></div></th>
                                <th>{props.fromAcdYr}</th>
                                <th><div></div></th>
                                <th>{props.toAcdYr}</th>
                                <th>Promote Approach</th>
                                <th>Action</th>
                            </tr>
                        </thead>}
                    </table>
                    <div className='tablebodyWrapper'>
                        <table>
                            {colgrp}
                            <tbody id="allocationTableBody">
                                {lstClass.map((obj, idx) => (
                                    <tr
                                        className={idx === props.selIdx ? 'selected' : ''}
                                        key={idx}
                                        onClick={(e) => rowClick(e, obj, idx)}>
                                        <td>
                                            <span>{obj.fromCls}</span><br />
                                            <span>{obj.fromClsPending}/{obj.fromClsTotal}</span>
                                        </td>

                                        <>{
                                            Array.from(Array(fromMaxSection), (e, i) => {

                                                return i < obj.lstFromSection.length ?
                                                    <td key={"fs_" + obj.lstFromSection[i].sectionId}>
                                                        <span>{obj.lstFromSection[i].section}</span><br />
                                                        <span>{obj.lstFromSection[i].pending + '/' + obj.lstFromSection[i].total}</span>
                                                    </td> : <td key={"fsec_" + i}></td>

                                            })}</>
                                        <td>
                                            <span>{obj.toCls}</span><br />
                                            <span>{obj.toClsAvailable}/{obj.toClsTotal}</span>
                                        </td>
                                        <>{
                                            Array.from(Array(toMaxSection), (e, j) => {

                                                return j < obj.lstToSection.length ? <td key={"ts_" + obj.lstToSection[j].sectionId}>
                                                    <span>{obj.lstToSection[j].section}</span><br />
                                                    <span>{obj.lstToSection[j].available + '/' + obj.lstToSection[j].maxAllowed}</span>
                                                </td> : <td key={"tsec_" + j}></td>

                                            })}</>
                                        <td>
                                            <div style={{ display: "inline-block", marginLeft: '5%' }}>
                                                <input type="checkbox" id={'c_10_' + idx} onClick={(e) => { e.stopPropagation(); cboxClick(idx, 10) }} />
                                                <span style={{ position: 'relative', top: -5, left: 5 }}>Match</span>
                                            </div>
                                            <div style={{ display: "inline-block", marginLeft: '5%' }}>
                                                <input type="checkbox" id={'c_20_' + idx} onClick={(e) => { e.stopPropagation(); cboxClick(idx, 20) }} />
                                                <span style={{ position: 'relative', top: -5, left: 5 }}>Random</span>
                                            </div>
                                            <div style={{ display: "inline-block", marginLeft: '5%' }}>
                                                <input type="checkbox" id={'c_30_' + idx} onClick={(e) => { e.stopPropagation(); cboxClick(idx, 30) }}></input>
                                                <span style={{ position: 'relative', top: -5, left: 5 }}>Manual</span>
                                            </div>
                                        </td>
                                        <td><button className="btn btn-info" onClick={(e) => { promote(e, obj, obj.fromClsId, obj.toClsId) }}>Promote</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div >
            }</div >
    );
}

export default PromotionTable