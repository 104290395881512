import lstModules from 'common/json/lstModules.json';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';


const screenId = 31;
let queryString = require('querystring');
const tokenid = localStorage.getItem('tokenid');
const httpURL = lstModules['academicYear'].url + ":" + lstModules['academicYear'].port;

export const getAcademicYearListSvc = (cbAcademicYearList) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/acdyr/list',
        method: "POST",
        params: { "screenId": screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.lstAcademicYear = response.data.lstAcademicYear;
        cbAcademicYearList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbAcademicYearList(obj);
    })
}


export const deleteAcademicYearSvc = (academicYearId, cbDeleteAcademicYear) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/acdyr/delete',
        method: "POST",
        params: { "academicYearId": academicYearId, "screenId": screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbDeleteAcademicYear(obj);
        // console.log("response", response);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbDeleteAcademicYear(obj);
    })
}


export const getAcademicYearDtlsSvc = (academicYearId, cbAcademicYearDtls) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/acdyr/dtls',
        method: "POST",
        params: { "academicYearId": academicYearId, "screenId": screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.lstAcademicYearWing = response.data.academicYearDtls.lstWingsData;
        obj.academicYearData = response.data.academicYearDtls.academicYear;
        cbAcademicYearDtls(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbAcademicYearDtls(obj);
    })
}


export const saveAcademicYearSvc = (academicYearObj, cbSaveAcademicYear) => {
    const obj = { status: "", message: "" };
    const formData = queryString.stringify({ 'academicYear': JSON.stringify(academicYearObj), screenId });
    axios({
        url: httpURL + '/sms/acdyr/save',
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbSaveAcademicYear(obj);
        // console.log("response", response);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSaveAcademicYear(obj);
    })
}

export const saveAcademicYearWingListSvc = (acdyrWing, cbSaveAcademicYearWingList) => {
    const obj = { status: "", message: "" };
    const formData = queryString.stringify({ 'acdyrWing': JSON.stringify(acdyrWing), screenId });
    axios({
        url: httpURL + '/sms/acdyr/saveWing',
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbSaveAcademicYearWingList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSaveAcademicYearWingList(obj);
    })
}