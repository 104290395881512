import React, { Component } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import $ from "jquery";
import axios from 'axios';


var httpURL = '';
var queryString = require('querystring');
var screenId = 33;


export default class DetailsSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sub1: 0,
            tch1: 0,
            asset1: 0,
            invigilator1: 0,
            evaluator1: 0,
            verifier: 0,
            link1: "",
            notes: ""
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        // console.log("Link", this.state.link1);
        // console.log("Teacher", this.state.tch1);
        // console.log("Subject", this.state.sub1);
        // console.log("Invigilator", this.state.invigilator1);
        // console.log("Evaluator", this.state.evaluator1);
        // console.log("Notes", this.state.notes);
        // console.log("asset",this.state.asset1);
        // console.log("verifier",this.state.verifier)
        // console.log("detailsSlider", $("#closeSlider").attr("cell"))
        var sub = this.state.sub1
        var tch = this.state.tch1
        var asset = this.state.asset1
        var invigilator = this.state.invigilator1
        var evaluator = this.state.evaluator1
        var verifier = this.state.verifier
        var link = this.state.link1
        var notes = this.state.notes
        var objToSave = {
            sub,
            tch,
            asset,
            invigilator,
            evaluator,
            verifier,
            link,
            notes
        }
        this.props.getFormData(objToSave);




    }

    handleInputChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })

    }


    render() {
        return (
            <div>
                <div className="container">
                    <form onSubmit={this.handleSubmit}>
                        <div className=" row ">
                            <div className="col-md-3 form-group">
                                <label htmlFor="subject">Subject</label>
                                <select id="subject" className="form-control"
                                    onChange={this.handleInputChange}
                                    name="sub1"
                                    value={this.state.sub1}
                                >
                                    <option value="0">Subject</option>
                                    {this.props.lstSubject.map((subject, i) =>
                                        <option value={subject.subjectId}>{subject.subject}</option>
                                    )}
                                </select>
                            </div>
                            <div className="col-md-3 form-group">
                                <label htmlFor="teacher">Teacher</label>
                                <select id="teacher" className="form-control" name="tch1"
                                    value={this.state.tch1}
                                    onChange={this.handleChange}>
                                    <option value="0">Teacher</option>
                                    {this.props.lstTeacher.map((teacher, i) =>
                                        <option value={teacher.teacherId}>{teacher.teacher}</option>
                                    )}
                                </select>
                            </div>
                            <div className="col-md-3 form-group">
                                <label htmlFor="asset1">Reservation</label>
                                <select id="asset1" className="form-control">
                                    <option value="0">Reservation</option>
                                    <option>...</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 form-group">
                                <label htmlFor="invigilator1">Invigilator</label>
                                <select id="invigilator1" className="form-control"
                                    onChange={this.handleInputChange}
                                    name="invigilator1"
                                    value={this.state.invigilator1}
                                >
                                    <option value="0">Invigilator</option>
                                    {this.props.lstTeacher.map((teacher, i) =>
                                        <option value={teacher.teacherId}>{teacher.teacher}</option>
                                    )}
                                </select>
                            </div>
                            <div className="col-md-3 form-group">
                                <label htmlFor="evaluator1">Evaluator</label>
                                <select id="evaluator1" className="form-control"
                                    onChange={this.handleInputChange}
                                    name="evaluator1"
                                    value={this.state.evaluator1}
                                >
                                    <option value="0">Evaluator</option>
                                    {this.props.lstTeacher.map((teacher, i) =>
                                        <option value={teacher.teacherId}>{teacher.teacher}</option>
                                    )}
                                </select>
                            </div>
                            <div className="col-md-3 form-group">
                                <label htmlFor="verifier">Verifier</label>
                                <select id="verifier" className="form-control"
                                    onChange={this.handleInputChange}
                                    name="verifier"
                                    value={this.state.verifier}
                                >
                                    <option value="0">Verifier</option>
                                    {this.props.lstTeacher.map((teacher, i) =>
                                        <option value={teacher.teacherId}>{teacher.teacher}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-8">
                                <label htmlFor="link">Link</label>
                                <input type="text" className="form-control"
                                    onChange={this.handleInputChange}
                                    name="link1"
                                    value={this.state.link1}
                                    id="link" placeholder="link" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-8">
                                <label htmlFor="notes">Notes</label>
                                <textarea className="form-control" id="notes"
                                    onChange={this.handleInputChange}
                                    name="notes"
                                    value={this.state.notes}
                                    rows="3" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-2">
                                <button type="submit" className="btn btn-secondary">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
                <hr></hr>
                <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={this.props.removeClassFromBody}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
            </div>






        )
    }
}