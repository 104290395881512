import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { testScheduleBatchListSvc } from './WTestScheduleSvc';

import { saveTestScheduleBatchSvc } from './WTestScheduleSvc';
import BatchStudents from './batchStudents';

const BatchTimeSlider = (props) => {

    const { testScheduleId, closeSlider, updateKey } = props;

    const [loaderSlider, setLoaderSlider] = useState("none");
    const [lstBatchDtls, setLstBatchDtls] = useState([]);

    const [countMaxStudents, setCountMaxStudents] = useState(0);

    useEffect(() => {
        // console.log("useEffect called in BatchTimeSlider");
        // console.log("testScheduleId", testScheduleId);
        getBatchList();
    }, []);

    const getBatchList = () => {
        // console.log("getBatchList called");
        setLoaderSlider("block");
        testScheduleBatchListSvc(testScheduleId, scheduleBatchListResponse);
    }

    const scheduleBatchListResponse = (data) => {
        // console.log("scheduleBatchListResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            // console.log("obj.lstBatchDtls", obj.lstBatchDtls);
            setLstBatchDtls(obj.lstBatchDtls);
            getMaxStudentCount(obj.lstBatchDtls);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoaderSlider('none');
    }

    const getMaxStudentCount = (pLstBatchDtls) => {
        // console.log("getMaxStudentCount, pLstBatchDtls", pLstBatchDtls);
        const lengths = pLstBatchDtls.map(obj => obj.lstStudent.length);
        const maxLength = lengths.reduce((max, current) => Math.max(max, current), 0);
        // console.log(maxLength);
        setCountMaxStudents(maxLength);
    }

    const updateStartTm = (testBatchId, newTime) => {
        // console.log("updateStartTm called, testBatchId", testBatchId, ", newTime", newTime);
        setLstBatchDtls(prevState =>
            prevState.map(batch =>
                batch.testBatchId === testBatchId ? { ...batch, startTm: newTime } : batch
            )
        );
    };

    const updateEndTm = (testBatchId, newTime) => {
        setLstBatchDtls(prevState =>
            prevState.map(batch =>
                batch.testBatchId === testBatchId ? { ...batch, endTm: newTime } : batch
            )
        );
    };

    const saveTestBatches = () => {
        // console.log("saveTestBatches method called");

        // Validate startTm and endTm format
        const isValidTimeFormat = (time) => {
            const timeRegex = /^(0?[1-9]|1[012]):([0-5]\d)\s([APap][mM])$/;
            return timeRegex.test(time);
        };

        for (const batch of lstBatchDtls) {
            if (!isValidTimeFormat(batch.startTm) || !isValidTimeFormat(batch.endTm)) {
                toast.error("Please select all fields in Start Time & End Time");
                return;
            }
        }

        // If all times are valid, proceed with saving
        const lstBatch = lstBatchDtls.map(({ testBatchId, batchNo, startTm, endTm }) => ({
            testBatchId,
            batchNo,
            startTm,
            endTm
        }));

        setLoaderSlider("block");
        const testBatchObj = { testScheduleId, lstBatch };
        saveTestScheduleBatchSvc(testBatchObj, saveTestBatchesResponse);
    };


    const saveTestBatchesResponse = (data) => {
        // console.log("saveTestBatchesResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            updateKey();
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoaderSlider('none');
    }


    return (
        <div className="col-xs-12 col-centered padding-remove ">
            <div style={{ position: "fixed", zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '40%', display: loaderSlider }} >
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div >
            <ToastContainer autoClose={3000} hideProgressBar />
            <div>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={() => closeSlider()} style={{ right: "90%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <div className='col-sm-12 padding-remove' style={{ margin: 0 }}>
                </div>
            </div>
            <form className='form form-horizontal' style={{ pointerEvents: loaderSlider === "none" ? "auto" : "none", height: 'auto', width: '96%', margin: '30px auto', padding: "30px 20px", background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                <div className="col-xs-12 col-centered padding-remove " style={{ display: "flex", justifyContent: "flex-start" }}>
                    <div className="col-xs-2 col-centered padding-remove ">
                        <div className='row' style={{ width: '100%' }}>
                            <label className="col-sm-8 " style={{ fontWeight: "bold" }}>Batch No</label>
                        </div>
                        <div className='row' style={{ width: '100%', height: "40px" }}>
                            <label className="col-sm-8 " style={{ fontWeight: "400" }}>Start Time</label>
                        </div>
                        <div className='row' style={{ width: '100%', height: "40px" }}>
                            <label className="col-sm-8 " style={{ fontWeight: "400" }}>End Time</label>
                        </div>
                        <div className='row' style={{ width: '100%', height: "40px", paddingTop: "10px" }}>
                            <label className="col-sm-8 " style={{ fontWeight: "bold" }}>{countMaxStudents ? "Students" : ""}</label>
                        </div>
                        {
                            countMaxStudents && countMaxStudents > 0 ?
                                Array.from({ length: countMaxStudents }, (_, index) => (
                                    <div key={index + 1} className='row' style={{ width: '100%' }}>
                                        <label className="col-sm-8 text-center" style={{ fontWeight: "400" }}>{index + 1}</label>
                                    </div>
                                ))
                                :
                                <div className='row' style={{ width: '100%' }}>
                                    <label className="col-sm-8 " style={{ fontWeight: "400" }}>{""}</label>
                                </div>
                        }
                    </div>
                    <div className="col-xs-10 col-centered " style={{ display: "flex", justifyContent: "flex-start" }}>
                        {
                            lstBatchDtls && lstBatchDtls.length ?
                                lstBatchDtls.map((batchDtls, idx) => (
                                    <div key={batchDtls.testBatchId} style={{ width: "20%" }}>
                                        <BatchStudents
                                            batchDtls={batchDtls}
                                            isLastColumn={idx === lstBatchDtls.length - 1}
                                            updateStartTm={updateStartTm}
                                            updateEndTm={updateEndTm}
                                            saveTestBatches={saveTestBatches}
                                        />
                                    </div>
                                ))
                                : null
                        }
                    </div>
                </div>
            </form >
        </div>
    );
}

export default BatchTimeSlider;