import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import 'jquery-ui';
import '../css/admissionTestSlot.css';
import 'common/css/master.css';
import axios from 'axios';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

let httpURL = '';
let queryString = require('querystring');
let lstRegsSlot = [];
let lstRegsSlotTime = [];
let lstFromClass = [];
var lstSlotStartTm = [];
var lstSlotEndTm = [];
let lstSlotType = [];
let tokenid = localStorage.getItem("tokenid");
var screenId = 20;

class admissionTestSlot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            tokenid: tokenid,
            flg: true,
            slotFlg: true,
            startDt: null,
            endDt: null,
            lstSlotStartTm: [],
            lstSlotEndTm: [],
            mode: 'Tests'
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);
    }

    handleChange(date) {
        this.setState({ startDt: date, });
    }

    handleChange1(date) {
        this.setState({ endDt: date });
    }

    componentDidMount = () => {
        $('.slotTimingWrap').hide();
        $('.dayCheckbox').prop('checked', true);

        lstSlotType = [{
            'slotId': 10, 'slotType': 'Test Slot'
        }, {
            'slotId': 20, 'slotType': 'Interview Slot'
        }];

        let slotType = 10;

        this.fillSlotTypeCmb(slotType)

        this.setState({ loader: 'block', opacity: 0.5 });

        this.setState({
            slotId: slotType
        });
        this.getAmissionTestSlotList(slotType);

        lstSlotStartTm = ["08 AM", "09 AM", "10 AM", "11 AM", "12 PM", "01 PM", "02 PM", "03 PM", "04 PM", "05 PM",
            "06 PM", "07 PM", "08 PM"
        ];
        lstSlotEndTm = ["08 AM", "09 AM", "10 AM", "11 AM", "12 PM", "01 PM", "02 PM", "03 PM", "04 PM", "05 PM",
            "06 PM", "07 PM", "08 PM"
        ];
        this.fillTimeCmb();


        axios({
            url: httpURL + '/sms/mst/class/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstFromClass = response.data.lstClass;
                this.fillFromClassCmb();
            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });



    }
    fillTimeCmb() {
        $('#startCmb').empty();
        $('#startCmb').prepend($("<option> </option>").val(0).html('Start Time'));
        for (var i = 0; i < lstSlotStartTm.length; i++) {
            var time = lstSlotStartTm[i];
            $('#startCmb').append($("<option> </option>").val(time).html(time));
        }

        $('#endCmb').empty();
        $('#endCmb').prepend($("<option> </option>").val(0).html('End Time'));
        for (var i = 0; i < lstSlotEndTm.length; i++) {
            var time = lstSlotEndTm[i];
            $('#endCmb').append($("<option> </option>").val(time).html(time));
        }

    }

    getAmissionTestSlotList = (slotType) => {
        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/mst/regs/slot/list',
            method: "POST",
            params: { slotType, screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstRegsSlot = response.data.lstRegsSlot;
                this.setAdmissionTestSlotTable();
            }
            else {

                if (response.data.SvcMsg === "No Data Found") {
                    lstRegsSlot = [];
                    this.setAdmissionTestSlotTable();
                }
                else if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);

                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    fillSlotTypeCmb() {
        $('#slotTypeCmb').empty();
        // $('#slotTypeCmb').prepend($("<option> </option>").val(0).html('Slots'));
        for (let i = 0; i < lstSlotType.length; i++) {
            let slotId = lstSlotType[i].slotId;
            let slotType = lstSlotType[i].slotType;
            $('#slotTypeCmb').append($("<option> </option>").val(slotId).html(slotType));
        }
    }

    fillFromClassCmb() {
        $('#classesCmb').empty();
        $('#classesCmb').prepend($("<option> </option>").val(0).html('Class'));
        for (let i = 0; i < lstFromClass.length; i++) {
            let classId = lstFromClass[i].classId;
            let className = lstFromClass[i].className;
            $('#classesCmb').append($("<option> </option>").val(classId).html(className));
        }
    }



    setAdmissionTestSlotTable = () => {
        $('#admissionTestSlotBodyTable tr').remove();
        let table = document.getElementById('admissionTestSlotTable');
        let tbody = document.getElementById('admissionTestSlotBodyTable');

        for (let i = 0; i < lstRegsSlot.length; i++) {
            let regsSlotId = lstRegsSlot[i].regsSlotId;
            let className = lstRegsSlot[i].className;
            let startDt = lstRegsSlot[i].startDt;
            let endDt = lstRegsSlot[i].endDt;
            let days = lstRegsSlot[i].days;

            let row = document.createElement('tr');
            row.id = regsSlotId;

            row.onclick = (e) => {

                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.setAmissionTestSlotForm($(e.target).closest('tr').attr('id'));
                this.getTestSlotList($(e.target).closest('tr').attr('id'));
                $('.slotTimingWrap').show();
                this.setState({
                    flg: false,
                });
            }

            this.addTD(row, regsSlotId, 'no-display');
            this.addTD(row, className, 'text-center');
            this.addTD(row, startDt, 'text-center');
            this.addTD(row, endDt, 'text-center');
            this.addTD(row, days, 'text-center');

            tbody.append(row);
        }
        table.append(tbody);
    }

    setAmissionTestSlotForm = (regsSlotId) => {
        let days = [];
        let admissionTestData = this.getAmissionTestSlotData(regsSlotId);
        $('#classesCmb').val(admissionTestData.regsSlotId);
        days = admissionTestData.days;

        // for (let i = 0; i < days.length; i++) {
        //     if (days[i] === "M") {
        //         $('#mon').prop('checked', true);
        //     }
        //     else if (days[i] === "T") {
        //         $('#tue').prop('checked', true);
        //     }
        //     else if  (days[i] === "W")  {
        //         $('#wed').prop('checked', false);
        //     }
        //     else if  (days[i] === "T")  {
        //         $('#thu').prop('checked', false);
        //     }
        //     else if  (days[i] === "F")  {
        //         $('#fri').prop('checked', false);
        //     }
        //     else if  (days[i] === "S")  {
        //         $('#sat').prop('checked', false);
        //     }
        //     else if  (days[i] === "S")  {
        //         $('#sun').prop('checked', false);
        //     }
        // }

        admissionTestData.mon === true ? $('#mon').prop('checked', true) : $('#mon').prop('checked', false);
        admissionTestData.tue === true ? $('#tue').prop('checked', true) : $('#tue').prop('checked', false);
        admissionTestData.wed === true ? $('#wed').prop('checked', true) : $('#wed').prop('checked', false);
        admissionTestData.thu === true ? $('#thu').prop('checked', true) : $('#thu').prop('checked', false);
        admissionTestData.fri === true ? $('#fri').prop('checked', true) : $('#fri').prop('checked', false);
        admissionTestData.sat === true ? $('#sat').prop('checked', true) : $('#sat').prop('checked', false);
        admissionTestData.sun === true ? $('#sun').prop('checked', true) : $('#sun').prop('checked', false);


        if (admissionTestData.endDt === "00-00-0000" || admissionTestData.endDt === null) {
            this.setState({
                endDt: "",
            });
        }
        else {
            let date = admissionTestData.endDt;
            date = date.split('-');
            let setDate = date.reverse().join();
            setDate = setDate.replace(/,/g, '-');
            let endDt = new Date(setDate);
            this.setState({
                endDt: endDt,
            });
        }
        if (admissionTestData.startDt === "00-00-0000") {
            this.setState({
                startDt: "",
            });
        }
        else {
            let date = admissionTestData.startDt;
            date = date.split('-');
            let setDate = date.reverse().join();
            setDate = setDate.replace(/,/g, '-');
            let startDt = new Date(setDate);
            this.setState({
                startDt: startDt,
            });
        }



    }

    getAmissionTestSlotData(regsSlotId) {
        for (let i = 0; i < lstRegsSlot.length; i++) {
            if (parseInt(regsSlotId) === lstRegsSlot[i].regsSlotId)
                return lstRegsSlot[i];
        }
    }

    addAmissionTestSlot = () => {
        let regsSlotTime = {};
        let regsSlotId = 0;
        let slotType = this.state.slotId;
        let classId = $('#classesCmb').find('option:selected').val();
        let startDt = $('#startDt').val();
        let endDt = $('#endDt').val();
        let mon = $('#mon').prop('checked') === true ? 1 : 0;
        let tue = $('#tue').prop('checked') === true ? 1 : 0;
        let wed = $('#wed').prop('checked') === true ? 1 : 0;
        let thu = $('#thu').prop('checked') === true ? 1 : 0;
        let fri = $('#fri').prop('checked') === true ? 1 : 0;
        let sat = $('#sat').prop('checked') === true ? 1 : 0;
        let sun = $('#sun').prop('checked') === true ? 1 : 0;

        let days = mon + "" + tue + wed + thu + fri + sat + sun;


        if (parseInt(classId) === 0) {
            toast.warning('Select a class');
            $('#classesCmb').focus();
            return false;
        }

        if (startDt === "") {
            toast.warning('Please Enter from Date');
            $('#startDt').focus();
            return false;
        }

        if (endDt === "") {
            toast.warning('Please Enter to Date');
            $('#endDt').focus();
            return false;
        }



        if (this.state.flg) {
            regsSlotId = 0;
        } else {
            regsSlotId = $('#admissionTestSlotBodyTable tr.selected').find('td:eq(0)').text();
        }


        regsSlotTime = {
            'regsSlotId': regsSlotId, 'classId': classId, 'startDt': startDt, 'endDt': endDt, 'classId': classId,
            days, slotType
        }

        this.setState({ loader: 'block', opacity: 0.5 });

        axios.post(httpURL + '/sms/mst/regs/slot/save',
            queryString.stringify({ 'regsSlot': JSON.stringify(regsSlotTime), screenId }),
            {
                headers: { 'tokenid': this.state.tokenid },
                withCredentials: true
            }
        ).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                let slotType = this.state.slotId;

                this.getAmissionTestSlotList(slotType);
                this.setAdmissionTestSlotTable();
                this.addAmissionTestSlotReady();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });

    }
    addAmissionTestSlotReady = () => {
        $('#amissionTestSlotAddForm').trigger('reset');
        $('#admissionTestSlotBodyTable tr').removeClass('selected');
        this.setState({
            startDt: null,
            endDt: null,
        });
        $('.slotTimingWrap').hide();
        $('.dayCheckbox').prop('checked', true);
        this.addTestSlotReady();

    }
    addTD(row, val, classname) {
        let cell = document.createElement('td');
        cell.className = classname;
        let text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }


    // ===================== Slot Timing =============================

    getTestSlotList = (regsSlotId) => {
        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/mst/regs/slot/time/list',
            method: "POST",
            params: { regsSlotId, screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstRegsSlotTime = response.data.lstRegsSlotTime;
                this.setTestSlotTable();
            }
            else {
                lstRegsSlotTime = [];
                this.setTestSlotTable();
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);

                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

    }


    setTestSlotTable = () => {
        $('#testSlotBodyTable tr').remove();
        let table = document.getElementById('testSlotTable');
        let tbody = document.getElementById('testSlotBodyTable');

        for (let i = 0; i < lstRegsSlotTime.length; i++) {
            let regsSlotTimeId = lstRegsSlotTime[i].regsSlotTimeId;
            let startTm = lstRegsSlotTime[i].startTm;
            let endTm = lstRegsSlotTime[i].endTm;
            let maxTests = lstRegsSlotTime[i].maxTests;


            let row = document.createElement('tr');
            row.id = regsSlotTimeId;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.setTestSlotForm($(e.target).closest('tr').attr('id'));
                this.setState({
                    slotFlg: false,
                });
            }

            this.addTD(row, regsSlotTimeId, 'no-display');
            this.addTD(row, startTm, 'text-center');
            this.addTD(row, endTm, 'text-center');
            this.addTD(row, maxTests, 'text-center');

            tbody.append(row);
        }
        table.append(tbody);
    }

    setTestSlotForm = (regsSlotTimeId) => {
        let slotTestData = this.getTestSlotData(regsSlotTimeId);
        $("#startCmb").val(slotTestData.startTm);
        $("#endCmb").val(slotTestData.endTm);
        $("#maxTests").val(slotTestData.maxTests);
        slotTestData.mon === true ? $('#mon').prop('checked', true) : $('#mon').prop('checked', false);
        slotTestData.tue === true ? $('#tue').prop('checked', true) : $('#tue').prop('checked', false);
        slotTestData.wed === true ? $('#wed').prop('checked', true) : $('#wed').prop('checked', false);
        slotTestData.thu === true ? $('#thu').prop('checked', true) : $('#thu').prop('checked', false);
        slotTestData.fri === true ? $('#fri').prop('checked', true) : $('#fri').prop('checked', false);
        slotTestData.sat === true ? $('#sat').prop('checked', true) : $('#sat').prop('checked', false);
        slotTestData.sun === true ? $('#sun').prop('checked', true) : $('#sun').prop('checked', false);


    }

    getTestSlotData(regsSlotTimeId) {
        for (let i = 0; i < lstRegsSlotTime.length; i++) {
            if (parseInt(regsSlotTimeId) === lstRegsSlotTime[i].regsSlotTimeId)
                return lstRegsSlotTime[i];
        }
    }

    addTestSlot = () => {
        let regsSlotTime = {};
        let regsSlotTimeId = 0;
        let regsSlotId = $('#admissionTestSlotBodyTable tr.selected').find('td:eq(0)').text();
        let startTm = $('#startCmb').find('option:selected').text();
        let endTm = $('#endCmb').find('option:selected').text();
        let maxTests = $('#maxTests').val();

        if (startTm === "Start Time") {
            toast.warning('Please Enter Start Time');
            $('#startCmb').focus();
            return false;
        }

        if (endTm === "End Time") {
            toast.warning('Please Enter End Time');
            $('#endCmb').focus();
            return false;
        }



        if (this.state.slotFlg) {
            regsSlotTimeId = 0;
        } else {
            regsSlotTimeId = $('#testSlotBodyTable tr.selected').find('td:eq(0)').text();
        }


        regsSlotTime = {
            regsSlotTimeId,
            regsSlotId,
            startTm,
            endTm,
            maxTests,
        }


        this.setState({ loader: 'block', opacity: 0.5 });

        axios.post(httpURL + '/sms/mst/regs/slot/time/save',
            queryString.stringify({ 'regsSlotTime': JSON.stringify(regsSlotTime), screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getTestSlotList(regsSlotId);
                this.setTestSlotTable();
                this.addTestSlotReady();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });

    }
    addTestSlotReady = () => {
        $('#testSlotAddForm').trigger('reset');
        $('#testSlotBodyTable tr').removeClass('selected');

    }


    slotChange = async () => {
        this.addAmissionTestSlotReady();
        this.getAmissionTestSlotList(this.state.slotId);
        var slotType = this.state.slotType;
        $('.slotTimingWrap').hide();
        if (slotType === "Test Slot") {
            this.setState({
                mode: 'Tests'
            });
        }
        else {
            this.setState({
                mode: 'Interviews'
            });
        }
    }


    handleTabClick = async (e, slotId) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.persist();
        var slotType = $(e.target).get(0).innerHTML;
        await this.setState({
            slotId: slotId,
            slotType: slotType
        });

        $(".nav-item").removeClass('active');
        $(e.target).closest(".nav-item").addClass('active');

        this.slotChange();
    }

    getWingId = (wingId) => {
        // console.log("wingId" + wingId);
    }


    render() {
        httpURL = lstModules['purchase'].url + ":" + lstModules['purchase'].port;
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer" style={{ opacity: this.state.opacity }}>
                    <div className="col-md-11 col-centered clearfix">

                        <div className="col-md-6  col-xs-6   padding-remove">
                            <div className="form-group col-xs-8 col-centered">
                                <div className="col-sm-3 padding-remove" style={{ height: '40px', lineHeight: '40px' }} >
                                    <label className="control-label padding-remove" >Slot Type<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                </div>
                                {/* <div className="col-sm-6 padding-remove">
                                    <select className="form-control" onChange={this.slotChange} id="slotTypeCmb"></select>
                                </div> */}
                                <ul className="nav nav-pills nav-fill" role="tablist" >
                                    {lstSlotType.map((item, idx) => {
                                        return idx == 0 ?
                                            <li className="nav-item active" key={item.slotId} role="presentation" >
                                                <a id={item.slotId}
                                                    data-mdb-toggle="pill"
                                                    /* href="#ex1-pills-1"  */
                                                    className="nav-link active"
                                                    aria-current="page"
                                                    href="#"
                                                    key={"a_" + item.slotId}
                                                    role="tab"
                                                    aria-controls="ex1-pills-1"
                                                    aria-selected="true"
                                                    onClick={((e) => this.handleTabClick(e, item.slotId))}
                                                >
                                                    {item.slotType}
                                                </a>
                                            </li>
                                            :
                                            <li className="nav-item" key={item.slotId} role="presentation" >
                                                <a id={item.slotId}
                                                    data-mdb-toggle="pill"
                                                    /* href="#ex1-pills-1"  */
                                                    className="nav-link"
                                                    aria-current="page"
                                                    href="#"
                                                    key={"a_" + item.slotId}
                                                    role="tab"
                                                    aria-controls="ex1-pills-1"
                                                    aria-selected="true"
                                                    onClick={((e) => this.handleTabClick(e, item.slotId))}
                                                >
                                                    {item.slotType}
                                                </a>
                                            </li>;
                                    })
                                    }
                                </ul>
                            </div>
                            <table className="tableLayout1 admissionTestSlot" style={{ width: this.state.width }}>
                                <thead>
                                    <tr>
                                        <th style={{ display: 'none' }}> admissionTestSlot Id </th>
                                        <th style={{ width: 40 }}> Class </th>
                                        <th style={{ width: 40 }}> Start Date </th>
                                        <th style={{ width: 40 }}> End Date </th>
                                        <th style={{ width: 40 }}> Days </th>

                                    </tr>
                                </thead>
                            </table>
                            <div className="amissionTestSlotTableDiv" style={{ height: '220px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                <table id="admissionTestSlotTable" className="tableLayout">
                                    <colgroup>
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                    </colgroup>
                                    <tbody id="admissionTestSlotBodyTable"></tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-xs-1 ">
                            <span id="addAdmissionSlotTest" title="Add Admission Test Slot" onClick={this.addAmissionTestSlotReady} className="addIcon glyphicon glyphicon-plus-sign"></span>
                        </div>
                        <div className="col-md-5 col-xs-8 padding-remove" >
                            <form id="amissionTestSlotAddForm" className="form-horizontal formWrapper">
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Class<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <select autoComplete="off" id="classesCmb" type="text" className="form-control " />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Start Dt.<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <DatePicker id="startDt"
                                            className='form-control'
                                            placeholderText=" Start Date"
                                            selected={this.state.startDt}
                                            onChange={this.handleChange}
                                            dateFormat="dd-MM-yyyy"
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-5" >End Dt.<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <DatePicker id="endDt"
                                            className='form-control'
                                            placeholderText=" End Date"
                                            selected={this.state.endDt}
                                            onChange={this.handleChange1}
                                            dateFormat="dd-MM-yyyy"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Days<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove daysWrapper">
                                        {/* <input autoComplete="off" id="days" type="text" className="form-control " placeholder="Days." /> */}
                                        <div>
                                            <span>M</span><input autoComplete="off" id="mon" type="checkbox" className="form-control dayCheckbox " />
                                        </div>
                                        <div>
                                            <span>T</span><input autoComplete="off" id="tue" type="checkbox" className="form-control dayCheckbox " />
                                        </div>
                                        <div>
                                            <span>W</span><input autoComplete="off" id="wed" type="checkbox" className="form-control dayCheckbox " />
                                        </div>
                                        <div>
                                            <span>Th</span><input autoComplete="off" id="thu" type="checkbox" className="form-control dayCheckbox " />
                                        </div>
                                        <div>
                                            <span>F</span><input autoComplete="off" id="fri" type="checkbox" className="form-control dayCheckbox " />
                                        </div>
                                        <div>
                                            <span>S</span><input autoComplete="off" id="sat" type="checkbox" className="form-control dayCheckbox " />
                                        </div>
                                        <div>
                                            <span>Su</span><input autoComplete="off" id="sun" type="checkbox" className="form-control dayCheckbox " />
                                        </div>

                                    </div>
                                </div>
                                <div className="form-group" >
                                    <div className="col-sm-12 text-center userButtonWrap" >
                                        <button type="button" style={{ marginTop: 20 }} title="Save" onClick={this.addAmissionTestSlot} className="btn btn-info">Save</button>
                                        {/* <button type="button" id="slot" style={{ marginTop: 20 }} title="Slot Timing" onClick={this.showSlotTiming} className="btn btn-info">Add Slot Timing</button> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-11 slotTimingWrap col-centered clearfix">
                        <div className="col-md-6  col-xs-6">
                            <h3>Slots</h3>
                            <table className="tableLayout1 testSlot" style={{ width: this.state.width }}>
                                <thead>
                                    <tr>
                                        <th style={{ display: 'none' }}> slot Id </th>
                                        <th style={{ width: 40 }}> Start Time </th>
                                        <th style={{ width: 40 }}>End Time </th>
                                        <th style={{ width: 40 }}>Max. Test </th>

                                    </tr>
                                </thead>
                            </table>
                            <div className="testSlotTableDiv" style={{ height: '220px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                <table id="testSlotTable" className="tableLayout">
                                    <colgroup>
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                    </colgroup>
                                    <tbody id="testSlotBodyTable"></tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-xs-1 ">
                            <span id="addSlotTest" title="Add Test Slot" onClick={this.addTestSlotReady} className="addIcon glyphicon glyphicon-plus-sign"></span>
                        </div>
                        <div className="col-md-5 col-xs-8 padding-remove" >
                            <form id="testSlotAddForm" className="form-horizontal formWrapper">
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Start Time<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <select className="form-control" id="startCmb"></select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >End Time<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <select className="form-control" id="endCmb"></select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Max {this.state.mode}<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="maxTests" type="number" className="form-control " placeholder={"Maximum  " + this.state.mode} />
                                    </div>
                                </div>
                                <div className="form-group" >
                                    <div className="col-sm-12 text-center userButtonWrap" >
                                        <button type="button" style={{ marginTop: 20 }} title="Save" onClick={this.addTestSlot} className="btn btn-info">Save</button>
                                        <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={this.closeSlider}>
                                            <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </div >

        );
    }



}
export default admissionTestSlot;