import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import 'jquery-ui';
import '../css/quotes.css';
import 'common/css/master.css';
import axios from 'axios';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

var httpURL = '';
var queryString = require('querystring');
var lstQuote = [];
var lstQuoteItems = [];
var lstType = [];
var lstQuoteSupplier = [];
var lstAction = [];
var lstSupplierResponse = [];
var lstSuppliers = [];
var lstItems = [];
var lstSuppResponse = [];
var tokenid = localStorage.getItem("tokenid");
var screenId = 14;


class quotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            tokenid: tokenid,
            flg: true,
            flgQItem: true,
            flgSItem: true,
            quoteDt: null,
            quoteExpiryDt: null,
            expDelDt: null,
            sentDt: null,
            supplierResponseDt: null,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);
        this.handleChange3 = this.handleChange3.bind(this);
        this.handleChange4 = this.handleChange4.bind(this);

    }

    handleChange(date) {
        this.setState({ quoteDt: date, });
    }
    handleChange1(date) {
        this.setState({ quoteExpiryDt: date, });
    }
    handleChange2(date) {
        this.setState({ expDelDt: date, });
    }
    handleChange3(date) {
        this.setState({ sentDt: date, });
    }
    handleChange4(date) {
        this.setState({ supplierResponseDt: date, });
    }



    componentDidMount = () => {
        $('.itemSupplierWrapper').hide();
        $('#itemCmb').prepend($('<option> </option>').val(0).html('Item'));
        $('#supplierCmb').prepend($('<option> </option>').val(0).html('Supplier'));

        this.getItemList();
        this.getSupplierCmbList();
        this.getQuoteList();


        lstAction = [{

            'ActionId': 1, 'Action': 'Accept',
        },
        {
            'ActionId': 2, 'Action': 'Issue PO',
        },
        {
            'ActionId': 3, 'Action': 'Reject',
        },
        ];

        lstSuppResponse = [{

            'suppResponseId': 10, 'suppResponse': 'Pending',
        },
        {
            'suppResponseId': 20, 'suppResponse': 'Accepted',
        },
        {
            'suppResponseId': 30, 'suppResponse': 'Query',
        },
        {
            'suppResponseId': 40, 'suppResponse': 'Rejected',
        },
        ];

        lstSupplierResponse = [{
            'itemId': 1, 'itemCode': '135', 'item': 'Blackboard', 'unit': 12, 'quantity': 4, 'rate': 200,
            'Total': 1000, 'remarks': 'None',
        },
        {
            'itemId': 2, 'itemCode': '100', 'item': 'Whiteboard', 'unit': 2, 'quantity': 5, 'rate': 100,
            'Total': 500, 'remarks': 'no',
        },
        {
            'itemId': 3, 'itemCode': '151', 'item': 'Chalks', 'unit': 5, 'quantity': 10, 'rate': 100,
            'Total': 600, 'remarks': 'None',
        },
        {
            'itemId': '1', 'itemCode': '102', 'item': 'Bulbs', 'unit': 10, 'quantity': 40, 'rate': 10,
            'Total': 400, 'remarks': 'None',
        },
        ];


        this.setSupplierResponseTable();


        this.fillRoomactionCmb();
        this.fillSuppResponseCmb();
        this.setQuotesTable();
        this.setQuoteItemsTable();
    }
    getItemList = () => {
        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/item/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstItems = response.data.lstItems;
                this.fillItemCmb();
            }
            else {
                if (response.data.SvcMsg == 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }
    getSupplierCmbList = () => {
        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/supplier/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstSuppliers = response.data.lstSupplier;
                this.fillSupplierCmb();
            }
            else {
                if (response.data.SvcMsg == 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);

                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }
    getQuoteList = () => {
        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/mst/quo/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstQuote = response.data.lstQuote;
                this.setQuotesTable();
            }
            else {
                if (response.data.SvcMsg == 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    getQuoteItemList = (quoteId) => {
        axios({
            url: httpURL + '/sms/mst/quo/items/list',
            method: "POST",
            params: { 'quoteId': quoteId, screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstQuoteItems = response.data.lstQuoteItems;
                this.setQuoteItemsTable();
            }
            else {
                if (response.data.SvcMsg == 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }
    getSupplierList = (quoteId) => {
        axios({
            url: httpURL + '/sms/mst/quo/supplier/list',
            method: "POST",
            params: { 'quoteId': quoteId, screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstQuoteSupplier = response.data.lstQuoteSupplier;
                this.setQuoteSupplierTable();
            }
            else {
                if (response.data.SvcMsg == 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }
    fillSuppResponseCmb() {
        $('#suppResponseCmb').empty();
        $('#suppResponseCmb').prepend($("<option> </option>").val(0).html('Supplier Response'));
        for (var i = 0; i < lstSuppResponse.length; i++) {
            var suppResponseId = lstSuppResponse[i].suppResponseId;
            var suppResponse = lstSuppResponse[i].suppResponse;
            $('#suppResponseCmb').append($("<option> </option>").val(suppResponseId).html(suppResponse));
        }
    }
    fillItemCmb() {
        $('#itemCmb').empty();
        $('#itemCmb').prepend($("<option> </option>").val(0).html('Item'));
        for (var i = 0; i < lstItems.length; i++) {
            var itemId = lstItems[i].itemId;
            var item = lstItems[i].item;
            $('#itemCmb').append($("<option> </option>").val(itemId).html(item));
        }
    }
    fillSupplierCmb() {
        $('#supplierCmb').empty();
        $('#supplierCmb').prepend($("<option> </option>").val(0).html('Supplier'));
        for (var i = 0; i < lstSuppliers.length; i++) {
            var supplierId = lstSuppliers[i].supplierId;
            var supplier = lstSuppliers[i].supplier;
            $('#supplierCmb').append($("<option> </option>").val(supplierId).html(supplier));
        }
    }


    setQuotesTable = () => {
        $('#quoteBodyTable tr').remove();
        var table = document.getElementById('quoteTable');
        var tbody = document.getElementById('quoteBodyTable');

        for (var i = 0; i < lstQuote.length; i++) {
            var quoteId = lstQuote[i].quoteId;
            var quoteNo = lstQuote[i].quoteNo;
            var quoteDt = lstQuote[i].quoteDt;
            var sentCount = lstQuote[i].sentCount;
            var responseCount = lstQuote[i].responseCount;
            var quoteExpiryDt = lstQuote[i].quoteExpiryDt;
            var expDelDt = lstQuote[i].expDelDt;
            var poId = lstQuote[i].poId;

            if (quoteExpiryDt == '00-00-0000' || quoteExpiryDt == "") {
                quoteExpiryDt = '-';
            }

            var row = document.createElement('tr');
            row.id = quoteId;


            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.setQuoteForm($(e.target).closest('tr').attr('id'));
                this.getQuoteItemList($(e.target).closest('tr').attr('id'));
                this.getSupplierList($(e.target).closest('tr').attr('id'));
                $('body').addClass('openSlider');
                $('.itemSupplierWrapper').show();
                this.setState({
                    flg: false,
                });
            }

            this.addTD(row, quoteId, 'no-display');
            this.addTD(row, quoteNo, 'text-center');
            this.addTD(row, quoteDt, 'text-center');
            this.addTD(row, sentCount, 'text-center');
            this.addTD(row, responseCount, 'text-center');
            this.addTD(row, quoteExpiryDt, 'text-center');
            this.addTD(row, expDelDt, 'text-center');
            this.addTD(row, poId, 'no-display');

            tbody.append(row);
        }
        table.append(tbody);
    }

    setQuoteForm = (quoteId) => {
        var quoteData = this.getquoteData(quoteId);

        $('#quoteId').val(quoteData.quoteId);
        $('#quoteNo').val(quoteData.quoteNo);
        // $('#quoteDt').val(quoteData.quoteDt);

        var date = quoteData.quoteDt;
        date = date.split('-');
        var setDate = date.reverse().join();
        setDate = setDate.replace(/,/g, '-');
        var quoteDt = new Date(setDate);
        this.setState({
            quoteDt: quoteDt,
        });

        if (quoteData.quoteExpiryDt == "00-00-0000" || quoteData.quoteExpiryDt == "") {
            this.setState({
                quoteExpiryDt: null,
            });
        }
        else {
            var date = quoteData.quoteExpiryDt;
            date = date.split('-');
            var setDate = date.reverse().join();
            setDate = setDate.replace(/,/g, '-');
            var quoteExpiryDt = new Date(setDate);
            this.setState({
                quoteExpiryDt: quoteExpiryDt,
            });
        }

        var date = quoteData.expDelDt;
        date = date.split('-');
        var setDate = date.reverse().join();
        setDate = setDate.replace(/,/g, '-');
        var expDelDt = new Date(setDate);
        this.setState({
            expDelDt: expDelDt,
        });
    }

    getquoteData(quoteId) {
        for (var i = 0; i < lstQuote.length; i++) {
            if (quoteId == lstQuote[i].quoteId)
                return lstQuote[i];
        }
    }

    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    addQuoteType = () => {
        $('.itemSupplierWrapper').hide();
        $('#quoteAddForm').trigger('reset');
        $('#quoteBodyTable tr').removeClass('selected');
        $('body').addClass('openSlider');
        this.setState({
            quoteDt: null,
            quoteExpiryDt: null,
            expDelDt: null,
        })
    }

    addQuote = () => {

        var lstQuotesArr = {};
        var quoteId = 0;
        var quoteNo = $('#quoteNo').val();
        var quoteDt = $('#quoteDt').val();
        var quoteExpiryDt = $('#quoteExpiryDt').val();
        var expDelDt = $('#expDelDt').val();

        if (quoteNo == "") {
            toast.warning('Please Enter Quote no');
            $('#quoteNo').focus();
            return false;
        }

        if (quoteDt == "") {
            toast.warning('Please Enter quote date');
            $('#quoteDt').focus();
            return false;
        }
        // if (quoteExpiryDt == "") {
        //     toast.warning('Please Enter quote expiry date');
        //     $('#quoteExpiryDt').focus();
        //     return false;
        // }
        if (expDelDt == "") {
            toast.warning('Please Enter expected delivery date');
            $('#expDelDt').focus();
            return false;
        }

        if (this.state.flg) {
            quoteId = 0
        }
        else {
            quoteId = $('#quoteBodyTable tr.selected').find('td:eq(0)').text();
        }


        lstQuotesArr = {
            'quoteId': quoteId,
            'quoteNo': quoteNo,
            'quoteDt': quoteDt,
            'quoteExpiryDt': quoteExpiryDt,
            'expDelDt': expDelDt,
        }

        axios({
            url: httpURL + '/sms/mst/quo/save',
            method: "POST",
            params: { 'quote': JSON.stringify(lstQuotesArr), screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getQuoteList();
                this.setQuotesTable();
                $('body').removeClass('openSlider');

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });

    }


    setQuoteItemsTable = () => {
        $('#quoteItemsBodyTable tr').remove();
        var table = document.getElementById('quoteItemsTable');
        var tbody = document.getElementById('quoteItemsBodyTable');

        for (var i = 0; i < lstQuoteItems.length; i++) {
            var expRate = lstQuoteItems[i].expRate;
            var itemId = lstQuoteItems[i].itemId;
            var item = lstQuoteItems[i].item;
            var quoteId = lstQuoteItems[i].quoteId;
            var quantity = lstQuoteItems[i].qty;
            var quoteItemId = lstQuoteItems[i].quoteItemId;

            var itemCode = lstQuoteItems[i].itemCode;
            // var unit = lstQuoteItems[i].unit;


            var requirement1 = lstQuoteItems[i].requirement1;
            var requirement2 = lstQuoteItems[i].requirement2;
            var requirement3 = lstQuoteItems[i].requirement3;
            var requirement4 = lstQuoteItems[i].requirement4;


            var row = document.createElement('tr');
            row.id = quoteItemId;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.setQuoteItemsForm($(e.target).closest('tr').attr('id'));
                this.setState({
                    flgQItem: false,
                });
            }

            this.addTD(row, quoteItemId, 'no-display');
            this.addTD(row, quoteId, 'no-display');
            this.addTD(row, itemCode, 'text-center');
            this.addTD(row, item, 'text-center');
            this.addTD(row, expRate, 'text-center');
            this.addTD(row, quantity, 'text-center');
            this.addTD(row, requirement1, 'text-center');

            tbody.append(row);
        }
        table.append(tbody);
    }

    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }
    /*addTDEdit(row, statusId, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var span = document.createElement('span');

        span.className = val;
        span.style.cursor = 'pointer';
        span.onclick = () => {
            $('body').addClass('openSlider');
            $('td').removeClass('selected');
            $(row).addClass('selected').siblings().removeClass('selected');
            var quoteItemId = $('#quoteItemsBodyTable tr.selected').find('td:eq(0)').text();
        };
        cell.append(span);
        row.append(cell);
    }*/

    setQuoteItemsForm = (quoteItemId) => {

        var quoteItemsData = this.getquoteItemsData(quoteItemId);
        $('#quoteId').val(quoteItemsData.quoteId);
        $('#itemCmb').val(quoteItemsData.itemId);
        $('#unit').val(quoteItemsData.unit);
        $('#quantity').val(quoteItemsData.qty);
        $('#remarks1').val(quoteItemsData.requirement1);
        $('#remarks2').val(quoteItemsData.requirement2);
        $('#remarks3').val(quoteItemsData.requirement3);
        $('#remarks4').val(quoteItemsData.requirement4);
    }

    getquoteItemsData(quoteItemId) {
        for (var i = 0; i < lstQuoteItems.length; i++) {
            if (quoteItemId == lstQuoteItems[i].quoteItemId)
                return lstQuoteItems[i];
        }
    }
    addQuoteItems = () => {
        var lstQuoteItem = {};
        var quoteItemId = 0;
        var quoteId = $('#quoteBodyTable tr.selected').find('td:eq(0)').text();
        var expRate = $('#itemCode').val();
        // var unit = $('#unit').val();
        var itemId = $('#itemCmb').find('option:selected').val();
        var quantity = $('#quantity').val();
        var requirement1 = $('#remarks1').val();
        var requirement2 = $('#remarks2').val();
        var requirement3 = $('#remarks3').val();
        var requirement4 = $('#remarks4').val();

        if (itemId == 0) {
            toast.warning('Please select Valid Item');
            $('#itemCmb').focus();
            return false;
        }

        // if (unit == "") {
        //     toast.warning('Please Enter unit');
        //     $('#itemId').focus();
        //     return false;
        // }
        if (quantity == "") {
            toast.warning('Please Enter quantity');
            $('#quantity').focus();
            return false;
        }
        if (this.state.flgQItem) {
            quoteItemId = 0
        }
        else {
            quoteItemId = $('#quoteItemsBodyTable tr.selected').find('td:eq(0)').text();
        }

        lstQuoteItem = {
            'quoteItemId': quoteItemId,
            'quoteId': quoteId,
            'itemId': itemId,
            'qty': quantity,
            'expRate': expRate,
            'requirement1': requirement1,
            'requirement2': requirement2,
            'requirement3': requirement3,
            'requirement4': requirement4,
        }

        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/mst/quo/items/save',
            method: "POST",
            params: { 'quoteItem': JSON.stringify(lstQuoteItem), screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getQuoteItemList(quoteId);
                this.setQuoteItemsTable();
                this.addQuoteItemsType();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });

    }


    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    addQuoteItemsType = () => {
        $('#quoteItemsAddForm').trigger('reset');
        $('#quoteItemsBodyTable tr').removeClass('selected');
    }

    closeSlider = () => {
        $('body').removeClass('openSlider');
    }

    fillRoomactionCmb() {
        $('#actionCmb').empty();
        $('#actionCmb').prepend($("<option> </option>").val(0).html('Action'));
        for (var i = 0; i < lstAction.length; i++) {
            var ActionId = lstAction[i].ActionId;
            var Action = lstAction[i].Action;
            $('#actionCmb').append($("<option> </option>").val(ActionId).html(Action));
        }
    }
    setQuoteSupplierTable = () => {
        $('#supplierBodyTable tr').remove();
        var table = document.getElementById('supplierTable');
        var tbody = document.getElementById('supplierBodyTable');

        for (var i = 0; i < lstQuoteSupplier.length; i++) {
            var supplierId = lstQuoteSupplier[i].supplierId;
            var supplierCode = lstQuoteSupplier[i].supplierCode;
            var supplier = lstQuoteSupplier[i].supplier;
            var businessName = lstQuoteSupplier[i].businessName;
            var sentDt = lstQuoteSupplier[i].sentDt;
            var suppResponse = lstQuoteSupplier[i].suppResponse;
            var supplierRemarks = '-';
            var Action = '-';


            var row = document.createElement('tr');
            row.id = supplierId;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.setSupplierForm($(e.target).closest('tr').attr('id'));
                this.setState({
                    flgSItem: false,
                })
            }

            this.addTD(row, supplierId, 'no-display');
            this.addTD(row, supplierCode, 'text-center');
            this.addTD(row, supplier, 'text-center');
            this.addTD(row, businessName, 'text-center');
            this.addTD(row, sentDt, 'text-center');
            this.addTD(row, suppResponse, 'text-center');
            this.addTD(row, supplierRemarks, 'text-center');
            this.addTD(row, Action, 'text-center');

            tbody.append(row);
        }
        table.append(tbody);
    }


    setSupplierResponseTable = () => {
        $('#supplierResponseBodyTable tr').remove();
        var table = document.getElementById('supplierResponseTable');
        var tbody = document.getElementById('supplierResponseBodyTable');

        var itemFilter = $('#itemFilter').val() !== undefined ? $('#itemFilter').val().toLowerCase() : '';
        var itemCodeFilter = $('#itemCodeFilter').val() !== undefined ? $('#itemCodeFilter').val().toLowerCase() : '';

        for (var i = 0; i < lstSupplierResponse.length; i++) {
            var ItemId = lstSupplierResponse[i].ItemId;
            var itemCode = lstSupplierResponse[i].itemCode;
            var item = lstSupplierResponse[i].item;
            var unit = lstSupplierResponse[i].unit;
            var quantity = lstSupplierResponse[i].quantity;
            var rate = lstSupplierResponse[i].rate;
            var Total = quantity * rate;
            var remarks = lstSupplierResponse[i].remarks;

            var row = document.createElement('tr');
            row.ItemId = ItemId;

            if (item != null && item.toLowerCase().indexOf(itemFilter) === -1)
                continue;
            if (itemCode != null && itemCode.toLowerCase().indexOf(itemCodeFilter) === -1)
                continue;


            this.addTD(row, ItemId, 'no-display');
            this.addTD(row, itemCode, 'text-center');
            this.addTD(row, item, 'text-center');
            this.addTD(row, unit, 'text-center');
            this.addTD(row, quantity, 'text-center');
            this.addTD(row, rate, 'text-center');
            this.addTD(row, Total, 'text-center');
            this.addTD(row, remarks, 'text-center');

            tbody.append(row);
        }
        table.append(tbody);
    }


    setSupplierForm = (supplierId) => {
        var supplierData = this.getSupplierData(supplierId);

        $('#supplierCmb').val(supplierData.supplierId);
        $('#sentDt').val(supplierData.sentDt);
        var suppResponseId = this.getSuppResponseId(supplierData.suppResponse);
        $('#suppResponseCmb').val(suppResponseId);
        $('#supplierRemarks').val(supplierData.supplierRemarks);
        $('#supplierResponseDt').val(supplierData.supplierResponseDt);

        var date = supplierData.sentDt;
        date = date.split('-');
        var setDate = date.reverse().join();
        setDate = setDate.replace(/,/g, '-');
        var sentDt = new Date(setDate);
        this.setState({
            sentDt: sentDt,
        });

        var date = supplierData.supplierResponseDt;
        date = date.split('-');
        var setDate = date.reverse().join();
        setDate = setDate.replace(/,/g, '-');
        var supplierResponseDt = new Date(setDate);
        this.setState({
            supplierResponseDt: supplierResponseDt,
        });

    }

    getSupplierData(supplierId) {
        for (var i = 0; i < lstQuoteSupplier.length; i++) {
            if (supplierId == lstQuoteSupplier[i].supplierId)
                return lstQuoteSupplier[i];
        }
    }
    getSuppResponseId(suppResponse) {
        for (var i = 0; i < lstSuppResponse.length; i++) {
            if (suppResponse == lstSuppResponse[i].suppResponse)
                return lstSuppResponse[i].suppResponseId;
        }
    }


    addSupplier = () => {
        var lstSupplierArr = {};
        var quoteSupplierId = 0;
        var quoteId = $('#quoteBodyTable tr.selected').find('td:eq(0)').text();
        var supplierId = $('#supplierCmb').find('option:selected').val();
        var sentDt = $('#sentDt').val();
        var suppResponse = $('#suppResponseCmb').find('option:selected').val();
        var supplierResponseDt = $('#supplierResponseDt').val();
        var supplierRemarks = $('#supplierRemarks').val();


        if (supplierId == 0) {
            toast.warning('Please Select Valid Supplier');
            $('#supplierCmb').focus();
            return false;
        }

        if (sentDt == "") {
            toast.warning('Please Enter sent on');
            $('#sentDt').focus();
            return false;
        }
        if (suppResponse == 0) {
            toast.warning('Please Select Valid Response');
            $('#suppResponse').focus();
            return false;
        }
        if (this.state.flgSItem) {
            quoteSupplierId = 0
        }
        else {
            quoteSupplierId = $('#supplierBodyTable tr.selected').find('td:eq(0)').text();
        }


        lstSupplierArr = {
            'quoteSupplierId': quoteSupplierId, 'quoteId': quoteId, 'supplierId': supplierId, 'sentDt': sentDt,
            'suppResponse': suppResponse, 'supplierResponseDt': supplierResponseDt, 'supplierRemarks': supplierRemarks,
        }


        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/mst/quo/supplier/save',
            method: "POST",
            params: { 'quoteSupplier': JSON.stringify(lstSupplierArr), screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getSupplierList(quoteId);
                this.setQuoteSupplierTable();
                this.addSupplierType();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });


    }

    addSupplierType = () => {
        $('#supplierAddForm').trigger('reset');
        $('#supplierBodyTable tr').removeClass('selected');
        this.setState({
            sentDt: null,
            supplierResponseDt: null
        });
    }


    getWingId = (wingId) => {
        // console.log("wingId" + wingId);
    }


    render() {
        httpURL = lstModules['purchase'].url + ":" + lstModules['purchase'].port;
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer Quotes" style={{ opacity: this.state.opacity }}>
                    <div className="col-md-11 col-centered clearfix">
                        <div className="col-md-9 col-xs-10 quoteWrap  padding-remove">
                            <table className="tableLayout1 quoteType" style={{ width: this.state.width }}>
                                <thead>
                                    <tr>
                                        <th style={{ display: 'none' }}> quote Id </th>
                                        <th style={{ width: 40 }}> Quote No </th>
                                        <th style={{ width: 40 }}> Quote Date </th>
                                        <th style={{ width: 40 }}> Sent Count </th>
                                        <th style={{ width: 40 }}> Response Count</th>
                                        <th style={{ width: 40 }}> Quote Expiry Date </th>
                                        <th style={{ width: 40 }}> Expected Delivery Date</th>

                                    </tr>
                                </thead>
                            </table>
                            <div className="quoteTableDiv" style={{ height: '400px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                <table id="quoteTable" className="tableLayout">
                                    <colgroup>
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                    </colgroup>
                                    <tbody id="quoteBodyTable"></tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-xs-1 ">
                            <span id="addQuotes" title="Add Quote Type" onClick={this.addQuoteType} className="addIcon glyphicon glyphicon-plus-sign"></span>
                        </div>

                    </div>
                    <div className="slider fullWidthSlider ">
                        <div className="col-md-11 col-centered wrapperTable  clearfix ">
                            <div className="col-md-5 col-xs-8  col-centered timingFormWrap" >
                                <form id="quoteAddForm" className="form-horizontal formWrapper">
                                    <div className="form-group">
                                        <label className="control-label col-sm-5" >Quote no.<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                        <div className="col-sm-6 padding-remove">
                                            <input autoComplete="off" id="quoteNo" type="text" className="form-control " placeholder="Enter Quote no." />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label col-sm-5" >Quote Date<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                        <div className="col-sm-6 padding-remove">
                                            <DatePicker id="quoteDt"
                                                className='form-control'
                                                placeholderText="Enter Quote Date"
                                                selected={this.state.quoteDt}
                                                onChange={this.handleChange}
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="control-label col-sm-5" > Quote Expiry Date<span></span></label>
                                        <div className="col-sm-6 padding-remove">
                                            <DatePicker id="quoteExpiryDt"
                                                className='form-control'
                                                placeholderText="Enter Quote expiry Date"
                                                selected={this.state.quoteExpiryDt}
                                                onChange={this.handleChange1}
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label col-sm-5" > Expected Delivery Date<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                        <div className="col-sm-6 padding-remove">
                                            <DatePicker id="expDelDt"
                                                className='form-control'
                                                placeholderText="Enter Quote expiry delivery Date"
                                                selected={this.state.expDelDt}
                                                onChange={this.handleChange2}
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group" >
                                        <div className="col-sm-12 text-center userButtonWrap" >
                                            <button type="button" style={{ marginTop: 20 }} title="Save" onClick={this.addQuote} className="btn btn-info">Save</button>
                                            <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={this.closeSlider}>
                                                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                                            </button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                        <div className="itemSupplierWrapper">
                            <div className="col-md-11 col-centered wrapperTable clearfix">
                                <div className="col-md-6  col-xs-6  padding-remove">
                                    <h3>Quote Item</h3>
                                    <table className="tableLayout1" style={{ width: this.state.width }}>
                                        <thead>
                                            <tr>
                                                <th style={{ display: 'none' }}> Quote item Id </th>
                                                <th style={{ width: 40 }}> Item Code </th>
                                                <th style={{ width: 25 }}> Item  </th>
                                                <th style={{ width: 25 }}> Unit  </th>
                                                <th style={{ width: 40 }}> Quantity </th>
                                                <th style={{ width: 55 }}> Remarks </th>

                                            </tr>
                                        </thead>
                                    </table>
                                    <div className="quoteItemsTableDiv" style={{ height: '250px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                        <table id="quoteItemsTable" className="tableLayout">
                                            <colgroup>
                                                <col width="40" />
                                                <col width="25" />
                                                <col width="25" />
                                                <col width="40" />
                                                <col width="55" />
                                            </colgroup>
                                            <tbody id="quoteItemsBodyTable"></tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-xs-1 ">
                                    <span id="addQuoteItems" title="Add Quote Items" onClick={this.addQuoteItemsType} className="addIcon glyphicon glyphicon-plus-sign"></span>
                                </div>
                                <div className="col-md-5 col-xs-8 padding-remove timingFormWrap" >
                                    <form id="quoteItemsAddForm" className="form-horizontal formWrapper">
                                        {/* <div className="form-group">
                                        <label className="control-label col-sm-5" > Item code<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                        <div className="col-sm-6 padding-remove">
                                            <input autoComplete="off" id="itemCode" type="text" className="form-control " placeholder="Enter code" />
                                        </div>
                                    </div> */}
                                        <div className="form-group">
                                            <label className="control-label col-sm-5" >Item<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                            <div className="col-sm-6 padding-remove">
                                                <select autoComplete="off" id="itemCmb" type="text" className="form-control " />
                                            </div>
                                        </div>
                                        {/* <div className="form-group">
                                        <label className="control-label col-sm-5" >Unit<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                        <div className="col-sm-6 padding-remove">
                                            <input autoComplete="off" id="unit" type="text" className="form-control " placeholder="Enter unit" />
                                        </div>
                                    </div> */}
                                        <div className="form-group">
                                            <label className="control-label col-sm-5" >Quantity<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                            <div className="col-sm-6 padding-remove">
                                                <input autoComplete="off" id="quantity" type="text" className="form-control " placeholder="Enter quantity" />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="control-label col-sm-5" >Remarks<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                            <div className="col-sm-6 padding-remove">
                                                <input autoComplete="off" id="remarks1" type="text" className="form-control " placeholder="Remarks 1" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label col-sm-5" >  </label>
                                            <div className="col-sm-6 padding-remove">
                                                <input autoComplete="off" id="remarks2" type="text" className="form-control " placeholder="Remarks 2" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label col-sm-5" >   </label>
                                            <div className="col-sm-6 padding-remove">
                                                <input autoComplete="off" id="remarks3" type="text" className="form-control " placeholder="Remarks 3" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label col-sm-5" >  </label>
                                            <div className="col-sm-6 padding-remove">
                                                <input autoComplete="off" id="remarks4" type="text" className="form-control " placeholder="Remarks 4" />
                                            </div>
                                        </div>


                                        <div className="form-group" >
                                            <div className="col-sm-12 text-center userButtonWrap" >
                                                <button type="button" style={{ marginTop: 20 }} title="Save" onClick={this.addQuoteItems} className="btn btn-info">Save</button>
                                            </ div>
                                        </ div>
                                    </form>
                                </div>

                            </div>

                            <div className="col-md-11 col-centered wrapperTable clearfix">
                                <div className="col-md-7  padding-remove">
                                    <table className="tableLayout1 " style={{ width: this.state.width }}>
                                        <thead>
                                            <tr>
                                                <th style={{ display: 'none' }}> supplierId </th>
                                                <th style={{ width: 40 }}> Supplier Code </th>
                                                <th style={{ width: 40 }}> Supplier </th>
                                                <th style={{ width: 40 }}> Bussiness </th>
                                                <th style={{ width: 40 }}> Set On </th>
                                                <th style={{ width: 40 }}> Responce </th>
                                                <th style={{ width: 40 }}> Status </th>
                                                <th style={{ width: 40 }}> Action </th>

                                            </tr>
                                        </thead>
                                    </table>
                                    <div className="supplierTableDiv" style={{ height: '400px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                        <table id="supplierTable" className="tableLayout">
                                            <colgroup>
                                                <col width="40" />
                                                <col width="40" />
                                                <col width="40" />
                                                <col width="40" />
                                                <col width="40" />
                                                <col width="40" />
                                                <col width="40" />
                                            </colgroup>
                                            <tbody id="supplierBodyTable"></tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-xs-1 ">
                                    <span id="addSupplier" title="Add Supplier Type" onClick={this.addSupplierType} className="addIcon glyphicon glyphicon-plus-sign"></span>
                                </div>
                                <div className="col-md-4 col-xs-8 padding-remove timingFormWrap" >
                                    <form id="supplierAddForm" className="form-horizontal formWrapper">
                                        <div className="form-group">
                                            <label className="control-label col-sm-5" >Supplier<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                            <div className="col-sm-6 padding-remove">
                                                <select autoComplete="off" id="supplierCmb" type="text" className="form-control " />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label col-sm-5" >Sent On<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                            <div className="col-sm-6 padding-remove">
                                                <DatePicker id="sentDt"
                                                    className='form-control'
                                                    placeholderText="sent on"
                                                    selected={this.state.sentDt}
                                                    onChange={this.handleChange3}
                                                    dateFormat="dd-MM-yyyy"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label col-sm-5" >Responce<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                            <div className="col-sm-6 padding-remove">
                                                <select autoComplete="off" id="suppResponseCmb" type="text" className="form-control " placeholder="Supplier Response" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label col-sm-5" >Response Date<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                            <div className="col-sm-6 padding-remove">
                                                <DatePicker id="supplierResponseDt"
                                                    className='form-control'
                                                    placeholderText="Response Date"
                                                    selected={this.state.supplierResponseDt}
                                                    onChange={this.handleChange4}
                                                    dateFormat="dd-MM-yyyy"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label col-sm-5" >Remarks<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                            <div className="col-sm-6 padding-remove">
                                                <input autoComplete="off" id="supplierRemarks" type="text" className="form-control " placeholder="Supplier Remarks" />
                                            </div>
                                        </div>
                                        <div className="form-group" >
                                            <div className="col-sm-12 text-center userButtonWrap" >
                                                <button type="button" style={{ marginTop: 20 }} title="Save" onClick={this.addSupplier} className="btn btn-info">Save</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-11 col-centered wrapperTable clearfix">
                                <div className="col-md-12  col-xs-12   padding-remove">
                                    <table className="tableLayout1" style={{ width: this.state.width }}>
                                        <thead>
                                            <tr>
                                                <th style={{ display: 'none' }}> Item ItemId</th>
                                                <th style={{ width: 50 }}>
                                                    <input type="text" className="form-control inp-search" onChange={this.setSupplierResponseTable} id="itemCodeFilter" placeholder="Item Code" />
                                                </th>
                                                <th style={{ width: 50 }}>
                                                    <input type="text" className="form-control inp-search" onChange={this.setSupplierResponseTable} id="itemFilter" placeholder="Item" />
                                                </th>
                                                <th style={{ width: 50 }}> Unit </th>
                                                <th style={{ width: 50 }}> Quantity </th>
                                                <th style={{ width: 50 }}> Rate</th>
                                                <th style={{ width: 80 }}> Total </th>
                                                <th style={{ width: 80 }}> Remarks </th>

                                            </tr>
                                        </thead>
                                    </table>
                                    <div className="supplierResponseTableDiv" style={{ height: '300px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                        <table id="supplierResponseTable" className="tableLayout">
                                            <colgroup>
                                                <col width="50" />
                                                <col width="50" />
                                                <col width="50" />
                                                <col width="50" />
                                                <col width="50" />
                                                <col width="80" />
                                                <col width="80" />

                                            </colgroup>
                                            <tbody id="supplierResponseBodyTable"></tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}
export default quotes;