import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import Header from "common/js/header/header.js";

import AnswerSheedPDF from './answerSheetPDF';
import { getLogoSvc } from './answerSheetSvc';


const AnswerSheet = () => {

    const [loader, setLoader] = useState("none");
    const [flgAnswerSheet, setFlgAnswerSheet] = useState(false);
    const [noOfResponses, setNoOfResponses] = useState(0);
    const [logoUrl, setLogoUrl] = useState('');
    const [rowsPerColumn, setRowsPerColumn] = useState(33);
    const [noOfColumns, setNoOfColumns] = useState(3);

    useEffect(() => {
        // console.log("useEffect in AnswerSheetCtrl called");
        getLogoSvc(cbLogoResponse);
    }, []);

    const printAnswerSheet = async (pResponses) => {
        // console.log("printAnswerSheet called, pResponses", pResponses);
        
        if (pResponses === 30) {
            await setRowsPerColumn(30);
            await setNoOfColumns(1);
        } else if (pResponses === 50) {
            await setRowsPerColumn(25);
            await setNoOfColumns(2);
        } else if (pResponses === 99) {
            await setRowsPerColumn(33);
            await setNoOfColumns(3);
        } else {
            await setNoOfColumns(3);
            await setRowsPerColumn(Math.ceil(noOfResponses / 3));
        }
        await setNoOfResponses(pResponses);
        setFlgAnswerSheet(true);
    }

    function cbLogoResponse(imageUrl) {
        // console.log("cbLogoResponse called, imageUrl", imageUrl);
        setLogoUrl(imageUrl)
    }

    return (
        <div style={{ height: '100%' }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '40%', top: '40%', display: loader }} >
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div >
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="col-sm-11 col-centered padding-remove" style={{ pointerEvents: loader === 'none' ? 'auto' : 'none', marginTop: "15px" }}>
                <div className="col-sm-6 col-md-3" style={{ marginTop: "10vh" }}>
                    <div className="col-sm-12 padding-remove">
                        <button type="button" className="btn btn-info " style={{ width: "80%", marginTop: "20px" }} onClick={(e) => printAnswerSheet(30)}>Print AnswerSheet 30</button>
                    </div>
                    <div className="col-sm-12 padding-remove">
                        <button type="button" className="btn btn-info " style={{ width: "80%", marginTop: "20px" }} onClick={(e) => printAnswerSheet(50)}>Print AnswerSheet 50</button>
                    </div>
                    <div className="col-sm-12 padding-remove">
                        <button type="button" className="btn btn-info " style={{ width: "80%", marginTop: "20px" }} onClick={(e) => printAnswerSheet(99)}>Print AnswerSheet 99</button>
                    </div>
                </div >
                {
                    flgAnswerSheet ?
                        <div className="col-sm-9 padding-remove" style={{ border: "1px solid #ccc", height: "80vh" }} >
                            <AnswerSheedPDF
                                logoUrl={logoUrl}
                                noOfResponses={noOfResponses}
                                rowsPerColumn={rowsPerColumn}
                                noOfColumns={noOfColumns}
                            />
                        </div >
                        : null
                }
            </div >
        </div >
    );
}

export default AnswerSheet;