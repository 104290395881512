import React, { useState, useEffect, useMemo } from "react";
import '../css/EnquiryCtrl.css'
import { getTestListSvc } from "./ScheduleTestSvc";

const TestTable = (props) => {

  const [lstTest, setLstTest] = useState([]);
  const [lstTestSlotId, setLstTestSlotId] = useState([]);
  const [lstSelTestId, setLstSelTestId] = useState([]);
  const [selTestId, setSelTestId] = useState(0)

  function cbTestListResponse(pLstTest) {
    setLstTest(pLstTest);
    // console.log("Fetching Test List");
  }
  useEffect(() => {
    if (props.enquiryId && props.clsId) {
      getTestListSvc(cbTestListResponse, props.enquiryId, props.clsId);
    }
  }, []);

  function selectLstTest(testId, pTestSlotId, status) {
    // console.log('Clicked on checkbox::' + testId + '-' + pTestSlotId + '-' + status);
    var lstTestSlotIdTemp = [];
    if (status && !lstTestSlotId.includes(pTestSlotId)) {
      // lstTestSlotIdTemp = lstTestSlotId;
      lstTestSlotIdTemp.push(pTestSlotId)
      setLstTestSlotId(lstTestSlotIdTemp);
      // console.log("testSlotId array  " + lstTestSlotIdTemp);
    }
    else {
      // lstTestSlotIdTemp = lstTestSlotId;
      lstTestSlotIdTemp.splice(lstTestSlotIdTemp.indexOf(pTestSlotId), 1);
      setLstTestSlotId(lstTestSlotIdTemp);
      // console.log("Selected Test Slot Id " + lstTestSlotIdTemp)
    }
    var lstTempArr = [];
    if (status && !lstSelTestId.includes(testId)) {
      lstTempArr.push(testId);
      setLstSelTestId(lstTempArr);
      // console.log("checked  " + lstTempArr);

    } else {
      lstTempArr.splice(lstTempArr.indexOf(testId), 1);
      setLstSelTestId(lstTempArr);
      // console.log("now checked " + lstTempArr);
    }
    var p2 = new Promise(function (resolve, reject) {
      resolve(returnSelTestId(lstTempArr));
    });

    p2.then(function () {
      returnSelTestSlotId(lstTestSlotIdTemp);
    });
  }
  const returnSelTestId = async (pLstSelTestId) => {
    // console.log("setSelectedTestId triggered in TestTable::" + pLstSelTestId);
    await props.setSelectedTestId(pLstSelTestId);
  }

  const returnSelTestSlotId = async (pLstTestSlotId) => {
    // console.log("setSelectedTestSlotId triggered in TestTable::" + pLstTestSlotId);
    await props.setSelectedTestSlotId(pLstTestSlotId);
  }
  function handleSelTestId(param) {
    setSelTestId(param)

  }
  const colgrp = (
    <colgroup>
      <col width="8%"></col>
      <col width="5%"></col>
      <col width="5%"></col>
      <col width="5%"></col>
      <col width="5%"></col>
      <col width="8%"></col>
      <col width="5%"></col>
      <col width="8%"></col>
      <col width="8%"></col>
      <col width="5%"></col>
      <col width="8%"></col>
      <col width="5%"></col>

    </colgroup>
  );
  return (
    <div>
      <table className='tableLayout1'><thead><tr><th>Test List</th></tr></thead></table>
      <table className="tableLayout1">

        {colgrp}
        <thead>
          <tr>
            <th>Test</th>
            <th>Passing<br /> Score</th>
            <th>Max<br /> Score</th>
            <th>Duration (Mins)</th>
            <th>Mandatory</th>
            <th>Test<br />Date</th>
            <th>Slot<br />Id</th>
            <th>Candidate<br />Informed On</th>
            <th>Evaluator<br />Informed On</th>
            <th>Result</th>
            <th>Status</th>
            <th>Select</th>
          </tr>
        </thead>
      </table>
      <div className="tablebodyWrapper">
        <table className="tableLayout">
          {colgrp}
          <tbody>
            {lstTest.map((test, idx) => {
              return (
                <tr onClick={(e) => handleSelTestId(test.testId)} className={test.testId === selTestId ? "selected" : ""} key={'t_' + idx}>
                  <td>{test.test}</td>
                  <td>{test.passScore}</td>
                  <td>{test.maxScore}</td>
                  <td>{test.duration}</td>
                  <td>{test.mandatory ? "Yes" : "-"}</td>
                  <td>{test.slotDt}</td>
                  <td>{test.slotId > 0 ? test.slotId : "-"}</td>
                  <td>{test.candInformDt}</td>
                  <td>{test.evalInformDt}</td>
                  <td>{test.result}</td>
                  <td>{test.status}</td>
                  <td>
                    <input
                      type="checkbox"
                      onClick={(e) =>
                        selectLstTest(test.testId, test.testSlotId, e.target.checked)
                      }
                    /* onClick={(e) =>
                      selectLstTestSlotId(test.testSlotId, e.target.checked)
                    } */
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default TestTable;
