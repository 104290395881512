import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "../css/studentTest.css";
import { testTimerSvc, testStartedSvc } from "./testInstructionSvc";
import InstructionText from "./testInstructionText";
import InstructionTimer from "./testInstructionTimer";
import InstructionStudents from "./testInstructionStudents";


const TestInstruction = () => {

    const params = new URLSearchParams(window.location.search);
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [studentId, setStudentId] = useState(0);
    const [testId, setTestId] = useState(0);
    const [schoolId, setSchoolId] = useState(0);
    const [minToStart, setMinToStart] = useState(null);
    const [secondsTostart, setSecondsTostart] = useState(null);
    const [noOfQuestions, setNoOfQuestions] = useState(0);
    const [lstStudent, setLstStudent] = useState([]);
    const [leaderboardCount, setLeaderboardCount] = useState(0);

    useEffect(() => {
        // console.log("TestInstruction called");
        const sId = params.get("sId");
        const tId = params.get("tId");
        const schId = params.get("schId");
        const rankers = params.get("rankers");
        setStudentId(sId);
        setTestId(tId);
        setSchoolId(schId);
        getTestTimer(tId, schId);
        callCheckTestStarted(tId, sId, schId, rankers);
        setLeaderboardCount(rankers);
        // handleTestStart(tId, sId);
    }, []);

    const callCheckTestStarted = (pTestId, pStudentId, pSchoolId, rankers) => {
        // console.log("callCheckTestStarted called, pTestId", pTestId, ", pStudentId", pStudentId);
        const interval = setInterval(() => {
            checkTestStarted(pTestId, pStudentId, pSchoolId, rankers);
        }, 500);
        return () => clearInterval(interval);
    }

    const getTestTimer = (pTestId, pSchoolId) => {
        // console.log("getTestTimer called, pTestId", pTestId);
        setLoader("block");
        setOpacity(0.5);
        testTimerSvc(pTestId, pSchoolId, testTimerResponse);
    }

    const testTimerResponse = (data) => {
        // console.log("testTimerResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            setMinToStart(obj.minToStart);
            setSecondsTostart(obj.secondsTostart);
            setNoOfQuestions(obj.noOfQuestions);
        }
        else {
            toast.error(obj.message);
        }
        setLoader('none');
        setOpacity(1);
    }

    const checkTestStarted = (pTestId, pStudentId, pSchoolId, rankers) => {
        // console.log("checkTestStarted called, pTestId", pTestId, ", pStudentId", pStudentId);
        // setLoader("block");
        // setOpacity(0.5);
        testStartedSvc(pTestId, pStudentId, pSchoolId, rankers, checkTestStartedResponse);
    }

    const checkTestStartedResponse = (data) => {
        // console.log("checkTestStartedResponse called", data);
        let obj = data;
        if (obj.status === 'Success') {
            if (obj.testStarted) {
                handleTestStart(obj.testId, obj.studentId, obj.schoolId, obj.rankers);
            } else {
                setLstStudent(obj.lstStudent);
            }
        }
        else {
            toast.error(obj.message);
        }
        // setLoader('none');
        // setOpacity(1);
    }
    const handleTestStart = (tId, sId, schId, rankers) => {
        // console.log("handleTestStart called, tId, sId", tId, sId);
        const newPageUrl = "/testQuestion?tId=" + tId + "&sId=" + sId + "&schId=" + schId + "&rankers=" + rankers ;
        window.location.href = newPageUrl;
    }

    return (
        <div>
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="instruction-page">
                <div className="col-sm-11 col-xs-12 col-centered" style={{ marginTop: "2vh", pointerEvents: loader === 'none' ? 'auto' : 'none' }}>
                    <div className="col-sm-12 padding-remove" style={{ minHeight: "100px" }}>
                        <div className="col-sm-10 col-xs-12 padding-remove">
                            <InstructionText
                                noOfQuestions={noOfQuestions}
                            />
                        </div>
                        <div className="col-sm-2 timer-div padding-remove">
                            <InstructionTimer
                                secondsTostart={secondsTostart}
                                minToStart={minToStart}
                            />
                        </div>
                    </div>
                    <div className="col-sm-10 padding-remove">
                        <div className="joined-student-section">
                            <InstructionStudents
                                key={lstStudent}
                                lstStudent={lstStudent}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
export default TestInstruction;