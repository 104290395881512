import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../css/question.css';
import '../css/studentTest.css';

const Question = (props) => {

    const { questionId, question } = props;

    useEffect(() => {
        // console.log("Question called");
        // console.log("questionId", questionId);
        // console.log("question", question);
    }, []);

    return (
        <div className="col-sm-12 col-xs-12 padding-remove">
            <div className="question-no-section" >
                Question {!!question && !!question.questionNo ? question.questionNo : ""}
            </div>
            {
                !!question ?
                    <div className="question-section" >
                        {
                            !!question && !!question.question ?
                                <div className="question-text" >
                                    {question.question}
                                </div>
                                : null
                        }
                        {
                            !!question && question.questionImage !== null ?
                                <div className="question-image">
                                    <div className="question-img">
                                        <img src={question.questionImage} alt={""} />
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    : null
            }
        </div>
    );
}

export default Question;