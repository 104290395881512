import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { getLstModeSvc, settlePaymentSvc } from './SettlementSliderSvc'
import 'react-datepicker/dist/react-datepicker.css';
import Datepicker from 'react-datepicker'

function SettlementSlider(props) {

    const [schoolId, setSchoolId] = useState(props.schoolId);
    const [settlementDt, setSettlementDt] = useState('')
    const [amt, setAmt] = useState(0)
    const [lstSettlement, setLstSettlement] = useState([])
    const [lstMode, setLstMode] = useState([])
    const [modeId, setModeId] = useState(0)
    const [mode, setMode] = useState('')
    const [refNo, setRefNo] = useState('')
    const [remarks, setRemarks] = useState('')
    const [loader, setLoader] = useState("none");
    useEffect(() => {
        setLstSettlement(props.lstSelSettlement)
        setSchoolId(props.schoolId)
        getLstModeSvc(cbGetLstModeResponse)
    }, [])
    function cbGetLstModeResponse(data) {
        // console.log("cbGetLstModeResponse method called in SettlementSlide, data", data);
        if (data.status === "Success") {
            setLstMode(data.lstPaymentMode)
            // console.log("data in cbGetLstModeResponse in SettlementSlide", data);
        } else {
            if (data.message === "You are not logged in") {
                localStorage.setItem("isRememberMeSelected", false);
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else if (data.message === "Your Session is expired, login again") {
                toast.error(data.SvcMsg);
                // console.log("session expired");
                localStorage.setItem("isRememberMeSelected", false);
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else {
                toast.error(data.message);
            }
        }
        setLoader('none')
    }
    function handleRefNoChange(param) {
        setRefNo(param)
    }
    function handleModeChange(param) {
        // console.log(param)
        setModeId(param)
    }
    function handleAmtChange(param) {
        setAmt(param)
    }
    function handleSettlementDtChange(param) {
        // console.log(param)
        if (param) {
            var m = param.getMonth() + 1;
            if (m < 10) {
                m = "0" + m;
            }
            var d = param.getDate();
            if (d < 10) {
                d = "0" + d;
            }
            const dt = d + "-" + (m) + "-" + param.getFullYear()
            // console.log(dt);
            setSettlementDt(dt)
        }
    }
    function handleRemarksChange(param) {
        setRemarks(param)
    }

    function getDateForCalendar(strDt) {
        if (!strDt)
            return null;
        var parts = strDt.split('-')
        var d = parts[0];
        var m = parts[1] - 1;
        var y = parts[2];
        // console.log(new Date(y, m, d))
        return new Date(y, m, d);
    }

    function settlePayment() {
        if (settlementDt == '') {
            toast.warn("Please select the settlement date")
            return;
        }
        if (amt == 0) {
            toast.warn("Please enter the settlment amount");
            return;
        }
        if (modeId == 0) {
            toast.warn("Please select the mode")
            return;
        }
        if (refNo == '') {
            toast.warn("Please enter the reference No")
            return;
        }
        setLoader("block");
        const settlementObj = {
            // schoolId: schoolId,
            lstSettlement: lstSettlement,
            modeId: modeId,
            remarks: remarks,
            dt: settlementDt,
            amount: amt,
            refNo: refNo,
            schoolId:schoolId

        }
        var formData = new FormData();
        formData.append("settlement", JSON.stringify(settlementObj))
        setLoader("block")
        settlePaymentSvc(formData, cbSettlePaymentResponse)

    }

    function cbSettlePaymentResponse(data) {
        setLoader("none");
        if (data.status === 'Success') {
            toast.success(data.SvcMsg)
            props.generateRandomNo()
        }
        else {
            if (data.message === "You are not logged in") {
                localStorage.setItem("isRememberMeSelected", false);
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else if (data.message === "Your Session is expired, login again") {
                toast.error(data.message);
                // console.log("session expired");
                localStorage.setItem("isRememberMeSelected", false);
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else {
                toast.error(data.message);
            }
        }
    }
    return (
        <form
            className="form form-horizontal"
            style={{
                pointerEvents: loader === "none" ? "auto" : "none",
                width: '50%',
                height: '100%',
                overflowY: 'scroll',
                zIndex: 999,
                position: 'fixed',
                right: 0,
                top: 0,
                backgroundColor: '#fff',
                transition: 'all .3s ease-in-out',
                border: '1px solid #ccc'
            }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="form-group col-sm-12">
                <label className="control-label col-md-3 text-left">Date:<span className="red-color">*</span></label>
                <div className='col-sm-6 padding-remove'>
                    <Datepicker
                        type='date'
                        className="form-control"
                        selected={getDateForCalendar(settlementDt)}
                        onChange={handleSettlementDtChange}
                        dateFormat="dd-MM-yyyy"
                        showYearDropdown
                        showMonthDropdown
                    ></Datepicker>
                </div>
            </div>
            <div className="form-group col-sm-12">
                <label className="control-label col-md-3 text-left">Amount:<span className="red-color">*</span></label>
                <div className='col-sm-6 padding-remove'>
                    <input type='text' className="form-control" defaultValue={amt} onChange={(e) => handleAmtChange(e.target.value)} ></input>
                </div>
            </div>
            <div className="form-group col-sm-12" >
                <label className="control-label col-md-3 text-left">Mode<span className="red-color">*</span></label>
                <div className='col-sm-6 padding-remove'>
                    <select onChange={(e) => handleModeChange(e.target.value)} className="form-control inp-search" value={modeId !== "" && modeId} id="cmbMode">
                        <option value={0}>Mode</option>
                        {lstMode && lstMode.length !== 0 && lstMode.map((obj, idx) => {
                            return <option key={idx} value={obj.modeId}>{obj.mode}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className="form-group col-sm-12" >
                <label className="control-label col-md-3 text-left">Reference No.<span className="red-color">*</span></label>
                <div className='col-sm-6 padding-remove'>
                    <input type='text' className="form-control" defaultValue={refNo} onChange={(e) => handleRefNoChange(e.target.value)} ></input>
                </div>
            </div>

            <div className="form-group col-sm-12">
                <label className="control-label col-sm-3 text-left">Remarks</label>
                <div className='col-sm-6 padding-remove'>
                    <textarea rows="8"
                        cols="80" maxLength={500} defaultValue={remarks} className="form-control" onChange={(e) => handleRemarksChange(e.target.value)}></textarea>
                </div>
            </div>

            <div className="form-group col-md-12">
                <div className="col-md-offset-6 col-md-1 padding-remove">
                    <button type="button" className="btn btn-info" onClick={(e) => settlePayment()}>Save</button>
                </div>
            </div>


        </form>)
}
export default SettlementSlider;
