import React, { useState, useEffect } from "react";
import Header from "common/js/header/header";
import 'common/css/master.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CommunicationGroupsTable from "./commGroupsTable";
import CommunicationGroupSlider from "./commGroupsSlider";
import CommunicationGroupsStudent from "./commGroupStudents";

const CommunicationGroups = () => {

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [isGroupSliderOpen, setIsGroupSliderOpen] = useState(false);
    const [isGroupStudentSliderOpen, setIsGroupStudentSliderOpen] = useState(false);
    const [selGroupId, setSelGroupId] = useState(0);
    const [selGroupData, setSelGroupData] = useState({
        "commGroupName": "",
        "commGroupId": 0,
        "studentsCount": 0,
        "createdDttm": "",
    });
    const [tableKey, setTableKey] = useState(0);


    useEffect(() => {
        // console.log("CommunicationGroups called");
    }, []);



    const addGroup = () => {
        // console.log("addGroup called");
        setSelGroupId(0);
        setSelGroupData({
            "commGroupName": "",
            "commGroupId": 0,
            "studentsCount": 0,
            "createdDttm": "",
        });
        openGroupSlider();
    }


    const openGroupSlider = () => {
        // console.log("openGroupSlider called, selGroupId", selGroupId);
        setIsGroupStudentSliderOpen(false);
        setIsGroupSliderOpen(true);
    }
    
    const openGroupStudents = () => {
        // console.log("openGroupStudents called");
        setIsGroupSliderOpen(false);
        setIsGroupStudentSliderOpen(true);
    }
    
    const closeSliders = () => {
        // console.log("closeSliders called");
        setIsGroupStudentSliderOpen(false);
        setIsGroupSliderOpen(false);
        updateKey();
    }

    const updateKey = () => {
        // console.log("updateKey called");
        setTableKey((prevKey)=> prevKey+1);
    }
    

    return (
        <div>
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="mainContainer" style={{opacity:opacity}}>
                <div className="col-xs-10 pull-right padding-remove">
                    <div className="col-xs-10 padding-remove">
                        <CommunicationGroupsTable
                            tableKey={tableKey}
                            setLoader={setLoader}
                            setOpacity={setOpacity}
                            openGroupSlider={openGroupSlider}
                            openGroupStudents={openGroupStudents}
                            setSelGroupData={setSelGroupData}
                            setSelGroupId={setSelGroupId}
                        />
                    </div>
                    <div className="col-xs-2 padding-remove " style={{ textAlign: "right" }}>
                        <div
                            className='col-sm-1'
                            onClick={() => addGroup()}
                            title='Create Communication Group'
                        >
                            <span
                                title='Create Communication Group'
                                className='addIcon glyphicon glyphicon-plus-sign'
                            ></span>
                        </div>
                    </div>
                </div>
            </div>
            {isGroupSliderOpen ?
                <div className='slider1-60'>
                    <CommunicationGroupSlider
                        selGroupId={selGroupId}
                        selGroupData={selGroupData}
                        closeSlider={closeSliders}
                    />
                </div>
                : null
            }
            {isGroupStudentSliderOpen ?
                <div className='slider1-80'>
                    <CommunicationGroupsStudent
                        selGroupId={selGroupId}
                        closeSlider={closeSliders}
                    />
                </div>
                : null
            }
        </div>
    );
}

export default CommunicationGroups;