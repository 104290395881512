import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import Header from "common/js/header/header.js";
import Loader from "common/images/loader.gif"; // Adjusted import for loader image

import SchoolProfileTab from "./schoolProfileTabs.js";
import SchoolAddress from "./schoolAddress";
import SchoolLogo from "./schoolLogo";
import SchoolFeeBook from "./schoolFeeBook";
import SchoolReportCard from "./schoolReportCard";
import SchoolIdCard from "./schoolIdCard";
import SchoolParentApp from "./schoolParentApp";
import SchoolEmail from "./schoolEmail";
import SchoolLocationCoordinates from "./schoolLocationCoordinates";

import "../css/schoolProfile.css";
import {
  getLstState,
  getParentAppDtlsSvc,
} from "./schoolProfileSvc.js";


export default function SchoolProfile() {
  const [lstState, setLstState] = useState([]);
  const [loader, setLoader] = useState("none");
  const [opacity, setOpacity] = useState(1);
  const [tabId, setTabId] = useState("");

  const mapTabs = {
    addr: "Address",
    logo: "Logo",
    feeBook: "Fee Book",
    reportCard: "Report Card",
    idCard: "Id Card",
    parentApp: "Parent App",
    email: "e-Mail",
    location: "Location Coordinates",
  };

  useEffect(() => {
    console.log("SchoolProfile component called");
    showLoader();
    setTabId("addr");
    getLstState(handleLstStateResponse);
  }, []);

  function showLoader() {
    setLoader("block");
    setOpacity(0.5);
  }

  function hideLoader() {
    setLoader("none");
    setOpacity(1);
  }

  function handleResponse(data) {
    hideLoader();
    if (data.status === "Success") {
      return data;
    } else if (data.message === "You are not logged in" || data.message === "Your Session is expired, login again") {
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
      return false;
    } else {
      toast.error(data.message);
      return null;
    }
  }

  function handleLstStateResponse(data) {
    const response = handleResponse(data);
    if (response) {
      setLstState(response.lstState);
    }
  }

  return (
    <div style={{ height: "100%" }}>
      <Header />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img className="loderImg" src={Loader} alt="Loader..." />
      </div>

      <div className="col-xl-12 col-ls-12 col-md-12 col-sm-12 col-xs-12 padding-remove"
        style={{
          cursor: (loader === "none") ? 'pointer' : 'none',
          pointerEvents: (loader === "none") ? 'auto' : 'none',
          opacity: opacity
        }}
      >
        <div className="col-xl-12 col-ls-12 col-md-12 col-sm-12 col-xs-12 padding-remove" style={{ margin: "15px 0" }}>
          <SchoolProfileTab mapTabs={mapTabs} tabId={tabId} setTabId={setTabId} />
        </div>

        <div className="mainContainer" style={{ opacity }}>
          <div className="col-sm-6 col-centered">
            {(() => {
              switch (tabId) {
                case 'addr':
                  return <SchoolAddress lstState={lstState} />;
                case 'logo':
                  return <SchoolLogo />;
                case 'feeBook':
                  return <SchoolFeeBook lstState={lstState} />;
                case 'reportCard':
                  return <SchoolReportCard />;
                case 'idCard':
                  return <SchoolIdCard />;
                case 'parentApp':
                  return <SchoolParentApp />;
                case 'email':
                  return <SchoolEmail />;
                case 'location':
                  return <SchoolLocationCoordinates />;
                default:
                  return <SchoolAddress lstState={lstState} />;
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
}
