import React, { useState, useEffect, useRef } from 'react'

const MonthlyStudentFeeDtls = props => {
  const [state, setState] = useState({
    pymt: 0,
    feeHeadId: 0,
    lstMonthlyStudentFeeDtls: props.lstMonthlyStudentFeeDtls
  })
  const [lstMonthlyStudentFeeDtls, setLstMonthlyStudentFeeDtls] = useState(
    props.lstMonthlyStudentFeeDtls
  )
  var tFee = 0,
    tDiscount = 0,
    tNetFee = 0,
    tDue = 0,
    tPayment = 0
  const [totalFee, setTotalFee] = useState(0)
  const [totalDiscount, setTotalDiscount] = useState(0)
  const [totalNetFee, setTotalNetFee] = useState(0)
  const [totalDue, setTotalDue] = useState(0)
  const [totalPayment, setTotalPayment] = useState(0)
  const isInitialMount = useRef(true)

  useEffect(() => {
    setLstMonthlyStudentFeeDtls(props.lstMonthlyStudentFeeDtls)
    setPayment()
  }, [])
  const setPayment = () => {
    var feeNodes = document.querySelector('.fee')
    for (let i = 0; i < lstMonthlyStudentFeeDtls.length; i++) {
      var obj = lstMonthlyStudentFeeDtls[i]
      tFee += parseInt(obj.fee)
      tDiscount += parseInt(obj.discountAmt)
      tNetFee += parseInt(obj.netFee)
      tDue += parseInt(obj.feeHeadAmtDue)
      tPayment += parseInt(obj.feeHeadAmtDue)
      // console.log('for tPayment::' + tPayment)
    }
    setTotalFee(tFee)
    setTotalDiscount(tDiscount)
    setTotalNetFee(tNetFee)
    setTotalDue(tDue)
    // setTotalPayment(tPayment);
  }
  const handleDues = async (
    e,
    id,
    billNo,
    studentId,
    monthId,
    feeHeadId,
    i
  ) => {
    // console.log('totalPayment = ' + totalPayment)
    var selected = document.getElementById(
      'dueCbox_' + studentId + '_' + monthId + '_' + feeHeadId
    ).checked
    var pymt = lstMonthlyStudentFeeDtls[i].feeHeadAmtDue
    pymt = parseInt(isNaN(pymt) ? 0 : pymt)
    // console.log('pymt::' + pymt + ' ::typeOF pymt::' + typeof pymt)
    var lstData = lstMonthlyStudentFeeDtls

    if (selected) {
      var temp = totalPayment + pymt
      await setTotalPayment(temp)
      lstData[i].amt = pymt
      lstData[i].selected = true
      // console.log('IFpymt::' + temp + ' ::typeOF pymt::' + typeof temp)
      document.getElementById(id).value =
        lstMonthlyStudentFeeDtls[i].feeHeadAmtDue
    } else {
      lstData[i].selected = false
      lstData[i].amt = 0
      document.getElementById(id).value = 0
      var temp = totalPayment - pymt
      // console.log('ELSEpymt::' + temp + ' typeOF pytm::' + typeof temp)
      await setTotalPayment(temp)
    }

    setLstMonthlyStudentFeeDtls(lstData)
    await props.updatePayment(totalPayment, props.studentId, billNo, lstData)
    document.getElementById(id).focus()
  }
  const handlePay = async (e, id, fhId, l) => {
    var pymt = parseInt(e.target.value)
    var lstData = lstMonthlyStudentFeeDtls

    if (isNaN(pymt) || pymt === 0) {
      lstData[l].selected = false
      pymt = 0
    } else {
      lstData[l].selected = true
    }
    lstData[l].amt = pymt
    var tot = 0
    for (var k = 0; k < lstData.length; k++) {
      var obj = lstData[k]
      if (obj.selected) {
        tot += parseInt(isNaN(obj.amt) ? 0 : obj.amt)
      }
    }
    setLstMonthlyStudentFeeDtls(lstData)
    await setTotalPayment(tot)
    await props.updatePayment(
      totalPayment,
      props.studentId,
      props.month,
      lstData
    )
    document.getElementById(id).focus()
  }

  function closeClick () {
    // console.log('lstMonthlyStud::' + JSON.stringify(lstMonthlyStudentFeeDtls))
    // props.updatePayment(totalPayment, props.studentId, props.month, lstMonthlyStudentFeeDtls);
    props.closePopup()
    // props.handlePayment(props.studentId + '_' + props.month);
  }

  return (
    <div>
      <button
        type='button'
        className='btn btn-info pull-right'
        onClick={() => closeClick()}
      >
        <span
          id='close'
          title='Close'
          className='glyphicon glyphicon-remove'
        ></span>
      </button>
      <h6 className='text-left'>Receipt No.: {props.month}</h6>
      <table className='tableLayout1'>
        <thead>
          <tr>
            <th style={{ width: 40 }}> Fee Head</th>
            <th style={{ width: 40 }}> Month </th>
            <th style={{ width: 40 }}> Label </th>
            <th style={{ width: 40 }}> Fee</th>
            <th style={{ width: 40 }}> Discount</th>
            <th style={{ width: 40 }}> Net Fee</th>
            <th style={{ width: 40 }}> Due</th>
          </tr>
        </thead>
      </table>
      <div className='tableDivSmall'>
        <table id='feeDueDtlsTable' className='tableLayout'>
          <tbody id='feeDueDtlsTableBody'>
            <tr style={{ background: '#d5dfe9', fontWeight: 'bold' }}>
              <td>Total</td>
              <td></td>
              <td></td>
              <td className='text-right'> {totalFee}</td>
              <td className='text-right'> {totalDiscount}</td>
              <td className='text-right'> {totalNetFee}</td>
              <td className='text-right'> {totalDue}</td>
              {/* <th className="text-right" style={{ width: 40 }}> {totalPayment}</th> */}
              {/* <th style={{ width: 40 }}> </th> */}
            </tr>
            {lstMonthlyStudentFeeDtls.map((feeDtls, i) => (
              <tr feeheadid={feeDtls.feeHeadId} key={feeDtls.monthId}>
                <td width='40' className='no-display'>
                  {feeDtls.feeHeadId}
                </td>
                <td key={feeDtls.feeHeadCode} width='40' className='text-left'>
                  {feeDtls.feeHeadCode}
                </td>
                <td
                  key={'mth' + feeDtls.month}
                  width='40'
                  className='text-left fee-lbl'
                >
                  {feeDtls.month}
                </td>
                <td
                  key={'lbl_' + feeDtls.label}
                  width='40'
                  className='text-left fee-lbl'
                >
                  {feeDtls.label}
                </td>
                <td
                  key={'fee_' + feeDtls.fee}
                  width='40'
                  className='text-right fee'
                >
                  {feeDtls.fee}
                </td>
                <td
                  key={'dis_' + feeDtls.discountAmt}
                  width='40'
                  className='text-right disc-amt'
                >
                  {feeDtls.discountAmt}
                </td>
                <td
                  key={'net_' + feeDtls.netFee}
                  width='40'
                  className='text-right net-due'
                >
                  {feeDtls.netFee}
                </td>
                <td
                  key={'due_' + feeDtls.feeHeadAmtDue}
                  id={`due_` + props.month + `_fh_` + feeDtls.feeHeadId}
                  width='40'
                  className='text-right fh-amt-due'
                >
                  {feeDtls.feeHeadAmtDue}
                </td>
                {/* <td width="40" className="text-center" >
                                    <input type="text" key={'pay_' + feeDtls.feeHeadId + '_' + feeDtls.amt}
                                        onKeyUp={(e) => handlePay(e, `pInp_` + props.month + `_fh_` + feeDtls.feeHeadId + '_m_' + feeDtls.monthId, feeDtls.feeHeadId, i)}
                                        className="form-control text-right pay-due"
                                        defaultValue={feeDtls.amt}
                                        id={`pInp_` + props.month + `_fh_` + feeDtls.feeHeadId + '_m_' + feeDtls.monthId} />
                                </td>
                                <td style={{ width: 13 + '%' }} className="text-center">
                                    <input type="checkbox" key={feeDtls.feeHeadId + '_' + !!(feeDtls.selected)} className="form-check-input" defaultChecked={!!(feeDtls.selected)}
                                        id={"dueCbox_" + props.studentId + '_' + feeDtls.monthId + '_' + feeDtls.feeHeadId} onClick={(e) => handleDues(e, `pInp_` + props.month + `_fh_` + feeDtls.feeHeadId + '_m_' + feeDtls.monthId, props.month, props.studentId, feeDtls.monthId, feeDtls.feeHeadId, i)}
                                    />
                                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default MonthlyStudentFeeDtls
