import { Checkbox } from '@mui/material'
import '../css/EducationSlider.css'
import React, { useEffect, useState } from 'react'
import { recruitmentEducationLstSvc,recruitmentEducationDtlsSvc,recruitmentEducationSaveSvc,recruitmentAttachmentView,recruitmentMstGradeSvc,recruitmentMstEducationSvc,recruitmentMstModeSvc,recruitmentEducationDeleteSvc,recruitmentEducationAttachmentSaveSvc,recruitmentAttachmentDeleteSvc } from './EducationSliderSvc'
import lstModules from 'common/json/lstModules.json';

var httpURL = lstModules['recruitment'].url + ":" + lstModules['recruitment'].port;

//Please create EducationTableSvc.js, EducationFormSvc.js

const EducationSlider = (props) => {
  const[lstEducation,setLstEducation] =useState([])
  const[dtlEducation,setDtlsEducation] =useState([])
  const[selectedIdx,setSelectedIdx] = useState()
  const[staffAttachmentId,setAttachmentId]=useState(0)
  const[attachmentPath,setAttachmentPath]=useState()
  const[attachment,setAttachment]=useState([])
  const[mstEducationLst,setMstEducationLst] =useState([])
  const[mstGradeLst,setMstGradeLst] =useState([])
  const[mstModeLst,setMstModeLst] =useState([])
  const[educationId,setEducationId]=useState()
  const[gradeId,setgradeId]=useState()
  const[year,setYear]=useState()
  const[college,setCollege]=useState('')
  const[modeId,setmodeId]=useState()
  const[flgNotCompleted,setFlgNotCompleted]=useState(false)
  const[qualificationId,setQualificationId]=useState(0)
  const $ = window.$;
  
  
  useEffect(()=>{
    recruitmentEducationLstSvc(cbRecruitmentEducationLstResponse,props.tchId)
    recruitmentMstEducationSvc(cbRecruitmentMstEducationResponse)
    recruitmentMstGradeSvc(cbRecruitmentMstGradeResponse)
    recruitmentMstModeSvc(cbRecruitmentMstModeResponse)
   
  
      },[props])

      function recruitmentDtls(pQualificationId){
        recruitmentEducationDtlsSvc(cbRecruitmentEducatioDtlsResponse,props.tchId,pQualificationId)
        

      }
      function saveAttachment(file,staffAttachmentId){
        recruitmentEducationAttachmentSaveSvc(cbrecruitmentEducationAttachmentSaveResponse,file,staffAttachmentId,qualificationId,props.tchId)
      
      }
      function cbrecruitmentEducationAttachmentSaveResponse(data){
        setOnuploadAttachment()
        if(data.status=="Success"){
  
          // console.log(data.message)
          
        }
          else{
            // console.log(data.message)
          }
        

      }
      function saveEducation(){
        var qualification={}
        qualification.tchId=props.tchId
        qualification.qualificationId=qualificationId
        qualification.educationId=educationId
        qualification.gradeId=gradeId
        qualification.year=year
        qualification.college=college
        qualification.modeId=modeId
        qualification.flgNotCompleted=flgNotCompleted
        var lstAttachment=[{staffAttachmentId,attachmentPath}]
        recruitmentEducationSaveSvc(cbRecruitmentEducationSaveResponse, qualification,lstAttachment)
      }
      function cbRecruitmentEducationSaveResponse(data){
        props.onGetRandNo()
  
        if(data.status=="Success"){
          
          // console.log(data.message)
          
        }
          else{
            // console.log(data.message)
          }
        
          
      }
      function cbRecruitmentEducationDeleteResponse(data){
        if(data.status=="Success"){
          setOnuploadAttachment()
          props.onGetRandNo()
          setNewForm()
  
          // console.log(data.message)
          
        }
          else{
            // console.log(data.message)
          }
        
          
      }
      function cbRecruitmentMstEducationResponse(data){
        setMstEducationLst(data)
      }
      function cbRecruitmentMstGradeResponse(data){
        setMstGradeLst(data)
      }
      function cbRecruitmentMstModeResponse(data){
        setMstModeLst(data)
      }

      function cbRecruitmentEducatioDtlsResponse(data){
        setDtlsEducation(data)
        props.onGetRandNo()
        
      }
      function cbRecruitmentAttachmentViewResponse(data){
        setAttachment(data)
        // console.log(data)
        
      }

      function cbRecruitmentEducationLstResponse(data){
        setLstEducation(data)}


        function rowClick(rec,idx){
          setQualificationId(rec.qualificationId)
          setSelectedIdx(idx)
          recruitmentDtls(rec.qualificationId)

    
        }
        function setForm(rec){
          setgradeId(dtlEducation.gradeId)
          setCollege(dtlEducation.college)
          setmodeId(dtlEducation.modeId)
          setYear(dtlEducation.year)
          setAttachment(dtlEducation.lstAttachment)
          setFlgNotCompleted(dtlEducation.flgNotCompleted)
         
         
          
          // console.log(dtlEducation.lstAttachment)



        }
        function setOnuploadAttachment(){
          recruitmentEducationDtlsSvc(cbRecruitSetAttchmentResponse,props.tchId,qualificationId)
          
        }
        function cbRecruitSetAttchmentResponse(data){
          setAttachment(data.lstAttachment)

        }
        function setNewForm(){

         
          setgradeId('')
          setCollege('')
          setmodeId('')
          setYear('')
          setFlgNotCompleted(false)
          setQualificationId(0)
          



        }
        function deleteQualification(pQualificationId){
         recruitmentEducationDeleteSvc(cbRecruitmentEducationDeleteResponse,props.tchId,pQualificationId)
        }
        function uploadImg(event,staffAttachmentId){
          
              if (!event) return;
              var files = event; //FileList object
              // console.log(event)
              setAttachmentPath(files[0]);
              // console.log(staffAttachmentId)
              setAttachmentId(staffAttachmentId)

              saveAttachment(files[0],staffAttachmentId)
              
          
      }
      function cbRecruitmentAttachmentDeleteResponse(data){
        setOnuploadAttachment()
        if(data.status=="Success"){
          props.onGetRandNo()
          setOnuploadAttachment()
  
          // console.log(data.message)
          
        }
          else{
            // console.log(data.message)
          }
        
          
      }
      


    return (
        <div className='container' >
        <div className="col-sm-6" >
        <div className="col-md-1  col-xs-1 pull-right" style={{ top:'20px'  }} >
                <span className="addIcon glyphicon glyphicon-plus-sign" onClick={setNewForm} ></span></div>
                
            <div className='tablebodyWrapper'>
                <table className='tableLayout' key={props.onGetRandNo}>
                    <thead>
                    <tr>
              
              <th style={{ width: 125 }}> 
              Education
              </th>
              <th  style={{ width: 100 }}>
                Year
                </th>
              <th style={{ width: 75 }}>
             Action
              </th>
              

            </tr>
                    </thead>
                    <tbody>
                    {lstEducation.map((rec,idx) =>( 
                <tr  className={idx === selectedIdx ? 'selected' : ''} key={idx} onMouseOver={(event)=>rowClick(rec,idx)}>
                <td>{rec.qualification}</td>
                <td>{rec.year}</td>
                <td width="100" className="text-center">
                                        <span onClick={() => setForm(rec)}
                                            className="glyphicon glyphicon-edit">
                                        </span>
                                        <span
                                            onClick={() => deleteQualification(rec.qualificationId)}
                                            className="glyphicon glyphicon-trash" >
                                        </span>
                                    </td>
                </tr>
                ))}

                    </tbody>
                </table>
                </div>
                </div>
                <div className='col-sm-4'>
                <form className='form form-horizontal' style={{ width: '120%', overflowX: 'hidden', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 ,marginBottom:'10px'}}>
                <div className='EnquiryHead col-sm-12 col-centered' style={{
                    border: "1px solid #aaa", borderTopLeftRadius: 10,
                    borderTopRightRadius: 10, borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5, fontWeight: 'bold'
                }}><h4>Education Details</h4></div>
                <div className="form-group col-md-12">
              <label className='control-label col-md-5'  > Education </label>
            <div className="col-sm-6">
            <select className="form-control" value={educationId} onChange={(event)=>setEducationId(event.target.value)}   >
            {mstEducationLst && mstEducationLst.length!==0 && mstEducationLst.map((obj,idx)=>{
              return <option key={idx} value= {obj.educationId}>{obj.education}</option>})}
            
        
              </select>
          </div>
          </div>
          <div className="form-group col-md-12">
              <label className='control-label col-md-5'> Grade </label>
            <div className="col-sm-6">
            <select className="form-control"  value={gradeId} onChange={(event)=>setgradeId(event.target.value)}    >
            {mstGradeLst && mstGradeLst.length!==0 && mstGradeLst.map((obj,idx)=>{
              return <option key={idx} value= {obj.gradeId}>{obj.grade}</option>})}
            
        
              </select>
          </div>
          </div>
          <div className="form-group col-md-12">
              <label className='control-label col-md-5'> Year </label>
            <div className="col-sm-6">
            <input className="form-control"  value={year} onChange={(event)=>setYear(event.target.value)}     >
            
        
              </input>
          </div>
          </div>
          <div className="form-group col-md-12">
              <label className='control-label col-md-5'> College/university </label>
            <div className="col-sm-6">
            <input className="form-control"  value={college} onChange={(event)=>setCollege(event.target.value)}     >
            
        
              </input>
          </div>
          </div>
          <div className="form-group col-md-12">
              <label className='control-label col-md-5'> Mode </label>
            <div className="col-sm-6">
            <select className="form-control"  value={modeId} onChange={(event)=>setmodeId(event.target.value)}     >
            {mstModeLst && mstModeLst.length!==0 && mstModeLst.map((obj,idx)=>{
              return <option key={idx} value= {obj.modeId}>{obj.mode}</option>})}

              </select>
          </div>
          </div>
          <div className="form-group col-md-12">
              <label className='control-label col-md-5'> Not completed </label>
            <div className="col-sm-6">
            <input type='checkbox' className="form-control"  checked={flgNotCompleted} onChange={(event)=>setFlgNotCompleted(event.target.checked)}     >
            
        
              </input>
          </div>
          </div>
          <div className="col-sm-6" style={{marginLeft:'90px', marginBottom:'10px'}} >
              <button type="button" className="btn btn-info " id="assignCounsellor "  onClick={saveEducation} >Save</button>
            </div>
                </form>
                
                <div className='EnquiryHead col-sm-12 col-centered' style={{
                    border: "1px solid #aaa", borderTopLeftRadius: 10,
                    borderTopRightRadius: 10, borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5, fontWeight: 'bold', width: '120%'
                    
                }}><h4></h4>Attachments</div>
                <div className="row-sm-8" style={{ width: '120%', overflowX: 'hidden', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 , overflowY:'scroll' ,height:'250px'}}>
               
                <div className="form-group col-md-12"></div>
                {attachment.map((rec,idx) =>( 
  <div className="custom-file-upload fas"  >
 
    <div className="img-wrap img-upload col-sm-4 padding-remove" key={idx}>
    <label>
    <span className="glyphicon glyphicon-edit edit-label"   htmlFor="photo-upload1"  >
    <input autoComplete="off" id="photo-upload1" onChange={(event)=>uploadImg(event.target.files,rec.staffAttachmentId)} type="file"  className="form-control " />
    </span>
  
    </label>
    <label>
    <button onClick={(event)=>recruitmentAttachmentDeleteSvc(cbRecruitmentAttachmentDeleteResponse, rec.staffAttachmentId)} className="glyphicon glyphicon-trash delete-label"  ></button>
    </label>
    {rec.contentType==="pdf"
      ? <iframe  height="100%" width="100%" src={ httpURL + "/sms/recruitment/attachment/viewimg?staffAttachmentId="+rec.staffAttachmentId}/>:
      <img   for="photo-upload" src={ httpURL + "/sms/recruitment/attachment/viewimg?staffAttachmentId="+rec.staffAttachmentId}/>
      }

    </div>
    
    </div>))}
    

    <label htmlFor="photo-upload" className="custom-file-upload fas">

 <div className="img-wrap img-upload" >
   <img for="photo-upload" src={require('./upload.svg')}/>
 </div>
 <input autoComplete="off" id="photo-upload"  onChange={(event)=>uploadImg(event.target.files,0)} type="file" className="form-control " />
 </label>

  
 
  </div>  </div>
        </div>
    )
}

export default EducationSlider