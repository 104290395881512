import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['students'].url + ':' + lstModules['students'].port;
const tokenid = localStorage.getItem('tokenid');
const schoolId = localStorage.getItem("schoolId");
var queryString = require("querystring");


export const getCityListSvc = (stateId, callback) => {
    const responseObj = { status: "", message: "", error: "" };
    axios({
        url: `${httpURL}/sms/mst/city/list`,
        method: 'POST',
        data: queryString.stringify({ schoolId, stateId }),
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // console.log("response in getCityListSvc , response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            if (response.data.SvcStatus === "Success") {
                responseObj.lstCity = response.data.lstCity;
            }
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};


export const saveStudentHistorySvc = (studentHistory, callback) => {
    const responseObj = { status: "", message: "", error: "" };
    axios({
        url: `${httpURL}/sms/student/save_history`,
        method: 'POST',
        data: queryString.stringify({ studentHistory: JSON.stringify(studentHistory) }),
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // console.log("response in saveStudentHistorySvc , response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};


export const saveStudentMedicalSvc = (studentMedical, callback) => {
    const responseObj = { status: "", message: "", error: "" };
    axios({
        url: `${httpURL}/sms/student/save_medical`,
        method: 'POST',
        data: queryString.stringify({ studentMedical: JSON.stringify(studentMedical) }),
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // console.log("response in saveStudentMedicalSvc , response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};
