import lstModules from 'common/json/lstModules.json';
import axios from 'axios';


const screenId = 33;
const tokenid = localStorage.getItem('tokenid');
const httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
const httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;

export const feeDueStudentListSvc = (cbGetFeeDueStudentList) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/payment/student/list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { 'screenId': screenId },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstFeeDue = response.data.lstStudent;
        }
        cbGetFeeDueStudentList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbGetFeeDueStudentList(obj);
    })
}

export const feeDueSvc = (studentId, cbGetFeeDue) => {
    // console.log("feeDueSvc called, studentId",studentId);
    const obj = { status: "", message: "", studentId: studentId };
    axios({
        url: httpURL + '/sms/payment/feedue/total',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { "studentId": studentId, 'screenId': screenId },
        withCredentials: true,
    }).then((response) => {
        // console.log("response",response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            // console.log("response",response);
            obj.feeDue = response.data.feeDue;
        }
        cbGetFeeDue(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbGetFeeDue(obj);
    })
}

export const deletePaymentSvc = (RQPaymentDelete, cbDeletePayment) => {
    // console.log("deletePaymentSvc called RQPaymentDelete", RQPaymentDelete);
    const obj = { status: "", message: ""};
    const formData = new FormData();
    formData.append("RQPaymentDelete", JSON.stringify(RQPaymentDelete));
    axios({
        url: httpURL + "/sms/payment/delete",
        method: "POST",
        headers: { 'tokenid': tokenid },
        // params: { RQPaymentDelete:RQPaymentDelete },
        data:formData,
        withCredentials: true,
    }).then((response) => {
        // console.log("response",response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbDeletePayment(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbDeletePayment(obj);
    })
}