import { getEnquiryListSvc, getStaffListSvc } from './EnquirySvc.js'
import React, { useState, useRef, useEffect, useMemo } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import SliderType from './sliderType.js'

const EnquiryTable = props => {
  const [lstEnq, setLstEnq] = useState([])
  const [flgSliderType, setFlgSliderType] = useState([])
  const [loader, setLoader] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [cls, setCls] = useState('')
  const [foundEnquiry, setFoundEnquiry] = useState(lstEnq)

  //const [lstSelEnquiry, setLstSelEnquiry] = useState({enqId:0,selected:false});
  const [lstSelEnquiry, setLstSelEnquiry] = useState([])
  // const [checked, setChecked] = useState(false);
  // const data=useMemo(()=> MOCK_DATA,[])
  const ref = useRef()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  let isMounted = false;
  const controller = new AbortController()
  const signal = controller.signal
  
  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isMenuOpen])

  useEffect(() => {
    isMounted = true;
    setLoader('block')
    getEnquiryListSvc(handleEnqResponse)
    return () => {
      isMounted = false;
      controller.abort()
    }
  }, [])

  function handleEnqResponse (data) {
    if(isMounted){
      setLoader('none')
      if (data.SvcStatus === 'Success') {
        setLstEnq(data.lstEnquiry)
        setFoundEnquiry(data.lstEnquiry)
      } else {
        toast.error(data.SvcMsg)
      }
    }
  }

  function handleCBoxChange (enqId, status) {
    // console.log('handleCBoxChange triggered')
    if (status && !lstSelEnquiry.includes(enqId)) {
      var lstTempArr = lstSelEnquiry
      lstTempArr.push(enqId)
      setLstSelEnquiry(lstTempArr)
      // console.log('checked ' + lstTempArr)
      returnSelEnqId()
    } else {
      var lstTempArr = lstSelEnquiry
      lstTempArr.splice(lstTempArr.indexOf(enqId))
      setLstSelEnquiry(lstTempArr)
      // console.log('unchecked' + lstTempArr)
      returnSelEnqId()
    }
  }

  function returnSelEnqId () {
    props.setEnqForAssign(lstSelEnquiry)
  }

  function updateFlgSliderType (params) {
    setFlgSliderType(params)
    // console.log(flgSliderType)
  }

  function actionClick (pEnqId, pClsId) {
    props.setSelectedEnquiryId(pEnqId)
    props.setSelectedClsId(pClsId)
    setIsMenuOpen(oldState => !oldState)
  }
  // const goToRegisterScreen = async (pEnqId, pRegsId, pUserId) => {
  //   await localStorage.setItem('enquiryId', pEnqId)
  //   await localStorage.setItem('registrationId', pRegsId)
  //   await localStorage.setItem('pUserId', pUserId)
  //   window.open(
  //     `/register?enquiryId=${pEnqId}&registrationId=${pRegsId}`,
  //     '_blank'
  //   )
  // }
  const filterName = e => {
    const keyword = e.target.value
    if (keyword !== '') {
      const results = foundEnquiry.filter(user => {
        return user.name.toLowerCase().includes(keyword.toLowerCase())
        // Use the toLowerCase() method to make it case-insensitive
      })
      setFoundEnquiry(results)
    } else {
      setFoundEnquiry(lstEnq)
      // If the text field is empty, show all users
    }

    setName(keyword)
  }
  const filterEmail = e => {
    const keyword = e.target.value
    if (keyword !== '') {
      const results = foundEnquiry.filter(user => {
        return user.email.toLowerCase().includes(keyword.toLowerCase())
        // Use the toLowerCase() method to make it case-insensitive
      })
      setFoundEnquiry(results)
    } else {
      setFoundEnquiry(lstEnq)
      // If the text field is empty, show all users
    }

    setEmail(keyword)
  }
  const filterClass = e => {
    const keyword = e.target.value
    if (keyword !== '') {
      const results = foundEnquiry.filter(user => {
        return user.cls.toLowerCase().includes(keyword.toLowerCase())
        // Use the toLowerCase() method to make it case-insensitive
      })
      setFoundEnquiry(results)
    } else {
      setFoundEnquiry(lstEnq)
      // If the text field is empty, show all users
    }

    setCls(keyword)
  }
  const colgrp = (
    <colgroup>
      <col width='3%'></col>
      <col width='6%'></col>
      <col width='7%'></col>
      <col width='7%'></col>
      <col width='6%'></col>
      <col width='4%'></col>
      <col width='4%'></col>
      <col width='4%'></col>
      <col width='6%'></col>
      <col width='7%'></col>
      <col width='6%'></col>
      <col width='6%'></col>
      <col width='5%'></col>
      <col width='5%'></col>
      <col width='4%'></col>
      <col width='4%'></col>
    </colgroup>
  )

  return (
    <div ref={ref}>
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: loader
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <table className='tableLayout1'>
        {colgrp}
        <thead>
          <tr>
            <th>Enq ID</th>
            <th>Enq Date</th>
            <th style={{ width: 75 }}>
              <input
                onChange={filterName}
                type='text'
                value={name}
                className='form-control inp-search text-left'
                placeholder='Name'
              />
            </th>
            <th style={{ width: 75 }}>
              <input
                onChange={filterEmail}
                type='text'
                value={email}
                className='form-control inp-search text-left'
                placeholder='Email'
              />
            </th>
            <th>Contact</th>
            <th>Gender</th>
            <th>Age</th>
            <th style={{ width: 75 }}>
              <input
                onChange={filterClass}
                type='text'
                value={cls}
                className='form-control  inp-search text-left'
                placeholder='Class'
              />
            </th>
            <th className='text-left'>City</th>
            <th className='text-left'>Assigned To</th>
            <th>Assign Date</th>
            <th className='text-left'>Source</th>
            <th className='text-left'>Campaign</th>
            <th className='text-left'>Status</th>
            <th>Select</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
      <div className='tablebodyWrapper'>
        <table className='tableLayout'>
          {colgrp}
          <tbody>
            {foundEnquiry ? foundEnquiry.map((enq, idx) => {
              return (
                <tr
                  className={
                    enq.enqId === props.selectedEnquiryId ? 'selected' : ''
                  }
                  key={idx}
                  onClick={e => props.setSelectedEnquiryId(enq.enqId)}
                >
                  <td>{enq.enqId}</td>
                  <td>{enq.enqDt}</td>
                  <td className='text-left'>{enq.name}</td>
                  <td className='text-left'>{enq.email}</td>
                  <td>{enq.contact}</td>
                  <td>{enq.gender}</td>
                  <td>{enq.age}</td>
                  <td
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {enq.cls}
                  </td>
                  <td className='text-left'>{enq.city}</td>
                  <td className='text-left'>{enq.assignedTo}</td>
                  <td>{enq.assignDt}</td>
                  <td className='text-left'>{enq.source}</td>
                  <td className='text-left'>{enq.campaign}</td>
                  <td className='text-left'>{enq.status}</td>
                  <td>
                    <input
                      type='checkbox'
                      onClick={e =>
                        handleCBoxChange(enq.enqId, e.target.checked)
                      }
                    />
                  </td>
                  <td className='text-center'>
                    <span
                      className='glyphicon glyphicon-option-vertical'
                      onClick={e => actionClick(enq.enqId, enq.clsId)}
                    >
                      <ul
                        className='ulStyle1'
                        style={{
                          display:
                            isMenuOpen && enq.enqId === props.selectedEnquiryId
                              ? 'block'
                              : 'none'
                        }}
                      >
                        <li
                          onClick={e =>
                            props.setFlgSliderType(
                              SliderType.SLIDER_ENQUIRY_DTLS
                            )
                          }
                        >
                          Edit Details
                        </li>
                        <li
                          onClick={e =>
                            props.setFlgSliderType(
                              SliderType.SLIDER_FOLLOWUP_DTLS
                            )
                          }
                        >
                          Follow up
                        </li>
                        <li
                          onClick={e => {
                            props.setEnqData({
                              enquiryId: parseInt(enq.enqId),
                              registrationId:enq.regsId=="-"?0: parseInt(enq.regsId),
                              userId: parseInt(enq.userId)
                            })
                          }}
                        >
                          Registration
                        </li>

                        <li
                          onClick={e => {
                            props.setCls(enq.cls)
                            props.setFlgSliderType(
                              SliderType.SLIDER_REGISTER_FEE
                            )
                          }}
                        >
                          Registration Fee
                        </li>
                        <li
                          onClick={e => {
                            props.setCls(enq.cls)
                            props.setFlgSliderType(
                              SliderType.SLIDER_REGISTER_FEE_PAYMENT
                            )
                          }}
                        >
                          Regs. Fee Payment
                        </li>
                        <li
                          onClick={e =>
                            {props.setRegistrationId(enq.regsId=="-"?0: parseInt(enq.regsId))
                            props.setFlgSliderType(
                              SliderType.SLIDER_SCHEDULE_TEST
                            )}
                          }
                        >
                          Schedule Test
                        </li>
                        {/* <li onClick={(e) => props.setFlgSliderType(SliderType.SLIDER_SCHEDULE_INTERVIEW)}>Schedule Interview</li> */}
                        <li
                          onClick={e =>
                            {props.setRegistrationId(enq.regsId=="-"?0: parseInt(enq.regsId))
                            props.setFlgSliderType(
                              SliderType.SLIDER_TEST_RESULT
                            )
                          }}
                        >
                          Evaluate Test
                        </li>
                        <li
                          onClick={e =>
                            {props.setRegistrationId(enq.regsId=="-"?0: parseInt(enq.regsId))
                            props.setFlgSliderType(
                              SliderType.SLIDER_INTERVIEW_RESULT
                            )
                          }}
                        >
                          Evaluate Interview
                        </li>
                        
                        <li
                          onClick={e => {
                            props.setCls(enq.cls)
                            props.setFlgSliderType(
                              SliderType.SLIDER_ADMISSION_FEE
                            )
                          }}
                        >
                          Admission Fee
                        </li>
                        <li
                          // onClick={(e) => { props.setCls(enq.cls); props.setFlgSliderType( SliderType.SLIDER_ADMISSION_FEE_PAYMENT ); }}
                        >
                          Adm. Fee Payment
                        </li>
                        <li
                          onClick={e =>
                            props.setFlgSliderType(SliderType.SLIDER_CLOSE)
                          }
                        >
                          Close
                        </li>
                      </ul>
                    </span>{' '}
                  </td>
                  {/* <td>
                  <div className="dropdown">
                    <button className="dropbtn" onClick={e => actionClick(enq.enqId)}>Action</button>
                  </div>
                </td> */}
                </tr>
              )
            }
            ) : null
          }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default EnquiryTable
