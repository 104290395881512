import React, { useState, useEffect } from "react";
import "common/css/master.css";
import "../css/transport.css";
import { toast, ToastContainer } from "react-toastify";
import { deleteVehicleSvc, getVehicleList } from "./vehicleSvc";
const VehicleTable = (props) => {
  const [loader, setLoader] = useState("none");
  const [lstVehicle, setLstVehicle] = useState([]);
  const [lstVehicleTable, setLstVehicleTable] = useState([]);
  const [vehicleId, setVehicleId] = useState(0);

  const [filteredRegNo, setFilteredRegNo] = useState("");
  const [filteredVehicleType, setFilteredVehicleType] = useState("");

  useEffect(() => {
    setLoader("block");
    getVehicleList(cbVehicleList);
    // console.log(props)
  }, []);
  function cbVehicleList(data) {
    if (data.SvcStatus === "Success") {
      setLstVehicle(data.lstVehicle);
      setLstVehicleTable(data.lstVehicle);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }

  function deleteVehicle(param) {
    setLoader("block");
    deleteVehicleSvc(param, cbDeleteVehicle);
  }
  function cbDeleteVehicle(data) {
    setLoader("none");
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      props.generateRandomNo();
    } else {
      toast.error(data.SvcMsg);
    }
  }
  function setRowVehicleId(param) {
    setVehicleId(param);
    props.setVehicleId(param);
  }


  const filterByRegsNo = (e) => {
    const keyword = e.target.value;
    setFilteredRegNo(keyword);
    filterFn(keyword, filteredVehicleType);
  };
  const filterByVehicleType = async (e) => {
    const keyword = e.target.value;
    setFilteredVehicleType(keyword);
    filterFn(filteredRegNo, keyword);
  };

  async function filterFn(fRegNo, fVehicleType) {
    var tmpLst = [];
    if(lstVehicle && lstVehicle.length){
      tmpLst = lstVehicle.filter((vehicle) => vehicle.vehicleRegsNo.toLowerCase().includes(fRegNo.toLowerCase()));
      tmpLst = tmpLst.filter((vehicle) => vehicle.vehicleType.toLowerCase().includes(fVehicleType.toLowerCase()));
    }

    setLstVehicleTable(tmpLst);
  }

  const colgrp = (
    <colgroup>
      <col width="20%"></col>
      <col width="20%"></col>
      <col width="20%"></col>
      <col width="15%"></col>
      <col width="10%"></col>
      <col width="25%"></col>
    </colgroup>
  );
  return (
    <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
      <table className="tableLayout1">
        {colgrp}
        <thead>
          <tr>
            <th>
              <input
                className="form-control inp-search"
                placeholder="Regs. No."
                value={filteredRegNo}
                onChange={(e) => filterByRegsNo(e)}
              />
            </th>
            <th>
              <input
                className="form-control inp-search"
                placeholder="Vehicle Type"
                value={filteredVehicleType}
                onChange={(e) => filterByVehicleType(e)}
              />
            </th>
            <th>Make</th>
            <th>Model</th>
            <th>Air Conditioned</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
      <div className="tablebodyWrapper">
        <table className="tableLayout">
          {colgrp}
          <tbody>
            {lstVehicleTable.map((obj, idx) => {
              return (
                <tr
                  key={idx}
                  onClick={(e) => setRowVehicleId(obj.vehicleId)}
                  className={obj.vehicleId === vehicleId ? "selected" : ""}
                >
                  <td>{obj.vehicleRegsNo}</td>
                  <td>{obj.vehicleType}</td>
                  <td>{obj.make}</td>
                  <td>{obj.model}</td>
                  <td>{obj.flgAc ? "Yes" : "-"}</td>
                  <td className="text-center">
                    <span
                      className="glyphicon glyphicon-edit"
                      onClick={(e) => props.openSlider()}
                      title="Edit"
                    ></span>
                    <span
                      className="glyphicon glyphicon-road"
                      onClick={(e) => props.openRouteSlider()}
                      title="Assign Route"
                    ></span>
                    <span
                      className="glyphicon glyphicon-trash"
                      onClick={(e) => deleteVehicle(obj.vehicleId)}
                      title="Delete"
                    ></span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default VehicleTable;
