import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import YearlyAttendanceTable from './YearlyAttendanceTable';

const YearlyAttendanceCtrl = () => {

    const [clsSectionId, setClsSectionId] = useState(0)

    const getWingId = (wingId) => {
        // console.log('wingId = ' + wingId)
    }

    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />

            <div className="MainContainer" >
                <div className="col-md-10 col-centered wingsWrap clearfix" style={{ marginTop: '1%' }}>
                    <YearlyAttendanceTable clsSectionId={clsSectionId} setClsSectionId={setClsSectionId} />
                </div>
            </div>
        </div>
    )
}

export default YearlyAttendanceCtrl