import React from 'react'
import $ from 'jquery';
import TdSub from './tdSub';

export default function TimeTableBody({ lstExamPeriod, tableId, tbodyId, bodyCls, days, examScheduleId, classSectionId, alongWithStudy, examDt, examId, heads, headCls, headWidth, showSlider, sub, tch, asset, invigilator, evaluator, verifier, link, notes }) {
	return (
		<div className={bodyCls} >
			<table id={tableId} className={headCls} style={{ width: headWidth }}>
				{/* <colgroup>
				<col width={30} ></col>
				{days.map((day,idx)=>{
					return  <col key={idx} width={40} ></col>
				})}
			</colgroup> */}
				<thead style={{ position: 'relative' }}>
					<tr>
						{heads.map((head, index) => {
							return <th key={index} style={{ width: 60, borderRight: '1px solid #000' }} className="" id={head.columnId}><span>{head.dispDate}</span><hr /><span>{head.weekday}</span></th>
						})}
					</tr>
				</thead>
				<tbody id={tbodyId}>

					{lstExamPeriod && lstExamPeriod.map((item, idx) => {

						return (
							<tr key={idx} id={idx} style={{ height: '80px' }}>
								{
									// console.log(item)
								}

								{examScheduleId && days.map((day) => {
									return day.dayTypeId != 10 ? <td style={{ background: '#e6e6e6', border: "1px solid #aaa" }}>

									</td> :
										<TdSub
											className="droppable"
											droppable="true"
											key={day.columnId + "_" + day.date}
											periodId={item.slotId}
											classSectionId={classSectionId}
											dayId={day.columnId}
											examScheduleId={examScheduleId}
											alongWithStudy={alongWithStudy}
											examDt={day.date}
											examId={examId}
											showSlider={showSlider}
										// sub={sub}
										// tch={tch}
										// asset={asset}
										// invigilator={invigilator}
										// evaluator={evaluator}
										// verifier={verifier}
										// link={link}
										// notes={notes}
										// sectionId={sectionId}
										// disabled={item.dayTypeId!=10}

										/>
								})}
							</tr>
						)

					})}
				</tbody>
			</table>
		</div>
	)
}
