import React, { useEffect, useState } from "react";
import "../css/login.css";
import "common/css/master.css";
import { toast } from "react-toastify";

import { getLoginDataSvc } from "./loginSvc";

const LastLoginData = (props) => {
    const { setEmail, login, setLastLogin, setLoader, setOpacity } = props;

    const [loginData, setLoginData] = useState([]);
    const [top3ScreensId, setTop3ScreensId] = useState([]);
    const [isLastLoginDataVisible, setIsLastLoginDataVisible] = useState(false);
    // const [emailsStore, setEmailsStore] = useState(localStorage.getItem("emailsStore"));

    useEffect(() => {
        // console.log("useEffect called in lastLoginData");
        let emails = [];
        emails.push(localStorage.getItem("emailsStore"));
        emails = { emails };
        getLoginData(emails);
    }, []);

    const getLoginData = (emails) => {
        // console.log("getLoginData called in lastLoginData");
        setLoader("block");
        setOpacity(0.5);
        getLoginDataSvc(emails, loginDataResponse);
    }

    const loginDataResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            // console.log("lastLogin in loginDataResponse", obj.lastLogin);
            setLoginData(obj.loginData);
            setLastLogin(obj.lastLogin);
            setTop3ScreensId(obj.top3ScreensId);
            if (obj.lastLogin != null) {
                setIsLastLoginDataVisible(true);
            } else {
                setIsLastLoginDataVisible(false);
            }
        }
        else {
            // toast.error(obj.message);
            // console.log(obj.message);
        }
        setLoader("none");
        setOpacity(1);
    }

    const notificationPage = () => {
    }

    return (
        <>
            {isLastLoginDataVisible &&
                <div className="col-xs-3 padding-remove lastLoginDtls clearfix">
                    <div className="lastLoginData">
                        {loginData.map((item, index) => (
                            <div key={index}>
                                <div
                                    onClick={() => {
                                        setEmail(item.email);
                                        // login();
                                    }
                                    }>{item.email}</div>
                                <span>Last accessed: {item.lastLogin}</span>
                                <div
                                    className="notificationCount"
                                    title="Notification"
                                    onClick={notificationPage}
                                >
                                    <a href="#!">{item.notificationCount}</a>
                                </div>
                                {/* <div className="top3Links" title="Most Used Links">
                                {item.top3ScreensId.map((screensId, i) => (
                                    <span key={i} id={screensId} onClick={this.topPage}>
                                        {this.getScreenNames(screensId).toUpperCase()}
                                    </span>
                                ))}
                            </div> */}
                            </div>
                        ))}
                    </div>
                </div>
            }
        </>
    );
}

export default LastLoginData;