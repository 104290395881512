import axios from 'axios'
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem('tokenid');
var httpURL = lstModules['enquiry'].url + ":" + lstModules['enquiry'].port;
function getFollowUpRemarksSvc(callBack, pEnqId) {
    if (!pEnqId) {
        return;
    }
    axios(
        {
            url: httpURL+"/sms/enquiry/followup",
            method: 'POST',
            params: { enqId: pEnqId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        console.error(error)
    });
}
function saveFollowUpRemarksSvc(penqId, premarks, cbSaveFollowUp) {
    axios({
        url: httpURL+"/sms/enquiry/followup/save",
        method: "POST",
        params: { enqId: penqId, remarks: premarks },
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        cbSaveFollowUp(data)
    }).catch((error) => { 
        // console.log("Error Occured") 
    });
}

export { getFollowUpRemarksSvc };
export { saveFollowUpRemarksSvc };