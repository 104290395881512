import CounselorSetup from 'sms/enquiry/js/CounselorSetup';
import React, { useEffect, useState } from 'react'
import { getLstExamSvc } from './ExamHeaderSvc';

const ExamDateHeader = (props) => {
    const [examId, setExamId] = useState(props.examId)
    const [lstExam, setLstExam] = useState([])
    const [dynamicWidth, setDynamicWidth] = useState('calc(100% / ' + 9 + ')');
    useEffect(() => {
        setExamId(props.examId)
        getLstExamSvc(cbLstExamResponse);

    }, [])

    function cbLstExamResponse(data) {
        if (data.SvcStatus === 'Success') {
            setLstExam(data.lstExam)
            setDynamicWidth('calc(100% / ' + data.lstExam.length + ')');
            props.examClick(data.lstExam[0].examId)
        }
        // console.log(data)

    }
    function examClick(pExamId, pExam) {
        props.examClick(pExamId, pExam);
    }
    return (

        <div className="wrapper-box" style={{ height: '8.5vh'
        , boxShadow: 'rgba(64, 60, 67, 0.16) 0px 2px 5px 1px'
        , width: '100%', marginBottom: 10 }}>
            {lstExam.map((obj) => {
                var isSelected = obj.examId === props.examId;
                return <div
                    className={isSelected ? "sellected box-block" : "box-block"}
                    onClick={(e) => examClick(obj.examId, obj.exam)} key={obj.examId}
                    style={{ width: dynamicWidth }}>
                    <div className="div-block text-center">
                        <span hidden>{obj.examId}</span>
                        <span>{obj.exam}</span>
                    </div>
                    <div className="div-block" style={{ fontSize: '1.2rem' }}>
                        <span>{obj.fromDt} | {obj.toDt}</span>
                    </div>
                </div>
            })}
        </div >

    )
}

export default ExamDateHeader