import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "common/css/master.css";
import "sa/settlement/css/settlement.css";
import SAHeader from "sa/header/js/saHeader";
import { getLstSettlementSvc } from "./SettlementTabSvc";
import SettlementSlider from "./SettlementSlider";
const params = new URLSearchParams(window.location.search);

const SettlementTab = () => {
  const [schoolId, setSchoolId] = useState(params.get("schoolId"));
  const [loader, setLoader] = useState("none");
  const [school, setSchool] = useState(params.get("school"));
  const [count, setCount] = useState(params.get("count"));
  const [city, setCity] = useState(params.get("city"));
  const [amount, setAmount] = useState("10,000");
  const [lstSettlement, setLstSettlement] = useState([]);
  const [locality, setLocality] = useState(params.get("locality"));
  const [lstSelSettlement, setLstSelSettlement] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalSysAmt, setTotalSysAmt] = useState(0);
  const [totalSchoolAmt, setTotalSchoolAmt] = useState(0);
  const [totalPGWAmt, setTotalPGWAmt] = useState(0);
  const [flgSlider, setFlgSlider] = useState(false);
  const [randNo, setRandNo] = useState(0);

  useEffect(() => {
    // console.log("useEffect called");
    setSettlement();
  }, []);
  function setSettlement() {
    showLoader();
    getLstSettlementSvc(schoolId, cbLstSettlementResponse);
  }
  function hideLoader() {
    setLoader("none");
  }
  function showLoader() {
    setLoader("block");
  }
  function cbLstSettlementResponse(data) {
    var obj = data;
    hideLoader();

    if (obj.status === "Success") {
      setLstSettlement(obj.lstSettlement);
      var tot = 0,
        totSysAmt = 0,
        totSchoolAmt = 0,
        totPGWAmt = 0;

      for (var i = 0; i < obj.lstSettlement.length; i++) {
        var vObj = obj.lstSettlement[i];
        tot += vObj.total;
        totSysAmt += vObj.systemAmt;
        totSchoolAmt += vObj.schoolAmt;
        totPGWAmt += vObj.pgwAmt;
      }
      setTotal(tot);
      setTotalSysAmt(totSysAmt);
      setTotalSchoolAmt(totSchoolAmt);
      setTotalPGWAmt(totPGWAmt);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        localStorage.setItem("isRememberMeSelected", false);
        setTimeout(function () {
          window.location.href = "/sa";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        localStorage.setItem("isRememberMeSelected", false);
        setTimeout(function () {
          window.location.href = "/sa";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
        return false;
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }
  function handleCboxChange(
    pSettlementId,
    pRazorpayPaymentId,
    pSettlementUtr,
    pRazorpayOrderId,
    pKmsgOrderId,
    status
  ) {
    var lstTempArr = [];
    if (status && !lstTempArr.includes(pRazorpayPaymentId)) {
      lstTempArr.push({
        settlementId: pSettlementId,
        razorpayPaymentId: pRazorpayPaymentId,
        razorpayOrderId: pRazorpayOrderId,
        kmsgOrderId: pKmsgOrderId,
        settlementUtr: pSettlementUtr,
      });
      setLstSelSettlement(lstTempArr);
      // console.log("checked " + lstTempArr);
    } else {
      lstTempArr.splice(lstTempArr.indexOf(pRazorpayPaymentId), 1);
      setLstSelSettlement(lstTempArr);
      // console.log("unchecked" + lstTempArr);
      //returnSelEnqId()
    }
    // console.log(lstTempArr);
  }
  function generateRandomNo() {
    var lRand = Math.floor(Math.random() * 999999);
    setRandNo(lRand);
  }
  function openSettlementSlider() {
    // console.log("openSettlementSlider");
    setFlgSlider(true);
  }
  function closeSlider() {
    setFlgSlider(false);
  }
  const colgrp = (
    <colgroup>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="50%"></col>
      <col width="10%"></col>
    </colgroup>
  );
  const getWingId = (wingId) => {
    // console.log("wingId" + wingId);
  };

  return (
    <div
      className="MainContainer"
      style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <SAHeader wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className="col-sm-10 col-centered" style={{ marginTop: "2%" }}>
        <div className="col-sm-11" style={{ height: "100%" }}>
          <div className="form form-horizontal">
            <div className="col-xs-12 form-group">
              <div className="col-xs-6 padding-remove">
                <div
                  className="pull-left padding-remove col-sm-12"
                  style={{ border: "1px solid rgb(180, 180, 180)" }}
                >
                  <label
                    className="col-sm-2 control-label padding-remove"
                    style={{
                      textAlign: "center",
                      color: "rgb(0, 0, 0)",
                      background: "rgb(204, 204, 204)",
                    }}
                  >
                    School:
                  </label>
                  <label className="col-sm-8 control-label padding-remove text-left">
                    <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                      {school}
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-xs-3 pull-right padding-remove">
                <div
                  className="pull-left padding-remove col-sm-12"
                  style={{ border: "1px solid rgb(180, 180, 180)" }}
                >
                  <label
                    className="col-sm-4 control-label padding-remove"
                    style={{
                      textAlign: "center",
                      color: "rgb(0,0,0)",
                      background: "rgb(204, 204, 204)",
                    }}
                  >
                    Count:
                  </label>
                  <label className="col-sm-8 control-label padding-remove text-right">
                    <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                      {count}
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-xs-12 form-group">
              <div className="col-xs-6 padding-remove">
                <div
                  className="pull-left padding-remove col-sm-12"
                  style={{ border: "1px solid rgb(180, 180, 180)" }}
                >
                  <label
                    className="col-sm-2 control-label padding-remove"
                    style={{
                      textAlign: "center",
                      color: "rgb(0, 0, 0)",
                      background: "rgb(204, 204, 204)",
                    }}
                  >
                    City:
                  </label>
                  <label className="col-sm-8 control-label padding-remove text-left">
                    <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                      {locality !== "-" ? locality + ", " : ""}
                      {city}
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-xs-3 pull-right padding-remove">
                <div
                  className="pull-left padding-remove col-sm-12"
                  style={{ border: "1px solid rgb(180, 180, 180)" }}
                >
                  <label
                    className="col-sm-4 control-label padding-remove"
                    style={{
                      textAlign: "center",
                      color: "rgb(0,0,0)",
                      background: "rgb(204, 204, 204)",
                    }}
                  >
                    Amount:
                  </label>
                  {total && (
                    <label className="col-sm-8 control-label padding-remove text-right">
                      <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                        {total}
                      </span>
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xs-12 form-group">
              <div className="col-xs-3 pull-right padding-remove">
                <button
                  type="button"
                  className="btn btn-info pull-right"
                  onClick={() => openSettlementSlider()}
                >
                  Settle
                </button>
              </div>
            </div>
          </div>
          <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <table className="tableLayout1">
              {/* {colgrp} */}
              <thead>
                <tr>
                  <th rowSpan={1} colSpan={1}>
                    Payment Date
                  </th>
                  <th rowSpan={1} colSpan={1}>
                    Order Id
                  </th>
                  <th rowSpan={1} colSpan={1}>
                    Payment Id
                  </th>
                  <th rowSpan={1} colSpan={2}>
                    PGW Settlement
                  </th>
                  <th rowSpan={1} colSpan={4}>
                    Amount
                  </th>
                  <th rowSpan={1} colSpan={1}>
                    Select
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th colSpan={1}>Total</th>
                  <th colSpan={1}>School</th>
                  <th colSpan={1}>System</th>
                  <th colSpan={1}>Payment</th>
                  <th></th>
                </tr>
              </thead>
            </table>
            <div className="tablebodyWrapper1">
              <table className="tableLayout">
                {/* {colgrp} */}
                <tbody>
                  <div style={{ display: "table-row", background: "grey" }}>
                    <div
                      style={{
                        display: "table-cell",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "table-cell",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "table-cell",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "table-cell",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "table-cell",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      TOTAL
                    </div>
                    <div
                      style={{
                        display: "table-cell",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      {total}
                    </div>
                    <div
                      style={{
                        display: "table-cell",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      {totalSchoolAmt}
                    </div>
                    <div
                      style={{
                        display: "table-cell",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      {totalSysAmt}
                    </div>
                    <div
                      style={{
                        display: "table-cell",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      {totalPGWAmt}
                    </div>
                    <div
                      style={{
                        display: "table-cell",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    ></div>
                  </div>
                  {lstSettlement &&
                    lstSettlement.length !== 0 &&
                    lstSettlement.map((obj, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{obj.paymentDt}</td>
                          <td>{obj.razorpayOrderId}</td>
                          <td>{obj.razorpayPaymentId}</td>
                          <td colSpan={2}>{obj.settlementId}</td>
                          <td>{obj.total}</td>
                          <td>{obj.schoolAmt}</td>
                          <td>{obj.systemAmt}</td>
                          <td>{obj.pgwAmt}</td>
                          <td>
                            <input
                              onClick={(e) =>
                                handleCboxChange(
                                  obj.settlementId,
                                  obj.razorpayPaymentId,
                                  obj.settlementUtr,
                                  obj.razorpayOrderId,
                                  obj.kmsgOrderId,
                                  e.target.checked
                                )
                              }
                              defaultChecked={obj.selected}
                              type="checkbox"
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          {flgSlider && (
            <div
              style={{ width: "50%", display: flgSlider ? "block" : "none" }}
              className=""
            >
              <button
                onClick={() => closeSlider()}
                type="button"
                className="btn btn-info text-center btnCloseDiv"
                style={{ right: "50%", zIndex: 1000 }}
              >
                <span
                  id="close"
                  title="Close Slider"
                  className="glyphicon glyphicon-remove"
                ></span>
              </button>
              <SettlementSlider
                key={schoolId}
                lstSelSettlement={lstSelSettlement}
                schoolId={schoolId}
                generateRandomNo={generateRandomNo}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettlementTab;
