import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';

import SyllabusClasses from "./syllabusClasses";
import ExamTabs from "./examTabs";
import ExamTable from "./examTable";


const ExamSyllabus = () => {

    const [loader, setLoader] = useState(0);
    const [opacity, setOpacity] = useState(1);
    const [syllabusId, setSyllabusId] = useState(0);
    const [sectionId, setSectionId] = useState(0);
    const [examId, setExamId] = useState(0);


    useState(() => {
        // console.log("useEffect in examSyylabus.js calle");

    }, []);

    const handleSyllabusChange = (pSybId) => {
        // console.log("handleSyllabusChange called, pSybId", pSybId);
        setSyllabusId(pSybId);
    }

    const handleClassChange = (pSectionId) => {
        // console.log("handleClassChange called, pSectionId", pSectionId);
        setSectionId(pSectionId);
    }

    const handleExamChange = (pExamId) => {
        // console.log("handleExamChange called, pExamId", pExamId);
        setExamId(pExamId);
    }

    return (
        <div style={{ height: '100%' }}>
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader === 0 ? "none" : "block" }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="exam-syllabus-main col-sm-12" style={{ pointerEvents: loader === 0 ? "auto" : "none", marginTop: "10px" }}>
                <div className="col-sm-12 padding-remove col-centered">
                    <div className="col-sm-11 padding-remove col-centered">
                        <SyllabusClasses
                            syllabusId={syllabusId}
                            sectionId={sectionId}
                            handleSyllabusChange={handleSyllabusChange}
                            handleClassChange={handleClassChange}
                            setLoader={setLoader}
                            setOpacity={setOpacity}
                        />
                    </div>
                </div>
                <div className="col-sm-11 padding-remove col-centered">
                    <div className="col-sm-12 wrapper-box padding-remove" style={{
                        boxShadow: 'rgba(64, 60, 67, 0.16) 0px 2px 5px 1px', marginBottom: 10, marginTop: "10px"
                    }}>
                        <div className="col-sm-12 padding-remove col-centered">
                            <ExamTabs
                                examId={examId}
                                handleExamChange={handleExamChange}
                                setLoader={setLoader}
                                setOpacity={setOpacity}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-11 padding-remove col-centered">
                    <div className="col-sm-12 padding-remove col-centered">
                        {examId && syllabusId ?
                            <ExamTable
                                examId={examId}
                                syllabusId={syllabusId}
                                sectionId={sectionId}
                                setLoader={setLoader}
                                setOpacity={setOpacity}
                            />
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ExamSyllabus;