import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import Loader from "common/components/Loader";

import "../css/schoolProfile.css";
import { saveEmailServerDtlsSvc, getEmailServerDtlsSvc } from "./schoolProfileSvc";


const SchoolEmail = (props) => {
    const { schoolId } = props;
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [host, setHost] = useState("");
    const [port, setPort] = useState("");
    const [mail, setMail] = useState("");
    const [password, setPassword] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [isPasswordVisible, setIsPaswordVisible] = useState(false);

    useEffect(() => {
        console.log("SchoolEmail component called");
        getEmailServerDetails();
    }, []);

    const getEmailServerDetails = () => {
        setLoader("block");
        setOpacity(0.5);
        getEmailServerDtlsSvc(handleEmailServerDtlsResponse);
    }

    
  const handleEmailServerDtlsResponse = (data) => {
    setLoader("none");
    setOpacity(1);
    var obj = data;
    if (data.status === "Success") {
      setHost(obj.mailDtls.host);
      setPort(obj.mailDtls.port);
      setMail(obj.mailDtls.email);
      setPassword(obj.mailDtls.password);
      setDisplayName(obj.mailDtls.displayName);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  }

    const saveEmailServerDtls = () => {
        var mailDtlsObj = {
            "host": host,
            "port": port,
            "email": mail,
            "password": password,
            "displayName": displayName,
        };
        // console.log("save button in email server details called(saveEmailServerDtls)");
        setLoader("block");
        setOpacity(0.5);
        saveEmailServerDtlsSvc(mailDtlsObj, handleSaveEmailServerDtlsResponse);
    }
    const handleSaveEmailServerDtlsResponse = (data) => {
        // console.log("obj return in handleBankLocalityResponse", data);
        var obj = data;
        setLoader("none");
        setOpacity(1);
        if (obj.status === "Success") {
            toast.success(obj.message);
        } else if (obj.status === "Failure") {
            if (obj.message === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (obj.message === "Your Session is expired, login again") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed" + obj);
        }
    }

    const togglePassword = () => {
        setIsPaswordVisible((prevIsPasswordVisible) => !prevIsPasswordVisible);
    };



    return (
        <div className="col-xl-12 col-ls-12 col-md-12 col-sm-12 col-xs-12 padding-remove"
            style={{
                cursor: (loader === "none") ? 'pointer' : 'none',
                pointerEvents: (loader === "none") ? 'auto' : 'none',
                opacity: opacity
            }}>
            <Loader loader={loader} position="fixed" />
            <form
                className="form form-vertical col-sm-12"
                style={{ margin: "2%", border: "1px solid #ddd", borderRadius: 6 }}
            >
                <div
                    className="EnquiryHead col-centered"
                    style={{
                        fontWeight: "bold",
                    }}
                >
                    <h4>Email Server Details</h4>
                </div>
                <div className="row">
                    <label className="control-label col-sm-2">
                        Host
                    </label>
                    <div className="form-group col-sm-4">
                        <input
                            type="text"
                            className="form-control"
                            name="host"
                            value={host}
                            onChange={(e) => setHost(e.target.value)}
                        />
                    </div>
                    <label
                        className="control-label col-sm-2 text-right"
                        htmlFor="port"
                    >
                        Port
                    </label>
                    <div className="form-group col-sm-2">
                        <input
                            type="text"
                            className="form-control"
                            name="port"
                            value={port}
                            onChange={(e) => setPort(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-2" >
                        Email
                    </label>
                    <div className="form-group col-sm-4">
                        <input
                            type="email"
                            className="form-control"
                            name="mail"
                            value={mail}
                            onChange={(e) => setMail(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-2">
                        Password
                    </label>
                    <div className="form-group col-sm-4">
                        <span  >
                            <input
                                type={isPasswordVisible ? "text" : "password"}
                                className="form-control"
                                name="mail"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {/* <span
                    // style={{paddingRight:10}}
                    onClick={togglePassword}
                    className={isPasswordVisible ? "fa fa-fw fa-eye-slash field_icon" : "fa fa-fw fa-eye field_icon"}
                  ></span> */}
                        </span>

                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-2">
                        Display Name
                    </label>
                    <div className="form-group col-sm-5">
                        <input
                            type="text"
                            className="form-control"
                            name="displayName"
                            value={displayName}
                            onChange={(e) => setDisplayName(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-11">
                        <button
                            className="pull-right btn btn-info"
                            title="Save"
                            onClick={(e) => saveEmailServerDtls(e)}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SchoolEmail;
