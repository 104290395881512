import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
import { Form } from 'react-router-dom';


const tokenid = localStorage.getItem("tokenid");
const httpURL = lstModules['performance'].url + ":" + lstModules['performance'].port + "/sms/exam_syllabus";



export const getSyllabusListSvc = (cbSyllabusList) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log("response in getSyllabusListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstSyllabus = response.data.lstSyllabus;
        }
        cbSyllabusList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSyllabusList(obj);
    })
}


export const getExamListSvc = (cbExamList) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/exam_list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log("response in getExamListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstExam = response.data.lstExam;
        }
        cbExamList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbExamList(obj);
    })
}


export const getSubjectListSvc = (syllabusId, examId, cbSubjectList) => {
    // console.log("getSubjectListSvc called, syllabusId", syllabusId);
    const formData = new FormData();
    formData.append("syllabusId", syllabusId);
    formData.append("examId", examId);
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/subject_list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        // params:{"syllabusId":syllabusId},
        data: formData,
        withCredentials: true,
    }).then((response) => {
        // console.log("response in getSubjectListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstSubject = response.data.lstSubject;
        }
        cbSubjectList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSubjectList(obj);
    })
}


export const saveChapterSvc = (chapterExam, cbSaveChapter) => {
    // console.log("saveChapterSvc called, chapterExam", chapterExam);
    const obj = { status: "", message: "" };
    const formData = new FormData();
    formData.append("chapterExamObj", JSON.stringify(chapterExam));
    axios({
        url: httpURL + '/chapter_save',
        method: "POST",
        headers: { 'tokenid': tokenid },
        data: formData,
        // params:{"chapterExamObj": JSON.stringify(chapterExam)},
        withCredentials: true,
    }).then((response) => {
        // console.log("response in saveChapterSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbSaveChapter(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSaveChapter(obj);
    })
}



