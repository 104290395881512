import '../css/EnquiryCtrl.css'
import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import { getFollowUpRemarksSvc } from './FollowUpSvc'
import { saveFollowUpRemarksSvc } from './FollowUpSvc'
import SliderType from './sliderType';

const FollowUpSlider = (props) => {
    const [loader, setLoader] = useState('none')
    const [enqId, setEnqId] = useState(props.enquiryId)
    const [remarks, setRemarks] = useState('')
    function cbFollowUpResponse(data) {
        if (data.SvcStatus === "Success") {
            // console.log("Fetching success")
            setRemarks(data.remarks)
        }
        else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
    function cbSaveFollowUp(data) {
        if (data.SvcStatus === "Success") {

            toast.success(data.SvcMsg)
            var p2 = new Promise(function (resolve, reject) {
                resolve(props.setSelectedEnquiryId(data.enqId));
            });

            p2.then(function () {
                props.closeSlider();
            })
        } else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
    useEffect(() => {
        setLoader('block')

        var promise = new Promise((resolve, reject) => {
            if (props.flgSliderType === SliderType.SLIDER_FOLLOWUP_DTLS && props.enquiryId) {
                getFollowUpRemarksSvc(cbFollowUpResponse, props.enquiryId)
                setEnqId(props.enquiryId)
            }
        });
    }, [props.enquiryId, props.flgSliderType])
    function handleRemarksChange(param) {
        setRemarks(param)
    }
    function saveFollowUp() {
        saveFollowUpRemarksSvc(enqId, remarks, cbSaveFollowUp)
    }
    return (
        <form className='form form-horizontal' style={{ width: '100%', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10, pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>

            <div className='EnquiryHead col-sm-12 col-centered' style={{
                border: "1px solid #aaa", borderTopLeftRadius: 10,
                borderTopRightRadius: 10, borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5, fontWeight: 'bold'
            }}><h4>Follow Up</h4></div>

            <div className="form-group col-md-12 padding-remove">
                <label className="control-label col-md-2">Remarks: <span className="red-color">*</span></label>
                <div className=' padding-remove col-md-10'>
                    <textarea maxLength={500} rows="8"
                        cols="80" defaultValue={remarks} className="form-control" onChange={(e) => handleRemarksChange(e.target.value)} required></textarea>
                </div>
            </div>

            <div className="form-group col-md-12 padding-remove">
                <button type="button" className="btn btn-info pull-right" onClick={(e) => saveFollowUp()}>Save</button>
            </div>
        </form>
    );
}
export default FollowUpSlider;