import React, { useEffect, useState } from 'react'
import ClassList from './classList'
import SectionListSyllabus from './sectionListSyllabus';

const ClsHeaderSyllabus = (props) => {
    const [state, setState] = useState({});

    useEffect(()=>{
        // console.log("clsHeaderSyllabus called");
        // console.log("props.selSessionId ",props.selSessionId);
        // console.log("props.lstCls ",props.lstCls);
        // console.log("props.lstSection ",props.lstSection);
        // console.log("props.classId ",props.classId);
    },[props.lstCls]);


    return (
        <div className="row" style={{ //boxShadow: '#ccc 0px 1px 4px 0px', borderWidth: '1px 0px', borderStyle: 'solid', height: '50px', borderColor: '#ccc', 
            padding: '0px 20px', height: 'auto' }}>

            <ClassList lstCls={props.lstCls} classClick={props.changeClass}  classId={props.classId}/>
            <SectionListSyllabus lstSection={props.lstSection} sectionClick={props.changeSection} selSectionId={props.selSectionId}  classId={props.classId} />

        </div>
    )
}

export default ClsHeaderSyllabus;