import React, { useState, useEffect } from "react";
import axios from 'axios'
import { toast, ToastContainer } from "react-toastify";
import lstModules from 'common/json/lstModules.json';
const httpURL = lstModules['transport'].url + ':' + lstModules['transport'].port;
var tokenid = localStorage.getItem('tokenid');
function StopRangeTable(props){
    const [loader, setLoader] = useState('none')
    const [lstStop,setLstStop]=useState([])
    const [selIdx,setSelIdx]=useState()
    useEffect(()=>{
        setLoader('block')
          getLstStop(cbGetLstStop)
      },[])
      function handleSelRow(param){
        setSelIdx(param)
        props.getSelData(lstStop[param])
      }
    function getLstStop(callBack){
       // setLoader('block')
        axios ({
            url:httpURL+'/sms/tpt/stop_range/list' ,
            method:'POST',
            params:{},
            headers:{'tokenid':tokenid},
            withCredentials:true
        }).then((Response)=>{
            var data=Response.data
            callBack(data)
        }).catch((error)=>{
            // console.log('error' +error)
        });
    }
    function cbGetLstStop(data){
        setLoader('none')
        if(data.SvcStatus==='Success'){
            setLstStop(data.lstStopRange)
        }
        else {
            toast.error(data.SvcMsg)
        }
    }
    const colgrp = (
        <colgroup>
          <col width="8%"></col>
          <col width="8%"></col>
          <col width="8%"></col>
        </colgroup>
      );
    return(
        <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
            <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
      <table className="col-sm-8  col-sm-offset-2">
      {colgrp}
          <thead>
          <tr>
              <th>Stop Range</th>
              <th>Distance</th>
              <th>Fee Head Code</th>
          </tr>
          </thead>
          <tbody>
              {
                  lstStop.map((obj,idx)=>{
                      return (
                          <tr onClick={(e)=>handleSelRow(idx)} className={selIdx === idx ? 'selected' : ''} key={idx}>
                              <td style={{textAlign:'center'}}>{obj.stopRange}</td>
                              <td style={{textAlign:'center'}}>{obj.distance}</td>
                              <td style={{textAlign:'center'}}>{obj.feeHeadCode}</td>
                          </tr>   
                      )
                  })
              }
          </tbody>
      </table>
        </div>
    )    

}
export default StopRangeTable;