import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css'
import '../css/transport.css'
import Header from 'common/js/header/header.js';
import VehicleSlider from "./vehicleSlider";
import VehicleTable from "./vehicleTable";
import VehicleRouteslider from "./vehicleRouteSlider";
function VehicleCtrl() {
    const [loader, setLoader] = useState("none")
    const [flgSlider, setFlgSlider] = useState(false)
    const [flgRoute, setFlgRoute] = useState(false)
    const [randNo, setRandNo] = useState(0)
    const [vehicleId, setVehicleId] = useState(0)
    const [vehicleObj, setVehicleObj] = useState({})
    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console.log(lRandNo)
        setRandNo(lRandNo)
    }
    function openRouteSlider() {
        setFlgRoute(true)
    }
    function openSlider() {
        setFlgSlider(true)
    }
    function closeSlider() {
        setVehicleId(0)
        setVehicleObj({})
        setFlgSlider(false)
        setFlgRoute(false)
        generateRandomNo()
    }
    const getWingId = (wingId) => {
        // console.log("wingId" + wingId);
    }
    return (
        <div className='MainContainer'
            style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="TestTblWrapper">
                <div className="TestTbl">
                    <VehicleTable
                        key={randNo}
                        openSlider={openSlider}
                        openRouteSlider={openRouteSlider}
                        closeSlider={closeSlider}
                        setVehicleId={setVehicleId}
                        setVehicleObj={setVehicleObj}
                        generateRandomNo={generateRandomNo}
                    />
                </div>
                <div className="" onClick={(e) => openSlider()}>
                    <span id="addReadyId" title="Add" className="addIcon glyphicon glyphicon-plus-sign"></span>
                </div>
                <div className="TestSlider" style={{ display: flgSlider ? 'block' : 'none' }}>
                    <VehicleSlider
                        key={vehicleId}
                        openSlider={openSlider}
                        closeSlider={closeSlider}
                        setRandNo={setRandNo}
                        vehicleId={vehicleId}
                        vehicleObj={vehicleObj}
                        flgSlider={flgSlider}
                    />
                </div>
                <div className="TestSlider" style={{ display: flgRoute ? 'block' : 'none', width: "50%" }}>
                    <VehicleRouteslider
                        key={vehicleId}
                        openSlider={openSlider}
                        openRouteSlider={openRouteSlider}
                        closeSlider={closeSlider}
                        setRandNo={setRandNo}
                        vehicleId={vehicleId}
                        vehicleObj={vehicleObj}
                    />
                </div>
            </div>
        </div>
    )
}
export default VehicleCtrl;