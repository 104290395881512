import React, { useEffect } from 'react'
import { saveAllocationSvc, allocateTchLstSvc } from './AllocateSliderSvc.js'
import { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import '../css/allocateSlider.css'

const AllocateSlider = (props) => {
  const [lstAvailableTeacher, setLstAvailableTeacher] = useState([])
  /*   const [currentLoad, setCurrentLoad] = useState('')
    const [completed, setCompleted] = useState('')
    const [total, setTotal] = useState('')
    const [availability, setAvailibility] = useState('')
    const [filterTeacher, setFilterTeacher] = useState('')
    const [expectedTime, setExpectedTime] = useState('')
    const [allocationId, setAllocationId] = useState('')
    const [examId, setExamId] = useState('')
    const [clsId, setClsId] = useState('')
    const [subId, setSubId] = useState('') */
  const [allocatedToId, setallocatedToId] = useState('')
  const [expHrs, setExpHrs] = useState('')
  /* const [sectionId, setSectionId] = useState('') */
  const [cls, setCls] = useState('')
  const [selectedIdx, setSelectedIdx] = useState('')
  const [lstFoundTch, setLstFoundTch] = useState([])
  const [tchKeyword, setTchKeyword] = useState('')

  useEffect(() => {
    allocateTchLstSvc(cbTchLstResponse)
    // console.log(props.cls)
    setCls(props.cls)
  }, [props])

  function cbTchLstResponse(data) {
    setLstAvailableTeacher(data);
    setLstFoundTch(data)
  }

  function saveAllocation() {
    var state = {};
    state.clsId = props.clsId;
    state.sectionId = props.sectionId
    state.subId = props.subId;
    state.allocationId = props.allocationId;

    saveAllocationSvc(props.examId, expHrs, allocatedToId, [state], cbsaveAllocationResponse)
  }

  const filterTch = async (e) => {
    const keyword = e.target.value.toLowerCase();
    await setTchKeyword(keyword);
    filterFn()
  };

  function filterFn() {

    var tmpLst = []
    for (var i = 0; i < lstAvailableTeacher.length; i++) {
      var obj = lstAvailableTeacher[i]
      // console.log(obj.staff)
      if (tchKeyword != '' && !obj.staff.toLowerCase().includes(tchKeyword)) {
        continue;
      }
      tmpLst.push(obj)

    }
    setLstFoundTch(tmpLst)
  }

  function cbsaveAllocationResponse(data) {
    // console.log(data)
    if (data.status === "Success") {
      toast.success(data.message)
    }
    else {
      toast.error(data.message)
    }
    props.closeSlider()
  }

  function rowClick(staffId) {
    setSelectedIdx(staffId)
  }

  const colgrp = <colgroup>
    <col width="20%" />
    <col width="20%" />
    <col width="20%" />
    <col width="20%" />
    <col width="20%" />
  </colgroup>

  return (
    <div className='border border-1  border m-1 mb-0 b '>
      <form>
        <div className='col-sm-12'>
          <div className="form-group col-md-2 ">
            <input readOnly className="form-control-plain" style={{ border: 'none', background: '#ffffff', fontWeight: 'bold' }} value={props.cls + "-" + props.section || ""} disabled></input>
          </div>
        </div>
        <div className='col-sm-12' >
          <div className="form-group col-md-2">
            <input readOnly className="form-control-plain " style={{ border: 'none', background: '#ffffff', fontWeight: 'bold' }} value={props.subject} disabled></input>
          </div>
        </div>
      </form>

      <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-12 col-sm-12 col-xs-12" >
        <table className="tableLayout1">
          {colgrp}
          <thead>
            <tr>
              <th><input className="form-control inp-search" placeholder="Teacher" onChange={(e) => filterTch(e)} onKeyUp={(e) => filterTch(e)} /></th>
              <th >Availability</th>
              <th>Current Load</th>
              <th>Completed</th>
              <th>Total</th>
            </tr>
          </thead>
        </table>
        <div className='tablebodyWrapper'>
          <table className="tableLayout">
            {colgrp}
            <tbody>
              {lstFoundTch && lstFoundTch.length > 0 ? lstFoundTch.map((obj, idx) => (
                <tr className={obj.staffId === selectedIdx ? 'selected' : ''} key={idx} onClick={(event) => rowClick(obj.staffId)} >
                  <td className='text-left'>{obj.staff}</td>
                  <td>{obj.availability}</td>
                  <td >{obj.currentLoad}</td>
                  <td >{obj.completed}</td>
                  <td>{obj.total}</td>
                </tr>
              )) : null
              }
            </tbody>
          </table>
        </div>
      </div>
      <div className="form form-horizontal" >
        <div className="col-md-6 col-md-offset-1" style={{ border: '1px solid #ccc', paddingTop: 10 }}>
          <div className="form-group">
            <div className='col-sm-8 padding-remove'>
              <label className="control-label col-sm-4">Expected Time<span className="red-color">*</span></label>
              <div className='col-sm-4 padding-remove'>
                <input type='text' className="form-control" onChange={(event) => setExpHrs(event.target.value)} ></input>
              </div>

              <div className="col-sm-3">
                <button type="button" className="btn btn-info " id="assignCounsellor" onClick={saveAllocation} >Assign</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllocateSlider