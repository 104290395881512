import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
const httpURL = lstModules['transport'].url + ':' + lstModules['transport'].port;
var tokenid = localStorage.getItem('tokenid');
function DriverVehicleType(props) {
    const [staffId, setStaffId] = useState()
    const [loader, setLoader] = useState('none')
    const [lstDriver, setLstDriver] = useState([])
    const [age, setAge] = useState()
    const [drivingExp, setDrivingExp] = useState()
    const [schoolExp, setSchoolExp] = useState()
    const [licenseNo, setLiscenceNo] = useState()
    const [licenseType, setLiscenceType] = useState()
    const [licenseExpiryDt, setLiscenceExpiryDt] = useState()
    const [lstVehicleType, setLstVehicleType] = useState([])
    const [vehicleTypeArr, setVehicleTypeArr] = useState([])
    useEffect(() => {
        getDtls(cbGetDtls)
        setStaffId(props.selStaffId)
    }, [])
    function save(callback) {
        var driver = {
            staffId: props.selStaffId,
            // age:age,
            drivingExp: drivingExp,
            schoolExp: schoolExp,
            licenseNo: licenseNo,
            licenseType: licenseType,
            licenseExpiryDt: licenseExpiryDt,
            lstVehicleTypeId: vehicleTypeArr
        }
        var formData = new FormData();
        formData.append("driver", JSON.stringify(driver))

        axios(
            {
                url: httpURL + "/sms/tpt/driver/save",
                method: 'POST',
                data: formData,
                headers: { 'tokenid': tokenid },
                withCredentials: true
            }
        ).then(
            (Response) => {
                var data = Response.data
                callback(data)



            }
        ).catch((error) => {
            console.error(error)
        });
    }
    function cbSave(data) {
        if (data.SvcStatus === 'Success') {
            toast.success(data.SvcMsg)
            props.closeSlider()

        }
        else {
            toast.error(data.SvcMsg)
        }
    }
    function getDtls(callback) {
        // console.log(props.selStaffId)
        if (!props.selStaffId || props.selStaffId < 0) {
            return
        }
        axios(
            {
                url: httpURL + "/sms/tpt/driver/dtls",
                method: 'POST',
                params: { staffId: props.selStaffId },
                headers: { 'tokenid': tokenid },
                withCredentials: true
            }
        ).then(
            (Response) => {
                var data = Response.data;
                callback(data);
            }
        ).catch((error) => {
            // console.log("error" + error)
        });
    }
    function cbGetDtls(data) {
        if (data.SvcStatus === "Success") {
            setLstDriver(data.driver)
            setAge(data.driver.age)
            setDrivingExp(data.driver.drivingExp)
            setSchoolExp(data.driver.schoolExp)
            setLstVehicleType(data.driver.lstVehicleType)
            setLiscenceType(data.driver.licenseType)
            setLiscenceNo(data.driver.licenseNo)
            setLiscenceExpiryDt(data.driver.licenseExpiryDt)
            makeArrVchType(data.driver.lstVehicleType)
        }
        else {
            toast.error(data.SvcMsg)
        }
        setLoader('none');
    }
    function makeArrVchType(data) {
        var tempArr = []
        for (var i = 0; i < data.length; i++) {
            if (data[i].selected === true) {
                tempArr.push(data[i].vehicleTypeId)
            }

        }
        setVehicleTypeArr(tempArr)

    }
    function handleCbxChange(id) {
        if (vehicleTypeArr.includes(id)) {
            var tempArr = vehicleTypeArr
            tempArr.splice(tempArr.indexOf(id))
            setVehicleTypeArr(tempArr)
        }
        else {
            var tempArr = vehicleTypeArr
            tempArr.push(id)
            setVehicleTypeArr(tempArr)
        }
    }
    const colgrp = (
        <colgroup>
            <col width="8%"></col>
            <col width="8%"></col>
        </colgroup>
    );
    return (<form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", height: '100%', width: '100%', margin: '0px auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
        <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
            <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
        </div>
        <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={props.closeSlider}>
            <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
        </button>
        <div className='row'>
            <div className=" col-sm-5 col-sm-offset-4" style={{ paddingTop: '10px' }}>
                <table>
                    {colgrp}
                    <thead>
                        <tr>
                            <th>
                                Vehicle Type
                            </th>
                            <th>
                                Select
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lstVehicleType.map((obj, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>
                                            {obj.vehicleType}
                                        </td>
                                        <td align="center">
                                            <input type='checkbox' defaultChecked={obj.selected} onChange={(e) => handleCbxChange(obj.vehicleTypeId)}></input>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
        <div className="row" style={{ paddingTop: '10px' }}>
            <button type="button" className="btn btn-info btn-space col-md-offset-6" onClick={(e) => save(cbSave)}>Save</button>
            {/* </div>
            <div className="row"> */}
        </div>
    </form>)
}
export default DriverVehicleType;