import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';

import { getSchedulePeriodListSvc } from "./academicScheduleSvc"

const PeriodTable = (props) => {
    const { academicScheduleId, editPeriodForm, setLoader, setOpacity, key  } = props;

    const [lstSchedulePeriod, setLstSchedulePeriod] = useState([]);
    

    useEffect(() => {
        // console.log("useEffect in periodTable called");
        // console.log("academicScheduleId get", academicScheduleId);
        setPeriodTable(academicScheduleId);
    }, [key]);


    const setPeriodTable = (academicScheduleId) => {
        setLoader("block");
        setOpacity(0.5);
        getSchedulePeriodListSvc(academicScheduleId, getPeriodDataResponse);
    }

    const getPeriodDataResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            setLstSchedulePeriod(obj.lstSchedulePeriod);
            // console.log("obj.lstSchedulePeriod in getPeriodDataResponse", obj.lstSchedulePeriod);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    return (
        <>
            <table className="tableLayout1 periodTable">
                <thead>
                    <tr>
                        <th style={{ width: 40 }}> Period</th>
                        <th style={{ width: 40 }}> Start Time </th>
                        <th style={{ width: 40 }}> Type</th>
                        <th style={{ width: 40 }}> Duration(Min)</th>
                    </tr>
                </thead>
            </table>
            <div className="tableDivMedium">
                <table id="periodTable" className="tableLayout">
                    <tbody id="periodTableBody">
                        {lstSchedulePeriod &&
                            lstSchedulePeriod.length > 0 &&
                            lstSchedulePeriod.map((period) => (
                                <tr key={period.schedulePeriodId} id={period.schedulePeriodId}
                                    onClick={() => editPeriodForm(period.schedulePeriodId, period.period, period.startTm, period.periodType, period.duration, period.flgAttendancePeriod)}>
                                    <td width="40" className="no-display">{period.schedulePeriodId}</td>
                                    <td width="40" className="text-center">{period.period}</td>
                                    <td width="40" className="text-center">{period.startTm}</td>
                                    <td width="40" className="text-center">{period.periodType}</td>
                                    <td width="40" className="text-center">{period.duration}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default PeriodTable;