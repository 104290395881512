import React, { useState, useEffect } from 'react'
import Header from 'common/js/header/header.js'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import './Registration.css'
import lstModules from 'common/json/lstModules.json'
import RegistrationPersonal from './RegistrationPersonal.js'
import RegistrationCategory from './RegistrationCategory.js'
import RegistrationCommunication from './RegistrationCommunication.js'
import RegistrationParents from './RegistrationParents.js'
import RegistrationSiblings from './RegistrationSiblings.js'
import RegistrationHistory from './RegistrationHistory.js'
import RegistrationDocs from './RegistrationDocs.js'

var httpURL = lstModules['enquiry'].url + ':' + lstModules['enquiry'].port

const AdmissionRegsCtrl = () => {
  const [lstDocDtls, setLstDocDtls] = useState([])
  const [photoPath, setPhotoPath] = useState('')
  const [loader, setLoader] = useState('none')
  const PHOTO = 50
  const search = useLocation().search
  const [admissionId, setAdmissionId] = useState(
    new URLSearchParams(search).get('admissionId')
  )
  const [userId, setUserId] = useState(
    new URLSearchParams(search).get('userId')
  )
  useEffect(() => {
    setLoader('block')
    getDocDtlsSvc(admissionId, cbIdDocDtlsResponse)
  }, [])

  function getDocDtlsSvc (admissionId, callBack) {
    axios({
      url: httpURL + '/sms/admission/docs/dtls',
      method: 'POST',
      params: { admissionId },
      withCredentials: true
    })
      .then(Response => {
        var data = Response.data
        // console.log('list fetching success')
        callBack(data)
      })
      .catch(error => {
        // console.log('error')
      })
  }
  function cbIdDocDtlsResponse (pLstDocDtls) {
    if (pLstDocDtls.SvcStatus === 'Success') {
      setLstDocDtls(pLstDocDtls.lstDocDtls)
      for (var i = 0; i < pLstDocDtls.lstDocDtls.length; i++) {
        var docObj = pLstDocDtls.lstDocDtls[i]
        if (docObj.typeId === PHOTO) {
          setPhotoPath(
            httpURL+'/sms/admission/docs/view?imgPath=' +
              docObj.imgPath
          )
        }
      }
    } else {
      toast.error(pLstDocDtls.SvcMsg)
    }
    setLoader('none')
  }
  const getWingId = wingId => {
    // console.log('wingId = ' + wingId)
  }
  return (
    <div className='col-sm-12 padding-remove regsContainer'>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className='container-fluid'>
        <div id='divPersonal' className='divPersonal row-fluid'>
          <div className='col-sm-12 section-head'>
            <h5>Personal </h5>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 section'>
            <RegistrationPersonal
              admissionId={admissionId}
              photoPath={photoPath}
            />
          </div>
        </div>

        <div id='divCategory' className='divCategory'>
          <div className='col-sm-12 section-head'>
            <h5>Category </h5>
          </div>
          <div
            className='col-xs-12 col-sm-12 col-md-12 col-lg-12 section form-inline'
            style={{ height: 50 }}
          >
            <RegistrationCategory admissionId={admissionId} />
          </div>
        </div>

        <div id='divCommunication' className='divCommunication'>
          <div className='col-sm-12 section-head'>
            <h5>Communication </h5>
          </div>
          <div
            className='col-xs-12 col-sm-12 col-md-12 col-lg-12 section'
            style={{ height: 50 }}
          >
            <RegistrationCommunication admissionId={admissionId} />
          </div>
        </div>

        <div id='divParents' className='divParents'>
          <div className='col-sm-12  section-head'>
            <h5>Parents </h5>
          </div>
          <div
            className='col-xs-12 col-sm-12 col-md-12 col-lg-12 section'
            style={{ height: 420 }}
          >
            <RegistrationParents admissionId={admissionId} />
          </div>
        </div>

        <div id='divSiblings' className='divSiblings'>
          <div className='col-sm-12  section-head'>
            <h5>Siblings </h5>
          </div>
          <div
            className='col-xs-12 col-sm-12 col-md-12 col-lg-12 section'
            style={{ height: 120 }}
          >
            <RegistrationSiblings admissionId={admissionId} />
          </div>
        </div>

        <div id='divHistory' className='divHistory'>
          <div className='col-sm-12  section-head'>
            <h5>History </h5>
          </div>
          <div
            className='col-xs-12 col-sm-12 col-md-12 col-lg-12 section'
            style={{ height: 350 }}
          >
            <RegistrationHistory admissionId={admissionId} />
          </div>
        </div>

        <div id='divDocs' className='divDocs'>
          <div className='col-sm-12  section-head'>
            <h5>Documents </h5>
          </div>
          <div
            className='col-xs-12 col-sm-12 col-md-12 col-lg-12 section'
            style={{ height: 200, marginBottom: 10 }}
          >
            <RegistrationDocs
              admissionId={admissionId}
              lstDocDtls={lstDocDtls}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdmissionRegsCtrl
