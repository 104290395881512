import React, { Component } from 'react'
import lstModules from 'common/json/lstModules.json'
import Header from 'common/js/header/header.js'
import SyllabusTable from './syllabusTable'
// import SyllabusForm from './syllabusFormOld';
import SyllabusForm from './syllabusForm';
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import $ from 'jquery'
import { uploadSyllabusExcel } from './syllabusSvc'
import UploadErrorSlider from 'common/components/UploadErrorSlider';
import '../css/syllabus.css'

var queryString = require('querystring')
var httpURL = ''
// var screenId= 29;

export default class Syllabus extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lstSyllabus: [],
      lstError: [],
      tokenid: localStorage.getItem('tokenid'),
      screenId: 29,
      syllabusId: 0,
      objData: {},
      flg: true,
      flgSlider: false,
      loader: 'none',
      opacity: 1,
      formKey:0,
      ctx: this
    }
  }

  componentDidMount = () => {
    // console.log("syllabus.jsx called");
    this.getSyllabusList()
  }
  addReady = () => {
    $('#addForm').trigger('reset')
    $('#tableBody tr').removeClass('selected')
    this.setState({
      flg: true,
      syllabusId: 0
    })
  }

  getSyllabusList = () => {
    this.setState({ loader: 'block', opacity: 0.5 })
    axios({
      url: httpURL + '/sms/syllabus/list',
      method: 'POST',
      params: { screenId: this.state.screenId, wingId: 1 },
      headers: { tokenid: this.state.tokenid },
      withCredentials: true
    })
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          this.setState({
            lstSyllabus: response.data.lstSyllabus
          })
        } else {
          toast.error(response.data.SvcMsg)
          if (response.data.SvcMsg === 'You are not logged in') {
            setTimeout(function () {
              window.location.href = '/'
            }, 2000)
            return false
          } else if (
            response.data.SvcMsg === 'Your Session is expired, login again'
          ) {
            setTimeout(function () {
              window.location.href = '/'
            }, 2000)
            return false
          }
        }
        this.setState({ loader: 'none', opacity: 1 })
      })
      .catch(error => {
        this.setState({ loader: 'none', opacity: 1 })
        toast.error('Service failed ' + error)
      })
  }

  addSyllabus = ({ syllabusCode, syllabusNotes }) => {
    var syllabusId = this.state.syllabusId
    var syllabus = {}

    syllabus = {
      syllabusId: syllabusId,
      syllabusCode: syllabusCode,
      syllabusNotes: syllabusNotes,
      wingId: 1
    }

    axios
      .post(
        httpURL + '/sms/syllabus/save',
        queryString.stringify({
          syllabus: JSON.stringify(syllabus),
          screenId: this.state.screenId
        }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true
        }
      )
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          toast.success(response.data.SvcMsg)
          this.updateKey();
          this.getSyllabusList()
        } else {
          toast.error(response.data.SvcMsg)
        }
        this.setState({ loader: 'none', opacity: 1 })
      })
      .catch(e => {
        this.setState({ loader: 'none', opacity: 1 })
        toast.error('Service failed: ' + e)
      })
  }

  updateKey = () => {
    this.setState((prevState) => ({
      key: prevState.key + 1
    }));
  };

  setForm = syllabusId => {
    this.setState({
      flg: false,
      syllabusId: syllabusId
    })
  }

  // getChapterList = sybSubId => {
  //   this.setState({ loader: 'block', opacity: 0.5 })
  //   axios({
  //     url: httpURL + '/sms/syllabus/chapter/list',
  //     method: 'POST',
  //     params: { screenId: this.state.screenId, sybSubId: sybSubId },
  //     headers: { tokenid: this.state.tokenid },
  //     withCredentials: true
  //   })
  //     .then(response => {
  //       if (response.data.SvcStatus === 'Success') {
  //         this.setState({
  //           lstChapters: response.data.lstChapters,
  //           sybSubId: sybSubId
  //         })
  //       } else {
  //         toast.error(response.data.SvcMsg)
  //         if (response.data.SvcMsg === 'You are not logged in') {
  //           setTimeout(function () {
  //             window.location.href = '/'
  //           }, 2000)
  //           return false
  //         } else if (
  //           response.data.SvcMsg === 'Your Session is expired, login again'
  //         ) {
  //           setTimeout(function () {
  //             window.location.href = '/'
  //           }, 2000)
  //           return false
  //         }
  //       }
  //       this.setState({ loader: 'none', opacity: 1 })
  //     })
  //     .catch(error => {
  //       this.setState({ loader: 'none', opacity: 1 })
  //       toast.error('Service failed ' + error)
  //     })
  // }

  closeSlider = () => {
    // console.log("closeSlider called in syllabus.jsx");
    $('body').removeClass('openSlider')
  }

  triggerUploadExcel = () => {
    $('#excelUpload').trigger('click')
  }

  uploadExcel = event => {
    // this.setState({ loader: 'block', opacity: 0.5 });
    /* $("#excelUpload").on('change', function (event) { */

    if (!event.target.files) {
      this.setState({ loader: 'none', opacity: 1 })
      return
    }

    var files = event.target.files //FileList object
    /* if (!event.target.files.length) {
            // console.log("no file selected")
            this.setState({ loader: 'none', opacity: 1 });
            return;
        } */
    files = files[0]
    var formData = new FormData()
    formData.append('syllabusUploadExcelFile', files)
    uploadSyllabusExcel(formData, this.cbSyllabusExcelUpload, this)

    /* }); */
  }

  cbSyllabusExcelUpload (data, ctx) {
    if (data.status === 'Success') {
      toast.success(data.message)
      // this.getSyllabusList()
      // $('#btn_showerror').trigger('click');
    } else if (data.status === 'Failure') {
      toast.error(data.message)
      // console.log(data.hasOwnProperty('errors'))
      // console.log(data.errors.length !== 0)
      // console.log(ctx)
      if (data.hasOwnProperty('errors') && data.errors.length !== 0) {
        ctx.setErrorList(data.errors)
        // console.log('control is here L194')
        ctx.showErrors()
      }
    } else {
      toast.error(data.message)
      if (data.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (data.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      }
    }
    ctx.setState({ loader: 'none', opacity: 1 })
  }

  setErrorList = async errors => {
    // console.log(errors)
    await this.setState({ lstError: errors })
  }
  showSlider () {
    $('body').addClass('openSlider')
  }
  showErrors = () => {
    // console.log('slider should be open')
    this.setState({ flgSlider: true })
    this.showSlider()
  }

  render () {
    httpURL = lstModules['syllabus'].url + ':' + lstModules['syllabus'].port
    return (
      <div style={{ height: '100%' }}>
        <Header wingFromChild={this.getWingId} />
        <ToastContainer autoClose={3000} hideProgressBar />
        <div
          style={{
            position: 'fixed',
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: '50%',
            top: '50%',
            display: this.state.loader
          }}
        >
          <img
            className='loderImg'
            src={require('common/images/loader.gif')}
            alt='Loader...'
          />
        </div>
        <div
          className='mainContainer Building'
          style={{ marginTop: 15, opacity: this.state.opacity }}
        >
          <div className='col-md-10 col-centered'>
            <div className='col-md-5'>
              <SyllabusTable
                lstSyllabus={this.state.lstSyllabus}
                setForm={this.setForm}
              />
            </div>
            <div className='col-xs-1 '>
              <div>
                <span
                  id='addReadyId'
                  title='Add'
                  onClick={this.addReady}
                  className='addIcon glyphicon glyphicon-plus-sign'
                ></span>
              </div>
              <div>
                <span
                  id='uploadSyllabus'
                  title='Upload Syllabus'
                  onClick={this.triggerUploadExcel}
                  className='addIcon glyphicon glyphicon-upload'
                ></span>
              </div>
              <input
                key={new Date().getTime()}
                type='file'
                id='excelUpload'
                className='hidden'
                accept='.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                onChange={e => this.uploadExcel(e)}
              />
              <div>
                <span
                  id='viewErrorList'
                  title='View Error List'
                  onClick={this.showErrors}
                  className='addIcon glyphicon glyphicon-list-alt hidden'
                ></span>
              </div>
            </div>
            <div className='col-md-6'>
              <SyllabusForm
                addSyllabus={this.addSyllabus}
                syllabusId={this.state.syllabusId}
                lstSyllabus={this.state.lstSyllabus}
                // getChapterList={this.getChapterList}
                lstChapters={this.state.lstChapters}
                sybSubId={this.state.sybSubId}
                displayForm={this.state.flg}
                updateKey={this.state.formKey}
              />
            </div>
          </div>
        </div>
        <div className='slider halfWidthSlider'>
          {this.state.flgSlider && (
            <UploadErrorSlider
              heading='Syllabus'
              lstErrors={this.state.lstError}
              countError={this.state.lstError.length || 0}
              removeClassFromBody={this.closeSlider}
            />
          )}
        </div>
      </div>
    )
  }
}
