import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { saveEventSvc, getLstClsSection } from "./eventSvc";
import { toast, ToastContainer } from 'react-toastify';

const EventSlider = (props) => {
    const [loader, setLoader] = useState("none")
    const [eventId, setEventId] = useState(props.eventId)
    const [event, setEvent] = useState('');
    const [eventObj, setEventObj] = useState(props.eventObj);
    const [lstCls, setLstCls] = useState([])
    const [lstClsId, setLstClsId] = useState([])
    const [cls, setCls] = useState('')
    const [found, setFound] = useState([])
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState('');
    const [files, setFiles] = useState([])
    const [eventDt, setEventDt] = useState(null);

    useEffect(() => {
        // console.log("eventSlider called props.eventObj", props.eventObj)
        setEventId(props.eventId)
        setEventObj(props.eventObj)
        setEvent(props.eventObj.event)
        setDescription(props.eventObj.eventDesc)
        setEventDt(getDateForCalendar(props.eventObj.eventDt))
        setTags(props.eventObj.tags)
        // getLstClass();
        getClsSection();
        setFound([])
    }, [props.eventId, props.eventObj]);

    function getDateForCalendar(strDt) {
        if (!strDt) return null;
        var parts = strDt.split("-");
        var d = parts[0];
        var m = parts[1] - 1;
        var y = parts[2];

        return new Date(y, m, d);
    }
    function getClsSection() {
        getLstClsSection(handleClsSectionResponse);
        // getFakeLstClsSection()
    }
    function handleClsSectionResponse(data) {
        var obj = data;
        if (obj.status == 'Success') {
            setLstCls(data.lstClsSection);
            setFound(data.lstClsSection)
            setStateLstClass(data.lstClsSection);
        } else if (obj.status == 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed " + obj.message);
        }
    }
   /*  function getLstClass() {
        // console.log("getLstclass called");
        getClassListSvc(cbClsResponseChange)
    } 
    function cbClsResponseChange(data) {
        if (data.status === "Success") {
            setLstCls(data.lstClass);
            setFound(data.lstClass)
            setStateLstClass(data.lstClass);
        }

    }*/

    function handleEventChange(param) {
        setEvent(param)
    }
    function handleDescChange(param) {
        setDescription(param)
    }
    function handleTagsChange(param) {
        setTags(param)
    }
    function saveEvent() {
        let pEventObj = {};
        pEventObj = {
            eventId: props.eventId,
            event: event,
            lstClsSection: lstClsId,
            eventDesc: description,
            tags: tags,
            eventDt: eventDt
        }
        setLoader("block")
        saveEventSvc(pEventObj, files, cbSaveEventResponse)
    }
    function cbSaveEventResponse(data) {
        setLoader("none")
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg)
            props.getEventList();
        } else {
            toast.error(data.SvcMsg)
        }
    }
    const colgrp = <colgroup>
        <col width="10%"></col>
        <col width="20%"></col>
    </colgroup>
    const filterClass = async (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = found.filter((obj) => {
                if(obj.selected || (obj.className+" - "+obj.section).toLowerCase().includes(keyword.toLowerCase())){
                    return obj
                }
                // Use the toLowerCase() method to make it case-insensitive
            });
            // console.log(results)
            await setFound(results);
        } else {
            // console.log(lstCls);
            await setFound(lstCls);
            // If the text field is empty, show all objs
        }

        setCls(keyword);
    };

    function setStateLstClass(pLstClassId) {
        var tmpArr = [];
        for (var i = 0; i < pLstClassId.length; i++) {
            var obj = pLstClassId[i];
            if (obj.selected) {
                tmpArr.push(obj.classSectionId)
            }
        }
        // console.log(tmpArr);
        setLstClsId(tmpArr);
    }
    function handleCBoxChange(pClsId, status) {
        // console.log(pClsId, status)
        var lstTempArr = lstClsId
        if (status && !lstClsId.includes(cls)) {

            lstTempArr.push(cls)
            setLstClsId(lstTempArr)
            // console.log("checked " + lstTempArr)

            for (var i = 0; i < found.length; i++) {
                if (found[i].clsSectionId === pClsId) {
                    found[i].selected = true
                }
            }
        }
        else {

            lstTempArr.splice(lstTempArr.indexOf(pClsId))
            setLstClsId(lstTempArr)
            // console.log("unchecked" + lstTempArr)
            //returnSelEnqId()
            for (var i = 0; i < found.length; i++) {
                if (found[i].clsSectionId === pClsId) {
                    found[i].selected = false
                    // lstSubject[i].selected=false
                }
            }
        }
    }
    const handleIdClick = e => {
        // console.log(document.getElementById('img_id'))
        // document.getElementById("img_id").dispatchEvent(new Event('click'));
        document.getElementById('img_id').click()
    }

    const onIdChange = e => {
        // console.log(e.target.files[0].name)
        // console.log('src:' + URL.createObjectURL(e.target.files[0]))
        if (e.target.files) {
            setFiles(e.target.files)
            // setImgId(URL.createObjectURL(e.target.files))
            // setLoader('block')
            // saveImgIdSvc(e.target.files, 10, cbSaveImgIdResponse)
        }
    }
    /* function saveImgIdSvc(file, type, callBack) {
        var formData = new FormData()
        formData.append('event', event)
        formData.append('file', file)
        formData.append('tags', '')
        saveEventSvc()
    }
    function cbSaveImgIdResponse(data) {
        if (data.SvcStatus === 'Success') {
            toast.success(data.SvcMsg)
        } else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
 */
    /* const onError = () => {
         setImgId(window.appURL + '/no-image.png')
     } */
    return (
        <>
            <form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", height: 'auto', width: '90%', margin: '10px auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={props.closeSlider} style={{ right: "50%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }} >
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div >
                <div className='row no-display' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Event Id</label>
                        <div className='col-sm-6 padding-remove'>
                            <input type="text" defaultValue={eventId} className="form-control" readOnly></input>
                        </div>
                    </div>
                </div>

                <div className='form-group' style={{ width: '100%' }}>
                    <label className="control-label col-sm-2" style={{ width: '19%' }}>Event</label>
                    <div className="form-group col-sm-10">
                        <div className='col-sm-6 padding-remove'>
                            <input type="text"
                                defaultValue={event}
                                className="form-control"
                                onChange={(e) => handleEventChange(e.target.value)}
                                maxLength="60" />
                        </div>
                        <label className="control-label col-sm-3" >Event Date<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-3 padding-remove">
                            <DatePicker
                                id='inp_date'
                                className='form-control text-center'
                                placeholderText='Date'
                                selected={eventDt}
                                onChange={setEventDt}
                                dateFormat='dd-MM-yyyy'
                                autoComplete='off'
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode='select'
                            />
                        </div>
                    </div>
                </div>
                <div className='form-group' style={{ width: '100%' }}>
                    <label className="control-label col-sm-2" style={{ width: '19%' }}>Description</label>
                    <div className="form-group col-sm-8">
                        <div className='col-sm-12 padding-remove'>
                            <textarea
                                defaultValue={description}
                                className="form-control"
                                onChange={(e) => handleDescChange(e.target.value)}
                                maxLength="300"></textarea>
                        </div>
                    </div>
                </div>
                <div className='form-group' style={{ background: 'transparent' }}>
                    <label className='col-sm-3 control-label' style={{ width: '19%' }}>Upload Images</label>
                    <div className='form-group col-sm-1'>
                        <input
                            type='file'
                            id='img_id'
                            name='img'
                            onChange={onIdChange}
                            className='hidden'
                            accept='image/*'
                            multiple
                        />
                        <img
                            src={window.appURL + '/browse_icon.png'}
                            alt='img'
                            onClick={e => handleIdClick(e)}
                            height='60'
                            width='60'
                            style={{ height:20, width:20 }}
                        />
                    </div>
                </div>
                <div className='form-group' style={{ width: '100%' }}>
                    <label className="control-label col-sm-2" style={{ width: '19%' }}>Tags</label>
                    <div className="form-group col-sm-8">
                        <div className='col-sm-12 padding-remove'>
                            <textarea
                                defaultValue={tags}
                                className="form-control"
                                onChange={(e) => handleTagsChange(e.target.value)}
                                maxLength="200"></textarea>
                        </div>
                    </div>
                </div>
                <div className='form-group' style={{ width: '100%' }}>
                    <label className="control-label col-sm-2" style={{ width: '19%' }}>Class</label>
                    <div className="form-group col-sm-6">
                        <table className="tableLayout1">
                            {colgrp}
                            <thead>
                                <tr>
                                    <th>Select</th>
                                    <th className="padding-remove">
                                        <input onChange={filterClass} type="text" value={cls} className="form-control inp-search" placeholder="Class" />
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <div className='tablebodyWrapper'>
                            <table className='tableLayout'>
                                {colgrp}
                                <tbody>
                                    {found && found.length ?
                                        found.map((obj, idx) => {
                                            return <tr key={idx} id={JSON.stringify(obj)}>
                                                <td className="no-display">{obj.classSectionId}</td>
                                                <td className="text-left">
                                                    <input
                                                        key={obj.classSectionId}
                                                        type="checkbox"
                                                        defaultChecked={obj.selected}
                                                        onChange={(e) => handleCBoxChange(obj.classSectionId, e.target.checked)}
                                                    />

                                                </td>
                                                <td className="text-left">{obj.className} - {obj.section}</td>
                                            </tr>
                                        })
                                        : null
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%', paddingBottom: "1px" }}>
                    <div className="col-md-offset-10 col-md-1 padding-remove">
                        <button type="button" className="btn btn-info pull-right" onClick={(e) => saveEvent()}>Save</button>
                    </div>
                </div>
            </form>
        </>
    )
}
export default EventSlider;