import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "../css/studentFeeDetails.css";
// import $ from "jquery";
import {
  getStudentFeeDtlsSvc,
  // getDiscountType,
  saveStudentFeeDtlsSvc,
  removeDiscountSvc,
  // feeBreakdownSvc,
} from "./studentFeeDetailsDiscountSvc";
// import FeeBreakdown from "./feeBreakdown.js";

const StudentFeeDetails = (props) => {
  const { studentId, closeDiscountSlider } = props;
  const [lstDiscount, setLstDiscount] = useState([]);
  const [loader, setLoader] = useState("none");
  const [opacity, setOpacity] = useState(1);
  const [lstSaveDiscount, setLstSaveDiscount] = useState([]);
  // const [lstDiscountType, setLstDiscountType] = useState([]);
  const [lstDiscountType, setLstDiscountType] = useState();
  const [isPopupVisible, setIsPopupVisible] = useState("false");
  const [lstFee, setLstFee] = useState([]);
  const [highlightedLinkId, setHighlightedLinkId] = useState(null);
  const [monthId, setMonthId] = useState(0);
  const [feeStudentId, setFeeStudentId] = useState(0);

  const [studentName, setStudentName] = useState(props.studentName);
  const [totalFees, setTotalFees] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalNetFee, setTotalNetFee] = useState(0);
  const [totalPaidFee, setTotalPaidFee] = useState(0);
  const [totalFeeBalance, setTotalFeeBalance] = useState(0);
  const [keySummary, setKeySummary] = useState(0);

  const month = [
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar",
  ];

  useEffect(() => {
    // console.log("studentFeeDiscount called");
    // console.log("key", props.feeDtlskey);
    // console.log("studentId", props.studentId);
    // console.log("studentName", props.studentName);
    if (keySummary === 0 && studentId !== 0) {
      // getDiscountType(discountTypeResponse);
      setStudentFeeDetailsTable();
    }
    // console.log("setFeeDiscountSummary called from useEffect");
    setFeeDiscountSummary();
  }, [keySummary]);

  // const discountTypeResponse = (data) => {
  //   var obj = data;
  //   if (obj.status === "Success") {
  //     toast.success(obj.message);
  //     setLstDiscountType(obj.lstDiscountType);
  // console.log("obj.lstDiscountType", obj.lstDiscountType);
  //   } else {
  //     toast.error(obj.message);
  //   }
  //   // setLoader('none');
  //   // setOpacity(1);
  // };

  const setStudentFeeDetailsTable = () => {
    setLoader("block");
    setOpacity(0.5);
    getStudentFeeDtlsSvc(studentId, discountTableResponse);
    // getTableData(1, discountTableResponse);
    // console.log("setFeeDiscountSummary called from setStudentFeeDetailsTable");
    // setFeeDiscountSummary();
  };

  const discountTableResponse = (data) => {
    // return new Promise((resolve) => {
    var obj = data;
    if (obj.status === "Success") {
      toast.success(obj.message);
      setLstDiscount(obj.lstDiscount);
      setKeySummary(prevKeySummary => prevKeySummary + 1);
      // setSaveDiscount();
    } else {
      toast.error(obj.message);
    }
    setLoader("none");
    setOpacity(1);
    //   resolve();
    // });
  };

  // const onClickFeeBreakdown = (mnthId) => {
  //   if (isPopupVisible) setIsPopupVisible(false);
  //   setHighlightedLinkId(mnthId);
  //   setMonthId(mnthId);
  //   showFeeBreakdown(studentId, mnthId);
  // };

  // const showFeeBreakdown = (wvrId, mnthId) => {
  // console.log("showFeeBreakdown called");
  //   feeBreakdownSvc(studentId, mnthId, feeBreakdownResponse);
  // };

  // const hideFeeBreakdown = () => {
  //   $("body").removeClass("showPopup");
  //   setHighlightedLinkId(null);
  //   setIsPopupVisible(false);
  // };

  // const feeBreakdownResponse = (data) => {
  //   var obj = data;
  //   if (obj.status === "Success") {
  //     toast.success(obj.message);
  //     setLstFee(obj.lstFee);
  // console.log("obj.lstFee", obj.lstFee);
  // console.log("obj.lstFee[0].feeHeadCode", obj.lstFee[0].feeHeadCode);

  //     if (obj.lstFee.length > 0) {
  //       $("body").addClass("showPopup");
  //       setIsPopupVisible(true);
  //     } else {
  //       toast.error();
  //     }
  //   } else {
  //     setHighlightedLinkId(null);
  //     toast("no fee breakdown data available");
  //   }
  // };

  const removeDiscounts = () => {
    var tmpLstWaiverId = [];
    lstDiscount.map((discountRow) => {
      tmpLstWaiverId.push(discountRow.waiverId);
    });
    removeDiscountSvc(tmpLstWaiverId, removeDiscountResponse);
  };

  const removeDiscountResponse = (data) => {
    var obj = data;
    if (obj.status === "Success") {
      toast.success(obj.message);
      setStudentFeeDetailsTable();
    } else {
      toast.error(obj.message);
    }
    setLoader("none");
    setOpacity(1);
  };

  const saveDiscount = () => {
    setLoader("block");
    setOpacity(0.5);
    // console.log("lstDiscount before saving lstSaveDiscount", lstDiscount);
    var tmpLstSaveData = [];
    lstDiscount.map((discountRow) => {
      const updatedDiscount = {
        monthId: discountRow.monthId,
        totalFees: discountRow.totalFees,
        discountId: discountRow.discountId,
        discountAmt: discountRow.discountAmt,
        flgLateFee: discountRow.flgLateFee,
        remarks: discountRow.remarks,
        feeStudentId : discountRow.feeStudentId ,
        headId: discountRow.feeHeadId,
      };
      tmpLstSaveData.push(updatedDiscount);
    });
    setLstSaveDiscount(tmpLstSaveData);
    saveStudentFeeDtlsSvc(studentId, tmpLstSaveData, saveDiscountResponse);
  };

  const saveDiscountResponse = (data) => {
    var obj = data;
    if (obj.status === "Success") {
      toast.success(obj.message);
      setStudentFeeDetailsTable();
    } else {
      toast.error(obj.message);
    }
    setLoader("none");
    setOpacity(1);
  };

  const updateDiscountAmount = (wId, e) => {
    // console.log('updateDiscountAmount.. '+mnthId+'::'+e.target.value)
    var tmpLstDiscount = [...lstDiscount];
    for (var i = 0; i < tmpLstDiscount.length; i++) {
      if (tmpLstDiscount[i].waiverId === wId) {
        // console.log('mnthId matched::'+mnthId)
        tmpLstDiscount[i] = {
          ...tmpLstDiscount[i],
          discountAmt: e.target.value,
        };
        break;
      }
    }
    setLstDiscount(tmpLstDiscount);
    setKeySummary(prevKeySummary => prevKeySummary + 1);
    // console.log("setFeeDiscountSummary called from updateDiscount Amount method");
    // setFeeDiscountSummary();
    // console.log("tmplstDiscount after updating discount amount",tmpLstDiscount);
  };

  const updateRemark = (wId, e) => {
    var tmpLstDiscount = [...lstDiscount];
    for (var i = 0; i < tmpLstDiscount.length; i++) {
      if (tmpLstDiscount[i].waiverId === wId) {
        tmpLstDiscount[i] = { ...tmpLstDiscount[i], remarks: e.target.value };
        break;
      }
    }
    setLstDiscount(tmpLstDiscount);
  };

  const updateFlgLateFee = (wId, e) => {
    var tmpLstDiscount = [...lstDiscount];
    for (var i = 0; i < tmpLstDiscount.length; i++) {
      if (tmpLstDiscount[i].waiverId === wId) {
        tmpLstDiscount[i] = {
          ...tmpLstDiscount[i],
          flgLateFee: !tmpLstDiscount[i].flgLateFee,
        };
        break;
      }
    }
    setLstDiscount(tmpLstDiscount);
  };

  const updatedDiscountType = (e, mnthId) => {
    var tmpLstDiscount = [...lstDiscount];
    for (var i = 0; i < tmpLstDiscount.length; i++) {
      if (tmpLstDiscount[i].monthId === mnthId) {
        tmpLstDiscount[i] = {
          ...tmpLstDiscount[i],
          discountId: e.target.value,
        };
        break;
      }
    }
    setLstDiscount(tmpLstDiscount);
    // console.log("temporary list after update", tmpLstDiscount);
  };

  const setFeeDiscountSummary = () => {
    // console.log("inside setFeeDiscountSummary method ");
    // console.log("values befor calculations");
    // console.log("lstDiscount ", lstDiscount);
    // console.log("totalFees", totalFees);
    // console.log("totalDiscount", totalDiscount);
    // console.log("totalNetFee", totalNetFee);
    // console.log("totalFeeBalance", totalFeeBalance);

    let tFee = 0;
    let tDisc = 0;
    let tPaid = 0;
    if (lstDiscount) {
      lstDiscount.map((discountRow) => {
        tFee = tFee + discountRow.totalFees;
        tDisc = tDisc + Number(discountRow.discountAmt);
        tPaid = tPaid + discountRow.paidAmt;
      });
      // console.log("calculated variables to set values");
      // console.log("tFee ", tFee);
      // console.log("tDisc", tDisc);
      // console.log("tPaid", tPaid);
      // console.log("tFee - tDisc", tFee - tDisc);
      // console.log("tFee - tDisc - tPaid", tFee - tDisc - tPaid);
    }
    setTotalFees(tFee);
    setTotalDiscount(tDisc);
    setTotalNetFee(tFee - tDisc);
    setTotalPaidFee(tPaid);
    setTotalFeeBalance(tFee - tDisc - tPaid);
  };



  // const getDiscountTypeName = (discId) => {
  //   for (var i = 0; i < lstDiscountType.length; i++) {
  //     if (lstDiscountType[i].discountId === discId) {
  //       return lstDiscountType[i].discountType;
  //     }
  //   }
  //   return "Discount Type";
  // };

  return (
    <div>
      <ToastContainer autoClose={3000} hideProgressBar />
      <div style={{ width: "100%", textAlign: "center", margin: 0 }}>
        <button
          type="button"
          className="btn btn-info text-center "
          style={{ right: "80%" }}
          id="closeSlider"
          onClick={props.closeSlider}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>

        {/* {isPopupVisible && lstFee.length > 0 && (
          <div>
            <FeeBreakdown
              hideFeeBreakdown={hideFeeBreakdown}
              lstFee={lstFee}
              monthId={monthId}
            />
          </div>
        )} */}

        <div className="col-sm-12 padding-remove" style={{ marginBottom: 5 }}>
          <div className="col-sm-2 padding-remove ">
            <label className=" col-sm-12 text-left padding-remove" style={{ fontWeight: "bold", paddingLeft: 10 }}>
              {studentName}
            </label>
          </div>
          <div className="col-sm-10 padding-remove ">
            <div className="col-sm-2 padding-remove ">
            </div>
            <div className="col-sm-2 padding-remove ">
              <div
                className="col-sm-11 padding-remove pull-right "
                style={{ border: "1px solid #ccc", borderRadius: "4px" }}
              >
                <label className=" col-sm-12 text-left padding-remove ">
                  <span style={{ fontWeight: "bold", paddingLeft: 5 }}>
                    Total Fees:
                  </span>
                  <span style={{ fontWeight: 400, paddingLeft: 5 }}>
                    {totalFees.toLocaleString('en-IN')}
                  </span>
                </label>
              </div>
            </div>
            <div className="col-sm-2 padding-remove ">
              <div
                className="col-sm-11 padding-remove pull-right "
                style={{ border: "1px solid #ccc", borderRadius: "4px" }}
              >
                <label className=" col-sm-12 text-left padding-remove">
                  <span style={{ fontWeight: "bold", paddingLeft: 5 }}>
                    Discount:
                  </span>
                  <span style={{ fontWeight: 400, paddingLeft: 5 }}>
                    {totalDiscount.toLocaleString('en-IN')}
                  </span>
                </label>
              </div>
            </div>
            <div className="col-sm-2 padding-remove ">
              <div
                className="col-sm-11 padding-remove pull-right "
                style={{ border: "1px solid #ccc", borderRadius: "4px" }}
              >
                <label className=" col-sm-12 text-left padding-remove">
                  <span style={{ fontWeight: "bold", paddingLeft: 5 }}>
                    Net Fees:
                  </span>
                  <span style={{ fontWeight: 400, paddingLeft: 5 }}>
                    {totalNetFee.toLocaleString('en-IN')}
                  </span>
                </label>
              </div>
            </div>
            <div className="col-sm-2 padding-remove ">
              <div
                className="col-sm-11 padding-remove pull-right "
                style={{ border: "1px solid #ccc", borderRadius: "4px" }}
              >
                <label className=" col-sm-12 text-left padding-remove ">
                  <span style={{ fontWeight: "bold", paddingLeft: 5 }}>
                    Paid:
                  </span>
                  <span style={{ fontWeight: 400, paddingLeft: 5 }}>
                    {totalPaidFee.toLocaleString('en-IN')}
                  </span>
                </label>
              </div>
            </div>
            <div className="col-sm-2 padding-remove ">
              <div
                className="col-sm-11 padding-remove pull-right "
                style={{ border: "1px solid #ccc", borderRadius: "4px" }}
              >
                <label className=" col-sm-12 text-left padding-remove">
                  <span style={{ fontWeight: "bold", paddingLeft: 5 }}>
                    Balance:
                  </span>
                  <span style={{ fontWeight: 400, paddingLeft: 5 }}>
                    {totalFeeBalance.toLocaleString('en-IN')}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div style={{ margin: 0, padding: 0 }}>
          <table>
            <thead>
              <tr>
                <th width="150">Month</th>
                <th style={{ display: 'none' }} width="150" >Fee Head Id</th>
                <th width="150">Fee Head</th>
                <th width="180">Total Fees</th>
                {/* <th width="300">Discount Type</th> */}
                <th width="180">Discount</th>
                <th width="100">Percent</th>
                <th width="180">Net Fees</th>
                <th width="100">Late Fee Waiver</th>
                <th width="180">Paid</th>
                <th width="450">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {lstDiscount && lstDiscount.map((discountRow, index) => (
                <tr key={index} id={index}>
                  <td width="150">{discountRow.month}</td>
                  <td style={{ display: 'none' }} width="150">{discountRow.feeHeadId}</td>
                  <td width="150">{discountRow.feeHead}</td>
                  <td width="180">
                    {discountRow.totalFees.toLocaleString('en-IN')}
                    {/* <a
                      className={`discountTd  discountTd ${highlightedLinkId === discountRow.monthId
                        ? "highlighted"
                        : ""
                        }`}
                      style={{ width: "80%", marginLeft: "10%" }}
                      onClick={(e) => onClickFeeBreakdown(discountRow.monthId)}
                    >
                      {discountRow.totalFees}
                    </a> */}
                  </td>
                  {/* <td width="300">
                    <select
                      id="discountId"
                      className="form-control "
                      style={{ width: "80%", marginLeft: "10%" }}
                      defaultValue={discountRow.discountId}
                      onChange={(e) =>
                        updatedDiscountType(e, discountRow.monthId)
                      }
                    >
                      <option value={discountRow.discountId}>
                        {getDiscountTypeName(discountRow.discountId)}
                      </option>
                      {lstDiscountType.map((option) => (
                        <option
                          key={option.discountId}
                          value={option.discountId}
                        >
                          {option.discountType}
                        </option>
                      ))}
                    </select>
                  </td> */}
                  <td width="180">
                    {/* {discountRow.discountAmt} */}
                    <input
                      type="number"
                      className="form-control "
                      style={{
                        width: "80%",
                        marginLeft: "10%",
                        textAlign: "right",
                      }}
                      id="discountAmt"
                      defaultValue={
                        discountRow.discountAmt > 0
                          ? discountRow.discountAmt.toLocaleString('en-IN')
                          : ""
                      }
                      placeholder="0"
                      onChange={(e) =>
                        updateDiscountAmount(discountRow.waiverId, e)
                      }
                      disabled = {discountRow.totalFees - discountRow.paidAmt === 0}
                    />
                  </td>
                  <td width="100">
                    {discountRow.discountAmt > 0
                      ? (
                        (discountRow.discountAmt * 100) /
                        discountRow.totalFees
                      ).toFixed(2) + "%"
                      : "-"}
                  </td>
                  <td width="180">
                    {(discountRow.totalFees - discountRow.discountAmt).toLocaleString('en-IN')}
                  </td>
                  <td width="100">
                    <input
                      type="checkbox"
                      defaultChecked={discountRow.flgLateFee}
                      onClick={(e) => updateFlgLateFee(discountRow.waiverId, e)}
                    />
                  </td>
                  <td width="180">{discountRow.paidAmt}</td>
                  <td width="450">
                    <textarea
                      className="form-control"
                      style={{ margin: "5px 5%", width: "90%", height: 35 }}
                      defaultValue={discountRow.remarks}
                      onChange={(e) => updateRemark(discountRow.waiverId, e)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div
          style={{
            marginTop: 20,
            marginRight: 40,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div>
            <div className="col-sm-12 text-center pull-right">
              <button
                type="button"
                style={{
                  width: 100,
                  backgroundColor: "#48a2bc",
                  padding: "3px 12px",
                  fontSize: 16,
                }}
                title="Save"
                onClick={saveDiscount}
                className="btn btn-info"
              >
                Save
              </button>
            </div>
          </div>
          <div>
            <a className="discountTd" onClick={removeDiscounts}>
              remove all discount
            </a>
            {/* <button onClick={removeDiscounts}>Remove all discounts</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentFeeDetails;
