import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import '../css/reportCard.css';
import Header from 'common/js/header/header.js';
import 'common/css/master.css';
import axios from 'axios';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var httpURL = "";
var queryString = require('querystring');

var tokenid = localStorage.getItem("tokenid");
var wingId = 1;
var lstWingClass = [];
var lstTerm = [];
var MAX_TERM_LENGTH = 20;
var schoolId = 1;
class ReportCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flg: true,
            width: '97%',
            loader: 'none',
            opacity: 1,
            tokenid: tokenid,
            lstTerm: lstTerm,
            lstWingClass: lstWingClass
        };
    }
    componentDidMount() {
        var wingId = 1;
        var classSectionId = 1;
        var classId = 4;
        this.handleResize();
        window.addEventListener("load", this.handleResize);
        window.addEventListener("resize", this.handleResize);
        this.setState({ loader: 'block', opacity: 0.5 });
        // this.getClassList(wingId);
        this.getTermList(wingId);
    }
    handleResize = () => {
        var width = ($('.tableLayout1').parent().width()) - 18;
        this.setState({ width: width });
    }


    getTermList = (wingId) => {
        axios({
            url: httpURL + '/sms/performance/term/list',
            method: "POST",
            data: queryString.stringify({ 'wingId': wingId, }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstTerm = response.data.lstTerms;
                this.setState({
                    lstTerm: lstTerm,
                });
                this.setTermTable();
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
        this.setState({ loader: 'none', opacity: 1 });
    }

    setTermTable = () => {
        // console.log("control here");
        var lstTerm = this.state.lstTerm;
        $('#termTableBody tr').remove();
        var table = document.getElementById('termTable');
        var tbody = document.getElementById('termTableBody');
        lstTerm = lstTerm.sort(this.dynamicsort("termOrder", "asc"));
        // console.log('lstTerm = ' + JSON.stringify(lstTerm));
        if (lstTerm) {
            for (var i = 0; i < lstTerm.length; i++) {
                var id = lstTerm[i].termId;
                var term = lstTerm[i].term;
                var weight = lstTerm[i].weight;
                var order = lstTerm[i].termOrder;

                var row = document.createElement('tr');
                row.id = id;
                row.onclick = (e) => {
                    $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                    this.setState({ flg: false });
                    this.addTermReady();
                    this.setState({ flg: true });

                }

                this.addTD(row, id, 'no-display');
                this.addTD(row, term, '');
                this.addTD(row, weight, 'text-right');
                this.addTermTDActions(row, 'text-center');
                // this.addTD(row, order, '');

                tbody.append(row);
            }

            table.append(tbody);
        }
    }
    dynamicsort(property, order) {
        var sort_order = 1;
        if (order === "desc") {
            sort_order = -1;
        }
        return function (a, b) {
            // a should come before b in the sorted order
            if (a[property] < b[property]) {
                return -1 * sort_order;
                // a should come after b in the sorted order
            } else if (a[property] > b[property]) {
                return 1 * sort_order;
                // a and b are the same
            } else {
                return 0 * sort_order;
            }
        }
    }
    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    addTermTDActions(row, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var span1 = document.createElement("span");
        span1.className = "glyphicon glyphicon-arrow-up";
        $(span1).css('padding-right', 2);
        span1.addEventListener('click', (e) => this.changeTermOrder(e, true));

        var span2 = document.createElement("span");
        span2.className = "glyphicon glyphicon-arrow-down";
        // img2.src = require("common/images/bin.png");
        // img2.width=20;
        span2.addEventListener('click', (e) => this.changeTermOrder(e, false));

        var img1 = document.createElement("img");
        img1.src = require("common/images/edit.png");
        $(img1).css('padding-left', 2);
        img1.width = 15;
        img1.addEventListener('click', (e) => this.editTerm(e));

        var img2 = document.createElement("img");
        img2.src = require("common/images/bin.png");
        $(img2).css('padding-left', 2);
        img2.width = 15;
        img2.addEventListener('click', (e) => this.deleteTerm(e));

        cell.append(span1);
        cell.append(span2);
        cell.append(img1);
        cell.append(img2);
        row.append(cell);
    }
    addTermReady = () => {
        var termId = 0;
        var term = '';
        let flg = false ;
        if (!this.state.flg) {
            flg = this.state.flg;
            termId = $('#termTableBody tr.selected').attr('id');
            term = $('#termTableBody tr.selected').find('td:eq(1)').text();
        } else {
            flg = true;
        }
        
        // route to new page by changing window.location
        // window.open(newPageUrl, "")
        
        let newUrl = "/reportcardtemplate?flg=" + flg + "&term=" + term + "&termId=" + termId;
        window.open(newUrl,  "_self");
    }

    maintainTerm = () => {
        // console.log("this.state.termId = "+this.state.termId);
        if (this.state.termId == 0) {
            this.addTerm();
        } else {
            this.editTerm();
        }
    }
    addTerm = () => {
        var termObj = {};
        var term = $('#inp_term').val();

        var weight = !$('#weight').val() ? null : $('#inp_weight').val();
        var order = !$('#inp_order').val() ? null : $('#inp_order').val();


        termObj = {
            'schoolId': schoolId,
            'termId': 0,
            'term': term,
            'weight': weight,
            'order': order,

        };
        // console.log("termObj: " + termObj);


        this.setState({ loader: 'none', opacity: 1 });
        if (term === '') {
            toast.warning('Please fill term name');
            document.getElementById('inp_term').focus();
            return false;
        }

        if (term.length > MAX_TERM_LENGTH) {
            toast.warning('Term length must not exceed ' + MAX_TERM_LENGTH + ' characters');
            document.getElementById('inp_term').focus();
            return false;
        }
        axios.post(httpURL + '/sms/term/save',
            queryString.stringify({ 'term': JSON.stringify(termObj) }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.setState({ loader: 'block', opacity: 0.5 });
                this.getTermsData(response.data.termId);

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
    }

    editTerm = () => {
        var termObj = {};
        var term = $('#inp_term').val();

        var order = !$('#inp_order').val() ? null : $('#inp_order').val();
        var weight = !$('#inp_weight').val() ? null : $('#inp_weight').val();

        termObj = {
            'termId': this.state.termId,
            'term': term,
            'weight': weight,
            'order': order,

        };
        // console.log("termObj = "+termObj);


        if (term === '') {
            toast.warning('Please fill term name');
            document.getElementById('inp_term').focus();
            return false;
        }

        if (term.length > MAX_TERM_LENGTH) {
            toast.warning('Term length must not exceed ' + MAX_TERM_LENGTH + ' characters');
            document.getElementById('inp_term').focus();
            return false;
        }

        this.setState({ loader: 'block', opacity: 0.5 });

        axios.post(httpURL + '/sms/term/save',
            queryString.stringify({ 'term': JSON.stringify(termObj) }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.setState({ loader: 'none', opacity: 1 });
                this.getTermsData(this.state.termId);
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });

    }

    changeTermOrder = (e, up) => {
        e.stopPropagation();
        this.setState({ loader: 'none', opacity: '0.1' });
        var lstTermId = [];
        $('#termTableBody tr').find('td:eq(0)').each(function () {
            lstTermId.push(parseInt($(this).text()));
        });
        var termId = parseInt($(e.currentTarget).closest('tr').find('td:eq(0)').text());
        // console.log("e.termId = " + termId);
        if (up) {
            // console.log("up");
            for (var i = 0; i < lstTermId.length; i++) {
                if (termId == lstTermId[i] && i != 0) {
                    var val = lstTermId[i - 1];
                    lstTermId[i - 1] = termId;
                    lstTermId[i] = val;
                    break;
                }
            }
        } else {
            // console.log("down");
            for (var i = 0; i < lstTermId.length; i++) {
                if (termId == lstTermId[i] && i != lstTermId.length - 1) {
                    var val = lstTermId[i + 1];
                    lstTermId[i + 1] = termId;
                    lstTermId[i] = val;
                    break;
                }
            }
        }
        // console.log("lstTermId = " + lstTermId);
        axios.post(httpURL + '/sms/performance/term/change_order',
            queryString.stringify({ 'lstTermId': JSON.stringify(lstTermId) }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.setState({ loader: 'block', opacity: 0.5 });
                this.getTermList(wingId);

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
    }

    deleteTerm = (event) => {
        event.stopPropagation();
        var termId = $(event.target).closest('tr').find("td:eq(0)").text();
        // var schoolSibling = {
        // 	"areaId" 	: $(event.target).closest('tr').find("td:eq(0)").text(),
        //     "schoolId"		: schoolId,
        //     "termId"		: termId
        // }

        // $(event.target).closest('tr').click();
        // $(event.target).closest('tr').remove();
        // console.log("termId = " + termId);
        axios.post(httpURL + '/sms/performance/term/delete',
            queryString.stringify({ 'termId': termId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getTermList(wingId);

            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not logged in') {
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }
    getWingId = (wingId) => {
        // console.log("wingId" + wingId);
    }

    render() {
        httpURL = lstModules['performance'].url + ":" + lstModules['performance'].port;
        return (
            <div >
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>


                <div className="mainContainer" style={{ opacity: this.state.opacity }}>
                    <div className="container-fluid">
                        <div className="col-sm-5 col-centered">

                            <div className="col-sm-11 padding-remove" style={{ marginTop: 10 }}>
                                <table className="tableLayout1" style={{ width: this.state.width }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: 50, display: 'none' }}>Term Id</th>
                                            <th style={{ width: 100 }}>Term</th>
                                            <th style={{ width: 70 }}>Weight</th>
                                            <th style={{ width: 70 }}>Action</th>
                                        </tr>
                                    </thead>
                                </table>
                                <div className="termTableDiv col-sm-12 col-xs-11 padding-remove">
                                    <table id="termTable" className="tableLayout">
                                        <colgroup>
                                            <col width="100" />
                                            <col width="70" />
                                            <col width="70" />

                                        </colgroup>
                                        <tbody id="termTableBody">
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-sm-1 col-xs-1">
                                <span id="addTerm" title="Add Term" onClick={this.addTermReady} className="addIcon glyphicon glyphicon-plus-sign"></span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }


}
export default ReportCard;