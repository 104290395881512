import axios from "axios";
import lstModules from "common/json/lstModules.json";

var httpURLCommon = lstModules["main"].url + ":" + lstModules["main"].port;
var httpSchoolURL =
  lstModules["schools"].url + ":" + lstModules["schools"].port;
var schoolId = 77; //localStorage.getItem("schoolId");
var tokenid = localStorage.getItem("tokenid");

const getLstTeamSvc = (cb) => {
  var obj = { status: "Success", message: "" };
  obj.lstTeam = [];
  // console.log(httpSchoolURL + "/schools/admin/team/dtls");
  axios({
    url: httpSchoolURL + "/schools/admin/team/dtls",
    method: "POST",
    params: { schoolId: schoolId },
    headers: {},
    withCredentials: true,
  })
    .then((response) => {
      var data = response.data;
      // console.log(data);
      obj.status = data.SvcStatus;

      if (data.SvcStatus === "Success") {
        obj.lstTeam = data.lstTeam;
      } else {
        obj.message = data.SvcMsg;
      }

      cb(obj);
    })
    .catch((error) => {
      obj.status = "error";
      obj.message = error;
      cb(obj);
    });
};
function deleteTeamSvc(pId, cb) {
  var obj = { status: "Success", message: "" };
  // console.log(httpSchoolURL + "/schools/admin/team/delete");
  axios({
    url: httpSchoolURL + "/schools/admin/team/delete",
    method: "POST",
    params: { teamId: pId },
    headers: {},
    withCredentials: true,
  })
    .then((response) => {
      var data = response.data;
      // console.log(data);
      obj.status = data.SvcStatus;

      obj.message = data.SvcMsg;

      cb(obj);
    })
    .catch((error) => {
      obj.status = "error";
      obj.message = error;
      cb(obj);
    });
}

function saveTeamSvc(formData, cb) {
  var obj = { status: "Success", message: "" };
  axios({
    url: httpSchoolURL + "/schools/admin/team/save",
    method: "POST",
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      obj.status = data.SvcStatus;

      obj.message = data.SvcMsg;

      cb(obj);
    })
    .catch((error) => {
      console.error(error);
    });
}
export { getLstTeamSvc, deleteTeamSvc, saveTeamSvc };
