import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import { getClassListSvc, getSectionListSvc } from './DailyAttendanceSvc';
import DailyAttendanceTable from './DailyAttendanceTable';

const DailyAttendanceCtrl = () => {
    const [loader, setLoader] = useState('none')
    const [dt, setDt] = useState(null);
    const [dtDdMmYyyy, setDtDdMmYyyy] = useState(null);
    const [lstCls, setLstCls] = useState([]);
    const [clsId, setClsId] = useState(null);
    const [lstSection, setLstSection] = useState([]);
    const [selSectionId, setSelSectionId] = useState(null);
    const [studentId, setStudentId] = useState(null);
    const [randNo, setRandNo] = useState(0);
    const [studentCount, setStudentCount] = useState(0);

    useEffect(() => {
        setDt(new Date())
        var defaultDt = convert(new Date())
        setDtDdMmYyyy(defaultDt)
        setClass()
    }, [])

    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console.log(lRandNo)
        setRandNo(lRandNo)
        setStudentId(0)
    }

    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        // console.log([day, mnth, date.getFullYear()].join("-"));
        return [day, mnth, date.getFullYear()].join("-");
    }

    function handleChange(date) {
        setDt(date)
        setDtDdMmYyyy(convert(date))
        generateRandomNo()
    }
    function setClass() {
        getClassListSvc(handleClassResponse);
    }

    function handleClassResponse(data) {
        var obj = data;
        if (obj.SvcStatus == 'Success') {
            setLstCls(obj.lstCls);
        } else if (obj.SvcStatus == 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }

    function handleClsChange(e) {
        var vClsId = e.target.value;
        setClsId(vClsId)
        setSelSectionId(0)
        setSection(vClsId);
    }

    function setSection(clsId) {
        getSectionListSvc(handleSectionResponse, clsId);
    }

    function handleSectionResponse(data) {
        var obj = data;
        if (obj.SvcStatus == 'Success') {
            setLstSection(obj.lstClassSection);
            // console.log("obj in handle SectionResponse", obj);
            if (obj.lstClassSection && obj.lstClassSection.length > 0) {
                changeSection(obj.lstClassSection[0].sectionId);
            }
        } else if (obj.SvcStatus == 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }

    function changeSection(pSectionId) {
        setSelSectionId(pSectionId);
        generateRandomNo()
    }


    const getWingId = (wingId) => {
        // console.log('wingId = ' + wingId)
    }

    

    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>

            <div className="MainContainer" >
                <div className="col-sm-2" style={{ padding: "10px" }}>
                    <div className="col-sm-12" style={{ left: '4%', paddingRight: 0 }}>
                        <label className="col-sm-3 text-left padding-remove"> Date</label>
                        <div className="form-group col-sm-9 padding-remove">
                            <DatePicker id="inp_toDate"
                                className='form-control text-center'
                                placeholderText="From Date"
                                selected={dt}
                                dateFormat="dd-MM-yyyy"
                                onChange={handleChange}
                                autoComplete='off'
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                                maxDate={new Date()}
                            />

                        </div>
                    </div>
                    <div className="col-sm-12" style={{ left: '4%', paddingRight: 0 }}>
                        <label className="col-sm-3 text-left padding-remove"> Class</label>
                        <div className="form-group col-sm-9 padding-remove" style={{ display: "flex", flexDirection: "column" }}>
                            <div className="form-group col-sm-12 padding-remove">
                                <select autoComplete="off" name="classId" id="classesCmb"
                                    type="text" className="form-control"
                                    onChange={handleClsChange}
                                >
                                    <option value="0" >Class</option>
                                    {lstCls.map((obj) => (
                                        <option value={obj.classId}
                                            key={obj.classId} id={obj.classId} >{obj.classLabel}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12" style={{ left: '4%', paddingRight: 0 }}>
                        <label className="col-sm-3 text-left padding-remove" ></label>
                        <div className="col-sm-9 padding-remove" style={{display:"flex", flexDirection:"column"}}>
                            <div className="col-sm-12 padding-remove tab" style={{ margin: 0, border: "none" }}>
                                {lstSection.map((obj) => {
                                    var isSelected = obj.sectionId === selSectionId;
                                    return (
                                        <div
                                            // style={{ height: 34, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            style={{ border: "1px solid #ccc", margin: '3px' }}
                                            className={isSelected ? "selected col-sm-2 padding-remove" : "col-sm-2 padding-remove"}
                                            onClick={(e) => changeSection(obj.sectionId)}
                                            key={obj.sectionId}>
                                            <span hidden>{obj.sectionId}</span>
                                            <span>{obj.section}</span>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="col-sm-12 " style={{padding:"10px 0"}} >
                                <div className="col-sm-12 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px", }} >
                                    <label className=" col-sm-12 text-center padding-remove  padding-remove " style={{ fontWeight: 400 }}  >Total Students: {studentCount}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-10 " style={{ padding: "10px" }}>
                    <DailyAttendanceTable
                        studentId={studentId}
                        sectionId={selSectionId}
                        dtDdMmYyyy={dtDdMmYyyy}
                        setStudentId={setStudentId}
                        setStudentCount={setStudentCount}
                        generateRandomNo={generateRandomNo}
                        key={randNo}
                    />
                </div>

            </div >
        </div >
    )
}

export default DailyAttendanceCtrl