import React, { useEffect, useState } from 'react'
import Header from 'common/js/header/header'
import { toast, ToastContainer } from 'react-toastify'
import 'common/css/master.css'
import $ from 'jquery'
import EditSlider from './EditSlider'
import ClassTeacherSlider from './ClassTeacherSlider'
import BuildingSlider from './BuildingSlider'
import UploadErrorSlider from 'common/components/UploadErrorSlider'

import {
  getClassData,
  getClassList,
  getClassTeacherList,
  getClassTeacherDetail,
  getBuildingList,
  saveClass,
  saveClassTeacher,
  saveBuilding,
  uploadClassExcel
} from './classService'
import lstModules from 'common/json/lstModules.json'

var httpURL = lstModules['main'].url + ':' + lstModules['main'].port
var queryString = require('querystring')
var screenId = 33

var slider
// var lstRoom = [
//   { roomId: 1, room: 'room1' },
//   { roomId: 2, room: 'room2' },
//   { roomId: 3, room: 'room3' }
// ]

const ClassDetail = () => {
  const [state, setState] = useState({
    mounted: false,
    loader: 'none',
    opacity: 1,
    flg: true,
    tokenid: localStorage.getItem('tokenid'),
    checkedAll: false,
    // lstRoom: lstRoom

    //action: '',
  })
  const [lstError, setLstError] = useState([])
  const [sectionId, setSectionId] = useState(0)
  const [lstAllClassSections, setLstAllClassSection] = useState([]);
  const [lstAllClassSectionsFiltered, setLstAllClassSectionsFiltered] = useState([]);
  const [selClsId, setSelClassId] = useState(0)
  const [classData1, setClassData1] = useState({})
  const [classData2, setClassData2] = useState({})
  const [classData3, setClassData3] = useState({})
  const [lstClass, setLstClass] = useState([])
  const [lstBuildings, setLstBuildings] = useState([])
  const [lstClassTeacher, setLstClassTeacher] = useState([])
  const [action, setAction] = useState('error')
  const [classFilter, setClassFilter] = useState(""); 
  const [sectionFilter, setSectionFilter] = useState("");
  // const [sliderKey, setSliderKey] = useState(0);

  useEffect(() => {
    setClass()
    setClassTeacher()
    setBuildings()
    setClassList()
    // console.log('useEffect')
  }, [])

  function handleClassListResponse (data) {
    var obj = data
    hideLoader()
    // console.log('lstAllClassSection =' + obj.lstAllClassSections)
    if (obj.status === 'Success') {
      setLstAllClassSection(obj.lstAllClassSections)
      setLstAllClassSectionsFiltered(obj.lstAllClassSections)
    } else if (obj.status === 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(obj.message)
        return false
      }
    } else {
      toast.error('Service failed ' + obj)
    }
  }


  const setLoaderValue = (ldr) => {
    setState({loader:ldr});
  }

  const setOpacityValue = (opct) => {
    setState({opacity:opct});
  }

  function setClassList () {
    showLoader()
    getClassData(handleClassListResponse)
  }

  function setClass () {
    showLoader()
    getClassList(handleClassResponse)
    // console.log('Function has been called')
    // console.log(getClassList)
  }

  function handleClassResponse (data) {
    var obj = data
    hideLoader()
    if (obj.status === 'Success') {
      setLstClass(obj.lstClass)
    } else if (obj.status === 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(obj.message)
        return false
      }
    } else {
      toast.error('Service failed ' + obj)
    }
  }

  function setClassTeacher () {
    showLoader()
    getClassTeacherList(handleClassTeacherResponse)
    // console.log('this function is 15 called')
  }
  function handleClassTeacherResponse (data) {
    var obj = data
    hideLoader()
    if (obj.status === 'Success') {
      setLstClassTeacher(obj.lstTeacher)
      // console.log('lstclstch = ' + obj.lstTeacher)
    } else if (obj.status === 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(obj.message)
        return false
      }
    } else {
      toast.error('Service failed ' + obj)
    }
  }

  function setClassTeacherDetail (classSectionId) {
    showLoader()
    getClassTeacherDetail(handleClsTchDetailResponse, classSectionId)
    // console.log('this function is 15 called' + classSectionId)
  }
  function openSlider () {
    $('body').addClass('openSlider')
  }
  function handleClsTchDetailResponse (data) {
    // console.log(data)
    var obj = data
    hideLoader()
    if (obj.status === 'Success') {
      setClassData2(obj.teacherDtls)
      openSlider()
      // console.log('teacherDtls = ' + obj.teacherDtls)
    } else if (obj.status === 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(obj.message)
        return false
      }
    } else {
      toast.error('Service failed ' + obj)
    }
  }

  function setBuildings () {
    getBuildingList(handleBuildingsResponse)
  }
  function handleBuildingsResponse (data) {
    var obj = data
    hideLoader()
    if (obj.status === 'Success') {
      setLstBuildings(obj.lstBuildings)
    } else if (obj.status === 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(obj.message)
        return false
      }
    } else {
      toast.error('Service failed ' + obj)
    }
  }

  function closeSlider () {
    $('body').removeClass('openSlider')
    // setSliderKey(prevKey => prevKey + 1);
  }

  const rowClickInMain = async e => {
    var data = e.target.getAttribute('data')
    await setClassData1(JSON.parse(data))
  }
  const dataClick = (e, clsSectionId) => {
    setClassTeacherDetail(clsSectionId)
  }
  const rowClickInMain2 = e => {
    var data = e.currentTarget.getAttribute('data')
    setClassData3(JSON.parse(data))
    // console.log('Data = ' + data)
  }
  const addClassReady = () => {
    setAction('cls')
    setClassData1({ ...classData1, classId: 0, maxStudents:"", section:"", classSectionId:0 });
    openSlider()
  }
  const showClsSlider = async (clsId, secId, e) => {
    e.persist()
    await setSelClassId(clsId)
    await setSectionId(secId)
    await setAction('cls')
    rowClickInMain(e)
    openSlider()
  }

  const showTchSlider = (clsId, secId, e) => {
    setSelClassId(clsId)
    setClassTeacher()
    setSectionId(secId)
    setAction('tch')
    // console.log(secId)
    dataClick(e, secId)
  }
  const showBldgSlider = (clsId, secId, e) => {
    setSelClassId(clsId)
    setSectionId(secId)
    setAction('bldg')
    rowClickInMain2(e)
    openSlider()
  }

  const openTab = async (pClsName, pClsId, pSection, pSectionId, e) => {
    setSectionId(pSectionId)
    // console.log(
    //   'clsId=' +
    //     pClsId +
    //     ',section=' +
    //     pSection +
    //     ',classSectionId=' +
    //     pSectionId
    // )
    /* await localStorage.setItem('className', pClsName)
    await localStorage.setItem('classId', pClsId)
    await localStorage.setItem('section', pSection)
    await localStorage.setItem('classSectionId', pSectionId) */
    window.open(`/classedit?className=${pClsName}&classId=${pClsId}&section=${pSection}&sectionId=${pSectionId}`, '_blank')
  }
  function getWingId (wingId) {
    // console.log('wingId' + wingId)
  }

  const processSaveWings = pClassData1 => {
    var classId = pClassData1.classId
    var section = pClassData1.section
    var maxStudents = pClassData1.maxStudents
    var classSectionId = pClassData1.classSectionId

    if (classId === 0) {
      toast.warning('Please Enter Class')
      return false
    }
    if (section === '') {
      toast.warning('Please Enter Section')
      return false
    }
    if (maxStudents === 0) {
      toast.warning('Please Enter MaxStudents')
      return false
    }

    var obj = {
      classId: classId,
      section: section,
      maxStudents: maxStudents,
      classSectionId: classSectionId
    }
    showLoader()
    saveClass(obj, handleSaveResponse)
  }
  function handleSaveResponse (data) {
    hideLoader()
    closeSlider()
    if (data.status === 'Success') {
      toast.success(data.message)
      setClassList()
    } else if (data.status === 'Failure') {
      if (data.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (data.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(data.message)
        return false
      }
    } else {
      toast.error('Service failed ' + data.error)
    }
  }

  function closeSlider () {
    $('body').removeClass('openSlider')
  }

  const processSaveClassTeacher = pClassData2 => {
    // console.log(pClassData2)
    var classTeacher = pClassData2.classTeacher
    var backupTeacher = pClassData2.backupTeacher
    var backupTeacherId = pClassData2.backupTeacherId
    var classTeacherId = pClassData2.classTeacherId
    var classSectionId = pClassData2.classSectionId
    if (!classSectionId) {
      backupTeacherId = pClassData2.bckTchId
      classTeacherId = pClassData2.clsTchId
      classSectionId = sectionId
    }
    if (classTeacherId === 0) {
      toast.warning('Please Select Class Teacher')
      return false
    }
    if (backupTeacherId === 0) {
      toast.warning('Please Select Backup Teacher')
      return false
    }

    var obj = {
      classSectionId,
      backupTeacherId,
      classTeacherId
    }
    showLoader()
    saveClassTeacher(obj, handleSaveResponse1)
  }

  function handleSaveResponse1 (data) {
    hideLoader()
    closeSlider()
    if (data.status === 'Success') {
      toast.success(data.message)
      setClassList()
    } else if (data.status === 'Failure') {
      if (data.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (data.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(data.message)
        return false
      }
    } else {
      toast.error('Service failed ' + data.error)
    }
  }
  function hideLoader () {
    setState({ ...state, loader: 'none', opacity: 1, pointerEvents: 'auto' })
  }
  function showLoader () {
    setState({ ...state, loader: 'block', opacity: 0, pointerEvents: 'none' })
  }
  const processSaveBuilding = pClassData3 => {
    var buildingId = pClassData3.buildingId
    var roomId = pClassData3.roomId
    var classSectionId = pClassData3.classSectionId

    if (buildingId === 0) {
      toast.warning('Please Select building')
      return false
    }
    if (roomId === 0) {
      toast.warning('Please Select Room')
      return false
    }

    var obj = {
      buildingId: buildingId,
      roomId: roomId,
      classSectionId: classSectionId
    }
    showLoader()
    saveBuilding(obj, handleSaveResponse2)
  }

  function handleSaveResponse2 (data, obj) {
    hideLoader()
    closeSlider()
    if (data.status === 'Success') {
      toast.success(data.message)
      setClassList()
    } else if (data.status === 'Failure') {
      if (data.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (data.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(data.message)
        return false
      }
    } else {
      toast.error('Service failed ' + data.error)
    }
  }
  // const [cls, setCls] = useState('')
  // const [sec, setSec] = useState('')
  // const [foundLst, setFoundLst] = useState(lstAllClassSections)
  const filterCls = e => {
    setClassFilter(e.target.value);
    filterTable(e.target.value, sectionFilter);
  }
  const filterSec = e => {
    setSectionFilter(e.target.value);
    filterTable(classFilter, e.target.value);
  }

  const filterTable = (fClass, fSection) => {
    let filterClassSecList = [];
        filterClassSecList = lstAllClassSections.filter(clsSec =>
            clsSec.className.toString().toLowerCase().includes(fClass.toLowerCase()));

       filterClassSecList =filterClassSecList.filter(clsSec =>
            clsSec.section.toString().toLowerCase().includes(fSection.toLowerCase()));

        setLstAllClassSectionsFiltered(filterClassSecList);
  }

  const triggerUploadExcel = () => {
    $('#excelUpload').trigger('click')
  }
  const uploadExcel = event => {
    setState({ ...state, loader: 'block', opacity: 0.5 })
    /* $("#excelUpload").on('change', function (event) { */

    if (!event.target.files) {
      setState({ ...state, loader: 'none', opacity: 1 })
      return
    }

    var files = event.target.files //FileList object
    /* if (!event.target.files.length) {
            // console.log("no file selected")
            this.setState({ loader: 'none', opacity: 1 });
            return;
        } */
    files = files[0]
    var formData = new FormData()
    formData.append('sectionUploadExcelFile', files)
    uploadClassExcel(formData, cbClassExcelUpload)

    /* }); */
  }
  function cbClassExcelUpload (data) {
    if (data.status === 'Success') {
      toast.success(data.message)
      setClassList()
      // $('#btn_showerror').trigger('click');
    } else if (data.status === 'Failure') {
      toast.error(data.message)
      if (data.hasOwnProperty('errors') && data.errors.length !== 0) {
        $('#btn_showerror').trigger('click')
        setErrorList(data.errors)
      }
    } else {
      toast.error(data.message)
      if (data.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (data.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      }
    }
    setState({ ...state, loader: 'none', opacity: 1 })
  }
  const setErrorList = async errors => {
    await setLstError(errors)
  }

  const showErrors = () => {
    // console.log('slider should be open')
    setAction('error')
    openSlider()
  }

  return (
    <div className='container1'>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: state.loader
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <div className='col-md-11 col-centered' style={{ marginTop: 15 + 'px' }}>
        <div className='col-md-10 padding-remove' style={{ width: '87%' }}>
          <table className='tableLayout1' id='customTable'>
            <thead>
              <tr>
                <th style={{ width: 40 + '%' }}>
                  <input
                    type='text'
                    value={classFilter}
                    onChange={filterCls}
                    className='form-control inp-search'
                    placeholder='Class'
                  />
                </th>
                <th style={{ width: 30 + '%' }}>
                  <input
                    type='text'
                    value={sectionFilter}
                    onChange={filterSec}
                    className='form-control inp-search'
                    placeholder='Section'
                  />
                </th>
                <th style={{ width: 40 + '%' }} className='text-left'>
                  Class Teacher
                </th>
                <th style={{ width: 40 + '%' }} className='text-left'>
                  Building
                </th>
                <th style={{ width: 40 + '%' }} className='text-left'>
                  Room
                </th>
                <th style={{ width: 40 + '%' }}>Room Capacity</th>
                <th style={{ width: 40 + '%' }}>Max. Student</th>
                <th style={{ width: 40 + '%' }}>Current Students</th>
                <th style={{ width: 70 + '%' }}>Action</th>
              </tr>
            </thead>
          </table>

          <div className='tableDiv' style={{ height: 500 + 'px' }}>
            <table id='periodTypeTable' className='tableLayout'>
              <tbody id='periodTypeTableBody'>
                {lstAllClassSectionsFiltered && lstAllClassSectionsFiltered.map(obj => (
                  <tr
                    className={
                      obj.classSectionId === parseInt(sectionId)
                        ? 'selected'
                        : ''
                    }
                    key={obj.classSectionId}
                    id={obj.classSectionId}
                  >
                    <td hidden>{obj.classId}</td>
                    <td style={{ width: 40 + '%' }} className='text-left'>
                      {obj.className}
                    </td>
                    <td hidden>{obj.classSectionId}</td>
                    <td style={{ width: 30 + '%' }} className='text-left'>
                      {obj.section}
                    </td>
                    <td style={{ width: 40 + '%' }} className='text-left'>
                      {obj.classTeacher}
                    </td>
                    <td style={{ width: 40 + '%' }} className='text-left'>
                      {obj.buildingName}
                    </td>
                    <td style={{ width: 40 + '%' }} className='text-left'>
                      {obj.room}
                    </td>
                    <td style={{ width: 40 + '%' }} className='text-center'>
                      {obj.capacity}
                    </td>
                    <td style={{ width: 40 + '%' }} className='text-center'>
                      {obj.maxStudents}
                    </td>
                    <td style={{ width: 40 + '%' }} className='text-center'>
                      {obj.currentStudent}
                    </td>
                    <td
                      style={{ width: 70 + '%' }}
                      className='text-center'
                      data={JSON.stringify(obj)}
                    >
                      <span className='glyphicon glyphicon-trash' title="delete class"></span>
                      <span
                        className='glyphicon glyphicon-pencil'
                        title="edit class"
                        data={JSON.stringify(obj)}
                        onClick={e =>
                          showClsSlider(obj.classId, obj.classSectionId, e)
                        }
                      ></span>
                      <span
                        className='glyphicon glyphicon-user'
                        title="class teacher"
                        data={JSON.stringify(obj)}
                        onClick={e =>
                          showTchSlider(obj.classId, obj.classSectionId, e)
                        }
                      ></span>
                      <span
                        className='glyphicon glyphicon-home'
                        title="select building"
                        data={JSON.stringify(obj)}
                        onClick={e =>
                          showBldgSlider(obj.classId, obj.classSectionId, e)
                        }
                      ></span>
                      <span
                        className='glyphicon glyphicon-edit'
                        title="class students"
                        onClick={e =>
                          openTab(
                            obj.className,
                            obj.classId,
                            obj.section,
                            obj.classSectionId,
                            e
                          )
                        }
                      ></span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='col-xs-1 padding-remove' style={{ width: '3%' }}>
          <span
            id='addClass'
            title='Add Class-Section'
            onClick={addClassReady}
            className='addIcon glyphicon glyphicon-plus-sign'
          ></span>
        </div>
        <div
          className='col-sm-2 col-xs-1 padding-remove'
          style={{ width: '10%' }}
        >
          <div className='form-group'>
            <input
              key={new Date().getTime()}
              type='file'
              id='excelUpload'
              className='hidden'
              accept='.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              onChange={e => uploadExcel(e)}
            />
            <button
              id='uploadeExcel'
              title='Upload Excel'
              type='button'
              onClick={() => triggerUploadExcel()}
              className='btn btn-warning'
            >
              Upload Classes
            </button>
          </div>
          <div className='form-group hidden'>
            <button
              type='button'
              id='btn_showerror'
              title='View Errors'
              onClick={e => showErrors()}
              className='btn btn-info'
            >
              {' '}
              View Errors
            </button>
          </div>
        </div>
      </div>
      <div className='slider halfWidthSlider'>
        {action === 'cls' && (
          <EditSlider
            // key={sliderKey}
            obj={state.obj}
            classData1={classData1}
            lstClass={lstClass}
            saveClass={processSaveWings}
            removeClassFromBody={closeSlider}
          />
        )}
        {action === 'error' && (
          <UploadErrorSlider
            lstErrors={lstError}
            heading='Class'
            countError={lstError.length || 0}
            removeClassFromBody={closeSlider}
          />
        )}
        {action === 'tch' && (
          <ClassTeacherSlider
            key={
              classData2 &&
              classData2 !== undefined &&
              classData2.clsTchId + '_' + classData2.bckTchId
            }
            obj={state.obj}
            secId={sectionId}
            classData2={classData2}
            lstClassTeacher={lstClassTeacher}
            saveClassTeacher={processSaveClassTeacher}
            removeClassFromBody={closeSlider}
          />
        )}
        {action === 'bldg' && (
          <BuildingSlider
            obj={state.obj}
            key={
              classData3 && classData3 !== undefined && classData3.buildingId
            }
            classData3={classData3}
            lstBuildings={lstBuildings}
            // lstRoom={state.lstRoom}
            saveBuilding={processSaveBuilding}
            removeClassFromBody={closeSlider}
            setLoader={setLoaderValue}
            setOpacity={setOpacityValue}

          />
        )}
      </div>
    </div>
  )
}
export default ClassDetail
