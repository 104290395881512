import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { getLstTchDutySvc, viewDutyByTchSvc, generateTchDatesheetSvc } from './TchTableSvc';
import ViewDatesheet from "./ViewDatesheet";

const TchTable = (props) => {
    const [lstTchDuty, setLstTchDuty] = useState([]);
    const [randNo, setRandNo] = useState(0)
    const [loader, setLoader] = useState("none");
    const [flgType, setFlgType] = useState(0);
    const [foundLst, setFoundLst] = useState([]);
    const [filterTch, setFilterTch] = useState('')
    const [filterRole, setFilterRole] = useState('')
    const [lstSelTchId, setLstSelTchId] = useState([]);
    const [lstTchId, setLstTchId] = useState([]);

    const filterTeacher = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = foundLst.filter((obj) => {
                return obj.tch.toLowerCase().includes(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setFoundLst(results);
        } else {
            setFoundLst(lstTchDuty);
            // If the text field is empty, show all users
        }
        setFilterTch(keyword);
    };

    const filterTchRole = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = foundLst.filter((obj) => {
                return obj.role.toLowerCase().includes(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setFoundLst(results);
        } else {
            setFoundLst(lstTchDuty);
            // If the text field is empty, show all users
        }
        setFilterRole(keyword);
    };


    const sortAscTotal = async () => {
        const sorted = await lstTchDuty.sort((a, b) => {
            return a.dutyCount - b.dutyCount;
        });
        await setFoundLst(sorted);
    }

    const sortDscTotal = async () => {
        const sorted = await lstTchDuty.sort((a, b) => {
            return b.dutyCount - a.dutyCount;
        });
        await setFoundLst(sorted);
    }

    useEffect(() => {
        getLstTchDutySvc(props.examId, cbLstTchResponse)

    }, [props.examId])

    function cbLstTchResponse(obj) {
        if (obj.status === "Success") {
            setLstTchDuty(obj.lstTchDuty);
            setFoundLst(obj.lstTchDuty)
        } else if (obj.status === 'Failure') {

            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {

            toast.error("Service failed " + obj.message);
        }
    }

    async function handleLstTchIdChange(pId, pChecked) {
        var tmpLstTch = lstSelTchId;
        pId = parseInt(pId)
        pChecked = !!(pChecked)

        if (pChecked && !lstSelTchId.includes(pId)) {
            tmpLstTch.push(parseInt(pId));
            setLstSelTchId(tmpLstTch);
        } else {
            tmpLstTch.splice(tmpLstTch.indexOf(pId), 1)
            setLstSelTchId(tmpLstTch);
        }
        setLstTchId(tmpLstTch)
    }

    const colgrp = <colgroup>
        <col width="30%"></col>
        <col width="25%"></col>
        <col width="20%"></col>
        <col width="10%"></col>
        <col width="15%"></col>
    </colgroup>

    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console.log(lRandNo)
        setRandNo(lRandNo)
    }

    function closeSlider() {
        setFlgType(0)
    }

    function viewDutyByTchId(pTchId, pExamId) {
        viewDutyByTchSvc(pTchId, pExamId, cbViewDutyByTch)
    }

    function generateTchDatesheet(pExamId) {
        // console.log(pExamId)
        // console.log(lstTchId)

        generateTchDatesheetSvc(pExamId, lstTchId, cbTchDatesheetSvc)
    }

    function cbTchDatesheetSvc(data) {
        if (data.status === 'Success') {
            toast.success(data.message)
        }
        else {
            toast.error(data.message)
        }
    }

    function cbViewDutyByTch(data) {
        let blob = new Blob([data.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob);
        const iframe = document.getElementById('viewer');
        iframe.setAttribute('src', url);
        URL.revokeObjectURL(url);
        setFlgType(10);
    }

    return (
        <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div className="col-sm-12" >
                <button type="button" style={{ marginRight: '2%' }} title="Generate Date Sheet" onClick={() => { generateTchDatesheet(props.examId) }} className="btn btn-warning pull-right">Generate</button>
            </div>

            <table className='tableLayout1'>
                {colgrp}
                <thead>
                    <tr>
                        <th><input onChange={filterTeacher} type="text" value={filterTch} className="form-control inp-search" placeholder="Teacher" /></th>
                        <th><input onChange={filterTchRole} type="text" value={filterRole} className="form-control inp-search" placeholder="Role" /></th>
                        <th>Duties
                            <button className='glyphicon glyphicon glyphicon-arrow-up' style={{ backgroundColor: "#4a86e8", margin: "0 1px" }} onClick={() => sortAscTotal()}></button>
                            <button className='glyphicon glyphicon glyphicon-arrow-down' style={{ backgroundColor: "#4a86e8", margin: "0 1px" }} onClick={() => sortDscTotal()}></button>
                        </th>
                        <th>Select</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </table>
            <div className='tablebodyWrapper'>
                <table className='tableLayout'>
                    {colgrp}
                    <tbody>
                        {foundLst && foundLst.length > 0 && foundLst.map((obj, idx) => {
                            return <tr key={idx} id={obj.tchId}
                                onClick={() => props.setTchId(obj.tchId)} className={obj.tchId === props.tchId ? "selected" : ""}
                            >
                                <td className="no-display">{obj.tchId}</td>
                                <td className="text-left">{obj.tch}</td>
                                <td className="text-left">{obj.role}</td>
                                <td className="text-center">{obj.dutyCount}</td>
                                <td className="text-center"><input type="checkbox" key={obj.tchId}
                                    id={obj.tchId}
                                    defaultChecked={obj.selected}
                                    onChange={(e) => handleLstTchIdChange(e.target.id, e.target.checked)} ></input></td>
                                <td className="text-center" onClick={() => viewDutyByTchId(obj.tchId, props.examId)}>View</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <div className="slider halfWidthSlider" style={{ right: 0, opacity: 1, display: flgType === 10 ? 'block' : 'none', zIndex: 10000 }}>
                <div>
                    <button
                        type="button"
                        className="btn btn-info text-center btnCloseDiv"
                        onClick={() => closeSlider()}>
                        <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                    </button>
                </div>
                <ViewDatesheet
                    key={generateRandomNo}
                />
            </div>
        </div>
    )
}

export default TchTable