import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import "common/css/master.css";
import "../css/student.css";
import { saveSiblingSvc } from "./studentSiblingSvc";


const StudentSiblingForm = (props) => {

    const { setLoader, setOpacity, studentId, siblingId, siblingObj, closePopup, lstCls, updateSiblingResponse } = props;

    const [sibling, setSibling] = useState('');
    const [age, setAge] = useState(0);
    const [gender, setGender] = useState("");
    const [clsId, setClsId] = useState(0);
    const [cls, setCls] = useState("");
    const [school, setSchool] = useState("");
    const [lstGender, setLstGender] = useState([
        { gender: "M", genderName: "Male" },
        { gender: "F", genderName: "Female" },
    ]);

    useEffect(() => {
        // console.log("StudentSiblingForm called, siblingId", siblingId, siblingObj);
        if (!!siblingId) {
            setSibling(siblingObj.sibling);
            setAge(siblingObj.age);
            setGender(siblingObj.gender);
            setClsId(siblingObj.clsId);
            setCls(siblingObj.cls);
            setSchool(siblingObj.school);
        }
    }, [siblingId, siblingObj]);

    const handleSiblingClassChange = (pClsId) => {
        // console.log("handleSiblingClassChange called, pClsId", pClsId);
        setClsId(pClsId);
        setCls(getClassById(pClsId));
    }

    const getClassById = (pClsId) => {
        const siblingClass = lstCls.find(element => element.clsId === pClsId);
        return siblingClass ? siblingClass.cls : '';
    };


    const saveSibling = () => {
        // console.log("saveSibling called");
        var studentSibling = {
            siblingId: siblingId,
            sibling: sibling,
            age: age,
            gender: gender,
            clsId: clsId,
            cls: cls,
            school: school,
        };
        if (siblingId === 0) {
            studentSibling.studentId = studentId;
        }

        if (!sibling) {
            toast.warn('Sibling field is required.');
            return;
        }

        if (!gender) {
            toast.warn('Gender field is required.');
            return;
        }

        if (!school) {
            toast.warn('School field is required.');
            return;
        }

        if (clsId === 0) {
            toast.warn('Class ID must be greater than zero.');
            return;
        }

        if (age === 0) {
            toast.warn('Age must be greater than zero.');
            return;
        }

        setLoader("block");
        setOpacity(0.5);
        saveSiblingSvc(studentSibling, updateSiblingResponse);
    }

    return (
        <div className="siblingFormPopup">
            <header style={{ width: "90%" }}>
                <h3 id="classHeader">Add/Edit Sibling</h3>
                <a
                    href="#0"
                    id="hideSiblingSlider"
                    className="cd-panel-close glyphicon glyphicon-remove"
                    onClick={() => closePopup()}
                >
                    Close
                </a>
            </header>

            <div style={{ width: "90%" }}>                        //sibling slider/popup
                <div
                    className="cd-panel-content"
                    style={{ padding: "20px 0% 20px" }}
                >
                    <div
                        id="SiblingSlider"
                        className="col-sm-12"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <div style={{ width: "90%", paddingLeft: 20 }}>
                            <form id="siblingForm" className="form-horizontal">
                                <div className="col-sm-12 padding-remove">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="control-label col-sm-5">
                                                Name:
                                            </label>
                                            <div className="col-sm-7 padding-remove">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Name"
                                                    id="inp_sibling"
                                                    value={sibling}
                                                    onChange={(e) => setSibling(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="control-label col-sm-5" id="">
                                                Age:
                                            </label>
                                            <div className="col-sm-7 padding-remove">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="inp_sage"
                                                    placeholder="sibling age"
                                                    value={age ? age : ""}
                                                    onChange={(e) => setAge(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="control-label col-sm-5">
                                                Gender:
                                            </label>
                                            <div className="col-sm-7 padding-remove">
                                                <select
                                                    className='form-control'
                                                    // style={{border:"1px solid #ccc"}}
                                                    value={gender}
                                                    onChange={e => setGender(e.target.value)}
                                                >
                                                    <option value={""}>Gender</option>
                                                    {lstGender.map((obj, idx) => {
                                                        return (
                                                            <option key={idx} value={obj.gender}>
                                                                {obj.genderName}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label col-sm-5">
                                                Class:
                                            </label>
                                            <div className="col-sm-7 padding-remove">
                                                <select
                                                    className='form-control '
                                                    // style={{border:"1px solid #ccc"}}
                                                    value={clsId}
                                                    onChange={e => handleSiblingClassChange(e.target.value)}
                                                >
                                                    <option value={""}>Class</option>
                                                    {lstCls ?
                                                        lstCls.map((obj, idx) => {
                                                            return (
                                                                <option key={idx} value={obj.clsId}>
                                                                    {obj.cls}
                                                                </option>
                                                            )
                                                        }) : null}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label col-sm-5">
                                                School:
                                            </label>
                                            <div className="col-sm-7 padding-remove">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="inp_sschool"
                                                    value={school}
                                                    onChange={(e) => setSchool(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group" style={{ marginTop: 50 }}>
                                    <div className="col-sm-11 text-right pull-right">
                                        <button
                                            type="button"
                                            onClick={saveSibling}
                                            className="btn btn-info"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default StudentSiblingForm;