import axios from 'axios'
import React from 'react'
import { toast } from 'react-toastify'
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem('tokenid');
var httpURL = lstModules['enquiry'].url + ":" + lstModules['enquiry'].port;
var httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;
function getTestListSvc(callBack) {
    axios(
        {
            url: httpURL+"/sms/test/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            if (data.SvcStatus == "Success") {
                // console.log("list fetching success" + data)
                callBack(data.lstTest)
            }
            else {
                toast.error(data.SvcMsg)
            }
        }
    ).catch((error) => {
        console.error(error)
    });
}
function getWingsListSvc(callBack) {
    axios(
        {
            url: httpURLCommon+"/sms/mst/wing/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data;
            callBack(data);
        }
    ).catch((error) => {
        console.error(error)
    });
}
function getClassListSvc(callBack, pWingId, pClassId) {
    // console.log("Wing Id:" + pWingId)
    axios(
        {
            url: httpURLCommon+"/sms/mst/wingCls/list",
            method: 'POST',
            params: { wingId: pWingId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data;
            callBack(data, pClassId);

        }
    ).catch((error) => {
        console.error(error)
    });
}
function deleteTestSvc(ptestId, callBack) {
    axios(
        {
            url: httpURL+"/sms/test/delete",
            method: 'POST',
            params: { testId: ptestId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data;
            callBack(data);
        }
    ).catch((error) => {
        console.error(error)
    });
}
function saveTestSvc(pTestObj, callBack) {

    axios({
        url: httpURL+"/sms/test/save",
        method: "POST",
        params: { test: JSON.stringify(pTestObj) },
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {

        var data = response.data
        if (data.testId === undefined) {
            data.testId = pTestObj.testId;
        }
        callBack(data);
    }).catch((error) => { 
        // console.log("Error Occured") 
    });
}

export { getTestListSvc };
export { getClassListSvc };
export { getWingsListSvc };
export { deleteTestSvc };
export { saveTestSvc };
