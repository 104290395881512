import React, { useState, useEffect } from "react";




function ExamRemarkTable(props) {
    const { tableKey, sectionId, examId, lstRemark, setLstRemark, lstSelect, setLstSelect } = props;


    const [lstRemarkFiltered, setLstRemarkFiltered] = useState([]);
    const [studentId, setStudentId] = useState(0);

    const [filteredEnroll, setFilteredEnroll] = useState("");
    const [filteredName, setFilteredName] = useState("");
    const [filteredFather, setFilteredFather] = useState("");
    const [filteredMother, setFilteredMother] = useState("");



    useEffect(() => {
        // console.log("ExamRemarkTable called, sectionId", sectionId, ", examId", examId, "lstSelect", lstSelect, ", lstRemark", lstRemark);
        setLstRemarkFiltered(lstRemark);

    }, [tableKey, examId, sectionId, examId, lstRemark, lstSelect]);



    const handleAttendanceChange = (pStudentId, pAttendance) => {
        // console.log("handleAttendanceChange called, pStudentId", pStudentId, ", pAttendance", pAttendance);
        setLstRemark(prevLstRemark =>
            prevLstRemark.map(student =>
                student.studentId === studentId
                    ? { ...student, attendance: pAttendance }
                    : student
            )
        );
        setLstRemarkFiltered(prevLstRemark =>
            prevLstRemark.map(student =>
                student.studentId === studentId
                    ? { ...student, attendance: pAttendance }
                    : student
            )
        );
    }
    const handleCheckboxChange = (pStudentId, check) => {
        // console.log("handleCheckboxChange called, pStudentId", pStudentId, ", check", check);
        // console.log("lstSelect before change", lstSelect);
        setLstSelect(prevLstSelect => {
            if (check) {
                // Add pStudentId to lstSelect if not already present
                return [...prevLstSelect, pStudentId];
            } else {
                // Remove pStudentId from lstSelect
                return prevLstSelect.filter(id => id !== pStudentId);
            }
        });
    }
    const handleRemarkChange = (pStudentId, pRemark) => {
        // console.log("handleRemarkChange called, pStudentId", pStudentId, ", pRemark", pRemark);
    }


    const saveUpdatedAttendance = (pStudentId, pAttendance) => {
        // console.log("saveUpdatedAttendance called, pStudentId", pStudentId, ", pAttendance", pAttendance);
    }

    const colgrp = (
        <colgroup>
            <col width="60"></col>
            <col width="150"></col>
            <col width="150"></col>
            <col width="150"></col>
            <col width="40"></col>
            <col width="40"></col>
            <col width="40"></col>
            {/* <col width="60"></col> */}
            <col width="30"></col>
            <col width="100"></col>
        </colgroup>
    );

    return (
        <div className="col-sm-12 padding-remove" >
            <table className="tableLayout1">
                {colgrp}
                <thead>
                    <tr>
                        {/* <th style={{ width: 75 }}>
                                    <input onChange={(e) => filterEno(e)} type="text" value={filteredEnroll} className="form-control inp-search" placeholder="Enrollment Number" />
                                </th> */}
                        <th>Enrollment No</th>
                        <th>Student</th>
                        <th>Father</th>
                        <th>Mother</th>
                        <th>Score</th>
                        <th>Percent</th>
                        <th>Rank</th>
                        {/* <th>Attendance</th> */}
                        <th>Select</th>
                        <th>Remarks</th>
                    </tr>
                </thead>
            </table>
            <div className="tableDiv2" style={{ height: "450px" }}>
                <table id="examRemarkTable" className="tableLayout">
                    {colgrp}
                    <tbody>
                        {lstRemarkFiltered && lstRemarkFiltered.length ?
                            lstRemarkFiltered.map((obj) => {
                                return (
                                    <tr onClick={(e) => setStudentId(obj.studentId)}
                                        className={studentId === obj.studentId ? 'selected' : ''}
                                        key={obj.studentId}>
                                        <td className="text-center">{obj.enrollmentNo}</td>
                                        <td className="text-left">{obj.studentName}</td>
                                        <td className="text-left">{obj.father}</td>
                                        <td className="text-left">{obj.mother}</td>
                                        <td className="text-center">{obj.score}</td>
                                        <td className="text-center">{obj.pct + " %"}</td>
                                        <td className="text-center">{obj.rank}</td>
                                        {/* <td className="text-center" align="center">
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                                <div style={{ width: "80%" }}>
                                                    <span>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            style={{ width: "100%", height: "80%", textAlign: "right" }}
                                                            value={!!obj.attendance && obj.attendance !== "/" ? obj.attendance : ""}
                                                            // value={obj.attendance }
                                                            placeholder="0/0"
                                                            onChange={(e) => handleAttendanceChange(obj.studentId, e.target.value)}
                                                            disabled={false}
                                                        />
                                                    </span>
                                                </div>
                                                <div style={{ width: "20%", display: "flex", justifyContent: "center" }}>
                                                    <span
                                                        className="glyphicon glyphicon-edit"
                                                        style={{ pointerEvents: 'auto', cursor: "pointer" }}
                                                        onClick={(e) => saveUpdatedAttendance(obj.studentId, obj.attendance)}
                                                    ></span>
                                                </div>
                                            </div>
                                        </td> */}
                                        <td className="text-center" >
                                            <input type='checkbox' id={"cbx" + obj.studentId} onChange={(e) => handleCheckboxChange(obj.studentId, e.target.checked)} checked={lstSelect.includes(obj.studentId)}></input>
                                        </td>
                                        <td className="text-left" align="center">
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                                <div style={{ width: "100%" }}>
                                                    {obj.remarks}
                                                    {/* <span>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            style={{ width: "100%", height: "80%", textAlign: "right" }}
                                                            value={obj.remarks}
                                                            onChange={(e) => handleRemarkChange(obj.studentId, e.target.value)}
                                                            disabled={false}
                                                        />
                                                    </span> */}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            : null
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default ExamRemarkTable;