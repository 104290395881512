import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "common/css/master.css";
import Header from "common/js/header/header.js";
import TchSubjectTableBody from "./tchsubjectTableBody";
import {
  getClassList,
  getSectionData,
  getSessionData,
  getTchLst,
  getLstDay,
  getLstPeriod,
  saveTchForCell,
} from "./ttTeacherService";
import TTTeacherBody from "./ttTeacherBody";
import TTTeacherHead from "./ttTchHeader";

var MAX_SUB = 4;
function TTTeacherCtrl() {
  var initialState = {
    loader: "none",
    opacity: 1,
  };
  const [flgSuccess, setFlgSuccess] = useState(true);
  const [ttbodyKey, setTTBodyKey] = useState(0);
  const [tchbodyKey, setTchBodyKey] = useState(0);
  // const [cellKey, setCellKey] = useState(0);
  const [state, setState] = useState(initialState);
  const [clsId, setClsId] = useState(0);
  const [classId, setClassId] = useState(0);
  const [sectionId, setSectionId] = useState(0);
  const [sessionId, setSessionId] = useState(0);
  const [selClsLabel, setSelClsLabel] = useState("");
  const [selSectionLabel, setSelSectionLabel] = useState("");
  const [cellId, setCellId] = useState(0);
  const [refreshDayId, setRefreshDayId] = useState(0);
  const [refreshPeriodId, setRefreshPeriodId] = useState(0);
  const [cell, setCell] = useState({});
  const [lstCls, setLstCls] = useState([]);
  const [lstSection, setLstSection] = useState([]);
  const [lstSession, setLstSession] = useState([]);
  const [lstTch, setlstTch] = useState([]);
  const [lstDay, setLstDay] = useState([]);
  const [lstPeriod, setLstPeriod] = useState([]);
  // const [selSubId, setSelSubId] = useState(0);
  const [selDayId, setSelDayId] = useState(0);
  const [selPeriodId, setSelPeriodId] = useState(0);
  const [selCellDtls, setSelCellDtls] = useState({});
  const [selTchId, setSelTchId] = useState(0);
  const [classLabel, setClassLabel] = useState({});
  const [cellRefresh, setCellRefresh] = useState(false);
  const [selectCellDtls, setSelectCellDtls] = useState({});
  const [selSubPosId, setSelSubPosId] = useState(0);
  const [selCellId, setSelCellId] = useState(0);
  const params = new URLSearchParams(window.location.search);
  useEffect(() => {
    setClass();
    // setTchLst(sessionId, clsId, sectionId);
    setDay(handleDaysResponse);
    // setClsId(clsId);
    // setSectionId(sectionId);
    // setSessionId(sessionId);
    // console.log("inside useeffect");
    // console.log("clsId ", clsId);
    // console.log(" sectionId", sectionId);
    // console.log(" sessionId", sessionId);
    // console.log("lstCls", lstCls);
    if (params.get("clsId")) {
      setClassId(params.get("clsId"));
      if (params.get("sectionId")) {
        // console.log("sectionId get using params",params.get("sectionId"));
        setSectionId(params.get("sectionId"));
        setSection(params.get("clsId"));
        if (params.get("sessionId")) {
      }
        setSessionId(params.get("sessionId"));
        setSession(params.get("clsId"));
        setPeriod(params.get("sessionId"))
      }
    }
    // console.log("clsId ", clsId);
    // console.log(" sectionId", sectionId);
    // console.log(" sessionId", sessionId);
  }, []);

  function setClass() {
    // return new Promise((resolve) => {
    showLoader();
    getClassList(handleClassResponse);
    //   resolve();
    // });
  }
  function showLoader() {
    setState({ ...state, loader: "block", opacity: 0.5 });
  }
  function hideLoader() {
    setState({ ...state, loader: "none", opacity: 1 });
  }
  function handleClassResponse(data) {
    // return new Promise((resolve) => {
    hideLoader();
    var obj = data;
    // console.log("handleClassResponse = " + JSON.stringify(data));
    if (obj.status === "Success") {
      setLstCls(obj.lstCls);
      // console.log("obj.lstcls", obj.lstCls);
    } else if (obj.status === "Failure") {
      setLstCls([]);
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed " + obj);
    }
    //   resolve();
    // });
  }

  function setSection(clasId) {
    // console.log("setSection called, classid passed", clasId);
    showLoader();
    getSectionData(clasId, handleSectionResponse);
  }

  function handleSectionResponse(data) {
    // console.log("handleSectionResponse called, data", data);
    hideLoader();
    var obj = data;
    if (obj.status === "Success") {
      setLstSection(obj.lstSection);
    } else if (obj.status === "Failure") {
      setLstSection([]);
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }

  function setSession(clsId) {
    showLoader();
    getSessionData(handleSessionResponse, clsId);
  }

  function handleSessionResponse(data) {
    hideLoader();
    var obj = data;
    if (obj.status === "Success") {
      setLstSession(obj.lstSession);
    } else if (obj.status === "Failure") {
      setLstSession([]);
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }

  function handleLstTchResponse(data) {
    hideLoader();
    var obj = data;
    if (obj.status === "Success") {
      setlstTch(obj.lstTch);
    } else if (obj.status === "Failure") {
      setlstTch([]);
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }
  function setTchLst(sessionId, clsId, sectionId) {
    showLoader();
    getTchLst(sessionId, clsId, sectionId, handleLstTchResponse);
  }

  function setDay(handleDaysResponse) {
    showLoader();
    getLstDay(handleDaysResponse);
  }

  function handleDaysResponse(data) {
    hideLoader();
    var obj = data;
    if (obj.status === "Success") {
      setLstDay(obj.lstDay);
    } else if (obj.status === "Failure") {
      setLstDay([]);
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }

  function setPeriod(sessionId) {
    showLoader();
    getLstPeriod(sessionId, handlePeriodResponse);
  }

  function handlePeriodResponse(data) {
    hideLoader();
    var obj = data;
    if (obj.status === "Success") {
      setLstPeriod(obj.lstPeriod);
      setTTBodyKey(generateRandNo);
    } else if (obj.status === "Failure") {
      setLstPeriod([]);
      setTTBodyKey(generateRandNo);
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }

  function changeClass(clsId, clsLabel) {
    let wingId = 1;
    let screenId = 33;
    if (sectionId && sessionId) {
      setTchLst(sessionId, clsId, sectionId);
      setDay(handleDaysResponse);
      setPeriod(sessionId);
      //setTTBodyKey(generateRandNo)
      setTchBodyKey(generateRandNo);
    }
    setState({ ...state, classId: clsId });
    setClassId(clsId);
    // console.log('sel cls = ' + clsLabel)
    setSelClsLabel(clsLabel);
    // console.log("changeClass: " + clsId);
    setSection(clsId, handleSectionResponse);
    setSession(clsId);
  }
  function generateRandNo() {
    return Math.floor(Math.random() * 999999);
  }
  function changeSection(pSectionId, pSectionLabel) {
    let wingId = 1;
    let screenId = 33;
    // console.log(pSectionId)
    if (classId && sessionId) {
      setTchLst(sessionId, classId, pSectionId);
      setDay(handleDaysResponse);
      setPeriod(sessionId);
      //setTTBodyKey(generateRandNo)
      setTchBodyKey(generateRandNo);
    }
    setState({ ...state, sectionId: pSectionId });
    setSectionId(pSectionId);
    // console.log("changeSection: " + pSectionId);
    // console.log('section: ' + pSectionLabel)
    setSelSectionLabel(pSectionLabel);
  }

  function changeSession(pSessionId) {
    let wingId = 1;
    let screenId = 33;
    if (classId && sectionId) {
      setTchLst(pSessionId, classId, sectionId);
      setDay(handleDaysResponse);
      setPeriod(pSessionId);
      //setTTBodyKey(generateRandNo)
      setTchBodyKey(generateRandNo);
    }
    setState({ ...state, sessionId: pSessionId });
    setSessionId(pSessionId);
    // console.log(pSessionId)
  }

  function onClickCell(e, dId, pId, classSectionId, pPos) {
    setSelSubPosId(1);
    var subCtr;
    // console.log('flgSuccess: ' + flgSuccess);
    if (flgSuccess) {
      subCtr = pPos + 1 > MAX_SUB ? MAX_SUB : pPos + 1;
    } else {
      subCtr = pPos > MAX_SUB ? MAX_SUB : pPos;
    }
    // console.log("hi" + subCtr);
    // console.log(e.currentTarget);
    var cellData = e.currentTarget.querySelector(".sub").getAttribute("cell");
    // console.log(typeof JSON.parse(cellData));
    var selCellId = JSON.parse(cellData).cellId;
    // setCellId(selCellId);
    var cell = e.currentTarget.getAttribute("selcelldtls");
    // console.log('pId= ' + pId + ', dId = ' + dId + ', selCellId = ' + selCellId)
    // setState({ ...state, selCellDtls: cell, selDayId: e.target.seldayid, selPeriodId: e.target.selperiodid });
    var cellDtls = {
      cellId: selCellId,
      classSectionId: classSectionId,
      schedulePeriodId: pId,
      dayId: dId,
      subPosition: subCtr,
    };
    // console.log(cellDtls);
    setSelCellDtls(cellDtls);
    setSelPeriodId(pId);
    setSelDayId(dId);
  }
  function setCellProps(pCellDtls, pCellId, pFlgSuccess) {
    setFlgSuccess(pFlgSuccess);
    // console.log("setcellprops")

    if (pFlgSuccess) {
      pCellDtls.cellId = pCellId;
      pCellDtls.subPosition =
        pCellDtls.subPosition > MAX_SUB
          ? 1
          : pCellDtls.subPosition == MAX_SUB
          ? MAX_SUB
          : pCellDtls.subPosition;
      setSelTchId(pCellDtls.tchId);
      // pCellDtls.subPosition = pCellDtls.subPosition + 1 > MAX_SUB ? 1 : pCellDtls.subPosition + 1 == MAX_SUB ? MAX_SUB : pCellDtls.subPosition + 1;
    } else {
      pCellDtls.cellId = pCellId;
      pCellDtls.subPosition =
        pCellDtls.subPosition >= MAX_SUB ? MAX_SUB : pCellDtls.subPosition;
    }
    setSelCellDtls(pCellDtls);
    setCellId(pCellId);
    setSelCellId(pCellId);
    setSelSubPosId(pCellDtls.subPosition);
    // console.log(document.querySelector('td.selected-cell'))
    // document.querySelector('td.selected-cell').closest('div').click();
    setCellRefresh(true);
  }
  const sortAscTotal = async (e) => {
    // console.log("reached")
    const sorted = await lstTch.sort((a, b) => {
      // console.log(a, b)
      return a.overallPct - b.overallPct;
    });
    setlstTch(sorted);
    setTchBodyKey(generateRandNo);
  };
  const sortDscTotal = async (e) => {
    // console.log("reached")
    const sorted = await lstTch.sort((a, b) => {
      // console.log(a, b)
      return b.overallPct - a.overallPct;
    });
    setlstTch(sorted);
    setTchBodyKey(generateRandNo);
  };
  const sortAscAllocation = async (e) => {
    // console.log("reached")
    const sorted = await lstTch.sort((a, b) => {
      // console.log(a, b)
      return a.pct - b.pct;
    });
    setlstTch(sorted);
    setTchBodyKey(generateRandNo);
  };
  const sortDscAllocation = async (e) => {
    // console.log("reached")
    const sorted = await lstTch.sort((a, b) => {
      // console.log(a, b)
      return b.pct - a.pct;
    });
    setlstTch(sorted);
    setTchBodyKey(generateRandNo);
  };
  function onClickSubCell(
    e,
    pCellId,
    pDayId,
    pPeriodId,
    pSubPosId,
    pTchId,
    pClassSectionId
  ) {
    pSubPosId = parseInt(pSubPosId) + 1;
    setSelCellId(pCellId);
    setSelSubPosId(pSubPosId);
    var subCtr;
    if (flgSuccess) {
      subCtr = pSubPosId + 1 > MAX_SUB ? MAX_SUB : pSubPosId + 1;
    } else {
      subCtr = pSubPosId > MAX_SUB ? MAX_SUB : pSubPosId;
    }
    var cellDtls = {
      cellId: pCellId,
      classSectionId: pClassSectionId,
      schedulePeriodId: pPeriodId,
      dayId: pDayId,
      subPosition: pSubPosId,
    };
    setSelCellDtls(cellDtls);
    setSelPeriodId(pPeriodId);
    setSelDayId(pDayId);
  }
  const getWingId = (wingId) => {
    // console.log("wingId" + wingId);
  };
  return (
    <div style={{ pointerEvents: state.opacity === 1 ? "auto" : "none" }}>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: state.loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <div className="MainContainer" style={{ opacity: state.opacity }}>
        <div className="col-md-12 col-centered wingsWrap clearfix">
          {lstCls && lstCls.length !== 0 && (
            <TTTeacherHead
              changeClass={changeClass}
              changeSection={changeSection}
              changeSession={changeSession}
              lstSession={lstSession}
              lstSection={lstSection}
              lstCls={lstCls}
              //   selSectionId={sectionId}
              selSessionId={sessionId}
              classId={classId}
              sectionId={sectionId}
              sessionId={sessionId}
              //   getClassName={getClassName}
            />
          )}
          <div className="row" style={{ marginTop: "1%", height: "38em" }}>
            {/* <div className="col-sm-12 padding-remove" style={{ marginTop: '1%', height: '38em' }}> */}
            <div className="col-sm-12 padding-remove">
              {lstDay && lstPeriod && lstPeriod.length !== 0 && (
                <TTTeacherBody
                  key={ttbodyKey}
                  cellId={cellId}
                  selCellId={selCellId}
                  selClsLabel={selClsLabel}
                  selSectionLabel={selSectionLabel}
                  lstTch={lstTch}
                  selTchId={selTchId}
                  classLabel={selClsLabel}
                  sectionLabel={selSectionLabel}
                  //rowClick={rowClickInMain}
                  tchbodyKey={tchbodyKey}
                  // cellKey={cellKey}
                  lstDay={lstDay}
                  lstPeriod={lstPeriod}
                  classId={classId}
                  classSectionId={sectionId}
                  sessionId={sessionId}
                  // selSubId={selSubId}
                  selDayId={selDayId}
                  selPeriodId={selPeriodId}
                  // refreshDayId={refreshDayId}
                  // refreshPeriodId={refreshPeriodId}
                  //selTchId={selTchId}
                  onClickCell={onClickCell}
                  onClickSubCell={onClickSubCell}
                  sortAscTotal={sortAscTotal}
                  sortDscTotal={sortDscTotal}
                  sortAscAllocation={sortAscAllocation}
                  sortDscAllocation={sortDscAllocation}
                  selCellDtls={selCellDtls}
                  setSelCellDtls={setSelCellDtls}
                  setCellId={setCellId}
                  cellRefresh={cellRefresh}
                  // setCellRefresh={setCellRefresh}
                  selectCellDtls={selectCellDtls}
                  setCellProps={setCellProps}
                  selSubPosId={selSubPosId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TTTeacherCtrl;
