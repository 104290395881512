import React, { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import Header from 'common/js/header/header.js';
import Map from './map';
import '../css/schoolList.css';

const SchoolList = () => {
    const [radius, setRadius] = useState(5);
    var initialState = {
        loader: 'none',
        opacity: 1,
    };
    const [state, setState] = useState(initialState)

    const data = [
        {
            id: 1,
            name: "Park Slope",
            latitude: "40.6710729",
            longitude: "-73.9988001",
            circle: {
                radius: 3000,
                options: {
                    strokeColor: "blue"
                    , fillColor: "#3878c7",
                    fillOpacity: 0.6,
                }
            }
        },
        {
            id: 2,
            name: "Bushwick",
            latitude: "40.6942861",
            longitude: "-73.9389312"
        },
        {
            id: 3,
            name: "East New York",
            latitude: "40.6577799",
            longitude: "-73.9147716",

        }
    ];

    const [lstSchool, setLstSchool] = useState(data);
    function inputVal(val) {
        setRadius(val);
        data[0].circle = {
            radius: val * 1000,
            options: {
                strokeColor: "blue"
                , fillColor: "#3878c7",
                fillOpacity: 0.6,
            }

        };
        setLstSchool(data);
        // console.log("inputVal=" + val)
    }
    function rangeVal(val) {
        if (isNaN(val)) {
            setRadius(0);
            data[0].circle = {
                radius: 0,
                options: {
                    strokeColor: "blue"
                    , fillColor: "#3878c7",
                    fillOpacity: 0.6,
                }
            };
            return;
        }
        setRadius(val);
        data[0].circle = {
            radius: val * 1000,
            options: {
                strokeColor: "blue"
                , fillColor: "#3878c7",
                fillOpacity: 0.6,
            }
        };
        setLstSchool(data);
        // console.log("rangeVal=" + val)
    }

    return (
        <div style={{ height: '100%' }}>
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="mainContainer" style={{ marginTop: 15, opacity: state.opacity, height: '86vh', border: '1px solid #bbbbbb', margin: '1%' }}>
                <form className="form form-horizontal" style={{ width: '100%' }}>

                    <div className='col-md-12 padding-remove'>
                        <div className="col-md-12" style={{ position: "absolute", zIndex: 100 }}>
                            <label className="col-md-1 control-label" style={{ color: 'rgb(255, 255, 255)', textAlign: 'left', width: '10%' }}>Distance(Kms.)</label>
                            <div className='form-group col-md-3'>
                                <input type="range" className="form-control dist-slider pull-right" name="distance" step="5" min="0" max="15" value={radius} onChange={(e) => { inputVal(e.target.value) }} />
                            </div>
                            <div className='form-group col-md-1'>
                                <input type="number" id="rangeDistance" min="5" max="15" step="5" className="form-control" placeholder="Distance" onChange={(e) => rangeVal(e.target.value)} onKeyPress={(e) => e.preventDefault()} onKeyDown={(e) => { if (e.keyCode === 8) return false; }} value={radius} readOnly />
                            </div>
                        </div>
                        <Map
                            radius={radius}
                            center={{ lat: 40.64, lng: -73.96 }}
                            zoom={12}
                            places={lstSchool}
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC_G16AeOUmhNAP9FuTvreRhFTs31bL4mQ"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `800px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                        />
                    </div>
                </form>
            </div >
        </div >
    )
}

export default SchoolList
