import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { getHeadListSvc, associateFeeHeadSvc } from './AdmissionSvc.js'

const AssociateHead = props => {
  const [lstEnqId, setLstEnqId] = useState([])
  const [headId, setHeadId] = useState(0)
  const [lstHead, setLstHead] = useState([])
  useEffect(() => {
    getHeadListSvc(handleHeadResponse)
  }, [])

  function handleHeadResponse (data) {
    setLstHead(data.lstHead)
  }

  function handleLstSelHeadChange (headId) {
    setHeadId(headId)
    // console.log('Value printed in setSelHeadId' + headId)
  }

  function associateHead () {
    if (props.lstSelEnqId.length === 0) {
      toast.warn('Please select atleast one enquiry')
      return
    }
    /* if (!headId) {
      toast.warn('Please select the head')
      return
    } */
    associateFeeHeadSvc(props.lstSelEnqId, headId, cbAssociateResponse)
  }

  function cbAssociateResponse (data) {
    props.generateRandomNo()
    props.refreshScreen()
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  return (
    <div className='form form-horizontal'>
      <div className='' style={{ border: '1px solid #ccc', paddingTop: 6 }}>
        <div className='form-group'>
          <label className='control-label col-sm-3'>
            Head<span className='red-color'>*</span>
          </label>
          <div className='col-sm-9  padding-remove'>
            <div className='form-group col-sm-6'>
              <select
                className='form-control'
                onChange={e => handleLstSelHeadChange(e.target.value)}
              >
                <option value={0}>Head</option>
                {lstHead &&
                  lstHead.length > 0 &&
                  lstHead.map((local, idx) => {
                    return (
                      <option key={idx} value={local.headId}>
                        {local.head}
                      </option>
                    )
                  })}
              </select>
            </div>
            <div className='col-sm-6'>
              <button
                type='button'
                className='btn btn-info '
                id='associateHead'
                onClick={e => associateHead()}
              >
                Associate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AssociateHead
