import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { toast, ToastContainer } from "react-toastify";
import "common/css/master.css";
import Header from "common/js/header/header.js";
import { viewReportSvc, viewMonthlyReportSvc } from "./DailyCollectionReportSvc";

function DailyCollectionReport() {
  const [loader, setLoader] = useState("none");
  const [state, setState] = useState({ fromDt: null, toDt: null });
  const [monthFromDt, setMonthFromDt] = useState(null);
  const [monthToDt, setMonthToDt] = useState(null);

  useEffect(() => {
    var endDt = new Date();
    var stDt = new Date();

    setState({
      fromDt: new Date(stDt),
      toDt: new Date(endDt),
    });
    const newEndDt = new Date(endDt);
    newEndDt.setMonth(newEndDt.getMonth() + 1);

    setMonthFromDt(new Date(stDt));
    setMonthToDt(newEndDt);

    var from = convert(new Date(stDt));
    var to = convert(new Date(endDt));
    // setLoader('block');
    // getData(from, to);
  }, []);

  function handleChange(date) {
    setState({
      ...state,
      fromDt: date,
    });
  }
  function handleChange1(date) {
    setState({
      ...state,
      toDt: date,
    });
  }

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    // console.log([day, mnth, date.getFullYear()].join("-"));
    return [day, mnth, date.getFullYear()].join("-");
  }
  function downloadExcel() {
    var from = convert(state.fromDt);
    var to = convert(state.toDt);
    setLoader("block");
    getData(from, to);
  }

  const getWingId = (wingId) => {
    // console.log("wingId = " + wingId);
  };
  function getData(from, to) {
    viewReportSvc(from, to, cbViewReportResponse);
  }
  function cbViewReportResponse(data) {
    setLoader("none");
    /* // toast.success(data.SvcMsg)
        let blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', lastModified: new Date().getTime() }),
            url = window.URL.createObjectURL(blob);
        const outputFilename = `${Date.now()}.xls`;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        fs.writeFileSync(outputFilename, data.data); */
    var url = document.createElement("a");
    url.href = window.URL.createObjectURL(
      new Blob([data.data], { type: "application/octet-stream" })
    );
    url.download = "Daily Collection Report.xlsx";
    document.body.appendChild(url);
    url.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(url);
    }, 200);
  }

  
  function downloadMonthlyExcelReport() {
    var from = convert(monthFromDt);
    var to = convert(monthToDt);
    setLoader("block");
    viewMonthlyReportSvc(from, to, monthlyReportResponse);
  }

  const monthlyReportResponse = (data) => {
    setLoader("none");
    var url = document.createElement("a");
    url.href = window.URL.createObjectURL(
      new Blob([data.data], { type: "application/octet-stream" })
    );
    url.download = "Monthly Collection Report.xlsx";
    document.body.appendChild(url);
    url.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(url);
    }, 200);
  }

  return (
    <div
      className="MainContainer"
      style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
    >
      <Header wingFromChild={getWingId} />

      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>

      <div className="col-sm-7 col-centered" style={{ marginTop: "3%" }}>
        <div
          className="col-sm-12 padding-remove"
          >

          <div
            className="col-sm-3 text-left" style={{fontWeight:"bold", paddingTop:"13px"}}>
            Daily Collection Report
          </div>
          <div
            className="col-sm-9"
            style={{
              border: "1px solid #ccc",
              borderRadius: 5,
              padding: 4,
            }}>
            <div className="col-sm-4" style={{ width: "40%" }}>
              <label
                className="col-sm-6 text-left padding-remove"
                style={{ top: 5 }}
              >
                From Date
              </label>
              <div className="form-group col-sm-6 padding-remove">
                <DatePicker
                  id="inp_toDate"
                  className="form-control text-center"
                  placeholderText="From Date"
                  selected={state.fromDt}
                  dateFormat="dd-MM-yyyy"
                  onChange={handleChange}
                  autoComplete="off"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                />
              </div>
            </div>

            <div className="col-sm-4" style={{ width: "38%" }}>
              <label
                className="text-left col-sm-5 padding-remove"
                style={{ top: 5 }}
              >
                To Date
              </label>
              <div className="form-group col-sm-7 padding-remove">
                <DatePicker
                  id="inp_toDate"
                  className="form-control text-center"
                  placeholderText="To Date"
                  selected={state.toDt}
                  onChange={handleChange1}
                  dateFormat="dd-MM-yyyy"
                  autoComplete="off"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                />
              </div>
            </div>
            <div
              className="col-sm-2 form-group pull-right"
              style={{ width: "18%" }}
            >
              <button
                type="button"
                className="btn btn-info pull-right"
                onClick={(e) => downloadExcel()}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-7 col-centered" style={{ marginTop: "5%" }}>
        <div
          className="col-sm-12 padding-remove"
          >

          <div
            className="col-sm-3 text-left" style={{fontWeight:"bold", paddingTop:"13px"}}>
            Monthly Collection Report
          </div>
          <div
            className="col-sm-9 "
            style={{
              border: "1px solid #ccc",
              borderRadius: 5,
              padding: 4,
            }}>
            <div className="col-sm-4" style={{ width: "40%" }}>
              <label
                className="col-sm-6 text-left padding-remove"
                style={{ top: 5 }}
              >
                From Date
              </label>
              <div className="form-group col-sm-6 padding-remove">
                <DatePicker
                  id="inp_toDate"
                  className="form-control text-center"
                  placeholderText="From Date"
                  selected={monthFromDt}
                  dateFormat="dd-MM-yyyy"
                  onChange={(dt)=> setMonthFromDt(dt)}
                  autoComplete="off"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                />
              </div>
            </div>

            <div className="col-sm-4" style={{ width: "38%" }}>
              <label
                className="text-left col-sm-5 padding-remove"
                style={{ top: 5 }}
              >
                To Date
              </label>
              <div className="form-group col-sm-7 padding-remove">
                <DatePicker
                  id="inp_toDate"
                  className="form-control text-center"
                  placeholderText="To Date"
                  selected={monthToDt}
                  onChange={(dt)=> setMonthToDt(dt)}
                  dateFormat="dd-MM-yyyy"
                  autoComplete="off"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                />
              </div>
            </div>
            <div
              className="col-sm-2 form-group pull-right"
              style={{ width: "18%" }}
            >
              <button
                type="button"
                className="btn btn-info pull-right"
                onClick={(e) => downloadMonthlyExcelReport()}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DailyCollectionReport;
