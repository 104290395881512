import React, { useState, useEffect } from 'react';
import ViewSlider from './viewslider';

function ClassWiseSummaryTbl(props) {
    const [lstTTSummary, setLstTTSummary] = useState(props.lstTTSummary);
    const [cls, setCls] = useState("")
    const [section, setSection] = useState("")
    const [foundCls, setFoundCls] = useState(props.lstTTSummary);
    const [selSectionId, setSelSectionId] = useState(0);
    const [flg, setFlg] = useState(false)
    const [clsId, setClsId] = useState(0)
    const [sectionId, setSectionId] = useState(0)
    const [sessionId, setSessionId] = useState(0)
    useEffect(() => {
        // console.log("useEffect called in classWiseSummaryTbl");
        // console.log("props.selSessionId",props.selSessionId);
        setLstTTSummary(props.lstTTSummary)
        setFoundCls(props.lstTTSummary)
        // console.log("props.lstTTSummary",props.lstTTSummary);
        
    }, [props]);
    const filterClass = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = foundCls.filter((user) => {
                return user.cls.toLowerCase().includes(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setFoundCls(results);
        } else {
            setFoundCls(lstTTSummary);
            // If the text field is empty, show all users
        }

        setCls(keyword);
    };
    const filterSection = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = foundCls.filter((user) => {
                return user.section.toLowerCase().includes(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setFoundCls(results);
        } else {
            setFoundCls(lstTTSummary);
            // If the text field is empty, show all users
        }

        setSection(keyword);
    };
    function view(pClsId, pSectionId, pSessionId) {
        // console.log(pClsId,pSectionId,pSessionId)
        setClsId(pClsId)
        setSectionId(pSectionId)
        setSessionId(pSessionId)
        setFlgView(true)
    }
    function setFlgView(params) {
        setFlg(params)
    }
    function setFlgClose() {
        setFlg(false)
    }
    return (
        <div>
            <table className="tableLayout1 clssummary" style={{ width: 700 }}>
                <thead>
                    <tr>
                        <th style={{ display: 'none' }}> classId </th>
                        <th style={{ width: 40 }}>
                            <input onChange={filterClass} type="text" value={cls} className="form-control inp-search" placeholder="Class" />
                        </th>
                        <th style={{ display: 'none' }}> sectionId </th>
                        <th style={{ width: 40 }}>
                            <input onChange={filterSection} type="text" value={section} className="form-control inp-search" placeholder="Section" />
                        </th>
                        <th style={{ width: 40 }}> Subject Allocation </th>
                        <th style={{ width: 40 }}> Teacher Allocation </th>
                        {/* <th style={{ width: 55 }}> Updated On </th> */}
                        <th style={{ width: 55 }}> Published On </th>
                        {/* <th style={{ width: 40 }}> Status </th> */}
                        <th style={{ width: 60 }}> Action </th>

                    </tr>
                </thead>
            </table>
            <div className="clssummaryTableDiv" style={{ height: '65vh', overflowY: 'scroll', border: '1px solid #ccc' }} >
                <table id="clssummaryTable" className="tableLayout">
                    <colgroup>
                        <col width="40" />
                        <col width="40" />
                        <col width="40" />
                        <col width="40" />
                        <col width="55" />
                        {/* <col width="55" /> */}
                        {/* <col width="40" /> */}
                        <col width="60" />
                    </colgroup>
                    <tbody id="summaryTablebody">
                        {foundCls && foundCls.map((obj) => (
                            <tr key={obj.sectionId} id={obj.clsId} onClick={(e) => setSelSectionId(obj.sectionId)} className={obj.sectionId === selSectionId ? "selected" : ""}>
                                <td>{obj.cls}</td>
                                <td>{obj.section}</td>
                                <td>{obj.subAllocation ? 'Done' : '-'}</td>
                                <td>{obj.tchAllocation ? 'Done' : '-'}</td>
                                {/* <td style={{ textAlign: 'center' }}>{obj.updatedOn}</td> */}
                                <td style={{ textAlign: 'center' }}>{obj.publishedOn}</td>
                                {/* <td style={{ textAlign: 'center' }}>{obj.status}</td> */}
                                <td className="text-center">
                                    <span id="editicon" title="Edit" className="padding-remove glyphicon glyphicon-edit" onClick={() => props.edit(obj.clsId, obj.sectionId, props.selSessionId, obj)}></span>
                                    <span id="viewicon" title="View" className="padding-remove glyphicon glyphicon-film" onClick={(e) => view(obj.clsId, obj.sectionId, props.selSessionId)}></span>
                                    <span id="publishicon" title="Publish" className="padding-remove glyphicon glyphicon-upload" onClick={() => props.send(obj.clsId, obj.sectionId)} style={{ paddingLeft: '10px' }}></span>
                                    <span id="deleteicon" title="Delete" className="padding-remove glyphicon glyphicon-trash" onClick={() => props.remove(obj.clsId, obj.sectionId)} style={{ paddingLeft: '10px' }}></span>
                                </td>
                            </tr>

                        ))}


                    </tbody>

                </table>
            </div>
            <div className='slider halfWidthSlider' style={{ right: 0, opacity: 1, display: flg === true ? 'block' : 'none', zIndex: 10000 }}>
                <ViewSlider
                    setFlgClose={setFlgClose}
                    flg={flg}
                    clsId={clsId}
                    sectionId={sectionId}
                    sessionId={sessionId}
                ></ViewSlider>
            </div>
        </div>

    )
}

export default ClassWiseSummaryTbl
