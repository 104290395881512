import axios from 'axios'
import lstModules from 'common/json/lstModules.json'

var httpURL = lstModules['feedback'].url + ':' + lstModules['feedback'].port;
var tokenid = localStorage.getItem('tokenid');

function getAreaListSvc(callBack) {
    axios(
        {
            url: httpURL + "/sms/feedback/area/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        // console.log("Area List Fetch error")
    });
}

function getChnlListSvc(callBack) {
    axios(
        {
            url: httpURL + "/sms/feedback/channel/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        // console.log("Teacher List Fetch error")
    });
}

function getFeedbackfromBE(cbFeedbackDtlsResponse, caseNo) {
    var result = { status: "Success", message: "" }
    axios(
        {
            url: httpURL + "/sms/feedback/dtls",
            method: "POST",
            withCredentials: true,
            params: { caseNo: caseNo },
            headers: { 'tokenid': tokenid }

        })
        .then((response) => {
            // console.log(response)

            result.feedbackDtls = response.data.feedbackDtls;
            result.status = response.data.SvcStatus;
            result.message = response.data.SvcMsg;

            cbFeedbackDtlsResponse(result)

        })
        .catch((error) => {
            // console.log("Feedback is not getting called")
        });
}

function saveReplySvc(cbReplyResponse, dtls) {
    axios(
        {
            url: httpURL + "/sms/feedback/reply",
            method: "POST",
            withCredentials: true,
            params: { reply: JSON.stringify(dtls) },
            headers: { 'tokenid': tokenid }

        })
        .then((Response) => {
            var result = {}
            result.status = Response.data.SvcStatus
            result.message = Response.data.SvcMsg
            cbReplyResponse(result)
        })
        .catch((error) => {
            console.error(error)
        });
}

export { getAreaListSvc, getChnlListSvc, getFeedbackfromBE, saveReplySvc }