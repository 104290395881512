import React, { useEffect, useState } from 'react'
import CompletionInd from './completionInd';
import DutyTbl from './dutyTbl';
import 'sms/tt/disruption/css/selectedCell.css'


const Duty = (props) => {
    const [state, setState] = useState({
        lstPeriod: props.lstPeriod,
        lstAbsentTch: props.lstAbsentTch,
        completionPct: props.completionPct
    })
    useEffect(() => {

        setState({
            completionPct: props.completionPct
            , lstAbsentTch: props.lstAbsentTch
            , lstPeriod: props.lstPeriod
        })

    }, [props])
    function setSelPeriodId(pId) {
        // console.log('pId = ' + pId)
        props.setSelPeriodId(pId)
    }

    function setSelTchId(tId) {
        // console.log('tId = ' + tId)
        props.setSelTchId(tId)
    }
    /* function setSelClsId(clsId) {
        // console.log('pId = ' + clsId)
        props.setSelClsId(clsId)
    }
    function setSelSecId(secId) {
        // console.log('pId = ' + secId)
        props.setSelSecId(secId)
    }
    function setSelSubId(subId) {
        // console.log('pId = ' + subId)
        props.setSelSubId(subId)
    } */
    function setSelCellDtls(cell) {
        props.setCellDtls(cell)
    }
    return (
        <div>
            <div>
                {state.completionPct && <CompletionInd completionPct={state.completionPct} setFlgView={props.setFlgView} informAll={props.informAll} />}
            </div>
            <div>
                {state.lstPeriod && <DutyTbl
                    lstPeriod={state.lstPeriod}
                    lstAbsentTch={state.lstAbsentTch}
                    selPeriodId={props.selPeriodId}
                    selTchId={props.selTchId}
                    setSelCellDtls={setSelCellDtls}
                    setSelPeriodId={setSelPeriodId}
                    setSelTchId={setSelTchId}
                    removeTchDuty={props.removeTchDuty}
                    onCellClicked={props.onCellClicked}
                />}
            </div>
            
        </div>
    )
}

export default Duty;