import React, { useEffect, useState } from 'react'

import "common/css/master.css";


const DailyDiarySummaryTbl = (props) => {
    const { lstSummary, diaryId, setDiaryId } = props;

    const [lstSummaryFiltered, setLstSummaryFiltered] = useState([]);
    const [selDiaryId, setSelDiaryId] = useState(0);

    useEffect(() => {
        // console.log("useEffect in DailyDiarySummaryTbl component called,lstSumary", lstSummary);
        setLstSummaryFiltered(lstSummary);
    }, [lstSummary]);

    useEffect(() => {
        // console.log("useEffect in DailyDiarySummaryTbl component called diaryId", diaryId);
        setSelDiaryId(diaryId);
    }, [diaryId]);

    const colgrp = <colgroup>
        <col width="20"></col>
        <col width="30"></col>
        <col width="30"></col>
        <col width="15"></col>
    </colgroup>



    return (
        <div >
            <div className="col-md-12 col-xs-12 col-centered padding-remove " >
                <table className="tableLayout1" >
                    {colgrp}
                    <thead>
                        <tr>
                            <th >
                                Date
                            </th>
                            <th >
                                Subject
                            </th>
                            <th >
                                Diary
                            </th>
                            <th >
                                Completed By
                            </th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv" style={{ height: "60vh" }}>
                    <table className="tableLayout">
                        {colgrp}
                        <tbody id="diaryDailySummaryTable" >
                            {lstSummaryFiltered &&
                                lstSummaryFiltered.map((section, i) => (
                                    <tr key={section.diaryId}
                                        onClick={() => setDiaryId(section.diaryId)}
                                        className={section.diaryId === selDiaryId ? "selected" : ''}>
                                        <td className="text-left">{section.diaryDt}</td>
                                        <td className="text-center">{section.subject}</td>
                                        <td className="text-center">{section.diary}</td>
                                        <td className="text-center">{section.countCompletedBy}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default DailyDiarySummaryTbl;