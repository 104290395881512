import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css'
import Header from 'common/js/header/header.js'
import SubjectSlider from './SubjectSlider';
import SubjectTable from './SubjectTable';
import { uploadSubjectExcelSvc } from './SubjectCtrlSvc';
import UploadErrorSlider from 'common/components/UploadErrorSlider';

const SubjCtrl = () => {

    const [subjectId, setSubjectId] = useState(0)
    const [subjectObj, setSubjectObj] = useState({})
    const [flgSliderType, setFlgSliderType] = useState(0)
    const [randNo, setRandNo] = useState(0)
    const [loader, setLoader] = useState('none')
    const [lstError, setLstError] = useState([]);
    const [subjectTableKey, setSubjectTableKey] = useState(0);

    function refreshScreen(pSubId) {
        setSubjectId(pSubId)
        setFlgSliderType(0);
        generateRandomNo();
    }
    function setSubjectData(subId, subObj) {
        // console.log("setSubjectData called, subId", subId, ", subObj", subObj);
        setSubjectId(subId);
        setSubjectObj(subObj)
        setFlgSliderType(10)
    }
    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console.log(lRandNo)
        setRandNo(lRandNo)
    }
    const triggerUploadExcel = () => {
        document.getElementById("excelUpload").click();
    }
    const uploadExcel = (event) => {

        setLoader('block');
        /* $("#excelUpload").on('change', function (event) { */

        if (!event.target.files) {
            setLoader('none');
            return;
        }

        var files = event.target.files; //FileList object
        /* if (!event.target.files.length) {
            // console.log("no file selected")
            this.setState({ loader: 'none', opacity: 1 });
            return;
        } */
        files = files[0];
        var formData = new FormData();
        formData.append("subjectUploadExcelFile", files);
        uploadSubjectExcelSvc(formData, cbSubjectExcelUpload);

        /* }); */

    }
    function cbSubjectExcelUpload(data) {
        if (data.status === "Success") {
            toast.success(data.message);
            // $('#btn_showerror').trigger('click');
        }
        else if (data.status === "Failure") {
            toast.error(data.message);
            if (data.hasOwnProperty("errors") && data.errors.length !== 0) {
                setFlgSliderType(20);
                setErrorList(data.errors);
            }
        } else {

            if (data.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (data.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(data.message);
            }
        }
        setLoader('none')
    }
    const setErrorList = async (errors) => {
        await setLstError(errors);
    }

    const showErrors = () => {
        // console.log("slider should be open")
        setFlgSliderType(20);

    }
    const getWingId = (wingId) => {
        // console.log("wingId" + wingId);
    }

    const updateKey = () => {
        setSubjectTableKey((prevKey) => prevKey + 1);
    }

    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className='MainContainer' style={{ marginTop: '1%' }}>
                <div className='col-sm-8 col-centered'>

                    <div className='col-sm-11' style={{ pointerEvents: loader === "none" ? "auto" : "none" }} >
                        <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }} >
                            <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                        </div >
                        <SubjectTable key={randNo} setSubjectData={setSubjectData} setSubjectId={setSubjectId} subjectId={subjectId} subjectTableKey={subjectTableKey} />
                    </div>
                    <div className="col-xs-1">
                        <div onClick={(e) => setSubjectData(0, { subId: 0 })}><span id="addReadyId" title="Add" className="addIcon glyphicon glyphicon-plus-sign"></span></div>
                        <div><span id="uploadStaff" title="Upload Staff" onClick={(e) => triggerUploadExcel()} className="addIcon glyphicon glyphicon-upload"></span></div>
                        <input key={new Date().getTime()} type="file" id="excelUpload" className="hidden" accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => uploadExcel(e)} />
                        <div><span id="viewErrorList" title="View Error List" onClick={(e) => showErrors()} className="addIcon glyphicon glyphicon-list-alt hidden"></span></div>
                    </div>
                    {flgSliderType === 10 ?
                        <div
                            style={{
                                width: '60%',
                                //  display: flgSliderType === 10 ? 'block' : 'none' 
                            }}
                            className='staffSlider'
                        >
                            <button type="button" className='btn btn-info text-center btnCloseDiv' style={{ right: '60%' }} onClick={refreshScreen}>
                                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                            </button>
                            <SubjectSlider
                                key={subjectId}
                                subjectId={subjectId}
                                subObj={subjectObj}
                                closeSlider={refreshScreen}
                                generateRandomNo={generateRandomNo}
                                flgSliderType={flgSliderType}
                                updateKey={updateKey}
                            />
                        </div>
                        : null
                    }
                    {flgSliderType === 20 ?
                        <div
                            style={{
                                width: '60%',
                                // display: flgSliderType === 20 ? 'block' : 'none'
                            }}
                            className='staffSlider'
                        >
                            <button type="button" className='btn btn-info text-center btnCloseDiv' style={{ right: '60%' }} onClick={refreshScreen}>
                                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                            </button>
                            <UploadErrorSlider
                                lstErrors={lstError}
                                heading='Staff'
                                countError={lstError.length || 0}
                                removeClassFromBody={refreshScreen}
                            />
                        </div>
                        : null
                    }
                </div>
            </div>
        </div >
    )
}

export default SubjCtrl