import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "../css/TestConfigCtrl.css";
import Header from "common/js/header/header.js";
import TestConfigTable from "./TestConfigTable";
import TestConfigSlider from "./TestConfigSlider";
import { getTestListSvc } from "./TestConfigSvc";
var tokenid = localStorage.getItem("tokenid");
function TestConfigCtrl() {
  const [loader, setLoader] = useState("none");
  const [selTestId, setSelTestId] = useState(0);
  const [flgSlider, setFlgSlider] = useState(false);
  const [lstTest, setLstTest] = useState([]);
  const [testObj, setTestObj] = useState({});
  const [testId, setTestId] = useState(0);
  const [randNo, setRandNo] = useState(0);
  useEffect(() => {
    getTestListSvc(handleTestResponse);
  }, []);

  function handleTestResponse(pLstTest) {
    setLstTest(pLstTest);
  }
  function setTestforEdit(testId) {
    // console.log("Param " + testId);
    for (var i = 0; i < lstTest.length; i++) {
      // console.log("Inside loop " + lstTest[i].testId);
      if (lstTest[i].testId === testId) {
        // console.log("inside if ");
        setTestId(lstTest[i].testId);
        setTestObj(lstTest[i]);
        break;
      }
    }
  }
  function openSlider() {
    setFlgSlider(true);
  }
  function closeSlider(pTestId) {
    setFlgSlider(false);
    setTestObj({});
    setTestId(pTestId);
    generateRandomNo();
    getLstTest();
  }
  function getLstTest() {
    getTestListSvc(handleTestResponse);
  }
  function generateRandomNo() {
    var lRandNo = Math.floor(Math.random() * 999999);
    // console.log(lRandNo);
    setRandNo(lRandNo);
  }
  const getWingId = (wingId) => {
    // console.log("wingId = " + wingId);
  };
  return (
    <div className="MainContainer">
      <Header wingFromChild={getWingId} />
      <div
        className="col-sm-12"
        style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
      >
        <ToastContainer autoClose={3000} hideProgressBar />
        <div
          style={{
            position: "fixed",
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: "50%",
            top: "50%",
            display: loader,
          }}
        >
          <img
            className="loderImg"
            src={require("common/images/loader.gif")}
            alt="Loader..."
          />
        </div>
      </div>
      <div className="TestTblWrapper">
        <div className="TestTbl">
          <TestConfigTable
            key={randNo}
            getLstTest={getLstTest}
            // setSelTestId={setSelTestId}
            closeSlider={closeSlider}
            openSlider={openSlider}
            setTestforEdit={setTestforEdit}
            lstTest={lstTest}
            generateRandomNo={generateRandomNo}
          />
        </div>
        <div
          className=""
          onClick={(e) => {
            setTestId(0);
            setTestObj({});
            openSlider();
          }}
        >
          <span
            id="addReadyId"
            title="Add"
            className="addIcon glyphicon glyphicon-plus-sign"
          ></span>
        </div>
      </div>
      <div
        style={{ display: flgSlider ? "block" : "none" }}
        className="TestSlider"
      >
        <TestConfigSlider
          key={testId}
          testId={testId}
          // setSelTestId={setSelTestId}
          closeSlider={closeSlider}
          openSlider={openSlider}
          testObj={testObj}
          clsId={testObj.clsId}
          wingId={testObj.wingId}
          test={testObj.test}
          duration={testObj.duration}
          maxScore={testObj.maxScore}
          passScore={testObj.passScore}
          mandatory={testObj.mandatory}
        />
      </div>
    </div>
  );
}

export default TestConfigCtrl;
