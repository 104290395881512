import axios from "axios";
import lstModules from "common/json/lstModules.json";
var httpURL = lstModules["library"].url + ":" + lstModules["library"].port;
var tokenid = localStorage.getItem("tokenid");

function searchBookBorrowerSvc(objSearch, callBack) {
  axios({
    url: httpURL + "/sms/library/return/borrower/search",
    method: "POST",
    params: {
      borrower: objSearch.keyBorrower,
      flgStaff: objSearch.flgStaff,
      bookCopyNo: objSearch.keyBookNo,
    },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      // console.log("Borrower Search Catch error");
    });
}

function getBorrowerBookListSvc(
  pStaffId,
  pStudentId,
  pFlgStaff,
  pLstBookCopyNo,
  callBack
) {
  var formData = new FormData();
  var obj = {
    staffId: pStaffId,
    studentId: pStudentId,
    flgStaff: pFlgStaff,
    lstBookCopyNo: pLstBookCopyNo,
  };
  formData.append("borrowerObj", JSON.stringify(obj));
  axios({
    url: httpURL + "/sms/library/return/borrower/book/list",
    method: "POST",
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      // console.log("Borrower Book List Catch error");
    });
}

function saveReturnBookSvc(pBorrowerObj, callBack) {
  var formData = new FormData();
  formData.append("returnObj", JSON.stringify(pBorrowerObj));
  axios({
    url: httpURL + "/sms/library/return/borrower/return/save",
    method: "POST",
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((response) => {
      var data = response.data;
      callBack(data);
    })
    .catch((error) => {
      // console.log("Error Occured in return Book");
    });
}

export {
  searchBookBorrowerSvc,
  getBorrowerBookListSvc,
  saveReturnBookSvc,
};


export const getIssueDetails = (cbIssueDtls) => {
  var obj = { status: "Success", message: "" };
  axios({
      url: httpURL + '/sms/library/mst/school/issue_period',
      method: "POST",
      headers: { tokenid: tokenid },
      withCredentials: true,
  })
      .then((response) => {
          obj.status = response.data.SvcStatus;
          obj.message = response.data.SvcMsg;
          obj.issueDtls = response.data.issueDtls;
          cbIssueDtls(obj);
      })
      .catch((e) => {
          obj.status = "Exception";
          obj.message = "error";
          cbIssueDtls(obj);
      })
}