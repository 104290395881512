import React, { useEffect, useState } from 'react'
import { getFeedbackTblList } from './FeedbackTblListSvc'

const FeedbackTable = (props) => {
  const [lstFeedback, setLstFeedback] = useState([])
  const [caseNo, setCaseNo] = useState(0)

  useEffect(() => {
    setCaseNo(props.caseNo)
    getFeedbackTblList(cbFeedbacklistResponse)
  }, [props.caseNo, props.randNo])

  async function cbFeedbacklistResponse(data) {
    // console.log(props.selLstAreaId)
    var results = []
    if (!props.selLstAreaId.length && !props.selLstStatusId.length) {
      // console.log("undefined areas list and no status selected")
      await setLstFeedback(data.lstFeedback)
      return;
    }
    if (props.selLstAreaId.length !== 0) {
      // console.log("selLstAreaId length is not zero")
      results = data.lstFeedback.filter((obj) => {
        return props.selLstAreaId.indexOf(obj.areaId) > -1;
      });
      await setLstFeedback(results);
    }
    // console.log(props.selLstStatusId)
    if (props.selLstStatusId.length !== 0) {
      // console.log("selLstStatusId length is not zero")
      var filteredResults = []
      if (results.length !== 0) {
        filteredResults = results.filter((obj) => {
          return props.selLstStatusId.indexOf(obj.statusId) > -1;
        });
      } else {
        filteredResults = data.lstFeedback.filter((obj) => {
          return props.selLstStatusId.indexOf(obj.statusId) > -1;
        });
      }
      await setLstFeedback(filteredResults);
    }

  }

  const colgrp = <colgroup>
    <col width="7%"></col>
    <col width="7%"></col>
    <col width="7%"></col>
    <col width="4%"></col>
    <col width="7%"></col>
    <col width="7%"></col>
    <col width="9%"></col>
    <col width="9%"></col>
    <col width="15%"></col>
    <col width="9%"></col>
    <col width="5%"></col>
    <col width="5%"></col>
    <col width="6%"></col>
  </colgroup>
  return (
    <div>
      <table className='tableLayout1'>
        {colgrp}
        <thead>
          <tr>
            <th>Last Actioned</th>
            <th>Created</th>
            <th>Case No.</th>
            <th>Priority</th>
            <th>Type</th>
            <th>Area</th>
            <th>Teacher</th>
            <th>Name</th>
            <th>eMail</th>
            <th>Phone</th>
            <th>Class</th>
            <th>Section</th>
            <th>Status</th>
          </tr>
        </thead>
      </table>
      <div className='tablebodyWrapper'>
        <table className='tableLayout'>
          {colgrp}
          <tbody>
            {lstFeedback && lstFeedback.map((value, key) => {
              var isSelected = caseNo === value.caseNo;
              return (

                <tr className={isSelected ? 'selected' : ''} key={key} onClick={() => props.onRowClick(value.caseNo)}>
                  <td>{value.lastActionedDttm}</td>
                  <td>{value.createdDttm}</td>
                  <td>{value.caseNo}</td>
                  <td>{value.priority}</td>
                  <td className="text-left">{value.type}</td>
                  <td className="text-left">{value.area}</td>
                  <td className="text-left">{value.tch}</td>
                  <td className="text-left">{value.name}</td>
                  <td className="text-left">{value.email}</td>
                  <td className="text-left">{value.phone}</td>
                  <td>{value.cls}</td>
                  <td>{value.section}</td>
                  <td className="text-left">{value.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>

  )
}

export default FeedbackTable