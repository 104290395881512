import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import 'jquery-ui';
import axios from 'axios';
import { toast } from 'react-toastify';

import ReactApexChart from 'react-apexcharts';



var httpURL = '';
var queryString = require('querystring');
var tokenid = localStorage.getItem('tokenid');
var enquiryData = [];
var enquiryRegister = [];
var weekLabels = [];
var screenId = 4;

// var enquiryData = [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160, 440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160,
//     440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160, 440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160];
// var enquiryRegister = [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16, 23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16,
//     23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16, 23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16];
// var weekLabels = ['01 week', '02 week', '03 week', '04 week', '05 week', '06 week', '07 week', '08 week', '09 week', '10 week', '11 week', '12 week',
//     '13 week', '14 week', '15 week', '16 week', '17 week', '18 week', '19 week', '20 week', '21 week', '22 week', '23 week', '24 week',
//     '01 week', '02 week', '03 week', '04 week', '05 week', '06 week', '07 week', '08 week', '09 week', '10 week', '11 week', '12 week',
//     '13 week', '14 week', '15 week', '16 week', '17 week', '18 week', '19 week', '20 week', '21 week', '22 week', '23 week', '24 week'];

class EnquiryConversion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {},
            series: [],
            tokenid: tokenid,
        };
    }

    componentDidMount = () => {
        this.getGraph();

    }
    getGraph = () => {

        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/enquiry/conversion?year=2020 ',
            queryString.stringify({ screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                enquiryData = response.data.conversion.enquiryData;
                enquiryRegister = response.data.conversion.enquiryRegister;
                weekLabels = response.data.conversion.weekLabels;
                // console.log(enquiryData);
                // console.log(enquiryRegister);
                // console.log(weekLabels);

                this.setState({
                    series: [{
                        name: 'Enquiry',
                        type: 'column',
                        data: enquiryData
                    }, {
                        name: 'Register ',
                        type: 'line',
                        data: enquiryRegister
                    }],
                    options: {
                        chart: {
                            height: 500,
                            type: 'line',
                        },
                        stroke: {
                            width: [0, 5],
                            show: true,
                            dashArray: 0,
                            lineCap: 'butt',
                        },
                        legend: {
                            show: true,
                            showForSingleSeries: false,
                            showForNullSeries: true,
                            showForZeroSeries: true,
                            position: 'top',
                            horizontalAlign: 'center',
                            floating: false,
                            fontSize: '14px',
                            fontWeight: 400,
                            offsetX: 100,
                            offsetY: -20,
                        },
                        markers: {
                            size: [0, 4],
                            width: 12,
                            height: 12,
                        },
                        title: {
                            text: 'Conversion(Registration to Enquiry)',
                            align: 'left',
                        },
                        dataLabels: {
                            enabled: true,
                            enabledOnSeries: [0],
                            style: {
                                fontSize: '15px',
                                fontWeight: 'bold',
                            },
                        },


                        xaxis: {
                            categories: weekLabels,
                            labels: {
                                offsetX: 6,
                                rotate: -45,
                                show: true,
                            },
                            axisTicks: {
                                show: true,
                            },
                            title: {
                                text: 'Weeks',
                                offsetX: 0,
                                offsetY: -20,
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            },
                        },
                        yaxis: [{
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                                color: '#000'
                            },
                            labels: {
                                style: {
                                    color: '#000',
                                    fontSize: 13
                                }
                            },
                            title: {
                                text: 'Enquiry',
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            },
                            tooltip: {
                                enabled: false
                            }

                        }, {
                            opposite: true,
                            title: {
                                text: 'Register ',
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            }
                        }]
                    },
                })
            }
            else {

                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    render() {
        httpURL = lstModules['enquiry'].url + ":" + lstModules['enquiry'].port;
        return (
            <div className="EnquiryConversion graphWrapper" style={{ opacity: this.state.opacity }}>
                <div className="col-sm-10 col-centered">
                    <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={500} />
                </div>
            </div>

        );
    }



}
export default EnquiryConversion;