import React, { useState, useEffect } from 'react'
import RdoLbl from './RdoLbl'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['enquiry'].url + ':' + lstModules['enquiry'].port
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port

const RegistrationCommunication = ({ admissionId }) => {
  const [commId, setCommId] = useState(0)
  const [loader, setLoader] = useState('none')
  useEffect(() => {
    setLoader('block')
    getCommunicationSvc(admissionId, cbCommunicationResponse)
  }, [])

  function cbCommunicationResponse (obj) {
    // console.log(obj)
    if (obj.SvcStatus === 'Success') {
      setCommId(obj.commId)
    } else {
      toast.error(obj.SvcMsg)
    }
    setLoader('none')
  }

  function getCommunicationSvc (admissionId, callBack) {
    axios({
      url: httpURL + '/sms/admission/comm/dtls',
      method: 'POST',
      params: { admissionId: admissionId },
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        // console.log(Response.data)
        var ourData = Response.data
        callBack(ourData)
      })
      .catch(error => {
        // console.log('error')
      })
  }
  function saveCommunication () {
    setLoader('block')
    saveCommunicationSvc(commId, cbSaveCommDtlsResponse)
  }
  function cbSaveCommDtlsResponse (data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
    setLoader('none')
  }
  function saveCommunicationSvc (commId, callBack) {
    axios({
      url: httpURL + '/sms/admission/comm/save',
      method: 'POST',
      params: { commId: commId, admissionId: admissionId },
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        // console.log(Response.data)
        var ourData = Response.data

        callBack(ourData)
      })
      .catch(error => {
        // console.log('error')
      })
  }
  const STUDENT = 10
  const FATHER = 20
  const MOTHER = 30
  const GUARDIAN = 40
  const DONOR = 50
  const OTHERS = 60

  return (
    <>
      <div
        className='col-sm-10'
        style={{
          height: '100%',
          float: 'left',
          background: '#fff',
          paddingTop: '0.5%',
          paddingBottom: '0.5%'
        }}
      >
        <div
          className='col-sm-12'
          style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}
        >
          <ToastContainer autoClose={3000} hideProgressBar />

          <div
            style={{
              position: 'fixed',
              zIndex: 10000,
              width: 100,
              height: 100,
              marginLeft: -50,
              marginTop: -50,
              left: '50%',
              top: '50%',
              display: loader
            }}
          >
            <img
              className='loderImg'
              src={require('common/images/loader.gif')}
              alt='Loader...'
            />
          </div>
          <RdoLbl
            label='Student'
            key={STUDENT}
            dataValue={STUDENT}
            checked={commId === STUDENT}
            onChange={setCommId}
          ></RdoLbl>
          <RdoLbl
            label='Father'
            key={FATHER}
            dataValue={FATHER}
            checked={commId === FATHER}
            onChange={setCommId}
          ></RdoLbl>
          <RdoLbl
            label='Mother'
            key={MOTHER}
            dataValue={MOTHER}
            checked={commId === MOTHER}
            onChange={setCommId}
          ></RdoLbl>
          <RdoLbl
            label='Guardian'
            key={GUARDIAN}
            dataValue={GUARDIAN}
            checked={commId === GUARDIAN}
            onChange={setCommId}
          ></RdoLbl>
          <RdoLbl
            label='Donor'
            key={DONOR}
            dataValue={DONOR}
            checked={commId === DONOR}
            onChange={setCommId}
          ></RdoLbl>
          <RdoLbl
            label='Others'
            key={OTHERS}
            dataValue={OTHERS}
            checked={commId === OTHERS}
            onChange={setCommId}
          ></RdoLbl>
        </div>
      </div>
      <div
        className='col-sm-2'
        style={{
          height: '100%',
          float: 'left',
          background: '#fff',
          paddingTop: '0.5%',
          paddingBottom: '0.5%'
        }}
      >
        <button
          type='button'
          className='btn-info btn'
          onClick={e => saveCommunication()}
        >
          Save
        </button>
      </div>
    </>
  )
}

export default RegistrationCommunication
