import React, { useState, useEffect } from "react";

export function BookIssueDtls(props) {
  const [bookNo, setBookNo] = useState("");
  const [copyNo, setCopyNo] = useState("");
  const [bookCopyNo, setBookCopyNo] = useState("");
  const [securityDeposit, setSecurityDeposit] = useState(0);
  const [cost, setCost] = useState(0);
  const [studentIssuePeriod, setStudentIssuePeriod] = useState(0);
  const [staffIssuePeriod, setStaffIssuePeriod] = useState(0);
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [publisher, setPublisher] = useState("");
  const [issueRestriction, setIssueRestriction] = useState("");
  const [borrower, setBorrower] = useState("");
  const [borrowerType, setBorrowerType] = useState("");
  const [dueDt, setDueDt] = useState("");
  const [loader, setLoader] = useState("none");

  /* useEffect(() => {
    // console.log(props);
  }, []); */
  useEffect(() => {
    // console.log(props);
    setBookCopyNo(
      props.bookObj.bookNo
        ? props.bookObj.copyNo
          ? `${props.bookObj.bookNo}-${props.bookObj.copyNo}`
          : `${props.bookObj.bookNo}`
        : ""
    );
    setBookNo(props.bookObj.bookNo);
    setCopyNo(props.copyNo);
    setSecurityDeposit(props.bookObj.securityDeposit);
    setCost(props.bookObj.cost);
    setStudentIssuePeriod(props.bookObj.studentIssuePeriod);
    setStaffIssuePeriod(props.bookObj.staffIssuePeriod);
    setTitle(props.bookObj.title);
    setAuthor(props.bookObj.author);
    setPublisher(props.bookObj.publisher);
    setIssueRestriction(props.bookObj.issueRestriction);
    setBorrower(props.bookObj.borrower);
    setBorrowerType(props.bookObj.borrowerType);
    setDueDt(props.bookObj.dueDt);
  }, [props.bookObj]);

  return (
    <form
      className="form form-horizontal"
      style={{
        width: "96%",
        margin: "0px 30px",
        background: "#fff",
        // border: "1px solid #ccc",
        // borderRadius: 10,
        pointerEvents: loader === "none" ? "auto" : "none",
      }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>

      <div className="col-sm-12 col-centered">
        <div className="form-group col-md-12 padding-remove">
          <label className="control-label col-md-2">Book No.</label>
          <div className=" padding-remove col-md-1">
            <input
              type="text"
              className="form-control text-center"
              defaultValue={bookCopyNo}
              readOnly
            />
          </div>
        </div>
        <div className="form-group col-md-12 padding-remove">
          <label className="control-label col-md-2">Title</label>
          <div className=" padding-remove col-md-3">
            <input
              type="text"
              className="form-control"
              value={title}
              readOnly
            />
          </div>
        </div>
        <div className="form-group col-md-12 padding-remove">
          <label className="control-label col-md-2">Author</label>
          <div className=" padding-remove col-md-3">
            <input
              type="text"
              className="form-control"
              value={author}
              readOnly
            />
          </div>
        </div>
        <div className="form-group col-md-12 padding-remove">
          <label className="control-label col-md-2">Publisher</label>
          <div className=" padding-remove col-md-3">
            <input
              type="text"
              className="form-control"
              value={publisher}
              readOnly
            />
          </div>
        </div>
        <div className="form-group col-md-12 padding-remove">
          <label className="control-label col-md-2">Issue Period</label>
          <div className="padding-remove col-md-10">
            <div className="form-group">
              <div className="col-md-2">
                <input
                  type="text"
                  className="form-control text-right"
                  value={staffIssuePeriod}
                  readOnly
                />
              </div>
              <label className="control-label col-md-1">Staff</label>
              <div className="col-md-2">
                <input
                  type="text"
                  className="form-control text-right"
                  value={studentIssuePeriod}
                  readOnly
                />
              </div>
              <label className="control-label col-md-1">Student</label>
            </div>
          </div>
        </div>
        <div className="form-group col-md-12 padding-remove">
          <label className="control-label col-md-2">Cost</label>
          <div className=" padding-remove col-md-2">
            <input
              type="text"
              className="form-control text-right"
              value={cost}
              readOnly
            />
          </div>
        </div>
        <div className="form-group col-md-12 padding-remove">
          <label className="control-label col-md-2">Security Deposit</label>
          <div className=" padding-remove col-md-2">
            <input
              type="text"
              className="form-control text-right"
              value={securityDeposit}
              style={{
                color: securityDeposit > 0 ? "#ff5722" : "",
                fontWeight: securityDeposit > 0 ? "bold" : "",
              }}
              readOnly
            />
          </div>
        </div>

        <div className="form-group col-md-12 padding-remove">
          <label className="control-label col-md-2">Issue Restriction</label>
          <div className=" padding-remove col-md-2">
            <input
              type="text"
              className="form-control text-left"
              value={issueRestriction}
              style={{
                color: issueRestriction != "" ? "#ff5722" : "",
                fontWeight: issueRestriction != "" ? "bold" : "",
              }}
              readOnly
            />
          </div>
        </div>
        <hr />
        {props.bookObj.statusId !== 10 && (
          <div className="col-md-12 padding-remove">
            <div
              className="col-md-12"
              style={{
                backgroundColor: "#ff5722",
                color: "#fff",
                fontWeight: "bold",
                height: 20,
                margin: 10,
                display: props.bookObj.statusId === 0 ? "none" : "block",
              }}
            >
              {props.bookObj.statusId === 20 && "Book is not available."}
              {props.bookObj.statusId === 30 && "Book is already issued."}
            </div>
          </div>
        )}

        {props.bookObj.statusId !== 10 && (
          <>
            <div className="form-group col-md-12 padding-remove">
              <label className="control-label col-md-2">Due Date</label>
              <div className=" padding-remove col-md-3">
                <input
                  type="text"
                  className="form-control"
                  value={dueDt}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group col-md-12 padding-remove">
              <label className="control-label col-md-2">Borrower</label>
              <div className=" padding-remove col-md-4">
                <input
                  type="text"
                  className="form-control"
                  value={borrower}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group col-md-12 padding-remove">
              <label className="control-label col-md-2">Staff/Student</label>
              <div className=" padding-remove col-md-3">
                <input
                  type="text"
                  className="form-control"
                  value={borrowerType}
                  readOnly
                />
              </div>
            </div>
          </>
        )}
      </div>
    </form>
  );
}
