import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";

import { lstAdmitCardSvc, getLogoSvc } from './admitCardSvc';
import AdmitCardPdf from '../pdf/admitCardPdf';




const AdmitCardPdfSlider = (props) => {
    const { sectionId, clsSection, exam, closePdfSlider } = props;

    const [pdfLoader, setPdfLoader] = useState("none");
    const [pdfOpacity, setPdfOpacity] = useState(1);
    const [lstStudent, setLstStudent] = useState([]);
    const [schoolLogo, setSchoolLogo] = useState("");


    useEffect(() => {
        // console.log("AdmitCardPdfSlider called ");
        // console.log("sectionId", sectionId);
        // console.log("clsSection", clsSection);
        // console.log("exam", exam);
        getLogoSvc(cbLogoResponse);
        getLstStudent(sectionId);
    }, [sectionId, exam]);



    function cbLogoResponse(imageUrl) {
        // console.log("cbLogoResponse called, imageUrl", imageUrl);
        setSchoolLogo(imageUrl)
    }

    const getLstStudent = (pSectionId) => {
        // console.log("getLstStudent called");
        setPdfLoader("block");
        setPdfOpacity(0.5);
        lstAdmitCardSvc(pSectionId, lstAdmitCardResponse);
    }

    const lstAdmitCardResponse = async (data) => {
        // console.log("lstAdmitCardResponse called, data", data);
        if (data.status === "Success") {
            await setLstStudent(data.lstStudent);
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setPdfLoader("none");
        setPdfOpacity(1);
    }

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    return (
        <div >
            <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={() => closePdfSlider()} style={{ right: "80%" }}>
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '58%', top: '45%', display: pdfLoader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="col-md-12 col-xs-12 col-centered padding-remove "
                style={{

                    cursor: (pdfLoader === "none") ? 'pointer' : 'none',
                    pointerEvents: (pdfLoader === "none") ? 'auto' : 'none',
                    opacity: pdfOpacity
                }}>
                {
                    !!lstStudent && lstStudent.length ?
                        <AdmitCardPdf
                            schoolLogo={schoolLogo}
                            clsSection={clsSection}
                            exam={exam}
                            lstStudent={lstStudent}
                        />
                        :
                        <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '58%', top: '45%' }}>
                            <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                        </div>
                }
            </div>
        </div>
    )
}

export default AdmitCardPdfSlider;