import React, { useEffect, useState } from 'react';
import { Document, Page, PDFViewer, View, Text } from '@react-pdf/renderer';

import { pageStyle } from './styleAdmitCard';
import AdmitCardContent from './admitCardContent';



const AdmitCardPdf = (props) => {


    const [lstStudent, setLstStudent] = useState([]);
    const [schoolLogo, setSchoolLogo] = useState("");
    const [clsSection, setClsSection] = useState("");
    const [examName, setExamName] = useState("");

    useEffect(() => {
        // console.log("AdmitCardPdf called");
        // console.log("lstStudent", lstStudent);
        // console.log("schoolLogo", schoolLogo);
        // console.log("clsSection", clsSection);
        // console.log("examName", examName);        
        setLstStudent(props.lstStudent);
        setSchoolLogo(props.schoolLogo);
        setClsSection(props.clsSection);
        setExamName(props.exam);
    }, [props]);


    const pages = [];

    for (let i = 0; i < lstStudent.length; i++) {
        pages.push(
            <AdmitCardContent
                key={lstStudent[i].studentId}
                studentName={lstStudent[i].student}
                schoolName={lstStudent[i].school}
                schoolAddress={lstStudent[i].schoolAddress}
                cls={clsSection}
                academicYear={lstStudent[i].acdYr}
                examName={examName}
                rollNo={lstStudent[i].rollNo}
                schoolLogo={schoolLogo}
                classTeacherSign={lstStudent[i].pathClassTeacherSign}
                studentImgUrl={lstStudent[i].imgPath}
            />
        )
    }

    return (
        <div className='col-sm-12 padding-remove'>
            <PDFViewer style={{ width: "100%", height: "95vh", margin: "2vh 0", overflowY: "scroll" }}>
                <Document>
                    <Page orientation="portrait" size="A4" style={pageStyle.page}>
                        {pages}
                    </Page>
                </Document>
            </PDFViewer>
        </div>
    );

}

export default AdmitCardPdf;