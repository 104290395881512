import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import 'jquery-ui';
import axios from 'axios';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import EnquiryConversion from './enquiryConversion.js';
import YearlyComparisonEnquiry from './YearlyComparisonEnquiry.js';
import ClassEnquiryGraph from './classEnquiryGraph.js';
import LoaderImage from 'common/components/LoaderImage.jsx';



var httpURL = '';
var queryString = require('querystring');
var screenId = 4;


var lstEnquiry = [];
var lstGender = [];
var lstSource = [];
var lstStatus = [];
var lstClass = [];
var lstTeacher = [];
var lstErrors = [];
var tokenid = localStorage.getItem('tokenid');

class Enquiry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            flg: true,
            tokenid: tokenid,
            enquiryDt: null,
            assignedOn: null,
            button: 'Show Enquiry Graphs'
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);
    }

    handleChange(date) {
        this.setState({
            enquiryDt: date,
        });
    }
    handleChange1(date) {
        this.setState({
            assignedOn: date,
        });
    }

    componentDidMount = () => {
        // console.log(this.state.tokenid);

        $('#registerWrapper').hide();
        $('.enquiryGraphs').hide();
        $('.Enquiry').show();

        $('#genderCmb').prepend($("<option> </option>").val(0).html('Gender'));
        $('#sourceCmb').prepend($("<option> </option>").val(0).html('Enquiry Type'));
        $('#statusCmb').prepend($("<option> </option>").val(0).html('Status'));
        $('#classAppliedCmb').prepend($("<option> </option>").val(0).html('Class'));
        $('#assignToCmb').prepend($("<option> </option>").val(0).html('Assign To'));
        lstGender = [{ 'genderId': '1', 'gender': 'M' }, { 'genderId': '2', 'gender': 'F' },
        { 'genderId': '3', 'gender': 'O' },
        ];
        this.fillGenderCmb();

        lstStatus = [{ 'statusId': 10, 'status': 'New' }, { 'statusId': 20, 'status': 'Responded' },
        { 'statusId': 30, 'status': 'Closed' },
        ];
        this.fillStatusCmb();
        this.fillFilterStatusCmb();

        lstSource = [{ 'sourceId': 10, 'source': 'Online' }, { 'sourceId': 20, 'source': 'Walkin' },
        { 'sourceId': 30, 'source': 'Phone' }, { 'sourceId': 40, 'source': 'Event' },
        { 'sourceId': 50, 'source': 'Customer Care' },
        ];
        this.fillEnquiryTypeCmb();
        this.fillFilterEnquiryCmb();




        axios({
            url: httpURL + '/sms/teacher/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstTeacher = response.data.lstTeacher;
                this.fillTeacherCmb();
            }
            else {
                toast.error(response.data.SvcMsg);
            }
        }).catch((error) => {
            toast.error("Service failed " + error);
        });


        axios({
            url: httpURL + '/sms/mst/class/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstClass = response.data.lstClass;
                this.fillClassCmb();
            }
            else {
                toast.error(response.data.SvcMsg);
            }
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

        this.getEnquiryList();

    }


    fillTeacherCmb() {
        $('#assignToCmb').empty();
        $('#assignToCmb').prepend($("<option> </option>").val(0).html('Assign To'));
        for (var i = 0; i < lstTeacher.length; i++) {
            var teacherId = lstTeacher[i].teacherId;
            var teacher = lstTeacher[i].teacher;
            $('#assignToCmb').append($("<option> </option>").val(teacherId).html(teacher));
        }
    }
    fillClassCmb() {
        $('#classAppliedCmb').empty();
        $('#classAppliedCmb').prepend($("<option> </option>").val(0).html('Class'));
        for (var i = 0; i < lstClass.length; i++) {
            var classId = lstClass[i].classId;
            var className = lstClass[i].className;
            $('#classAppliedCmb').append($("<option> </option>").val(classId).html(className));
        }
    }

    getEnquiryList = () => {
        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/enquiry/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstEnquiry = response.data.lstEnquiry;
                this.setEnquiryTable();
            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg)
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error("Service failed " + error);
        });
    }


    fillGenderCmb() {
        $('#genderCmb').empty();
        $('#genderCmb').prepend($("<option> </option>").val(0).html('Gender'));
        for (var i = 0; i < lstGender.length; i++) {
            var genderId = lstGender[i].genderId;
            var gender = lstGender[i].gender;
            $('#genderCmb').append($("<option> </option>").val(genderId).html(gender));
        }
    }
    fillEnquiryTypeCmb() {
        $('#sourceCmb').empty();
        $('#sourceCmb').prepend($("<option> </option>").val(0).html('Enquiry Type'));
        for (var i = 0; i < lstSource.length; i++) {
            var sourceId = lstSource[i].sourceId;
            var source = lstSource[i].source;
            $('#sourceCmb').append($("<option> </option>").val(sourceId).html(source));
        }
    }
    fillStatusCmb() {
        $('#statusCmb').empty();
        $('#statusCmb').prepend($("<option> </option>").val(0).html('Status'));
        for (var i = 0; i < lstStatus.length; i++) {
            var statusId = lstStatus[i].statusId;
            var status = lstStatus[i].status;
            $('#statusCmb').append($("<option> </option>").val(statusId).html(status));
        }
    }
    fillFilterStatusCmb() {
        $('#filterStatusCmb').empty();
        $('#filterStatusCmb').prepend($("<option> </option>").val(0).html('Status'));
        for (var i = 0; i < lstStatus.length; i++) {
            var statusId = lstStatus[i].statusId;
            var status = lstStatus[i].status;
            $('#filterStatusCmb').append($("<option> </option>").val(statusId).html(status));
        }
    }
    fillFilterEnquiryCmb() {
        $('#filterEnquiryTypeCmb').empty();
        $('#filterEnquiryTypeCmb').prepend($("<option> </option>").val(0).html('Source'));
        for (var i = 0; i < lstSource.length; i++) {
            var sourceId = lstSource[i].sourceId;
            var source = lstSource[i].source;
            $('#filterEnquiryTypeCmb').append($("<option> </option>").val(sourceId).html(source));
        }
    }


    setEnquiryTable = () => {
        $('#enquiryTableBody tr').remove();
        var table = document.getElementById('enquiryTable');
        var tbody = document.getElementById('enquiryTableBody');
        var filterName = $('#nameFilter').val() !== undefined ? $('#nameFilter').val().toLowerCase() : '';
        var filterContact = $('#contactFilter').val() !== undefined ? $('#contactFilter').val().toLowerCase() : '';
        var filterStatus = $('#filterStatusCmb').find('option:selected').text();
        var filterSource = $('#filterEnquiryTypeCmb').find('option:selected').text();


        for (var i = 0; i < lstEnquiry.length; i++) {
            var enquiryId = lstEnquiry[i].enquiryId;
            var enquiryNo = lstEnquiry[i].enquiryNo;
            var enquiry = lstEnquiry[i].enquiry;
            var gender = lstEnquiry[i].gender;
            var age = lstEnquiry[i].age;
            var contactNo = lstEnquiry[i].contactNo;
            var enquiryDt = lstEnquiry[i].enquiryDt;
            var className = lstEnquiry[i].classes.className;
            var assignedTo = lstEnquiry[i].assignedTo;
            var assignedOn = lstEnquiry[i].assignedOn;
            // var sourceId = lstEnquiry[i].sourceId;
            // var source = this.getSource(sourceId);
            var source = lstEnquiry[i].source;
            //var statusId = lstEnquiry[i].statusId;
            var status = lstEnquiry[i].status;
            // var status = this.getStatus(statusId);
            var genderAge = gender + "  /  " + age;



            var row = document.createElement('tr');
            row.id = enquiryId;


            if (enquiry != null && enquiry.toLowerCase().indexOf(filterName) === -1)
                continue;
            if (contactNo != null && contactNo.toLowerCase().indexOf(filterContact) === -1)
                continue;
            if (filterStatus !== "Status" && status !== filterStatus)
                continue;
            if (filterSource !== "Source" && source !== filterSource)
                continue;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.setEnquiryForm($(e.target).closest('tr').attr('id'));
                $('body').addClass('openSlider');
                $('#registerWrapper').show();
                $('.importWrap').hide();
                this.setState({
                    flg: false,
                });
            }

            this.addTD(row, enquiryId, 'no-display');
            this.addTD(row, enquiryNo, 'text-center');
            this.addTD(row, enquiry, 'text-left');
            this.addTD(row, genderAge, 'text-center');
            this.addTD(row, contactNo, 'text-center');
            this.addTD(row, enquiryDt, 'text-center');
            this.addTD(row, source, 'text-center');
            this.addTD(row, className, 'text-center');
            this.addTD(row, assignedTo, 'text-left');
            this.addTD(row, assignedOn, 'text-center');
            this.addTD(row, status, 'text-center');

            tbody.append(row);
        }
        table.append(tbody);
    }

    getSource(sourceId) {
        for (var i = 0; i < lstSource.length; i++) {
            if (parseInt(sourceId) === lstSource[i].sourceId)
                return lstSource[i].source;
        }
    }
    getStatus(statusId) {
        for (var i = 0; i < lstStatus.length; i++) {
            if (parseInt(statusId) === lstStatus[i].statusId)
                return lstStatus[i].status;
        }
    }


    setEnquiryForm = (enquiryId) => {
        var genderId = 0;
        var enquiryData = this.getEnquiryData(enquiryId);

        $('#enquiryNo').val(enquiryData.enquiryNo);
        $('#enquiry').val(enquiryData.enquiry);
        $('#classAppliedCmb').val(enquiryData.classes.classId);
        $('#age').val(enquiryData.age);
        var gender = enquiryData.gender;
        if (gender === 'M') {
            genderId = 1;
        }
        else if (gender === 'F') {
            genderId = 2;
        }
        else if (gender === 'O') {
            genderId = 3;
        }
        $('#genderCmb').val(genderId);
        $('#statusCmb').val(enquiryData.statusId);
        $('#sourceCmb').val(enquiryData.sourceId);
        $('#contactNo').val(enquiryData.contactNo);
        $('#assignedTo').val(enquiryData.assignedTo);
        $('#eMail').val(enquiryData.eMail);
        $('#regsNo').val(enquiryData.regsNo);
        $('#assignToCmb').val(enquiryData.assignedToId);
        $('#query').val(enquiryData.query);

        if (enquiryData.enquiryDt === "00-00-0000" || enquiryData.enquiryDt === null) {
            this.setState({
                enquiryDt: "",
            });
        }
        else {
            var date = enquiryData.enquiryDt;
            date = date.split('-');
            var setDate = date.reverse().join();
            setDate = setDate.replace(/,/g, '-');
            var enquiryDt = new Date(setDate);
            this.setState({
                enquiryDt: enquiryDt,
            });
        }

        if (enquiryData.assignedOn === "00-00-0000" || enquiryData.assignedOn === null) {
            this.setState({
                assignedOn: "",
            });
        }
        else {
            date = enquiryData.assignedOn;
            date = date.split('-');
            setDate = date.reverse().join();
            setDate = setDate.replace(/,/g, '-');
            var assignedOn = new Date(setDate);
            this.setState({
                assignedOn: assignedOn,
            });
        }


    }

    getEnquiryData(enquiryId) {
        for (var i = 0; i < lstEnquiry.length; i++) {
            if (parseInt(enquiryId) === lstEnquiry[i].enquiryId)
                return lstEnquiry[i];
        }
    }

    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    addEnquiryReady = () => {
        $('#enquiryAddForm').trigger('reset');
        $('#enquiryTableBody tr').removeClass('selected');
        $('body').addClass('openSlider');
        $('#registerWrapper').hide();
        $('.importWrap').show();
        this.setState({
            enquiryDt: null,
            assignedOn: null
        })
    }


    addEnquiry = () => {
        var enquiryArr = {};
        var enquiryId = 0;
        var enquiryNo = $('#enquiryNo').val();
        var enquiry = $('#enquiry').val();
        var gender = $('#genderCmb').find('option:selected').text();
        var age = $('#age').val();
        var contactNo = $('#contactNo').val();;
        var eMail = $('#eMail').val();;
        var sourceId = $('#sourceCmb').find('option:selected').val();
        // var source = $('#sourceCmb').find('option:selected').text();
        var classId = $('#classAppliedCmb').find('option:selected').val();
        var classes = { 'classId': classId };
        var assignedToId = $('#assignToCmb').find('option:selected').val();
        var enquiryDt = $('#enquiryDt').val();
        var assignedOn = $('#assignedOn').val();
        var statusId = $('#statusCmb').find('option:selected').val();
        var query = $('#query').val();
        var regsNo = $('#regsNo').val();


        if (enquiryNo === "") {
            toast.warning("Please Enter Enquiry");
            $('#enquiryNo').focus()
            return false
        }
        if (contactNo === "") {
            toast.warning("Please Enter Contact Number");
            $('#contactNo').focus()
            return false
        }
        if (eMail === "") {
            toast.warning("Please Enter Email");
            $('#eMail').focus()
            return false
        }
        if (sourceId === 0) {
            toast.warning("Please Select Valid Source");
            $('#sourceCmb').focus()
            return false
        }
        if (classId === 0) {
            toast.warning("Please Select Valid Class");
            $('#classAppliedCmb').focus()
            return false
        }
        // if (regsNo === "") {
        //     toast.warning("Please Enter Registration Number");
        //     $('#regsNo').focus()
        //     return false
        // }



        if (this.state.flg) {
            enquiryId = 0;
        } else {
            enquiryId = $('#enquiryTableBody tr.selected').find('td:eq(0)').text();
        }

        this.setState({ loader: 'block', opacity: 0.5 });
        enquiryArr = {
            'enquiryId': enquiryId,
            "enquiryNo": enquiryNo,
            "gender": gender,
            'enquiry': enquiry,
            enquiryDt,
            age,
            contactNo,
            eMail,
            sourceId,
            classes,
            assignedToId,
            assignedOn,
            statusId,
            query,
            regsNo
        };

        axios.post(httpURL + '/sms/enquiry/save',
            queryString.stringify({ 'enquiry': JSON.stringify(enquiryArr), screenId }),
            {
                headers: { 'tokenid': this.state.tokenid },
                withCredentials: true
            }
        ).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getEnquiryList();
                this.closeSlider();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
    }



    closeSlider = () => {
        $('body').removeClass('openSlider');
    }

    showGraphs = () => {
        if (this.state.button === "Show Enquiry Graphs") {
            $('.enquiryGraphs').slideDown();
            $('.Enquiry').slideUp();
            this.setState({
                button: 'Show Enquiry Details'
            })
        }
        else {
            $('.enquiryGraphs').slideUp();
            $('.Enquiry').slideDown();
            this.setState({
                button: 'Show Enquiry Graphs'
            })
        }
    }

    registerEnquiry = () => {
        let enquiryId = $('#enquiryTableBody tr.selected').find('td:eq(0)').text();
        let regsNo = $('#regsNo').val();

        if (regsNo === "") {
            toast.warning("Please Enter Registration Number");
            $('#regsNo').focus()
            return false
        }

        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/enquiry/register',
            method: "POST",
            params: { enquiryId, regsNo, screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getEnquiryList();
                this.closeSlider();

            } else {
                toast.error(response.data.SvcMsg);
            }

        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
        this.setState({ loader: 'none', opacity: 1 });

    }

    uploadExcel = () => {
        $("#excelUpload").click();
        $("#excelUpload").on('change', function (event) {
            if (!event.target.files) return;
            var files = event.target.files; //FileList object
            files = files[0];
            var formData = new FormData();
            formData.append("enquiryExcelFile", files);
            formData.append('screenId', screenId);

            axios({
                url: httpURL + '/sms/enquiry/excel/upload',
                method: "POST",
                data: formData,
                headers: { tokenid },
                withCredentials: true,
            }).then((response) => {
                if (response.data.SvcStatus === "Success") {
                    toast.success(response.data.SvcMsg);
                    this.getEnquiryList();
                    this.setEnquiryTable();
                }
                else {
                    toast.error(response.data.SvcMsg);
                    lstErrors = response.data.errors;
                    this.setErrorTable();
                    for (let i = 0; i < lstErrors.length; i++) {
                        // console.log(response.data.errors[i]);
                        toast.error(response.data.errors[i]);
                    }

                }
            }).catch((e) => {
                toast.error("Service failed " + e);
            });
        });

    }
    showErrors = () => {
        // $('body').removeClass('openSlider');
        $('body').addClass('showPopup');
    }

    closePopup = () => {
        $('body').removeClass('showPopup');
    }

    setErrorTable = () => {
        $('#errorTableBody tr').remove();
        var table = document.getElementById('errorTable');
        var tbody = document.getElementById('errorTableBody');

        for (var i = 0; i < lstErrors.length; i++) {
            var errorId = lstErrors[i].errorId;
            var lineNo = lstErrors[i].lineNo;
            var error = lstErrors[i].error;

            var row = document.createElement('tr');
            row.id = errorId;

            this.addTD(row, errorId, 'no-display');
            this.addTD(row, lineNo, 'text-center');
            this.addTD(row, error, 'text-center');

            tbody.append(row);
        }
        table.append(tbody);
    }

    getWingId = (wingId) => {
        // console.log("wingId" + wingId);
    }


    render() {
        httpURL = lstModules['enquiry'].url + ":" + lstModules['enquiry'].port;
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <LoaderImage />
                </div>
                <div className="mainContainer" style={{ opacity: this.state.opacity }}>
                    <div className="col-md-9 col-centered text-right">
                        <div className="graphButton">
                            <button className="btn btn-info" onClick={this.showGraphs}>{this.state.button}</button>
                        </div>
                    </div>
                    <div className=" Enquiry clearfix" >
                        <div className="col-md-11 col-centered">
                            <div className="classSummery">

                            </div>
                            <div className="statusSummery">

                            </div>
                            <div className="col-sm-11  col-xs-11 enquiryWrap  padding-remove">
                                <table className="tableLayout1 enquiry" style={{ width: this.state.width }}>
                                    <thead>
                                        <tr>
                                            <th style={{ display: 'none' }}> Enquiry Id </th>
                                            <th style={{ width: 30 }}> Enquiry No. </th>
                                            <th style={{ width: 50 }}>
                                                <input type="text" className="form-control inp-search" onChange={this.setEnquiryTable} id="nameFilter" placeholder="Name" />
                                            </th>
                                            <th style={{ width: 30 }}> Gender/Age </th>
                                            <th style={{ width: 40 }}>
                                                <input type="text" className="form-control inp-search" onChange={this.setEnquiryTable} id="contactFilter" placeholder="Contact" />
                                            </th>
                                            <th style={{ width: 35 }}> Enq. Date </th>
                                            <th style={{ width: 30 }}>
                                                <div style={{ float: 'left', width: '100%' }}>
                                                    <select type="text" onChange={this.setEnquiryTable} className="form-control inp-search" id="filterEnquiryTypeCmb"> </select>
                                                </div>
                                            </th>
                                            <th style={{ width: 40 }}> Class </th>
                                            <th style={{ width: 40 }}> Assigned To </th>
                                            <th style={{ width: 35 }}> Assigned On</th>
                                            <th style={{ width: 30 }}>
                                                <div style={{ float: 'left', width: '100%' }}>
                                                    <select type="text" onChange={this.setEnquiryTable} className="form-control inp-search" id="filterStatusCmb"> </select>
                                                </div>
                                            </th>

                                        </tr>
                                    </thead>
                                </table>
                                <div className="tableDiv">
                                    <table id="enquiryTable" className="tableLayout">
                                        <colgroup>
                                            <col width="30" />
                                            <col width="50" />
                                            <col width="30" />
                                            <col width="40" />
                                            <col width="35" />
                                            <col width="30" />
                                            <col width="40" />
                                            <col width="40" />
                                            <col width="35" />
                                            <col width="30" />
                                        </colgroup>
                                        <tbody id="enquiryTableBody"></tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-xs-1 ">
                                <span id="addEnquiry" title="Add Enquiry" onClick={this.addEnquiryReady} className="addIcon glyphicon glyphicon-plus-sign"></span>
                            </div>
                            <div className="col-sm-5 col-xs-8 padding-remove slider halfWidthSlider" >
                                <div className="col-sm-10 col-xs-10 col-centered padding-remove enquiryFormWrap" >
                                    <form id="enquiryAddForm" className="form-horizontal formWrapper">
                                        <div className="twoCol">
                                            <div className="form-group col-md-6">
                                                <label className="control-label col-sm-6" >Enquiry No.<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                <div className="col-sm-6 padding-remove">
                                                    <input autoComplete="off" id="enquiryNo" type="text" className="form-control " placeholder="Enter Enquiry Number" />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="control-label col-sm-6" >Enquiry Date<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                <div className="col-sm-6 padding-remove">
                                                    <DatePicker id="enquiryDt"
                                                        className='form-control'
                                                        placeholderText="Date"
                                                        selected={this.state.enquiryDt}
                                                        onChange={this.handleChange}
                                                        dateFormat="dd-MM-yyyy"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        adjustDateOnChange
                                                        dropdownMode="select"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="twoCol">
                                            <div className="form-group col-md-6">
                                                <label className="control-label col-sm-6" > Enq. Source Type<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                <div className="col-sm-6 padding-remove">
                                                    <select onChange={this.changeSourceType} className="form-control" style={{ width: '100%' }} id="sourceCmb"></select>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="control-label col-sm-6" > Name<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                <div className="col-sm-6 padding-remove">
                                                    <input autoComplete="off" id="enquiry" type="text" className="form-control " placeholder="Enter Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="twoCol">
                                            <div className="form-group col-md-6">
                                                <label className="control-label col-sm-6" > Age<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                <div className="col-sm-6 padding-remove">
                                                    <input autoComplete="off" id="age" type="text" className="form-control " placeholder="Enter Age" />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="control-label col-sm-6" > Gender<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                <div className="col-sm-6 padding-remove">
                                                    <select onChange={this.changeGender} className="form-control" style={{ width: '100%' }} id="genderCmb"></select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="twoCol">
                                            <div className="form-group col-md-6">
                                                <label className="control-label col-sm-6" > Contact<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                <div className="col-sm-6 padding-remove">
                                                    <input autoComplete="off" id="contactNo" type="text" className="form-control " placeholder="Enter Contact Number" />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="control-label col-sm-6" > Email<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                <div className="col-sm-6 padding-remove">
                                                    <input autoComplete="off" id="eMail" type="text" className="form-control " placeholder="Enter Email Id" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="twoCol">
                                            <div className="form-group col-md-6">
                                                <label className="control-label col-sm-6" > Class Applied For<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                <div className="col-sm-6 padding-remove">
                                                    <select onChange={this.changeClass} className="form-control" style={{ width: '100%' }} id="classAppliedCmb"></select>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="control-label col-sm-6" > Status<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                <div className="col-sm-6 padding-remove">
                                                    <select onChange={this.changeStatus} className="form-control" style={{ width: '100%' }} id="statusCmb"></select>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="twoCol">
                                        <div className="form-group col-md-6">
                                            <label className="control-label col-sm-6" >State<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                            <div className="col-sm-6 padding-remove">
                                                <select onChange={this.changeState} className="form-control" style={{ width: '100%' }} id="stateCmb"></select>
                                            </div>
                                        </div>
                                            <div className="form-group col-md-6">
                                                <label className="control-label col-sm-6" > City<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                <div className="col-sm-6 padding-remove">
                                                    <input autoComplete="off" id="city" type="text" className="form-control " placeholder="Enter City" />
                                                </div>
                                            </div>
                                            
                                        </div> */}
                                        <div className="twoCol">
                                            <div className="form-group col-md-6">
                                                <label className="control-label col-sm-6" >Assign To<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                <div className="col-sm-6 padding-remove">
                                                    <select onChange={this.changeAssignTo} className="form-control" style={{ width: '100%' }} id="assignToCmb"></select>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="control-label col-sm-6" >Assign On<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                <div className="col-sm-6 padding-remove">
                                                    <DatePicker id="assignedOn"
                                                        className='form-control'
                                                        placeholderText="Date"
                                                        selected={this.state.assignedOn}
                                                        onChange={this.handleChange1}
                                                        dateFormat="dd-MM-yyyy"
                                                        //minDate={new Date()}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        adjustDateOnChange
                                                        dropdownMode="select"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="twoCol">
                                            <div className="form-group col-md-11">
                                                <label className="control-label col-sm-3" > Query<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                <div className="col-sm-8 padding-remove">
                                                    <textarea autoComplete="off" id="query" type="text" className="form-control " placeholder="Enter Your Query (Max 200)"></textarea>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="form-group" >
                                            <div className="col-sm-12 text-center userButtonWrap" >
                                                <button type="button" style={{ marginTop: 20 }} title="Save" onClick={this.addEnquiry} className="btn btn-info">Save</button>

                                                <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={this.closeSlider}>
                                                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className=" importWrap text-center">
                                            <input type="file" id="excelUpload" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                            <button id='uploadeExcel' title="Upload Excel" type="button" onClick={this.uploadExcel} className="btn btn-warning">Upload Enquiry</button>
                                            <button type="button" id="errorButton" style={{ marginLeft: 20 }} title="Show Errors" onClick={this.showErrors} className="btn btn-danger">Show Errors</button>
                                        </div>
                                        <div className="twoCol" id="registerWrapper">
                                            <div className="form-group">
                                                <div className="form-group col-md-6">
                                                    <label className="control-label col-sm-6" > Reg. Number<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                                    <div className="col-sm-6 padding-remove">
                                                        <input autoComplete="off" id="regsNo" type="text" className="form-control " placeholder="Enter Registration Number" />
                                                    </div>
                                                </div>
                                                <button type="button" id="register" style={{ marginLeft: 20 }} title="Register" onClick={this.registerEnquiry} className="btn btn-warning register">Register</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-5 popupTable">
                                <div className="col-xs-12 padding-remove">
                                    <h4>Errors</h4>
                                    <table className="tableLayout1 errorTable" style={{ width: this.state.width }}>
                                        <thead>
                                            <tr>
                                                <th style={{ display: 'none' }}> error Id </th>
                                                <th style={{ width: 20 }}>Line Number </th>
                                                <th style={{ width: 60 }}> Error </th>

                                            </tr>
                                        </thead>
                                    </table>
                                    <div className="errorTableDiv" style={{ height: '300px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                        <table id="errorTable" className="tableLayout">
                                            <colgroup>
                                                <col width="20" />
                                                <col width="60" />
                                            </colgroup>
                                            <tbody id="errorTableBody"></tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-xs-12 text-center">
                                    <button type="button" title="Close" onClick={this.closePopup} className="btn btn-info">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="enquiryGraphs clearfix">
                        <EnquiryConversion />
                        <YearlyComparisonEnquiry />
                        <ClassEnquiryGraph />
                    </div>
                </div>
            </div>

        );
    }



}
export default Enquiry;