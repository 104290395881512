import React, { useState, useEffect, useRef } from "react";
import { saveTeamSvc } from "./TeamSvc";
import { toast, ToastContainer } from "react-toastify";
import { useDebounceEffect } from "common/functions/useDebounceEffect";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { getPreviewFile, canvasPreview } from "common/functions/canvasPreview";
import "react-image-crop/dist/ReactCrop.css";

var schoolId = 77; //localStorage.getItem("schoolId");

const TeamSlider = (props) => {
  const previewCanvasRef = useRef(null);
  const [file, setFile] = useState(null);
  const imgRef = useRef(null);

  const [loader, setLoader] = useState("none");
  const [teamId, setTeamId] = useState(0);
  const [desc, setDesc] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [qual, setQual] = useState("");
  const [isManagement, setManagement] = useState(false);
  const [mgmt, setMgmt] = useState("Staff");
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1);
  const reader = new FileReader();

  useEffect(() => {
    // console.log(props);
    setTeamId(props.teamObj.teamId);
    setName(props.teamObj.name);
    setRole(props.teamObj.role);
    setQual(props.teamObj.qual);
    setDesc(props.teamObj.description);
    setMgmt(props.teamObj.management ? "Management" : "Staff");
    setManagement(props.teamObj.management);
    var url = `https://sms.cooledsys.com:9233/schools/mst/img/view?flg=${props.teamObj.management ? 2 : 1
      }&schoolId=${schoolId}&imgPath=${props.teamObj.img}`;
    setImgSrc(url);
    toDataURL(url).then((dataUrl) => {
      // console.log("Here is Base64 Url", dataUrl);
      var fileData = dataURLtoFile(dataUrl, "imageName.jpg");
      // console.log("Here is JavaScript File Object", fileData);
      setFile(fileData);
    });
  }, [props.teamObj.teamId]);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
        setFile(
          getPreviewFile(
            imgRef.current,
            previewCanvasRef.current,
            completedCrop,
            scale,
            rotate
          )
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );
  /***---------------Here is the code for converting "image source" (url) to "Base64".---------***/

  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  /***----------Here is code for converting "Base64" to javascript "File Object".------------***/

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  /*** ---Calling both function -------***/

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
    setAspect(undefined);
    // handleToggleAspectClick();
  }

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  function saveTeam() {
    var teamDtls = {
      schoolId: schoolId,
      teamId: teamId,
      name: name,
      description: desc,
      role: role,
      qual: qual,
      management: isManagement,
    };
    var formData = new FormData();
    formData.append("team", JSON.stringify(teamDtls));
    formData.append("img", file);
    saveTeamSvc(formData, cbSaveTeamResponse);
  }

  function cbSaveTeamResponse(pObj) {
    if (pObj.status === "Success") {
      toast.success(pObj.message);
      props.closeSlider();
      props.getTeamList();
    } else {
      toast.error(pObj.message);
    }
    setLoader("none");
  }

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      setImgSrc(e.target.files[0]);
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      setFile(e.target.files[0]);
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  return (
    <form
      className="form form-horizontal"
      style={{
        pointerEvents: loader === "none" ? "auto" : "none",
        height: "auto",
        width: "100%",
        margin: "0px auto",
        padding: 10,
        background: "#fff",
        border: "1px solid #ccc",
        borderRadius: 10,
      }}
    >
      <button
        type="button"
        className="btn btn-info text-center btnCloseDiv"
        onClick={props.closeSlider}
        style={{ right: "60%" }}
      >
        <span
          id="close"
          title="Close Slider"
          className="glyphicon glyphicon-remove"
        ></span>
      </button>
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-12">
          <label className="control-label col-sm-4">Name </label>
          <div className="col-sm-8 padding-remove">
            <input
              type="text"
              defaultValue={name}
              className="form-control "
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
        </div>
      </div>

      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-12">
          <label className="control-label col-sm-4">Role </label>
          <div className="col-sm-4 padding-remove">
            <input
              type="text"
              defaultValue={role}
              className="form-control "
              onChange={(e) => setRole(e.target.value)}
            ></input>
          </div>
        </div>
      </div>

      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-12">
          <label className="control-label col-sm-4">Qualification </label>
          <div className="col-sm-4 padding-remove">
            <input
              type="text"
              value={qual}
              className="form-control"
              onChange={(e) => setQual(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-12">
          <label className="control-label col-sm-4">Type</label>
          <div className="col-sm-8 padding-remove">
            <label className="radio-inline">
              <input
                type="radio"
                name="management"
                value="Management"
                checked={mgmt === "Management"}
                onChange={(e) => {
                  setMgmt("Management");
                  setManagement(true);
                }}
              ></input>
              Management
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="management"
                value="Staff"
                checked={mgmt === "Staff"}
                onChange={(e) => {
                  setMgmt("Staff");
                  setManagement(false);
                }}
              ></input>
              Staff
            </label>
          </div>
        </div>
      </div>
      {isManagement && (
        <div className="row" style={{ width: "100%" }}>
          <div className="form-group col-md-12">
            <label className="control-label col-sm-4">Description </label>
            <div className="col-sm-8 padding-remove">
              <textarea
                maxLength={500}
                rows="8"
                cols="80"
                defaultValue={desc}
                className="form-control"
                onChange={(e) => setDesc(e.target.value)}
                required
              ></textarea>
            </div>
          </div>
        </div>
      )}

      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-12">
          <label className="control-label col-sm-4">Photo </label>
          <div className="col-sm-8 padding-remove">
            <div className="Crop-Controls" style={{ marginTop: 5 }}>
              <input
                type="file"
                accept="image/*"
                onChange={onSelectFile}
                style={{ display: "block" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-sm-12">
          <label className="control-label col-sm-4"></label>
          <div className="col-sm-4 padding-remove">
            {!!imgSrc && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
          </div>
          <div className="col-sm-4">
            {!!completedCrop && (
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: "1px solid black",
                  objectFit: "contain",
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div className="row" style={{ width: "100%", paddingBottom: "1px" }}>
        <div className="col-md-offset-10 col-md-1 padding-remove">
          <button
            type="button"
            className="btn btn-info pull-right"
            onClick={(e) => saveTeam()}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default TeamSlider;
