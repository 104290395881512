import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import 'jquery-ui';
import '../css/class.css';
import 'common/css/master.css';
import axios from 'axios';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
var httpURL = '';
var queryString = require('querystring');
var tokenid = localStorage.getItem('tokenid');
var lstAllClassSections = [];
var lstClassTeacher = [];
var lstBuilding = [];
var lstRoom = [];
var lstPrefect = [];
var lstMonitor = [];
var screenId = 2;




class Class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            flg: true,
            flg1: true,
            fromDt: null,
            effectiveDt: null,
            tokenid: tokenid,

        }
        this.handleChange1 = this.handleChange1.bind(this);
    }

    handleChange1(date) {
        this.setState({
            effectiveDt: date,
        });
    }

    componentDidMount = () => {

        this.getClassList();

    }

    getClassList = () => {

        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/cls/section/list',
            queryString.stringify({ screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstAllClassSections = response.data.lstAllClassSections;
                this.setClassTable();

            }
            else {

                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error("Service failed " + error);
        });
    }


    setClassTable = () => {
        $('#classTableBody tr').remove();
        var table = document.getElementById('classTable');
        var tbody = document.getElementById('classTableBody');

        for (var i = 0; i < lstAllClassSections.length; i++) {
            var classId = lstAllClassSections[i].classId;
            var classSectionId = lstAllClassSections[i].classSectionId;
            var className = lstAllClassSections[i].className;
            var section = lstAllClassSections[i].section;
            var classTeacher = lstAllClassSections[i].classTeacher;
            var building = lstAllClassSections[i].buildingName;
            var room = lstAllClassSections[i].room;
            var capacity = lstAllClassSections[i].capacity;
            var maxStudents = lstAllClassSections[i].maxStudents;
            var currentStudent = lstAllClassSections[i].currentStudent;


            var row = document.createElement('tr');
            row.id = classSectionId;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
            }

            this.addTD(row, classSectionId, 'no-display');
            this.addTD(row, classId, 'no-display');
            this.addTD(row, className, 'text-center');
            this.addTD(row, section, 'text-center');
            this.addTD(row, classTeacher, 'text-center');
            this.addTD(row, building, 'text-center');
            this.addTD(row, room, 'text-center');
            this.addTD(row, capacity, 'text-center');
            this.addTD(row, maxStudents, 'text-center');
            this.addTD(row, currentStudent, 'text-center');
            this.addTDAction(row, classSectionId, 'glyphicon glyphicon-trash', 'text-center actions');



            tbody.append(row);
        }
        table.append(tbody);
    }

    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    addTDAction(row, statusId, val, classname) {
        // console.log("addTDAction called, row", row, ", statusId", statusId, ", val", val, ", classname", classname);
        var cell = document.createElement('td');
        cell.className = classname;
        var span = document.createElement('span');
        var span1 = document.createElement('span');
        span.className = val;
        span1.className = 'glyphicon glyphicon-edit'
        span1.style.cursor = 'pointer';
        span.style.cursor = 'pointer';
        span.onclick = () => {
            $('td').removeClass('selected');
            $(row).addClass('selected').siblings().removeClass('selected');
            // var classId = $('#classTableBody tr.selected').find('td:eq(0)').text();
            // var sectionId = $('#classTableBody tr.selected').find('td:eq(1)').text();
            this.getConfirmation();
        };
        span1.onclick = (e) => {
            $('td').removeClass('selected');
            $(row).addClass('selected').siblings().removeClass('selected');
            var classSectionId = $('#classTableBody tr.selected').find('td:eq(0)').text();
            var classId = $('#classTableBody tr.selected').find('td:eq(1)').text();
            var section = $('#classTableBody tr.selected').find('td:eq(3)').text();

            // var classSectionData = await this.getClassSecionData(classSectionId);

            // localStorage.setItem("classId", classId);
            // localStorage.setItem("section", section);
            // localStorage.setItem("classSectionData", JSON.stringify(classSectionData));
            // localStorage.setItem('classSectionId', classSectionId);

            let newPageUrl = '/classStudentManage?classId=' +classId+ '&section=' +section+ '&classSectionId=' +classSectionId;
            window.open(newPageUrl, "_blank");
        };
        cell.append(span);
        cell.append(span1);
        row.append(cell);
    }

    getConfirmation = () => {
        var retVal = window.confirm("Do you want to Delete Class Section ?");
        if (retVal == true) {
            this.deleteClass();
            return true;
        } else {
            return false;
        }
    }

    deleteClass = () => {
        // console.log("deleteClass called");
        var classSectionId = $('#classTableBody tr.selected').find('td:eq(0)').text();
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/cls/delete',
            queryString.stringify({ classSectionId, screenId }),
            {
                headers: { tokenid: this.state.tokenid },
                withCredentials: true
            }
        ).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getClassList();
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }

    // getClassSecionData=(classSectionId)=>{
    //     for (var i = 0; i < lstAllClassSections.length; i++) {
    //         if (parseInt(classSectionId) === lstAllClassSections[i].classSectionId) {
    //             return lstAllClassSections[i];
    //         }
    //     }
    //     return -1;
    // }

    addClassReady = () => {
        // localStorage.setItem("classId", "");
        // localStorage.setItem("section", "");
        // localStorage.setItem('classSectionId', 0);
        let newPageUrl = '/classStudentManage?classId=' + "" + '&section=' + "" + '&classSectionId=' + 0;
        window.open(newPageUrl, "_blank");
    }



    getWingId = (wingId) => {
        // console.log("wingId" + wingId);
    }


    render() {
        httpURL = lstModules['classes'].url + ":" + lstModules['classes'].port;
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer Class" style={{ opacity: this.state.opacity }}>
                    <div className="col-md-11 col-centered">
                        <div className="col-md-11  col-xs-11 classTableWrap  padding-remove">
                            <table className="tableLayout1 classTable" style={{ width: this.state.width }}>
                                <thead>
                                    <tr>
                                        <th style={{ display: 'none' }}> Class Id </th>
                                        <th style={{ width: 40 }}>Class </th>
                                        <th style={{ width: 40 }}> Section </th>
                                        <th style={{ width: 40 }}> Class Teacher</th>
                                        <th style={{ width: 40 }}> Building  </th>
                                        <th style={{ width: 40 }}> Room </th>
                                        <th style={{ width: 40 }}> Room Capacity</th>
                                        <th style={{ width: 40 }}> Max. Student</th>
                                        <th style={{ width: 40 }}> Current Student</th>
                                        <th style={{ width: 40 }}> Action</th>

                                    </tr>
                                </thead>
                            </table>
                            <div className="timeTableDiv" style={{ height: '400px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                <table id="classTable" className="tableLayout">
                                    <colgroup>
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                    </colgroup>
                                    <tbody id="classTableBody"></tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-xs-1 ">
                            <span id="addClass" title="Add Class" onClick={this.addClassReady} className="addIcon glyphicon glyphicon-plus-sign"></span>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

}
export default Class;
