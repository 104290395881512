import React, { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import '../css/EnquiryCtrl.css'
import 'common/css/master.css'
import AdmissionTable from './AdmissionTable.js'
import SchTestSlider from './SchTestSlider.js'
import AssignCounsellor from './AssignCounsellor'
import CloseSlider from './CloseSlider'
import EvaluateTestSlider from './EvaluateTestSlider'
import AssociateHead from './AssociateHead'
import Header from 'common/js/header/header.js'
import SliderType from './sliderType'
import OneHeadListPopup from './OneHeadListPopup'

import ScheduleInterviewSlider from './ScheduleInterviewSlider'
import EvaluateInterviewSlider from './EvaluateInterviewSlider'
import RegistrationFeeSlider from './RegistrationFeeSlider'
import AdmissionFeeSlider from './AdmissionFeeSlider'
import RegsFeePaymentSlider from './RegsFeePaymentSlider'
import AdmnFeePaymentSlider from './AdmnFeePaymentSlider'

function AdmissionCtrl () {
  const [selectedAdmissionId, setSelectedAdmissionId] = useState(0)
  const [lstSelAdmissionId, setLstSelAdmissionId] = useState([])
  const [flgSliderType, setFlgSliderType] = useState(0)
  const [randNo, setRandNo] = useState(0)
  const [cls, setCls] = useState('')
  const [amount, setAmount] = useState(0)
  const [selectedClsId, setSelectedClsId] = useState(0)
  const [oneTimeHeadId, setOneTimeHeadId] = useState(0)
  const [oneTimeHead, setOneTimeHead] = useState('-')
  const [lstOneTimeHead, setLstOneTimeHead] = useState([])
  const [popupId, setPopupId] = useState(0)
  function refreshScreen () {
    setSelectedAdmissionId(0)
    setFlgSliderType(0)
    generateRandomNo()
  }
  function setAdmissionToAssign (param) {
    setLstSelAdmissionId(param)
  }
  function generateRandomNo () {
    var lRandNo = Math.floor(Math.random() * 999999)
    // console.log(lRandNo)
    setRandNo(lRandNo)
  }
  const closePopup = () => {
    document.querySelector('body').classList.remove('showPopup')
  }
  function scheduleInterviewSlider (pTypeId) {
    if (lstSelAdmissionId.length === 0) {
      toast.warn('Please select atleast one enquiry')
      return
    }
    setFlgSliderType(pTypeId)
  }
  function openOneTimeHeadPopup () {
    setPopupId(SliderType.POPUP_REGISTER_FEE)
    document.querySelector('body').classList.add('showPopup')
  }
  const getWingId = wingId => {
    // console.log('wingId = ' + wingId)
  }
  const goToRegisterScreen = () => {
    /* await localStorage.setItem('AdmissionId', pAdmissionId)
    await localStorage.setItem('pUserId', pUserId) */
    window.open('/admnregister?admissionId=0', '_blank')
  }
  return (
    <div className='MainContainer'>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />

      <div className='enquiryTblWrapper'>
        <div className='enquiryTbl'>
          <AdmissionTable
            key={randNo}
            setCls={setCls}
            selectedAdmissionId={selectedAdmissionId}
            setSelectedAdmissionId={setSelectedAdmissionId}
            setAdmissionToAssign={setAdmissionToAssign}
            setFlgSliderType={setFlgSliderType}
            setSelectedClsId={setSelectedClsId}
          />
          <div className='col-sm-12'>
            <div className='assignStaffWrapper col-sm-7'>
              <AssignCounsellor
                key={randNo}
                lstSelAdmissionId={lstSelAdmissionId}
                refreshScreen={refreshScreen}
                generateRandomNo={generateRandomNo}
              />
            </div>
            <div className='form form-horizontal'>
              <div className='col-md-2 pull-right' style={{ marginTop: '1%' }}>
                <button
                  type='button'
                  className='btn btn-info'
                  onClick={e =>
                    scheduleInterviewSlider(
                      SliderType.SLIDER_SCHEDULE_INTERVIEW
                    )
                  }
                >
                  Schedule Interview
                </button>
              </div>
              <div className='col-md-3'>
                <div className='assignStaffWrapper col-sm-12'>
                  <AssociateHead
                    key={randNo}
                    lstSelAdmissionId={lstSelAdmissionId}
                    refreshScreen={refreshScreen}
                    generateRandomNo={generateRandomNo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className=''
          onClick={e => setFlgSliderType(SliderType.SLIDER_ENQUIRY_DTLS)}
        >
          <span
            id='addReadyId'
            title='Add'
            className='addIcon glyphicon glyphicon-plus-sign'
            onClick={e => goToRegisterScreen()}
          ></span>
        </div>
      </div>

      <div
        style={{
          width: '90%',
          display:
            flgSliderType === SliderType.SLIDER_SCHEDULE_TEST ? 'block' : 'none'
        }}
        className='enquirySlider'
      >
        <button
          type='button'
          className='btn btn-info text-center btnCloseDiv'
          style={{ right: '90%' }}
          onClick={refreshScreen}
        >
          <span
            id='close'
            title='Close Slider'
            className='glyphicon glyphicon-remove'
          ></span>
        </button>
        <SchTestSlider
          key={selectedAdmissionId}
          enquiryId={selectedAdmissionId}
          closeSlider={refreshScreen}
          clsId={selectedClsId}
          flgSliderType={flgSliderType}
        />
      </div>
      <div
        style={{
          width: '90%',
          display:
            flgSliderType === SliderType.SLIDER_TEST_RESULT ? 'block' : 'none'
        }}
        className='enquirySlider'
      >
        <button
          type='button'
          className='btn btn-info text-center btnCloseDiv'
          style={{ right: '90%' }}
          onClick={refreshScreen}
        >
          <span
            id='close'
            title='Close Slider'
            className='glyphicon glyphicon-remove'
          ></span>
        </button>
        <EvaluateTestSlider
          key={selectedAdmissionId}
          enquiryId={selectedAdmissionId}
          closeSlider={refreshScreen}
          clsId={selectedClsId}
          flgSliderType={flgSliderType}
        />
      </div>
      <div
        style={{
          width: '50%',
          display:
            flgSliderType === SliderType.SLIDER_REGISTER_FEE ? 'block' : 'none'
        }}
        className='enquirySlider'
      >
        <button
          type='button'
          className='btn btn-info text-center btnCloseDiv'
          style={{ right: '50%' }}
          onClick={refreshScreen}
        >
          <span
            id='close'
            title='Close Slider'
            className='glyphicon glyphicon-remove'
          ></span>
        </button>
        <RegistrationFeeSlider
          key={'adm' + selectedAdmissionId + '-' + oneTimeHeadId}
          admissionId={selectedAdmissionId}
          setSelectedAdmissionId={setSelectedAdmissionId}
          closeSlider={refreshScreen}
          flgSliderType={flgSliderType}
          openOneTimeHeadPopup={openOneTimeHeadPopup}
          setLstOneTimeHead={setLstOneTimeHead}
          oneTimeHead={oneTimeHead}
          oneTimeHeadId={oneTimeHeadId}
          amount={amount}
        />
      </div>
      <div
        style={{
          width: '50%',
          display:
            flgSliderType === SliderType.SLIDER_ADMISSION_FEE ? 'block' : 'none'
        }}
        className='enquirySlider'
      >
        <button
          type='button'
          className='btn btn-info text-center btnCloseDiv'
          style={{ right: '50%' }}
          onClick={refreshScreen}
        >
          <span
            id='close'
            title='Close Slider'
            className='glyphicon glyphicon-remove'
          ></span>
        </button>
        <AdmissionFeeSlider
          key={selectedAdmissionId}
          enquiryId={selectedAdmissionId}
          setSelectedAdmissionId={setSelectedAdmissionId}
          closeSlider={refreshScreen}
          flgSliderType={flgSliderType}
        />
      </div>

      <div
        style={{
          width: '50%',
          display: flgSliderType === SliderType.SLIDER_CLOSE ? 'block' : 'none'
        }}
        className='enquirySlider'
      >
        <button
          type='button'
          className='btn btn-info text-center btnCloseDiv'
          style={{ right: '50%' }}
          onClick={refreshScreen}
        >
          <span
            id='close'
            title='Close Slider'
            className='glyphicon glyphicon-remove'
          ></span>
        </button>
        <CloseSlider
          key={selectedAdmissionId}
          setSelectedAdmissionId={setSelectedAdmissionId}
          enquiryId={selectedAdmissionId}
          closeSlider={refreshScreen}
          flgSliderType={flgSliderType}
        />
      </div>
      <div
        style={{
          width: '50%',
          display:
            flgSliderType === SliderType.SLIDER_INTERVIEW_RESULT
              ? 'block'
              : 'none'
        }}
        className='enquirySlider'
      >
        <button
          type='button'
          className='btn btn-info text-center btnCloseDiv'
          style={{ right: '50%' }}
          onClick={refreshScreen}
        >
          <span
            id='close'
            title='Close Slider'
            className='glyphicon glyphicon-remove'
          ></span>
        </button>
        <EvaluateInterviewSlider
          key={selectedAdmissionId}
          setSelectedAdmissionId={setSelectedAdmissionId}
          admissionId={selectedAdmissionId}
          flgSliderType={flgSliderType}
          closeSlider={refreshScreen}
        />
      </div>

      <div
        style={{
          width: '85%',
          display:
            flgSliderType === SliderType.SLIDER_SCHEDULE_INTERVIEW
              ? 'block'
              : 'none'
        }}
        className='enquirySlider'
      >
        <button
          type='button'
          className='btn btn-info text-center btnCloseDiv'
          style={{ right: '85%' }}
          onClick={refreshScreen}
        >
          <span
            id='close'
            title='Close Slider'
            className='glyphicon glyphicon-remove'
          ></span>
        </button>
        <ScheduleInterviewSlider
          key={lstSelAdmissionId}
          selectedLstAdmissionId={lstSelAdmissionId}
          closeSlider={refreshScreen}
          flgSliderType={flgSliderType}
        />
      </div>
      <div className='popup'>
        {popupId === SliderType.POPUP_REGISTER_FEE && (
          <OneHeadListPopup
            key={'padm' + selectedAdmissionId}
            admissionId={selectedAdmissionId}
            oneTimeHeadId={oneTimeHeadId}
            closePopup={closePopup}
            setOneTimeHeadId={setOneTimeHeadId}
            setOneTimeHead={setOneTimeHead}
            setAmount={setAmount}
          />
        )}
      </div>
    </div>
  )
}

export default AdmissionCtrl
