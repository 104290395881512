import React, { useState, useEffect } from "react";
import lstModules from "common/json/lstModules.json";
import axios from "axios";
import "common/css/master.css";
import "common/css/tabs.css";
import Header from "common/js/header/header.js";
import { toast, ToastContainer } from "react-toastify";
import { getGroupListSvc } from "./ListingFacilitySvc.js";
import FacilityTable from "./FacilityTable.js";
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules["enquiry"].url + ":" + lstModules["enquiry"].port;
var httpURLCommon = lstModules["main"].url + ":" + lstModules["main"].port;
var httpSchoolURL =
  lstModules["schools"].url + ":" + lstModules["schools"].port;

const ListingFacility = () => {
  const [lstGroup, setLstGroup] = useState([]);

  useEffect(() => {
    getGroupListSvc(cbGroupListResponse);
  }, []);

  function cbGroupListResponse(data) {
    setLstGroup(data.lstGroup);
  }

  function getFacList(pId) {
    // console.log(pId);
  }
  return (
    <div style={{ height: "100%" }}>
      <Header />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: "none",
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <div className="MainContainer">
        <div className="col-md-12 col-centered wingsWrap clearfix"></div>
        <div className="col-sm-11 col-centered page" style={{ marginTop: 25 }}>
          <div className="pcss3t pcss3t-effect-scale pcss3t-theme-1">
            {lstGroup.map((item, idx) => {
              return (
                <React.Fragment key={"tab" + (idx + 1)}>
                  <input
                    type="radio"
                    name="pcss3t"
                    defaultChecked={true}
                    id={`tab${idx + 1}`}
                    className={
                      idx === 0 ? `tab-content-first` : `tab-content-${idx + 1}`
                    }
                    onClick={() => getFacList(item.grpId)}
                  />
                  <label
                    style={{ padding: "0 17px" }}
                    htmlFor={`tab${idx + 1}`}
                    title="Banner"
                    onClick={() => getFacList(item.grpId)}
                  >
                    {item.grp}
                  </label>
                </React.Fragment>
              );
            })}
            <ul>
              {lstGroup.map((item, idx) => {
                return (
                  <li
                    key={"l" + (idx + 1)}
                    className={
                      idx === 0
                        ? `tab-content tab-content-first typography`
                        : `tab-content tab-content-${idx + 1} typography`
                    }
                  >
                    <div className="col-sm-12">
                      <form
                        className="form form-horizontal"
                        style={{
                          width: "100%",
                          margin: "10px auto",
                          padding: 10,
                          background: "#fff",
                          border: "1px solid #ccc",
                          borderRadius: 10,
                          //   pointerEvents: state.loader === 'none' ? 'auto' : 'none'
                        }}
                      >
                        <div className="row" style={{ padding: 10 }}>
                          <div className="col-sm-6">
                            <div className="form-group col-md-12">
                              <FacilityTable groupId={item.grpId} />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingFacility;
