import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { getStudentDtlsSvc } from "./pDashboardSvc";

const StudentDtlsSlider = (props) => {
    const [loader, setLoader] = useState('none')
    const [lstStudentDtls, setLstStudentDtls] = useState(props.lstStudentDtls)
    useEffect(() => {
        getStudentDtls()
    }, [props])
    function getStudentDtls() {
        getStudentDtlsSvc(cbStudentDtlsResponse)
    }
    function cbStudentDtlsResponse(data) {
        if (data.SvcStatus === "Success") {
            // console.log(data)
            setLstStudentDtls(data.lstStudentDtls)
        } else {
            toast.error(data.SvcMsg)
        }
    }

    const colgrp = <colgroup>
        <col width="6.667%"></col>
        <col width="6.667%"></col>
        <col width="6.667%"></col>
        <col width="6.667%"></col>
        <col width="6.667%"></col>
        <col width="6.667%"></col>
        <col width="6.667%"></col>
        <col width="6.667%"></col>
        <col width="6.667%"></col>
        <col width="6.667%"></col>
        <col width="6.667%"></col>
        <col width="6.667%"></col>
    </colgroup>

    return (
        <div style={{ width: '100%', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10, pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div className="col-sm-12 col-centered" style={{ border: '1px solid rgb(170, 170, 170)', borderRadius: '10px 10px 5px 5px', fontWeight: 'bold' }}><h4>Students Attendance</h4></div>
            {lstStudentDtls && lstStudentDtls.length > 0 && <div className="col-sm-12" style={{ height: 'auto', marginTop: '1em' }}>
                <div className="col-sm-12">
                    <table className='tableLayout1'>
                        {colgrp}
                        <thead>
                            <tr>
                                {lstStudentDtls.map((obj, idx) => {
                                    return <th key={idx}>{obj.cls}</th>
                                })}
                            </tr>
                        </thead>
                    </table>
                    <div className='tablebodyWrapper'>
                        <div className='tableLayout' style={{ display: 'table' }}>
                            <div className="col-sm-12 padding-remove">

                                {lstStudentDtls.map((obj, idx) => {
                                    return <div key={idx} style={{ float: 'left', width: "6.667%", padding: '5px' }}>
                                        {/* {obj.lstSection.length === 0 &&
                                            <div key={0} className="text-center" style={{ width: 50, height: 50, margin: '0 auto', marginBottom: 5 }}>
                                                <div className="col-sm-12 col-centered subsec-circle1">{0}</div>
                                            </div>} */}
                                        {obj.lstSection.map((secObj, subIdx) => {
                                            return <div key={subIdx} className="text-center" style={{ width: 50, height: 50, margin: '0 auto', marginBottom: 5 }}>
                                                <div className="col-sm-12 col-centered subsec-circle1">{secObj.presentPct}</div>
                                            </div>
                                        })}
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default StudentDtlsSlider