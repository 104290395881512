import React, { useEffect, useState } from 'react';
import 'sms/tt/subject/css/tabList.css'
import 'common/css/master.css'

const SectionList = (props) => {
    const [lstSection, setLstSection] = useState(props.lstSection)
    useEffect(() => {
        // console.log("props.selSectionId sectionList",props.selSectionId);
        setLstSection(props.lstSection);
        // console.log("useEffect for section")
    }, [props.classId, props.sectionId, props.lstSection]);

    return (
        <div className="tab" style={{ marginLeft: 4 }}>

            {lstSection.map((obj) => {
                // console.log("obj.sectionId in lstsession.map in sectionList)",obj.sectionId, "props.selSectionId ",props.selSectionId);
                var isSelected = obj.sectionId == props.selSectionId;
                return <div className={isSelected ? "sellected" : ""} onClick={(e) => props.sectionClick(obj.sectionId)} key={obj.sectionId} style={{ height: 35 }}>
                    <span hidden>{obj.sectionId}</span>
                    <span>{obj.section}</span>
                    {/* <br></br>
                    <span>{obj.pct + '%'}</span> */}
                </div>
            })}
        </div>
    )

}
export default SectionList;