import React, { useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from 'react-toastify';

import "../css/examSyllabus.css";
import { getSyllabusListSvc } from "./examSyllabusSvc";


const SyllabusClasses = (props) => {

    const { syllabusId, sectionId, handleSyllabusChange, handleClassChange, setLoader, setOpacity } = props;

    const scrollContainerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [lstSyllabus, setLstSyllabus] = useState([]);
    const [lstClsSection, setLstClsSection] = useState([]);

    useEffect(() => {
        // console.log("SyllabusClasses called");
        getSyllabusList();
    }, []);

    const getSyllabusList = () => {
        setLoader((prevLoader) => prevLoader + 1);
        setOpacity(0.5);
        getSyllabusListSvc(syllabusListReponse);
    }



    const syllabusListReponse = (data) => {
        // console.log("syllabusListReponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            setLstSyllabus(obj.lstSyllabus);
            if (obj.lstSyllabus && obj.lstSyllabus.length) {
                handleSyllabusChange(obj.lstSyllabus[0].syllabusId);
                setLstClsSection(obj.lstSyllabus[0].lstSection);
                if (obj.lstSyllabus[0].lstSection && obj.lstSyllabus[0].lstSection.length) {
                    handleClassChange(obj.lstSyllabus[0].lstSection[0].sectionId);
                }
            }
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader((prevLoader) => prevLoader - 1);
        setOpacity(1);
    }

    const updateSyllabusId = (pSybId) => {
        // console.log("updateSyllabusId, pSybId", pSybId);
        handleSyllabusChange(pSybId);
        handleClassChange(0);
        setClassSectionList(pSybId);
    }

    const setClassSectionList = (pSybId) => {
        // console.log("setClassSectionList called, pSybId", pSybId);
        // console.log("lstSyllabus", lstSyllabus);
        if (lstSyllabus && lstSyllabus.length) {
            // const selectedSyllabus = lstSyllabus.find(syllabus => syllabus.syllabusId === pSybId)
            let selectedSyllabus = [];
            for (let i = 0; i < lstSyllabus.length; i++) {
                // console.log("lstSyllabus[i]", lstSyllabus[i]);
                if (lstSyllabus[i].syllabusId == pSybId) {
                    // console.log("lstSyllabus[i].syllabusId", lstSyllabus[i].syllabusId);
                    // console.log("lstSyllabus[i].lstSection", lstSyllabus[i].lstSection);
                    selectedSyllabus = lstSyllabus[i];
                    break;
                }
            }
            if (!!selectedSyllabus) {
                // console.log("selectedSyllabus", selectedSyllabus);
                setLstClsSection(selectedSyllabus.lstSection);
                if (selectedSyllabus.lstSection && selectedSyllabus.lstSection.length) {
                    handleClassChange(selectedSyllabus.lstSection[0].sectionId);
                }
            } else {
                // console.log(`Syllabus with id ${pSybId} not found`);
                setLstClsSection([]);
            }
        } else {
            setLstClsSection([]);
        }
    }

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: -150, behavior: 'smooth' });
            setScrollPosition(scrollContainerRef.current.scrollLeft - 150);
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: 150, behavior: 'smooth' });
            setScrollPosition(scrollContainerRef.current.scrollLeft + 150);
        }
    };



    return (
        <div className="col-sm-12 padding-remove">
            <div className="col-xs-3 ">
                <label
                    className="col-sm-3 text-left padding-remove"
                    style={{ top: "5px" }}
                >
                    Syllabus
                </label>
                <div className="form-group col-sm-9 padding-remove">
                    <select
                        className="form-control"
                        value={syllabusId}
                        onChange={(e) => updateSyllabusId(e.target.value)}
                    >
                        <option value={0}>Syllabus</option>
                        {lstSyllabus && lstSyllabus ?
                            lstSyllabus.map((syllabus) => (
                                <option
                                    key={syllabus.syllabusId}
                                    value={syllabus.syllabusId}>
                                    {syllabus.syllabus}
                                </option>
                            ))
                            : null
                        }
                    </select>
                </div>
            </div>
            <div className="col-xs-9 ">
                {/* <div className="col-sm-12 padding-remove " style={{ margin: "10px 0 0 0", border: "none", overflowX:"scroll" }}>
                            {lstClsSection && lstClsSection ?
                                lstClsSection.map((clsSec) => {
                                    return (
                                        <div
                                            style={{ width:"150px", height:"30px",  border: "1px solid #ccc", borderRadius:"25px", margin:"0 2px" }}
                                            className={(clsSec.sectionId === sectionId) ? "selected col-sm-2 padding-remove" : "col-sm-2 padding-remove"}
                                            onClick={(e) => handleClassChange(clsSec.sectionId)}
                                            key={clsSec.sectionId}>
                                            <div style={{padding:"3px 10px", justifyContent:"center", whiteSpace: "nowrap",  overflow: "hidden"}}>{clsSec.clsSection}</div>
                                        </div>
                                    )
                                }) : null
                            }
                        </div> */}
                <div className="scroll-container" style={{ margin: "5px 0 0 0", border: "none" }}>
                    {lstClsSection && lstClsSection.length > 0 && (
                        <div style={{ display: "flex", flexDirection: "row", }}>
                            {/* <div style={{ width: "20px" }} >
                                        <button className="scroll-button left" onClick={scrollLeft}>&lt;</button>
                                    </div> */}
                            <div className="scroll-content" ref={scrollContainerRef}>
                                {lstClsSection.map((clsSec) => (
                                    <div
                                        style={{ width: "150px", height: "30px", border: "1px solid #ccc", borderRadius: "25px", margin: "0 2px" }}
                                        className={(clsSec.sectionId === sectionId) ? "selected col-sm-2 padding-remove" : "col-sm-2 padding-remove"}
                                        onClick={() => handleClassChange(clsSec.sectionId)}
                                        key={clsSec.sectionId}
                                    >
                                        <div style={{ padding: "3px 10px", justifyContent: "center", whiteSpace: "nowrap", overflow: "hidden" }}>
                                            {clsSec.cls + " - " + clsSec.section}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* <div style={{ width: "20px" }} >
                                        <button className="scroll-button right" onClick={scrollRight}>&gt;</button>
                                    </div> */}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SyllabusClasses;