import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import ExamDateHeader from './examDateHeader';
import Header from 'common/js/header/header';

import AdmitCardPdfSlider from './admitCardPdfSlider';
import { getClassData } from './admitCardSvc';




const AdmitCardCtrl = () => {
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);

    const [examId, setExamId] = useState(0);
    const [exam, setExam] = useState("");
    const [sectionId, setSectionId] = useState(0);
    const [clsSection, setClsSection] = useState("");
    const [lstAllClassSections, setLstAllClassSection] = useState([]);
    const [lstAllClassSectionsFiltered, setLstAllClassSectionsFiltered] = useState([]);

    const [classFilter, setClassFilter] = useState("");
    const [sectionFilter, setSectionFilter] = useState("");

    const [flgPdfSliderVisible, setPdfSliderVisible] = useState(false);

    useEffect(() => {
        // console.log("AdmitCardCtrl called ");
        setClassList();
    }, []);


    function setClassList() {
        showLoader()
        getClassData(handleClassListResponse);
    }


    function handleClassListResponse(data) {
        var obj = data
        hideLoader()
        // console.log('lstAllClassSection =' + obj.lstAllClassSections)
        if (obj.status === 'Success') {
            setLstAllClassSection(obj.lstAllClassSections);
            setLstAllClassSectionsFiltered(obj.lstAllClassSections);
            setClassFilter("");
            setSectionFilter("");
        } else if (obj.status === 'Failure') {
            handleServiceError(obj.message);
        } else {
            toast.error('Service failed ' + obj)
        }
    }


    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    const filterCls = e => {
        setClassFilter(e.target.value);
        filterTable(e.target.value, sectionFilter);
    }
    const filterSec = e => {
        setSectionFilter(e.target.value);
        filterTable(classFilter, e.target.value);
    }

    const filterTable = (fClass, fSection) => {
        // console.log("lstAllClassSections", lstAllClassSections);
        let filterClassSecList = [];
        filterClassSecList = lstAllClassSections.filter(clsSec =>
            clsSec.className.toString().toLowerCase().includes(fClass.toLowerCase()));

        filterClassSecList = filterClassSecList.filter(clsSec =>
            clsSec.section.toString().toLowerCase().includes(fSection.toLowerCase()));

        setLstAllClassSectionsFiltered(filterClassSecList);
    }


    const handleExamChange = (pExam) => {
        // console.log("handleExamChange called", pExam);
        setExamId(pExam.examId);
        setExam(pExam.exam);
        setClassList();
    }

    const closePdfSlider = () => {
        // console.log("closePdfSlider called");
        setPdfSliderVisible(false);
    }

    function hideLoader() {
        setLoader("none");
        setOpacity(1);
    }
    function showLoader() {
        setLoader("block");
        setOpacity(0.5);
    }

    const printAdmitCard = async (obj) => {
        // console.log("printAdmitCard called, obj", obj);
        await setSectionId(obj.classSectionId);
        await setClsSection(obj.className + " - " + obj.section);
        setPdfSliderVisible(true);
    }



    return (
        <div >
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="col-sm-10 col-centered padding-remove" style={{ marginTop: 15 + "px" }}>
            <div className="col-sm-12 col-centered padding-remove">
                        <ExamDateHeader
                            examClick={handleExamChange}
                            examId={examId}
                        />
                    </div>
                    </div>
            <div className="col-md-12 col-xs-12 col-centered padding-remove " >
                <div className="col-sm-10 col-centered padding-remove" style={{ marginTop: 10 + "px" }}>
                    <table className='tableLayout1' >
                        <thead>
                            <tr>
                                <th style={{ width: 40 + '%' }}>
                                    <input
                                        type='text'
                                        value={classFilter}
                                        onChange={filterCls}
                                        className='form-control inp-search'
                                        placeholder='Class'
                                    />
                                </th>
                                <th style={{ width: 30 + '%' }}>
                                    <input
                                        type='text'
                                        value={sectionFilter}
                                        onChange={filterSec}
                                        className='form-control inp-search'
                                        placeholder='Section'
                                    />
                                </th>
                                <th style={{ width: 70 + '%' }}>Action</th>
                            </tr>
                        </thead>
                    </table>

                    <div className='tableDiv' style={{ height: 500 + 'px' }}>
                        <table className='tableLayout'>
                            <tbody>
                                {lstAllClassSectionsFiltered && lstAllClassSectionsFiltered.map(obj => (
                                    <tr
                                        className={
                                            obj.classSectionId === parseInt(sectionId)
                                                ? 'selected'
                                                : ''
                                        }
                                        key={obj.classSectionId}
                                        id={obj.classSectionId}
                                    >
                                        <td hidden>{obj.classId}</td>
                                        <td style={{ width: 40 + '%' }} className='text-left'>
                                            {obj.className}
                                        </td>
                                        <td hidden>{obj.classSectionId}</td>
                                        <td style={{ width: 30 + '%' }} className='text-left'>
                                            {obj.section}
                                        </td>
                                        <td
                                            style={{ width: 70 + '%' }}
                                            className='text-center'
                                        // data={JSON.stringify(obj)}
                                        >
                                            <span className='fa fa-eye' title="View"></span>
                                            <span
                                                className='glyphicon glyphicon-share'
                                                title="Generate"
                                                // data={JSON.stringify(obj)}
                                                onClick={() =>
                                                    printAdmitCard(obj)
                                                }
                                            ></span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
            {flgPdfSliderVisible && (
                <div className="slider1-80">
                    <AdmitCardPdfSlider
                        sectionId={sectionId}
                        clsSection={clsSection}
                        exam={exam}
                        closePdfSlider={closePdfSlider}
                    />
                </div>
            )}
        </div >
    )
}

export default AdmitCardCtrl;