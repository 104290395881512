import React, { useState, useEffect } from "react";
import Header from "common/js/header/header";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AcceptPaymentTable from "./acceptPaymentTable";

const AcceptPayment = () => {

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);

    useEffect (() => {
        // console.log("useEffect called in acceptPayment");
    },[]);

    return (
        <div>
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>

            <div className="mainContainer" style={{pointerEvents: loader === "none" ? "auto" : "none", opacity: opacity }}>
                <div className="col-xs-11 col-centered padding-remove">
                    <div className="col-xs-12 padding-remove">
                        <AcceptPaymentTable
                            setLoader={setLoader}
                            setOpacity={setOpacity}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AcceptPayment;