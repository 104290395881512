import axios from 'axios';

import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['main'].url + ':' + lstModules['main'].port;
var tokenid = localStorage.getItem("tokenid");
var queryString = require("querystring");
const schoolId = localStorage.getItem("schoolId");


export const getDiaryDailyService = (monthId, sectionId, callback) => {
    // console.log("getDiaryDailyService called");
    var month = { status: "Success", message: "" }
    const formData = new FormData();
    formData.append("calMthId", monthId);
    formData.append("sectionId", sectionId);
    axios({
        url: httpURL + '/sms/diary/report/daily_summary',
        method: "POST",
        headers: { 'tokenid': tokenid },
        data: formData,
        withCredentials: true,
    }).then((response) => {
        // console.log('data.SvcStatus=' + response.data.SvcStatus);
        month.status = response.data.SvcStatus;
        month.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            month.lstDailySummary = response.data.lstDailySummary;
        }
        callback(month);
    }).catch((error) => {
        month.status = "Exception";
        month.message = error;
        month.lstIdCard = [];
        callback(month);
    });
}


export const getClassListSvc = (cbClassList) => {
    axios({
        url: httpURL + "/sms/cls/list",
        method: "POST",
        headers: { tokenid: tokenid },
        withCredentials: true,
    })
        .then((response) => {
            cbClassList(response.data);
        })
        .catch((error) => {
            cbClassList({ status: "Exception", message: error });
        });
}

export const getSectionListSvc = (clsId, callback) => {
    const responseObj = { status: "", message: "", error: "" };
    axios({
        url: `${httpURL}/sms/cls/sec/list`,
        method: 'POST',
        data: queryString.stringify({ "classId": clsId }),
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // console.log("response in getSEctionListSvc, response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            if (response.data.SvcStatus === "Success") {
                responseObj.lstSection = response.data.lstClassSection;
            }
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};