import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "common/js/header/header.js";
import AreaSummary from "./AreaSummary";
import StatusSummary from "./StatusSummary";
import FeedbackTable from "./FeedbackTable";
import FeedbackSlider from "./FeedbackSlider";
import "common/css/master.css";
import "../css/feedback.css";
import { getFeedbackfromBE } from "./FeedbackSliderSvc";

const FeedbackCtrl = () => {
  const [randNo, setRandNo] = useState(0);
  const [loader, setLoader] = useState("none");
  const [sliderDisplayed, setSliderDisplayed] = useState(false);
  const [selectedCaseNo, setSelectedCaseNo] = useState(0);
  const [lstAreaSummary, setLstAreaSummary] = useState([
/*     { areaId: 1, area: "Accounts", count: 19 },
    { areaId: 2, area: "Teacher", count: 29 },
    { areaId: 3, area: "Admin", count: 39 },
    { areaId: 4, area: "Transport", count: 49 },
    { areaId: 5, area: "Hygiene", count: 59 },
    { areaId: 6, area: "Others", count: 69 },
 */  ]);
  const [selLstAreaId, setSelLstAreaId] = useState([])
  const [selLstStatusId, setSelLstStatusId] = useState([])

  const [lstFeedback, setLstFeedback] = useState([
    /*     {
          lastActionedDtTm: "11/04/2022",
          createdDtTm: "04/10/2021",
          caseNo: 1,
          priority: "High",
          type: "Suggestion",
          area: "Accounts",
          areaId: 70,
          tch: "Ingelbert Farlham",
          name: "Dorene Vaisey",
          email: "dvaisey0@wufoo.com",
          phone: "274-441-6348",
          cls: "X",
          sec: "D",
          status: "In-Process",
          statusId: 10,
        },
        {
          lastActionedDtTm: "19/05/2022",
          createdDtTm: "04/10/2021",
          caseNo: 2,
          priority: "-",
          type: "Feedback",
          area: "Teacher",
          areaId: 10,
          tch: "Cathie Skouling",
          name: "Maryl Bourhill",
          email: "mbourhill1@unicef.org",
          phone: "359-305-5213",
          cls: "VIII",
          sec: "C",
          status: "New",
          statusId: 30,
        },
        {
          lastActionedDtTm: "15/02/2022",
          createdDtTm: "14/12/2021",
          caseNo: 3,
          priority: "-",
          type: "Suggestion",
          area: "Others",
          areaId: 30,
          tch: "Danita Lamblot",
          name: "Kyle Dybell",
          email: "kdybell2@mit.edu",
          phone: "162-367-0609",
          cls: "III",
          sec: "B",
          status: "Resolved",
          statusId: 10,
        },
        {
          lastActionedDtTm: "30/11/2021",
          createdDtTm: "26/02/2022",
          caseNo: 4,
          priority: "High",
          type: "Suggestion",
          area: "Transport",
          areaId: 70,
          tch: "Loy Hames",
          name: "Alleen Downgate",
          email: "adowngate3@aol.com",
          phone: "877-626-8904",
          cls: "VI",
          sec: "A",
          status: "In-Process",
          statusId: 20,
        },
        {
          lastActionedDtTm: "27/11/2021",
          createdDtTm: "07/02/2022",
          caseNo: 5,
          priority: "High",
          type: "Complaint",
          area: "Transport",
          areaId: 10,
          tch: "Etan Tuhy",
          name: "Natividad Bisterfeld",
          email: "nbisterfeld4@noaa.gov",
          phone: "927-657-8579",
          cls: "I",
          sec: "D",
          status: "Resolved",
          statusId: 20,
        }, */
  ]);
  function generateRandNo() {
    var lRandNo = Math.floor(Math.random() * 999999);
    setRandNo(lRandNo);
  }
  function openSlider(caseNo) {
    setSliderDisplayed(true);
    setSelectedCaseNo(caseNo);
  }
  function refreshScreen(pCaseNo) {
    setSelectedCaseNo(pCaseNo);
    setSliderDisplayed(false);
    generateRandNo();
  }

  function clickArea(pSelLstAreaId) {
    // console.log(pSelLstAreaId)
    setSelLstAreaId(pSelLstAreaId)
    generateRandNo()
  }
  function clickStatus(pSelLstStatusId) {
    setSelLstStatusId(pSelLstStatusId)
    generateRandNo()
  }
  const getWingId = (wingId) => {
    // console.log('wingId = ' + wingId)
  }
  return (
    <div className="MainContainer" style={{ pointerEvents: loader === "none" ? "auto" : "none", }}>
      <div style={{
        position: "fixed",
        zIndex: 10000,
        width: 100,
        height: 100,
        marginLeft: -50,
        marginTop: -50,
        left: "50%",
        top: "50%",
        display: loader,
      }}>
        <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
      </div>

      <Header wingFromChild={getWingId} />

      <ToastContainer autoClose={3000} hideProgressBar />
      <div className="col-sm-12">
        <div className="col-sm-12 padding-remove">
          <div className="row head">
            <div className="container-fluid" style={{ height: "20%", boxShadow: "rgb(204 204 204) 8px 2px 4px 0px", borderWidth: "1px 0px", borderStyle: "solid", borderColor: "rgb(204, 204, 204)", padding: "5px 20px", border: "none", pointerEvents: loader === "none" ? "auto" : "none" }}>
              <div className="col-sm-5 div_summary">
                <AreaSummary clickArea={clickArea} selLstAreaId={selLstAreaId} />
              </div>
              <div className="col-sm-3 div_summary" style={{ marginLeft: '1%', width: '20%' }}>
                <StatusSummary clickStatus={clickStatus} selLstStatusId={selLstStatusId} />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-centered" style={{ marginTop: '1%' }}>
            <div className="col-sm-12 col-centered padding-remove" >
              <FeedbackTable
                randNo={randNo}
                onRowClick={openSlider}
                key={randNo}
                caseNo={selectedCaseNo}
                selLstAreaId={selLstAreaId}
                selLstStatusId={selLstStatusId} />
            </div>
          </div>
        </div>

        <div style={{ display: sliderDisplayed === true ? "block" : "none" }} className="feedbackSlider">
          <button type="button" className="btn btn-info text-center btnCloseDiv" onClick={() => refreshScreen(selectedCaseNo)}>
            <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
          </button>
          <FeedbackSlider
            key={randNo}
            caseNo={selectedCaseNo}
            closeSlider={refreshScreen}
            generateRandNo={generateRandNo}
            sliderDisplayed={sliderDisplayed} />
        </div>
      </div>
    </div>
  );
};

export default FeedbackCtrl;