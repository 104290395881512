import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "common/js/header/header.js";
import Graph from "./Graph"
import GraphCls from "./GraphCls"
import GraphLabel from "./GraphLabel"
import MonthWise from './MonthWise'
import ClassWise from './ClassWise'
import LabelWise from './LabelWise'
import ReactApexChart from 'react-apexcharts';
import './css/AnnualCollection.css'
import { getLstClassCollectionSvc, getLstMonthCollectionSvc, getLstLabelCollectionSvc, getLstMonthTblSvc, getLstClassTblSvc } from "./AnnualCollectionSvc"

function AnnualCollection() {
    const [lstClassCollection, setLstClassCollection] = useState([])
    const [lstMonthCollection, setLstMonthCollection] = useState([])
    const [lstLabelCollection, setLstLabelCollection] = useState([])
    const [lstMonthTbl, setLstMonthTbl] = useState([])
    const [lstClassTbl, setLstClassTbl] = useState([])
    const [tableType, setTableType] = useState(1)
    const [randNo, setRandNo] = useState(0)
    const [label, setLabel] = useState(['Month']);
    const [labelCls, setLabelCls] = useState(['Class'])
    const month = []
    const cls = []
    const labelGraph = []
    const mCollection = []
    const cCollection = []
    const lCollection = []
    const [loader, setLoader] = useState('none')
    useEffect(() => {
        //   setLoader('block')
        getLstClassCollectionSvc(cbLstClassCollection)
        getLstClassTblSvc(cbGetLstClass)
        getLstLabelCollectionSvc(cbLstLabelCollection)
        getLstMonthCollectionSvc(cbLstMonthCollection)
        getLstMonthTblSvc(cbGetLstMonth)

    }, [])
    function cbLstClassCollection(data) {
        if (data.SvcStatus === "Success") {
            // console.log("cbLstClassCollection::" + JSON.stringify(data))
            setLstClassCollection(data.lstClassCollection)
            for (var i = 0; i < data.lstClassCollection.length; i++) {
                cls.push(data.lstClassCollection[i].cls)
                cCollection.push(data.lstClassCollection[i].collection)

            }
            generateRandNo()
            window.dispatchEvent(new Event('resize'))
        }
        else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')


    }
    function cbGetLstClass(data) {
        if (data.SvcStatus === "Success") {
            setLstClassTbl(data.lstClassWiseHeadCollection)
            addTotalInClassCollection(data.lstClassWiseHeadCollection)
            getLabelCls(data.lstClassWiseHeadCollection)
            generateRandNo()
        }
        else {
            toast.error(data.SvcMsg)
        }
    }
    function addTotalInClassCollection(pLstTemp) {
        var tmpTotal = [];
        for (var row = 0; row < pLstTemp[0].lstLabelAmt.length; row++) {
            var total = 0;

            for (var col = 0; col < pLstTemp.length; col++) {
                var lblAmtObj = pLstTemp[col].lstLabelAmt[row];
                total += lblAmtObj.amt;
                // console.log('label=' + row + ', cls=' + col + ',amt=' + lblAmtObj.amt);
            }
            var obj = { labelId: lblAmtObj['labelId'], label: lblAmtObj['label'], amt: total }
            // console.log("lstLabelAmt:" + JSON.stringify(obj))
            tmpTotal.push(obj);


        }

        // console.log("tmpTotal= " + JSON.stringify(tmpTotal))
        pLstTemp.unshift({ clsId: 13, cls: 'Total', lstLabelAmt: tmpTotal })
        setLstClassTbl(pLstTemp);
        // console.log("pLstTemp::"+JSON.stringify(pLstTemp))
    }
    function getLabelCls(lstClassCollection) {
        // console.log("Data::" + JSON.stringify(lstClassCollection))
        var tempLabel2 = labelCls
        var lstLabel2 = lstClassCollection[0].lstLabelAmt;
        for (var i = 0; i < lstLabel2.length; i++) {
            tempLabel2.push(lstLabel2[i].label)
        }
        tempLabel2.push('Total')
        setLabelCls(tempLabel2)
        // toast.success("Success")
    }
    function cbLstLabelCollection(data) {
        if (data.SvcStatus === "Success") {
            setLstLabelCollection(data.lstLabelCollection)

            for (var i = 0; i < data.lstLabelCollection.length; i++) {
                labelGraph.push(data.lstLabelCollection[i].label)
                lCollection.push(data.lstLabelCollection[i].amt)
            }
            generateRandNo()
            window.dispatchEvent(new Event('resize'))
            addTotalInLabelCollection(data.lstLabelCollection)
        }
        else {
            toast.error(data.SvcMsg)
        }

        setLoader('none')

    }
    function addTotalInLabelCollection(pLstTemp) {

        var temp = []
        var total = 0
        var tmpLbl = 0
        for (var i = 0; i < pLstTemp.length; i++) {
            total = total + pLstTemp[i].amt
            tmpLbl++
        }
        var obj = { "labelId": tmpLbl, "label": "Total", "amt": total }
        temp = pLstTemp
        temp.push(obj);
        setLstLabelCollection(temp)

    }

    function cbLstMonthCollection(data) {
        if (data.SvcStatus === "Success") {
            setLstMonthCollection(data.lstMonthCollection)
            for (var i = 0; i < data.lstMonthCollection.length; i++) {

                month.push(data.lstMonthCollection[i].month)
                mCollection.push(data.lstMonthCollection[i].collection)

            }
            generateRandNo()
            window.dispatchEvent(new Event('resize'))
        }
        else {
            toast.error("While loading month collection::" + data.SvcMsg)
        }
        setLoader('none')

    }

    function cbGetLstMonth(data) {
        if (data.SvcStatus === 'Success') {

            // setLstMonthTbl(data.lstMonthCollection)
            getLabel(data.lstMonthCollection)
            addTotalInMonthCollection(data.lstMonthCollection)

            generateRandNo()
        }
        else {
            toast.error(data.SvcMsg)
        }

    }
    function addTotalInMonthCollection(pLstTemp) {

        var tmpTotal = [];
        for (var row = 0; row < pLstTemp[0].lstLabelAmt.length; row++) {
            var total = 0;

            for (var col = 0; col < pLstTemp.length; col++) {
                var lblAmtObj = pLstTemp[col].lstLabelAmt[row];
                total += lblAmtObj.amt;
                // console.log('label=' + row + ', month=' + col + ',amt=' + lblAmtObj.amt);
            }
            var obj = { labelId: lblAmtObj['labelId'], label: lblAmtObj['label'], amt: total }
            // console.log("lstLabelAmt:" + JSON.stringify(obj))
            tmpTotal.push(obj);


        }

        // console.log("tmpTotal= " + JSON.stringify(tmpTotal))
        pLstTemp.unshift({ monthId: 13, month: 'Total', lstLabelAmt: tmpTotal })
        setLstMonthTbl(pLstTemp);
    }
    function getLabel(lstMonthCollection) {
        var tempLabel = label
        var lstLabel = lstMonthCollection[0].lstLabelAmt;
        for (var i = 0; i < lstLabel.length; i++) {
            tempLabel.push(lstLabel[i].label)
        }
        tempLabel.push('Total')
        setLabel(tempLabel)
        // toast.success("Success")
    }

    function handleTableType(param) {
        setTableType(param)
    }

    function generateRandNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        setRandNo(lRandNo)
    }
    const getWingId = (wingId) => {
        // console.log('wingId = ' + wingId)
    }
    return (
        <div className='MainContainer'
            style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="mainWrapper">
                <div className="col-sm-12 graphDiv">
                    <div className={tableType === 1 ? "selected col-sm-4 graphMain" : "col-sm-4 graphMain"} >
                        <div onClick={(e) => handleTableType(1)} >

                            <Graph
                                //key={randNo}
                                month={month}
                                mCollection={mCollection}
                            />
                        </div>
                    </div>
                    <div className={tableType === 2 ? "selected col-sm-4 graphMain" : "col-sm-4 graphMain"} >
                        <div onClick={(e) => handleTableType(2)} >
                            <GraphCls
                                // key={randNo}
                                cls={cls}
                                cCollection={cCollection}
                            />
                        </div>
                    </div>
                    <div className={tableType === 3 ? "selected col-sm-4 graphMain" : "col-sm-4 graphMain"} >
                        <div onClick={(e) => handleTableType(3)} >
                            <GraphLabel
                                // key={randNo}
                                labelGraph={labelGraph}
                                lCollection={lCollection}
                            />
                        </div>
                    </div>

                </div>
                <div className="col-sm-12">
                    <div className="col-sm-10 col-centered tableMain" style={{ display: tableType === 1 ? 'block' : 'none', paddingTop: '1%' }}>
                        <MonthWise
                            key={randNo}
                            label={label}
                            lstMonthTbl={lstMonthTbl}
                        />
                    </div>
                    <div className="col-sm-10 col-centered tableMain" style={{ display: tableType === 2 ? 'block' : 'none', paddingTop: '1%' }}>
                        <ClassWise
                            key={randNo}
                            labelCls={labelCls}
                            lstClassTbl={lstClassTbl}
                        />
                    </div>
                    <div className="col-sm-10 col-centered tableMain" style={{ display: tableType === 3 ? 'block' : 'none', paddingTop: '1%' }}>
                        <LabelWise
                            lstLabelCollection={lstLabelCollection}
                        />
                    </div>

                </div>
            </div>
        </div>



    )
}
export default AnnualCollection;