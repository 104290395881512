import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';

// project related common imports

import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import ExamHeader from './examDateHeader.js';
import AllocationTable from './AllocationTable.js';
import AllocateSlider from './AllocateSlider.js';
import 'sms/tt/header/css/SectionList.css'

const AllocateCtrl = () => {
    const [tableLoader, setTableLoader] = useState('none')
    const [sliderLoader, setSliderLoader] = useState('none')
    const [flgSlider, setFlgSlider] = useState(false)
    const [examId, setExamId] = useState(0);
    const [allocationId, setAllocationId] = useState(0);
    const [subject, setSubject] = useState('')
    const [exam, setExam] = useState();
    const [clsId, setClsId] = useState('')
    const [cls, setCls] = useState()
    const [section, setSection] = useState('')
    const [subId, setSubId] = useState('')
    const [allocatedToId, setallocatedToId] = useState('')
    const [expHrs, setExpHrs] = useState('')
    const [sectionId, setSectionId] = useState('')
    const [randNo, setRandNo] = useState(0);
    const [selIdx, setSelIdx] = useState(-1);
    const [srandNo, setSrandNo] = useState(0);


    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console.log(lRandNo)
        setRandNo(lRandNo)
    }
    function generateSRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // console.log(lRandNo)
        setSrandNo(lRandNo)
    }
    async function setSlider(obj, idx, flgOpenSlider) {
        setAllocationId(obj.allocationId)
        setSubId(obj.subjectId)
        setSubject(obj.subject)
        setSection(obj.section)
        setSectionId(obj.sectionId)
        setClsId(obj.clsId)
        setCls(obj.cls)
        setSelIdx(idx)
        // console.log(flgOpenSlider)
        await setFlgSlider(flgOpenSlider)
        // console.log(obj.examId)
    }

    function examClick(pExamId, pExam) {
        setExamId(pExamId);
        setExam(pExam);
        generateRandomNo()
    }
    function closeSlider() {
        generateSRandomNo()
        setFlgSlider(false)

    }
    function openNewSlider() {
        setFlgSlider(true)
        setCls('')
        setSubject('')
        setSection('')
    }
    function openSlider(e) {
        e.stopPropagation();
        setFlgSlider(true)
    }

    const getWingId = (wingId) => {
        // console.log("wingId" + wingId);
    }

    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="MainContainer" >
                <div className="col-md-10 col-centered wingsWrap clearfix" style={{ marginTop: '1%' }}>
                    <ExamHeader examId={examId} examClick={examClick} />
                </div>
                <div className="col-md-11 col-centered clearfix">
                    <div className="col-md-12">
                        <div className="col-md-6  col-xs-6">
                            <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: tableLoader }}>
                                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                            </div>

                            <AllocationTable
                                key={randNo}
                                examId={examId}
                                openSlider={openSlider}
                                openNewSlider={openNewSlider}
                                onSetSlider={setSlider}
                                selIdx={selIdx}
                                generateRandomNo={generateRandomNo} />

                        </div>


                        <div className="slider halfWidthSlider" style={{ right: 0, opacity: 1, display: flgSlider === true ? 'block' : 'none', zIndex: 10000 }}>
                            <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: sliderLoader }}>
                                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                            </div>

                            <div>
                                <button
                                    type="button"
                                    className="btn btn-info text-center btnCloseDiv"
                                    onClick={(e) => closeSlider()}>
                                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                                </button>
                            </div>


                            <AllocateSlider
                                key={srandNo}
                                allocationId={allocationId}
                                subject={subject}
                                subId={subId}
                                examId={examId}
                                clsId={clsId}
                                cls={cls}
                                section={section}
                                sectionId={sectionId}
                                generateRandomNo={generateRandomNo}
                                closeSlider={closeSlider}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AllocateCtrl