import React, { useEffect, useState } from 'react'
import ClassList from './classList'
import SectionList from './sectionList'
import SessionList from './sessionList'

const TTTeacherHead = (props) => {
    const [lstCls, setLstCls] = useState(props.lstCls)

    useEffect(() => {
        // console.log("props.lstSession inttTchHeader",props.lstSession);
        // console.log("props.sessionId inttTchHeader",props.sessionId);
        // console.log("props.selSessionId inttTchHeader",props.selSessionId);
        setLstCls(props.lstCls)
        // console.log("props.lstSession inttTchHeader",props.lstSession);
        // console.log("props.classId in useEffect in ttTchHeader",props.classId);
        // console.log("props.sectionId in useEffect in ttTchHeader",props.sectionId);

    }, [props.lstCls])
    return (
        <div className="row" style={{ boxShadow: '#ccc 0px 1px 4px 0px', borderWidth: '1px 0px', borderStyle: 'solid', height: '50px', borderColor: '#ccc', padding: '5px 20px', height: 'auto' }}>

            <ClassList lstCls={lstCls} classClick={props.changeClass} classId={props.classId}
            //  getClassName={props.getClassName}
             />
            <SectionList lstSection={props.lstSection} sectionClick={props.changeSection} sectionId={props.sectionId} classId={props.classId} selSectionId={props.sectionId}/>
            <SessionList lstSession={props.lstSession} sessionClick={props.changeSession} selSessionId={props.selSessionId} classId={props.classId} sectionId={props.sectionId} sessionId={props.sessionId}/>

        </div>
    )
}

export default TTTeacherHead;