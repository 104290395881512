import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var httpURL = lstModules['main'].url + ":" + lstModules['main'].port;
var tokenid = localStorage.getItem('tokenid');


function getClassListSvc(callback1) {
  // console.log("getClassListSvc called");
  var status = 'Success'
  var obj = { status: status };

  axios({
    url: httpURL + '/sms/mst/cls/list',
    method: "POST",
    params: { screenId: 0 },
    headers: { 'tokenid': tokenid },
    withCredentials: true,
  }).then((response) => {
    // console.log('data.SvcStatus=' + response.data.SvcStatus);
    obj.message = response.data.SvcMsg;
    obj.status = 'Success';
    obj.lstClass = response.data.lstClass;
    // console.log(obj);
    callback1(obj);
  }).catch((error) => {
    obj.status = 'Exception';
    obj.message = error;
    callback1(obj)
  });
}

function getTestScheduleListSvc(callBack) {
  // console.log("getTestScheduleListSvc called");
  axios(
    {
      url: httpURL + "/sms/wt/schedule/list",
      method: 'POST',
      params: {},
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  ).then(
    (Response) => {
      var data = Response.data
      callBack(data)
    }
  ).catch((error) => {
    // console.log("TestScheduleList Catch error")
    callBack(error)
  });
}

function testScheduleDtlsSvc(testScheduleId, testBatchId, cbScheduleDtls) {
  // console.log("testScheduleDtlsSvc called, testScheduleId", testScheduleId, ", testBatchId", testBatchId);
  const formData = new FormData;
  formData.append("testScheduleId", testScheduleId);
  formData.append("testBatchId", testBatchId);
  const obj = { status: "", message: "" };
  axios(
    {
      url: httpURL + "/sms/wt/schedule/dtls",
      method: 'POST',
      // params: {"testScheduleId":testScheduleId, "testBatchId":testBatchId },
      data: formData,
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  )
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
          obj.testScheduleDtls = response.data.testScheduleDtls;
        }
        cbScheduleDtls(obj);
      } else {
        obj.status = 'Failure';
        obj.message = response.data.SvcMsg;
        cbScheduleDtls(obj);
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      cbScheduleDtls(obj)
    })
}
function testScheduleBatchListSvc(testScheduleId, cbScheduleBatchList) {
  // console.log("testScheduleBatchListSvc called, testScheduleId", testScheduleId);
  const formData = new FormData;
  formData.append("testScheduleId", testScheduleId);
  const obj = { status: "", message: "" };
  axios(
    {
      url: httpURL + "/sms/wt/schedule/batch/list",
      method: 'POST',
      data: formData,
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  )
    .then(response => {
      // console.log("response in testScheduleBatchListSvc", response);
      if (response.data.SvcStatus === 'Success') {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
          obj.lstBatchDtls = response.data.lstBatchDtls;
        }
        cbScheduleBatchList(obj);
      } else {
        obj.status = 'Failure';
        obj.message = response.data.SvcMsg;
        cbScheduleBatchList(obj);
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      cbScheduleBatchList(obj)
    })
}

function saveTestScheduleBatchSvc(testBatchObj, cbScheduleBatchList) {
  // console.log("saveTestScheduleBatchSvc called, testBatchObj", testBatchObj);
  const formData = new FormData;
  formData.append("testBatchObj", JSON.stringify(testBatchObj));
  const obj = { status: "", message: "" };
  axios(
    {
      url: httpURL + "/sms/wt/schedule/batch/save",
      method: 'POST',
      data: formData,
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  )
    .then(response => {
      // console.log("response in saveTestScheduleBatchSvc", response);
      if (response.data.SvcStatus === 'Success') {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbScheduleBatchList(obj);
      } else {
        obj.status = 'Failure';
        obj.message = response.data.SvcMsg;
        cbScheduleBatchList(obj);
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      cbScheduleBatchList(obj)
    })
}


function testScheduleDatesListSvc(testScheduleId,  cbScheduleDatesList) {
  // console.log("testScheduleDatesListSvc called, testScheduleId", testScheduleId);
  const formData = new FormData;
  formData.append("testScheduleId", testScheduleId);
  const obj = { status: "", message: "" };
  axios(
    {
      url: httpURL + "/sms/wt/schedule/test_dates/list",
      method: 'POST',
      data: formData,
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  )
    .then(response => {
      // console.log("response in testScheduleDatesListSvc", response);
      if (response.data.SvcStatus === 'Success') {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
          obj.lstTestDt = response.data.lstTestDt;
        }
        cbScheduleDatesList(obj);
      } else {
        obj.status = 'Failure';
        obj.message = response.data.SvcMsg;
        cbScheduleDatesList(obj);
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      cbScheduleDatesList(obj)
    })
}


function deleteTestScheduleDtSvc(objTestScheduleDt, cbDeleteTestScheduleDt){
  const formData = new FormData;
  formData.append("testScheduleObj", JSON.stringify(objTestScheduleDt));
  const obj = { status: "", message: "" };
  axios(
    {
      url: httpURL + "/sms/wt/schedule/test_dates/delete",
      method: 'POST',
      data: formData,
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  )
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbDeleteTestScheduleDt(obj);
      } else {
        obj.status = 'Failure';
        obj.message = response.data.SvcMsg;
        cbDeleteTestScheduleDt(obj);
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      cbDeleteTestScheduleDt(obj)
    })
}


function deleteTestScheduleSvc(testScheduleId, cbDeleteTestSchedule) {
  // console.log("deleteTestScheduleSvc called, testScheduleId", testScheduleId);
  const formData = new FormData;
  formData.append("testScheduleId", testScheduleId);
  const obj = { status: "", message: "" };
  axios(
    {
      url: httpURL + "/sms/wt/schedule/delete",
      method: 'POST',
      data: formData,
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  )
    .then(response => {
      // console.log("response in deleteTestScheduleSvc", response);
      if (response.data.SvcStatus === 'Success') {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbDeleteTestSchedule(obj);
      } else {
        obj.status = 'Failure';
        obj.message = response.data.SvcMsg;
        cbDeleteTestSchedule(obj);
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      cbDeleteTestSchedule(obj)
    })
}


function saveTestScheduleDtSvc(objTestSchedule, cbSaveScheduleTest) {
  // console.log("saveTestScheduleDtSvc called, objTestSchedule", objTestSchedule);
  const formData = new FormData;
  formData.append("testScheduleObj", JSON.stringify(objTestSchedule));
  const obj = { status: "", message: "" };
  axios(
    {
      url: httpURL + "/sms/wt/schedule/test_dates/save",
      method: 'POST',
      data: formData,
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  )
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbSaveScheduleTest(obj);
      } else {
        obj.status = 'Failure';
        obj.message = response.data.SvcMsg;
        cbSaveScheduleTest(obj);
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      cbSaveScheduleTest(obj)
    })
}

function saveTestScheduleSvc(objTestSchedule, cbSaveScheduleTest) {
  // console.log("saveTestScheduleSvc called, objTestSchedule", objTestSchedule);
  const formData = new FormData;
  formData.append("objTestSchedule", JSON.stringify(objTestSchedule));
  const obj = { status: "", message: "" };
  axios(
    {
      url: httpURL + "/sms/wt/schedule/save",
      method: 'POST',
      // params: {"cbSaveScheduleTest":cbSaveScheduleTest },
      data: formData,
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  )
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbSaveScheduleTest(obj);
      } else {
        obj.status = 'Failure';
        obj.message = response.data.SvcMsg;
        cbSaveScheduleTest(obj);
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      cbSaveScheduleTest(obj)
    })
}

function sectionListSvc(clsId, callback2) {
  var status = 'Success'
  var obj = { status: status, clsId: clsId }
  const formData = new FormData;
  formData.append("classId", clsId);
  axios({
    url: httpURL + '/sms/cls/sec/list',
    // url: httpURLCommon + '/sms/cls/sec/list',
    method: 'POST',
    // params: { clsId },
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
          obj.lstSection = response.data.lstClassSection;
        }
        callback2(obj)
      } else {
        obj.status = 'Failure'
        obj.message = response.data.SvcMsg
        callback2(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback2(obj)
    })
}
function generateQuestionsSvc(testScheduleId, testId, noOfQus, clsId, cbGenerateQuestionsResponse ){
  // console.log("saveTestScheduleSvc called, testScheduleId:", testScheduleId,", testId:", testId,", noOfQus:", noOfQus, ", clsId", clsId);
  const formData = new FormData;
  formData.append("testScheduleId", testScheduleId);
  formData.append("testId", testId);
  formData.append("noOfQus", noOfQus);
  formData.append("clsId", clsId);
  const obj = { status: "", message: "" };
  axios(
    {
      url: httpURL + "/sms/wt/schedule/generate_qus",
      method: 'POST',
      data: formData,
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  )
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbGenerateQuestionsResponse(obj);
      } else {
        obj.status = 'Failure';
        obj.message = response.data.SvcMsg;
        cbGenerateQuestionsResponse(obj);
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      cbGenerateQuestionsResponse(obj)
    })
}

export { getClassListSvc, getTestScheduleListSvc, sectionListSvc, testScheduleDtlsSvc, saveTestScheduleSvc, testScheduleBatchListSvc, testScheduleDatesListSvc, saveTestScheduleBatchSvc, deleteTestScheduleSvc, saveTestScheduleDtSvc, deleteTestScheduleDtSvc, generateQuestionsSvc };