import React, { useState, useEffect } from "react";
import "common/css/master.css";
import "../css/transport.css";
import { toast, ToastContainer } from "react-toastify";
import { getStopListSvc, reorderSvc } from "./routeSvc";

const RouteStopTable = (props) => {
  const [loader, setLoader] = useState("none");
  const [routeId, setRouteId] = useState(props.routeId);
  const [lstStop, setLstStop] = useState([]);
  const [flgMorEve, setFlgMorEve] = useState(false);
  const [lstReverseStop, setLstReverseStop] = useState([]);
  var lstStopId = [];
  useEffect(() => {
    // setLstStop(props.lstRouteStop)
    setRouteId(props.routeId);
    // setLoader("block")
    setFlgMorEve(props.flgMorEve);
    // console.log("useEffect called");
    getStopListSvc(props.routeId, props.flgMorEve, cbStopList);
  }, [props.routeId, props.flgMorEve]);

  function getStopListByRoute(pRouteId, pFlgMorEve) {
    getStopListSvc(pRouteId, pFlgMorEve, cbStopList);
  }
  function cbStopList(data, pFlgReverse) {
    if (data.SvcStatus === "Success") {
      if (!pFlgReverse) setLstStop(data.lstStop);
      else setLstReverseStop(data.lstStop);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }

  function reorder(pWhere, pStopId, pFlgMorEve) {
    var action;
    // console.log( "pWhere = " +  pWhere +  ", pStopId = " +  pStopId +  ", pFlgMorEve = " +  pFlgMorEve  );
    if (pWhere === 0) {
      // reOrderToTop(pStopId)
      action = "TOP";
    }
    if (pWhere === 1) {
      // reOrderUp(pStopId)
      action = "UP";
    }
    if (pWhere === 2) {
      // reOrderDown(pStopId)
      action = "DOWN";
    }
    if (pWhere === 3) {
      // reOrderToBottom(pStopId)
      action = "BOTTOM";
    }
    reorderSvc(routeId, pStopId, action, pFlgMorEve, cbReorderResponse);
  }

  function cbReorderResponse(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      props.generateRandomNo();
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function reOrderToBottom(pStopId) {
    lstStopId = [];

    var lstTmpStop = lstStop;
    for (var i = 0; i < lstStop.length; i++) {
      var stopObj = lstStop[i];

      if (pStopId === stopObj.stopId) {
        if (i === lstStop.length - 1) {
          return;
        }
        var tmp = lstTmpStop.splice(i, 1);
        lstTmpStop.push(tmp);
        break;
      }
    }
    setLstStop(lstTmpStop);
    for (var j = 0; j < lstTmpStop.length; j++) {
      var tmpStopObj = lstTmpStop[j];
      lstStopId.push(tmpStopObj.stopId);
    }
  }

  function reOrderDown(pStopId) {
    lstStopId = [];
    var lstTmpStop = lstStop;
    for (var i = 0; i < lstStop.length; i++) {
      var stopObj = lstStop[i];
      if (pStopId === stopObj.stopId) {
        // var stopToMoveUp = lstTmpStop.splice(i, 1)
        lstTmpStop[i] = lstStop[i + 1];
        lstTmpStop[i + 1] = stopObj;

        break;
      }
    }
    setLstStop(lstTmpStop);
    for (var j = 0; j < lstTmpStop.length; j++) {
      var tmpStopObj = lstTmpStop[j];
      lstStopId.push(tmpStopObj.stopId);
    }
  }
  function reOrderUp(pStopId) {
    lstStopId = [];
    var lstTmpStop = lstStop;
    for (var i = 0; i < lstStop.length; i++) {
      var stopObj = lstStop[i];
      if (pStopId === stopObj.stopId) {
        // var stopToMoveUp = lstTmpStop.splice(i, 1)
        lstTmpStop[i] = lstStop[i - 1];
        lstTmpStop[i - 1] = stopObj;

        break;
      }
    }
    setLstStop(lstTmpStop);
    for (var j = 0; j < lstTmpStop.length; j++) {
      var tmpStopObj = lstTmpStop[j];
      lstStopId.push(tmpStopObj.stopId);
    }
  }
  function reOrderToTop(pStopId) {
    lstStopId = [];
    var lstTmpStop = lstStop;
    for (var i = 0; i < lstStop.length; i++) {
      var stopObj = lstStop[i];
      if (pStopId === stopObj.stopId) {
        var tmp = lstTmpStop.splice(i, 1);
        lstTmpStop.unshift(tmp);
        break;
      }
    }
    setLstStop(lstTmpStop);
    for (var j = 0; j < lstTmpStop.length; j++) {
      var tmpStopObj = lstTmpStop[j];
      lstStopId.push(tmpStopObj.stopId);
    }
  }

  const colgrp = (
    <colgroup>
      <col width="5%"></col>
      <col width="12%"></col>
      <col width="12%"></col>
      <col width="9%"></col>
      <col width="9%"></col>
      <col width="9%"></col>
      <col width="9%"></col>
      <col width="6%"></col>
      <col width="6%"></col>
      <col width="6%"></col>
      <col width="13%"></col>
      <col width="10%"></col>
    </colgroup>
  );
  return (
    <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
      <ul className="nav nav-tabs" style={{ marginBottom: "2%" }}>
        <li
          className="active"
          onClick={(e) => {
            // console.log("morning");
            // getStopListByRoute(props.routeId, false);
            document.querySelector("#noon").className = "no-display";
            document.querySelector("#morning").className = "";
            e.target.closest("li").nextElementSibling.className = "";
            e.target.closest("li").className = "active";
            props.setFlgMorEve(false);
          }}
        >
          {" "}
          <a href="#morning" role="tab" data-toggle="tab">
            Morning Route
          </a>
        </li>
        <li
          onClick={(e) => {
            // console.log("evening");
            // getStopListByRoute(props.routeId, true);
            document.querySelector("#morning").className = "no-display";
            document.querySelector("#noon").className = "";
            e.target.closest("li").className = "active";
            e.target.closest("li").previousElementSibling.className = "";
            props.setFlgMorEve(true);
          }}
        >
          <a href="#noon" role="tab" data-toggle="tab">
            Afternoon Route
          </a>
        </li>
      </ul>
      <div className="tab-content">
        <div
          className="tab-pane fade active in"
          id="morning"
          style={{ fontWeight: "normal" }}
        >
          <div className="row">
            <div className="col-sm-12 form-group">
              <div
                className="pull-left padding-remove col-sm-2"
                style={{ border: "1px solid #b4b4b4" }}
              >
                <label
                  className="col-sm-7 control-label padding-remove"
                  style={{
                    color: "#fff",
                    background: "#ccc",
                  }}
                >
                  Route No.:
                </label>
                <label className="col-sm-5 control-label padding-remove pull-right">
                  {props.routeNo}
                </label>
              </div>
              <div
                className="pull-left padding-remove col-sm-2"
                style={{ marginLeft: "2%", border: "1px solid #b4b4b4" }}
              >
                <label
                  className="col-offset-1 col-sm-7 control-label padding-remove"
                  style={{ color: "#fff", background: "#ccc" }}
                >
                  ETA:
                </label>
                <label className="col-sm-5 control-label padding-remove pull-right">
                  8:15 am
                </label>
              </div>
              <div
                className="pull-left padding-remove col-sm-2"
                style={{ marginLeft: "2%", border: "1px solid #b4b4b4" }}
              >
                <label
                  className="col-sm-7 control-label padding-remove"
                  style={{ color: "#fff", background: "#ccc" }}
                >
                  ETD:
                </label>
                <label className="col-sm-5 control-label padding-remove pull-right">
                  7: 15 am
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-11" style={{ width: "95%" }}>
              <table className="tableLayout1">
                {colgrp}
                <thead>
                  <tr>
                    <th>Stop No.</th>
                    <th>Description</th>
                    <th>Address</th>
                    <th>Locality</th>
                    <th>City</th>
                    <th>Lat-Long</th>
                    <th>Range</th>
                    <th>Time from Previous</th>
                    <th>Time to Next</th>
                    <th>Arrival Time</th>
                    <th>Move</th>
                    <th>Action</th>
                  </tr>
                </thead>
              </table>
              <div className="tablebodyWrapper">
                <table className="tableLayout">
                  {colgrp}
                  <tbody>
                    {lstStop.map((obj, idx) => {
                      return (
                        <tr
                          key={idx}
                          route-id={routeId}
                          stop-id={obj.stopId}
                          id={obj.stopId}
                          onClick={(e) => props.setStopId(obj.stopId)}
                          className={
                            obj.stopId === props.stopId ? "selected" : ""
                          }
                        >
                          <td className="no-display">{obj.stopId}</td>
                          <td>{obj.stopNo}</td>
                          <td className="text-left">{obj.stopDesc}</td>
                          <td className="text-left">{obj.address}</td>
                          <td className="text-left">{obj.locality}</td>
                          <td className="text-left">{obj.city}</td>
                          <td className="text-left">{obj.latLong}</td>
                          <td className="text-left">{obj.stopRange}</td>
                          <td>{obj.tmFromPrev}</td>
                          <td>{obj.tmToNext}</td>
                          <td>{obj.arrivalTm}</td>
                          {/* <td>{obj.duration}</td> */}
                          <td className="text-center">
                            <span
                              style={{ transform: "rotate(90deg)" }}
                              className="glyphicon glyphicon glyphicon-backward"
                              title="Move to Top"
                              onClick={(e) => reorder(0, obj.stopId, flgMorEve)}
                            ></span>
                            <span
                              className="glyphicon glyphicon-triangle-top"
                              title="Move up"
                              onClick={(e) => reorder(1, obj.stopId, flgMorEve)}
                            ></span>
                            <span
                              className="glyphicon glyphicon-triangle-bottom"
                              title="Move down"
                              onClick={(e) => reorder(2, obj.stopId, flgMorEve)}
                            ></span>
                            <span
                              style={{ transform: "rotate(90deg)" }}
                              className="glyphicon glyphicon-forward"
                              title="Move to Bottom"
                              onClick={(e) => reorder(3, obj.stopId, flgMorEve)}
                            ></span>
                          </td>
                          <td className="text-center">
                            <span
                              onClick={(e) =>
                                props.openStopSlider(
                                  routeId,
                                  obj.stopId,
                                  flgMorEve,
                                  obj
                                )
                              }
                              className="glyphicon glyphicon-edit"
                            ></span>
                            <span
                              onClick={(e) => {
                                props.setStopId(obj.stopId);
                                props.setLatLng(obj.latLong);
                                props.openLatLngSlider();
                              }}
                              className="glyphicon glyphicon-map-marker"
                            ></span>
                            <span
                              onClick={(e) =>
                                props.deleteRouteStop(routeId, obj.stopId)
                              }
                              className="glyphicon glyphicon-trash"
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="col-sm-1"
              style={{ width: "4%" }}
              onClick={(e) => {
                props.openStopSlider(routeId, 0, flgMorEve, {
                  stopId: 0,
                  stopNo: "",
                  stopDesc: "",
                  stateId: 0,
                  address: "",
                  cityId: 0,
                  localityId: 0,
                  stopRangeId: 0,
                });
                props.generateSliderKey();
              }}
            >
              <span
                id="addReadyId"
                title="Add"
                className="addIcon glyphicon glyphicon-plus-sign"
              ></span>
            </div>
          </div>
        </div>
        <div className="tab-pane" id="noon">
          <div className="row">
            <div className="col-sm-12 form-group">
              <div
                className="pull-left padding-remove col-sm-2"
                style={{ border: "1px solid #b4b4b4" }}
              >
                <label
                  className="col-sm-7 control-label padding-remove"
                  style={{
                    color: "#fff",
                    background: "#ccc",
                  }}
                >
                  Route No.:
                </label>
                <label className="col-sm-5 control-label padding-remove pull-right">
                  {props.routeNo}
                </label>
              </div>
              <div
                className="pull-left padding-remove col-sm-2"
                style={{ marginLeft: "2%", border: "1px solid #b4b4b4" }}
              >
                <label
                  className="col-offset-1 col-sm-7 control-label padding-remove"
                  style={{ color: "#fff", background: "#ccc" }}
                >
                  ETA:
                </label>
                <label className="col-sm-5 control-label padding-remove pull-right">
                  8:15 am
                </label>
              </div>
              <div
                className="pull-left padding-remove col-sm-2"
                style={{ marginLeft: "2%", border: "1px solid #b4b4b4" }}
              >
                <label
                  className="col-sm-7 control-label padding-remove"
                  style={{ color: "#fff", background: "#ccc" }}
                >
                  ETD:
                </label>
                <label className="col-sm-5 control-label padding-remove pull-right">
                  7: 15 am
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-11">
              <table className="tableLayout1">
                {colgrp}
                <thead>
                  <tr>
                    <th>Stop No.</th>
                    <th>Description</th>
                    <th>Address</th>
                    <th>Locality</th>
                    <th>City</th>
                    <th>Lat-Long</th>
                    <th>Range</th>
                    <th>Time from Previous</th>
                    <th>Time to Next</th>
                    <th>Arrival Time</th>
                    <th>Move</th>
                    <th>Action</th>
                  </tr>
                </thead>
              </table>
              <div className="tableDiv tableBodyBorder" style={{ height: "200px" }}>
                <table className='tableLayout'>
                  {colgrp}
                  <tbody>
                    {lstReverseStop.map((obj, idx) => {
                      return (
                        <tr
                          key={idx}
                          route-id={routeId}
                          stop-id={obj.stopId}
                          id={obj.stopId}
                          onClick={(e) => props.setStopId(obj.stopId)}
                          className={
                            obj.stopId === props.stopId ? "selected" : ""
                          }
                        >
                          <td className="no-display">{obj.stopId}</td>
                          <td>{obj.stopNo}</td>
                          <td className="text-left">{obj.stopDesc}</td>
                          <td className="text-left">{obj.address}</td>
                          <td className="text-left">{obj.locality}</td>
                          <td className="text-left">{obj.city}</td>
                          <td className="text-left">{obj.latLong}</td>
                          <td className="text-left">{obj.stopRange}</td>
                          <td>{obj.tmFromPrev}</td>
                          <td>{obj.tmToNext}</td>
                          <td>{obj.arrivalTm}</td>
                          {/* <td>{obj.duration}</td> */}
                          <td className="text-center">
                            <span
                              style={{ transform: "rotate(90deg)" }}
                              className="glyphicon glyphicon glyphicon-backward"
                              title="Move to Top"
                              onClick={(e) => reorder(0, obj.stopId)}
                            ></span>
                            <span
                              className="glyphicon glyphicon-triangle-top"
                              title="Move up"
                              onClick={(e) => reorder(1, obj.stopId)}
                            ></span>
                            <span
                              className="glyphicon glyphicon-triangle-bottom"
                              title="Move down"
                              onClick={(e) => reorder(2, obj.stopId)}
                            ></span>
                            <span
                              style={{ transform: "rotate(90deg)" }}
                              className="glyphicon glyphicon-forward"
                              title="Move to Bottom"
                              onClick={(e) => reorder(3, obj.stopId)}
                            ></span>
                          </td>
                          <td className="text-center">
                            <span
                              onClick={(e) =>
                                props.openStopSlider(
                                  routeId,
                                  obj.stopId,
                                  flgMorEve,
                                  obj
                                )
                              }
                              className="glyphicon glyphicon-edit"
                            ></span>
                            <span
                              onClick={(e) => {
                                props.setLatLng(obj.latLong);
                                props.openLatLngSlider();
                              }}
                              className="glyphicon glyphicon-map-marker"
                            ></span>
                            <span
                              onClick={(e) =>
                                props.deleteRouteStop(routeId, obj.stopId)
                              }
                              className="glyphicon glyphicon-trash"
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="col-sm-1"
              style={{ width: "4%" }}
              onClick={(e) => props.openStopSlider(routeId, 0, flgMorEve, {})}
            >
              <span
                id="addReadyId"
                title="Add"
                className="addIcon glyphicon glyphicon-plus-sign"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RouteStopTable;
