import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify';
import lstModules from 'common/json/lstModules.json';

var httpURL = lstModules['timeTable'].url + ":" + lstModules['timeTable'].port;
var httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;

var screenId = 33;
var tokenid = localStorage.getItem("tokenid");

function saveTchForCell(cellDtls, cb) {
    var status = 'Success'
    var obj = { status: status, message: "" };
    // console.log("savetchforcell::" + JSON.stringify(cellDtls))
    axios
        ({
            url: httpURL + "/sms/tt/v1/tt/tch/cell/save",
            //queryString.stringify({ screenId: screenId, cellId: cellId, sub1: sub1 }),
            method: "POST",
            headers: { 'tokenid': tokenid },
            withCredentials: true,
            params: { screenId: screenId, cellDtls: JSON.stringify(cellDtls) },
        })
        .then((response) => {
            //if (response.data.SvcStatus === 'Success') {
            // console.log("msg:" + response.data.SvcMsg + "status:" + response.data.SvcStatus)
            obj.message = response.data.SvcMsg;
            obj.status = response.data.SvcStatus;
            cb(cellDtls, obj)
            //}
            // else {
            //     obj.status = 'Failure';
            //     obj.message = response.data.SvcMsg;
            //     cb({}, obj)
            // }

        }).catch((error) => {
            obj.status = 'Exception';
            obj.message = error;
            cb({}, obj)
        });
}
function getClassList(callback1) {
    // console.log("getClassList");
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURLCommon + '/sms/cls/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            obj.lstCls = response.data.lstCls;
            callback1(obj)
        }
        else {
            obj.status = 'Failure';
            obj.message = response.data.SvcMsg;
            callback1(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback1(obj)
    });
}

function getSectionData(clsId, callback2) {
    // console.log("getSectionList");
    // console.log("CLSID" + clsId);
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + "/sms/tt/v1/tt/section/list",
        method: "POST",
        params: { screenId, clsId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            obj.lstSection = response.data.lstSection;
            callback2(obj)
        }
        else {
            obj.status = 'Failure';
            obj.message = response.data.SvcMsg;
            callback2(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback2(obj)
    });
}

function getSessionData(callback, clsId) {
    // console.log("getSessionList");
    // console.log("CLSID for session " + clsId);
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + "/sms/tt/v1/tt/session/list",
        method: "POST",
        params: { screenId, clsId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            obj.lstSession = response.data.lstSession;
            callback(obj)
        }
        else {
            obj.status = 'Failure';
            obj.message = response.data.SvcMsg;
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

function getTchLst(sessionId, clsId, sectionId, callback) {
    // console.log(sessionId, clsId, sectionId)
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + "/sms/tt/v1/tt/tch/sec/list",
        method: "POST",
        params: { screenId, sessionId, clsId, sectionId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {

        if (response.data.SvcStatus === 'Success') {
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            obj.lstTch = response.data.lstTch;
            callback(obj)
        }
        else {
            obj.status = 'Failure';
            obj.message = response.data.SvcMsg;
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

function getLstDay(callback) {
    // console.log("getDaysDtls");
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/tt/v1/tt/wing_days/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {

        if (response.data.SvcStatus === 'Success') {
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            obj.lstDay = response.data.lstDay;
            callback(obj)
        }
        else {
            obj.status = 'Failure';
            obj.message = response.data.SvcMsg;
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });

}

function getLstPeriod(sessionId, callback) {
    // console.log("getPeriodDtls");
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + '/sms/tt/v1/tt/period/list',
        method: "POST",
        params: { sessionId, screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            obj.lstPeriod = response.data.lstPeriod;
            callback(obj)
        }
        else {
            obj.status = 'Failure';
            obj.message = response.data.SvcMsg;
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

function getCellDtls(callback, rqCell, pRefresh, pCellId) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + '/sms/tt/v1/tt/tch/cell/dtls',
        method: "POST",
        params: { rqCell: JSON.stringify(rqCell), screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            obj.cellDtls = response.data.cellDtls;
            callback(obj, pRefresh, pCellId)
        }
        else {
            obj.status = 'Failure';
            obj.message = response.data.SvcMsg;
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

function deleteCellDtls(cellId, subPosition, callback) {
    var cellDtls = {
        cellId: cellId,
        subPosition: subPosition,
    }
    var status = 'Success'
    var obj = { status: status };
    if (window.confirm("Are you sure to remove the teacher from the cell?")) {
        axios
            ({
                url: httpURL + "/sms/tt/v1/tt/tch/cell/delete",
                //queryString.stringify({ screenId: screenId, cellId: cellId, sub1: sub1 }),
                method: "POST",
                headers: { 'tokenid': tokenid },
                withCredentials: true,
                params: { screenId: screenId, cellDtls: JSON.stringify(cellDtls) },
            })
            .then((response) => {
                if (response.data.SvcStatus === 'Success') {
                    obj.message = response.data.SvcMsg;
                    obj.status = 'Success';
                    callback(obj, cellDtls)
                }
                else {
                    obj.status = 'Failure';
                    obj.message = response.data.SvcMsg;
                    callback(obj)
                }
            }).catch((error) => {
                obj.status = 'Exception';
                obj.message = error;
                callback(obj)
            });
    }
};

export { getClassList, getSectionData, getSessionData, getTchLst, getLstDay, getLstPeriod, getCellDtls, deleteCellDtls, saveTchForCell }
