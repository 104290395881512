import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../css/leaderboard.css';

const StudentScore = (props) => {

    const { studentScore, flgStudentScore } = props;


    useEffect(() => {
        // console.log("StudentScore called, studentScore", studentScore, ", flgStudentScore", flgStudentScore);
    }, [studentScore, flgStudentScore]);

    return (
        <div className="col-sm-12 padding-remove">
            {
                flgStudentScore ?
                    <div className="col-sm-12 col-xs-12 student-score padding-remove" >
                        <div className="col-sm-6 col-xs-6  padding-remove " >
                            <div className="my-score-label">
                                {"My Score"}
                            </div >
                        </div>
                        <div className="col-sm-6 col-xs-6  padding-remove scoreCircle" >
                            <div className="score-text">
                                {studentScore}
                            </div >
                        </div>
                    </div>
                    : null
            }
        </div>
    );
}

export default StudentScore;