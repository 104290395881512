import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../css/leaderboard.css';
import { size } from "lodash";
import { leaderBoardSvc, nextQuestionSvc } from "./leaderBoardSvc";
import TopStudents from "./topStudents";
import StudentScore from "./studentScore";
import TopFiveRankers from "./topFiveRankers";
import FinalScore from "./finalScore";
import FullScreenConfetti from "student/utility/fullScreenConfetti";

const LeaderBoard = (props) => {

    const params = new URLSearchParams(window.location.search);
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [studentId, setStudentId] = useState(0);
    const [testId, setTestId] = useState(0);
    const [schoolId, setSchoolId] = useState(0);
    const [questionNo, setQuestionNo] = useState(0);
    const [studentScore, setStudentScore] = useState(0);
    const [flgFinalScore, setFlgFinalScore] = useState(false);

    const [lstLeaderBoard, setLstLeaderBoard] = useState([]);
    const [flgStudentScore, setFlgStudentScore] = useState(true);
    const [leaderboardCount, setLeaderboardCount] = useState(0);

    useEffect(() => {
        // console.log("TestQuestion called");
        // let tempLstLeaderBoard = [{ studentId: 7182, avatarId: 1, score: 60, crypticNm: 'Becky' }, { studentId: 7189, avatarId: 2, score: 98, crypticNm: 'Humpty' }, { studentId: 7190, avatarId: 3, score: 97, crypticNm: 'Perry' }];
        // console.log("tempLstLeaderBoard before sorting", tempLstLeaderBoard);
        // tempLstLeaderBoard.sort((a, b) => a.score - b.score);
        // console.log("tempLstLeaderBoard after sorting", tempLstLeaderBoard.sort((a, b) => a.score - b.score));
        // setLstLeaderBoard(tempLstLeaderBoard.sort((a, b) => b.score - a.score));
        const sId = params.get("sId");
        const tId = params.get("tId");
        const qusNo = params.get("qusNo");
        const schId = params.get("schId");
        const rankers = params.get("rankers");
        setLeaderboardCount(rankers);
        setStudentId(sId);
        setTestId(tId);
        setQuestionNo(qusNo);
        setSchoolId(schId);
        // console.log("LeaderBoard called::" + sId + '-' + tId);
        getLeaderBoard(sId, tId, schId, rankers);
    }, []);

    const getLeaderBoard = (sId, tId, schId, rankers) => {
        // console.log("getLeaderBoard called, sId", sId, ", tId", tId);
        setLoader("block");
        setOpacity(0.5);
        leaderBoardSvc(tId, sId, schId, rankers, leaderboardResponse);
    }

    const leaderboardResponse = (data) => {
        // console.log("leaderboardResponse called, data", data);
        let tempLstLeaderBoard = {};
        let obj = data;
        if (obj.status === 'Success') {
            tempLstLeaderBoard = obj.lstLeaderBoard;
            setLstLeaderBoard(tempLstLeaderBoard.sort((a, b) => b.score - a.score));
            setFlgFinalScore(obj.finalScore);
            setStudentScore(obj.studentScore);
            if (!obj.finalScore) {
                callNextQuestion(obj.testId, obj.studentId, obj.schoolId, obj.rankers);
            }
            const sId = params.get("sId");
            if (obj.lstLeaderBoard.some(student => student.studentId == sId)) {
                setFlgStudentScore(false);
            }
        }
        else {
            toast.error(obj.message);
        }
        setLoader('none');
        setOpacity(1);
    }

    const callNextQuestion = (pTestId, pStudentId, pSchoolId, rankers) => {
        // console.log("callNextQuestion called, pTestId", pTestId, ", pStudentId", pStudentId);
        const qusNo = params.get("qusNo");
        setQuestionNo(qusNo);
        const interval = setInterval(() => {
            getNextQuestion(pTestId, pStudentId, qusNo, pSchoolId, rankers);
        }, 500);
        return () => clearInterval(interval);
    }

    const getNextQuestion = (pTestId, pStudentId, qusNo, pSchoolId, rankers) => {
        // console.log("getNextQuestion called, pTestId", pTestId, ", pStudentId", pStudentId);
        // setLoader("block");
        // setOpacity(0.5);
        nextQuestionSvc(pTestId, pStudentId, qusNo, pSchoolId, rankers, nextQuestionResponse);
    }

    const nextQuestionResponse = (data) => {
        // console.log("nextQuestionResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            if (obj.nextAction !== 10) {
                handleNextPage(obj.nextAction, obj.testId, obj.studentId, obj.schoolId, obj.rankers);
            }
        }
        else {
            toast.error(obj.message);
        }
        // setLoader('none');
        // setOpacity(1);
    }

    const handleNextPage = (pNextAction, tId, sId, schId, rankers) => {
        // console.log("handle nextPage called, pNextAction", pNextAction);
        // 10: Stay, 20: next qus, 30:leaderboard
        if (pNextAction === 20) {
            // const newPageUrl = "/testQuestion?tId=" + testId + "&sId=" + studentId;
            const newPageUrl = "/testQuestion?tId=" + tId + "&sId=" + sId + "&schId=" + schId + "&rankers=" + rankers;
            window.location.href = newPageUrl;
        }
    }

    const giveFeedback = (flgPositive) => {
        // console.log("giveFeedback called, flgPositive", flgPositive);
        const newPageUrl = "testFeedback?testId=" + testId + "&studentId=" + studentId + "&schoolId=" + schoolId + "&flgFeedbackPositive=" + flgPositive;
        // console.log("newPageUrl", newPageUrl);
        window.location.href = newPageUrl;
    }

    return (
        <div className="col-sm-12 padding-remove">
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="col-sm-12 col-xs-12 padding-remove" style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}>
                <div className="col-sm-6 col-xs-12 col-centered">
                    <FinalScore
                        flgFinalScore={flgFinalScore}
                    />
                </div>
                <div className="col-sm-12 col-xs-12 padding-remove" >
                    {lstLeaderBoard ?
                        (
                            leaderboardCount == 5 ?
                                <div className="col-sm-8 col-xs-12 col-centered">
                                    <TopFiveRankers
                                        lstLeaderBoard={lstLeaderBoard}
                                    />
                                </div>
                                :
                                <div className="col-sm-6 col-xs-10 col-centered">
                                    <TopStudents
                                        lstLeaderBoard={lstLeaderBoard}
                                    />
                                </div>
                        )
                        : null
                    }
                    <div className="col-sm-4 col-xs-8  pull-right">
                        <StudentScore
                            studentScore={studentScore}
                            flgStudentScore={flgStudentScore}
                        />
                    </div>
                </div>
                {
                    flgFinalScore ?
                        <div className="col-sm-3 col-xs-12 col-centered padding-remove" >
                            <div className="col-sm-6 col-xs-6 padding-remove">
                                <button className="submit-button " style={{width:"90%", marginLeft:"5%", marginRight:"5%"}} onClick={() =>giveFeedback(true)}>I Enjoyed!</button>
                            </div>
                            <div className="col-sm-6 col-xs-6 padding-remove">
                                <button className="submit-button " style={{width:"90%", marginLeft:"5%", marginRight:"5%"}} onClick={()=>giveFeedback(false)}>Not so good!</button>
                            </div>
                        </div>
                        : null
                }
                {
                    flgFinalScore && schoolId === 31270 ?
                        <FullScreenConfetti
                        />
                        : null
                }
            </div>
        </div >
    );
}

export default LeaderBoard;